import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AppLogo from 'general/components/AppLogo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

AuthHeader.propTypes = {

};

function AuthHeader(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();

    // MARK: --- Functions ---
    function handleTerms(e) {
        e.preventDefault();
        history.push('/terms');
    }

    function handlePolicyPrivacy(e) {
        e.preventDefault();
        history.push('/policy-privacy');
    }

    return (
        <div className='AuthHeader'>
            <div className='container-xxl py-3'>
                <div className='row'>
                    {/* Logo */}
                    <div className='col-lg-3 col-md-4 col-sm-5'>
                        <AppLogo />
                    </div>

                    <div className='col'></div>

                    {/* Right Links */}
                    <div className='col-lg-4 col-md-5 col-sm-6 d-flex justify-content-end align-items-center'>
                        <ul className="nav d-flex align-items-center">
                            <li className="nav-item">
                                <a className="nav-link text-white text-hover-primary font-size-lg font-weight-bold" href="#" onClick={handleTerms}>{t('Terms')}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white text-hover-primary font-size-lg font-weight-bold" href="#" onClick={handlePolicyPrivacy}>{t('Privacy')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthHeader;