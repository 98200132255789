import { createRef, useLayoutEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyView from "../../../../../general/components/Views/EmptyView";
import { AppIcons } from "../../../../../general/constants/AppResource";
import Utils from "../../../../../general/utils/Utils";
import { thunkLoadMoreAlert } from "../../../IdeaListSlice";
import CreateAlertModal from "../CreateAlertModal";
import RemoveAlertModal from "../RemoveAlertModal";
import "./style.scss";

const ListAlertModalRef = createRef()

export default {
    show: (cantSelectStockCode, stockCodeDefault) => {
        ListAlertModalRef.current?.show(cantSelectStockCode, stockCodeDefault)
    },
    hide: () => {
        ListAlertModalRef.current?.hide()
    }
}

export function ListAlertModalComponent() {
    const { t } = useTranslation();
    const listAlert = useSelector(state => state?.ideaList?.mgAlert?.listAlert)
    const pagination = useSelector(state => state?.ideaList?.mgAlert?.pagination)
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [params, setParams] = useState({})
    const [enableSelectStockCode, setEnableSelectStockCode] = useState(true)
    const [selectStockCode, setSelectStockCode] = useState(null)
    useLayoutEffect(() => {
        ListAlertModalRef.current = {
            'show': (cantSelectStockCode, stockCodeDefault) => {
                if (cantSelectStockCode) {
                    setEnableSelectStockCode(false)
                    setSelectStockCode(stockCodeDefault)
                }
                setModalVisible(true)
            },
            'hide': () => setModalVisible(false)
        }
    }, [])

    const ref = useRef()

    const loadMoreAlert = async () => {
        dispatch(thunkLoadMoreAlert({
            page: pagination.currentPage
        }))
    }

    const handleScroll = async (e) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom && (pagination?.total > listAlert?.length)) {
            await loadMoreAlert()
        }
    }
    const handleCreateAlert = () => {
        setModalVisible(false)
        if (enableSelectStockCode) {
            CreateAlertModal.show(false, null, false, null)
        }
        else {
            CreateAlertModal.show(false, null, true, selectStockCode)
        }
    }

    const handleDeleteAlert = (alertId) => {
        setModalVisible(false)
        RemoveAlertModal.show(alertId)
    }

    const handleEditAlert = (alert) => {
        setModalVisible(false)
        CreateAlertModal.show(true, alert)
    }

    return (
        <>
            <Modal
                centered={true}
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                backdrop={true}
                keyboard={false}
                size={"md"}
            >
                <Modal.Header style={{ height: "50px" }}>
                    <Modal.Title>{t("ListAlert")}</Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='max-h-350px p-3 q-hide-scrollbar rounded-bottom' style={{
                    backgroundColor: "#F3F5F8",
                    overflowY: 'scroll', overflowX: 'hidden'
                }} onScroll={handleScroll}>
                    <div className='font-size-h6 custom-body-list'>
                        {
                            listAlert?.map((item, index) => {
                                let type = Utils.getAlertType(item?.type)
                                let compare = Utils.getCompareSign(item?.compare)
                                return (
                                    <div
                                        key={index}
                                        className={`px-4 py-2 bg-white d-flex justify-content-between align-items-center ${index < listAlert?.length - 1 && 'border-bottom'}`}
                                    >
                                        <div className='px-2' style={{ overflowX: 'hidden', flex: '1 1 auto' }}>
                                            <span>{item?.stockCode}</span>
                                            <span className={"ml-2 compare-text"}>{
                                                t(type) + " " + compare + " " + item?.value
                                            }</span>
                                        </div>

                                        <div
                                            className='p-1'
                                            style={{ flex: '0 0 auto' }}
                                        >
                                            <img
                                                src={AppIcons.icSquarePen}
                                                draggable={false}
                                                className='mr-3 cursor-pointer hover-opacity-70'
                                                onClick={() => handleEditAlert(item)}
                                            />
                                            <img
                                                src={AppIcons.icSquareXMark}
                                                draggable={false}
                                                className='cursor-pointer hover-opacity-70'
                                                onClick={() => handleDeleteAlert(item?.alertId)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            listAlert?.length == 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className=''>
                    <div className='w-100 d-flex row'>
                        <Button className='font-weight-bold flex-grow-1 col mr-3' variant="secondary" onClick={() => {
                            setModalVisible(false);
                        }}>
                            {t('Cancel')}
                        </Button>
                        <Button className='font-weight-bold flex-grow-1 col ml-3' variant="primary" onClick={handleCreateAlert}>
                            {t('CreateAlert')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

