import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { thunkGetCurrentUserInfo } from '../../../../app/authSlice';
import UserHelper from '../../../../general/helpers/UserHelper';
import './style.scss';

function AccountTransactionScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const loggedInUser = useSelector(state => state.auth.current);

    /**
     * @type {{paymentId,type:'MEMBER',planId,status:'SUCCESS',plan:{duration},time}[]}
     */
    const allAccountTransactions = _.orderBy(useSelector(state => state.account.allTransactions ?? []), ['time'], 'desc');

    // all plan payment
    let allSuccessPlanPayment = _.isArray(allAccountTransactions) ?
        allAccountTransactions.filter(payment => payment.status == 'SUCCESS' && payment.type == 'MEMBER') : []

    const currentPlan = loggedInUser ? allSuccessPlanPayment.sort((a, b) => moment(a.time).toDate() - moment(b.time).toDate())
        .find(payment => moment().toDate() < moment(payment.time).add(moment(payment.plan?.duration), 'month').toDate()) : null;

    const extendedPlans = loggedInUser && !!currentPlan ? allSuccessPlanPayment.sort((a, b) => moment(a.time).toDate() - moment(b.time).toDate())
        .filter(payment => moment(payment.time).toDate() >= moment(currentPlan?.time).toDate() && payment.paymentId != currentPlan.paymentId) : []

    const dispatch = useDispatch()

    useEffect(() => {
        if (!Utils.isObjectEmpty(loggedInUser) && UserHelper.checkAccessTokenValid()) {
            dispatch(thunkGetCurrentUserInfo());
        }
    }, [])

    // MARK: --- Functions ---
    function handleUpdate() {
        history.push('/payment/plan');
    }

    function handleExtend() {
        history.push('/payment/plan');
    }

    function handleClickTransactionItem(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                history.push(`/course/${course.courseId}`);
            }
        }
        if (type === 'WEBINAR') {
            const webinar = item.webinar;
            if (webinar) {
                history.push(`/knowledge-center/webinar/${webinar.webinarId}`);
            }
        }
    }

    function getTransacltionDescription(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                return course.name;
            }
        }
        if (type === 'WEBINAR') {
            const webinar = item.webinar;
            if (webinar) {
                return webinar.name;
            }
        }
        return '';
    }

    function getTransacltionName(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                return course.name;
            }
        }
        if (type === 'WEBINAR') {
            const webinar = item.webinar;
            if (webinar) {
                return webinar.name;
            }
        }
        return '';
    }

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className='d-flex flex-column align-items-center'>
                    <img
                        className='mb-6'
                        src={AppIcons.icAccountTransaction}
                        alt='icon'
                        draggable={false}
                    />
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{t('AccountTransactionTitle').toUpperCase()}</p>
                    <p className='font-size-lg text-center m-0'>{t('AccountTransactionDescription')}</p>
                </div>

                <div>
                    <div className='container-xxl d-flex justify-content-center pt-7 pb-4'>
                        <table className='table col-lg-5 col-md-8 col-sm-9 border'>
                            <tbody className=''>
                                {
                                    currentPlan ? (
                                        <>
                                            <tr>
                                                <td className='AccountTransactionScreen_Text_01 col-5 text-right '><span >{`${t('CurrentPlan')}:`}</span></td>
                                                <td className='AccountTransactionScreen_Text_02 col-5 '><span>{currentPlan?.plan?.name ?? ''}</span></td>
                                            </tr>
                                            <tr>
                                                <td className='AccountTransactionScreen_Text_01 col-5 text-right'><span >{`${t('Duration')}:`}</span></td>
                                                <td className='AccountTransactionScreen_Text_02 col-5 '><span>{`${currentPlan?.plan?.duration ?? ''} ${t('months')}`}</span></td>
                                            </tr>
                                            {(_.isArray(extendedPlans) && extendedPlans.length > 0) && (
                                                <tr>
                                                    <td className='AccountTransactionScreen_Text_01 col-5 text-right'><span >{`${t('Extended by')}:`}</span></td>
                                                    <td className='AccountTransactionScreen_Text_02 col-5 '><span>{`${_.sum(extendedPlans.map(plan => plan.plan?.duration)) ?? ''} ${t('months')}`}</span></td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className='AccountTransactionScreen_Text_01 col-5 text-right'><span >{`${t('Status')}:`}</span></td>
                                                <td className='AccountTransactionScreen_Text_02 col-5'><span style={{ color: Utils.getTransactionStatusColor(currentPlan?.status) }}>{t(currentPlan?.status)}</span></td>
                                            </tr>
                                            <tr>
                                                <td className='AccountTransactionScreen_Text_01 col-5 text-right'><span >{`${t('StartDate')}:`}</span></td>
                                                <td className='AccountTransactionScreen_Text_02 col-5 '><span>{Utils.formatDateTime(currentPlan?.time, 'DD/MM/YYYY HH:mm')}</span></td>
                                            </tr>
                                            <tr>
                                                <td className='AccountTransactionScreen_Text_01 col-5 text-right'><span >{`${t('ExpiredDate')}:`}</span></td>
                                                <td className='AccountTransactionScreen_Text_02 col-5 '><span>{Utils.formatDateTime(loggedInUser?.memberExpirationDate, 'DD/MM/YYYY HH:mm')}</span></td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr className='align-items-center justify-content-center'>
                                            <div className='w-100 d-flex align-items-center justify-content-center pt-6'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        </tr>
                                    )
                                }

                                <tr>
                                    {
                                        Utils.isObjectNull(currentPlan) ? (
                                            <td colSpan={2} className='col-5 text-right border-bottom h-60px'>
                                                <button className='AccountTransactionScreen_button_Update h-100 w-100 hover-opacity-60' onClick={() => { handleUpdate() }}>{t('Upgrade')}</button>
                                            </td>
                                        ) : (
                                            <td colSpan={2} className='col-5 text-left border-bottom h-60px'>
                                                <button className='AccountTransactionScreen_button_Extend h-100 w-100 hover-opacity-60' onClick={() => { handleExtend() }}>{t('Extend')}</button>
                                            </td>
                                        )
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                allAccountTransactions.length > 0 ? (
                    <div className='d-block overflow-auto max-h-450px border container col-10 p-0'>
                        <table className="table table-hover m-0" >
                            <thead className='position-sticky top-0 zindex-1'>
                                <tr className='AccountTransactionScreen_Table_Header'>
                                    <th style={{ whiteSpace: 'nowrap' }}>{t('TransactionId')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>{t('TransactionType')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Description')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Time')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Status')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('PaymentMethod')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Price')}</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    allAccountTransactions.map((item, index) => {
                                        return (
                                            <tr
                                                key={index} className='cursor-pointer'
                                                onClick={() => {
                                                    handleClickTransactionItem(item);
                                                }}
                                            >
                                                <td className='col-1 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {item?.paymentTransactionId}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns font-weight-bolder font-size-lg text-primary'>
                                                    {t(item?.type)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {getTransacltionDescription(item)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {Utils.formatDateTime(item.time, 'DD/MM/YYYY HH:mm')}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns' style={{
                                                    color: Utils.getTransactionStatusColor(item?.status)
                                                }}>
                                                    {t(item?.status)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {t(item?.paymentChannel)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {`${Utils.formatNumber(item?.price)} VNĐ`}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                ) : (<></>)
            }
        </AccountBaseLayout>
    );
}

export default AccountTransactionScreen;
