import {
    CandlestickData, createChart,
    CrosshairMode, HistogramData, IChartApi
} from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import Utils from "../../../../../../general/utils/Utils";
import "./style.scss";

/**
 * 
 * @param {{fitContent:boolean,volumeData:(HistogramData|CandlestickData)[],candleData:(HistogramData|CandlestickData)[]}} props 
 * @returns 
 */
function CandleChart(props) {
    let { fitContent } = props
    let volumeData = props.volumeData.map(item => {
        return { ...item };
    });
    let candleData = props.candleData.map(item => {
        return { ...item };
    });
    let chartContainerRef = useRef();
    let chart: React.MutableRefObject<IChartApi> = useRef();
    let resizeObserver: React.MutableRefObject<ResizeObserver> = useRef();
    let [currentValue, setCurrentValue] = useState([])
    let [closeLessThanOpen, setCloseLessThanOpen] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        chart.current = createChart(chartContainerRef.current, {
            width: '100%',
            height: '100%',
            layout: {
                backgroundColor: '#FFFFFF',
                textColor: '#353945',
            },
            rightPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            leftPriceScale: {
                visible: true,
                borderColor: 'rgba(197, 203, 206, 1)',
            },
            grid: {
                vertLines: {
                    color: '#F1F2F4',
                },
                horzLines: {
                    color: '#F1F2F4',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: "#B1B5C3"
            },
            localization: {
                dateFormat: 'dd/MM/yyyy'
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                borderColor: 'white',
                barSpacing: 10,
                fixRightEdge: true,
            },

        });

        let volumeSeries = chart.current.addHistogramSeries({
            priceFormat: {
                type: 'volume',
            },
            priceScaleId: 'left',
            overlay: false,
        });

        let candleSeries = chart.current.addCandlestickSeries({
            priceScaleId: 'right',
            // overlay: false,
            upColor: '#68C57C',
            downColor: '#E84650',
            borderDownColor: '#E84650',
            borderUpColor: '#68C57C',
            wickDownColor: '#E84650',
            wickUpColor: '#68C57C',
            autoscaleInfoProvider: original => {
                const res = original();
                if (res !== null) {
                    res.priceRange.minValue -= 5;
                    res.priceRange.maxValue += 5;
                }
                return res;
            },
        });

        volumeSeries.setData(volumeData);
        candleSeries.setData(candleData);
        volumeSeries.priceScale('overlay1').applyOptions({
            scaleMargins: {
                top: 0.863,
                bottom: 0,
            },
        });

        chart.current?.subscribeCrosshairMove(params => {
            let obj = Array.from(params?.seriesPrices.values())
            if (obj[1]) {
                setCloseLessThanOpen(obj[1].close < obj[1].open)
                setCurrentValue(obj ?? [])
            }

            // setCurrentValue(obj);
        })

    }, []);


    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            let { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            if (!!fitContent) {
                setTimeout(() => {
                    chart.current.timeScale().fitContent();
                }, 0);
            }
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);


    return (
        <div className={"custom-chart-area"}>
            <div ref={chartContainerRef} className={"h-600px"} >
                {
                    currentValue?.length > 0 &&
                    <div className={"current-hover-value"} style={{
                        color: closeLessThanOpen ? "red" : "rgb(14,138,0)"
                    }}>
                        <p>
                            <span className={"title-text-custom"}>{"O: "} </span>
                            <span className={"mr-2"}>{currentValue[1]?.open}</span>
                            <span className={"title-text-custom"}>{" C: "}</span>
                            <span className={"mr-2"}>{currentValue[1]?.close}</span>
                            <span className={"title-text-custom"}>{" L: "}</span>
                            <span className={"mr-2"}>{currentValue[1]?.low}</span>
                            <span className={"title-text-custom"}>{" H: "}</span>
                            <span>{currentValue[1]?.high}</span>

                        </p>
                        <p>
                            <span className={"title-text-custom"}>{t("Volume") + ": "}</span>
                            <span>{Utils.abbreviateNumber(currentValue[0])}</span>
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}

export default CandleChart
