import React, { useEffect } from 'react';

let KTScrolltop = require("assets/plugins/ktscrolltop");

ScrollTop.propTypes = {

};

function ScrollTop(props) {

    var scrollTopObject = null;

    useEffect(() => {
        if (typeof KTScrolltop !== "undefined") {
            scrollTopObject = new KTScrolltop("kt_scrolltop", {
                offset: 300,
                speed: 100
            });
        }
    }, []);

    return (
        <div id="kt_scrolltop" className="scrolltop">
            <i className="far fa-arrow-up"></i>
        </div>
    );
}

export default ScrollTop;