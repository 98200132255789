import { thunkGetHomeSectorIndexData } from 'app/appSlice';
import ModalAllSector from 'features/Others/components/ModalAllSector';
import ModalSectorChart from 'features/Stock/components/ModalSectorChart';
import MGDataTable from 'general/components/Tables/MGDataTable';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalMgIdentifies from '../ModalMgIdentifies';

function HomeSectionUpDownTrend(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const { realtimeIndexes, topMgIdentifies, sectorIndexData } = useSelector(state => state.app);
    const topMgIdentify = topMgIdentifies.length > 0 ? topMgIdentifies[0] : null;
    const [modalMGIdentifyShowing, setModalMGIdentifyShowing] = useState(false);
    const [modalAllSectorShowing, setModalAllSectorShowing] = useState(false);
    const loggedInUser = useSelector(state => state.auth.current);
    const isLoggedIn = !Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser);
    const [modalSectorChartShowing, setModalSectorChartShowing] = useState(false);
    const [selectedSectorItem, setSelectedSectorItem] = useState(null);
    const dispatch = useDispatch();

    // MARK: --- Hooks --- 
    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(thunkGetHomeSectorIndexData());
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    // MARK: --- Functions ---
    return (
        <div className='bg-white'>
            <div className='container-xxl py-20'>
                <div className='row'>
                    {
                        isLoggedIn && (
                            <div className='col-12 col-lg-4'>
                                <div>
                                    <SectionHeaderView
                                        title={t('IdentifyOfMG')}
                                        dividerVisible={false}
                                        buttonTitle={t('ViewHistory')}
                                        onPressButton={() => {
                                            setModalMGIdentifyShowing(true);
                                        }}
                                    />
                                    {
                                        !Utils.isObjectNull(topMgIdentify) ? (
                                            <div className='mt-2 border-top border-light py-4'>
                                                <div className='d-flex align-items-center'>
                                                    <p className='m-0 font-size-lg text-uppercase'>{t('MarketStatus')}</p>
                                                    <span className="label label-success label-inline ml-2 h-24px text-uppercase" style={{
                                                        borderRadius: '12px',
                                                        backgroundColor: topMgIdentify.status === 'POSITIVE' ? '#68C57C' : (topMgIdentify.status === 'NEGATIVE' ? '#F64E60' : '#3699FF')
                                                    }}>{t(topMgIdentify.status)}</span>
                                                </div>
                                                <div className='mt-2'>
                                                    <p className='m-0 mb-1 font-size-lg font-weight-bold text-justify'>{topMgIdentify.title}</p>
                                                    <p className='m-0 mb-2 font-size-lg text-justify'>{topMgIdentify.description}</p>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <p className='m-0 font-size-base text-justify font-weight-bold text-primary'>{topMgIdentify.author}</p>
                                                    &nbsp;
                                                    <p className='m-0 font-size-base text-justify font-italic'><span className=''>{`${t('Date')}: `}</span>{Utils.formatDateTime(topMgIdentify.datetime, 'DD/MM/YYYY HH:mm')}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        )
                    }

                    <div className={`col-12 ${isLoggedIn ? 'col-lg-8' : ''}`}>
                        <div className='row'>
                            <div className='col-12 col-xl-6'>
                                <SectionHeaderView
                                    title='Index'
                                    dividerVisible={false}
                                />
                                <MGDataTable
                                    className='bg-white mt-2'
                                    headItems={[
                                        { title: 'Index', subTitle: '', col: 'col-3' },
                                        { title: t('Price'), subTitle: '', col: 'col-3', textAlign: 'right' },
                                        { title: t('Change'), subTitle: '', col: 'col-3', textAlign: 'right' },
                                        { title: `${t('Change')} (%)`, subTitle: '', col: 'col-3', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        realtimeIndexes.map(item => {
                                            return {
                                                onPress: () => {
                                                    history.push(`/stock/${item?.stockCode}`);
                                                },
                                                data: [
                                                    { icon: '', title: item.stockCode, name: '', titleWeight: '600', value: '', valueColor: '', col: 'col-3', enableHoverTitle: true },
                                                    { icon: '', title: '', name: Utils.formatNumber((item?.price).toFixed(2)), value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(parseFloat(item.change).toFixed(2)), nameColor: Utils.getStockColor(item.changeInPercent), value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                                    { icon: '', title: '', name: `${Utils.formatNumber(parseFloat(item.changeInPercent).toFixed(2))} %`, nameColor: Utils.getStockColor(item.changeInPercent), value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                            <div className='col-12 col-xl-6 mt-10 mt-xl-0'>
                                <SectionHeaderView
                                    title={t('Sector')}
                                    dividerVisible={false}
                                    buttonTitle={t('ViewMore')}
                                    onPressButton={() => {
                                        setModalAllSectorShowing(true);
                                    }}
                                />
                                <MGDataTable
                                    className='bg-white mt-2'
                                    headItems={[
                                        { title: t('Sector'), subTitle: '', col: 'col-4' },
                                        { title: t('Volume'), subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: t('Change') + ' KL (%)', subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Price')}`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Change') + ' Giá'} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.slice(sectorIndexData, 0, realtimeIndexes.length).map((item, index) => {
                                            const { totalVolume, totalPrice } = item;
                                            const yesterdayTotalVolume = item?.yesterday?.totalVolume;
                                            const yesterdayTotalPrice = item?.yesterday?.totalPrice;
                                            let volumePerChange = '0';
                                            let pricePerChange = '0';
                                            if (totalVolume && yesterdayTotalVolume) {
                                                volumePerChange = ((totalVolume - yesterdayTotalVolume) * 100 / yesterdayTotalVolume).toFixed(2);
                                            }
                                            if (totalPrice && yesterdayTotalPrice) {
                                                pricePerChange = ((totalPrice - yesterdayTotalPrice) * 100 / yesterdayTotalPrice).toFixed(2);
                                            }

                                            return {
                                                onPress: () => {
                                                    setModalSectorChartShowing(true);
                                                    setSelectedSectorItem(item);
                                                },
                                                data: [
                                                    { icon: '', title: item.name, titleWeight: '600', name: '', value: '', valueColor: '', col: 'col-4', enableHoverTitle: true },
                                                    { icon: '', title: '', name: Utils.formatNumber(item.totalVolume), value: '', valueColor: Utils.getValueColor(volumePerChange), col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', value: '', name: `${volumePerChange > 0 ? '+' : ''}${Utils.formatNumber(volumePerChange)}%`, nameColor: Utils.getValueColor(volumePerChange), col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber((item.totalPrice / 1).toFixed(2)), value: ``, valueColor: Utils.getValueColor(pricePerChange), col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', value: '', name: `${pricePerChange > 0 ? '+' : ''}${Utils.formatNumber(pricePerChange)}%`, nameColor: Utils.getValueColor(pricePerChange), col: 'col-2', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal MG Identify */}
            <ModalMgIdentifies
                show={modalMGIdentifyShowing}
                onClose={() => {
                    setModalMGIdentifyShowing(false);
                }}
            />

            {/* Modal all sector */}
            <ModalAllSector
                show={modalAllSectorShowing}
                onClose={() => {
                    setModalAllSectorShowing(false);
                }}
            />

            {/* Modal sector chart */}
            <ModalSectorChart
                show={modalSectorChartShowing}
                sectorItem={selectedSectorItem}
                onClose={() => {
                    setModalSectorChartShowing(false);
                    setSelectedSectorItem(null);
                }}
            />
        </div >
    );
}

export default HomeSectionUpDownTrend;