import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CourseHomeScreen from './screens/CourseHomeScreen';
import CourseDetailScreen from './screens/CourseDetailScreen';
import NotFound from 'general/components/NotFound';

Course.propTypes = {

};

function Course(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                component={CourseHomeScreen}
            />
            <Route
                path={`${match.url}/:courseId`}
                component={CourseDetailScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Course;