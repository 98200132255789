import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import CellCourseGrid from 'features/Course/components/CellCourseGrid';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';

HomeSectionCourse.propTypes = {

};

function HomeSectionCourse(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { topCourses } = useSelector(state => state.app);
    const history = useHistory();

    return (
        <div className='HomeSectionCourse'>
            <div className='bg-white py-10'>
                <div className='container-xxl'>
                    <SectionHeaderView
                        title={t('FeaturedCourse')}
                        buttonTitle={t('ViewAll')}
                        onPressButton={() => {
                            history.push('/course');
                        }}
                    />

                    <div className='row'>
                        {
                            topCourses.length > 0 && topCourses.map((item, index) => {
                                return (
                                    <div key={index} className='col-12 col-sm-6 col-md-6 mt-6'>
                                        <CellCourseGrid info={item} />
                                    </div>
                                )
                            })
                        }

                        {
                            topCourses.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSectionCourse;