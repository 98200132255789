import stockApi from 'api/stockApi';
import { useFormik } from 'formik';
import AppTabs from 'general/components/AppTabs';
import BaseDropdownSelect from 'general/components/Forms/FormControls/BaseDropdownSelect';
import MGDataTable from 'general/components/Tables/MGDataTable';
import AppData from 'general/constants/AppData';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

ModalTopStocks.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalTopStocks.defaultProps = {
    show: false,
    onClose: null,
};

const currentDate = moment().local().format('YYYY-MM-DD');

function ModalTopStocks(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            option: 'CHANGE_ASC'
        },
        onSubmit: (values) => {

        }
    });
    const [filter, setFilter] = useState({
        date: currentDate,
        exchangeCode: '',
        sortBy: 'changeInPercent',
        sortDirection: 'DESC',
        page: 0,
        limit: Global.gDefaultPagination,
    });
    const [topStocks, setTopStocks] = useState([]);
    let exchangeCodes = [...AppData.allExchangeCode];
    exchangeCodes.unshift(t('All'));
    const [selectedTab, setSelectedTab] = useState(exchangeCodes[0]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    function handleSelectedTab(tab) {
        setFilter({
            ...filter,
            exchangeCode: AppData.allExchangeCode.includes(tab) ? tab : '',
        })
        setSelectedTab(tab);
    }

    function handlePressStock(stock) {
        history.push(`/stock/${stock.stockCode}`);
    }

    async function getRealtimeTopStocks() {
        setLoading(true);
        setTopStocks([]);
        let formattedFilter = { ...filter };
        Utils.removeEmptyKey(formattedFilter);
        const res = await stockApi.realtimeTopStocks(formattedFilter);
        const { result, data } = res;
        if (result === 'success' && data) {
            setTopStocks(data);
        }
        setLoading(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getRealtimeTopStocks();
    }, [filter]);

    useEffect(() => {
        const interval = setInterval(() => {
            getRealtimeTopStocks();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                // centered
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('TopStocks')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='bg-light overflow-auto' style={{
                    // height: "calc(100vh - 200px)"
                    maxHeight: '84vh'
                }}>
                    <div className='d-flex justify-content-between align-items-end'>
                        <AppTabs
                            tabs={exchangeCodes.reverse()}
                            selectedTab={selectedTab}
                            handleClick={handleSelectedTab}
                            className='flex-grow-1'
                        />
                        <div className='border-bottom pb-2'>
                            <BaseDropdownSelect
                                name='selectFilterOption'
                                label={t('Sort')}
                                options={AppData.sampleStockOptions}
                                fieldHelpers={formik.getFieldHelpers('option')}
                                fieldMeta={formik.getFieldMeta('option')}
                                fieldProps={formik.getFieldProps('option')}
                                onValueChanged={(value) => {
                                    console.log({ value });
                                    if (value === 'CHANGE_ASC') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'changeInPercent',
                                            sortDirection: 'DESC'
                                        })
                                    }
                                    if (value === 'CHANGE_DESC') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'changeInPercent',
                                            sortDirection: 'ASC'
                                        })
                                    }
                                    if (value === 'VOLUME') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'totalVolume',
                                            sortDirection: 'DESC'
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <MGDataTable
                        className='bg-white border-top-0'
                        loading={loading}
                        headItems={[
                            { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                            // { title: t('Transaction'), subTitle: '', col: 'col-1' },
                            { title: t('Price'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'end' },
                            { title: t('Change'), subTitle: '%', col: 'col-1' },
                            { title: t('Volume'), subTitle: `${t('Change')} (%)`, col: 'col-1' },
                            { title: t('TotalPoint'), subTitle: 'MG', col: 'col-1' },
                            { title: t('Strength'), subTitle: 'EPS', col: 'col-1' },
                            { title: t('Strength'), subTitle: t('Price'), col: 'col-1' },
                            { title: t('Ranking'), subTitle: t('Demand'), col: 'col-1' },
                            { title: t('Ranking'), subTitle: t('Group'), col: 'col-1' },
                        ]}
                        dataItems={
                            (selectedTab === t('All') ? topStocks : topStocks.filter(item => item.exchangeCode === selectedTab)).map((item, index) => {
                                return {
                                    onPress: () => {
                                        handlePressStock(item);
                                    },
                                    data: [
                                        { icon: '', title: item?.stockCode ?? item?.stockName ?? ' ', titleWeight: '600', subTitle: item.stockName, name: '', value: '', valueColor: '', col: 'col-3' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.closingPrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: `${!isNaN(parseFloat(item?.changeInPercent).toFixed(2)) ? Utils.formatNumber(parseFloat(item.changeInPercent).toFixed(2)) : '0'} %`, nameColor: Utils.getStockColor(parseFloat(item.changeInPercent)), value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.totalVolume), nameColor: '', value: `${Utils.formatNumber(parseFloat(item.changeOfVolumeInPercent).toFixed(2))} %`, valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.summaryStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.priceStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: item?.demandStrength, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                    ]
                                }
                            })
                        }
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalTopStocks;