import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'general/components/AppLayout';
import usePushNotifications from 'hooks/usePushNotifications';

TestPushNotificationScreen.propTypes = {

};

function TestPushNotificationScreen(props) {
    // MARK: --- Params ---
    const {
        userConsent,
        pushNotificationSupported,
        userSubscription,
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        error,
        loading
    } = usePushNotifications();
    const isConsentGranted = userConsent === 'granted';

    // MARK: --- Functions ---
    const handleAskUserPermission = () => {
        onClickAskUserPermission();
    };

    const handleSubscribeToPushNotification = () => {
        onClickSusbribeToPushNotification();
    };

    const handleSendSubscriptionToPushServer = () => {
        onClickSendSubscriptionToPushServer();
    };

    const handleSendNotification = () => {
        onClickSendNotification();
    };

    return (
        <AppLayout>
            <div className='bg-white min-h-600px py-10'>
                <div className='container-xxl d-flex flex-column align-items-center'>
                    <p className='font-size-lg'>{`Push notifications are ${!pushNotificationSupported ? 'NOT' : ''} supported by your device.`}</p>
                    <p className='font-size-lg'>User consent to receive push notifications is <strong>{userConsent}</strong>.</p>
                    <div className='d-flex flex-column align-items-center py-4'>
                        <button
                            type='button'
                            className='btn btn-primary mb-4'
                            disabled={!pushNotificationSupported || isConsentGranted}
                            onClick={handleAskUserPermission}
                        >
                            {isConsentGranted ? 'Consent Granted' : 'Ask User Permission'}
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary mb-4'
                            disabled={!pushNotificationSupported || !isConsentGranted || userSubscription}
                            onClick={handleSubscribeToPushNotification}
                        >
                            {userSubscription ? 'Push Notification Created' : 'Create Notification Subscription'}
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary mb-4'
                            disabled={!userSubscription || pushServerSubscriptionId}
                            onClick={handleSendSubscriptionToPushServer}
                        >
                            {pushServerSubscriptionId ? 'Subscription Sent To The Server' : 'Send Subscription To Push Server'}
                        </button>
                    </div>
                    {
                        pushServerSubscriptionId && (
                            <div className='d-flex flex-column align-items-center py-4'>
                                <p>The server accepted the push subscription!</p>
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSendNotification}
                                >
                                    Send A Notification
                                </button>
                            </div>
                        )
                    }

                    {/* Error */}
                    {
                        error && (
                            <div className='bg-light-danger p-6 rounded'>
                                <h6 className='text-danger'>{error.name}</h6>
                                <p className='text-danger'>Error message: {error.message}</p>
                                <p className='text-danger'>Error code: {error.code}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </AppLayout>
    );
}

export default TestPushNotificationScreen;