import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import AuthHeader from 'features/Auth/components/AuthHeader';
import AuthContent from 'features/Auth/components/AuthContent';
import { Card } from 'react-bootstrap';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import BaseCheckBox from 'general/components/Forms/FormControls/BaseCheckBox';
import * as Yup from 'yup';
import authApi from 'api/authApi';
import ToastHelper from 'general/helpers/ToastHelper';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Utils from "../../../../general/utils/Utils";

SignUpScreen.propTypes = {

};

const sTag = '[SignUpScreen]';

function SignUpScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rePassword: '',
            acceptTerms: true,
        },
        onSubmit: async (values) => {
            const params = { ...values };
            let hashPassword = Utils.sha256(params.password)
            params["password"] = hashPassword
            delete params['acceptTerms'];
            delete params['rePassword'];
            console.log(`${sTag} on submit: ${JSON.stringify(params)}`);

            // Sign up new account
            try {
                const res = await authApi.signUp(params);
                if (res) {
                    const { result, account } = res;
                    if (result === 'success') {
                        localStorage.setItem(PreferenceKeys.savedEmail, values.email);
                        localStorage.setItem(PreferenceKeys.savedPassword, /*values.password*/'');
                        ToastHelper.showSuccess(t('SignUpSuccess'));
                        history.push('/sign-in');
                    }
                }
            } catch (error) {
                console.log(`${sTag} sign up account error: ${error.message}`);
            }
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t('EmailIsRequired')).email(t('EmailNotValid')),
            password: Yup.string().required(t('PasswordIsRequired')).min(6, t('PasswordMinLength6')).matches(/^\S*$/, 'Mật khẩu không được chứa khoảng trắng'),
            rePassword: Yup.string().required(t('PasswordIsRequired')).oneOf([Yup.ref('password')], t('PasswordNotMatch')),
            acceptTerms: Yup.boolean().test('isTrue', t('AcceptTermsRequired'), (value) => value === true)
        }),
    });

    // MARK: --- Functions ---
    function handleSignUp(e) {
        e.preventDefault();
        formik.handleSubmit();
    }

    return (
        <div className='SignUpScreen min-vh-100 d-flex flex-column'>
            <AuthHeader />
            <AuthContent
                rightElement={(
                    <div>
                        <Card className='rounded-lg overflow-hidden'>
                            <Card.Header className='border-0'>
                                <p className='font-size-h3 font-weight-boldest m-0 text-center'>{t('SignUpNewAccount')}</p>
                            </Card.Header>
                            <Card.Body className='pt-0'>
                                <div className='pb-0'>
                                    <form onSubmit={formik.handleSubmit}>
                                        <BaseTextField
                                            name='email'
                                            placeholder={`${t('Email')}...`}
                                            label={t('Email')}
                                            fieldHelper={formik.getFieldHelpers('email')}
                                            fieldProps={formik.getFieldProps('email')}
                                            fieldMeta={formik.getFieldMeta('email')}
                                        />
                                        <BaseTextField
                                            name='password'
                                            placeholder={`${t('Password')}...`}
                                            label={t('Password')}
                                            type='password'
                                            fieldHelper={formik.getFieldHelpers('password')}
                                            fieldProps={formik.getFieldProps('password')}
                                            fieldMeta={formik.getFieldMeta('password')}
                                        />
                                        <BaseTextField
                                            name='rePassword'
                                            placeholder={`${t('RePassword')}...`}
                                            label={t('RePassword')}
                                            type='password'
                                            fieldHelper={formik.getFieldHelpers('rePassword')}
                                            fieldProps={formik.getFieldProps('rePassword')}
                                            fieldMeta={formik.getFieldMeta('rePassword')}
                                        />
                                        <BaseCheckBox
                                            name='checkboxTerms'
                                            label='Tôi đồng ý với những Điều khoản và điều kiện sử dụng cũng như Chính sách quyền riêng tư của MoneyGain'
                                            fieldHelper={formik.getFieldHelpers('acceptTerms')}
                                            fieldProps={formik.getFieldProps('acceptTerms')}
                                            fieldMeta={formik.getFieldMeta('acceptTerms')}
                                        />
                                        <a
                                            href="#"
                                            className="btn btn-primary btn-lg w-100 border-0 font-weight-bold"
                                            onClick={handleSignUp}
                                        >
                                            {t('SignUp')}
                                        </a>
                                        <div className='mt-4'>

                                        </div>
                                    </form>
                                </div>
                            </Card.Body>
                            <Card.Footer className='d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: '#F3F5F8', borderTopColor: '#DBE3EF' }}>
                                <p className='m-0 text-center font-weight-boldest font-size-h6'>Quay lại màn hình</p>
                                <button onClick={() => {
                                    history.push('/sign-in');
                                }} style={{ backgroundColor: '#4886FF' }} className="btn btn-linkedin btn-lg mt-4 w-100 border-0 hover-opacity-80 font-weight-bold">{t('SignIn')}</button>
                            </Card.Footer>
                        </Card>
                    </div>
                )}
            />

        </div>
    );
}

export default SignUpScreen;
