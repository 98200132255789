import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

Basic.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

Basic.defaultProps = {
    data: [],
    loading: false,
};

function Basic(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { data, loading } = props;
    const history = useHistory();

    return (
        <div className='border-left border-bottom border-right pt-3 bg-white'>
            <MGDataTable
                className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                loading={loading}
                headItems={[
                    { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                    { title: t('MarketPrice'), subTitle: 'VNĐ', col: 'col-1', textAlign: 'right' },
                    { title: `${t('Growth')} ${t('Revenue')}`, subTitle: `${t('LatestQuarter')}`, col: 'col-1', textAlign: 'right' },
                    { title: `${t('Growth')} ${t('Profit')}`, subTitle: `${t('LatestQuarter')}`, col: 'col-1', textAlign: 'right' },
                    { title: 'PE', subTitle: '', col: 'col-1', textAlign: 'right' },
                    { title: 'PB', subTitle: '', col: 'col-1', textAlign: 'right' },
                    { title: 'ROE', subTitle: '', col: 'col-1', textAlign: 'right' },
                    { title: 'ROA', subTitle: '', col: 'col-1', textAlign: 'right' },
                    { title: 'Beta', subTitle: '', col: 'col-1', textAlign: 'right' },
                ]}
                dataItems={
                    data.map((item, index) => {
                        const growthRevenue = parseFloat(item?.lastRevenue).toFixed(2);
                        const growthProfit = parseFloat(item?.lastProfit).toFixed(2);

                        const latestProfit = item?.lastestProfit ?? [];
                        const latestRevenue = item?.lastestRevenue ?? [];
                        let currentProfit = latestProfit.length > 0 ? latestProfit[0] : 0;
                        let prevProfit = latestProfit.length > 1 ? latestProfit[1] : 0;
                        let currentRevenue = latestRevenue.length > 0 ? latestRevenue[0] : 0;
                        let prevRevenue = latestRevenue.length > 1 ? latestRevenue[1] : 0;
                        const profitColor = currentProfit > prevProfit ? '#0BDF39' : '#FF0017';
                        const revenueColor = currentRevenue > prevRevenue ? '#0BDF39' : '#FF0017';

                        const profitPercent = prevProfit > 0 ? parseFloat((((currentProfit - prevProfit) * 100 / prevProfit))).toFixed(2) : '---';
                        const revenuePercent = prevRevenue > 0 ? parseFloat((((currentRevenue - prevRevenue) * 100 / prevRevenue))).toFixed(2) : '---';

                        return {
                            onPress: () => {
                                history.push(`/stock/${item?.stockCode}`);
                            },
                            data: [
                                { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item?.companyName, name: '', value: '', valueColor: '', col: 'col-3' },
                                { icon: '', title: '', name: Utils.formatNumber(item.marketPrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: `${revenuePercent > 0 ? '+' : ''}${revenuePercent}%`, nameColor: revenueColor, value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: `${profitPercent > 0 ? '+' : ''}${profitPercent}%`, nameColor: profitColor, value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: !isNaN(parseFloat(item?.pe).toFixed(2)) && Utils.formatNumber(parseFloat(item?.pe).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: !isNaN(parseFloat(item?.pb).toFixed(2)) && Utils.formatNumber(parseFloat(item?.pb).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: Utils.formatNumber(item?.roe ?? 0), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: Utils.formatNumber(item?.roa ?? 0), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: Utils.formatNumber(item?.beta ?? 0), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                            ]
                        }
                    })
                }
            />
        </div>
    );
}

export default Basic;
