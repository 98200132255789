import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WebinarDetailScreen from './screens/WebinarDetailScreen';
import NotFound from 'general/components/NotFound';
import WebinarHomeScreen from './screens/WebinarHomeScreen';

Webinar.propTypes = {

};

function Webinar(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                component={WebinarHomeScreen}
            />
            <Route
                path={`${match.url}/:webinarId`}
                component={WebinarDetailScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Webinar;