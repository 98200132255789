import { removeAxiosAccessToken } from "api/axiosClient";
import { signOutFirebase } from "app/FirebaseListener";
import store from "app/store";
import PreferenceKeys from "general/constants/PreferenceKeys";
import moment from 'moment';
import { element } from "prop-types";

const UserHelper = {
    // Get random avatar url
    getRandomAvatarUrl: () => {
        return 'https://blenderartists.org/uploads/default/original/4X/6/a/d/6adcaac6f7378fbf998f5ea0490724cea82eb01f.jpeg';
    },

    // Check access token valid
    checkAccessTokenValid: () => {
        const accessToken = localStorage.getItem(PreferenceKeys.accessToken);
        const accessTokenExpired = localStorage.getItem(PreferenceKeys.accessTokenExpired);

        if (accessToken && accessTokenExpired) {
            const momentExpired = moment.utc(accessTokenExpired);
            const momentNow = moment.utc();
            return momentExpired.isAfter(momentNow);
        }

        return false;
    },

    // Get display name
    getDisplayName: (account) => {
        if (account) {
            return account.fullname ?? account.email ?? 'Unknown';
        }
        return '';
    },

    // Get avatar
    getAvatar: (account) => {
        if (account) {
            return account.avatar ?? UserHelper.getRandomAvatarUrl();
        }
        return UserHelper.getRandomAvatarUrl();
    },

    // Sign out
    signOut: () => {
        signOutFirebase();
        localStorage.removeItem(PreferenceKeys.accessToken);
        localStorage.removeItem(PreferenceKeys.accessTokenExpired);
        localStorage.removeItem(PreferenceKeys.fbAccessToken);
        localStorage.removeItem(PreferenceKeys.googleAccessToken);
        removeAxiosAccessToken();
    },

    checkWebinarStatus: (webinarId) => {
        let bRet = undefined;
        const allWebinarTransactions = store.getState().account.allTransactions.filter(item => item.type === 'WEBINAR');
        allWebinarTransactions.forEach(element => {
            if (element.webinarId == webinarId) {
                bRet = element.status;
            }
        });
        if (bRet === undefined) {
            const allWebinars = store.getState().account.allWebinars;
            allWebinars.forEach(element => {
                if (element.webinarId == webinarId) {
                    bRet = 'SUCCESS';
                }
            });
        }
        return bRet;
    },

    checkCourseStatus: (courseId) => {
        let bRet = undefined;
        const allCourseTransactions = store.getState().account.allTransactions.filter(item => item.type === 'COURSE');
        allCourseTransactions.forEach(element => {
            if (element.courseId == courseId) {
                bRet = element.status;
            }
        });
        return bRet;
    },
};

export default UserHelper;