import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFound from 'general/components/NotFound';
import MyListHomeScreen from './screens/MyListHomeScreen';

MyList.propTypes = {

};

function MyList(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match.url}`}
                component={MyListHomeScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default MyList;