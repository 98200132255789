import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFound from 'general/components/NotFound';
import TestPushNotificationScreen from './screens/TestPushNotificationScreen';

Notification.propTypes = {

};

function Notification(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match.url}/test`}
                component={TestPushNotificationScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Notification;