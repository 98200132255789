import myListApi from 'api/myListApi';
import { addNewList } from 'features/MyList/myListSlice';
import { useFormik } from 'formik';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import ToastHelper from 'general/helpers/ToastHelper';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

ModalCreateMyList.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalCreateMyList.defaultProps = {
    show: false,
    onClose: null,
};

function ModalCreateMyList(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const { allMyLists } = useSelector(state => state.myList);
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => {
            for (let i = 0; i < allMyLists.length; i++)
                if (allMyLists[i].name === values.name) {
                    ToastHelper.showError(`${t('MyListNameExisted')}`)
                    return
                }
            createNewList(values.name);
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('Required')),
        }),
    });
    const dispatch = useDispatch();

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
            formik.handleReset();
        }
    }

    async function createNewList(listName) {
        const res = await myListApi.createMyList(listName);
        const { result, detail } = res;
        if (result === 'success' && detail) {
            ToastHelper.showSuccess(t('CreateListSuccess'));
            dispatch(addNewList(detail));
            formik.handleReset();
            handleClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('CreateMyList')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='bg-light'>
                    <div>
                        <BaseTextField
                            label={t('ListName')}
                            placeholder={`${t('ListName')}...`}
                            name='listName'
                            fieldHelper={formik.getFieldHelpers('name')}
                            fieldProps={formik.getFieldProps('name')}
                            fieldMeta={formik.getFieldMeta('name')}
                            className=''
                            spellCheck={false}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer className=''>
                    <div className='w-100 d-flex row'>
                        <Button className='font-weight-bold flex-grow-1 col mr-3' variant="secondary" onClick={() => {
                            handleClose();
                        }}>
                            {t('Cancel')}
                        </Button>
                        <Button className='font-weight-bold flex-grow-1 col ml-3' variant="primary" onClick={() => {
                            formik.handleSubmit();
                        }}>
                            {t('CreateMyList')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalCreateMyList;