import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';
import { useTranslation } from 'react-i18next';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import CellStockList from 'features/Stock/components/CellStockList';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { thunkAddStock } from './../../watchlistSlice';
import ToastHelper from './../../../../general/helpers/ToastHelper';
import stockApi from 'api/stockApi';

ModalAddWatchlistStock.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    watchlistId: PropTypes.number
};

ModalAddWatchlistStock.defaultProps = {
    show: false,
    onClose: null,
    watchlistId: 0
};

function ModalAddWatchlistStock(props) {
    // MARK: --- Params ---
    const { show, onClose, watchlistId } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()
    const [loadingStock, setLoadingStock] = useState(false);
    const [stocks, setStocks] = useState([]);
    const [paginationStock, setPaginationStock] = useState(null);
    const [filterStock, setFilterStock] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
        searchKey: '',
    });
    const { isLoadingWatchList, selectedWatchlist } = useSelector(state => state.watchlist);
    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    // search stock
    async function searchStock() {
        setLoadingStock(true);
        const res = await stockApi.getListStockCode(filterStock);
        const { result, total, count, page } = res;
        const searchedStocks = res.stocks;
        if (result === 'success' && searchedStocks) {
            if (page > 0) {
                setStocks(stocks.concat(searchedStocks));
            } else {
                setStocks(searchedStocks);
            }
            setPaginationStock({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoadingStock(false);
    }

    // handle scroll content
    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 20;
        if (bottom) {
            if (!loadingStock) {
                let nextPage = Utils.getNextPage(paginationStock);
                console.log({ nextPage });
                if (nextPage) {
                    setFilterStock({
                        ...filterStock,
                        page: nextPage,
                    });
                }
            }
        }
    }

    useEffect(() => {
        searchStock();
    }, [filterStock]);

    return (
        <div>
            <Modal
                className=''
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
                onExit={() => {
                    setFilterStock({
                        limit: Global.gDefaultPagination,
                        page: 0,
                        searchKey: '',
                    });
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('AddStockToWatchList')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='max-h-600px p-0 q-hide-scrollbar pb-4' onScroll={handleScroll} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <div className='bg-white p-5 position-sticky sticky-top border-bottom mr-0'>
                        <BaseSearchBar
                            name='searchStock'
                            placeholder={`${t('TypeToSearch')}...`}
                            onSubmit={(text) => {
                                setFilterStock({
                                    ...filterStock,
                                    page: 0,
                                    searchKey: text
                                })
                            }}
                        />
                    </div>

                    <div className=' mt-4'>
                        {
                            stocks.map((item, index) => {
                                return (
                                    <CellStockList
                                        key={index}
                                        item={item}
                                        onPress={() => {
                                            for (let i = 0; i < selectedWatchlist.listStock.length; i++)
                                                if (selectedWatchlist.listStock[i].stockCode === item.stockCode) {
                                                    ToastHelper.showError(`${t('ThisStockCodeIsAlreadyInYourWatchlist')}`)
                                                    return
                                                }
                                            dispatch(thunkAddStock({ watchlistId: watchlistId, stockCode: item.stockCode }))
                                        }}
                                        showDivider={index < stocks.length - 1}
                                        className='px-5'
                                    />
                                )
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalAddWatchlistStock;