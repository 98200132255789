import productApi from 'api/productApi';
import AppLayout from 'general/components/AppLayout';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellProduct from '../../components/CellProduct';
import './style.scss';

const sTag = '[ListProductScreen]';

function ListProductScreen(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        searchKey: '',
    });
    const [pagination, setPagination] = useState(null);



    async function getAllProduct() {
        if (loading) {
            return;
        }
        setLoading(true);
        const res = await productApi.getProductList(filter);
        const { result, total, count, page, products } = res;
        console.log(res);
        if (result === 'success' && products) {
            if (page !== null && page === 0) {
                setAllProducts(products);
            } else {
                setAllProducts(allProducts.concat(products));
            }
            setPagination({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoading(false);
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(pagination);
            console.log({ nextPage });
            if (nextPage) {
                setFilter({
                    ...filter,
                    page: nextPage,
                });
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getAllProduct();
    }, [filter]);

    return (
        <AppLayout>
            <div className='bg-white min-h-600px'>
                <div className='container-xxl'>
                    <img src={AppImages.imgListProductScreen} className='w-100 py-10'
                        draggable={false} alt='' />
                    <div className='container-xxl border-bottom pb-5 row mb-5 d-md-flex justify-content-between align-items-end mx-0 px-0'>
                        <p className='ListProductScreen_Title_Header'>{t('MyProducts')}</p>
                        <BaseSearchBar placeholder={`${t('TypeToSearch')}...`}
                            name='searchProduct'
                            onSubmit={(text) => {
                                setFilter({
                                    ...filter,
                                    page: 0,
                                    searchKey: text,
                                })
                            }}
                        />
                    </div>
                    <div className='row'>
                        {
                            allProducts.length > 0 && allProducts.map((items, index) => {
                                return (
                                    <CellProduct key={index}
                                        title={items.name}
                                        description={items.description}
                                        thumbnail={items.thumbnail}
                                        url={items.url}
                                    />
                                )
                            })
                        }

                        {
                            allProducts.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                        loading={loading}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default ListProductScreen;