import React from 'react';
import PropTypes from 'prop-types';
import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UserHelper from 'general/helpers/UserHelper';
import CellAccountMenuGrid from 'features/Account/components/CellAccountMenuGrid';
import AppData from 'general/constants/AppData';

AccountHomeScreen.propTypes = {

};

function AccountHomeScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const loggedInUser = useSelector(state => state.auth.current);

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className=''>
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{`${t('Hello')}, ${UserHelper.getDisplayName(loggedInUser)}`}</p>
                    <p className='font-size-lg text-center m-0'>{t('AccountMenuDescription')}</p>
                </div>
                <div className='row mt-0 mt-md-4 d-flex justify-content-center'>
                    {
                        AppData.accountMenuItems.map((item, index) => {
                            return (
                                <div key={index} className='col-12 col-md-6 col-lg-4 mt-7'>
                                    <CellAccountMenuGrid item={item} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </AccountBaseLayout>
    );
}

export default AccountHomeScreen;