import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useTranslation } from 'react-i18next';

AuthContent.propTypes = {
    rightElement: PropTypes.element,
    leftTitle: PropTypes.string,
    leftDescription: PropTypes.string,
};

AuthContent.defaultProps = {
    rightElement: (<div></div>),
    leftTitle: 'Đầu tư hiệu quả với MoneyGain Platform',
    leftDescription: 'Trải nghiệm đầu tư theo cách hoàn toàn mới. Chúng tôi tin tưởng bạn sẽ đạt được nhiều hơn những gì mong đợi.Hãy bắt đầu trải nghiệm ngay.',
};

function AuthContent(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { rightElement, leftTitle, leftDescription } = props;

    return (
        <div className='AuthContent d-flex flex-grow-1 align-items-center'>
            <div className='container-xxl'>
                <div className='row pb-6'>
                    {/* Left */}
                    <div className='col-12 col-md-5 d-flex flex-column justify-content-center'>
                        <p className='AuthContent_TextTitle text-white text-center text-md-left mb-2'>{leftTitle}</p>
                        <p className='AuthContent_TextDescription text-white text-center text-md-left'>{leftDescription}</p>
                    </div>

                    <div className='col-12 col-md-1'></div>

                    {/* Right */}
                    <div className='col'>
                        {rightElement}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthContent;