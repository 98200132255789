import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Utils from 'general/utils/Utils';
import AppData from 'general/constants/AppData';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppImages } from 'general/constants/AppResource';
import _ from 'lodash';

CellArticleList.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
    showDivider: PropTypes.bool,
    imgMl: PropTypes.bool,
};

CellArticleList.defaultProps = {
    item: {},
    onPress: null,
    showDivider: false,
    imgMl: false,
};

function CellArticleList(props) {
    // MARK: --- Params ---
    const { item, onPress, showDivider, imgMl } = props;
    const { title, description, author, category, content, date, thumbnail, articleId } = item;
    const { t } = useTranslation();
    const history = useHistory();

    // MARK: --- Functions ---
    function handleClick() {
        if (onPress) {
            onPress();
        } else {
            history.push(`/article/${articleId}`);
        }
    }

    return (
        <div
            className={`CellArticleList d-flex cursor-pointer hover-opacity-60 ${showDivider && 'border-bottom pb-5'} ${imgMl && 'px-5'}`}
            onClick={handleClick}
        >
            <img
                className={`CellArticleList_Image w-160px h-100px rounded min-w-160px min-h-100px border`}
                alt='thumbnail'
                style={{ objectFit: 'cover' }}
                src={thumbnail ?? AppImages.imgDefaultThumbnail}
                onError={(e) => {
                    e.target.src = AppImages.imgDefaultThumbnail;
                }}
                draggable={false}
            />
            <div className='ml-4'>
                <p className='CellArticleList_Title font-size-lg font-weight-bolder m-0 q-max-line-2 mb-1'>{title}</p>
                <p className='CellArticleList_Content m-0 q-max-line-2 mb-1'>{description}</p>
                {
                    category && (
                        <p className='my-1 font-weight-bold text-muted'>{`${t('Category')}: `}<span className='font-size-lg font-weight-bolder text-dark-75'>{category.name}</span></p>
                    )
                }
                <p className='CellArticleList_Time font-weight-normal m-0 mb-1'>
                    {`${Utils.formatDateTime(date, 'DD/MM/YYYY HH:mm')}`}
                    {
                        !_.isEmpty(author) && (
                            <span className='font-weight-bold text-dark-75'>{` | ${t('By')}: ${author}`}</span>
                        )
                    }
                </p>
            </div>
        </div>
    );
}

export default CellArticleList;