import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

import './index.scss';
import Utils from 'general/utils/Utils';

Section_4.propTypes = {
    processOfIncreasingCharteredCapitals: PropTypes.array,
};

Section_4.defaultProps = {
    processOfIncreasingCharteredCapitals: [],
};

function Section_4(props) {
    // MARK: --- Params ---
    const { processOfIncreasingCharteredCapitals } = props;
    // console.log({ processOfIncreasingCharteredCapitals });
    const { t } = useTranslation();
    const series = [{
        name: t('AuthorizedCapital'),
        data: processOfIncreasingCharteredCapitals.map(item => Math.round(item.charteredCapital / 1000000000))
    }];
    const options = {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        xaxis: {
            categories: processOfIncreasingCharteredCapitals.map(item => Utils.formatMonthYear(item.dateChange)),
        }
    };

    return (
        <div className="bg-white border mt-4 p-4">
            {/* <div id="LineChart"></div> */}
            <div className='d-flex align-items-center justify-content-between px-5'>
                <p className="m-0" style={{
                    fontSize: '1.08rem',
                    fontWeight: '600',
                    lineHeight: '1.85rem'
                }}>{t('IncreasingCharteredCapitals')}</p>

                <div className="py-3 px-4 d-flex align-items-center">
                    <div className="w-20px h-20px rounded" style={{ background: '#008FFB' }}></div>
                    <p className="m-0 ml-3" align='center-left'>
                        {`${t('AuthorizedCapital')} (Tỷ VNĐ)`}
                    </p>
                </div>
            </div>

            <Chart
                className=''
                options={options}
                series={series}
                type="line"
                width='100%'
                height={350}
            />
        </div>
    )
};

export default Section_4;
