// quybka
import icFlagEn from "assets/icons/flags/ic_flag_en.svg";
import icFlagUs from "assets/icons/flags/ic_flag_us.svg";
import icFlagVi from "assets/icons/flags/ic_flag_vi.svg";
import icMGLogo from "assets/icons/ic_mg_logo.svg";
import icMGProduct from "assets/icons/ic_mg_product.svg";
import icAccountInfo from "assets/icons/ic_account_info.svg";
import icAccountReferral from "assets/icons/ic_account_referral.svg";
import icAccountCourse from "assets/icons/ic_account_course.svg";
import icAccountTransaction from "assets/icons/ic_account_transaction.svg";
import icAccountSecurity from "assets/icons/ic_account_security.svg";
import icAccountMyList from "assets/icons/ic_account_my_list.svg";
import icMenuHandle from "assets/icons/ic_menu_handle.svg";
import icLocation from "assets/icons/ic_contact_us_location.svg";
import icMail from "assets/icons/ic_contact_us_mail.svg";
import icPhone from "assets/icons/ic_contact_us_phone.svg";
import icHotNumber from "assets/icons/ic_contact_us_hot_number.svg";
import icZaloLogo from "assets/icons/ic_zalo_logo.svg";
import icLock from "assets/icons/ico_lock.svg";
import icCirclePlay from "assets/icons/ico_circle_play.svg";
import icShare from "assets/icons/ic_share.svg";
import icCircleUser from "assets/icons/ic_circle_user.svg";
import icBarChartUp from "assets/icons/ic_bar_chart_up.svg";
import icSquareList from "assets/icons/ic_square_list.svg";
import icPurplePlus from "assets/icons/ic_purple_plus.svg";
import icGear from "assets/icons/ic_gear.svg";
import icSquarePen from "assets/icons/ic_square_pen.svg";
import icSquareXMark from "assets/icons/ic_square_x_mark.svg";
import icWhitePlus from "assets/icons/ic_white_plus.svg";
import icArrowDownShortWide from "assets/icons/ic_arrow_down_short_wide.svg";
import icPriceTag from "assets/icons/ic_price_tag.svg";
import icConfirmTick from "assets/icons/ic_confirm_tick.svg";
import icCloseTick from "assets/icons/ic_close_tick.svg";
import icEmptyPack from "assets/icons/ic_empty_pack.svg";
import icCheck from "assets/icons/ic_check.svg";
import icTrash from "assets/icons/ic_trash.svg";
import icTrienVong from "assets/icons/ic_trienvong.svg";
import icCoPhieuChonLoc from "assets/icons/ic_cophieuchonloc.svg";
import icHocVien from "assets/icons/ic_hocvien.svg";
import icPhanTich from "assets/icons/ic_phantich.svg";

// Icons
export const AppIcons = {
  icFlagEn,
  icFlagUs,
  icFlagVi,
  icMGLogo,
  icMGProduct,
  icAccountInfo,
  icAccountReferral,
  icAccountCourse,
  icAccountTransaction,
  icAccountSecurity,
  icAccountMyList,
  icMenuHandle,
  icLocation,
  icMail,
  icPhone,
  icHotNumber,
  icZaloLogo,
  icLock,
  icCirclePlay,
  icShare,
  icCircleUser,
  icBarChartUp,
  icSquareList,
  icPurplePlus,
  icGear,
  icSquarePen,
  icSquareXMark,
  icWhitePlus,
  icArrowDownShortWide,
  icPriceTag,
  icConfirmTick,
  icCloseTick,
  icEmptyPack,
  icCheck,
  icTrash,
  icTrienVong,
  icCoPhieuChonLoc,
  icHocVien,
  icPhanTich
};

// Images
export const AppImages = {
  img404: require("assets/images/404.jpg"),
  imgLandingPage01: require("assets/images/img_landingpage_01.png"),
  imgLandingPage02: require("assets/images/img_landingpage_02.png"),
  imgLandingPage04: require("assets/images/img_landingpage_04.png"),
  imgLandingPageChart01: require("assets/images/img_landingpage_chart_01.png"),
  imgLandingPageChart02: require("assets/images/img_landingpage_chart_02.png"),
  imgLandingPageChart03: require("assets/images/img_landingpage_chart_03.png"),
  imgHomeWebinarBg: require("assets/images/img_home_webinar_bg.png"),
  imgWebinarThumbnailDefault: require("assets/images/img_webinar_thumbnail_default.jpg"),
  imgDefaultAvatar: require("assets/images/img_default_avatar.jpg"),
  imgAboutUs01: require("assets/images/img_about_us_01.png"),
  imgAboutUs03: require("assets/images/img_about_us_03.png"),
  imgKCBookBanner: require("assets/images/img_kc_book_banner.png"),
  imgKCArticleBanner: require("assets/images/img_kc_article_banner.png"),
  imgKCInvestBanner: require("assets/images/img_kc_invest_banner.png"),
  imgEmptyBookCover: require("assets/images/img_empty_book_cover.jpg"),
  imgDefaultThumbnail: require("assets/images/img_default_thumbnail.png"),
  imgDefaultThumbnailVideo: require("assets/images/img_default_thumbnail_video.png"),
  imgListProductScreen: require("assets/images/img_list_product_screen.png"),
  imgListProductScreenTest: require("assets/images/img_list_product_screen_test.png"),
  imgProductBG: require('assets/images/img_product_bg.png'),
  imgPurchasedCourseBottomBanner: require("assets/images/purchased_course_bottom_banner.png"),
  imgCourseHeader: require('assets/images/img_course_header.png'),
  imgCourseBottomBanner: require("assets/images/course_bottom_banner.png"),
  imgPurchasedCourseTag: require("assets/images/purchased_course_tag.png"),
  imgZigZac: require("assets/images/zigzac.png"),
  imgTechcombank: require("assets/images/img_techcombank.png"),
  imgVPbank: require("assets/images/img_vpbank.png"),
  imgTPbank: require("assets/images/img_tpbank.png"),
  imgVietcombank: require("assets/images/img_vietcombank.png"),
  imgPaymentSuccess: require("assets/images/img_payment_success.png"),
  imgRegisterWebinarBackground: require("assets/images/img_register_webinar_background.png"),
  imgRegisterWebinarSuccess: require("assets/images/img_register_webinar_success.png"),
  imgRegisterWebinarFail: require("assets/images/img_register_webinar_fail.png"),
  imgVideoThumbnail: require("assets/images/img_video_thumbnail.jpg"),
};
