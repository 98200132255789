import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

TextFieldNoFormik.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    spellCheck: PropTypes.bool,

    onSubmit: PropTypes.func,
    subText: PropTypes.string,
    isError: PropTypes.bool,
};

TextFieldNoFormik.defaultProps = {
    type: "text",
    label: "",
    value: '',
    placeholder: "",
    disabled: false,
    spellCheck: false,

    className: 'form-group',

    onSubmit: null,
    subText: '',
    isError: false,
};

function TextFieldNoFormik(props) {
    // MARK: --- Params ---
    const { name, type, value, label, placeholder, disabled, className, spellCheck, onSubmit, subText, isError } = props;
    // console.log({ value });
    const typingTimeoutRef = useRef(null);
    const [text, setText] = useState(value);

    // MARK: --- Functions ---
    function handleTextChanged(e) {
        const value = e.target.value;
        setText(value);

        if (!onSubmit) {
            return;
        }

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            onSubmit(value);
        }, 500);
    }

    return (
        <div className='TextFieldNoFormik'>
            <div className={className}>
                {
                    label && (<label htmlFor={name}>{label}</label>)
                }
                <input
                    className={`form-control ${isError && 'is-invalid'}`}
                    id={name}
                    disabled={disabled}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    spellCheck={spellCheck}
                    value={text}
                    onChange={handleTextChanged}
                />
                {
                    subText.length > 0 && (
                        <span className='form-text text-muted'>{subText}</span>
                    )
                }
                {
                    isError && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{t('Invalid')}</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default TextFieldNoFormik;