import {Modal} from "react-bootstrap";
import React, {createRef, useLayoutEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {useDispatch} from "react-redux";
import {AppIcons} from "../../../../../general/constants/AppResource";
import {thunkRemoveAlert, thunkUpdateAlert} from "../../../IdeaListSlice";
import ToastHelper from "../../../../../general/helpers/ToastHelper";

const RemoveAlertModalRef = createRef()

export default {
    show: (alertId) => {
        RemoveAlertModalRef.current?.show(alertId)
    },
    hide: () => {
        RemoveAlertModalRef.current?.hide()
    }
}

export function RemoveAlertModalComponent() {
    const {t} = useTranslation();
    const [id, setId] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    useLayoutEffect(() => {
        RemoveAlertModalRef.current = {
            'show': (alertId) => {
                setId(alertId)
                setModalVisible(true)
            },
            'hide': () => setModalVisible(false)
        }
    }, [])

    const handleDeleteAlert = async () => {
        try {
            await dispatch(thunkRemoveAlert({
                alertId: id
            })).then((res) => {
                if (res?.payload?.result == "success") {
                    ToastHelper.showSuccess(t("DeleteAlertSuccess"))
                    setModalVisible(false)
                } else {
                    ToastHelper.showError(t("DeleteAlertFailed"))
                }
            })
        } catch (e) {
            ToastHelper.showError(e)
        }
    }

    return (
        <>
            <Modal
                centered={true}
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                backdrop={true}
                keyboard={false}
                size={"md"}
            >
                <Modal.Header style={{height: "50px"}}>
                    <Modal.Title>{t("DeleteAlert")}</Modal.Title>
                    <div className={"custom-right-component-modal"}>
                        <div className={"custom-div-chart close-button hover-opacity-50"}
                             onClick={() => setModalVisible(false)}>
                            <div className={"custom-close-button"}>
                                <i className="far fa-times icon-xl font-weight-light"/>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='q-hide-scrollbar rounded-bottom' style={{
                    backgroundColor: "#F3F5F8",
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    alignItems: "center"
                }} >
                    <div className='font-size-h6 d-flex flex-row' style={{
                        alignItems: "center"
                    }}>
                        <i className={"fas fa-trash-alt icon-3x"} />
                        <span className={"ml-3"}>{t("DeleteAlertDesc")}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex flex-row justify-content-center' style={{width: "100%"}}>
                        <button
                            className='border-0 col-5 mx-5 btn-secondary rounded-sm py-3 font-weight-bolder font-size-base'
                            onClick={() => {
                                setModalVisible(false)
                            }}>{t('Cancel')}</button>
                        <button
                            className='border-0 col-5 mx-5 btn-primary rounded-sm py-3 font-weight-bolder font-size-base'
                            onClick={() => handleDeleteAlert()}
                        >{t('Delete')}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

