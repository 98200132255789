import accountApi from 'api/accountApi';
import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './style.scss';

function AccountCourseScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const [filter, setFilter] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
        type: 'COURSE',
        orderField: 'paymentId',
        orderMode: 'DESC'
    });
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    async function getTransactionHistory() {
        setLoading(true);
        const res = await accountApi.getTransactionHistory(filter);
        const { result, transactions, count, page, total } = res;
        if (result === 'success' && transactions) {
            setTransactions(transactions);
        }
        setLoading(false);
    }

    function handleClickTransactionItem(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                history.push(`/course/${course.courseId}`);
            }
        }
        if (type === 'WEBINAR') {
            const webinar = item.webinar;
            if (webinar) {
                history.push(`/knowledge-center/webinar/${webinar.webinarId}`);
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getTransactionHistory();
    }, [filter]);

    function getTransacltionDescription(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                return course.description;
            }
        }
        return '';
    }

    function getTransacltionName(item) {
        const { type } = item;
        if (type === 'COURSE') {
            const course = item.course;
            if (course) {
                return course.name;
            }
        }
        return '';
    }

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className='d-flex flex-column align-items-center pb-10'>
                    <img
                        className='mb-6'
                        src={AppIcons.icAccountCourse}
                        alt='icon'
                        draggable={false}
                    />
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{t('AccountCourseTitle').toUpperCase()}</p>
                    <p className='font-size-lg text-center m-0'>{t('AccountCourseDescription')}</p>
                </div>
            </div>
            {/* dung */}
            <div className='d-block overflow-auto max-h-450px border container p-0 col-10'>
                {
                    transactions.length > 0 ? (
                        <table className='table table-hover cursor-pointer m-0'>
                            <thead className='position-sticky top-0 zindex-1 AccountCourseScreen_Table_Header'>
                                <tr>
                                    <th style={{ whiteSpace: 'nowrap' }}>{t('TransactionId')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>{t('CourseName')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Description')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Time')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Status')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('PaymentMethod')}</th>
                                    <th style={{ whiteSpace: 'nowrap' }} className=''>{t('Price')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transactions.map((item, index) => {
                                        return (
                                            <tr
                                                key={index} className='cursor-pointer'
                                                onClick={() => {
                                                    handleClickTransactionItem(item);
                                                }}
                                            >
                                                <td className='col-1 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {item?.paymentTransactionId}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns font-weight-bolder font-size-lg text-primary'>
                                                    {getTransacltionName(item)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {getTransacltionDescription(item)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {Utils.formatDateTime(item.time, 'DD/MM/YYYY HH:mm')}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns' style={{
                                                    color: Utils.getTransactionStatusColor(item?.status)
                                                }}>
                                                    {t(item?.status)}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {item?.paymentChannel}
                                                </td>
                                                <td className='col-2 AccountTransactionScreen_Table_Body_OtherColumns'>
                                                    {`${Utils.formatNumber(item?.course?.price)} VNĐ`}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    ) : (
                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                            <EmptyView
                                description='Bạn chưa mua khoá học nào'
                                iconEmpty={AppIcons.icEmptyPack}
                                loading={loading}
                            />
                        </div>
                    )
                }
            </div>
        </AccountBaseLayout>
    );
}

export default AccountCourseScreen;