import BillInfor from "features/Payment/components/BillInfor";
import ProductInfor from "features/Payment/components/ProductInfor";
import AppLayout from "general/components/AppLayout";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { thunkGetBankAccountList } from "../../paymentSlice";
import "./style.scss";

PaymentScreen.propTypes = {};

const sTag = "[PaymentScreen]";

function PaymentScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const location = useLocation();
  let product = location.state;
  const bankList = useSelector(state => state?.payment?.bankAccount?.list)
  const dispatch = useDispatch()

  // MARK: --- Hooks ---
  useEffect(() => {
    if (!bankList?.length > 0) {
      dispatch(thunkGetBankAccountList())
    }
  }, []);

  return (
    <AppLayout>
      <div className="container-xxl">
        <div className="row my-6">
          <div className=" col-md-8">
            <BillInfor product={product} />
          </div>

          <div className="col-md-4 mt-4 mt-md-0 mb-4 mb-md-0">
            <ProductInfor product={product} />
          </div>
        </div>
      </div>

    </AppLayout>
  );
}

export default PaymentScreen;
