import axiosClient from "./axiosClient";

const stockApi = {
    stockHistory: (params) => {
        const url = `/history/${params?.stockCode}`;
        return axiosClient.get(url, { params });
    },

    stockRealtime: (params) => {
        const url = `/realtime/${params?.stockCode}`;
        delete params.stockCode
        return axiosClient.get(url, { params });
    },

    // realtime index
    realtimeIndex: () => {
        const url = '/realtime/index';
        return axiosClient.post(url);
    },

    // realtime top stocks
    realtimeTopStocks: (params) => {
        const url = '/realtime/topStocks';
        return axiosClient.get(url, { params });
    },

    getListStockCode: (params) => {
        const url = '/stock/all';
        return axiosClient.get(url, { params });
    },

    // get warrant data
    // thong tin chung quyen
    getWarrantData: (stockCode, params) => {
        const url = `/stock/warrant/${stockCode}`;
        return axiosClient.get(url, { params });
    },

    // get report data
    // bao cao tai chinh
    getReportData: (stockCode, years, termCodes) => {
        const url = '/company/report/';
        return axiosClient.post(url, {
            stockCode: stockCode,
            years: years,
            termCodes: termCodes,
        });
    },

    // realtime change
    getRealtimeChange: (stockCode) => {
        const url = '/realtime/change';
        return axiosClient.post(url, {
            stockCode: stockCode,
        });
    },

    // get all stocks
    getAllStocks: () => {
        const url = '/stock/all';
        return axiosClient.get(url);
    }
};

export default stockApi;
