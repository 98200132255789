import React from 'react';
import PropTypes from 'prop-types';
import { AppImages } from 'general/constants/AppResource';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

CellWebinarGrid.propTypes = {
    item: PropTypes.object,
};

CellWebinarGrid.defaultProps = {
    item: {},
};

function CellWebinarGrid(props) {
    // MARK: --- Params ---
    const { item } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const { webinarId, name, description, level, startTime, thumbnail, location, link, status, online } = item;

    // MARK: --- Functions ---
    function handleClick() {
        if (webinarId) {
            history.push(`/webinar/${webinarId}`);
        }
    }

    return (
        <div className='CellWebinarGrid cursor-pointer hover-opacity-60' onClick={handleClick}>
            <img
                className='w-100 rounded border'
                draggable={false}
                alt='thumbnail'
                src={Utils.getFullUrl(thumbnail) ?? AppImages.imgDefaultThumbnail}
                style={{
                    aspectRatio: '16/9',
                    backgroundColor: '#f3f5f8',
                    objectFit: 'cover'
                }}
                onError={(e) => {
                    e.target.src = AppImages.imgDefaultThumbnail;
                }}
            />
            <div className='mt-4'>
                <p
                    className='font-size-h5 font-weight-bolder m-0'
                    style={{
                        color: '#18214d'
                    }}
                >
                    {name}
                </p>
                <p
                    className='font-size-lg my-2 q-max-line-3'
                    style={{
                        color: '#5c6077'
                    }}
                >
                    {description}
                </p>
                <p
                    className='font-weight-bold m-0 text-dark-75'
                    style={{
                        color: '#a4a9c5'
                    }}
                >
                    <i className="far fa-calendar-alt mr-2 text-dark-75 min-w-20px"></i>{Utils.formatDateTime(startTime, 'DD/MM/YYYY HH:mm')} {status === 'FINISHED' && (<span className='' style={{ color: 'red' }}>{`(${t('Finished')})`}</span>)}
                </p>
                <p
                    className='font-weight-bold m-0 my-2'
                >
                    {/* {`${t('Type')}: `} */}
                    <i className='fad fa-users-class text-dark-75 min-w-20px mr-2' />
                    <span className='text-dark-75'>{online === 1 ? t('Online') : t('Offline')}</span>
                </p>
                {
                    location && location.length > 0 && (
                        <p
                            className='font-weight-bold m-0 text-dark-75'
                            style={{
                                color: '#a4a9c5'
                            }}
                        >
                            <i className="fas fa-map-marker mr-2 text-dark-75 min-w-20px"></i>{location}
                        </p>
                    )
                }

            </div>
        </div>
    );
}

export default CellWebinarGrid;