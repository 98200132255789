import axiosClient from "./axiosClient";

export default {
  async createTransaction(param: {
    paymentTransactionId: String,
    bankAccountId: Number,
    bankName: String,
    type: "WEBINAR" | "COURSE" | "MEMBER",
    productId: Number,
    price: Number,
  }) {
    let {
      bankAccountId,
      bankName,
      paymentTransactionId,
      price,
      productId,
      type,
    } = param;

    let paymentChannel = "BANKTRANSFER";
    switch (bankName) {
      case "MOMO":
        paymentChannel = "MOMO";
        break;
      case "VNPAY":
        paymentChannel = "VNPAY";
        break;
    }

    let targetId = {
      planId: undefined,
      courseId: undefined,
      webinarId: undefined,
    };

    switch (type) {
      case "COURSE":
        targetId.courseId = productId;
        break;
      case "MEMBER":
        targetId.planId = productId;
        break;
      case "WEBINAR":
        targetId.webinarId = productId;
    }

    return axiosClient.post("/transaction/create", {
      type,
      paymentTransactionId,
      paymentChannel,
      price,
      bankAccountId,
      ...targetId,
    });
  },
};
