import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

ModalActiveAccount.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    active: PropTypes.func,
};

ModalActiveAccount.defaultProps = {
    show: false,
    onClose: null,
    active: null,
}

function ModalActiveAccount(props) {

    const { t } = useTranslation();
    const { show, onClose, active } = props;

    function handleClose() {
        if (onClose) {
            onClose();
        }
    };

    function handleActive() {
        if (active) {
            active();
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header className='d-flex flex-row justify-content-center align-items-center'>
                {/* modal header */}
                <Modal.Title className='text-danger'>
                    {t('SignInError')}
                </Modal.Title>
                <div
                    onClick={() => {
                        handleClose();
                    }}
                    className='btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer mr-6'
                    style={{
                        position: 'absolute',
                        right: '0%'
                    }}
                >
                    <i className="fal fa-times"></i>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className='mr-1'>{t('SignInErrorNotice')}</span>
                    <span
                        className='font-weight-bolder cursor-pointer'
                        style={
                            {
                                color: '#7621B1',
                                textDecoration: 'underline'
                            }
                        }
                        onClick={
                            handleActive
                        }
                    >
                        {t('here')}
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalActiveAccount;