import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { AppIcons } from 'general/constants/AppResource';

AppLogo.propTypes = {
    showOnlyLogo: PropTypes.bool,
    logoClassName: PropTypes.string,
    onLogoClicked: PropTypes.func,
};

AppLogo.defaultProps = {
    showOnlyLogo: false,
    logoClassName: '',
    onLogoClicked: null,
};

function AppLogo(props) {
    // MARK: --- Params ---
    const { showOnlyLogo, logoClassName, onLogoClicked } = props;
    const history = useHistory();

    // MARK: --- Functions ---
    function handleClickLogo(e) {
        e.preventDefault();
        history.push('/home');
        if (onLogoClicked) {
            onLogoClicked();
        }
    }

    return (
        <div className='AppLogo'>
            <a
                href='#'
                className='d-flex flex-row align-items-center'
                onClick={handleClickLogo}
            >
                <img
                    alt='Logo'
                    draggable={false}
                    src={AppIcons.icMGLogo}
                    className={`${logoClassName}`}
                />
                {
                    !showOnlyLogo && (
                        <p className='m-0 AppLogo_Name text-white ml-4'>MoneyGain</p>
                    )
                }
            </a>
        </div>
    );
}

export default AppLogo;