import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from 'general/components/NotFound';
import AccountHomeScreen from './screens/AccountHomeScreen';
import AccountInfoScreen from './screens/AccountInfoScreen';
import AccountSecurityScreen from './screens/AccountSecurityScreen';
import AccountReferralScreen from './screens/AccountReferralScreen';
import AccountCourseScreen from './screens/AccountCourseScreen';
import AccountTransactionScreen from './screens/AccountTransactionScreen';
import PrivateRoute from 'general/components/Routes/PrivateRoute';
import MyList from 'features/MyList';

Account.propTypes = {

};

function Account(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            {/* Private route */}
            <Route
                path={`${match.url}/my-list`}
                component={MyList}
            />

            <Route
                exact
                path={`${match.url}`}
                component={AccountHomeScreen}
            />
            <Route
                path={`${match.url}/info`}
                component={AccountInfoScreen}
            />
            <Route
                path={`${match.url}/security`}
                component={AccountSecurityScreen}
            />
            <Route
                path={`${match.url}/referral`}
                component={AccountReferralScreen}
            />
            <Route
                path={`${match.url}/course`}
                component={AccountCourseScreen}
            />
            <Route
                path={`${match.url}/transaction`}
                component={AccountTransactionScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Account;