import videoApi from "api/videoApi";
import CellVideoGrid from "features/Video/components/CellVideoGrid";
import CellVideoList from "features/Video/components/CellVideoList";
import EmptyView from "general/components/Views/EmptyView";
import { AppIcons } from "general/constants/AppResource";
import Global from "general/Global";
import Utils from "general/utils/Utils";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import KCHeaderView from "../KCHeaderView";

KCVideo.propTypes = {
  onMenuHandle: PropTypes.func,
};

KCVideo.defaultProps = {
  onMenuHandle: null,
};

function KCVideo(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { onMenuHandle } = props;
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const history = useHistory();
  const [filter, setFilter] = useState({
    page: 0,
    limit: Global.gDefaultPagination,
    q: '',
  });
  const pagination = useRef(null);
  const match = useRouteMatch();

  // MARK: --- Functions ---
  async function getVideoList() {
    setLoading(true);
    const res = await videoApi.getVideoList(filter);
    const { result, total, page, count } = res;
    const resVideos = res.videos;
    if (result === 'success' && resVideos) {
      if (page === 0) {
        setVideos(resVideos);
      } else {
        setVideos(videos.concat(resVideos));
      }
      pagination.current = {
        total: total,
        count: count,
        currentPage: page
      };
    }
    setLoading(false);
  }

  function onWindowScroll() {
    if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
      if (pagination.current) {
        let nextPage = Utils.getNextPage(pagination.current);
        if (nextPage) {
          setFilter({
            ...filter,
            page: nextPage,
          });
        }
      }
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    getVideoList();
  }, [filter]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => window.removeEventListener('scroll', onWindowScroll);
  }, []);

  return (
    <div className="KCVideo">
      <KCHeaderView
        title={t("AllVideos")}
        onMenuHandle={() => {
          if (onMenuHandle) {
            onMenuHandle();
          }
        }}
        onSubmitSearch={(text) => {
          setFilter({
            ...filter,
            q: text
          })
        }}
      />

      {
        videos && videos.length > 0 && (
          <div>
            <div className="row p-7">
              <div className="col-12">
                <CellVideoList
                  item={videos[0]}
                  onPress={() => {
                    history.push(`${match.url}/${videos[0].videoId}`);
                  }}
                />
              </div>
            </div>

            <hr className="m-0" />

            <div className="px-7 pb-7">
              <div className="row">
                {
                  videos.map((item, index) => {
                    if (index === 0) {
                      return (<div key={index}></div>)
                    }
                    return (
                      <div key={index} className="col-12 col-lg-6 mt-7">
                        <CellVideoGrid
                          item={item}
                          onPress={() => {
                            history.push(`${match.url}/${item.videoId}`);
                          }}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
      }

      {
        videos.length === 0 && (

          <div className='w-100 d-flex align-items-center justify-content-center py-10'>
            <EmptyView
              description='Không có videos'
              iconEmpty={AppIcons.icEmptyPack}
              loading={loading}
            />
          </div>
        )
      }
    </div>
  );
}

export default KCVideo;
