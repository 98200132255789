import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

DropdownSelect.propTypes = {
    name: PropTypes.string.isRequired,

    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelpers: PropTypes.object,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,

    options: PropTypes.array,
    onValueChanged: PropTypes.func,
    additionContainerSelectClass: PropTypes.string,

    actionItems: PropTypes.array,
};

DropdownSelect.defaultProps = {
    fieldHelpers: {},
    fieldMeta: {},
    fieldProps: {},
    label: "",
    disabled: false,
    text: "",
    options: [],
    onValueChanged: null,
    additionContainerSelectClass: '',
    actionItems: [],
};

function DropdownSelect(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const {
        fieldProps,
        fieldMeta,
        fieldHelpers,
        name,
        label,
        disabled,
        text,
        options,
        onValueChanged,
        additionContainerSelectClass,
        actionItems,
    } = props;
    const { error, touched } = fieldMeta;
    const showError = error && touched;
    const value = fieldProps.value;
    const currentOption = options.find(item => item.value === value);

    // MARK: --- Functions ---
    function handleOptionChanged(item) {
        const selectedValue = item.value;
        fieldHelpers.setValue(selectedValue);

        if (onValueChanged) {
            onValueChanged(selectedValue);
        }
    }

    return (
        <div className='d-flex align-items-center'>
            {label && (<label className={`mr-4 mb-0`}>{label}</label>)}
            <div className={additionContainerSelectClass}>
                <Dropdown className=''>
                    <Dropdown.Toggle
                        disabled={disabled}
                        className='d-flex flex-row align-items-center custom-dropdown-select'
                        variant=''>
                        <p className=''>{currentOption?.text}</p>
                        <i className="fas fa-caret-down ml-2 text-dark-50"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='w-100'>
                        <div className='max-h-225px overflow-auto'>
                            {
                                options.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Dropdown.Item
                                                className='d-flex justify-content-between'
                                                href="#"
                                                onClick={() => {
                                                    if (item.onPress) {
                                                        item.onPress();
                                                    } else {
                                                        handleOptionChanged(item);
                                                    }
                                                }}>
                                                {item.text}
                                                {value === item.value && <i className="far fa-solid fa-check text-dark pl-3" style={{ flex: '0 0 auto' }} />}
                                            </Dropdown.Item>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className=''>
                            {
                                actionItems.map((item, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            className='d-flex flex-row justify-content-start border-top btn-light align-items-center'
                                            onClick={() => {
                                                if (item.onPress) {
                                                    item.onPress();
                                                }
                                            }}>
                                            <i className="text-primary fal fa-plus"></i>
                                            <span className='text-primary ml-3 font-weight-bolder'>{t('CreateMyList')}</span>
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {
                    showError && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    )
                }
                {
                    text.length > 0 && (
                        <span className="form-text text-muted">{text}</span>
                    )
                }
            </div>
        </div>
    );
}

export default DropdownSelect;