import React, {useState} from "react"
import "./style.scss"
import {useDispatch, useSelector} from "react-redux";
import {addOrRemoveConditional} from "../../IdeaListSlice";
import Utils from "../../../../general/utils/Utils";
import {useTranslation} from "react-i18next";

const ConditionalCheckbox = ({filterConditionalId}) => {
    const dispatch = useDispatch()
    const filter = useSelector(state=>state.ideaList?.filter?.currentFilter)
    const check = (filter?.map(object => object?.filterConditionalId)?.indexOf(filterConditionalId)>=0)
    const [isChecked, setIsChecked] = useState(check);
    const { t } = useTranslation();

    const handleOnChange = () => {
        setIsChecked(!isChecked);
        dispatch(addOrRemoveConditional({
            filterConditionalId: filterConditionalId
        }))

    };
    return (
        <>
        <div className={"custom-checkbox-div col-6"}>
            <input
                className={"custom-checkbox-filter"}
                type="checkbox"
                value={t(Utils.getFilterConditionalTitle(filterConditionalId))}
                checked={isChecked}
                onChange={handleOnChange}
            />
            <span className={"custom-title-name"}>{t(Utils.getFilterConditionalTitle(filterConditionalId))}</span>
        </div>
        </>
    )
}

export default ConditionalCheckbox
