import axiosClient from "./axiosClient";

const articleApi = {
    // Get article list
    getArticleList: (params) => {
        const url = '/knowledge/article/find';
        return axiosClient.post(url, params);
    },

    // Get article info by id
    getArticleInfo: (articleId) => {
        const url = '/knowledge/article/show';
        return axiosClient.post(url, {
            articleId: parseInt(articleId),
        });
    },

    // get related articles
    getRelatedArticles: (params) => {
        const url = '/knowledge/article/get_related';
        return axiosClient.get(url, { params });
    },
};

export default articleApi;