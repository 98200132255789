import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';

ModalExecutiveBoard.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    items: PropTypes.array,
};

ModalExecutiveBoard.defaultProps = {
    show: false,
    onClose: null,
    items: [],
};

function ModalExecutiveBoard(props) {
    // MARK: --- Params ---
    const { show, onClose, items } = props;
    const { t } = useTranslation();

    const handleClose = (e) => {
        e.preventDefault();
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                centered
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('ExecutiveBoard')}
                    </Modal.Title>
                    <a href="#" className="btn btn-sm btn-icon btn-secondary" onClick={handleClose}>
                        <i className="far fa-times"></i>
                    </a>
                </Modal.Header>

                <Modal.Body className='overflow-auto' style={{
                    maxHeight: '84vh'
                }}>
                    <MGDataTable
                        className='bg-white'
                        headItems={[
                            { title: t('Name'), subTitle: t('Position'), col: 'col-8' },
                            { title: t('Quantity'), subTitle: '', col: 'col-4' },
                        ]}
                        dataItems={
                            items.map((item, index) => {
                                return {
                                    onPress: () => { },
                                    data: [
                                        { icon: '', title: '', subTitle: '', name: `${item.title} ${item.name}`, nameColor: '', value: Utils.getPositionNames(item.positions), valueColor: '#5C6077', col: 'col-8' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.totalShares), nameColor: '', value: '', valueColor: '', col: 'col-4' },
                                    ]
                                }
                            })
                        }
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalExecutiveBoard;