import { thunkGetRealtimeIndexes } from "app/appSlice";
import Utils from "general/utils/Utils";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

StockIndexTopView.propTypes = {};

function StockIndexTopView(props) {
  // --- MARK: Param ---
  const dispatch = useDispatch();
  const { realtimeIndexes } = useSelector((state) => state.app);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(thunkGetRealtimeIndexes({}));
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <Marquee
      gradient={false}
      speed={50}
      // onCycleComplete={() => dispatch(thunkGetRealtimeIndexes({}))}
      // style={{ padding: "0 -2px" }}
      className='bg-container'
    >
      <div className="IndexTopViewContainer bg-container py-2">
        {realtimeIndexes.map((item, index) => {
          return (
            <span className="marquee-item mr-8" key={index}>
              <span className="text-white font-weight-bolder">
                {item.stockCode}&nbsp;
              </span>
              <span
                className=""
                style={{
                  color: Utils.getStockColor(item.changeInPercent),
                }}
              >
                {item.price}&nbsp;
                <i
                  className={`fas fa-caret-${item.change > 0 ? "up" : "down"}`}
                  style={{
                    color: Utils.getStockColor(item.changeInPercent),
                  }}
                ></i>
                {`${Math.abs(parseFloat(item.changeInPercent).toFixed(2))}%`}
              </span>
            </span>
          );
        })}
        {realtimeIndexes.map((item, index) => {
          return (
            <span className="marquee-item mr-8" key={index}>
              <span className="text-white font-weight-bolder">
                {item.stockCode}&nbsp;
              </span>
              <span
                className=""
                style={{
                  color: Utils.getStockColor(item.changeInPercent),
                }}
              >
                {item.price}&nbsp;
                <i
                  className={`fas fa-caret-${item.change > 0 ? "up" : "down"}`}
                  style={{
                    color: Utils.getStockColor(item.changeInPercent),
                  }}
                ></i>
                {`${Math.abs(parseFloat(item.changeInPercent).toFixed(2))}%`}
              </span>
            </span>
          );
        })}
        {realtimeIndexes.map((item, index) => {
          return (
            <span className="marquee-item mr-8" key={index}>
              <span className="text-white font-weight-bolder">
                {item.stockCode}&nbsp;
              </span>
              <span
                className=""
                style={{
                  color: Utils.getStockColor(item.changeInPercent),
                }}
              >
                {item.price}&nbsp;
                <i
                  className={`fas fa-caret-${item.change > 0 ? "up" : "down"}`}
                  style={{
                    color: Utils.getStockColor(item.changeInPercent),
                  }}
                ></i>
                {`${Math.abs(parseFloat(item.changeInPercent).toFixed(2))}%`}
              </span>
            </span>
          );
        })}
      </div>
    </Marquee>
  );
}

export default StockIndexTopView;
