import HomeSectionArticle from 'features/Home/components/HomeSectionArticle';
import HomeSectionCourse from 'features/Home/components/HomeSectionCourse';
import HomeSectionEvent from 'features/Home/components/HomeSectionEvent';
import HomeSectionMGProduct from 'features/Home/components/HomeSectionMGProduct';
import HomeSectionMGWarning from 'features/Home/components/HomeSectionMGWarning';
import HomeSectionMyList from 'features/Home/components/HomeSectionMyList';
import HomeSectionNewVideos from 'features/Home/components/HomeSectionNewVideos';
import HomeSectionResearch from 'features/Home/components/HomeSectionResearch';
import HomeSectionTopStock from 'features/Home/components/HomeSectionTopStock';
import HomeSectionUpDownTrend from 'features/Home/components/HomeSectionUpDownTrend';
import HomeSectionWebinar from 'features/Home/components/HomeSectionWebinar';
import AppLayout from 'general/components/AppLayout';
import Utils from 'general/utils/Utils';
import { useSelector } from 'react-redux';

HomeMainScreen.propTypes = {

};

function HomeMainScreen(props) {
    // MARK: --- Params ---
    const loggedInUser = useSelector(state => state.auth.current);
    const isLoggedIn = !Utils.isObjectEmpty(loggedInUser);
    const { topVideos } = useSelector(state => state.app);

    return (
        <AppLayout>
            <HomeSectionUpDownTrend />
            <HomeSectionMGProduct />
            <HomeSectionTopStock />
            {
                isLoggedIn && (
                    <HomeSectionMGWarning />
                )
            }
            <HomeSectionWebinar />
            <HomeSectionCourse />
            {
                isLoggedIn && (
                    <HomeSectionEvent />
                )
            }
            {
                topVideos && topVideos.length > 0 && (
                    <HomeSectionNewVideos />
                )
            }
            {
                isLoggedIn && (
                    <HomeSectionMyList />
                )
            }
            {
                isLoggedIn ? (
                    <HomeSectionArticle />
                ) : (
                    <HomeSectionResearch />
                )
            }
        </AppLayout>
    );
}

export default HomeMainScreen;
