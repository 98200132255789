import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListPlanScreen from './screens/ListPlanScreen';
import NotFound from 'general/components/NotFound';
import PaymentScreen from './screens/PaymentScreen';
import { useDispatch } from "react-redux";
import { thunkGetBankAccountList, thunkGetPlanList } from "./paymentSlice";
import PrivateRoute from 'general/components/Routes/PrivateRoute'

Payment.propTypes = {

};

function Payment(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();


    return (
        <Switch>
            <Route
                path={`${match.url}/plan`}
                component={ListPlanScreen}
            />
            <PrivateRoute
                path={`${match.url}/purchase`}
                component={PaymentScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Payment;
