import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'general/components/AppLayout';
import './style.scss'
import { AppImages } from 'general/constants/AppResource';
import { Col, Row } from 'react-bootstrap';
import { Stack } from 'react-bootstrap'
import LandingPageFeaturedReview from 'features/Home/components/LandingPageFeaturedReview';

AboutUsScreen.propTypes = {

};

const sTag = '[AboutUsScreen]';

function AboutUsScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        }
    }, []);

    // MARK: --- Functions ---

    return (
        <AppLayout>
            <div className='AboutUsScreen bg-white'>
                <div className='container-xxl d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column align-items-center pt-20 pb-10'>
                        <p className='AboutUsScreen_MoneyGain_Title m-0 text-primary'>MoneyGain</p>
                        <p className='m-0 font-size-h3 text-dark-50 col-md-8 col-sm-12 text-center'>MoneyGain được thành lập và vận hành bởi một đội ngũ nhân sự gồm toàn những chuyên gia có thâm niên hàng chục năm với hiểu biết sâu sắc về thị trường tài chính chứng khoán Việt Nam.</p>
                    </div>
                    <img src={AppImages.imgAboutUs01} className="mx-auto d-block w-100"
                        draggable={false} alt="About us Image" />
                    <div className='pt-10 pb-20'>
                        <Row className='row mt-6'>
                            <Col md className='mt-0'>
                                <p className='text-primary font-size-h3 font-weight-boldest m-0'>SỨ MỆNH</p>
                                <p className='font-size-lg m-0 mt-2 text-dark-50'>SeaWire Web is a wireframe kit that has more than 15 popular categories and more than 200 screens from each category, SeaWire Web deserves to be your partner in working on projects. SeaWire Web is a wireframe kit that has more than 15 popular categories.</p>
                            </Col>
                            <Col md className='mt-6 mt-md-0'>
                                <p className='text-primary font-size-h3 font-weight-boldest m-0'>TẦM NHÌN</p>
                                <p className='font-size-lg m-0 mt-2 text-dark-50'>SeaWire Web is a wireframe kit that has more than 15 popular categories and more than 200 screens from each category, SeaWire Web deserves to be your partner in working on projects. SeaWire Web is a wireframe kit that has more than 15 popular categories.</p>
                            </Col>
                            <Col md className='mt-6 mt-md-0'>
                                <p className='text-primary font-size-h3 font-weight-boldest m-0'>GIÁ TRỊ CỐT LÕI</p>
                                <p className='font-size-lg m-0 mt-2 text-dark-50'>SeaWire Web is a wireframe kit that has more than 15 popular categories and more than 200 screens from each category, SeaWire Web deserves to be your partner in working on projects. SeaWire Web is a wireframe kit that has more than 15 popular categories.</p>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='AboutUsScreen_OurValue py-20 py-md-30'>
                    <div className='container-xxl'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className='bg-white rounded p-10 h-100 d-flex flex-column'>
                                    <div className='w-90px h-90px bg-primary rounded-circle'>

                                    </div>
                                    <p className='font-size-lg my-6'>Tanahair is the friendliest and most efficient company I have ever used. The whole thing takes time to introduce the product and as a result puts forward only the best opportunities that really suit you. they help from start to finish to create a great product identity for your company</p>
                                    <p className='font-weight-boldest font-size-h6 m-0'>MoneyGain</p>
                                    <p className='font-size-lg'>Chief Executive Officer</p>
                                </div>
                            </div>

                            <div className='col-12 col-md-6'>
                                <div className='mt-10 mt-md-0'>
                                    <div className='text-white font-size-h1 font-weight-boldest'>MỤC TIÊU ĐEM LẠI</div>
                                    <div className='d-flex mt-10'>
                                        <div className='text-white-50 font-size-h4 font-weight-boldest min-w-40px'>01</div>
                                        <div className=''>
                                            <p className='text-white font-size-h4 font-weight-boldest m-0'>DỊCH VỤ CHUYÊN NGHIỆP</p>
                                            <p className='text-white-50 font-size-lg mt-2'>Thâm niên trên 10 năm kinh nghiệm cung cấp các dịch vụ cho tổ chức và nhà đầu tư cá nhân trên thị trường</p>
                                        </div>
                                    </div>
                                    <div className='d-flex my-10'>
                                        <div className='text-white-50 font-size-h4 font-weight-boldest min-w-40px'>02</div>
                                        <div className=''>
                                            <p className='text-white font-size-h4 font-weight-boldest m-0'>NỘI DUNG CHẤT LƯỢNG</p>
                                            <p className='text-white-50 font-size-lg mt-2'>Dữ liệu, nội dung cung cấp cho khách hàng được kiểm soát chặt chẽ, không sai sót</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-10'>
                                        <div className='text-white-50 font-size-h4 font-weight-boldest min-w-40px'>03</div>
                                        <div className=''>
                                            <p className='text-white font-size-h4 font-weight-boldest m-0'>ĐỘI NGŨ NHIỀU KINH NGHIỆM</p>
                                            <p className='text-white-50 font-size-lg mt-2'>Đội ngũ nhân sự gồm toàn những chuyên gia có thâm niên hàng chục năm với hiểu biết sâu sắc về thị trường</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LandingPageFeaturedReview />
            </div>
        </AppLayout>
    );
}

export default AboutUsScreen;