import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppData from 'general/constants/AppData';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Utils from 'general/utils/Utils';
import './style.scss';
import { useTranslation } from 'react-i18next';
import AppLogo from '../AppLogo';

let KTLayoutAside = require("assets/plugins/aside/aside");
let KTLayoutAsideMenu = require("assets/plugins/aside/aside-menu");
let KTOffcanvas = require("assets/plugins/ktoffcanvas");

QuickAppSiteMenu.propTypes = {

};

function QuickAppSiteMenu(props) {
    // MARK: --- Params ---
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const { t } = useTranslation();
    const refSideMenuOffcanvas = useRef(null);

    // MARK: --- Hooks ---
    useEffect(() => {
        // Init MG Aside Menu
        if (typeof KTLayoutAsideMenu !== "undefined" && !window.KTLayoutAsideMenuInited) {
            KTLayoutAsideMenu.init("mg_aside_menu");
        }

        if (typeof KTOffcanvas !== "undefined" && refSideMenuOffcanvas.current === null) {
            let offCanvasObject = new KTOffcanvas("quick_app_menu_aside", {
                baseClass: "aside",
                overlay: true,
                toggleBy: {
                    target: 'site-menu-handle',
                }
            });
            refSideMenuOffcanvas.current = offCanvasObject;
        }
    }, []);

    return (
        <div className='QuickAppSiteMenu aside aside-left aside-fixed bg-white' id='quick_app_menu_aside'>
            {/* App logo */}
            <div
                className='QuickAppSiteMenu_Header d-flex flex-column align-items-center justify-content-center min-h-110px'
            >
                <AppLogo
                    showOnlyLogo
                    onLogoClicked={() => {
                        refSideMenuOffcanvas.current.hide();
                    }}
                />
                <p className='QuickAppSiteMenu_Header_Title m-0 text-white'>MoneyGain</p>
            </div>

            {/* Site menu */}
            <div
                id="mg_aside_menu"
                className="aside-menu"
                data-menu-scroll="1"
            >
                <ul className="menu-nav">
                    {
                        AppData.appSiteMenus.map((item, index) => {
                            const { url, text, subMenus } = item;
                            const isActive = locationPathname.includes(url);
                            const hasSubMenu = !Utils.isObjectNull(subMenus);

                            return (
                                <li
                                    key={index}
                                    className={`menu-item ${isActive ? 'menu-item-active' : ''} ${hasSubMenu && `menu-item-submenu ${isActive && 'menu-item-open'}`}`}
                                >
                                    <Link
                                        to={url}
                                        className={`align-items-center menu-link ${hasSubMenu && 'menu-toggle'}`}
                                        onClick={() => {
                                            if (!hasSubMenu) {
                                                refSideMenuOffcanvas.current.hide();
                                            }
                                        }}
                                    >
                                        {/* <span className="svg-icon menu-icon"><i className={`${icon} text-white`}></i></span> */}
                                        <span className="menu-text font-size-lg font-weight-bolder">{t(text)}</span>
                                        {
                                            hasSubMenu && (
                                                <i className="menu-arrow q-arrow" />
                                            )
                                        }
                                    </Link>
                                    {
                                        hasSubMenu && (
                                            <div className="menu-submenu">
                                                <ul className="menu-subnav">
                                                    {
                                                        subMenus.map((subItem, subIndex) => {
                                                            const isSubMenuActive = locationPathname.includes(subItem.url);
                                                            return (
                                                                <li key={subIndex} className={`menu-item ${isSubMenuActive && 'menu-item-active'}`}>
                                                                    <Link
                                                                        className='menu-link'
                                                                        style={{ borderRadius: 0 }}
                                                                        to={subItem.url}
                                                                        onClick={() => {
                                                                            refSideMenuOffcanvas.current.hide();
                                                                        }}
                                                                    >
                                                                        <i className="menu-bullet menu-bullet-dot">
                                                                            <span></span>
                                                                        </i>
                                                                        <span className="menu-text font-size-lg font-weight-bold">{t(subItem.text)}</span>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

export default QuickAppSiteMenu;