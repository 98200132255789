import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AccountHeader from '../AccountHeader';
import { Card } from 'react-bootstrap';

AccountBaseLayout.propTypes = {

};

function AccountBaseLayout(props) {
    return (
        <div className='AccountBaseLayout min-vh-100 d-flex flex-column '>
            {/* Header */}
            <AccountHeader />

            {/* Body */}
            <div className='container-xxl'>
                <Card className='bg-white rounded-lg my-10 overflow-hidden'>
                    <Card.Body className='py-10 py-md-20'>
                        {props.children}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default AccountBaseLayout;