import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';

CellStockList.propTypes = {
    item: PropTypes.object,
    showDivider: PropTypes.bool,
    onPress: PropTypes.func,
    onContextMenu: PropTypes.func,
};

CellStockList.defaultProps = {
    item: {},
    showDivider: true,
    onPress: null,
    onContextMenu: null,
};

function CellStockList(props) {
    // MARK: --- Params ---
    const { item, showDivider, onPress, onContextMenu } = props;
    // console.log({ item });
    if (item?.stockCode === 'BBM') {
        console.log({ item });
    }

    // MARK: --- Functions ---
    function handlePress(e) {
        e.preventDefault();
        if (onPress) {
            onPress(e);
        }
    }

    function handleRightClick(e) {
        e.preventDefault();
        if (onContextMenu) {
            onContextMenu();
        }
    }

    return (
        <div className={`CellStockList hover-opacity-60 cursor-pointer`} onClick={handlePress} onContextMenu={handleRightClick}>
            <div className='d-flex flex-column'>
                <div className='d-flex align-items-center justify-content-between px-5 mb-1'>
                    <a href='#' onClick={e => e.preventDefault()} className="font-weight-bold font-size-lg text-dark flex-grow-1">{item.stockCode} <span className='font-size-sm font-weight-normal text-dark-50'>({item?.exchangeCode ?? ''})</span></a>
                    <span className='font-weight-bold m-0 text-right text-dark font-size-lg'>{Utils.formatNumber(item.price ?? 0)}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between px-5'>
                    <span className="font-size-sm font-weight-bold text-muted">{item?.stockName ?? ''}</span>
                    <span
                        className='font-weight-bold m-0 font-size-sm text-right flex-column-fluid'
                        style={{ color: Utils.getStockColor(Utils.getStockPriceChangeInPercent(item.price, item.refPrice)) }}
                    >
                        {`${Utils.formatNumber(Utils.getStockPriceChange(item.price, item.refPrice))} / ${Utils.getTextStockPriceChangeInPercent(item.price, item.refPrice)}`}
                    </span>
                </div>
            </div>

            {
                showDivider && (
                    <hr className='my-4' />
                )
            }
        </div>
    );
}

export default CellStockList;