import articleApi from 'api/articleApi';
import CellArticleList from 'features/Article/components/CellArticleList';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import KCHeaderView from '../KCHeaderView';
import './style.scss';

KCArticle.propTypes = {
    onMenuHandle: PropTypes.func,
};

KCArticle.defaultProps = {
    onMenuHandle: null,
};

function KCArticle(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const history = useHistory();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        q: '',
        type: 'ARTICLE'
    });
    const pagination = useRef(null);
    const match = useRouteMatch();
    const currentTextSearch = useRef('');

    // MARK: --- Functions ---
    async function getArticleList() {
        setLoading(true);
        const res = await articleApi.getArticleList(filter);
        const { result, total, page, count } = res;
        const resArticles = res.articles;

        if (result === 'success' && resArticles) {
            if (page === 0) {
                setArticles(resArticles);
            } else {
                setArticles(articles.concat(resArticles));
            }
            pagination.current = {
                total: total,
                count: count,
                currentPage: page
            }
        }
        setLoading(false);
    }

    function onWindowScroll() {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (pagination.current) {
                let nextPage = Utils.getNextPage(pagination.current);
                if (nextPage) {
                    setFilter({
                        ...filter,
                        page: nextPage,
                        q: currentTextSearch.current,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getArticleList();
    }, [filter]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <div className='KCArticle'>
            <KCHeaderView
                title={t('AllArticles')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
                onSubmitSearch={(text) => {
                    currentTextSearch.current = text;
                    setFilter({
                        ...filter,
                        page: 0,
                        q: text
                    });
                }}
            />
            <div className='KCArticle_Banner row pl-10 pl-md-20 border-bottom'>
                <div className='col-5 d-flex align-items-center py-12 py-md-24'>
                    <p className='m-0 text-left font-weight-boldest text-uppercase' style={{ fontSize: '2.5rem', lineHeight: '120%' }}>
                        {t('Article')}
                        <br />
                        <span className='text-primary' style={{ fontSize: '3rem' }}>MoneyGain</span>
                    </p>
                </div>
                <div className='KCArticle_Banner_Right col-7 py-30'></div>
            </div>

            {/* content */}
            <div className='row px-4'>
                {
                    articles.length > 0 && articles.map((item, index) => {
                        return (
                            <div key={index} className={`col-12 p-4 ${index < articles.length - 1 && 'border-bottom'}`}>
                                <CellArticleList
                                    item={item}
                                    onPress={() => {
                                        history.push(`${match.url}/${item.articleId}`);
                                    }}
                                />
                            </div>
                        )
                    })
                }

                {
                    articles.length === 0 && (
                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                            <EmptyView
                                description='Không có bài viết'
                                iconEmpty={AppIcons.icEmptyPack}
                                loading={loading}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default KCArticle;