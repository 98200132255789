import React from 'react';
import PropTypes from 'prop-types';
import { AppImages } from 'general/constants/AppResource';
import './style.scss';

LandingPageSectionInfo2.propTypes = {

};

function LandingPageSectionInfo2(props) {
    return (
        <div className='LandingPageSectionInfo2 py-10 bg-white'>
            <div className='container-xxl'>
                <div className='row'>
                    {/* Left */}
                    <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <p className='mt-sm-4 LandingPageSectionInfo2_Title'>Chọn đúng cổ phiếu, điểm khởi đầu cho tất cả</p>
                        <p className='m-0 text-justify font-size-h6'>
                            Với MoneyGain, bạn có thể bỏ những thói quen nghiên cứu chứng khoán cũ của mình. Thay vì dành hàng giờ để đọc hàng tá trang web, vào các diễn đàn, nghe lời khuyên của bạn bè…bạn có thể tiết kiệm thời gian của mình bằng cách nghiên cứu đầu tư trên một màn hình duy nhất.<br />
                            Chỉ trong một giao diện, bạn có thể tìm thấy những cổ phiếu hoạt động tốt nhất, sử dụng thông tin cơ bản doanh nghiệp được thể hiện ngay trên biểu đồ kỹ thuật để phân tích chuyên sâu, từ đó xác định cổ phiếu đạt tiêu chí và thời điểm lý tưởng mua và bán một cách dễ dàng. Bạn sẽ được trang bị tất cả các nguồn lực của một chuyên gia thị trường chứng khoán trên bàn làm việc của bạn</p>
                    </div>

                    {/* Right */}
                    <div className='col-12 col-md-5 d-flex align-items-center'>
                        <img
                            alt=''
                            draggable={false}
                            src={AppImages.imgLandingPage02}
                            className='w-100'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionInfo2;