import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

HomeSectionMGWarning.propTypes = {

};

function HomeSectionMGWarning(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const { ideaLists } = useSelector(state => state.app);
    let listBuyQueue = [];
    let listSellQueue = [];
    let listBuyWatch = [];
    let listSellWatch = [];
    ideaLists.forEach(item => {
        const { listName, data } = item;
        if (listName === 'BUY_QUEUE') {
            listBuyQueue = data;
        }
        if (listName === 'SELL_QUEUE') {
            listSellQueue = data;
        }
        if (listName === 'BUY_WATCH') {
            listBuyWatch = data;
        }
        if (listName === 'SELL_WATCH') {
            listSellWatch = data;
        }
    });

    return (
        <div className='HomeSectionMGWarning py-20'>
            <div className='container-xxl d-flex flex-column align-items-center'>
                <div className='mb-5 col-lg-7'>
                    <p className='HomeSectionMGWarning_Header_Title text-uppercase text-center mb-2'>
                        {t('MoneyGainListsWarning')}
                    </p>
                    <p className='text-center font-size-lg text-white'>
                        Tín hiệu giao dịch là 1 hệ thống công cụ gợi ý giao dịch. Lưu ý rằng đây chỉ là công cụ gợi ý, bạn cần quản lý vốn & giao dịch dựa trên quyết định cá nhân cuối cùng.
                    </p>
                </div>
                <div className='col-9 my-5'>
                    <div className='row '>
                        <div className='HomeSectionMGWarning_BodyLeft col-md-6 col-sm-12 px-0 mb-sm-5 mb-md-0 border-top border-left border-bottom'>
                            <div className='HomeSectionMGWarning_BuyList text-center py-3'> <p className='m-0' style={{ color: "#68C57C" }}>{t('BuyQueueList')}</p></div>
                            <div className='d-flex justify-content-around HomeSectionMGWarning_Detail_Left py-5 border-top border-bottom'>
                                {
                                    listBuyQueue.map((item, index) => {
                                        return (
                                            <span key={index} className='cursor-pointer text-hover-primary' onClick={() => {
                                                history.push(`/stock/${item.stockCode}`);
                                            }}>{item.stockCode}</span>
                                        )
                                    })
                                }
                                {
                                    listBuyQueue.length === 0 && (
                                        <span>&nbsp;</span>
                                    )
                                }
                            </div>
                            <div className='HomeSectionMGWarning_BuyList d-flex justify-content-center py-3'>
                                <button className='d-flex bg-transparent border-0 align-items-center hover-opacity-60' onClick={() => {
                                    history.push('/idea-list/mg-lists/buy-queue');
                                }}>
                                    <span className='mr-2' style={{ color: "#FFFFFF" }}>{t('More')}</span>
                                    <i className="fas fa-caret-right" style={{ color: "#FFFFFF" }}></i>
                                </button>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 px-0 table-bordered'>
                            <div className='HomeSectionMGWarning_SellList text-center py-3'> <p className='m-0' style={{ color: "#E84650" }}>{t('SellQueueList')}</p></div>
                            <div className='d-flex justify-content-around HomeSectionMGWarning_Detail_Right py-5 border-top border-bottom'>
                                {
                                    listSellQueue.map((item, index) => {
                                        return (
                                            <span key={index} className='cursor-pointer text-hover-primary' onClick={() => {
                                                history.push(`/stock/${item.stockCode}`);
                                            }}>{item.stockCode}</span>
                                        )
                                    })
                                }
                                {
                                    listSellQueue.length === 0 && (
                                        <span>&nbsp;</span>
                                    )
                                }
                            </div>
                            <div className='HomeSectionMGWarning_BuyList d-flex justify-content-center py-3'>
                                <button className='d-flex bg-transparent border-0 align-items-center hover-opacity-60' onClick={() => {
                                    history.push('/idea-list/mg-lists/sell-queue');
                                }}>
                                    <span className='mr-2' style={{ color: "#FFFFFF" }}>{t('More')}</span>
                                    <i className="fas fa-caret-right" style={{ color: "#FFFFFF" }}></i>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-9 my-5'>
                    <div className='row '>
                        <div className='HomeSectionMGWarning_BodyLeft col-md-6 col-sm-12 px-0 mb-sm-5 mb-md-0 border-left border-top border-bottom'>
                            <div className='HomeSectionMGWarning_BuyList text-center py-3'> <p className='m-0' style={{ color: "#FFFFFF" }}>{t('SellWatchList')}</p></div>
                            <div className='d-flex justify-content-around HomeSectionMGWarning_Detail_Left py-5 border-top border-bottom'>
                                {
                                    listSellWatch.map((item, index) => {
                                        return (
                                            <span key={index} className='cursor-pointer text-hover-primary' onClick={() => {
                                                history.push(`/stock/${item.stockCode}`);
                                            }}>{item.stockCode}</span>
                                        )
                                    })
                                }
                                {
                                    listSellWatch.length === 0 && (
                                        <span>&nbsp;</span>
                                    )
                                }
                            </div>
                            <div className='HomeSectionMGWarning_BuyList d-flex justify-content-center py-3'>
                                <button className='d-flex bg-transparent border-0 align-items-center hover-opacity-60' onClick={() => {
                                    history.push('/idea-list/mg-lists/sell-watch');
                                }}>
                                    <span className='mr-2' style={{ color: "#FFFFFF" }}>{t('More')}</span>
                                    <i className="fas fa-caret-right" style={{ color: "#FFFFFF" }}></i>
                                </button>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 px-0 table-bordered'>
                            <div className='HomeSectionMGWarning_SellList text-center py-3'> <p className='m-0' style={{ color: "#FFFFFF" }}>{t('BuyWatchList')}</p></div>
                            <div className='d-flex justify-content-around HomeSectionMGWarning_Detail_Right py-5 border-top border-bottom'>
                                {
                                    listBuyWatch.map((item, index) => {
                                        return (
                                            <span key={index} className='cursor-pointer text-hover-primary' onClick={() => {
                                                history.push(`/stock/${item.stockCode}`);
                                            }}>{item.stockCode}</span>
                                        )
                                    })
                                }
                                {
                                    listBuyWatch.length === 0 && (
                                        <span>&nbsp;</span>
                                    )
                                }
                            </div>
                            <div className='HomeSectionMGWarning_BuyList d-flex justify-content-center py-3'>
                                <button className='d-flex bg-transparent border-0 align-items-center hover-opacity-60' onClick={() => {
                                    history.push('/idea-list/mg-lists/buy-watch');
                                }}>
                                    <span className='mr-2' style={{ color: "#FFFFFF" }}>{t('More')}</span>
                                    <i className="fas fa-caret-right" style={{ color: "#FFFFFF" }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <button type="button" className="btn btn-primary font-weight-bold">{t('More')}</button> */}

            </div>
        </div>
    );
}

export default HomeSectionMGWarning;