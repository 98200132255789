import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'general/components/AppLayout';
import CellArticleGrid from 'features/Article/components/CellArticleGrid';
import CellArticleList from 'features/Article/components/CellArticleList';
import AppBanner from 'general/components/AppBanner';
import { useTranslation } from 'react-i18next';
import Global from 'general/Global';
import articleApi from 'api/articleApi';
import { useSelector } from 'react-redux';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { useHistory } from 'react-router-dom';
import Utils from 'general/utils/Utils';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';

ArticleHomeScreen.propTypes = {

};

const sTag = '[ArticleHomeScreen]';

function ArticleHomeScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { topArticles } = useSelector(state => state.app);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        q: '',
        type: 'ARTICLE'
    });
    const pagination = useRef(null);
    const history = useHistory();

    // MARK: --- Functions ---
    async function getArticlesList() {
        setLoading(true);
        const res = await articleApi.getArticleList(filter);
        const { result, total, page, count } = res;
        const resArticles = res.articles;
        if (result === 'success' && resArticles) {
            if (page === 0) {
                setArticles(resArticles);
            } else {
                setArticles(articles.concat(resArticles));
            }
            pagination.current = {
                total: total,
                count: count,
                currentPage: page
            };
        }
        setLoading(false);
    }

    function onWindowScroll() {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (pagination.current) {
                let nextPage = Utils.getNextPage(pagination.current);
                console.log({ nextPage });
                if (nextPage) {
                    setFilter({
                        ...filter,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} view did load`);

        return () => {
            console.log(`${sTag} view will dismiss`);
        }
    }, []);

    useEffect(() => {
        getArticlesList();
    }, [filter]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <AppLayout className=''>
            <div className='ArticleHomeScreen bg-white min-h-600px'>
                {/* content */}
                <div className='container-xxl py-10'>
                    {/* top articles */}
                    <SectionHeaderView
                        title={t('TopArticles')}
                    />
                    <div className='row pb-4'>
                        {
                            topArticles.length > 0 && topArticles.map((item, index) => {
                                if (index >= 2) {
                                    return (<></>)
                                } else {
                                    return (
                                        <div key={index} className='col-12 col-sm-6 col-md-6 my-4'>
                                            <CellArticleGrid
                                                item={item}
                                                onPress={() => {
                                                    history.push(`/article/${item.articleId}`);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }

                        {
                            topArticles.length === 0 && (

                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {/* other articles */}
                    <div className='mt-10'>
                        <SectionHeaderView
                            title={t('Articles')}
                        />
                        <div className='row pb-10'>
                            <div className='col-12 col-md-8'>
                                <div className='row'>
                                    {
                                        articles.length > 0 && articles.map((item, index) => {
                                            return (
                                                <div key={index} className='col-12 pt-5'>
                                                    <CellArticleList
                                                        item={item}
                                                        showDivider={index < articles.length - 1}
                                                        onPress={() => {
                                                            history.push(`/article/${item.articleId}`);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        articles.length === 0 && (

                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* banner */}
                            <div className='col-4 pl-4 pt-4 d-none d-md-block'>
                                <AppBanner className='h-600px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default ArticleHomeScreen;