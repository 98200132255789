import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Utils from 'general/utils/Utils';

AnalyzeTransactionHistoryTableBodyItem.propTypes = {

};

function AnalyzeTransactionHistoryTableBodyItem(props) {

    const { data, flag } = props
    const { t } = useTranslation()

    return (
        <div className='d-flex flex-column align-items-center w-100 p-0'>
            <div className='row w-100'>
                {data.map((item, index) => {
                    return (
                        <div key={index} className='col-4 py-1 d-flex flex-column align-items-start'>
                            <span style={{
                                fontWeight: '400',
                                lineHeight: '180%',
                                color: item?.titleColor
                            }}>{item.title}</span>
                            {(!Utils.isObjectNull(item.gain))
                                ? <div>
                                    <span style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#68C57C'
                                    }}>{item.gain}</span>
                                    <span style={{ color: '#A4A9C5' }}>/</span>
                                    <span style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#E84650'
                                    }}>{item.loss}</span>
                                    {item.breakEven && <span style={{ color: '#A4A9C5' }}>/</span>}
                                    <span style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#F4B638'
                                    }}>{item.breakEven}</span>
                                </div>
                                : <span style={{
                                    fontWeight: '400',
                                    lineHeight: '180%',
                                    color: item?.subTitleColor
                                }}>{item.subTitle}</span>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default AnalyzeTransactionHistoryTableBodyItem;