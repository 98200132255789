import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppImages } from 'general/constants/AppResource';
import AppData from 'general/constants/AppData';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';


LandingPageSectionInfo4.propTypes = {

};

function LandingPageSectionInfo4(props) {
    // MARK: --- Params ---
    const history = useHistory();

    const { t } = useTranslation();

    return (
        <div className='LandingPageSectionInfo4 py-20 bg-white'>
            <div className='container-xxl'>
                <div className='row'>
                    {/* Left */}
                    <div className='col-12 col-md-7 d-flex flex-column'>
                        <p className='mt-sm-4 LandingPageSectionInfo2_Title'>Nơi tìm kiếm và sở hữu những cơ hội đầu tư tuyệt vời</p>
                        <p className='m-0 text-justify font-size-h6'>
                            MoneyGainLists là nơi để tìm kiếm các siêu cổ phiếu trước khi chúng có sóng tăng giá mạnh mẽ nhất. <br />Tại MoneyGainLists bạn có thể tìm thấy:
                        </p>
                        <ul className='navi navi-border mr-6 mt-6'>
                            {AppData.landingPageMGLists.map((item, index) => {
                                return (
                                    <li key={index} className='navi-item'>
                                        <a href='#' className='navi-link py-6' onClick={(e) => {
                                            e.preventDefault();
                                            if (!_.isEmpty(item?.url)) {
                                                history.push(item?.url);
                                            }
                                        }}>
                                            <span className="navi-icon"><i className="far fa-angle-right fa-1x" /></span>
                                            <span className="navi-text font-size-lg font-weight-bold">{item.text}</span>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                        <button
                            className='LandingPageSectionInfo4_Button'
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    history.push('/sign-up');
                                }
                            }
                        >
                            {t('OpenAccount')}
                        </button>
                    </div>

                    {/* Right */}
                    <div className='col-12 col-md-5 d-flex align-items-center'>
                        <img
                            alt=''
                            draggable={false}
                            src={AppImages.imgLandingPage04}
                            className='w-100'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionInfo4;