import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthHeader from 'features/Auth/components/AuthHeader';
import AuthContent from 'features/Auth/components/AuthContent';
import { Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import * as Yup from 'yup';
import './style.scss';
import accountApi from 'api/accountApi';
import ToastHelper from 'general/helpers/ToastHelper';
import LoadingOverlay from 'general/components/LoadingOverlay';

ForgotPasswordScreen.propTypes = {

};

const sTag = '[ForgotPasswordScreen]';

function ForgotPasswordScreen(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: localStorage.getItem(PreferenceKeys.savedEmail) ?? '',
        },
        onSubmit: async (values) => {
            const email = values.email;
            setLoading(true);
            try {
                const res = await accountApi.requestResetPassword(email);
                const { result, expirationDate } = res;
                setLoading(false);
                if (result === 'success') {
                    if (expirationDate) {
                        localStorage.setItem(PreferenceKeys.savedResetPasswordExpirationDate, expirationDate);
                    }
                    ToastHelper.showSuccess('Vui lòng kiểm tra email và làm theo hướng dẫn');
                    history.push('/reset-password');
                }
            } catch (error) {
                console.log(`${sTag} Error: ${error}`);
                setLoading(false);
            }

        },
        validationSchema: Yup.object({
            email: Yup.string().required(t('EmailIsRequired')).email(t('EmailNotValid')),
        }),
    });

    // MARK: --- Functions ---

    // MARK: --- Hooks ---

    return (
        <div className='ForgotPasswordScreen min-vh-100 d-flex flex-column'>
            <AuthHeader />
            <AuthContent
                leftTitle='Bạn Quên Mật Khẩu?'
                leftDescription='Đừng lo! Hãy nhập Email của bạn và làm theo hướng dẫn để cập nhật mật khẩu mới'
                rightElement={(
                    <div>
                        <Card className={`rounded-lg overflow-hidden ${loading ? 'overlay overlay-block' : ''}`}>
                            <Card.Header className='border-0'>
                                <p className='font-size-h3 font-weight-boldest m-0 text-center'>{t('ForgotPassword')}</p>
                            </Card.Header>
                            <Card.Body className='pt-0'>
                                <div className=''>
                                    <form onSubmit={formik.handleSubmit}>
                                        <BaseTextField
                                            name='email'
                                            placeholder={`${t('Email')}...`}
                                            label={t('Email')}
                                            fieldHelper={formik.getFieldHelpers('email')}
                                            fieldProps={formik.getFieldProps('email')}
                                            fieldMeta={formik.getFieldMeta('email')}
                                        />

                                        <button
                                            className="btn btn-primary btn-lg w-100 border-0 font-weight-bold"
                                            onClick={() => {

                                            }}
                                        >
                                            {t('Send')}
                                        </button>
                                    </form>
                                </div>
                            </Card.Body>
                            <Card.Footer className='d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: '#F3F5F8', borderTopColor: '#DBE3EF' }}>
                                <p className='m-0 text-center font-weight-boldest font-size-h6'>Quay lại màn hình</p>
                                <button onClick={() => {
                                    history.goBack();
                                }} style={{ backgroundColor: '#4886FF' }} className="btn btn-linkedin btn-lg mt-4 w-100 border-0 hover-opacity-80 font-weight-bold">{t('SignIn')}</button>
                            </Card.Footer>

                            {
                                loading && (
                                    <LoadingOverlay />
                                )
                            }
                        </Card>
                    </div>
                )}
            >

            </AuthContent>
        </div>
    );
}

export default ForgotPasswordScreen;