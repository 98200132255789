import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

Technical.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

Technical.defaultProps = {
    data: [],
    loading: false,
};

function Technical(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { data, loading } = props;
    const history = useHistory();

    return (
        <div className='border-left border-bottom border-right pt-0 bg-white'>
            <MGDataTable
                className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                loading={loading}
                headItems={[
                    { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                    { title: t('MarketCap'), subTitle: 'Tỷ VNĐ', col: 'col-1', textAlign: 'right' },
                    { title: t('PriceToday'), subTitle: `${t('High')}/${t('Low')}`, col: 'col-1', textAlign: 'right' },
                    { title: t('Volume'), subTitle: '', col: 'col-1', textAlign: 'right' },
                    { title: t('TransactionValue'), subTitle: 'Tỷ VNĐ', col: 'col-1', textAlign: 'right' },
                    { title: t('Price52Weeks'), subTitle: `${t('High')}/${t('Low')}`, col: 'col-1', textAlign: 'right' },
                    {
                        title: '', subTitle: '', component: (
                            <div className='d-flex flex-column align-items-center'>
                                <div className='border-bottom w-100 d-flex align-items-center justify-content-center py-1'>
                                    <span className='py-2' style={{
                                        fontWeight: '600',
                                        lineHeight: '171%',
                                        color: '#23262f'
                                    }}>{t('Trend')}</span>
                                </div>
                                <div className='row w-100'>
                                    <span className='col-4 py-2 d-flex align-items-center justify-content-center' style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#5c6077'
                                    }}>{t('Day')}</span>
                                    <span className='col-4 py-2 border-left border-right d-flex align-items-center justify-content-center' style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#5c6077'
                                    }}>{t('Month')}</span>
                                    <span className='col-4 py-2 d-flex align-items-center justify-content-center' style={{
                                        fontWeight: '400',
                                        lineHeight: '180%',
                                        color: '#5c6077'
                                    }}>{t('Year')}</span>
                                </div>
                            </div>
                        ), col: 'col-1'
                    }
                ]}
                dataItems={
                    data.map((item, index) => {
                        const { realtimePrice, yesterdayRealtimePrice, lastMonthRealtimePrice, lastYearRealtimePrice } = item;
                        let trendDay = '';
                        let trendDayColor = '#F4B638';
                        if (realtimePrice) {
                            if (yesterdayRealtimePrice) {
                                trendDay = realtimePrice === yesterdayRealtimePrice ? t('Neutral') : (realtimePrice < yesterdayRealtimePrice ? t('Down') : t('Up'));
                                trendDayColor = realtimePrice === yesterdayRealtimePrice ? '#F4B638' : (realtimePrice < yesterdayRealtimePrice ? '#FF0017' : '#0BDF39');
                            }
                        }
                        let trendMonth = '';
                        let trendMonthColor = '#F4B638';
                        if (realtimePrice) {
                            if (lastMonthRealtimePrice) {
                                trendMonth = realtimePrice === lastMonthRealtimePrice ? t('Neutral') : (realtimePrice < lastMonthRealtimePrice ? t('Down') : t('Up'));
                                trendMonthColor = realtimePrice === lastMonthRealtimePrice ? '#F4B638' : (realtimePrice < lastMonthRealtimePrice ? '#FF0017' : '#0BDF39');
                            }
                        }
                        let trendYear = '';
                        let trendYearColor = '#F4B638';
                        if (realtimePrice) {
                            if (lastYearRealtimePrice) {
                                trendYear = realtimePrice === lastYearRealtimePrice ? t('Neutral') : (realtimePrice < lastYearRealtimePrice ? t('Down') : t('Up'));
                                trendYearColor = realtimePrice === lastYearRealtimePrice ? '#F4B638' : (realtimePrice < lastYearRealtimePrice ? '#FF0017' : '#0BDF39');
                            }
                        }

                        return {
                            onPress: () => {
                                history.push(`/stock/${item?.stockCode}`);
                            },
                            data: [
                                { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item?.companyName, name: '', value: '', valueColor: '', col: 'col-3' },
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber(parseFloat(item?.capital / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: `${Utils.formatNumber(item?.maxDayPrice)}/${Utils.formatNumber(item?.minDayPrice)}`, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: Utils.formatNumber(item?.totalVolume), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber(parseFloat(item?.totalValue / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                { icon: '', title: '', name: `${Utils.formatNumber(item?.max52w)}/${Utils.formatNumber(item?.min52w)}`, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                {
                                    icon: '', title: '', name: '', nameColor: '', value: '', valueColor: '', col: 'col-2', component: (
                                        <div className='d-flex flex-column align-items-center w-100'>
                                            <div className='row w-100'>
                                                <span className='col-4 py-1 d-flex align-items-center justify-content-center' style={{
                                                    fontWeight: '400',
                                                    lineHeight: '180%',
                                                    color: trendDayColor
                                                }}>{trendDay}</span>
                                                <span className='col-4 py-1 d-flex align-items-center justify-content-center' style={{
                                                    fontWeight: '400',
                                                    lineHeight: '180%',
                                                    color: trendMonthColor
                                                }}>{trendMonth}</span>
                                                <span className='col-4 py-1 d-flex align-items-center justify-content-center' style={{
                                                    fontWeight: '400',
                                                    lineHeight: '180%',
                                                    color: trendYearColor
                                                }}>{trendYear}</span>
                                            </div>
                                        </div>
                                    )
                                },
                            ]
                        }
                    })
                }
            />
        </div>
    );
}

export default Technical;
