import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppImages } from 'general/constants/AppResource';

LandingPageSectionInfo1.propTypes = {

};

function LandingPageSectionInfo1(props) {
    return (
        <div className='LandingPageSectionInfo1 pt-10 pb-10 pb-md-0'>
            <div className='container'>
                <div className='row'>
                    {/* Left */}
                    <div className='col-12 col-md-5'>
                        <img
                            draggable={false}
                            alt=''
                            src={AppImages.imgLandingPage01}
                            className='w-100'
                        />
                    </div>

                    {/* Right */}
                    <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <p className='mt-sm-4 LandingPageSectionInfo1_TitleSmall'>Tại sao nên sử dụng <span className='LandingPageSectionInfo1_TitleLarge'>Money Gain</span></p>
                        <p className='m-0 text-justify font-size-h6'>Có phải bạn đang thường dễ dàng nghe theo các “lời khuyên miễn phí” đến từ bạn bè, những người tư vấn, những người nghe ngóng thông tin từ các đội lái…Và có phải bạn chưa biết cách và chưa có những tiêu chí để lựa chọn một cổ phiếu, chưa biết cách xác định thời điểm mua vào và thời điểm bán ra? Sau những cú sụp đổ của thị trường, các nhà đầu tư đã nhận ra, may mắn chỉ nhất thời nhưng kiến thức giúp họ kiếm sống suốt một cuộc đời. Họ phải tự chịu trách nhiệm và học cách tự đầu tư số tiền mồ hôi nước mắt của mình. </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionInfo1;