import React from "react";
import PropTypes from "prop-types";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";
import ScrollTop from "../ScrollTop";

AppLayout.propTypes = {
  className: PropTypes.string,
};

AppLayout.defaultProps = {
  className: '',
};

function AppLayout(props) {
  // MARK: --- Params ---
  const { className } = props;

  return (
    <div className={`d-flex flex-column flex-root ${className}`}>
      {/* begin::Page */}
      <div className="d-flex flex-row flex-column-fluid page">
        {/* begin::Wrapper */}
        <div
          className="d-flex flex-column flex-row-fluid wrapper"
          id="kt_wrapper"
        >
          {/* begin::Header */}
          <AppHeader />
          {/* end::Header */}

          {/* begin::Content */}
          <div className="d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="d-flex flex-column-fluid">
              <div className="w-100">{props.children}</div>
            </div>
          </div>
          {/* end::Content */}

          {/* begin::Footer */}
          <AppFooter />
          {/* end::Footer */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Page */}
      <ScrollTop />
    </div>
  );
}

export default AppLayout;
