import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {FieldHelperProps, FieldMetaProps, FieldInputProps} from 'formik'

import './style.scss'


BaseFileInput.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,

    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelpers: PropTypes.object,
};

BaseFileInput.defaultProps = {
    className: '',
    disabled: false,
    text: "",

    fieldProps: {},
    fieldHelpers: {},
    fieldMeta: {},
};

/**
 * @typedef Props
 * @property {string} name (string) id and name of input
 * @property {string} className container class name
 * @property {string} text Text when file is empty
 * @property {FieldInputProps} fieldProps for field helper
 * @property {FieldHelperProps} fieldHelpers for field helper
 * @property {FieldMetaProps} fieldMeta for field helper
 * @property {boolean=} disabled
 * @property {string=} fileExtension file extension , `e.g:` ".txt, .png"
 * 
 * @param {Props} props
 */
function BaseFileInput(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();

    const { name, className, disabled, text, fieldProps, fieldHelpers, fieldMeta, fileExtension } = props

    const { error, touched } = fieldMeta
    const isError = error && touched

    const dropZoneRef = useRef(null)
    const inputRef = useRef(null)

    // MARK: --- Functions ---
    async function handleFiles(file) {
        await fieldHelpers.setValue(file)
        fieldHelpers.setTouched(true)
    }

    function onDragOver(e) {
        e.preventDefault()
        dropZoneRef.current.classList.add("shadow")
    }

    function onDragLeave(e) {
        e.preventDefault()
        dropZoneRef.current.classList.remove("shadow")
    }

    function onDrop(e) {
        e.preventDefault()
        const files = e.dataTransfer.files
        dropZoneRef.current.classList.remove("shadow")
        if (files && files.length) handleFiles(files[0])
    }

    function onChange(e) {
        e.preventDefault()
        const files = e.target.files;
        if (files && files.length) handleFiles(files[0])
    }


    return (
        <div
            className={`${className} cursor-pointer`}
            align='center'
            ref={dropZoneRef}
            onClick={() => inputRef.current.click()}
            onDragOver={(e) => onDragOver(e)}
            onDragLeave={(e) => onDragLeave(e)}
            onDrop={(e) => onDrop(e)}
        >
            {
                !isError && fieldProps.value === null &&
                (<div className='FileInput d-flex flex-column px-20 py-25'>
                    <i className="far fa-file-upload"></i>
                    <p className='Tittle_24B Capitalize'>{t('ChooseFile')}</p>
                    <span style={{ color: '#A4A9C5' }}>
                        {text}
                    </span>
                </div>)
            }
            {
                isError &&
                (
                    <div className='FileInput_Result d-flex flex-row'>
                        <div className='file-upload-icon-box bg-white p-3 mr-3'>
                            <i className="far fa-file-upload" style={{ color: '#E84650' }}></i>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <p className='FileName'>{fieldProps.value?.name}</p>
                            <p className='Number_14' style={{ color: '#E84650' }}>{t('UploadFailed')}, {t(error)}</p>
                        </div>
                    </div>
                )
            }
            {
                !isError && fieldProps.value !== null &&
                (
                    <div className='FileInput_Result d-flex flex-row'>
                        <div className='file-upload-icon-box bg-white p-3 mr-3'>
                            <i className="far fa-file-upload" style={{ color: '#68C57C' }}></i>
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <p className='FileName'>{fieldProps.value.name}</p>
                            <p className='Number_14' style={{ color: '#68C57C' }}>{t('FileUploadedSuccessfully')}</p>
                        </div>
                    </div>
                )
            }

            <input
                type="file"
                disabled={!!disabled}
                id={name} name={name}
                className="drop-zone__input"
                ref={inputRef}
                onChange={(e) => onChange(e)}
                accept={fileExtension}
            />
        </div>
    )
}

export default BaseFileInput;
