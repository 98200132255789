import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import LandingPageSectionInfo3Item from './Item';
import { AppImages } from 'general/constants/AppResource';

LandingPageSectionInfo3.propTypes = {

};

function LandingPageSectionInfo3(props) {
    return (
        <div className='LandingPageSectionInfo3 py-10 text-center'>
            <div className='container-xxl'>
                <p className='LandingPageSectionInfo3_Title m-0 my-10'>Một bức tranh trị giá hàng triệu đô la</p>

                <LandingPageSectionInfo3Item
                    title='Định Thời Điểm Là Tất Cả'
                    content={(
                        <span>Định thời điểm là tất cả. Biểu đồ MoneyGain cung cấp nhiều hơn nhiều so với dữ liệu giá/khối lượng mà bạn tìm thấy trên các công cụ biểu đồ khác. <br />
                            Trong biểu đồ MoneyGain, bạn sẽ thấy toàn bộ chiến lược đầu tư được đặt ra trước mắt. Bạn sẽ không chỉ thấy các mô hình tăng trưởng trong quá khứ, bạn cũng sẽ thấy các mô hình hiện đang hình thành cùng với các điểm mua, bán và chốt lời được đề xuất. Với biểu đồ của mỗi cổ phiếu, bạn sẽ thấy dữ liệu cơ bản cập nhật, được xếp hạng và đánh giá. Vì vậy, bạn không bao giờ phải rời khỏi MoneyGain để tìm kiếm dữ liệu cơ bản về tăng trưởng doanh thu, lợi nhận, EPS …trong quá khứ và cập nhất mới nhất.</span>
                    )}
                    icon={AppImages.imgLandingPageChart01}
                    reverse={true}
                />
                <LandingPageSectionInfo3Item
                    title='Triển Vọng Thị Trường'
                    content={(
                        <p>
                            <p>Biểu đồ chứng khoán tại MoneyGain kết hợp hoàn hảo giữa dữ liệu cơ bản quan trọng và các chỉ số kỹ thuật chính. Hơn 70 mục dữ liệu riêng lẻ, chỉ số và xếp hạng và xếp hạng độc quyền giúp cho các nhà đầu tư với các phong cách đầu tư khác nhau để nhanh chóng đánh giá sức khỏe tài chính và sức mạnh giá của bất kỳ cổ phiếu nào. <br />
                                Biểu đồ cổ phiếu của chúng tôi giúp việc xác định xu hướng và mô hình trở nên đơn giản. Ngoài ra, biểu đồ MoneyGain chứa các tính năng hữu ích để phân tích nhanh:</p>
                            <ul className='mt-6'>
                                <li>Đường sức mạnh giá</li>
                                <li>Đường trung bình tùy chỉnh (phân tích mẫu theo Mark Minervini)</li>
                                <li>Các sự kiện của công ty như giao dịch nội bộ, kết quả kinh doanh</li>
                                <li>Đánh dấu và chú thích</li>
                            </ul>
                        </p>
                    )}
                    icon={AppImages.imgLandingPageChart02}
                />
                <LandingPageSectionInfo3Item
                    title='Đánh Giá Cổ Phiếu'
                    content={(
                        <p>
                            <p>Đặc biệt, cũng trong cùng một giao dịch, các thông tin cơ bản cốt lõi giúp nhà đầu tư đưa ra những đánh giá sức khỏe tài chính của công ty được hiện thị một cách ấn tượng</p>
                            <ul className='mt-6'>
                                <li>Doanh thu và thu nhập hàng quý và hàng năm</li>
                                <li>Các đối thủ cạnh tranh hàng đầu trong nhóm ngành</li>
                                <li>Mô tả công ty với các cập nhật thích hợp</li>
                                <li>Chỉ số tài chính mô tả tình hình tài chính của công ty tiềm năng tăng trưởng của Công ty</li>
                                <li>Xếp hạng và Xếp hạng độc quyền</li>
                            </ul>
                        </p>
                    )}
                    icon={AppImages.imgLandingPageChart03}
                    reverse={true}
                />
            </div>
        </div>
    );
}

export default LandingPageSectionInfo3;