import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { cleanResult, thunkDeleteFilter, thunkEditFilter, thunkGetAllFilter } from 'features/IdeaList/IdeaListSlice';
import { AppIcons } from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import ModalConfirmDelete from 'general/components/ModalConfirmDelete';
import EmptyView from 'general/components/Views/EmptyView';

ModalManageFilters
    .propTypes = {
    onClose: PropTypes.func,
    onShow: PropTypes.func
};

ModalManageFilters.defaultProps = {
    onClose: null,
    onShow: null
}

function ModalManageFilters(props) {

    const listFilters = useSelector(state => state?.ideaList?.listFilters);
    const [isEditedID, setIsEditedId] = useState();
    const [filterNameInput, setFilterNameInput] = useState('');
    const [modalConfirmDeleteFilter, setModalConfirmDeleteFilter] = useState(false);
    const [filterIdDelete, setFilterIdDelete] = useState();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function handleShow() {
        if (props.onShow) {
            props.onShow();
        }
    }

    function handleClose() {
        setIsEditedId();
        if (props.onClose) {
            props.onClose();
        }
    }

    // function handleCleanResult(){
    //     if(listFilters.length == 0){
    //         dispatch(cleanResult())
    //     }
    // }
    useEffect(() => {
        if (listFilters.length == 0) {
            dispatch(cleanResult())
        }
    }, [listFilters.length])

    function handleEditFilter(e) {
        if (e.key === 'Enter' || e === 'change') {
            if (filterNameInput === '') {
                ToastHelper.showError(`${t('FilterNameCanNotBeEmpty')}`)
                return
            }
            for (let i = 0; i < listFilters.length; i++) {
                if (filterNameInput === listFilters[i].filterName) {
                    if (isEditedID === listFilters[i].filterId) {
                        //ToastHelper.showError(`${t('FilterNameUnChange')}`)
                    } else {
                        ToastHelper.showError(`${t('FilterNameIsExisted')}`)
                    }
                }
            }
            dispatch(thunkEditFilter({ "filterId": isEditedID, "name": filterNameInput }));
            setIsEditedId();
        }

    }

    function handleChangeFilterName(e) {
        setFilterNameInput(e.target.value)
    }

    return (
        <div className='d-flex flex-column '>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={handleClose}
                centered
            >
                {listFilters.length == 0
                    ? <div className='d-flex align-items-center justify-content-center py-10 font-weight-light'>
                        <EmptyView
                            iconEmpty={AppIcons.icEmptyPack}
                            description={t('NoDataToDisplay')}
                        />
                    </div>
                    : <div>
                        {/* modal header */}
                        <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                            <span className='font-weight-bolder font-size-base ml-5'>{t('ManageFilters')}</span>
                            <div
                                onClick={() => {
                                    handleClose();
                                }}
                                className='btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer mr-6'>
                                <i className="fal fa-times"></i>
                            </div>
                        </div>
                        {/* modal body  */}
                        <div className='bg-light p-3 rounded'>
                            <div className='bg-white border ' style={{ maxHeight: 311, overflowY: 'auto', overflowX: 'hidden' }}>
                                {listFilters.map((item, index) => {
                                    return (
                                        <div
                                            key={item.filterId}
                                            className={`border-top py-3 d-flex flex-row justify-content-between align-items-center ${index == 0 && 'border-top-0'}`}>
                                            {isEditedID === item.filterId
                                                ?
                                                <input
                                                    className='form-control py-0 ml-3'
                                                    defaultValue={item.filterName}
                                                    onChange={handleChangeFilterName}
                                                    onKeyPress={handleEditFilter}
                                                    spellCheck='false'
                                                    style={{ flex: '1 1 auto' }}
                                                />
                                                : <span className='ml-7'>{item.filterName}</span>}
                                            <div
                                                className='p-3'
                                                style={{ flex: '0 0 auto' }}
                                            >
                                                {(isEditedID !== item.filterId)
                                                    ? <img
                                                        src={AppIcons.icSquarePen}
                                                        className='mr-5 cursor-pointer hover-opacity-70'
                                                        onClick={() => {
                                                            (!isEditedID || isEditedID !== item.filterId) ? setIsEditedId(item.filterId) : setIsEditedId();
                                                            setFilterNameInput(item.filterName);
                                                        }}
                                                    />
                                                    : <img
                                                        src={AppIcons.icCheck}
                                                        draggable={false}
                                                        draggable={false}
                                                        className='mr-5 cursor-pointer hover-opacity-70'
                                                        onClick={() => {
                                                            setIsEditedId();
                                                            handleEditFilter('change');
                                                        }} />
                                                }
                                                <img
                                                    src={AppIcons.icSquareXMark}
                                                    draggable={false}
                                                    className='cursor-pointer hover-opacity-70'
                                                    onClick={() => {
                                                        setModalConfirmDeleteFilter(true);
                                                        setFilterIdDelete(item.filterId)
                                                        handleClose();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>}
            </Modal>
            <ModalConfirmDelete
                show={modalConfirmDeleteFilter}
                onClose={() => {
                    setModalConfirmDeleteFilter(false);
                    handleShow();
                }}
                deleteItem={() => {
                    dispatch(thunkDeleteFilter({ "filterId": filterIdDelete }));
                    setFilterIdDelete();
                }}
                deleteText={t('ConfirmDeleteFilterText')}
                onShow={() => { setModalConfirmDeleteFilter(true) }}
            />

        </div>
    );
}

export default ModalManageFilters
    ;