import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import { thunkGetCommonData, thunkGetHomeSectorIndexData, thunkGetRealtimeIndexes, thunkGetReportNormData, thunkGetTopProfitCompany } from './appSlice';
import { useLocation } from 'react-router-dom';

DataCommonListener.propTypes = {

};

const sTag = '[DataCommonListener]';

function DataCommonListener(props) {
    // MARK: --- Params ---
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.auth.current);

    const location = useLocation();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} did load`);

        return () => {
            console.log(`${sTag} will dismiss`);
        }
    }, []);

    useEffect(() => {
        // Get common data
        dispatch(thunkGetCommonData({}));

        // Get realtime indexes
        dispatch(thunkGetRealtimeIndexes({}));

        // get report norm data
        dispatch(thunkGetReportNormData());

        // get top profit company
        dispatch(thunkGetTopProfitCompany({
            limit: 6,
        }));

        // get home sector index data
        dispatch(thunkGetHomeSectorIndexData());
    }, [loggedInUser]);

    useEffect(()=>{
        Utils.scrollToTop();
    },[location])

    return (<></>);
}

export default DataCommonListener;