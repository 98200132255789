import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'general/components/AppLayout';
import './style.scss';
import privacyPolicy from 'general/constants/PrivacyPolicy';
import { Markup } from 'interweave';
import Utils from 'general/utils/Utils';

PolicyPrivacyScreen.propTypes = {

};

const sTag = '[PolicyPrivacyScreen]';

function PolicyPrivacyScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);
        Utils.scrollToTop();

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        }
    }, []);

    // MARK: --- Functions ---

    return (
        <AppLayout>
            <div className='PolicyPrivacyScreen min-h-600px bg-white'>
                <div className='PolicyPrivacyScreen_Header py-lg-30 py-12'>{t('PrivacyPolicy').toUpperCase()}</div>
                <div className='container-xxl py-20 font-size-lg text-justify'>
                    <Markup content={privacyPolicy} />
                </div>
            </div>
        </AppLayout>
    );
}

export default PolicyPrivacyScreen;