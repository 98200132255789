import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import KnowledgeCenterHomeScreen from './screens/KnowledgeCenterHomeScreen';
import NotFound from 'general/components/NotFound';
import WebinarDetailScreen from 'features/Webinar/screens/WebinarDetailScreen';
import ArticleDetailScreen from 'features/Article/screens/ArticleDetailScreen';
import VideoDetailScreen from 'features/Video/screens/VideoDetailScreen';

KnowledgeCenter.propTypes = {

};

function KnowledgeCenter(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match.url}/webinar/:webinarId`}
                component={WebinarDetailScreen}
            />
            <Route
                path={`${match.url}/article/:articleId`}
                component={ArticleDetailScreen}
            />
            <Route
                path={`${match.url}/video/:videoId`}
                component={VideoDetailScreen}
            />
            <Route
                path={`${match.url}/investing-university/article/:articleId`}
                component={ArticleDetailScreen}
            />
            <Route
                path={`${match.url}/investing-university/research/:articleId`}
                component={ArticleDetailScreen}
            />
            <Route
                path={`${match.url}/investing-university/video/:videoId`}
                component={VideoDetailScreen}
            />
            <Route
                path={`${match.url}`}
                component={KnowledgeCenterHomeScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default KnowledgeCenter;