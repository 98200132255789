import stockApi from 'api/stockApi';
import ModalTopStocks from 'features/Stock/components/ModalTopStocks';
import { useFormik } from 'formik';
import AppTabs from 'general/components/AppTabs';
import BaseDropdownSelect from 'general/components/Forms/FormControls/BaseDropdownSelect';
import MGDataTable from 'general/components/Tables/MGDataTable';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const currentDate = moment().local().format('YYYY-MM-DD');

function HomeSectionTopStock(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const [modalTopStockShowing, setModalTopStockShowing] = useState(false);
    const formik = useFormik({
        initialValues: {
            option: 'CHANGE_ASC'
        },
        onSubmit: (values) => { }
    });
    const [filter, setFilter] = useState({
        date: currentDate,
        exchangeCode: '',
        sortBy: 'changeInPercent',
        sortDirection: 'DESC',
        page: 0,
        limit: 10,
    });
    const [topStocks, setTopStocks] = useState([]);
    let exchangeCodes = [...AppData.allExchangeCode];
    exchangeCodes.unshift(t('All'));
    const [selectedTab, setSelectedTab] = useState(exchangeCodes[0]);
    const [loading, setLoading] = useState(false);

    // MARK: --- Functions ---
    function handleSelectedTab(tab) {
        setFilter({
            ...filter,
            exchangeCode: AppData.allExchangeCode.includes(tab) ? tab : '',
        })
        setSelectedTab(tab);
    }

    function handlePressStock(stock) {
        if (stock) {
            history.push(`/stock/${stock?.stockCode}`);
        }
    }

    function handlePressViewMore() {
        setModalTopStockShowing(true);
    }

    async function getRealtimeTopStocks() {
        setLoading(true);
        setTopStocks([]);
        let formattedFilter = { ...filter };
        Utils.removeEmptyKey(formattedFilter);
        const res = await stockApi.realtimeTopStocks(formattedFilter);
        const { result, data } = res;
        if (result === 'success' && data) {
            setTopStocks(data);
        }
        setLoading(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getRealtimeTopStocks();
    }, [filter]);

    useEffect(() => {
        const interval = setInterval(() => {
            getRealtimeTopStocks();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className='bg-light'>
            <div className='container-xxl py-20'>
                <SectionHeaderView
                    title={t('TopStocks')}
                    buttonTitle={t('ViewAll')}
                    dividerVisible={false}
                    onPressButton={handlePressViewMore}
                />
                <div className='mt-2'>
                    <div className='d-flex justify-content-between align-items-end'>
                        <AppTabs
                            tabs={exchangeCodes.reverse()}
                            activeTab={selectedTab}
                            handleClick={handleSelectedTab}
                            className='flex-grow-1'
                        />
                        <div className='border-bottom pb-2'>
                            <BaseDropdownSelect
                                name='selectFilterOption'
                                label={t('Sort')}
                                options={AppData.sampleStockOptions}
                                fieldHelpers={formik.getFieldHelpers('option')}
                                fieldMeta={formik.getFieldMeta('option')}
                                fieldProps={formik.getFieldProps('option')}
                                onValueChanged={(value) => {
                                    if (value === 'CHANGE_ASC') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'changeInPercent',
                                            sortDirection: 'DESC'
                                        })
                                    }
                                    if (value === 'CHANGE_DESC') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'changeInPercent',
                                            sortDirection: 'ASC'
                                        })
                                    }
                                    if (value === 'VOLUME') {
                                        setFilter({
                                            ...filter,
                                            sortBy: 'totalVolume',
                                            sortDirection: 'DESC'
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <MGDataTable
                        className='bg-white border-top-0'
                        loading={loading}
                        headItems={[
                            { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                            { title: t('Price'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'end' },
                            { title: t('Change'), subTitle: '%', col: 'col-1', textAlign: 'end' },
                            { title: t('Volume'), subTitle: `${t('Change')} (%)`, col: 'col-1', textAlign: 'end' },
                            { title: t('TotalPoint'), subTitle: 'MG', col: 'col-1', textAlign: 'end' },
                            { title: t('Strength'), subTitle: 'EPS', col: 'col-1', textAlign: 'end' },
                            { title: t('Strength'), subTitle: t('Price'), col: 'col-1', textAlign: 'end' },
                            { title: t('Ranking'), subTitle: t('Demand'), col: 'col-1', textAlign: 'end' },
                            { title: t('Ranking'), subTitle: t('Group'), col: 'col-1', textAlign: 'end' },
                        ]}
                        dataItems={
                            // (selectedTab === t('All') ? topStocks : topStocks.filter(item => item.exchangeCode === selectedTab)).map((item, index) => {
                            topStocks.map((item, index) => {
                                console.log({ item });
                                return {
                                    onPress: () => {
                                        handlePressStock(item);
                                    },
                                    data: [
                                        { icon: '', title: item?.stockCode ?? item?.stockName ?? ' ', titleWeight: '600', subTitle: item.stockName, name: '', value: '', valueColor: '', col: 'col-3' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.closingPrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: `${!isNaN(parseFloat(item?.changeInPercent).toFixed(2)) ? Utils.formatNumber(parseFloat(item.changeInPercent).toFixed(2)) : '0'} %`, nameColor: Utils.getStockColor(parseFloat(item.changeInPercent)), value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.totalVolume), nameColor: '', value: `${Utils.formatNumber(parseFloat(item.changeOfVolumeInPercent).toFixed(2))} %`, valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.summaryStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.priceStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: item?.demandStrength, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'end' },
                                    ]
                                }
                            })
                        }
                    />
                </div>
            </div>

            {/* Modal top stocks */}
            <ModalTopStocks
                show={modalTopStockShowing}
                onClose={() => {
                    setModalTopStockShowing(false);
                }}
            />
        </div>
    );
}

export default HomeSectionTopStock;