import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppIcons } from 'general/constants/AppResource';
import './style.scss';
import { useHistory } from 'react-router-dom';

CellAccountMenuGrid.propTypes = {
    item: PropTypes.object,
};

CellAccountMenuGrid.defaultProps = {
    item: {},
};

function CellAccountMenuGrid(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { item } = props;
    const { icon, title, description, detail, url } = item;
    const history = useHistory();

    // MARK: --- Functions ---
    function handleClick() {
        history.push(url);
    }

    return (
        <div
            className='CellAccountMenuGrid d-flex flex-column align-items-center py-10 cursor-pointer h-100'
            onClick={handleClick}
        >
            <img
                className='w-95px h-95px'
                src={icon}
                alt={title}
                draggable={false}
            />
            <div className='CellAccountMenuGrid_TextContent'>
                <p className='font-size-h6 font-weight-boldest text-center m-0 my-4'>{t(title)}</p>
                <p className='font-size-lg text-center m-0 my-3'>{t(description)}</p>
                <p className='font-size-lg text-primary font-weight-bolder text-center m-0'>{t(detail)}</p>
            </div>

        </div>
    );
}

export default CellAccountMenuGrid;