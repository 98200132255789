import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';

CellNewVideoGrid.propTypes = {
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func,
};

CellNewVideoGrid.defaultProps = {
    onPress: null,
}

function CellNewVideoGrid(props) {
    // MARK: --- Params ---
    const { item, onPress } = props;
    const { createdAt, description, name, thumbnail, updatedAt, url } = item;

    // MARK: --- Functions ---
    function handlePress() {
        if (onPress) {
            onPress();
        }
    }

    return (
        <div className='CellReviewGrid bg-dark d-flex flex-column justify-content-end w-100 h-225px position-relative cursor-pointer hover-opacity-80' onClick={handlePress}>
            <img
                alt='thumbnail'
                draggable={false}
                src={Utils.getFullUrl(thumbnail)}
                className='w-100 h-100 position-absolute top-0 left-0 zindex-0'
                style={{
                    objectFit: 'cover',
                    border: '1px solid black'
                }}
            />
            <div className='px-6 pb-3 pt-6 zindex-1' style={{
                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8) 39.45%, rgba(0, 0, 0, 0) 100%)',
            }}>
                <p className='font-size-sm font-weight-normal m-0 text-white'>{Utils.formatDateTime(updatedAt, 'DD/MM/YYYY HH:mm')}</p>
                <p className='font-size-h6 m-0 text-white font-weight-bold q-max-line-2'>{name}</p>
            </div>
        </div>
    );
}

export default CellNewVideoGrid;