import ModalAssociates from 'features/Stock/components/ModalAssociates';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import AppTabs from 'general/components/AppTabs';
import MGDataTable from 'general/components/Tables/MGDataTable';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import ShowMoreText from "react-show-more-text";
import ModalExecutiveBoard from '../ModalExecutiveBoard';
import './index.scss';

Section_1.propTypes = {
    company: PropTypes.object,
    associates: PropTypes.array,
    boardOfManagements: PropTypes.array,
    revenues: PropTypes.array,
    laborStructures: PropTypes.array,
    reportData: PropTypes.object,
    financeReportByTime: PropTypes.object,
};

Section_1.defaultProps = {
    company: {},
    associates: [],
    boardOfManagements: [],
    revenues: [],
    laborStructures: [],
    reportData: {},
    financeReportByTime: {},
};

function Section_1(props) {
    const {
        company,
        associates,
        boardOfManagements,
        revenues,
        laborStructures,
        reportData,
        financeReportByTime
    } = props;
    const { t } = useTranslation();

    const employees = laborStructures.map(item => item.numOfEmployees);
    const totalEmployees = employees.reduce((sum, number) => {
        return sum + number;
    }, 0);

    const info = [
        { label: t('Address'), value: company.address, },
        { label: t('PhoneNumber'), value: company.phone, },
        { label: t("Fax"), value: company.fax, },
        { label: t("Email"), value: company.email, },
        { label: t("Website"), value: company.website, },
        { label: t('Industry'), value: company.industry.name },
        // linh vuc
        { label: t('Lĩnh Vực'), value: company?.subIndustry?.name },
    ];
    const listedInfo = [
        { label: t('ListingDate'), value: Utils.formatDateTime(company.postUpDate, 'DD/MM/YYYY') },
        { label: t('FirstTradingSessionPrice'), value: `${Utils.formatNumber(company.firstTradingSessionPrice)} VNĐ` },
        // { label: t('InitialListingVolume'), value: '' },
        { label: t('ListingVolume'), value: Utils.formatNumber(reportData.klcpny) },
        { label: t('ListingVolumeCirculate'), value: Utils.formatNumber(reportData.klcplh) },
        { label: t('ListingPlace'), value: company.stock.exchangeCode },
    ];
    const text = company.introduction;
    const options = {
        chart: {
            id: 'apexchart-pie-revenue'
        },
        labels: revenues.map(item => item.name),
        colors: AppData.apexChartColors,
    };
    const series = revenues.map(item => item.revenue);
    const pieChartFeature = revenues.map((item, index) => {
        return {
            label: item.name,
            color: AppData.apexChartColors[index]
        }
    })
    const tabs = [
        t('FinanceReportByQuarter'),
        t('FinanceReportByYear')
    ];
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    let arrQuarter = [];
    let arrYears = [];
    const arrProfits = [];
    _.forEach((financeReportByTime.profits ?? []), (item, index) => {
        const quarterYear = `${item?.termCode}/${item?.yearPeriod}`;
        arrProfits.push({
            quarterYear: quarterYear,
            quarter: item.termCode,
            value: item.value,
            year: item.yearPeriod,
        });
        if (!_.includes(arrQuarter, quarterYear) && item.termCode !== 'N') {
            arrQuarter.push(quarterYear);
        }
        if (!_.includes(arrYears, item.yearPeriod) && item.termCode === 'N') {
            arrYears.push(item.yearPeriod);
        }
    });

    const arrRevenues = [];
    _.forEach((financeReportByTime.revenues ?? []), (item, index) => {
        const quarterYear = `${item?.termCode}/${item?.yearPeriod}`;
        arrRevenues.push({
            quarterYear: `${item?.termCode}/${item?.yearPeriod}`,
            quarter: item.termCode,
            value: item.value,
            year: item.yearPeriod,
        });

        if (!_.includes(arrQuarter, quarterYear) && item.termCode !== 'N') {
            arrQuarter.push(quarterYear);
        }
        if (!_.includes(arrYears, item.yearPeriod) && item.termCode === 'N') {
            arrYears.push(item.yearPeriod);
        }
    });
    arrQuarter = _.orderBy(arrQuarter, 'desc');
    arrYears = _.orderBy(arrYears, 'desc');

    const [modalExecutiveBoardShowing, setModalExecutiveBoardShowing] = useState(false);
    const [modalAssociateShowing, setModalAssociateShowing] = useState(false);

    // MARK: --- Functions ---
    function handleSelectedTab(tab) {
        setSelectedTab(tab);
    }

    return (
        <div className="row">
            {/* LEFT */}
            <div className="col-md-8 col-12 d-flex flex-column p-0 pl-4 pr-4 pr-md-0">
                {/* Basic info */}
                <div className=''>
                    <AppCardDataInfo
                        title={t('Information')}
                        content={(
                            <div>
                                {
                                    info.map((item, index) => (
                                        <div key={index}>
                                            <div className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                <p className="m-0">{item.label}</p>
                                                <p className="m-0" align='right'>{item.value}</p>
                                            </div>
                                            {
                                                index < info.length - 1 && (
                                                    <hr className='m-0' />
                                                )
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        )}
                    />
                </div>

                {/* Notes */}
                {/* <div className='mt-7'>
                    <AppCardDataInfo
                        title={t('Notes')}
                        content={(
                            <div className='p-4'>
                                <Markup
                                    className='text-justify font-size-base'
                                    content={company.notes}
                                />
                            </div>
                        )}
                    />
                </div> */}

                {/* Other info  */}
                <div className='mt-4'>
                    <AppCardDataInfo
                        title={t('Introduction')}
                        content={(
                            <div className='p-4'>
                                <ShowMoreText
                                    lines={6}
                                    more={t('ShowMore')}
                                    less={t('ShowLess')}
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                    keepNewLines={true}
                                    children={Utils.decodeHTML(text)}
                                >
                                    {/* <p className="m-0">{text}</p> */}
                                </ShowMoreText>
                            </div>
                        )}
                    />
                </div>

                {/* Cong ty con, lien doanh, lien ket */}
                <div className=' mt-4'>
                    <AppCardDataInfo
                        title={`${t('Subsidiaries')}, ${t('JointVenture')}, ${t('Associated')}`}
                        rightComponent={(
                            <div>
                                <a
                                    href='#'
                                    className='font-size-lg font-weight-bold d-flex align-items-center'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalAssociateShowing(true);
                                    }}
                                >
                                    {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                </a>
                            </div>
                        )}
                        content={(
                            <div className='overflow-auto max-h-500px'>
                                <MGDataTable
                                    className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                    headItems={[
                                        { title: t('CompanyName'), subTitle: '', col: 'col-4' },
                                        { title: `${t('AuthorizedCapital')}`, subTitle: '', col: 'col-4', textAlign: 'right' },
                                        { title: `${t('Percentage')} (%)`, subTitle: '', col: 'col-4', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.chain(associates).orderBy(['ratio'], ['desc']).slice(0, 7).value().map((item, index) => {
                                            return {
                                                onPress: () => { },
                                                data: [
                                                    { icon: '', title: item.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-4' },
                                                    { icon: '', title: '', name: `${item.currencyUnit.trim() === 'USD' ? Utils.formatNumber(item.charteredCapital / 1000000) : Utils.formatNumber(item.charteredCapital / 1000000000)} ${item.currencyUnit.trim() === 'USD' ? 'tr USD' : 'tỷ VND'}`, nameColor: '', value: '', valueColor: '', col: 'col-4', textAlign: 'right' },
                                                    { icon: '', title: '', name: `${Utils.formatNumber(item.ratio)}%`, nameColor: '#0BDF39', value: '', valueColor: '', col: 'col-4', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        )}
                    />
                </div>

                {/* Ket qua kinh doanh KQKD */}
                <div className='mt-4'>
                    <AppTabs
                        tabs={tabs.reverse()}
                        selectedTab={selectedTab}
                        handleClick={handleSelectedTab}
                    />
                    {
                        selectedTab === t('FinanceReportByQuarter') ? (
                            <div className='max-h-400px overflow-auto border-bottom border-right border-left'>
                                <MGDataTable
                                    className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                    headItems={[
                                        { title: t('Quarter'), subTitle: '', col: 'col-1' },
                                        { title: `${t('Revenue')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Profit')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.chain(arrQuarter).orderBy(arrQuarter, 'desc').value().map((item, index) => {
                                            const currentRevenue = _.find(arrRevenues, { 'quarterYear': item })?.value ?? 0;
                                            let prevRevenue = 0;
                                            const prevRevenueIndex = index + 1;
                                            if (prevRevenueIndex < arrQuarter.length) {
                                                prevRevenue = _.find(arrRevenues, { 'quarterYear': arrQuarter[prevRevenueIndex] })?.value ?? 0;
                                            }
                                            let growthRevenue = 0;
                                            if (prevRevenue > 0) {
                                                growthRevenue = (currentRevenue - prevRevenue) * 100 / prevRevenue;
                                            }

                                            const currentProfit = _.find(arrProfits, { 'quarterYear': item })?.value ?? 0;
                                            const currentProfitIndex = _.findIndex(arrProfits, { 'quarterYear': item });
                                            let prevProfit = 0;
                                            const prevProfitIndex = index + 1;
                                            if (prevProfitIndex < arrQuarter.length) {
                                                prevProfit = _.find(arrProfits, { 'quarterYear': arrQuarter[prevProfitIndex] })?.value ?? 0;
                                            }
                                            let growthProfit = 0;
                                            if (prevProfit > 0) {
                                                growthProfit = (currentProfit - prevProfit) * 100 / prevProfit;
                                            }

                                            return {
                                                onPress: () => { },
                                                data: [
                                                    { icon: '', title: item, subTitle: '', name: '', value: '', valueColor: '', col: 'col-1' },
                                                    { icon: '', title: '', subTitle: '', name: Utils.formatNumber((currentRevenue / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${growthRevenue.toFixed(2)}%`, nameColor: Utils.getValueColor(growthRevenue), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${Utils.formatNumber((currentProfit / 1000000000).toFixed(2))}`, nameColor: Utils.getValueColor(currentProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${growthProfit.toFixed(2)}%`, nameColor: Utils.getValueColor(growthProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <div className='max-h-400px overflow-auto border-bottom border-right border-left'>
                                <MGDataTable
                                    className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                    headItems={[
                                        { title: t('Year'), subTitle: '', col: 'col-1' },
                                        { title: `${t('Revenue')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Profit')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                        { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.chain(arrYears).orderBy(arrYears, 'desc').value().map((item, index) => {
                                            const currentRevenue = _.find(arrRevenues, { 'quarterYear': `N/${item}` })?.value ?? 0;
                                            let prevRevenue = 0;
                                            const prevRevenueIndex = index + 1;
                                            if (prevRevenueIndex < arrYears.length) {
                                                prevRevenue = _.find(arrRevenues, { 'quarterYear': `N/${arrYears[prevRevenueIndex]}` })?.value ?? 0;
                                            }
                                            let growthRevenue = 0;
                                            if (prevRevenue > 0) {
                                                growthRevenue = (currentRevenue - prevRevenue) * 100 / prevRevenue;
                                            }

                                            const currentProfit = _.find(arrProfits, { 'quarterYear': `N/${item}` })?.value ?? 0;
                                            let prevProfit = 0;
                                            const prevProfitIndex = index + 1;
                                            if (prevProfitIndex < arrYears.length) {
                                                prevProfit = _.find(arrProfits, { 'quarterYear': `N/${arrYears[prevProfitIndex]}` })?.value ?? 0;
                                            }
                                            let growthProfit = 0;
                                            if (prevProfit > 0) {
                                                growthProfit = (currentProfit - prevProfit) * 100 / prevProfit;
                                            }

                                            return {
                                                onPress: () => { },
                                                data: [
                                                    { icon: '', title: `${item}`, subTitle: '', name: '', value: '', valueColor: '', col: 'col-1' },
                                                    { icon: '', title: '', subTitle: '', name: Utils.formatNumber((currentRevenue / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${growthRevenue.toFixed(2)}%`, nameColor: Utils.getValueColor(growthRevenue), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${Utils.formatNumber((currentProfit / 1000000000).toFixed(2))}`, nameColor: Utils.getValueColor(currentProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                    { icon: '', title: '', subTitle: '', name: `${growthProfit.toFixed(2)}%`, nameColor: Utils.getValueColor(growthProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        )
                    }
                </div>
            </div>

            {/* Begin Right Side  */}
            <div className="col-md-4 col-12 p-0 px-4">
                {/* Doanh thu */}
                <div className='mt-4 mt-md-0'>
                    <AppCardDataInfo
                        title={`${t('Revenues')} ${revenues && revenues.length > 0 && revenues[0].year}`}
                        content={(
                            <div className=''>
                                <Chart
                                    className='pieChart-hide-label'
                                    options={options}
                                    series={series}
                                    type="pie"
                                    width='100%'
                                    height={320}
                                />
                                <div className='max-h-300px overflow-auto q-hide-scrollbar'>
                                    {
                                        pieChartFeature.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="py-3 px-4 d-flex align-items-center">
                                                        <div className="w-20px h-20px rounded" style={{ background: item.color }}></div>
                                                        <p className="m-0 ml-3" align='center-left'>
                                                            {item.label}
                                                        </p>
                                                    </div>
                                                    {
                                                        index < pieChartFeature.length - 1 && (
                                                            <hr className='m-0' />
                                                        )
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )}
                    />
                </div>

                {/* Ban lanh dao */}
                <div className='mt-4'>
                    <AppCardDataInfo
                        title={t('ExecutiveBoard')}
                        rightComponent={(
                            <div>
                                <a
                                    href='#'
                                    className='font-size-lg font-weight-bold d-flex align-items-center'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalExecutiveBoardShowing(true);
                                    }}
                                >
                                    {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                </a>
                            </div>
                        )}
                        content={
                            <div className='overflow-auto max-h-500px'>
                                <MGDataTable
                                    className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                    headItems={[
                                        { title: t('Name'), subTitle: t('Position'), col: 'col-8' },
                                        { title: t('Quantity'), subTitle: '', col: 'col-4', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.slice(boardOfManagements, 0, 6).map((item, index) => {
                                            return {
                                                onPress: () => { },
                                                data: [
                                                    { icon: '', title: '', subTitle: '', name: `${item.title} ${item.name}`, nameColor: '', value: Utils.getPositionNames(item.positions), valueColor: '#5C6077', col: 'col-8' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item.totalShares), nameColor: '', value: '', valueColor: '', col: 'col-4', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        }
                    />
                </div>

                {/* Thong tin niem yet */}
                <div className='mt-4'>
                    <AppCardDataInfo
                        title={t('ListingInformation')}
                        content={
                            <div>
                                {
                                    listedInfo.map((item, index) => (
                                        <div key={index}>
                                            <div className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                <p className="m-0">{item.label}</p>
                                                <p className="m-0" align='right'>{item.value}</p>
                                            </div>
                                            {
                                                index < listedInfo.length - 1 && (
                                                    <hr className='m-0' />
                                                )
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        }
                    />
                </div>
            </div>
            {/* End Right Side  */}

            {/* Modal executive board */}
            <ModalExecutiveBoard
                show={modalExecutiveBoardShowing}
                onClose={() => {
                    setModalExecutiveBoardShowing(false);
                }}
                items={boardOfManagements}
            />

            {/* Modal associates */}
            <ModalAssociates
                show={modalAssociateShowing}
                onClose={() => {
                    setModalAssociateShowing(false);
                }}
                items={associates}
            />
        </div>
    );
}

export default Section_1;
