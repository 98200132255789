import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import './index.scss';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import PropTypes from 'prop-types';
import CellArticleList from 'features/Article/components/CellArticleList';
import ModalArticleDetail from 'features/Article/components/ModalArticleDetail';
import Utils from 'general/utils/Utils';
import Global from 'general/Global';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';

NewsEvent.propTypes = {
    articles: PropTypes.array,
    events: PropTypes.array,
    paginationArticle: PropTypes.object,
    paginationEvent: PropTypes.object,
    onLoadMoreArticle: PropTypes.func,
    onLoadMoreEvent: PropTypes.func,
};

NewsEvent.defaultProps = {
    articles: [],
    events: [],
    paginationArticle: null,
    paginationEvent: null,
    onLoadMoreArticle: null,
    onLoadMoreEvent: null,
};

function NewsEvent(props) {
    // MARK: --- Params ---
    const { articles, events, paginationArticle, paginationEvent, onLoadMoreArticle, onLoadMoreEvent } = props;
    const { t } = useTranslation();
    const [modalArticleDetailVisible, setModalArticleDetailVisible] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const refScrollArticle = useRef(null);
    const refScrollEvent = useRef(null);

    // console.log({ events });

    // MARK: --- Functions ---
    function handleScrollArticle(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(paginationArticle);
            if (onLoadMoreArticle && nextPage) {
                if (refScrollArticle.current) {
                    Global.gSavedScrollTopArticle = refScrollArticle.current.scrollTop;
                }
                onLoadMoreArticle(nextPage);
            }
        }
    }

    function handleScrollEvent(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(paginationEvent);
            if (onLoadMoreEvent && nextPage) {
                Global.gSavedScrollTopEvent = refScrollEvent.current.scrollTop;

                onLoadMoreEvent(nextPage);
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (refScrollArticle.current) {
            refScrollArticle.current.scrollTop = Global.gSavedScrollTopArticle;
        }
        if (refScrollEvent.current) {
            refScrollEvent.current.scrollTop = Global.gSavedScrollTopEvent;
        }
    }, []);

    return (
        <div className='p-4 NewsEvent'>
            <div className='row'>
                <div className='col-12 col-md-6 align-self-start p-0 pl-4 pr-4 pr-md-0 overflow-hidden'>
                    <AppCardDataInfo
                        title={t('News')}
                        content={(
                            <div ref={refScrollArticle} className='max-h-700px min-h-400px overflow-auto' onScroll={handleScrollArticle}>
                                {
                                    articles.length > 0 && articles.map((item, index) => {
                                        return (
                                            <div key={index} className='col-12 px-0 mt-5'>
                                                <CellArticleList
                                                    item={item.article}
                                                    showDivider={index < articles.length - 1}
                                                    imgMl={true}
                                                    onPress={() => {
                                                        setSelectedArticle(item.article);
                                                        setModalArticleDetailVisible(true);
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }

                                {
                                    articles.length === 0 && (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    />
                </div>
                <div className='col-12 col-md-6 align-self-start p-0 px-4 mt-4 mt-md-0'>
                    <AppCardDataInfo
                        title={t('Events')}
                        rightComponent={
                            <p className='m-0 font-italic text-dark-75'>(*): Ngày được hiển thị là ngày GDKHQ</p>
                        }
                        content={(
                            <div ref={refScrollEvent} className='max-h-700px min-h-400px overflow-auto' onScroll={handleScrollEvent}>
                                {
                                    events.map((item, index) => {
                                        return (
                                            <div key={index} className={`col-12 px-0 ${index % 2 !== 0 && 'bg-light'}`}>
                                                <div className='d-flex align-items-center px-5 py-4'>
                                                    {
                                                        (item?.eventTypeId === 2 || item?.eventTypeId === 5) ? (
                                                            <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.gdkhqDate ? Utils.formatDateTime(item?.gdkhqDate, 'DD/MM/YYYY') : ''}</p>
                                                        ) : (
                                                            <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.ndkthDate ? Utils.formatDateTime(item?.ndkthDate, 'DD/MM/YYYY') : ''}</p>
                                                        )
                                                    }
                                                    <p className='m-0 font-size-lg font-weight-base flex-grow-1'>{item?.titleEvent}</p>
                                                </div>
                                                {
                                                    index < 7 && (
                                                        <hr className='m-0' />
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }

                                {
                                    events.length === 0 && (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    />
                </div>
            </div>

            {/* Modal article detail */}
            <ModalArticleDetail
                show={modalArticleDetailVisible}
                article={selectedArticle}
                onClose={() => {
                    setModalArticleDetailVisible(false);
                }}
            />
        </div>
    );
}

export default NewsEvent;
