import academyApi from 'api/academyApi';
import CellArticleList from 'features/Article/components/CellArticleList';
import CellVideoGrid from 'features/Video/components/CellVideoGrid';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import KCHeaderView from '../KCHeaderView';
import './style.scss';

KCInvestingUniversity.propTypes = {
    onMenuHandle: PropTypes.func,
};

KCInvestingUniversity.defaultProps = {
    onMenuHandle: null,
};

function KCInvestingUniversity(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const [loading, setLoading] = useState(false);
    const [academyArticles, setAcademyArticles] = useState([]);
    const [academyVideos, setAcademyVideos] = useState([]);
    const academyData = academyArticles.concat(academyVideos);
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('academy_article');
    const match = useRouteMatch();

    // MARK: --- Functions ---
    async function getAcademyData() {
        setLoading(true);
        const res = await academyApi.getAcademyData();
        const { result, articles, videos } = res;

        if (result === 'success') {
            if (articles) {
                setAcademyArticles(articles);
            }
            if (videos) {
                setAcademyVideos(videos);
            }
        }
        setLoading(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getAcademyData();
    }, []);

    return (
        <div className='KCInvestingUniversity'>
            <KCHeaderView
                title={t('InvestingUniversity')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
                showSearchBar={false}
            />
            <div className='KCInvestingUniversity_Banner row px-10 px-md-20 pt-5 pt-md-10 border-bottom'>
                <div className='col-5 d-flex align-items-center pb-5 pb-md-10'>
                    <p className='m-0 text-left font-weight-boldest text-uppercase' style={{ fontSize: '2.5rem', lineHeight: '120%' }}>
                        {t('Overview')}
                        <br />
                        <span className='text-primary' style={{ fontSize: '3rem' }}>{t('Invest')}</span>
                    </p>
                </div>
                <div className='KCInvestingUniversity_Banner_Right col-7 py-30'></div>
            </div>

            {/* content */}
            <div className=''>
                <div className='d-flex justify-content-between'>
                    {/* Nav menus */}
                    <ul className="nav nav-tabs nav-tabs-line w-100 px-10 mt-2">
                        <li className="nav-item">
                            <a className={`font-weight-bolder nav-link ${activeTab === 'academy_article' && 'active'}`} href="#" onClick={(e) => {
                                e.preventDefault();
                                setActiveTab('academy_article');
                            }}>{t('Article')}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`font-weight-bolder nav-link ${activeTab === 'academy_video' && 'active'}`} href="#" onClick={(e) => {
                                e.preventDefault();
                                setActiveTab('academy_video');
                            }}>{t('Video')}</a>
                        </li>
                    </ul>
                </div>

                <Tabs
                    defaultActiveKey='academy_article'
                    activeKey={activeTab}
                    className=''
                >
                    <Tab eventKey='academy_article' className=''>
                        <div className='row px-4'>
                            {
                                academyArticles.length > 0 && academyArticles.map((item, index) => {
                                    const { articleId, type } = item;
                                    return (
                                        <div key={index} className={`col-12 p-4`}>
                                            {
                                                articleId && (
                                                    <CellArticleList
                                                        item={item}
                                                        showDivider={index < academyArticles.length - 1}
                                                        onPress={() => {
                                                            if (type === 'ARTICLE') {
                                                                history.push(`${match.url}/article/${articleId}`);
                                                            } else if (type === 'RESEARCH') {
                                                                history.push(`${match.url}/research/${articleId}`);
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }

                            {
                                academyArticles.length === 0 && (
                                    <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                        <EmptyView
                                            description='Không có bài viết'
                                            iconEmpty={AppIcons.icEmptyPack}
                                            loading={loading}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </Tab>
                    <Tab eventKey='academy_video' className=''>
                        <div className="row px-7 pb-7">
                            {
                                academyVideos.length > 0 && academyVideos.map((item, index) => {
                                    return (
                                        <div key={index} className="col-12 col-lg-6 mt-7">
                                            <CellVideoGrid
                                                item={item}
                                                onPress={() => {
                                                    history.push(`/video/${item.videoId}`);
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }

                            {
                                academyVideos.length === 0 && (
                                    <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                        <EmptyView
                                            description='Không có videos'
                                            iconEmpty={AppIcons.icEmptyPack}
                                            loading={loading}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default KCInvestingUniversity;