import NotFound from 'general/components/NotFound';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListProductScreen from './screens/ListProductScreen';

function MGProduct(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match.url}`}
                component={ListProductScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default MGProduct;