import miscApi from 'api/miscApi';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

ModalBusinessReport.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalBusinessReport.defaultProps = {
    show: false,
    onClose: null,
};

function ModalBusinessReport(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
    });
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allReports, setAllReports] = useState([]);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function getAllReport() {
        if (loading) {
            return;
        }
        setLoading(true);
        const res = await miscApi.getTopProfitCompany(filter);
        const { result, total, count, page, data } = res;
        if (result === 'success' && data) {
            setAllReports(data);
            // if (page === 0) {
            //     setAllIdentifies(mgIdentifies);
            // } else {
            //     setAllIdentifies(allIdentifies.concat(mgIdentifies));
            // }
            // setPagination({
            //     total: total,
            //     count: count,
            //     currentPage: page
            // });
        }
        setLoading(false);
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            // let nextPage = Utils.getNextPage(pagination);
            // console.log({ nextPage });
            // if (nextPage) {
            //     setFilter({
            //         ...filter,
            //         page: nextPage,
            //     });
            // }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getAllReport();
    }, [filter]);

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
                size='lg'
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('BusinessReport')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='overflow-auto p-0' style={{
                    maxHeight: '84vh'
                }} onScroll={handleScroll}>
                    <MGDataTable
                        className='bg-white border-top-0'
                        headItems={[
                            { title: t('Stock'), subTitle: t('CompanyName'), col: 'col-4' },
                            { title: t('Profit'), subTitle: 'x1000 VND', col: 'col-2' },
                            { title: t('Revenue'), subTitle: 'x1000 VND', col: 'col-2' },
                            { title: t('Term'), subTitle: '', col: 'col-2' },
                            { title: t('Time'), subTitle: '', col: 'col-2' },
                        ]}
                        dataItems={
                            allReports.map((item, index) => {
                                const latestProfit = item?.lastestProfit ?? [];
                                const latestRevenue = item?.lastestRevenue ?? [];
                                // console.log({ latestProfit, latestRevenue });
                                let currentProfit = latestProfit.length > 0 ? latestProfit[0] : 0;
                                let prevProfit = latestProfit.length > 1 ? latestProfit[1] : 0;
                                let currentRevenue = latestRevenue.length > 0 ? latestRevenue[0] : 0;
                                let prevRevenue = latestRevenue.length > 1 ? latestRevenue[1] : 0;
                                const profitColor = currentProfit > prevProfit ? '#0BDF39' : '#FF0017';
                                const revenueColor = currentRevenue > prevRevenue ? '#0BDF39' : '#FF0017';

                                const profitPercent = prevProfit > 0 ? parseFloat((((currentProfit - prevProfit) * 100 / prevProfit))).toFixed(2) : '---';
                                const revenuePercent = prevRevenue > 0 ? parseFloat((((currentRevenue - prevRevenue) * 100 / prevRevenue))).toFixed(2) : '---';

                                return (
                                    {
                                        onPress: () => { },
                                        data: [
                                            { icon: '', title: item?.stockCode, titleWeight: '600', subTitle: item?.companyName, name: '', value: '', valueColor: '', col: 'col-4' },
                                            { icon: '', title: '', name: Utils.formatNumber((currentProfit / 1000).toFixed(2)), nameColor: '', value: `${profitPercent > 0 ? '+' : ''}${Utils.formatNumber(profitPercent)}%`, valueColor: profitColor, col: 'col-2' },
                                            { icon: '', title: '', name: Utils.formatNumber((currentRevenue / 1000).toFixed(2)), nameColor: '', value: `${revenuePercent > 0 ? '+' : ''}${Utils.formatNumber(revenuePercent)}%`, valueColor: revenueColor, col: 'col-2' },
                                            { icon: '', title: '', name: `${item?.quarter?.termCode}/${item?.yearPeriod}`, nameColor: '', value: '', valueColor: '', col: 'col-2' },
                                            { icon: '', title: '', name: Utils.formatDateTime(item?.reportDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-2' }
                                        ]
                                    }
                                )
                            })
                        }
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalBusinessReport;