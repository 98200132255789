import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import AppData from "../../../../../general/constants/AppData";
import Utils from "../../../../../general/utils/Utils";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";


const MGFilterLine = (props) => {
    let { filterConditionalId, values, max, min, listTitle } = props?.conditional
    const { t } = useTranslation();

    const renderMGFilterLine = (filterConditionalId) => {

        // select exchange code
        if (filterConditionalId == 1) {
            return (
                <div className='d-flex  py-4 align-items-center border-top'>
                    <div className='col-xs-12 col-3 d-flex flex-row'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{t(Utils.getFilterConditionalTitle(filterConditionalId))}</span>
                    </div>
                    <div className='col-xs-10 col-9 d-flex flex-row'>
                        <div className='custom-right-component'>
                            {
                                AppData.allExchangeCode.map((exchangeCode, index) => {
                                    return (
                                        <button
                                            disabled
                                            key={index}
                                            className={`border-0 py-2 px-3 mr-2 rounded-sm font-weight-bolder ${(values?.includes(exchangeCode)) ? "BuildYourScreen_Button_Clicked" : "BuildYourScreen_Button_UnClicked"}`}>{exchangeCode}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )
            // select industry
        } else if (filterConditionalId == 2) {
            return (
                <div>
                    <div className='d-flex border-top py-4 align-items-center'>
                        <div className='col-xs-12 col-3 d-flex flex-row'>
                            <span className='BuildYourScreen_FilterCondition_Title'>{t("Industry")}</span>
                        </div>
                        {
                            (values?.length > 0) &&
                            <div className={"d-flex pt-2 align-items-center"}>
                                <div className={'col-xs-12 col-12'}>
                                    {
                                        listTitle?.map((item, index) => {
                                            return (
                                                <p className={"mg-industry-area"} key={index}>
                                                    <span className={"mg-industry-name-custom px-2"}>{item?.name}</span>
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>
            )
        } else {
            return (
                <div className='d-flex border-top py-3 align-items-center'>
                    <div className='col-xs-12 col-3 d-flex flex-row align-items-center'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{t(Utils.getFilterConditionalTitle(filterConditionalId)) + " (" + Utils.getUnitByFilterConditionalId(filterConditionalId) + ")"}</span>
                    </div>
                    <div className='col-xs-10 col-9 d-flex flex-grow'>
                        <span className='ml-3 font-weight-bolder d-flex flex-row align-items-center justify-content-between'
                            style={{ color: "#7621B1" }} variant=''>
                            {min == max ? min : (min + " - " + max)}
                        </span>
                    </div>
                </div>

            )
        }
    }
    return (
        renderMGFilterLine(filterConditionalId)
    )

}

export default MGFilterLine
