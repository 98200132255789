import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import videoApi from 'api/videoApi';
import AppLayout from 'general/components/AppLayout';
import AppBreadcrumb from 'general/components/AppBreadcrumb';
import Utils, { useScrollToTop } from 'general/utils/Utils';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import Global from 'general/Global';
import ReactPlayer from 'react-player';
import { AppIcons } from 'general/constants/AppResource';
import CellVideoGrid from 'features/Video/components/CellVideoGrid';
import EmptyView from 'general/components/Views/EmptyView';
import { FacebookShareButton } from 'react-share';
import AppConfigs from 'general/constants/AppConfigs';
import { Markup } from 'interweave';

VideoDetailScreen.propTypes = {

};

const sTag = '[VideoDetailScreen]';

function VideoDetailScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const match = useRouteMatch();
    const routeParams = match.params;
    let videoId = routeParams.videoId;
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [loadingRelatedVideos, setLoadingRelatedVideos] = useState(false);
    const [relatedVideos, setRelatedVideos] = useState([]);
    const [firstLoading, setFirstloanding] = useState(true)
    const path = match.url;
    let arrPaths = path.split('/');
    arrPaths = arrPaths.filter((item, index) => item.length > 0 && index < arrPaths.length - 1);
    const subBreadcrumbs = arrPaths.map((item, index) => {
        const url = arrPaths.slice(0, index + 1).join('/');
        return {
            text: item,
            url: `/${url}`
        }
    })

    // MARK: --- Functions ---
    // get video detail
    async function getVideoInfo(id) {
        setLoading(true);
        const res = await videoApi.getVideoInfo(id);
        const { result, data } = res;
        if (result === 'success' && data && data.video) {
            setVideo(data.video);
            getRelatedVideos(data.video.videoId, data.video.category.categoryId);
        }
        setLoading(false);
    }

    // get related videos
    async function getRelatedVideos(videoId, categoryId) {
        setLoadingRelatedVideos(true);
        const res = await videoApi.getRelatedVideos({
            page: 0,
            limit: Global.gDefaultPagination,
            categoryId: categoryId,
            videoId: videoId
        });
        const { result, videos } = res;
        if (result === 'success') {
            setFirstloanding(false);
        }
        if (result === 'success' && videos) {

            setRelatedVideos(videos);
        }

        setLoadingRelatedVideos(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} view did load`);

        return () => {
            console.log(`${sTag} view will dismiss`);
        }
    }, []);

    useEffect(() => {
        if (videoId && !isNaN(videoId)) {
            getVideoInfo(videoId);
            Utils.scrollToTop()
        }
    }, [videoId]);

    // useScrollToTop()

    return (
        <AppLayout>
            <div className='VideoDetailScreen bg-white min-h-600px'>
                <div className='container-xxl py-10'>
                    {
                        video && (
                            <AppBreadcrumb
                                items={[
                                    { text: 'Home', url: '/home' },
                                    // { text: 'KnowledgeCenter', url: '/knowledge-center' },
                                    // { text: 'Video', url: '/knowledge-center/video' },
                                    ...subBreadcrumbs,
                                    { text: video.name, url: '' }
                                ]}
                            />
                        )
                    }

                    <div className='row'>
                        <div className='col-12 col-lg-8 overflow-hidden'>
                            {
                                video && (
                                    <div className=''>
                                        {/* player */}
                                        <div className='w-100' style={{ aspectRatio: '16/9' }}>
                                            <ReactPlayer
                                                width='100%'
                                                height='100%'
                                                url={Utils.getFullUrl(video.url)}
                                                controls={true}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className='mt-5'>
                                            <span className='font-size-h5 font-weight-bold'>{video.name}</span>
                                            <p className='font-weight-bold mb-4 text-muted font-size-lg'>{`${Utils.formatDateTime(video.updatedAt, 'DD/MM/YYYY HH:mm')}`}</p>

                                            {/* <p className='font-size-lg text-dark-75 text-justify'>{video.description}</p> */}
                                            {/* content */}
                                            <Markup
                                                className='font-size-lg text-justify q-markup'
                                                content={Utils.decodeHTML(video.description)}
                                            />
                                        </div>

                                        {/* share */}
                                        <div className='row mt-10'>
                                            <div className="col-6">
                                                <FacebookShareButton
                                                    url={video.url}
                                                    quote={""}
                                                    title={video.name}
                                                    hashtag={AppConfigs.hashtag}
                                                    description={video.description}
                                                    className='w-100 h-40px btn text-white font-weight-bold d-flex align-items-center justify-content-center hover-opacity-60'
                                                    style={{
                                                        backgroundColor: '#3C5795'
                                                    }}
                                                >
                                                    <i className="fab fa-facebook text-white mr-2"></i>{t('Share')}
                                                </FacebookShareButton>
                                            </div>
                                            <div className='col-6'>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <div className='col-12 col-lg-4'>
                            <div className=''>
                                <SectionHeaderView
                                    title={t('RelatedVideos')}
                                />
                                <div className='row mt-5'>
                                    {
                                        firstLoading
                                            ? <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('GettingData')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                            : (relatedVideos.length > 0 && relatedVideos.map((item, index) => {
                                                return (
                                                    <div key={index} className={`col-12 mb-4 pb-4`}>
                                                        <CellVideoGrid
                                                            item={item}
                                                            onPress={() => {
                                                                history.push(`/video/${item.videoId}`);
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }))
                                    }

                                    {
                                        !firstLoading
                                        && (relatedVideos.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default VideoDetailScreen;