import KTTooltip from 'general/components/KTTooltip';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import gsap from 'gsap';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

MGDataTable.propTypes = {
    headItems: PropTypes.array,
    dataItems: PropTypes.array,
    className: PropTypes.string,
    toggleHead: PropTypes.bool,
    minWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    overflowX: PropTypes.bool,
    tableId: PropTypes.string,
    nonBorder: PropTypes.bool,
    loading: PropTypes.bool,
};

MGDataTable.defaultProps = {
    headItems: [],
    dataItems: [],
    className: 'bg-light',
    toggleHead: false,
    minWidth: '',
    maxHeight: '',
    overflowX: false,
    tableId: '',
    nonBorder: false,
    loading: false,
};

function MGDataTable(props) {
    // MARK: --- Params ---
    const { headItems, dataItems, className, toggleHead, minWidth, maxHeight, overflowX, tableId, nonBorder, loading } = props;
    const { t } = useTranslation();
    const [bodyVisible, setBodyVisible] = useState(true);
    const tableBodyRef = useRef(null);

    // MARK: --- Functions ---
    function handlePressHead() {
        if (toggleHead) {
            setBodyVisible(bodyVisible => !bodyVisible);
        }
    }

    useEffect(() => {
        if (bodyVisible) {
            gsap.to(tableBodyRef.current, { autoAlpha: 1, display: '', duration: 0.3, ease: 'none' });
        } else {
            gsap.to(tableBodyRef.current, { autoAlpha: 0, display: 'none', duration: 0.1, ease: 'none' });
        }
    }, [bodyVisible]);

    return (
        <div className={`MGDataTable ${!nonBorder && 'border'} ${!overflowX && 'd-block overflow-auto'} ${className}`} style={{ maxHeight: `${maxHeight}` }}>
            <table id={tableId} className='table m-0' style={{ minWidth: `${minWidth}` }}>
                <thead className={toggleHead ? 'cursor-pointer bg-hover-light position-relative' : ''} onClick={handlePressHead}>
                    <tr>
                        {
                            headItems.map((item, index) => {
                                const { title, subTitle, component, textAlign } = item;
                                const col = item.col ?? 'col';
                                if (component) {
                                    return (
                                        <th key={index} className='border-left px-0 py-0' style={{

                                        }}>
                                            {component}
                                        </th>
                                    )
                                }
                                return (
                                    <th key={index} className={`border-bottom ${col} ${index === 0 && 'pl-5'} ${(index === headItems.length - 1) && 'pr-5'}`} style={{ verticalAlign: 'middle', textAlign: `${textAlign}` }}>
                                        <span className='MGDataTable_Table_Header_Name' style={{
                                            whiteSpace: 'nowrap',
                                        }}>{title}</span><br />
                                        {
                                            subTitle && subTitle.length > 0 && (
                                                <span className='MGDataTable_Table_Header_Unit' style={{
                                                    whiteSpace: 'nowrap'
                                                }}>{subTitle}&nbsp;</span>
                                            )
                                        }
                                    </th>
                                )
                            })
                        }
                    </tr>

                    {
                        toggleHead && (
                            <div className='position-absolute' style={{
                                right: '10px',
                                bottom: '0%'
                            }}>
                                <i className={`fas fa-caret-${bodyVisible ? 'up' : 'down'}`} />
                            </div>
                        )
                    }
                </thead>
                <tbody ref={tableBodyRef}>
                    {
                        /*bodyVisible && */dataItems.length > 0 && dataItems.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className={`${item.onPress && 'cursor-pointer bg-hover-light-secondary'}`}
                                onClick={() => {
                                    if (item.onPress) {
                                        item.onPress();
                                    }
                                }}
                            >
                                {
                                    item.data.map((subItem, subIndex) => {
                                        const { icon,
                                            title,
                                            enableHoverTitle,
                                            titleColor,
                                            titleWeight,
                                            subTitle,
                                            name,
                                            nameColor,
                                            value,
                                            valueColor,
                                            col,
                                            component,
                                            componentParentAdditionClass,
                                            textAlign,
                                            titleMinWidth
                                        } = subItem;

                                        if (component) {
                                            return (
                                                <td key={subIndex} className={`${componentParentAdditionClass ? componentParentAdditionClass : ''} p-0 ${col}`} style={{ verticalAlign: 'middle' }}>
                                                    <div className='d-flex align-items-center justify-content-start '>
                                                        {component}
                                                    </div>
                                                </td>
                                            )
                                        } else if (title && title.length > 0) {
                                            return (
                                                <td key={subIndex} className={`${col} ${subIndex === 0 && 'pl-5'} ${(subIndex === item.data.length - 1) && 'pr-5'}`} style={{ verticalAlign: 'middle' }}>
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        {
                                                            icon && icon.length > 0 && (
                                                                <img
                                                                    className='h-30px w-30px rounded-circle mr-3'
                                                                    style={{ objectFit: "contain" }}
                                                                    draggable={false}
                                                                    src={icon}
                                                                    alt="icon"
                                                                />
                                                            )
                                                        }
                                                        <div>
                                                            {
                                                                enableHoverTitle ? (
                                                                    <KTTooltip text='Click để xem chi tiết'>
                                                                        <div className={`MGDataTable_Table_Body_Value q-text-hover-primary ${icon && icon.length > 0 && 'font-weight-bolder'}`} style={{ color: titleColor, fontWeight: titleWeight, whiteSpace: 'nowrap' }}>{title}</div>
                                                                    </KTTooltip>
                                                                ) : (
                                                                    <div className={`MGDataTable_Table_Body_Value ${icon && icon.length > 0 && 'font-weight-bolder'}`} style={{ color: titleColor, fontWeight: titleWeight }}>{title}</div>
                                                                )
                                                            }
                                                            <div className={`MGDataTable_Table_Body_Value q-max-line-1`} >{subTitle}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={subIndex} className={`${col} ${subIndex === 0 && 'pl-5'} ${(subIndex === item.data.length - 1) && 'pr-5'}`} style={{ verticalAlign: 'middle', textAlign: `${textAlign}` }}>
                                                    <span className='MGDataTable_Table_Body_Value' style={{ color: nameColor ?? '' }}>{name}</span> <br />
                                                    <span style={{ color: valueColor }}>{value}</span>
                                                </td>
                                            )
                                        }
                                    })
                                }
                            </tr>
                        )
                    })
                    }
                </tbody>
            </table>
            {
                dataItems.length === 0 && (
                    <div className='d-flex align-items-center justify-content-center py-10'>
                        <EmptyView
                            iconEmpty={AppIcons.icEmptyPack}
                            description={t('NoDataToDisplay')}
                            loading={loading}
                        />
                    </div>
                )
            }
        </div>
    );
}

export default MGDataTable;