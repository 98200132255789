import axiosClient from "./axiosClient";

const miscApi = {
    getCommonData: (params) => {
        const url = '/global/common_info';
        return axiosClient.get(url, { params });
    },

    // get report norm data
    getReportNormData: () => {
        const url = '/company/reportSample';
        return axiosClient.get(url);
    },

    // get top profit company
    getTopProfitCompany: (params) => {
        const url = '/global/topProfitCompany';
        return axiosClient.get(url, { params });
    },
};

export default miscApi;