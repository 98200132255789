import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import AppLayout from 'general/components/AppLayout';
import AppTabs from 'general/components/AppTabs';

import AnalyzeTransactionHistory from "../../components/analyzeTransactionHistory";
import Basic from "../../components/basic";
import Category from "../../components/category";
import CategoryAnalytic from "../../components/categoryAnalysis";
import Ranking from "../../components/ranking";
import Technical from "../../components/technical";

import myListApi from 'api/myListApi';
import { thunkGetHomeMyListData } from 'app/appSlice';
import ModalAddTransaction from 'features/MyList/components/ModalAddTransaction';
import ModalCreateMyList from 'features/MyList/components/ModalCreateMyList';
import ModalEditTransaction from 'features/MyList/components/ModalEditTransaction';
import ModalEmptyList from 'features/MyList/components/ModalEmptyList';
import ModalImportTransaction from 'features/MyList/components/ModalImportTransaction';
import ModalManageMyList from 'features/MyList/components/ModalManageMyList';
import ModalTransactionHistory from 'features/MyList/components/ModalTransactionHistory';
import { setCurrentSelectedMyList, thunkGetHistoryTransaction, thunkGetInvestmentPerformance, thunkGetListMyList, thunkGetMyListDetail } from 'features/MyList/myListSlice';
import EmptyView from 'general/components/Views/EmptyView';
import AppConfigs from 'general/constants/AppConfigs';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

function MyListHomeScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const {
        isGetting,
        allMyLists,
        selectedMyList,
        transactionHistorys,
        selectedMyListDetail,
        myListInvestmentPerformance
    } = useSelector(state => state.myList);
    const [selectedTab, setSelectedTab] = useState('my_category_category');
    const menuItems = [
        {
            id: 'my_category_category',
            text: 'List',
            path: 'category'
        },
        {
            id: 'my_category_ranking',
            text: 'Ranking',
            path: 'ranking'
        },
        {
            id: 'my_category_basic',
            text: 'Basic',
            path: 'basic'
        },
        {
            id: 'my_category_technical',
            text: 'Technical',
            path: 'technical'
        },
        {
            id: 'my_category_category_analytic',
            text: 'CategoryAnalysis',
            path: 'category-analytic'
        },
        {
            id: 'my_category_transaction_analysis',
            text: 'AnalyzeTransactionHistory',
            path: 'analyze-transaction-history'
        },
    ];
    const dispatch = useDispatch();
    var activeTab = menuItems.filter(item => item.path == history.location.pathname.split('/').pop())[0];
    const [modalCreateMyListShowing, setModalCreateMyListShowing] = useState(false);
    const [modalManageMyListShowing, setModalManageMyListShowing] = useState(false);
    const [modalAddTransactionShowing, setModalAddTransactionShowing] = useState(false);
    const [modalImportTransactionShowing, setModalImportTransactionShowing] = useState(false);
    const [modalTransactionHistoryShowing, setModalTransactionHistoryShowing] = useState(false);

    const [listData, setListData] = useState([]);
    const [rankData, setRankData] = useState([]);
    const [rankGroupScore, setRankGroupScore] = useState({});
    const [basicData, setBasicData] = useState([]);
    const [techniqueData, setTechniqueData] = useState([]);
    const [analysisData, setAnalysisData] = useState({});
    const [analysisTransactionHistory, setAnalysisTransactionHistory] = useState([]);
    const [analysisTransactionHistoryPagination, setAnalysisTransactionHistoryPagination] = useState({});
    const [analysisTransactionHistoryFilter, setAnalysisTransactionHistoryFilter] = useState({
        limit: 10,
        page: 0,
    });

    const [modalEditTransaction, setModalEditTransaction] = useState(false);
    const [modalEmptyListShowing, setModalEmptyListShowing] = useState(false);
    const [disableSelectList, setDisableSelectList] = useState(false);

    const initialAddTransactionStockCode = useRef(null);

    const [summaryData, setSummaryData] = useState({});
    const [transactionsGroupedByStockCode, setTransactionsGroupedByStockCode] = useState({});
    const [investmentPerformanceDurationValue, setInvestmentPerformanceDurationValue] = useState('1M');
    const [investmentPerformanceTo, setInvestmentPerformanceTo] = useState(moment().format('DD/MM/YYYY'));
    const [investmentPerformanceFrom, setInvestmentPerformanceFrom] = useState(moment().subtract(1, 'months').format('DD/MM/YYYY'));

    const [loading, setLoading] = useState(false);

    // MARK: --- Functions ---
    function handlePressAddTransaction(e) {
        e.target.blur();
        if (!Utils.isObjectEmpty(selectedMyList)) {
            initialAddTransactionStockCode.current = null;
            setModalAddTransactionShowing(true);
        } else {
            setModalEmptyListShowing(true);
        }
    }

    function handlePressImportTransaction(e) {
        e.target.blur();
        if (!Utils.isObjectEmpty(selectedMyList))
            setModalImportTransactionShowing(true);
        else {
            setModalEmptyListShowing(true);
        }
    }

    function handlePressDownloadSampleTransaction(e) {
        e.target.blur();
    }

    function handlePressTransactionHistory(e) {
        setModalTransactionHistoryShowing(true);
        dispatch(thunkGetHistoryTransaction(selectedMyList.myListId));
        e.target.blur();
    }

    async function getMyListData() {
        setLoading(true);
        // get my list summary
        {
            const res = await myListApi.getMyListSummary(selectedMyList.myListId);
            const { result, data } = res;
            if (result === 'success' && data) {
                setSummaryData(data);
            } else {
                setSummaryData({});
            }
        }

        // du lieu tab danh muc
        {
            const res = await myListApi.getMyListListData(selectedMyList.myListId);
            const { result, data } = res;
            if (result === 'success' && data) {
                setListData(data);
            }
        }

        // du lieu tab xep hang
        {
            const res = await myListApi.getMyListRankData(selectedMyList.myListId);
            const { result, data, groupScore } = res;
            if (result === 'success') {
                if (data) {
                    setRankData(data);
                }
                if (groupScore) {
                    setRankGroupScore(groupScore);
                }
            }
        }

        // du lieu tab co ban
        {
            const res = await myListApi.getMyListBasicData(selectedMyList.myListId);
            const { result, data } = res;
            if (result === 'success' && data) {
                setBasicData(data);
            }
        }

        // du lieu tab ky thuat
        {
            const res = await myListApi.getMyListTechnicalData(selectedMyList.myListId);
            const { result, data } = res;
            if (result === 'success' && data) {
                setTechniqueData(data);
            }
        }

        // du lieu tab phan tich danh muc
        {
            const res = await myListApi.getMyListAnalyticList(selectedMyList.myListId);
            const { result, data } = res;
            if (result === 'success' && data) {
                // console.log({ data });
                setAnalysisData(data);
            }
        }

        // du lieu tab phan tich lich su giao dich
        getAnalysisTransactionHistoryData();
        setLoading(false);
    }

    async function getAnalysisTransactionHistoryData() {
        setLoading(true);
        const res = await myListApi.getHistoryTransaction(selectedMyList.myListId, analysisTransactionHistoryFilter);
        const { result, detail, page, total, count } = res;
        if (result === 'success' && detail) {
            setAnalysisTransactionHistory(detail);

            setAnalysisTransactionHistoryPagination({
                total: total,
                currentPage: page,
                perPage: analysisTransactionHistoryFilter.limit ?? Global.gDefaultPagination,
            });
        }
        setLoading(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (allMyLists.length === 0) {
            dispatch(thunkGetListMyList({}));
        }
    }, []);

    useEffect(() => {
        if (!Utils.isObjectNull(selectedMyList)) {
            dispatch(thunkGetMyListDetail({ myListId: selectedMyList.myListId }));
            getMyListData();
        }
    }, [selectedMyList]);

    useEffect(() => {
        getAnalysisTransactionHistoryData();
    }, [analysisTransactionHistoryFilter]);

    useEffect(() => {
        const transactionsGroupedByStockCode = _.chain(selectedMyListDetail).groupBy('stockCode').value();
        setTransactionsGroupedByStockCode(transactionsGroupedByStockCode);
    }, [selectedMyListDetail]);

    return (
        <AppLayout>
            <div className='MyListHomeScreen bg-light'>
                <div className='container-xxl py-7'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-lg-4'>
                            <Dropdown className="w-100">
                                <Dropdown.Toggle
                                    className='w-100 py-3 pl-0 rounded-0 bg-hover-light d-flex justify-content-between align-items-center'
                                    variant=""
                                    style={{
                                        color: '#23262F',
                                        fontWeight: '700',
                                        fontSize: '1.6rem'
                                    }}
                                >
                                    <div className='' style={{ overflowX: 'hidden' }}>
                                        {selectedMyList ? selectedMyList.name : t('ChooseList')}
                                        <i className="fa fa-solid fa-caret-down pl-4 text-dark" />
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-75 p-0 font-size-h6 justify-content-center align-content-center border shadow-lg'>
                                    <div className='px-5 py-3 font-weight-bold'>{t('MyListList')}</div>
                                    <div className='border-y' style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
                                        {
                                            allMyLists.length > 0 && allMyLists.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Dropdown.Item
                                                            className={`d-flex align-items-center btn-light`}
                                                            onClick={() => {
                                                                dispatch(setCurrentSelectedMyList(item));
                                                            }}
                                                        >
                                                            <div style={{ overflowX: 'hidden', flex: '1 1 auto' }}>
                                                                <img src={AppIcons.icSquareList} alt="icon"
                                                                    draggable={false} className='mr-3' />
                                                                {item.name}
                                                            </div>
                                                            {selectedMyList.myListId === item.myListId && <i className="far fa-solid fa-check text-dark pl-3" style={{ flex: '0 0 auto' }} />}
                                                        </Dropdown.Item>
                                                        {
                                                            index < allMyLists.length - 1 && (<Dropdown.Divider className='m-0' />)
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            allMyLists.length === 0 && (
                                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                    <EmptyView
                                                        description={t('NoDataToDisplay')}
                                                        iconEmpty={AppIcons.icEmptyPack}
                                                        loading={isGetting}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <Dropdown.Item
                                        className='px-5 py-3 btn-light d-flex align-items-center text-primary font-weight-bold'
                                        onClick={() => {
                                            setModalCreateMyListShowing(true);
                                        }}
                                    >
                                        <img src={AppIcons.icPurplePlus} alt="icon" className='mr-3' />{t('CreateMyList')}
                                    </Dropdown.Item>
                                    <Dropdown.Divider className='m-0' />
                                    <Dropdown.Item
                                        className='px-5 py-3 btn-light d-flex align-items-center text-primary font-weight-bold'
                                        onClick={() => {
                                            setModalManageMyListShowing(true)
                                        }}
                                    >
                                        <img src={AppIcons.icGear} alt="icon"
                                            draggable={false} className='mr-3' />{t('ManageMyList')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className='col-12 col-sm-6 col-lg-8'>
                            <div className='row'>
                                <div className='col-xl-3 col-lg-4 col-sm-6 py-2'>
                                    <button type="button" className="btn btn-outline-secondary w-100 font-weight-bold bg-white" onClick={handlePressAddTransaction}><i className="fal fa-plus-circle mr-1" />{t('AddTransaction')}</button>
                                </div>
                                <div className='col-xl-3 col-lg-4 col-sm-6 py-2'>
                                    <button type="button" className="btn btn-outline-secondary w-100 font-weight-bold bg-white" onClick={handlePressImportTransaction}><i className="fal fa-file-import mr-1" />{t('ImportTransaction')}</button>
                                </div>

                                <div className='col-xl-3 col-lg-4 col-sm-6 py-2'>
                                    <a href={Utils.getFullUrl(AppConfigs.fileSampleListImport)} download>
                                        <button type="button" className="btn btn-outline-secondary w-100 font-weight-bold bg-white" onClick={handlePressDownloadSampleTransaction}><i className="fal fa-file-download mr-1" />{t('DownloadSampleImport')}</button>
                                    </a>
                                </div>
                                <div className='col-xl-3 col-lg-4 col-sm-6 py-2'>
                                    <button type="button" className="btn btn-outline-secondary w-100 font-weight-bold bg-white" onClick={handlePressTransactionHistory}><i className="fal fa-history mr-1" />{t('TransactionHistory')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        !Utils.isObjectEmpty(selectedMyList) && (
                            <div className='mt-4 px-4 bg-white border d-flex flex-wrap align-items-center justify-content-center'>
                                <div className='flex-grow-1 mr-4 border-right my-4'>
                                    <p className='m-0 text-muted'>NAV</p>
                                    <p className='m-0 font-size-lg font-weight-bold'>{Utils.formatNumber(summaryData?.NAV ?? 0)}</p>
                                </div>
                                <div className='flex-grow-1 mr-4 border-right my-4'>
                                    <p className='m-0 text-muted'>Tổng Giá Trị Thị Trường</p>
                                    <p className='m-0 font-size-lg font-weight-bold'>{Utils.formatNumber(summaryData?.sumMarketCap ?? 0)}</p>
                                </div>
                                <div className='flex-grow-1 mr-4 border-right my-4'>
                                    <p className='m-0 text-muted'>Tổng Vốn</p>
                                    <p className='m-0 font-size-lg font-weight-bold'>{Utils.formatNumber(summaryData?.sumCap ?? 0)}</p>
                                </div>
                                <div className='flex-grow-1 mr-4 border-right my-4'>
                                    <p className='m-0 text-muted'>Tổng Lãi/Lỗ</p>
                                    <p className='m-0 font-size-lg font-weight-bold' style={{ color: Utils.getValueColor(summaryData?.sumDiff ?? 0) }}>{`${summaryData?.sumDiff > 0 ? '+' : ''}${Utils.formatNumber(summaryData?.sumDiff ?? 0)}`}</p>
                                </div>
                                <div className='flex-grow-1 my-4'>
                                    <p className='m-0 text-muted'>Lãi/Lỗ Trong Ngày</p>
                                    <p className='m-0 font-size-lg font-weight-bold' style={{ color: Utils.getValueColor(summaryData?.sumDayDiff ?? 0) }}>{`${summaryData?.sumDayDiff > 0 ? '+' : ''}${Utils.formatNumber(summaryData?.sumDayDiff ?? 0)}`}</p>
                                </div>
                            </div>
                        )
                    }

                    {/* Content */}
                    <div className='mt-7'>
                        <AppTabs
                            className={''}
                            tabs={menuItems.map(item => item.text).reverse()}
                            activeTab={activeTab ? activeTab.text : null}
                            handleClick={(text) => {
                                var filterByText = menuItems.filter(item => item.text == text);
                                var selectedTab = filterByText[0];
                                // history.push(`${selectedTab.path}`);
                                setSelectedTab(selectedTab.id);
                            }}
                        />

                        {
                            selectedTab === 'my_category_category' ? (
                                <Category
                                    data={listData}
                                    onEditMyListStockCode={(stockCode) => {
                                        setModalEditTransaction(true);
                                    }}
                                    onPressAddTransaction={(stockCode) => {
                                        initialAddTransactionStockCode.current = stockCode;
                                        setModalAddTransactionShowing(true);
                                        setDisableSelectList(true);
                                    }}
                                />
                            ) : selectedTab === 'my_category_ranking' ? (
                                <Ranking data={rankData} groupScore={rankGroupScore} loading={isGetting} />
                            ) : selectedTab === 'my_category_basic' ? (
                                <Basic data={basicData} loading={isGetting} />
                            ) : selectedTab === 'my_category_technical' ? (
                                <Technical data={techniqueData} loading={isGetting} />
                            ) : selectedTab === 'my_category_category_analytic' ? (
                                <CategoryAnalytic data={analysisData} loading={isGetting} />
                            ) : selectedTab === 'my_category_transaction_analysis' ? (
                                <AnalyzeTransactionHistory
                                    loading={isGetting}
                                    transactionHistoryData={analysisTransactionHistory}
                                    transactionHistoryPagination={analysisTransactionHistoryPagination}
                                    onChangePage={(iNewPage) => {
                                        setAnalysisTransactionHistoryFilter({
                                            ...analysisTransactionHistoryFilter,
                                            page: iNewPage,
                                        });
                                    }}
                                    onChangePerPage={(iNewPerPage) => {
                                        setAnalysisTransactionHistoryFilter({
                                            ...analysisTransactionHistoryFilter,
                                            page: 0,
                                            limit: iNewPerPage,
                                        });
                                    }}
                                    transactionsGroupedByStockCode={transactionsGroupedByStockCode}
                                    allTransactionsHistory={selectedMyListDetail}
                                    onChangeInvestmentPerformance={(durationValue, fromDate, toDate) => {
                                        setInvestmentPerformanceDurationValue(durationValue);
                                        setInvestmentPerformanceFrom(fromDate);
                                        setInvestmentPerformanceTo(toDate);
                                        let from = !_.isEmpty(fromDate) ? moment(fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
                                        let to = !_.isEmpty(toDate) ? moment(toDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
                                        console.log({ from, to });
                                        let params = { myListId: selectedMyList?.myListId };
                                        if (!_.isEmpty(from)) {
                                            params.fromDate = from;
                                        }
                                        if (!_.isEmpty(to)) {
                                            params.toDate = to;
                                        }
                                        dispatch(thunkGetInvestmentPerformance(params));
                                    }}
                                    investmentPerformanceDurationValue={investmentPerformanceDurationValue}
                                    investmentPerformanceFrom={investmentPerformanceFrom}
                                    investmentPerformanceTo={investmentPerformanceTo}
                                    investmentPerformanceData={myListInvestmentPerformance}
                                />
                            ) : (
                                <></>
                            )
                        }

                        {/* <Switch>
                            <Redirect
                                exact
                                from={match.url}
                                to={`${match.url}/category`}
                            />
                            <Route path={`${match.url}/category`} component={() => (
                                <Category
                                    data={listData}
                                    onEditMyListStockCode={(stockCode) => {
                                        setModalEditTransaction(true);
                                    }}
                                    onPressAddTransaction={(stockCode) => {
                                        initialAddTransactionStockCode.current = stockCode;
                                        setModalAddTransactionShowing(true);
                                        setDisableSelectList(true);
                                    }}
                                />
                            )} />
                            <Route path={`${match.url}/ranking`} component={() => (<Ranking data={rankData} groupScore={rankGroupScore} loading={isGetting} />)} />
                            <Route path={`${match.url}/basic`} component={() => (<Basic data={basicData} loading={isGetting} />)} />
                            <Route path={`${match.url}/technical`} component={() => (<Technical data={techniqueData} loading={isGetting} />)} />
                            <Route path={`${match.url}/category-analytic`} component={() => (<CategoryAnalytic data={analysisData} loading={isGetting} />)} />
                            <Route path={`${match.url}/analyze-transaction-history`} component={() => (
                                <AnalyzeTransactionHistory
                                    loading={isGetting}
                                    transactionHistoryData={analysisTransactionHistory}
                                    transactionHistoryPagination={analysisTransactionHistoryPagination}
                                    onChangePage={(iNewPage) => {
                                        setAnalysisTransactionHistoryFilter({
                                            ...analysisTransactionHistoryFilter,
                                            page: iNewPage,
                                        });
                                    }}
                                    onChangePerPage={(iNewPerPage) => {
                                        setAnalysisTransactionHistoryFilter({
                                            ...analysisTransactionHistoryFilter,
                                            page: 0,
                                            limit: iNewPerPage,
                                        });
                                    }}
                                    transactionsGroupedByStockCode={transactionsGroupedByStockCode}
                                    allTransactionsHistory={selectedMyListDetail}
                                    onChangeInvestmentPerformance={(durationValue, fromDate, toDate) => {
                                        setInvestmentPerformanceDurationValue(durationValue);
                                        setInvestmentPerformanceFrom(fromDate);
                                        setInvestmentPerformanceTo(toDate);
                                        let from = !_.isEmpty(fromDate) ? moment(fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
                                        let to = !_.isEmpty(toDate) ? moment(toDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
                                        console.log({ from, to });
                                        let params = { myListId: selectedMyList?.myListId };
                                        if (!_.isEmpty(from)) {
                                            params.fromDate = from;
                                        }
                                        if (!_.isEmpty(to)) {
                                            params.toDate = to;
                                        }
                                        dispatch(thunkGetInvestmentPerformance(params));
                                    }}
                                    investmentPerformanceDurationValue={investmentPerformanceDurationValue}
                                    investmentPerformanceFrom={investmentPerformanceFrom}
                                    investmentPerformanceTo={investmentPerformanceTo}
                                    investmentPerformanceData={myListInvestmentPerformance}
                                />)}
                            />

                            <Route
                                render={(props) => (
                                    <NotFound />
                                )}
                            />
                        </Switch> */}
                    </div>
                </div>
            </div>

            {/* Modal create my list */}
            <ModalCreateMyList
                show={modalCreateMyListShowing}
                onClose={() => {
                    setModalCreateMyListShowing(false);
                }}
            />
            <ModalManageMyList
                show={modalManageMyListShowing}
                onClose={() => {
                    setModalManageMyListShowing(false);
                }}
                onOpen={() => {
                    setModalManageMyListShowing(true);
                }}
            />

            <ModalAddTransaction
                show={modalAddTransactionShowing}
                onClose={() => {
                    setModalAddTransactionShowing(false);
                    setDisableSelectList(false);
                }}
                onAddStockSuccess={() => {
                    getMyListData();
                    if (selectedMyList) {
                        dispatch(thunkGetHomeMyListData({ myListId: selectedMyList.myListId }));
                    }
                }}
                initialStockCode={initialAddTransactionStockCode.current}
                disableSelectList={disableSelectList}
            />
            <ModalImportTransaction
                show={modalImportTransactionShowing}
                onClose={() => {
                    setModalImportTransactionShowing(false);
                }}
            />

            {/* Modal transaction history */}
            <ModalTransactionHistory
                show={modalTransactionHistoryShowing}
                onClose={() => {
                    setModalTransactionHistoryShowing(false);
                }}
                onShow={() => {
                    setModalTransactionHistoryShowing(true);
                }}
                onEditSuccess={() => {
                    getMyListData();
                }}
            />

            {/* Modal edit transaction */}
            <ModalEditTransaction
                show={modalEditTransaction}
                onClose={() => setModalEditTransaction(false)}
                onShow={() => setModalEditTransaction(true)}
                onEditSuccess={() => {
                    getMyListData();
                }}
            />

            {/* Modal empty list */}
            <ModalEmptyList
                show={modalEmptyListShowing}
                onClose={() => {
                    setModalEmptyListShowing(false);
                }}
                onAcceptCreateNewList={() => {
                    setModalCreateMyListShowing(true);
                }}
            />
        </AppLayout>
    );
}

export default MyListHomeScreen;