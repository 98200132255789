import "bootstrap/dist/css/bootstrap.min.css";
import CellCourseGrid from 'features/Course/components/CellCourseGrid';
import AppLayout from 'general/components/AppLayout';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { AppIcons } from 'general/constants/AppResource';
import { Markup } from 'interweave';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Accordion, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Utils from "../../../../general/utils/Utils";
import { thunkGetCourseDetail, thunkUpdateLessionProcess } from '../../CourseReducer';
import './style.scss';


CourseDetailScreen.propTypes = {};

const sTag = '[CourseDetailScreen]';

function CourseDetailScreen(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();
    const routeParams = match.params;
    const courseId = routeParams.courseId;
    const history = useHistory()
    const dispatch = useDispatch()
    const [currentSeeLessionId, setCurrentSeeLessionId] = useState(null)
    const [currentVideoDuration, setCurrentVideoDuration] = useState(0)
    const [playing, setPlaying] = useState(false)
    const [isPlayingIntro, setIsPlayingIntro] = useState(false)
    const { t } = useTranslation();
    const topCourses = useSelector(state => state?.app?.topCourses)
    const course = useSelector(state => state?.course?.currentCourse) ?? {}
    const currentLoggedInUser = useSelector(state => state.auth.current);

    // console.log({ currentLoggedInUser, course });

    // MARK: --- Hooks ---
    useEffect(() => {
        window.scrollTo(0, 0)
        getCourseDetail()
    }, [courseId]);

    useEffect(() => {
        getCourseDetail()
        return () => {
            if (currentSeeLessionId != null) {

            }
        }
    }, []);

    const getCourseDetail = () => {
        try {
            dispatch(thunkGetCourseDetail({
                courseId: courseId
            }))
        } catch (e) {
            console.log(e)
        }
    }

    const handleOnClick = (Id, duration) => {
        // nếu chưa chọn bài học nào và thời lượng có thể cập nhật được
        if (currentSeeLessionId != null && (currentSeeLessionId != Id)) {
            let selectLession = _.find(course?.viewProcess, { lessionId: currentSeeLessionId });
            let currentDuration = selectLession?.duration
            if (currentDuration < duration && Math.round(currentVideoDuration) > currentDuration) {
                dispatch(thunkUpdateLessionProcess({
                    lessionId: currentSeeLessionId,
                    duration: Math.round(currentVideoDuration)
                }))
            }
            setPlaying(false)
            setCurrentVideoDuration(null)
            setCurrentSeeLessionId(Id)
        }
        // nếu chọn chính bài học đang xem
        else if (currentSeeLessionId == Id && currentSeeLessionId != null) {
            setCurrentSeeLessionId(null)
            let selectLession = _.find(course?.viewProcess, { lessionId: currentSeeLessionId });
            let currentDuration = selectLession?.duration
            if (currentDuration < duration && currentVideoDuration > currentDuration) {
                dispatch(thunkUpdateLessionProcess({
                    lessionId: currentSeeLessionId,
                    duration: Math.round(currentVideoDuration)
                }))
            }
            setPlaying(false)
            setCurrentVideoDuration(null)
        }
        else {
            setPlaying(false)
            setCurrentSeeLessionId(Id)
        }
    }

    return (
        <AppLayout>
            <div className='CourseDetailScreen bg-white min-h-600px'>
                <div className='container-xxl course-detail-container'>
                    <div className='row'>
                        <div className='col col-12 col-lg-8 col-md-8 col-sm-12 col-xs-12 pb-5'>
                            <div className='rounded-lg overflow-hidden custom-view-intro-video' style={{ aspectRatio: '16/10' }}>
                                <ReactPlayer
                                    url={Utils.getFullUrl(course?.video)}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    playing={isPlayingIntro}
                                    onPlay={() => {
                                        setPlaying(false)
                                        setIsPlayingIntro(true)
                                    }}
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload'
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <SectionHeaderView
                                title='Danh Sách Bài Học'
                                dividerVisible={false}
                            />
                            <Accordion style={{
                                paddingTop: '10px'
                            }}>
                                {
                                    course?.courseLessions?.map((lession) => {
                                        let processList = course?.viewProcess
                                        let current = _.find(processList, { lessionId: lession?.lessionId });
                                        let currentDuration = current?.duration ?? 0
                                        let lessionDuration = lession?.duration
                                        let percentView = currentDuration / lessionDuration > 1 ? 1 : (currentDuration / lessionDuration <= 0 ? 0 : currentDuration / lessionDuration)
                                        let headerClassName = "custom-lession-header"
                                        let iconClassName = ""
                                        let bodyClassName = ""
                                        let viewProcessStyle = {
                                            backgroundColor: "#F4B638",
                                            height: "3px",
                                            width: `${percentView * 100}%`
                                        }
                                        if (currentSeeLessionId == lession?.lessionId) {

                                            headerClassName = "custom-lession-header-selected"
                                            iconClassName = "custom-icon-style"
                                            bodyClassName = "custom-selected-body"
                                            viewProcessStyle = {}
                                        }
                                        return (
                                            <Card key={lession?.lessionId} className={"custom-lession-card"}>
                                                <Accordion.Toggle as={Card.Header} eventKey={lession?.lessionId}
                                                    onClick={() => handleOnClick(lession?.lessionId, lession?.duration)}
                                                    className={"custom-selectHeader"}
                                                    style={{
                                                        borderTopLeftRadius: "5px",
                                                        borderTopRightRadius: "5px",
                                                        overflow: "hidden",
                                                    }}>

                                                    <div className={`${headerClassName}`}>
                                                        <div className={"custom-lession-header-left"}>
                                                            <img
                                                                className={`${iconClassName}`}
                                                                alt='menu-handle'
                                                                draggable={false}
                                                                src={((course?.isPurchased || (lession?.video != null) || course.price == 0 || currentLoggedInUser?.accessLevel <= course?.accessLevel || course?.accessLevel === 3) && (currentLoggedInUser?.accessLevel <= lession?.accessLevel || lession?.accessLevel === 3)) ? AppIcons.icCirclePlay : AppIcons.icLock} />
                                                            <div className={"lession-name"}>{lession.name}</div>
                                                        </div>
                                                        <div
                                                            className={"lession-duration"}>{Utils.displayDuration(lession.duration)}</div>
                                                    </div>
                                                    <div style={viewProcessStyle}></div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={lession?.lessionId} >
                                                    <Card.Body className={`${bodyClassName}`}>
                                                        {
                                                            (course?.isPurchased || course?.price == 0 || currentLoggedInUser?.accessLevel <= course?.accessLevel || course?.accessLevel === 3) ? (
                                                                <div>
                                                                    {
                                                                        currentLoggedInUser?.accessLevel <= lession?.accessLevel || lession?.accessLevel === 3 ? (
                                                                            <div className='custom-view-video'>
                                                                                {lession?.video ?
                                                                                    <>
                                                                                        <ReactPlayer
                                                                                            url={Utils.getFullUrl(lession?.video)}
                                                                                            width='100%'
                                                                                            controls={true}
                                                                                            onProgress={(progress) => {
                                                                                                setCurrentVideoDuration(progress.playedSeconds);
                                                                                            }}
                                                                                            playing={(currentSeeLessionId == lession?.lessionId) && (playing === true)}
                                                                                            onStart={() => setPlaying(true)}
                                                                                            onPlay={() => {
                                                                                                setIsPlayingIntro(false);
                                                                                                setCurrentSeeLessionId(lession?.lessionId)
                                                                                                setPlaying(true)
                                                                                            }}
                                                                                            onPause={() => {
                                                                                                setPlaying(false)
                                                                                            }}
                                                                                            config={{
                                                                                                file: {
                                                                                                    attributes: {
                                                                                                        controlsList: 'nodownload'
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <br />
                                                                                    </> : null
                                                                                }
                                                                                <div className='custom-lession-desc'>{lession?.description}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>{lession?.accessLevel === 1 ? 'Bạn cần đăng ký thuê bao để xem bài học này' : lession?.accessLevel === 2 ? 'Bạn cần đăng ký tài khoản để xem bài học này' : 'Bạn không đủ quyền để xem được bài học này'}</div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div>{'Đăng ký tham gia khoá học để có thể xem được bài học này'}</div>
                                                            )
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    })
                                }
                            </Accordion>
                            <div className={'custom-top-border custom-course-detail-des'}>
                                <SectionHeaderView
                                    title='Chi Tiết Khóa Học'
                                    dividerVisible={false}
                                />
                                <div className={'m-0 font-size-h6 mt-2'}>
                                    <Markup
                                        className='font-size-lg text-justify q-markup'
                                        content={Utils.decodeHTML(course?.content)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col col-12 col-lg-4 col-md-4 col-sm-12'>
                            <div className={'m-0 font-size-h4 font-weight-bolder'}>
                                {course?.name}
                            </div>
                            <div className={'m-0 font-size-lg'}>
                                {course?.description}
                            </div>
                            <div className={"custom-row-course-list-attrs"}>
                                <div className={'custom-row-course-detail'}>
                                    <div className={"m-0 font-size-lg"}>
                                        {"Diễn giả"}
                                    </div>
                                    <div className={"font-size-lg font-weight-bolder"} style={{ color: '#7621B1' }}>
                                        {course?.author}
                                    </div>
                                </div>

                                <div className={'custom-row-course-detail'}>
                                    <div className={'m-0 font-size-lg text-dark-75'}>
                                        {"Trình độ"}
                                    </div>
                                    <div className={"font-size-lg font-weight-bolder"}>
                                        {Utils.getCourseLevelDesc(course?.level)}
                                    </div>
                                </div>

                                <div className={'custom-row-course-detail'}>
                                    <div className={"m-0 font-size-lg"}>
                                        {"Số bài học"}
                                    </div>
                                    <div className={"font-size-lg font-weight-bolder"}>
                                        {course?.courseLessions?.length}
                                    </div>
                                </div>
                                <div className={'custom-row-course-detail custom-top-border'}>
                                    <div className={"m-0 font-size-lg"}>
                                        {"Giá (VNĐ)"}
                                    </div>
                                    <div className={"font-size-h6 font-weight-bolder"}>
                                        {course?.isPurchased ? "Đã mua" : (course?.price > 0) ? Utils.formatNumber(course?.price) : "Miễn Phí"}
                                    </div>
                                </div>
                                {(course.price == 0) ? null :
                                    <div className={'custom-row-course-detail custom-top-border'}>
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            history.push({
                                                pathname: "/payment/purchase",
                                                state: {
                                                    productId: course?.courseId,
                                                    name: course?.name,
                                                    price: course?.price,
                                                    currency: null,
                                                    type: 'COURSE',
                                                    description: course?.description,
                                                },
                                            });
                                        }}
                                            className={`btn btn-primary col-12 font-weight-bold ${(course?.isPurchased || course?.transaction?.status == 'PENDING' || currentLoggedInUser?.accessLevel <= course?.accessLevel || course?.accessLevel === 3) ? 'disabled' : ''}`}
                                        >
                                            {(course?.isPurchased || currentLoggedInUser?.accessLevel <= course?.accessLevel || course?.accessLevel === 3) ? t('Joined') : course?.transaction?.status == 'PENDING' ? t('Wait for confirmation') : t('JoinNow')}
                                        </a>
                                    </div>}
                            </div>
                            <div className='mt-4'>
                                <SectionHeaderView
                                    title='Các Khóa Học Nổi Bật'
                                />
                                <div style={{
                                    paddingTop: '10px'
                                }}>
                                    {
                                        topCourses.length > 0 && topCourses?.map((item, index) => {
                                            return (
                                                <CellCourseGrid key={index} info={item} />
                                            )
                                        })
                                    }

                                    {
                                        topCourses.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default CourseDetailScreen;
