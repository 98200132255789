import store from 'app/store';
import UserHelper from 'general/helpers/UserHelper';
import Utils from 'general/utils/Utils';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

function PrivateRoute(props) {
    // const loggedInUser = store.getState().auth.current;
    const isAuth = UserHelper.checkAccessTokenValid()/* && !Utils.isObjectEmpty(loggedInUser)*/;

    return isAuth
        ? <Route {...props} />
        : <Redirect to='/sign-in' />
}

export default PrivateRoute;