import './style.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import ModalAddWatchlistStock from '../ModalAddWatchlistStock';
import ModalCreateWatchlist from '../ModalCreateWatchlist';
import ModalManageWatchlist from './../ModalManageWatchlist/index';
import { useDispatch, useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import { thunkGetAllWithDetail, setSelectedWatchlist, thunkRemoveStock } from 'features/WatchList/watchlistSlice';
import { Dropdown } from 'react-bootstrap';
import CellStockList from 'features/Stock/components/CellStockList';

let KTOffcanvas = require("assets/plugins/ktoffcanvas");

QuickWatchlist.propTypes = {
    onOffcanvasReady: PropTypes.func,
};

QuickWatchlist.defaultProps = {
    onOffcanvasReady: null,
};

function QuickWatchlist(props) {
    // MARK: --- Params ---
    const { onOffcanvasReady } = props;
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const { t } = useTranslation();
    const refOffcanvasWatchlist = useRef(null);
    const [modalSearchStockShowing, setModalSearchStockShowing] = useState(false);
    const [modalCreateWatchlistShowing, setModalCreateWatchlistShowing] = useState(false);
    const [modalManageWatchlistShowing, setModalManageWatchlistShowing] = useState(false);
    const loggedInUser = useSelector(state => state.auth.current);
    const { isLoadingWatchList, listWatchList, selectedWatchlist } = useSelector(state => state.watchlist);
    const dispatch = useDispatch();
    const [previousSelectedWatchlist, setPreviousSelectedWatchlist] = useState(null)
    const [sortOption, setSortOption] = useState(1)
    const [sortedListStockCode, setSortedListStockCode] = useState([])
    const [currentItem, setCurrentItem] = useState()
    const [menuShowing, setMenushowing] = useState(false)
    const [menuPosition, setMenuPosition] = useState({ width: 0, height: 0 })
    const refMenu = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuShowing && refMenu.current && !refMenu.current.contains(event.target)) {
                setMenushowing(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuShowing]);


    // MARK: --- Functions ---
    function handleOffcanvasReady() {
        if (onOffcanvasReady) {
            onOffcanvasReady(refOffcanvasWatchlist.current);
        }
    }

    function handleSort(sortOption) {
        var sortedList = selectedWatchlist.listStock
        switch (sortOption) {
            case 1:
                setSortedListStockCode(selectedWatchlist.listStock)
                break;
            case 2:
                sortedList = sortedList.slice().sort((a, b) => a.stockCode.localeCompare(b.stockCode))
                setSortedListStockCode(sortedList)
                break;
            case 3:
                sortedList = sortedList.slice().sort((b, a) => a.stockCode.localeCompare(b.stockCode))
                setSortedListStockCode(sortedList)
                break;
            default:
                break;
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (typeof KTOffcanvas !== "undefined" && refOffcanvasWatchlist.current === null) {
            let offCanvasObject = new KTOffcanvas("quick_watchlist_aside", {
                baseClass: "offcanvas",
                overlay: true,
                toggleBy: {
                    target: 'watchlist-menu-handle',
                }
            });
            refOffcanvasWatchlist.current = offCanvasObject;
            handleOffcanvasReady();
        }
    }, []);

    useEffect(() => {
        if (listWatchList.length === 0) {
            dispatch(thunkGetAllWithDetail({}))
        }
    }, [loggedInUser]);

    useEffect(() => {
        if (selectedWatchlist?.watchlistId !== previousSelectedWatchlist?.watchlistId) {
            setSortOption({ id: 1, title: t('Default') })
            setPreviousSelectedWatchlist(selectedWatchlist)
        }
    }, [selectedWatchlist]);

    useEffect(() => {
        if (selectedWatchlist?.listStock) {
            setSortedListStockCode(selectedWatchlist.listStock)
            handleSort(sortOption.id)
        }
    }, [selectedWatchlist?.listStock]);

    return (
        <div className='QuickWatchlist offcanvas offcanvas-right bg-white pt-6 d-flex flex-column' id='quick_watchlist_aside'>
            <div className='d-flex align-items-center justify-content-between mb-2 px-5'>
                <h3 className='font-weight-bolder'>
                    Watchlist
                </h3>
                <div
                    className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        refOffcanvasWatchlist.current.hide();
                    }}
                >
                    <i className="far fa-times"></i>
                </div>
            </div>
            <div className='mt-1 border-top' />

            {
                (Utils.isObjectNull(loggedInUser) || Utils.isObjectEmpty(loggedInUser))
                    ? <div className='d-flex align-items-center justify-content-between px-5 flex-grow-1'>
                        <EmptyView
                            iconEmpty={AppIcons.icCircleUser}
                            title={t('SignIn')}
                            description={t('WatchlistNotLoggedInDescription')}
                            buttonText={t('SignIn')}
                            buttonIcon='far fa-sign-in'
                            onPressButton={() => {
                                history.push('/sign-in');
                            }}
                        />
                    </div>
                    : listWatchList.length == 0
                        ? <div className='d-flex align-items-center justify-content-between px-5 flex-grow-1'>
                            <EmptyView
                                iconEmpty={AppIcons.icBarChartUp}
                                title={t('WatchlistEmptyTitle')}
                                description={t('WatchlistEmptyDescription')}
                                buttonText={t('CreateWatchlist')}
                                buttonIcon='far fa-solid fa-plus'
                                onPressButton={() => {
                                    setModalCreateWatchlistShowing(true);
                                }}
                            />
                        </div>
                        : <div className='d-flex flex-column h-100 w-100'>
                            <Dropdown className="w-100">
                                <Dropdown.Toggle
                                    className='w-100 px-5 py-3 font-size-h4 font-weight-bold border-bottom rounded-0 bg-hover-light d-flex justify-content-between align-items-center'
                                    variant=""
                                    id="dropdown-autoclose-true"
                                >
                                    <div className='' style={{ overflowX: 'hidden' }}>{selectedWatchlist.name}</div>
                                    <i className="fa fa-solid fa-caret-down pl-6 text-dark"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='Dropdown-menu w-75 p-0 font-size-h6 justify-content-center align-content-center border shadow-lg'>
                                    <div className='px-5 py-3 font-weight-bold'>{t('WatchlistList')}</div>
                                    <div className='border-y' style={{ maxHeight: 200, overflowY: 'auto', overflowX: 'hidden' }}>
                                        {
                                            listWatchList.map((item, index) =>
                                                <Dropdown.Item
                                                    key={item.watchlistId}
                                                    className={`d-flex align-items-center btn-light ${index < listWatchList.length - 1 && 'border-bottom'}`}
                                                    onClick={() => {
                                                        dispatch(setSelectedWatchlist(item))
                                                    }}
                                                >
                                                    <div style={{ overflowX: 'hidden', flex: '1 1 auto' }}>
                                                        <img src={AppIcons.icSquareList} alt="icon"
                                                            draggable={false} className='mr-3' />
                                                        {item.name}
                                                    </div>
                                                    {selectedWatchlist.watchlistId === item.watchlistId && <i className="far fa-solid fa-check text-dark pl-3" style={{ flex: '0 0 auto' }} />}
                                                </Dropdown.Item>
                                            )
                                        }
                                    </div>
                                    <Dropdown.Item
                                        className='px-5 py-3 btn-light d-flex align-items-center text-primary font-weight-bold border-bottom'
                                        onClick={() => setModalCreateWatchlistShowing(true)}
                                    >
                                        <img src={AppIcons.icPurplePlus}
                                            draggable={false} alt="icon" className='mr-3' />{t('CreateWatchlist')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className='px-5 py-3 btn-light d-flex align-items-center text-primary font-weight-bold'
                                        onClick={() => setModalManageWatchlistShowing(true)}
                                    >
                                        <img src={AppIcons.icGear} alt="icon"
                                            draggable={false} className='mr-3' />{t('ManageWatchlist')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <div className='h-100 p-0 q-hide-scrollbar' style={{ overflowY: 'scroll' }}>
                                <div className='mt-4 p-0 q-hide-scrollbar' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 130px)' }}>
                                    {
                                        sortedListStockCode.length > 0 && sortedListStockCode.map((item, index) =>
                                            <CellStockList
                                                key={index}
                                                item={item.stock ?? {}}
                                                onPress={(e) => {
                                                    setCurrentItem(item)
                                                    setMenushowing(true)
                                                    setMenuPosition({ width: e.clientX, height: e.clientY })
                                                }}
                                                showDivider={index < sortedListStockCode.length - 1}
                                                className=''
                                            />
                                        )
                                    }

                                    {
                                        sortedListStockCode.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* Bottom button */}
                            <div className='p-4 bg-white position-absolute bottom-0 w-100 d-flex justify-content-center'>
                                <div className='w-100 row'>
                                    <div className='col-6 px-0 border-right border-right-white'>
                                        <div
                                            className='w-100 d-flex justify-content-center align-items-center btn btn-primary rounded-right-0 font-size-lg font-weight-bold'
                                            onClick={() => setModalSearchStockShowing(true)}
                                        >
                                            <img src={AppIcons.icWhitePlus} alt="icon"
                                                draggable={false} className='mr-3' />{t('AddStock')}
                                        </div>
                                    </div>
                                    <div className='col-6 px-0'>
                                        <Dropdown className="w-100">
                                            <Dropdown.Toggle
                                                className='w-100 d-flex justify-content-center align-items-center btn btn-primary rounded-left-0 font-size-lg font-weight-bold'
                                                id="dropdown-autoclose-true"
                                                drop="up"
                                            >
                                                <img src={AppIcons.icArrowDownShortWide} alt="icon"
                                                    draggable={false} className='mr-3' />{t('SortBy')}: {sortOption.title}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100 font-size-lg shadow-lg'>
                                                <Dropdown.Item
                                                    className='border-bottom'
                                                    onClick={() => {
                                                        setSortOption({ id: 1, title: t('Default') })
                                                        handleSort(1)
                                                    }}
                                                    active={sortOption.id == 1 ? true : false}
                                                >{t('Default')}</Dropdown.Item>
                                                <Dropdown.Item
                                                    className='border-bottom'
                                                    onClick={() => {
                                                        setSortOption({ id: 2, title: 'A - Z' })
                                                        handleSort(2)
                                                    }}
                                                    active={sortOption.id == 2 ? true : false}
                                                >A - Z</Dropdown.Item>
                                                <Dropdown.Item
                                                    className='border-bottom'
                                                    onClick={() => {
                                                        setSortOption({ id: 3, title: 'Z - A' })
                                                        handleSort(3)
                                                    }}
                                                    active={sortOption.id == 3 ? true : false}
                                                >Z - A</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                        </div>
            }

            {/* Modal search + add stock */}
            <ModalAddWatchlistStock
                show={modalSearchStockShowing}
                onClose={() => {
                    setModalSearchStockShowing(false);
                }}
                watchlistId={selectedWatchlist?.watchlistId}
            />

            {/* Modal create watchlist */}
            <ModalCreateWatchlist
                show={modalCreateWatchlistShowing}
                onClose={() => {
                    setModalCreateWatchlistShowing(false);
                }}
            />

            {/* Modal manage watchlist */}
            <ModalManageWatchlist
                show={modalManageWatchlistShowing}
                onClose={() => {
                    setModalManageWatchlistShowing(false);
                }}
                onOpen={() => {
                    setModalManageWatchlistShowing(true);
                }}
            />

            <div
                ref={refMenu}
                className='position-absolute bg-white border shadow-lg d-flex flex-column rounded w-150px'
                style={{
                    top: (document.documentElement.clientHeight - menuPosition.height < 150
                        ? menuPosition.height - 125
                        : menuPosition.height),
                    right: (menuPosition.width < 150
                        ? document.documentElement.clientWidth - menuPosition.width - 150
                        : document.documentElement.clientWidth - menuPosition.width),
                    visibility: menuShowing ? 'visible' : 'hidden'
                }}
            >
                <div
                    className='btn btn-white rounded-bottom-0 border-bottom font-size-lg'
                    onClick={() => {
                        history.push(`/stock/${currentItem.stockCode}`);
                        refOffcanvasWatchlist.current.hide();
                        setMenushowing(false)
                    }}
                >{t('Detail')}</div>
                <div
                    className='btn btn-white rounded-top-0 border-bottom font-size-lg'
                    onClick={() => {
                        dispatch(thunkRemoveStock({ watchlistStockId: currentItem.watchlistStockId }))
                        setMenushowing(false)
                    }}
                >{t('Delete')}</div>
                <div
                    className='btn btn-white rounded-top-0 font-size-lg'
                    onClick={() => {
                        setMenushowing(false)
                    }}
                >{t('Close')}</div>
            </div>

        </div>
    );
}

export default QuickWatchlist;
