// quybka
import appReducer from 'app/appSlice';
import authReducer from 'app/authSlice';
import accountReducer from 'features/Account/accountSlice';
import watchlistReducer from 'features/WatchList/watchlistSlice';
import notificationReducer from 'features/Notification/notificationSlice';
import myListReducer from 'features/MyList/myListSlice';
import stockReducer from 'features/Stock/stockSlice';
// duy
import courseReducer from "../features/Course/CourseReducer";
import transactionReducer from "../features/Account/TransactionReducer";
import ideaListReducer from "../features/IdeaList/IdeaListSlice"
import paymentReducer from "../features/Payment/paymentSlice";

const { configureStore } = require("@reduxjs/toolkit");

const rootReducer = {
    // quybka
    app: appReducer,
    auth: authReducer,
    account: accountReducer,
    watchlist: watchlistReducer,
    notification: notificationReducer,
    myList: myListReducer,
    stock: stockReducer,
    // duy
    course: courseReducer,
    transaction: transactionReducer,
    ideaList: ideaListReducer,
    payment: paymentReducer
};

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.REACT_APP_DEV_TOOLS == 1 ? true : false,
});

export default store;
