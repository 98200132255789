import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import myListApi from 'api/myListApi';

import Global from 'general/Global';
import Utils from 'general/utils/Utils';

import BaseDropdownSelect from 'general/components/Forms/FormControls/BaseDropdownSelect';
import BaseTextArea from 'general/components/Forms/FormControls/BaseTextArea';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import StockSearchBar from './StockSearchBar';

import CellStockList from 'features/Stock/components/CellStockList';

import stockApi from 'api/stockApi';
import DropdownSelect from 'general/components/Forms/FormControls/DropdownSelect';
import DatePickerInputMask from 'general/components/Forms/FormWidgets/DatePickerInputMask';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import { useHistory } from 'react-router-dom';
import ModalCreateMyList from '../ModalCreateMyList';
import './style.scss';


ModalAddTransaction.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    initialStockCode: PropTypes.string,
    onAddStockSuccess: PropTypes.func,
    disableSelectList: PropTypes.bool,
};

ModalAddTransaction.defaultProps = {
    show: false,
    onClose: null,
    initialStockCode: null,
    onAddStockSuccess: null,
    disableSelectList: false,
};

/**
 * @prop show (bool): default false
 * @function onClose(): callback when close. Default null
 */
function ModalAddTransaction(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { show, onClose, initialStockCode, onAddStockSuccess, disableSelectList } = props;
    const { isGetting, allMyLists, selectedMyList } = useSelector(state => state.myList);
    const [modalCreateMyListShowing, setModalCreateMyListShowing] = useState(false);
    const [showing, setShowing] = useState(true);

    // MARK: --- State ---
    const [filterStock, setFilterStock] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
        searchKey: '',
    });

    const [loadingStock, setLoadingStock] = useState(false);      // prevent call api when previous api still fetching
    const [stocks, setStocks] = useState([]);                   // all result be fetched by same filter
    const [paginationStock, setPaginationStock] = useState(null);

    const [stockCodeText, setStockCodeText] = useState('');

    const loggedInUser = useSelector(state => state.auth.current);
    const isUserLoggedIn = !Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser);
    const history = useHistory();

    // MARK: --- Formik ---
    // Should be in config file and language file
    const priceRange = [1, 100000000]
    const volumeRange = [1, 100000000000]
    const noteMaxLength = 200;
    const priceRangeErrorMessage = 'Giá phải nằm trong khoảng 0 đến 100.000.000';
    const volumeRangeErrorMessage = 'Khối lượng phải nằm trong khoảng 0 đến 100.000.000.000';
    const noteRangeErrorMessage = `Ghi chú không thể dài quá ${noteMaxLength} ký tự`;

    const formik = useFormik({
        initialValues: {
            listId: '',
            stockCode: '',
            stockCodeIsValid: 'false',
            order: 'Buy',
            date: moment().format('DD/MM/YYYY'),
            price: 0,
            volume: 0,
            note: '',
        },
        validationSchema: Yup.object({
            listId: Yup.number().required(t('Required')),
            stockCode: Yup.string().required(t('Required')),
            stockCodeIsValid: Yup.mixed().oneOf(['true'], t('Mã cổ phiếu không tồn tại. Chọn mã từ gợi ý')),
            order: Yup.mixed()
                .oneOf(['Sell', 'Buy']),
            date: Yup.string().transform(value => {
                // console.log({ value });
                if (value && !value.includes('_') && moment(value, 'DD/MM/YYYY').isValid()) {
                    return value;
                }
                return '';
            }).required(t('Required')),
            price: Yup.number()
                .min(priceRange[0], t(priceRangeErrorMessage))
                .max(priceRange[1], t(priceRangeErrorMessage))
                .required(t('Required')),
            volume: Yup.number()
                .min(volumeRange[0], t(volumeRangeErrorMessage))
                .max(volumeRange[1], t(volumeRangeErrorMessage))
                .required(t('Required')),
            note: Yup.string().max(noteMaxLength, noteRangeErrorMessage).nullable()
        }),
        onSubmit: (values) => {
            // console.log({ values });
            const params = {
                myListId: values.listId,
                stockCode: values.stockCode,
                price: values.price,
                quantity: values.volume,
                command: values.order.toUpperCase(),
                note: values.note
            };
            if (!_.isEmpty(values.date) && moment(values.date, 'DD/MM/YYYY').isValid()) {
                params['date'] = moment(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
            addStockToList(params);
        },
    });


    // MARK: --- Functions ---
    // function call api add Transaction To MyList
    async function addStockToList(params) {
        // console.log({ params });
        // return;

        const res = await myListApi.addStock(params);
        // console.log({ res });
        const { result, detail } = res;
        if (result === 'success' && detail) {
            ToastHelper.showSuccess(t('AddTransactionSuccess'));
            if (onAddStockSuccess) {
                onAddStockSuccess();
            }
            handleClose();
        }
    }

    // API to search. Called in useEffect when filterStock change
    async function searchStock() {
        setLoadingStock(true);
        const res = await stockApi.getListStockCode(filterStock);
        const { result, total, count, page } = res;
        const searchedStocks = res.stocks;
        // console.log({ searchedStocks });
        if (result === 'success' && searchedStocks) {
            if (page > 0) {
                setStocks(stocks.concat(searchedStocks));
            } else {
                setStocks(searchedStocks);
            }
            setPaginationStock({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoadingStock(false);
    }

    // load more stock code by filterCompany when scroll to bottom 
    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 30;
        if (bottom) {
            if (!loadingStock) {
                let nextPage = Utils.getNextPage(paginationStock);
                if (nextPage) {
                    setFilterStock({
                        ...filterStock,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // close modal
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }


    // MARK: --- Hook ---
    useEffect(() => {
        searchStock();
    }, [filterStock]);

    useEffect(() => {
        if (selectedMyList) {
            formik.getFieldHelpers('listId').setValue(selectedMyList.myListId);
        }
    }, [selectedMyList]);

    useEffect(() => {
        if (initialStockCode && initialStockCode.length > 0) {
            formik.getFieldHelpers('stockCode').setValue(initialStockCode);
            formik.getFieldHelpers('stockCodeIsValid').setValue('true');

            // if (allMyLists.length > 0) {
            //     formik.getFieldHelpers('listId').setValue(allMyLists[0].myListId);
            // }
        } else {
            formik.getFieldHelpers('stockCode').setValue('');
            formik.getFieldHelpers('stockCodeIsValid').setValue('false');
        }
    }, [initialStockCode]);

    return (
        <div>
            <Modal
                className=''
                show={show && showing}
                onHide={() => {
                    handleClose();
                }}
                centered
                onExit={() => {
                    formik.handleReset();
                    if (selectedMyList) {
                        formik.getFieldHelpers('listId').setValue(selectedMyList.myListId);
                    }
                    if (!Utils.isObjectNull(initialStockCode)) {
                        formik.getFieldHelpers('stockCode').setValue(initialStockCode);
                        formik.getFieldHelpers('stockCodeIsValid').setValue('true');
                        // if (allMyLists.length > 0) {
                        //     formik.getFieldHelpers('listId').setValue(allMyLists[0].myListId);
                        // }
                    }
                }}
            >
                <Modal.Header className='px-5 py-3'>
                    <Modal.Title>
                        {t('AddStockToList')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='bg-light p-6'>
                    {
                        isUserLoggedIn && (
                            <div>
                                {/* Chọn Danh Mục  */}
                                <div className=''>
                                    <p className='Number_14B Capitalize'>{t('ListToAdd')}</p>
                                    <DropdownSelect
                                        name='listId'
                                        disabled={disableSelectList}
                                        options={
                                            allMyLists.map(item => {
                                                return { value: item.myListId, text: item.name }
                                            })
                                        }
                                        fieldHelpers={formik.getFieldHelpers('listId')}
                                        fieldProps={formik.getFieldProps('listId')}
                                        fieldMeta={formik.getFieldMeta('listId')}
                                        additionContainerSelectClass='w-100'
                                        actionItems={[
                                            {
                                                value: '', text: t('CreateMyList'), onPress: () => {
                                                    setModalCreateMyListShowing(true);
                                                    setShowing(false);
                                                }
                                            }
                                        ]}
                                    />
                                </div>

                                <div className='mt-6'>
                                    {/* Tìm Mã Cổ Phiếu  */}
                                    <p className='Number_14B Capitalize'>{t('StockCode')}</p>
                                    <StockSearchBar
                                        name='stockCode'
                                        className='custom-search-stock'
                                        placeholder={`${t('TypeToSearch')}...`}
                                        onSubmit={(text) => {
                                            // console.log({ text });
                                            setFilterStock({
                                                ...filterStock,
                                                page: 0,
                                                searchKey: text,
                                            })
                                            setStockCodeText('')
                                        }}
                                        typingTimeout={300}
                                        fieldHelpers={formik.getFieldHelpers('stockCode')}
                                        fieldProps={{ ...formik.getFieldProps('stockCode'), autoComplete: "off" }}
                                        fieldMeta={formik.getFieldMeta('stockCode')}
                                        validProps={formik.getFieldProps('stockCodeIsValid')}
                                        validHelpers={formik.getFieldHelpers('stockCodeIsValid')}
                                        validMeta={formik.getFieldMeta('stockCodeIsValid')}
                                        disabled={!Utils.isObjectNull(initialStockCode)}
                                    />
                                    {
                                        (stockCodeText.length > 0) && formik.values.stockCodeIsValid === 'true' && (
                                            <span className="form-text text-muted">{formik.values.stockCode} &#45; {stockCodeText}</span>
                                        )
                                    }

                                    {/* Kết Quả Tìm Kiếm  */}
                                    {
                                        !(formik.values.stockCodeIsValid === 'true') && formik.values.stockCode && Utils.isObjectNull(initialStockCode) && (
                                            <div className='Search_Result flex-grow-1 overflow-auto py-4 bg-white border-left border-bottom border-right' onScroll={handleScroll}>
                                                {
                                                    stocks.length > 0 && stocks.map((item, index) => {
                                                        return (
                                                            <CellStockList
                                                                key={index}
                                                                item={item}
                                                                onPress={() => {
                                                                    formik.getFieldHelpers('stockCode').setValue(item.stockCode)
                                                                    formik.getFieldHelpers('stockCodeIsValid').setValue('true')
                                                                    setStockCodeText(item.stock ? item.stock.stockName : '')
                                                                }}
                                                                showDivider={index < stocks.length - 1}
                                                                className='px-2'
                                                            />
                                                        )
                                                    })
                                                }

                                                {
                                                    stocks.length === 0 && (
                                                        <div className="fv-plugins-message-container px-4">
                                                            <div className="fv-help-block">{t('NoItemsFound')}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                                <div className='row'>
                                    {/* Chọn Lệnh Mua / Bán  */}
                                    <div className='col-6 mt-6'>
                                        <p className='Number_14B Capitalize'>{t('Command')}</p>
                                        <BaseDropdownSelect
                                            name='order'
                                            options={[
                                                { value: 'Buy', text: t('Buy') },
                                                { value: 'Sell', text: t('Sell') },
                                            ]}
                                            fieldHelpers={formik.getFieldHelpers('order')}
                                            fieldProps={{ ...formik.getFieldProps('order'), value: formik.values.order }}
                                            fieldMeta={formik.getFieldMeta('order')}
                                            additionContainerSelectClass='w-100'
                                        />
                                    </div>

                                    {/* Chọn Ngày  */}
                                    <div className='col-6 mt-6'>
                                        <p className='Number_14B Capitalize'>{t('Date')} {t(formik.values.order)}</p>
                                        <DatePickerInputMask
                                            name='date'
                                            fieldHelpers={formik.getFieldHelpers('date')}
                                            fieldMeta={formik.getFieldMeta('date')}
                                            fieldProps={formik.getFieldProps('date')}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    {/* Nhập Giá  */}
                                    <div className='col-6 mt-0'>
                                        <p className='Number_14B Capitalize'>{t('Price')}</p>
                                        <BaseTextField
                                            name='price'
                                            type='number'
                                            placeholder='0'
                                            fieldHelpesr={formik.getFieldHelpers('price')}
                                            fieldProps={{ ...formik.getFieldProps('price'), min: 0 }}
                                            fieldMeta={formik.getFieldMeta('price')}
                                            className='custom-text-field w-100 mt-1'
                                        />
                                    </div>

                                    {/* Nhập Khối Lượng */}
                                    <div className='col-6 mt-0'>
                                        <p className='Number_14B Capitalize'>{t('Volume')}</p>
                                        <BaseTextField
                                            name='volume'
                                            type='number'
                                            placeholders='0'
                                            fieldHelper={formik.getFieldHelpers('volume')}
                                            fieldProps={{ ...formik.getFieldProps('volume'), min: 0 }}
                                            fieldMeta={formik.getFieldMeta('volume')}
                                            className='custom-text-field w-100 mt-1'
                                        />
                                    </div>
                                </div>

                                {/* Nhập Ghi Chú  */}
                                <div className='mt-6'>
                                    <p className='Number_14B Capitalize'>{t('Notes')}</p>
                                    <BaseTextArea
                                        name='note'
                                        fieldHelpers={formik.getFieldHelpers('note')}
                                        fieldProps={{ ...formik.getFieldProps('note'), maxLength: noteMaxLength }}
                                        fieldMeta={formik.getFieldMeta('note')}
                                        className='w-100 mt-1'
                                        placeholder={`${t('Notes')}...`}
                                        resizable={false}
                                        text={`${formik.values.note.length}/${noteMaxLength} ${t('Characters').toLowerCase()}`}
                                    />
                                </div>
                            </div>
                        )
                    }

                    {
                        !isUserLoggedIn && (
                            <EmptyView
                                iconEmpty={AppIcons.icCircleUser}
                                title={t('SignIn')}
                                description={t('PleaseSignInFirst')}
                                buttonText={t('SignIn')}
                                buttonIcon='far fa-sign-in'
                                onPressButton={() => {
                                    history.push('/sign-in');
                                }}
                            />
                        )
                    }
                </Modal.Body>

                <Modal.Footer className=''>
                    <div className='w-100 d-flex row'>
                        <Button className='font-weight-bold flex-grow-1 col mr-3' variant="secondary" onClick={() => {
                            handleClose();
                        }}>
                            {t('Cancel')}
                        </Button>
                        {
                            isUserLoggedIn && (
                                <Button className='font-weight-bold flex-grow-1 col ml-3' variant="primary" onClick={() => {
                                    formik.handleSubmit();
                                }}>
                                    {t('Add')}
                                </Button>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Modal create my list */}
            <ModalCreateMyList
                show={modalCreateMyListShowing}
                onClose={() => {
                    setModalCreateMyListShowing(false);
                    setShowing(true);
                }}
            />
        </div>
    );
}

export default ModalAddTransaction;
