import React from "react";
import PropTypes from "prop-types";
import { AppIcons } from "general/constants/AppResource";
import { AppImages } from "general/constants/AppResource";
import { useTranslation } from "react-i18next";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
CustomToggleButton.propTypes = {};

function CustomToggleButton({ children, eventKey, btnColor, borderShape, onClick }) {
  let className =
    "btn w-100 h-100 font-weight-bolder font-size-base text-left purple rounded";
  className += btnColor;
  className += borderShape;


  const decoratedOnClick = useAccordionToggle(eventKey, () => {

  });

  return (
    <div
      className={className}
      type="button"
      onClick={() => {
        decoratedOnClick();
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
}

export default CustomToggleButton;
