import axiosClient from "./axiosClient";

const videoApi = {
    // Get video list
    getVideoList: (params) => {
        const url = '/knowledge/video/find';
        return axiosClient.post(url, params);
    },

    // Get video info by id
    getVideoInfo: (videoId) => {
        const url = '/knowledge/video/show';
        return axiosClient.post(url, {
            videoId: parseInt(videoId),
        });
    },

    // get related videos
    getRelatedVideos: (params) => {
        const url = '/knowledge/video/get_related';
        return axiosClient.get(url, { params });
    },
};

export default videoApi;