import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AppData from 'general/constants/AppData';
import { useSelector } from 'react-redux';

StockIndexHeaderView.propTypes = {

};

function StockIndexHeaderView(props) {
    // MARK: --- Params ---
    const { realtimeIndexes } = useSelector(state => state.app);
    // console.log({ realtimeIndexes });

    return (
        <div className={`overflow-hidden bg-container min-h-55px align-items-center justify-content-center ${realtimeIndexes.length === 0 ? 'd-none' : 'd-flex'}`} style={{ whiteSpace: 'nowrap' }}>
            <span className="stock-index mr-0">
                {
                    realtimeIndexes.map((item, index) => {
                        return (
                            <span className='mx-4' key={index}>
                                <span className='text-white font-weight-bolder'>{item.stockCode}&nbsp;</span>
                                <span className={`${item.change > 0 ? 'up-color' : 'down-color'}`}>{item.price}&nbsp;<i className={`fas fa-caret-${item.change > 0 ? 'up' : 'down'} ${item.change > 0 ? 'up-color' : 'down-color'}`}></i>{`${Math.abs(item.changeInPercent)}%`}</span>
                            </span>
                        )
                    })
                }
            </span>
            {/* <span className="stock-index mr-0">
                {
                    AppData.stockIndexSampleData.map((item, index) => {
                        return (
                            <span className='mx-4' key={index}>
                                <span className='text-white font-weight-bolder'>{item.name}&nbsp;</span>
                                <span className={`${item.change > 0 ? 'up-color' : 'down-color'}`}>{item.value}&nbsp;<i className={`fas fa-caret-${item.change > 0 ? 'up' : 'down'} ${item.change > 0 ? 'up-color' : 'down-color'}`}></i>3.29</span>
                            </span>
                        )
                    })
                }
            </span>
            <span className="stock-index mr-0">
                {
                    AppData.stockIndexSampleData.map((item, index) => {
                        return (
                            <span className='mx-4' key={index}>
                                <span className='text-white font-weight-bolder'>{item.name}&nbsp;</span>
                                <span className={`${item.change > 0 ? 'up-color' : 'down-color'}`}>{item.value}&nbsp;<i className={`fas fa-caret-${item.change > 0 ? 'up' : 'down'} ${item.change > 0 ? 'up-color' : 'down-color'}`}></i>3.29</span>
                            </span>
                        )
                    })
                }
            </span> */}
        </div>
    );
}

export default StockIndexHeaderView;