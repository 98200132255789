import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { thunkCreateWatchList } from 'features/WatchList/watchlistSlice';
import ToastHelper from './../../../../general/helpers/ToastHelper';
import { useFormik } from 'formik';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import * as Yup from 'yup';

ModalCreateWatchlist.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalCreateWatchlist.defaultProps = {
    show: false,
    onClose: null,
};

function ModalCreateWatchlist(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { isLoadingWatchList, listWatchList } = useSelector(state => state.watchlist);
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => {
            for(let i = 0; i < listWatchList.length ; i++)
                if(listWatchList[i].name === values.name) 
                    {
                        ToastHelper.showError(`${t('WatchlistNameExisted')}`)
                        return
                    }
            dispatch(thunkCreateWatchList(values.name))
            handleClose()
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('Required')),
        }),
    });
    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
            formik.handleReset();
        }
    }

    return (
        <div>
            <Modal
                className='font-weight-bold'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex'>
                    <Modal.Title>
                        {t('CreateWatchlist')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => handleClose()}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='bg-light font-size-lg font-weight-bold'>
                    <div>
                        <BaseTextField
                            label={t('NewWatchlistName')}
                            placeholder={`${t('NewWatchlistName')}...`}
                            name='watchlistName'
                            fieldHelper={formik.getFieldHelpers('name')}
                            fieldProps={formik.getFieldProps('name')}
                            fieldMeta={formik.getFieldMeta('name')}
                            className=''
                            spellCheck={false}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-light font-size-lg font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-primary font-size-lg font-weight-bold'
                                onClick={() => { formik.handleSubmit() }}
                            >
                                {t('CreateWatchlist')}
                            </a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalCreateWatchlist;