import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import usePushNotifications from 'hooks/usePushNotifications';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';

PushNotification.propTypes = {

};

const sTag = '[PushNotification]';

function PushNotification(props) {
    // MARK: --- Params ---
    const {
        userConsent,
        pushNotificationSupported,
        userSubscription,
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        error,
        loading
    } = usePushNotifications();
    const isConsentGranted = userConsent === 'granted';
    const currentLoggedInUser = useSelector(state => state.auth.current);

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} did load`);

        return () => {
            console.log(`${sTag} will dismiss`);
        }
    }, []);

    useEffect(() => {
        if (pushNotificationSupported) {
            console.log(`${sTag} Push notifications are supported by your device.`);

            if (!isConsentGranted) {
                console.log(`${sTag} Ask User Permission`);
                onClickAskUserPermission();
            } else {
                console.log(`${sTag} Consent Granted`);

                if (!userSubscription) {
                    console.log(`${sTag} UserSubscription Invalid`);
                    onClickSusbribeToPushNotification();
                }
            }
        } else {
            console.log(`${sTag} Push notifications are NOT supported by your device.`);
        }
    }, [pushNotificationSupported, isConsentGranted]);

    useEffect(() => {
        if (userSubscription && currentLoggedInUser && !Utils.isObjectEmpty(currentLoggedInUser)) {
            console.log(`${sTag} UserSubscription = ${JSON.stringify(userSubscription)}`);
            onClickSendSubscriptionToPushServer();
        }
    }, [userSubscription, currentLoggedInUser]);

    useEffect(() => {
        if (error) {
            console.log(`${sTag} Error Name: ${error.name}`);
            console.log(`${sTag} Error Message: ${error.message}`);
            console.log(`${sTag} Error Code: ${error.code}`);
        }
    }, [error]);

    return (<></>);
}

export default PushNotification;