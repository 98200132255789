import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import Utils from "general/utils/Utils";
import { useTranslation } from "react-i18next";
import { AppImages } from "general/constants/AppResource";

CellVideoGrid.propTypes = {
  onPress: PropTypes.func,
  item: PropTypes.object,
};

CellVideoGrid.defaultProps = {
  onPress: null,
  item: {},
};

function CellVideoGrid(props) {
  // MARK: --- Params ---
  const { onPress, item } = props;
  const { name, description, url, category, thumbnail, updatedAt } = item;
  const { t } = useTranslation();

  // MARK: --- Functions ---
  function handlePress(e) {
    e.preventDefault();
    if (onPress) {
      onPress();
    }
  }

  return (
    <div className="CellVideoGrid border rounded overflow-hidden">
      <div className="position-relative CellVideoGrid_Image">
        <img
          className="w-100 min-w-100 border-bottom bg-light"
          draggable={false}
          src={Utils.getFullUrl(thumbnail) ?? AppImages.imgDefaultThumbnailVideo}
          style={{
            objectFit: 'cover',
            aspectRatio: '16/9'
          }}
          alt='thumbnail'
          onError={(e) => {
            e.target.src = AppImages.imgDefaultThumbnailVideo;
          }}
        />
        <a
          href="#"
          className="position-absolute btn btn-dark CellVideoGrid_ButtonShare"
          style={{
            top: '10px',
            right: '10px'
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <i className="fas fa-share m-0 p-0"></i>
        </a>
      </div>

      <div className="p-5">
        <a href="#" onClick={handlePress} className='font-size-h6 font-weight-bolder m-0 q-max-line-2 mb-2 text-dark-75 text-hover-primary' style={{ lineHeight: '120%' }}>{name}</a>
        <p className='font-weight-bold mt-1'>{`${t('Category')}: `}<span className='font-size-lg font-weight-bolder text-dark-75'>{category && category.name}</span></p>
        <p className='font-size-lg m-0 text-justify q-max-line-2 my-1 text-dark-50'>
          {description}
        </p>
        <p className='font-size-lg m-0 text-muted q-max-line-2'>{Utils.formatDateTime(updatedAt, 'DD/MM/YYYY HH:mm')}</p>
      </div>
    </div>
  );
}

export default CellVideoGrid;
