import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';
import AppData from 'general/constants/AppData';
import Utils from 'general/utils/Utils';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import UserHelper from 'general/helpers/UserHelper';
import AppLogo from '../AppLogo';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

AppSiteMenu.propTypes = {
    onPressSearch: PropTypes.func,
    onPressWatchlist: PropTypes.func,
    onPressNotification: PropTypes.func,
};

AppSiteMenu.defaultProps = {
    onPressSearch: null,
    onPressWatchlist: null,
    onPressNotification: null,
};

function AppSiteMenu(props) {
    // MARK: --- Params ---
    const { onPressSearch, onPressWatchlist, onPressNotification } = props;
    const loggedInUser = useSelector(state => state.auth.current);
    const { t } = useTranslation();
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const isSignedIn = UserHelper.checkAccessTokenValid() && !Utils.isObjectEmpty(loggedInUser);
    const totalUnreadNotifications = useSelector(state => state.notification.totalUnread);

    // MARK: --- Functions ---
    function handleSignOut() {
        Swal.fire({
            title: t('SignOut'),
            text: t('DoYouReallyWantToSignOut'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('Yes'),
            cancelButtonText: t('Cancel'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger font-weight-bolder',
                cancelButton: 'btn btn-light font-weight-bolder',
            }
        }).then(function (result) {
            console.log(result);
            if (result.value) {
                UserHelper.signOut();
                window.location.href = '/sign-in';

            }
        });
    }

    function handleAccountSetting() {
        history.push('/account');
    }

    function handleAccountInfo() {
        history.push('/account/info');
    }

    function handleAccountUpgrade() {
        history.push('/account/transaction');
    }

    function handleAccountMyList() {
        history.push('/account/my-list');
    }

    return (
        <div className='AppSiteMenu'>
            <div className='container-xxl py-3'>
                <div className='row'>
                    {/* Site menu */}
                    <div className='col d-flex align-items-center'>
                        {/* Logo */}
                        <AppLogo />

                        <div className='d-flex align-items-center flex-grow-1 justify-content-between ml-6'>
                            {/* Site menu */}
                            <ul className='nav d-flex align-items-center'>
                                {
                                    AppData.appSiteMenus.map((item, index) => {
                                        const isActive = locationPathname.includes(item.url);
                                        // console.log(isActive, item.url, locationPathname);
                                        return Utils.isObjectNull(item.subMenus) ? (
                                            <li
                                                className="nav-item"
                                                key={index}
                                            >
                                                <p
                                                    className={`cursor-pointer nav-link text-white text-hover-primary font-size-lg font-weight-bold ${isActive ? 'q-menu-active' : ''}`}
                                                    // href='#'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(item.url);
                                                    }}
                                                >
                                                    {t(item.text).toUpperCase()}
                                                </p>
                                            </li>
                                        ) : (
                                            <li
                                                className="nav-item"
                                                key={index}
                                            >
                                                <UncontrolledDropdown
                                                    className=''
                                                >
                                                    <DropdownToggle
                                                        caret={false}
                                                        tag='a'
                                                        className={`align-items-start nav-link text-white text-hover-primary font-size-lg font-weight-bold dropdown-toggle cursor-pointer ${locationPathname.includes(item.url) ? 'q-menu-active' : ''}`}
                                                    >
                                                        {t(item.text).toUpperCase()}
                                                        <i className={`fas fa-caret-down ml-2 text-white text-hover-primary ${locationPathname.includes(item.url) ? 'q-menu-active' : ''}`} style={{
                                                            paddingTop: '1px'
                                                        }} />
                                                        <DropdownMenu className='px-0 mt-4 py-0 rounded overflow-hidden shadow'>
                                                            {
                                                                item.subMenus.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div key={subIndex}>
                                                                            <DropdownItem
                                                                                key={subIndex}
                                                                                onClick={() => {
                                                                                    history.push(subItem.url);
                                                                                }}
                                                                            >
                                                                                <div className='d-flex align-items-center py-2 px-4'>
                                                                                    {
                                                                                        subItem.icon && (
                                                                                            <i className={`${subItem.icon} min-w-25px text-dark-75`}></i>
                                                                                        )
                                                                                    }
                                                                                    <span className='font-size-lg font-weight-bold'>{t(subItem.text)}</span>
                                                                                </div>
                                                                            </DropdownItem>
                                                                            {
                                                                                subIndex < item.subMenus.length - 1 && (
                                                                                    <DropdownItem divider className='m-0' />
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </DropdownMenu>
                                                    </DropdownToggle>
                                                </UncontrolledDropdown>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                    {/* button notification */}
                                    <div className='mr-1 pr-3 position-relative'>
                                        <button
                                            className="btn btn-icon hover-opacity-40 rounded-0"
                                            id='notification-menu-handle'
                                            onClick={() => {
                                                if (onPressNotification) {
                                                    onPressNotification();
                                                }
                                            }}
                                        >
                                            <i className="fal fa-bell text-white font-weight-bold"></i>
                                        </button>
                                        {
                                            totalUnreadNotifications > 0 && (
                                                <span className="label label-danger position-absolute" style={{ top: '-2px', right: '12px', pointerEvents: 'none' }}>{`${totalUnreadNotifications > 9 ? '9+' : totalUnreadNotifications}`}</span>
                                            )
                                        }
                                    </div>
                                    {/* button watch list */}
                                    <button
                                        className="btn btn-icon hover-opacity-40 rounded-0 mr-1 pr-3"
                                        id='watchlist-menu-handle'
                                        onClick={() => {
                                            if (onPressWatchlist) {
                                                onPressWatchlist();
                                            }
                                        }}
                                    >
                                        <i className="fal fa-list-alt text-white font-weight-bold"></i>
                                    </button>
                                    {/* Button search */}
                                    <button
                                        className="btn btn-icon hover-opacity-40 rounded-0 mr-3 pr-3"
                                        id='search-menu-handle'
                                        onClick={() => {
                                            if (onPressSearch) {
                                                onPressSearch();
                                            }
                                        }}
                                        style={{
                                            borderRightWidth: '1',
                                            borderRightColor: 'rgba(255, 255, 255, 0.2)'
                                        }}
                                    >
                                        <i className="fal fa-search text-white font-weight-bold"></i>
                                    </button>
                                </div>

                                <div className='d-flex align-items-center'>
                                    {/* Button Search */}
                                    {/* <button className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg">
                                <i className="far fa-search"></i>
                            </button> */}
                                    {
                                        !isSignedIn && (
                                            <UncontrolledDropdown
                                                className=''
                                            >
                                                <DropdownToggle
                                                    caret={false}
                                                    tag='a'
                                                    className="d-flex align-items-center cursor-pointer text-white text-hover-primary font-size-h6 font-weight-bold"
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        {/* <span className="font-size-lg">{t('Account')}</span> */}
                                                        <div className="btn btn-icon btn-icon-primary btn-circle align-items-center overflow-hidden ml-2">
                                                            <img alt="Avatar" src={AppImages.imgDefaultAvatar} className="w-100"
                                                                draggable={false} style={{ objectFit: 'cover' }} />
                                                            {/* <i className="fas fa-user fa-2x"></i> */}
                                                        </div>
                                                    </div>
                                                    <DropdownMenu className='px-4 mt-4' end>
                                                        <DropdownItem onClick={() => {
                                                            history.push('/sign-in');
                                                        }}>
                                                            <div className='d-flex align-items-center'>
                                                                <i className="fas fa-sign-in min-w-25px text-primary"></i>
                                                                <span className='font-size-lg'>{t('SignIn')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => {
                                                            history.push('/sign-up')
                                                        }}>
                                                            <div className='d-flex align-items-center'>
                                                                <i className="fas fa-user min-w-25px text-primary"></i>
                                                                <span className='font-size-lg'>{t('SignUp')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>
                                        )
                                    }

                                    {
                                        isSignedIn && (
                                            <UncontrolledDropdown
                                                className=''
                                            >
                                                <DropdownToggle
                                                    caret={false}
                                                    tag='a'
                                                    className="d-flex align-items-center cursor-pointer text-white text-hover-primary font-size-h6 font-weight-bold"
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        {/* <span className="text-right overflow-hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{UserHelper.getDisplayName(loggedInUser)}</span> */}
                                                        <div className="btn btn-icon btn-bg-white btn-icon-primary btn-circle align-items-center overflow-hidden ml-2">
                                                            <img
                                                                alt="Avatar" src={Utils.getFullUrl(UserHelper.getAvatar(loggedInUser))}
                                                                className="h-100 w-100"
                                                                draggable={false}
                                                                style={{ objectFit: 'cover' }}
                                                                onError={(e) => {
                                                                    e.target.src = UserHelper.getRandomAvatarUrl();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <DropdownMenu className='dropdown-menu-md mt-5 pb-0 overflow-hidden' end>
                                                        <div className='d-flex flex-column align-items-center py-4' onClick={() => {
                                                            history.push('/account');
                                                        }}>
                                                            <div className="btn btn-icon btn-bg-secondary btn-circle align-items-center overflow-hidden w-85px h-85px">
                                                                <img
                                                                    alt="Avatar"
                                                                    draggable={false}
                                                                    src={Utils.getFullUrl(UserHelper.getAvatar(loggedInUser))}
                                                                    className="h-100 w-100" style={{ objectFit: 'cover' }}
                                                                    onError={(e) => {
                                                                        e.target.src = UserHelper.getRandomAvatarUrl();
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className='mb-0 mt-4 font-size-h5 font-weight-bolder text-center px-6' style={{
                                                                wordBreak: 'break-word'
                                                            }}>{UserHelper.getDisplayName(loggedInUser)}</p>
                                                            <p className='mb-0 mt-2 text-primary font-weight-bold text-center'>{t('AccessLevel' + loggedInUser.accessLevel)}</p>
                                                        </div>
                                                        <DropdownItem divider className='m-0' />
                                                        <DropdownItem onClick={handleAccountInfo} className='d-flex justify-content-center py-5'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <i className="fas fa-sign-in min-w-25px text-dark-75"></i> */}
                                                                <span className='font-size-lg font-weight-bold'>{t('AccountInfo')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem divider className='m-0' />
                                                        <DropdownItem onClick={handleAccountUpgrade} className='d-flex justify-content-center py-5'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <i className="fas fa-shopping-cart min-w-25px text-dark-75"></i> */}
                                                                <span className='font-size-lg font-weight-bold'>{t('RenewalUpgrade')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem divider className='m-0' />
                                                        <DropdownItem onClick={handleAccountMyList} className='d-flex justify-content-center py-5'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <i className="fas fa-shopping-cart min-w-25px text-dark-75"></i> */}
                                                                <span className='font-size-lg font-weight-bold'>{t('MyLists')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem divider className='m-0' />
                                                        <DropdownItem onClick={handleAccountSetting} className='d-flex justify-content-center py-5'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <i className="fas fa-cog min-w-25px text-dark-75"></i> */}
                                                                <span className='font-size-lg font-weight-bold'>{t('Setting')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                        <DropdownItem divider className='m-0' />
                                                        <DropdownItem onClick={handleSignOut} className='d-flex justify-content-center py-5'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <i className="fas fa-sign-out min-w-25px text-dark-75"></i> */}
                                                                <span className='font-size-lg font-weight-bold'>{t('SignOut')}</span>
                                                            </div>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppSiteMenu;