import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'general/components/AppLayout';
import AppData from 'general/constants/AppData';
import './style.scss'
import LandingPageFrequentlyQuestion from 'features/Home/components/LandingPageFrequentlyQuestion';
import { Accordion, Card, Button } from 'react-bootstrap';
import CustomFrequentlyQuestionToggle from 'features/Home/components/LandingPageFrequentlyQuestion/CustomFrequentlyQuestionToggle';
import faqApi from 'api/faqApi';
import Global from 'general/Global';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';

FAQScreen.propTypes = {

};

const sTag = '[FAQScreen]';

function FAQScreen(props) {

    // MARK: --- Params ---
    const { t } = useTranslation();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        searchKey: '',
    });
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const [pagination, setPagination] = useState(null);

    // MARK: --- Functions ---
    async function getFaqList() {
        setLoading(true);
        const res = await faqApi.getFaqList(filter);
        if (res) {
            const { result, faqs, count, page, total } = res;
            if (result === 'success' && faqs) {
                setFaqs(faqs);
                setPagination({
                    total: total,
                    count: count,
                    currentPage: page
                });
            }
        }
        setLoading(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        }
    }, []);

    useEffect(() => {
        getFaqList();
    }, [filter]);

    // MARK: --- Functions ---

    return (
        <AppLayout>
            <div className='FrequentlyQuestionScreen min-h-600px '>
                <div className='FrequentlyQuestionScreen_Header py-12 py-lg-30'>{t('FrequentlyQuestion').toUpperCase()}</div>
                <div className='bg-white'>
                    <div className='container-xxl py-20'>
                        <p className='FrequentlyQuestionScreen_Title mb-0'>{t('FrequentlyQuestion')}</p>
                        <Accordion>
                            {
                                faqs.length > 0 && faqs.map((item, index) => {
                                    return (
                                        <Card className='rounded-0 border-right-0 border-left-0' key={index}>
                                            <Card.Header className='bg-transparent'>
                                                <CustomFrequentlyQuestionToggle eventKey={`${item.faqId}`}>
                                                    <p className='m-0 font-size-lg font-weight-bold text-left'>{`${t('Question')} ${index + 1}: ${item.question}`}</p>
                                                </CustomFrequentlyQuestionToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={`${item.faqId}`}>
                                                <Card.Body className='pl-5 pt-0'>
                                                    <p className='m-0 font-size-lg text-dark-75 pr-10'>{item.answer}</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )
                                })
                            }

                            {
                                faqs.length === 0 && (
                                    <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                        <EmptyView
                                            description={t('NoDataToDisplay')}
                                            iconEmpty={AppIcons.icEmptyPack}
                                        />
                                    </div>
                                )
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default FAQScreen;