import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

FirebaseListener.propTypes = {

};

const sTag = '[FirebaseListener]';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PushNotification from "features/Notification/components/PushNotification";
import { thunkGetAllTransactions } from "features/Account/accountSlice";
import AccountListener from "features/Account/AccountListener";
import DataCommonListener from "app/DataCommonListener";

import { getAuth, signInWithPopup, FacebookAuthProvider, signOut, GoogleAuthProvider } from "firebase/auth";
import PreferenceKeys from 'general/constants/PreferenceKeys';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAxirLc0nVpRsQvfJiqn3DCAudf3KBE-o0",
    authDomain: "moneygain-a54d2.firebaseapp.com",
    projectId: "moneygain-a54d2",
    storageBucket: "moneygain-a54d2.appspot.com",
    messagingSenderId: "728422852411",
    appId: "1:728422852411:web:f4bd9035dbea245356791e",
    measurementId: "G-N99RXC57Q8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);

// sign out firebase
export function signOutFirebase(onSuccess, onError) {
    signOut(auth).then(() => {
        // Sign-out successful.
        if (onSuccess) {
            onSuccess();
        }
    }).catch((error) => {
        // An error happened.
        if (onError) {
            onError(error);
        }
    });
}

export function startFirebaseSignInGoogle(onSuccess, onError) {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // ...
            localStorage.setItem(PreferenceKeys.googleAccessToken, accessToken);

            if (onSuccess) {
                onSuccess(user, credential, accessToken);
            }
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            if (onError) {
                onError(error);
            }
        });
}

export function startFirebaseSignInFB(onSuccess, onError) {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

            localStorage.setItem(PreferenceKeys.fbAccessToken, accessToken);

            if (onSuccess) {
                onSuccess(user, credential, accessToken);
            }

            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = FacebookAuthProvider.credentialFromError(error);

            // ...

            if (onError) {
                onError(error);
            }
        });
};

function FirebaseListener(props) {
    // MARK: --- Params ---
    const dispatch = useDispatch();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} did load`);

        return () => {
            console.log(`${sTag} will dismiss`);
        }
    }, []);

    return (<></>);
}

export default FirebaseListener;