import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import { useFormik } from 'formik';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import { AppIcons } from 'general/constants/AppResource';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import authApi from "../../../../api/authApi";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import UserHelper from "../../../../general/helpers/UserHelper";
import Utils from "../../../../general/utils/Utils";

AccountSecurityScreen.propTypes = {};

function AccountSecurityScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const loggedInUser = useSelector(state => state.auth.current);
    const isFBAccount = loggedInUser?.accountType === 'FACEBOOK';
    const isGoogleAccount = loggedInUser?.accountType === 'GOOGLE';
    const isMGAccount = loggedInUser?.accountType === 'MG';
    const isHasPassword = !_.isEmpty(loggedInUser?.password);

    const formik = useFormik({
        initialValues: {
            password: '',
            newPassword: '',
            reNewPassword: '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            requestUpdateAccountPassword(values);
        },
        validationSchema: isHasPassword ? Yup.object({
            password: Yup.string().required(t('PasswordIsRequired')),
            newPassword: Yup.string().required(t('NewPasswordIsRequired')).min(6, t('Short_Password')),
            reNewPassword: Yup.string().required(t('ReNewPasswordIsRequired')).oneOf([Yup.ref('newPassword')], t('NewPasswordNotMatch')),
        }) : Yup.object({
            newPassword: Yup.string().required(t('NewPasswordIsRequired')).min(6, t('Short_Password')),
            reNewPassword: Yup.string().required(t('ReNewPasswordIsRequired')).oneOf([Yup.ref('newPassword')], t('NewPasswordNotMatch')),
        }),
    });
    const { isUpdatingInfo } = useSelector(state => state.account);
    const dispatch = useDispatch();
    const history = useHistory();

    // MARK: --- Functions ---
    async function requestUpdateAccountPassword(values) {
        let params = {
            oldPassword: Utils.sha256(values?.password),
            newPassword: Utils.sha256(values?.newPassword)
        }
        if (!isHasPassword) {
            delete params.oldPassword;
        }
        try {
            let changePasswordResult = await authApi.changePassword(params)
            let { result, description } = changePasswordResult
            if (result == "success") {
                UserHelper.signOut();
                ToastHelper.showSuccess(t('ChangePasswordSuccess'));
                history.replace('/sign-in');
            } else {
                ToastHelper.showError(description ?? t('ChangePasswordFailed'));
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className='d-flex flex-column align-items-center'>
                    <img
                        className='mb-6'
                        src={AppIcons.icAccountSecurity}
                        alt='icon'
                        draggable={false}
                    />
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{t('AccountSecurityTitle').toUpperCase()}</p>
                    <p className='font-size-lg text-center m-0'>{t('AccountSecurityDescription')}</p>
                </div>

                <div className='row mt-10'>
                    <div className='col-3'></div>
                    <div className='col'>
                        <form onSubmit={formik.handleSubmit}>
                            {/* Password */}
                            {
                                isHasPassword && (
                                    <div className='form-group'>
                                        <BaseTextField
                                            name='password'
                                            type='password'
                                            placeholder={`${t('Password')}...`}
                                            label={t('Password')}
                                            fieldHelper={formik.getFieldHelpers('password')}
                                            fieldProps={formik.getFieldProps('password')}
                                            fieldMeta={formik.getFieldMeta('password')}
                                        />
                                    </div>
                                )
                            }
                            {/* New Password */}
                            <div className='form-group'>
                                <BaseTextField
                                    name='newPassword'
                                    type='password'
                                    placeholder={`${t('NewPassword')}...`}
                                    label={t('NewPassword')}
                                    fieldHelper={formik.getFieldHelpers('newPassword')}
                                    fieldProps={formik.getFieldProps('newPassword')}
                                    fieldMeta={formik.getFieldMeta('newPassword')}
                                />
                            </div>
                            {/* Re New Password */}
                            <div className='form-group'>
                                <BaseTextField
                                    name='reNewPassword'
                                    type='password'
                                    placeholder={`${t('ReNewPassword')}...`}
                                    label={t('ReNewPassword')}
                                    fieldHelper={formik.getFieldHelpers('reNewPassword')}
                                    fieldProps={formik.getFieldProps('reNewPassword')}
                                    fieldMeta={formik.getFieldMeta('reNewPassword')}
                                />
                            </div>
                            {/* Button save */}
                            <div className='d-flex justify-content-center'>
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.goBack();
                                    }}
                                    className="btn btn-secondary btn-lg border-0 font-weight-bolder hover-opacity-80 mr-4"
                                >
                                    {t('Cancel')}
                                </a>
                                <button
                                    type='submit'
                                    className="btn btn-primary btn-lg border-0 font-weight-bolder"
                                >
                                    {t('ChangePassword')}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className='col-3'></div>
                </div>
            </div>
        </AccountBaseLayout>
    );
}

export default AccountSecurityScreen;
