import axiosClient from "./axiosClient";

const filterApi = {
    // Lấy danh sách bộ lọc
    getAll: () => {
        const url = `/filter/all`;
        return axiosClient.get(url);
    },
    // xem chi tiết 1 bộ lọc
    getDetail: (params) => {
        const url = `/filter/${params?.filterId}`;
        return axiosClient.get(url);
    },
    //tạo bộ lọc mới
    createFilter: (params) => {
        const url = '/filter/create';
        return axiosClient.post(url, params);
    },
    // đổi tên 1 bộ lọc
    renameFilter: (params) => {
        const url = `/filter/${params?.filterId}`;
        return axiosClient.put(url, params);
    },
    // Xoá 1 bộ lọc
    deleteFilter: (params) => {
        const url = '/filter/delete';
        return axiosClient.post(url, params);
    },
    // Chỉnh sửa điều kiện lọc của một bộ lọc
    editConditional: (params) => {
        const url = `/filter/edit_conditional/${params?.filterId}`;
        return axiosClient.put(url, params);
    },
    // Lấy kết quả từ 1 bộ lọc
    getResult: (params) => {
        const url = `/filter/result/${params?.filterId}`;
        return axiosClient.get(url);
    },
    // Lấy số lượng cổ phiếu thoả mãn 1 điều kiện lọc
    countNumberSatisfied: (params) => {
        const url = `/filter/satisfied`;
        return axiosClient.post(url,params);
    },
    // Lấy tất cả điều kiện lọc
    getAllConditionals: ()=> {
        const url = `/filter/all_conditional`;
        return axiosClient.get(url);
    },
    // Lấy kết quả từ danh sách điều kiện lọc
    getResultByConditionals: (params) => {
        const url = `/filter/result`;
        return axiosClient.post(url,params);
    },
    //lấy danh sách bộ lọc mg
    getMGFilter: (params)=> {
        const url = `/filter/all/mg`;
        return axiosClient.get(url,{params});
    }
};

export default filterApi;
