import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { AppIcons, AppImages } from "general/constants/AppResource";
import { useTranslation } from "react-i18next";
import Utils from "general/utils/Utils";
import { FacebookShareButton } from "react-share";
import AppConfigs from "general/constants/AppConfigs";

CellVideoList.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
};

CellVideoList.defaultProps = {
  item: {},
  onPress: null,
};

function CellVideoList(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { item, onPress } = props;
  const { name, description, url, category, thumbnail, updatedAt } = item;
  const refDescription = useRef(null);
  const refButtonMoreLess = useRef(null);

  // MARK: --- Functions ---
  function handlePressMoreLess(e) {
    e.preventDefault();
    if (refDescription.current) {
      if (refDescription.current.classList.contains('q-max-line-3')) {
        refDescription.current.classList.remove('q-max-line-3');
        if (refButtonMoreLess.current) {
          refButtonMoreLess.current.innerHTML = t('Less');
        }
      } else {
        refDescription.current.classList.add('q-max-line-3');
        if (refButtonMoreLess.current) {
          refButtonMoreLess.current.innerHTML = t('More');
        }
      }
    }
  }

  function handlePress(e) {
    e.preventDefault();
    if (onPress) {
      onPress();
    }
  }

  return (
    <div className="CellVideoList">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="position-relative CellVideoList_Image">
            <img
              className="bg-light w-100 min-w-100 border rounded"
              draggable={false}
              src={Utils.getFullUrl(thumbnail) ?? AppImages.imgDefaultThumbnailVideo}
              style={{
                objectFit: 'cover',
                aspectRatio: '16/9'
              }}
              alt='thumbnail'
              onError={(e) => {
                e.target.src = AppImages.imgDefaultThumbnailVideo;
              }}
            />
            {/* share button */}
            <FacebookShareButton
              url={url}
              quote={""}
              title={name}
              hashtag={AppConfigs.hashtag}
              description={description}
              className='position-absolute w-40px h-40px btn text-white font-weight-bold d-flex align-items-center justify-content-center hover-opacity-60 CellVideoList_ButtonShare'
              style={{
                top: '10px',
                right: '10px'
              }}
            >
              <i className="fas fa-share m-0 p-0 text-white"></i>
            </FacebookShareButton>
            {/* <a
              href="#"
              className="position-absolute btn btn-dark CellVideoList_ButtonShare"
              style={{
                top: '10px',
                right: '10px'
              }}
              onClick={(e) => {
                e.preventDefault();
                alert(url);
              }}
            >
              <i className="fas fa-share m-0 p-0"></i>
            </a> */}
          </div>

        </div>
        <div className="col-12 col-md-6 mt-6 mt-md-0">
          <div>
            <a href="#" onClick={handlePress} className='font-size-h6 font-weight-bolder m-0 q-max-line-2 text-dark-75 text-hover-primary' style={{ lineHeight: '120%' }}>{name}</a>
            <p className='font-weight-bold my-2'>{`${t('Category')}: `}<span className='font-size-lg font-weight-bolder text-dark-75'>{category && category.name}</span></p>
            <p className='font-size-lg m-0 text-muted q-max-line-2 mb-2'>{Utils.formatDateTime(updatedAt, 'DD/MM/YYYY HH:mm')}</p>
            <p className='font-size-lg m-0 text-justify q-max-line-3 mb-2 text-dark-50' ref={refDescription}>
              {description}
            </p>
            <a
              className="font-weight-bold"
              href="#"
              onClick={handlePressMoreLess}
              ref={refButtonMoreLess}
            >
              {t('More')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CellVideoList;
