import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import HomeLandingPageScreen from './screens/LandingPage';
import NotFound from 'general/components/NotFound';
import HomeMainScreen from './screens/Main';

Home.propTypes = {

};

function Home(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                component={HomeLandingPageScreen}
            />
            <Route
                path={`${match.url}/main`}
                component={HomeMainScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Home;
