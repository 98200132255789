import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppImages } from "general/constants/AppResource";
import "./style.scss";
import BaseTextField from "general/components/Forms/FormControls/BaseTextField";
import BaseTextArea from "general/components/Forms/FormControls/BaseTextArea";
import ModalJoinWebinarSuccess from "../ModalJoinWebinarSuccess";
import { useSelector } from "react-redux";
import ModalJoinWebinarFail from "../ModalJoinWebinarFail";
import Utils from "general/utils/Utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import webinarApi from "api/webinarApi";
import ToastHelper from "general/helpers/ToastHelper";
import { useHistory } from "react-router-dom";

ModalRegisterJoinWebinar.propTypes = {
  webinarItem: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalRegisterJoinWebinar.defaultProps = {
  show: false,
  onClose: null,
};

function ModalRegisterJoinWebinar(props) {
  // MARK: --- Params ---
  const { webinarItem, show, onClose } = props;
  const { t } = useTranslation();
  const loggedInUser = useSelector((state) => state.auth.current);
  const [modalJoinWebinarSuccess, setModalJoinWebinarSuccess] =
    React.useState(false);
  const [modalJoinWebinarFail, setModalJoinWebinarFail] = React.useState(false);
  const [failedReason, setFailedReason] = useState('');
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: loggedInUser ? loggedInUser.fullname : '',
      email: loggedInUser ? loggedInUser.email : '',
      phone: loggedInUser ? loggedInUser.phone : '',
      question: ''
    },
    onSubmit: (values) => {
      if (webinarItem) {
        if (webinarItem.price && webinarItem.price > 0) {
          // chuyen sang man hinh thanh toan
          history.push({
            pathname: "/payment/purchase",
            state: {
              productId: webinarItem?.webinarId,
              name: webinarItem?.name,
              price: webinarItem?.price,
              currency: null,
              type: 'WEBINAR',
              description: webinarItem?.description,
            },
          });
        } else {
          // hoi thao free thi join luon
          handleJoinWebinar(webinarItem.webinarId, values.name, values.email, values.phone, values.question);
        }
      }
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().required(t('EmailIsRequired')).email(t('EmailNotValid')).nullable(),
      name: Yup.string().required(t('Required')).nullable(),
      phone: Yup.string().required(t('Required')).nullable(),
    }),
  });

  // console.log({ webinarItem });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  async function handleJoinWebinar(webinarId, name, email, phone, question) {
    try {
      let joinInWebinarResult = await webinarApi.joinInWebinar(webinarId, question)
      let { result, detail, description } = joinInWebinarResult
      console.log({ joinInWebinarResult });
      if (result === "success") {
        // ToastHelper.showSuccess(t("join_in_webinar_success"));
        // history.push(`webinar/${webinarId}`)
        setModalJoinWebinarSuccess(true);
      } else {
        // ToastHelper.showError(description ?? '');
        setFailedReason(description);
        setModalJoinWebinarFail(true);
      }
    } catch (e) {
      ToastHelper.showError(t("buy_webinar_notice"));
    }
    handleClose();
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        onHide={() => {
          handleClose();
        }}
        centered
        size="lg"
        scrollable="true"
      >
        <Modal.Header>
          <Modal.Title>{t("RegisterJoinWebinar")}</Modal.Title>
          <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light">
          <div
            className="d-flex justify-content-center w-100 modalRegisterWebinarBackground rounded"

          >
            <div
              className=" h-100 w-100 text-center text-uppercase  mx-5 pd-0 py-20"
            >
              <p
                className="font-weight-boldest font-size-h2"
                style={{ color: "#F4B638" }}
              >
                {webinarItem.name}
              </p>
            </div>
          </div>
          <div className="mt-3 container p-0">
            <p className="font-weight-boldest purple font-size-h3 line-height-lg ">
              {webinarItem.price === 0 ? t('Free') : `${Utils.formatNumber(webinarItem.price)} VNĐ`}
            </p>
            <div className="border-top border-bottom border-light py-3">
              <p className="font-weight-normal font-size-base line-height-md darkBlue">
                {`${t("StartTime")}: `}
                <span className="purple font-weight-bold">
                  {Utils.formatDateTime(
                    webinarItem.startTime,
                    "DD/MM/YYYY hh:mm A"
                  )}
                </span>{" "}
                | {`${t("TypeOfSeminar")}: `}
                <span className="purple font-weight-bold">
                  {webinarItem.online === 0 ? "Offline" : "Online"}
                </span>{" "}
                | {`${t("Location")} : `}
                <span className="purple font-weight-bold">{webinarItem.location}</span>
              </p>
            </div>
            <div className="d-flex justify-content-between row mt-4">
              <div className="col-md-4">
                <BaseTextField
                  name="name"
                  placeholder={`${t("Fullname")}...`}
                  label={t("Fullname")}
                  // disabled
                  fieldHelper={formik.getFieldHelpers('name')}
                  fieldProps={formik.getFieldProps('name')}
                  fieldMeta={formik.getFieldMeta('name')}
                />
              </div>
              <div className="col-md-4">
                <BaseTextField
                  name="email"
                  placeholder={`${t("Email")}...`}
                  label="Email"
                  // disabled
                  fieldHelper={formik.getFieldHelpers('email')}
                  fieldProps={formik.getFieldProps('email')}
                  fieldMeta={formik.getFieldMeta('email')}

                />
              </div>
              <div className="col-md-4">
                <BaseTextField
                  name="phone"
                  // disabled
                  placeholder={`${t("PhoneNumber")}...`}
                  label={t("PhoneNumber")}
                  fieldHelper={formik.getFieldHelpers('phone')}
                  fieldProps={formik.getFieldProps('phone')}
                  fieldMeta={formik.getFieldMeta('phone')}
                />
              </div>
            </div>
            <div>
              <BaseTextArea
                name="question"
                placeholder={`${t("EnterQuestions")}...`}
                label={t("QuestionsAboutSeminar")}
              />
            </div>
            <p className="font-weight-bolder font-size-base line-height-lg">
              {t("SeminarGreetings")}
            </p>
            <p className="font-weight-bolder font-size-base line-height-lg">
              {t("BestRegards")}
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <div
            className=" ml-3 h-40px rounded bg-light  mt-5 text-center buttonCancel border  d-flex align-items-center justify-content-center"
            style={{
              width: "30%",
            }}
            onClick={handleClose}
          >
            <p className="font-weight-bold font-size-lg line-height-md  darkBlue m-0">
              {t("Cancel")}
            </p>
          </div>

          <div
            className=" ml-3 h-40px  bg-light rounded  mt-5 buttonConfirm text-center border d-flex align-items-center justify-content-center"
            style={{
              width: "30%",
            }}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            <p
              className="font-weight-bold font-size-lg line-height-md buyNowText m-0"
              style={{ color: "white" }}
            >
              {t("RegisterNow")}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalJoinWebinarSuccess
        show={modalJoinWebinarSuccess}
        onClose={() => setModalJoinWebinarSuccess(false)}
        webinarItem={webinarItem}
      />
      <ModalJoinWebinarFail
        show={modalJoinWebinarFail}
        onClose={() => setModalJoinWebinarFail(false)}
        webinarItem={webinarItem}
        reason={failedReason}
      />
    </div>
  );
}

export default ModalRegisterJoinWebinar;
