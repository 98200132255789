import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactPlayer from 'react-player';
import Utils from 'general/utils/Utils';

ModalVideoPlayer.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalVideoPlayer.defaultProps = {
    title: '',
    url: '',
    isOpen: false,
    onClose: null,
};

function ModalVideoPlayer(props) {
    // MARK: --- Params ---
    const { title, url, isOpen, onClose } = props;
    const { t } = useTranslation();
    const playerRef = useRef(null);

    // MARK: --- Hooks ---

    return (
        <Modal
            isOpen={isOpen}
            size='xl'
            centered
            className='rounded overflow-hidden'
        >
            <ModalHeader close={<button className="close" onClick={() => {
                if (onClose) {
                    onClose();
                }
            }}><i className='fal fa-times fa-1x' /></button>}>
                {title}
            </ModalHeader>
            <ModalBody className='p-0 overflow-hidden rounded-bottom' style={{ backgroundColor: 'black', aspectRatio: '1.78' }}>
                <ReactPlayer
                    className='w-100 h-100'
                    url={Utils.getFullUrl(url)}
                    ref={playerRef}
                    playing
                    controls
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                />
            </ModalBody>
        </Modal>
    );
}

export default ModalVideoPlayer;