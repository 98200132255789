import industryApi from 'api/industryApi';
import { useFormik } from 'formik';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import DatePickerInputMask from 'general/components/Forms/FormWidgets/DatePickerInputMask';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CandleChart from '../summary/components/CandleChart';

ModalSectorChart.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    sectorItem: PropTypes.object,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    onChangeDate: PropTypes.func,
};

ModalSectorChart.defaultProps = {
    show: false,
    onClose: null,
    sectorItem: {},
    endDate: '',
    startDate: '',
    onChangeDate: null,
};

// const stockCandleData = [

//     {
//         time: '2012-10-08',
//         open: 1.1795,
//         high: 1.2242,
//         low: 1.1795,
//         close: 1.2205
//     },

//     {
//         time: '2012-10-09',
//         open: 1.2019,
//         high: 1.2186,
//         low: 1.1963,
//         close: 1.1981
//     },

//     {
//         time: '2012-10-10',
//         open: 1.2019,
//         high: 1.2074,
//         low: 1.1627,
//         close: 1.1981
//     },

//     {
//         time: '2012-10-11',
//         open: 1.2298,
//         high: 1.241,
//         low: 1.2019,
//         close: 1.2093
//     },

//     {
//         time: '2012-10-12',
//         open: 1.1851,
//         high: 1.2186,
//         low: 1.1795,
//         close: 1.2093
//     },

//     {
//         time: '2012-10-15',
//         open: 1.1963,
//         high: 1.2074,
//         low: 1.1795,
//         close: 1.1813
//     },

//     {
//         time: '2012-10-16',
//         open: 1.1739,
//         high: 1.2242,
//         low: 1.1739,
//         close: 1.2261
//     },

//     {
//         time: '2012-10-17',
//         open: 1.2801,
//         high: 1.2801,
//         low: 1.2745,
//         close: 1.2821
//     },

//     {
//         time: '2012-10-18',
//         open: 1.3192,
//         high: 1.3192,
//         low: 1.2857,
//         close: 1.3157
//     },

//     {
//         time: '2012-10-19',
//         open: 1.3137,
//         high: 1.3137,
//         low: 1.2857,
//         close: 1.2933
//     },
// ];

// const stockVolumeData = [
//     {
//         time: '2012-10-08',
//         value: 78870,
//         color: 'rgba(88, 189, 125, 0.3)'
//     },

//     {
//         time: '2012-10-09',
//         value: 101660,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },

//     {
//         time: '2012-10-10',
//         value: 17610,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },

//     {
//         time: '2012-10-11',
//         value: 72320,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },

//     {
//         time: '2012-10-12',
//         value: 47720,
//         color: 'rgba(88, 189, 125, 0.3)'
//     },

//     {
//         time: '2012-10-15',
//         value: 40950,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },

//     {
//         time: '2012-10-16',
//         value: 63440,
//         color: 'rgba(88, 189, 125, 0.3)'
//     },

//     {
//         time: '2012-10-17',
//         value: 332070,
//         color: 'rgba(88, 189, 125, 0.3)'
//     },

//     {
//         time: '2012-10-18',
//         value: 172980,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },

//     {
//         time: '2012-10-19',
//         value: 112880,
//         color: 'rgba(255, 104, 56, 0.35)'
//     },
// ];

function ModalSectorChart(props) {
    // MARK: --- Params ---
    const { show, onClose, sectorItem, endDate, startDate, onChangeDate } = props;
    const [specialData, setSpecialData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingChartData, setLoadingChartData] = useState(false);
    const [currentRange, setCurrentRange] = useState('1D');
    const [intervalType, setIntervalType] = useState(4)
    const { t } = useTranslation();
    const [chartData, setChartData] = useState(null);

    // const intervalList = [
    //     {
    //         Id: 0,
    //         value: "1H",
    //         title: "1H"
    //     },
    //     {
    //         Id: 1,
    //         value: "2H",
    //         title: "2H"
    //     },
    //     {
    //         Id: 2,
    //         value: "3H",
    //         title: "3H"
    //     },
    //     {
    //         Id: 3,
    //         value: "4H",
    //         title: "4H"
    //     },
    //     {
    //         Id: 4,
    //         value: null,
    //         title: "1D"
    //     },
    //     {
    //         Id: 5,
    //         value: null,
    //         title: "1W"
    //     },
    //     {
    //         Id: 6,
    //         value: null,
    //         title: "1M"
    //     }
    // ];

    let volumePerChange = '0';
    let pricePerChange = '0';
    if (sectorItem) {
        const { totalVolume, totalPrice } = sectorItem;
        const yesterdayTotalVolume = sectorItem?.yesterday?.totalVolume;
        const yesterdayTotalPrice = sectorItem?.yesterday?.totalPrice;
        if (totalVolume && yesterdayTotalVolume) {
            volumePerChange = ((totalVolume - yesterdayTotalVolume) * 100 / yesterdayTotalVolume).toFixed(2);
        }
        if (totalPrice && yesterdayTotalPrice) {
            pricePerChange = ((totalPrice - yesterdayTotalPrice) * 100 / yesterdayTotalPrice).toFixed(2);
        }
    }

    const formik = useFormik({
        initialValues: {
            endDate: moment().format("DD/MM/YYYY"),
            startDate: moment().subtract(10, 'years').format("DD/MM/YYYY")
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const startDate = moment(values.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            const endDate = moment(values.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            requestSectorChartData(sectorItem?.industryId, currentRange, startDate, endDate);
        }
    });

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function requestSectorData(sectorId) {
        setLoading(true);
        const res = await industryApi.getIndustrySpecial(sectorId);
        const { result, data } = res;
        if (result === 'success' && data) {
            setSpecialData(data);
        }
        setLoading(false);
    }

    async function requestSectorChartData(sectorId, interval, startDate, endDate) {
        setLoadingChartData(true)
        setChartData(null);

        const res = await industryApi.getIndustryChartData(sectorId, interval, startDate, endDate);
        const { result, data } = res;
        if (result === 'success' && data) {
            console.log({ data });
            const candle = [];
            const volume = [];
            data.map(item => {
                const date = item.date;
                candle.push({
                    time: date,
                    open: item.openingPrice,
                    high: item.highestPrice,
                    low: item.lowestPrice,
                    close: item.closingPrice,
                });
                volume.push({
                    time: date,
                    value: _.toNumber(item.totalTradingVolume),
                    color: (item?.closingPrice < item?.openingPrice)
                        ? "rgba(255, 104, 56, 0.35)"
                        : "rgba(88, 189, 125, 0.3)",
                });
            });
            setChartData({
                candleData: candle,
                volumeData: volume,
            })
        }
        setLoadingChartData(false);
    }

    // const handleChangeDateRange = (code) => {
    //     if (code != intervalType) {
    //         if (onChangeIntervalType) {
    //             onChangeIntervalType(code);
    //             setCurrentRange(intervalList[code].title)
    //         }
    //     }
    // }

    // function onChangeIntervalType(intervalType) {
    //     setStockChartIntervalType(intervalType);
    //     if (intervalType < 4) {
    //         getStockRealtime(stockCode, stockChartStartDate, stockChartEndDate, intervalType);
    //     } else {
    //         getStockHistory(stockCode, stockChartStartDate, stockChartEndDate, intervalType);
    //     }
    // }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (sectorItem) {
            requestSectorData(sectorItem?.industryId);
            const startDate = moment(formik.values.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            const endDate = moment(formik.values.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            requestSectorChartData(sectorItem?.industryId, currentRange, startDate, endDate);
        }
    }, [sectorItem]);

    return (
        <div>
            <Modal
                size='xl'
                scrollable={true}
                // backdrop='static'
                className=''
                show={show}
                centered={true}
                onHide={() => {
                    handleClose();
                    setSpecialData(null);
                    formik.handleReset();
                    setCandleData([]);
                    setVolumeData([]);
                }}
            >
                <Modal.Header>
                    <div className='w-100'>
                        <div>
                            <div className='d-flex flex-row align-items-start justify-content-between'>
                                <div>
                                    <p className='m-0 text-dark-75' style={{
                                        fontSize: '1.6rem',
                                        fontWeight: '600',
                                        color: '#23262F'
                                    }}>Ngành: {sectorItem?.name}</p>
                                    <div className='d-flex align-items-center'>
                                        <p className='m-0 mr-4 text-dark-75' style={{
                                            fontSize: '1.8rem',
                                            fontWeight: '700',
                                        }}>KL: {Utils.formatNumber(sectorItem?.totalVolume)}</p>
                                        <p className='m-0' style={{
                                            fontSize: '1.2rem',
                                            fontWeight: '700',
                                            color: Utils.getValueColor(pricePerChange)
                                        }}>
                                            {`${Utils.formatNumber((sectorItem?.totalPrice / 1000000000).toFixed(2))} / ${pricePerChange > 0 ? '+' : ''}${Utils.formatNumber(pricePerChange)}%`}
                                        </p>
                                        {
                                            loading && (
                                                <div className='spinner ml-4'></div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div
                                    className="btn btn-sm btn-icon btn-light btn-hover-secondary cursor-pointer"
                                    onClick={handleClose}
                                >
                                    <i className="far fa-times"></i>
                                </div>
                            </div>
                            <div className='row bg-light px-1 py-2 mt-2 rounded'>
                                <div className='col-3'>P/E: <span className='font-weight-bolder'>{specialData?.pe ? specialData?.pe.toFixed(4) : ''}</span></div>
                                <div className='col-3'>P/B: <span className='font-weight-bolder'>{specialData?.pb ? specialData?.pb.toFixed(4) : ''}</span></div>
                                <div className='col'>Vốn hóa: <span className='font-weight-bolder'>{`${Utils.formatNumber((specialData?.vonhoa / 1000000000).toFixed(2))} tỷ`}</span></div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {/* bieu do */}
                    <div className={'col-12 align-self-start'}>
                        <AppCardDataInfo
                            title={(
                                <div>
                                    {t('Chart')}
                                </div>
                            )}
                            rightComponent={(
                                <form onSubmit={formik.handleSubmit}>
                                    <div style={{
                                        alignContent: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "39px"
                                    }}>
                                        <div className={"col-xs-12"} style={{
                                            paddingBottom: 0,
                                            marginBottom: 0
                                        }}>

                                            <DatePickerInputMask
                                                name="startDate"
                                                fieldHelper={formik.getFieldHelpers('startDate')}
                                                fieldProps={formik.getFieldProps('startDate')}
                                                fieldMeta={formik.getFieldMeta('startDate')}
                                            />
                                        </div>
                                        <span style={{
                                            paddingLeft: "5px",
                                            paddingRight: "5px",
                                            lineHeight: "39px",
                                            verticalAlign: "middle"
                                        }}>{"–"}</span>
                                        <div className={"col-xs-12"}>
                                            <DatePickerInputMask
                                                name="endDate"
                                                fieldHelper={formik.getFieldHelpers('endDate')}
                                                fieldProps={formik.getFieldProps('endDate')}
                                                fieldMeta={formik.getFieldMeta('endDate')}
                                            />
                                        </div>
                                        <div style={{ height: "36px" }}>
                                            <button
                                                type='submit'
                                                className="custom-filter-button btn-primary btn-sm border-0 font-weight-bolder font-size-base hover-opacity-80 px-6"
                                            >
                                                {t('Filter')}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )}
                            content={(
                                (chartData === null) ?
                                    (
                                        <div
                                            className='h-650px w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                                loading={loadingChartData}
                                            />
                                        </div>
                                    )
                                    :
                                    <div className="h-650px">
                                        <CandleChart candleData={chartData.candleData} volumeData={chartData.volumeData} />
                                    </div>
                            )}
                        // bottomComponent={(
                        //     <div>
                        //         <Dropdown alignRight>
                        //             <Dropdown.Toggle variant="" className="font-weight-bolder border">
                        //                 {currentRange}
                        //                 <i className="fas fa-caret-down text-dark ml-2"></i>
                        //             </Dropdown.Toggle>
                        //             <Dropdown.Menu>
                        //                 {intervalList.map((item, index) => {
                        //                     return (
                        //                         <Dropdown.Item className="w-100 font-weight-bolder d-flex flex-row justify-content-between" key={index} onClick={() => handleChangeDateRange(item?.Id)}>
                        //                             {item?.title}
                        //                             {currentRange === item.title && <i className="fas fa-check text-success"></i>}
                        //                         </Dropdown.Item>
                        //                     )
                        //                 })}
                        //             </Dropdown.Menu>
                        //         </Dropdown>
                        //     </div>
                        // )}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalSectorChart;