import ModalAllEvents from 'features/FinancialEvent/components/ModalAllEvents';
import ModalEventInfo from 'features/FinancialEvent/components/ModalEventInfo';
import MGDataTable from 'general/components/Tables/MGDataTable';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import Utils from 'general/utils/Utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ModalBusinessReport from '../ModalBusinessReport';

HomeSectionEvent.propTypes = {

};

function HomeSectionEvent(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const { recentEvents, topProfitCompanies } = useSelector(state => state.app);
    const [modalEventShowing, setModalEventShowing] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [modalAllEventShowing, setModalAllEventShowing] = useState(false);
    const [modalBusinessReportShowing, setModalBusinessReportShowing] = useState(false);

    // MARK: --- Functions ---
    function handlePressStock(stock) {
        history.push('/stock/1');
    }

    return (
        <div className='bg-light'>
            <div className='container-xxl py-20'>
                <div className='row'>
                    <div className='col-12 col-lg-6'>
                        <SectionHeaderView
                            title='Báo Cáo Kết Quả Kinh Doanh'
                            buttonTitle={t('ViewAll')}
                            dividerVisible={false}
                            onPressButton={() => {
                                setModalBusinessReportShowing(true);
                            }}
                        />
                        <MGDataTable
                            className='bg-white mt-2'
                            headItems={[
                                { title: t('Stock'), subTitle: t('CompanyName'), col: 'col-4' },
                                { title: t('Profit'), subTitle: 'x1000 VND', col: 'col-2' },
                                { title: t('Revenue'), subTitle: 'x1000 VND', col: 'col-2' },
                                { title: t('Term'), subTitle: '', col: 'col-2' },
                                { title: t('Time'), subTitle: '', col: 'col-2' },
                            ]}
                            dataItems={
                                topProfitCompanies.map((item, index) => {
                                    const latestProfit = item?.lastestProfit ?? [];
                                    const latestRevenue = item?.lastestRevenue ?? [];
                                    // console.log({ latestProfit, latestRevenue });
                                    let currentProfit = latestProfit.length > 0 ? latestProfit[0] : 0;
                                    let prevProfit = latestProfit.length > 1 ? latestProfit[1] : 0;
                                    let currentRevenue = latestRevenue.length > 0 ? latestRevenue[0] : 0;
                                    let prevRevenue = latestRevenue.length > 1 ? latestRevenue[1] : 0;
                                    const profitColor = currentProfit > prevProfit ? '#0BDF39' : '#FF0017';
                                    const revenueColor = currentRevenue > prevRevenue ? '#0BDF39' : '#FF0017';

                                    const profitPercent = prevProfit > 0 ? parseFloat((((currentProfit - prevProfit) * 100 / prevProfit))).toFixed(2) : '---';
                                    const revenuePercent = prevRevenue > 0 ? parseFloat((((currentRevenue - prevRevenue) * 100 / prevRevenue))).toFixed(2) : '---';

                                    return (
                                        {
                                            onPress: () => { },
                                            data: [
                                                { icon: '', title: item?.stockCode, titleWeight: '600', subTitle: item?.companyName, name: '', value: '', valueColor: '', col: 'col-4' },
                                                { icon: '', title: '', name: Utils.formatNumber((currentProfit / 1000).toFixed(2)), nameColor: '', value: `${profitPercent > 0 ? '+' : ''}${Utils.formatNumber(profitPercent)}%`, valueColor: profitColor, col: 'col-2' },
                                                { icon: '', title: '', name: Utils.formatNumber((currentRevenue / 1000).toFixed(2)), nameColor: '', value: `${revenuePercent > 0 ? '+' : ''}${Utils.formatNumber(revenuePercent)}%`, valueColor: revenueColor, col: 'col-2' },
                                                { icon: '', title: '', name: `${item?.quarter?.termCode}/${item?.yearPeriod}`, nameColor: '', value: '', valueColor: '', col: 'col-2' },
                                                { icon: '', title: '', name: Utils.formatDateTime(item?.reportDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-2' }
                                            ]
                                        }
                                    )
                                })
                            }
                        />
                    </div>

                    {/* financial events */}
                    <div className='col-12 col-lg-6 mt-20 mt-lg-0'>
                        <SectionHeaderView
                            title={t('IncomingFinancialEvents')}
                            buttonTitle={t('ViewAll')}
                            dividerVisible={false}
                            onPressButton={() => {
                                setModalAllEventShowing(true);
                            }}
                        />
                        <MGDataTable
                            className='bg-white mt-2'
                            headItems={[
                                { title: t('Event'), subTitle: '', col: 'col-5' },
                                { title: t('Location'), subTitle: '', col: 'col-4' },
                                { title: t('Date'), subTitle: '', col: 'col-3' },
                            ]}
                            dataItems={
                                recentEvents.map((item, index) => {
                                    return {
                                        onPress: () => {
                                            setModalEventShowing(true);
                                            setSelectedEvent(item);
                                        },
                                        data: [
                                            {
                                                icon: '',
                                                title: item.name,
                                                subTitle: '',
                                                name: '',
                                                value: '',
                                                valueColor: '',
                                                col: 'col-5'
                                            },
                                            {
                                                icon: '',
                                                title: '',
                                                subTitle: '',
                                                name: item.location,
                                                value: '',
                                                valueColor: '',
                                                col: 'col-4'
                                            },
                                            {
                                                icon: '',
                                                title: '',
                                                subTitle: '',
                                                name: Utils.formatDateTime(item.time, 'DD/MM/YYYY'),
                                                value: '',
                                                valueColor: '',
                                                col: 'col-3'
                                            },
                                        ]
                                    }
                                })
                            }
                        />
                    </div>
                </div>
            </div>

            {/* Modal event */}
            <ModalEventInfo
                show={modalEventShowing}
                event={selectedEvent}
                onClose={() => {
                    setModalEventShowing(false);
                }}
            />

            {/* Modal all events */}
            <ModalAllEvents
                show={modalAllEventShowing}
                onClose={() => {
                    setModalAllEventShowing(false);
                }}
            />

            {/* Modal business report */}
            <ModalBusinessReport
                show={modalBusinessReportShowing}
                onClose={() => {
                    setModalBusinessReportShowing(false);
                }}
            />
        </div>
    );
}

export default HomeSectionEvent;