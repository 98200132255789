import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppLayout from "general/components/AppLayout";
import LandingPageFeaturedReview from "features/Home/components/LandingPageFeaturedReview";
import CellPlan from "features/Payment/components/CellPlan";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useSelector } from "react-redux";
ListPlanScreen.propTypes = {};

const sTag = "[ListPlanScreen]";

function ListPlanScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();

  const planContent = useSelector(state => state?.payment?.plan?.list)

  return (
    <AppLayout>
      <div className="bg-light">
        <div className="container-xxl min-h-400px py-20">
          <div className=" text-center pt-10 d-flex flex-column align-items-center">
            <p
              className="font-weight-boldest font-size-h1 text-uppercase"
              style={{
                color: "#23262F",
              }}
            >
              {t("MoneygainPlan")}
            </p>
            <p
              className="col-lg-8 font-size-lg font-weight-normal mb-5"
              style={{
                color: "#5C6077",
              }}
            >
              Chúng tôi tạo gói này cho những ai thực sự quan tâm và nhận được
              lợi nhuận từ các khóa học hoặc sách của chúng tôi. Chúng tôi muốn
              thực hiện một gói chi phí thấp cho họ. Để họ có thể mua bất kỳ
              khóa học nào với gói mà họ mua từ chúng tôi. Cũng sẽ nhận được
              sách miễn phí từ mỗi gói.
            </p>
          </div>
          <div className="row mb-20">
            {planContent?.map((item, cellPlanIndex) => {
              return (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-5" key={cellPlanIndex}>
                  <CellPlan cellPlanItem={item} />
                </div>
              );
            })}
          </div>
        </div>
        {/* featured reviews */}
        <LandingPageFeaturedReview />
      </div>
    </AppLayout>
  );
}

export default ListPlanScreen;
