import MGDataTable from 'general/components/Tables/MGDataTable';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Accordion, Card, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import ToastHelper from "../../../../general/helpers/ToastHelper";
import { cleanResult, setCurrentSelectedFilter, thunkEditConditional, thunkGetAllFilter, thunkGetFilterDetail, thunkGetFilterResult, thunkLoadMoreFilterResult } from "../../IdeaListSlice";
import IdeaListHeaderView from '../IdeaListHeaderView';
import CreateAlertModal from "./CreateAlertModal";
import ModalCreateNewFilter from './CreateNewFilter';
import CustomFilterConditionToggle from './CustomFilterConditionToggle';
import FilterLine from "./FilterLine";
import FilterModal from "./FilterModal";
import ListAlertModal from "./ListAlertModal";
import ModalManageFilters from './ModalManageFilters';
import './style.scss';

BuildYourScreen.propTypes = {
    onMenuHandle: PropTypes.func,
};

BuildYourScreen.defaultProps = {
    onMenuHandle: null,
}

function BuildYourScreen(props) {

    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const history = useHistory();
    const [modalCreateNewFilter, setModalCreateNewFilter] = useState(false)
    const [modalManageFilters, setModalManageFilters] = useState(false)
    const yourFilter = useSelector(state => state?.ideaList?.filter?.currentFilter)
    const editFilter = useSelector(state => state?.ideaList?.editFilter)
    const filterResult = useSelector(state => state?.ideaList?.filter?.filterResult)
    const listFilters = useSelector(state => state?.ideaList?.listFilters)
    const isLoggedIn = useSelector(state => state?.auth?.current)
    const currentSelectedFilter = useSelector(state => state.ideaList.currentSelectedFilter);
    const [newConditionals, setNewConditionals] = useState([])

    let showData = []
    // console.log({ filterResult });
    filterResult?.result?.map((item) => {
        showData.push({
            onPress: () => {
                history.push(`/stock/${item.stockCode}`);
            },
            data: [
                {
                    title: item?.stockCode,
                    titleColor: '#7621B1',
                    titleWeight: '600',
                    subTitle: '',
                    name: '',
                    value: '',
                    valueColor: '#7621B1',
                    col: 'col-1'
                },
                { name: item?.stock?.exchangeCode, nameColor: '', value: '', valueColor: 'green', col: 'col-1' },
                { name: Utils.formatNumber(item?.stock?.price), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRoa, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRos, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRoe, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: Utils.formatNumber(item?.stock?.bvps), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: Utils.formatNumber(item?.stock?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1' },
            ]
        })
    })
    const [activeKey, setActiveKey] = useState(false)
    const dispatch = useDispatch()


    function checkFilterParams(filters) {
        let validConditionals = []
        filters.map((item) => {
            if (((item.values != null) && ([1, 2].includes(item?.filterConditionalId))) || ([3, 4, 5, 6, 7].includes(item?.filterConditionalId)) && (!isNaN(item.max) && !isNaN(item.max) && (item.max >= item.min))) {
                validConditionals.push(item)
            }
        })
        let res = validConditionals.length > 0
        return {
            result: res,
            filters: validConditionals
        }
    }

    useEffect(() => {
        let { result, filters } = checkFilterParams(yourFilter)
        if (result) {
            setActiveKey(true);
            dispatch(thunkGetFilterResult({
                conditionals: filters,
                page: 0
            }))
        } else {
            dispatch(cleanResult())
        }

    }, [yourFilter])

    useEffect(() => {
        if (listFilters.length === 0) {
            dispatch(thunkGetAllFilter())
        }
    }, [isLoggedIn])

    useEffect(() => {
        if (!Utils.isObjectNull(currentSelectedFilter)) {
            dispatch(thunkGetFilterDetail({ "filterId": currentSelectedFilter.filterId }));
        }
    }, [currentSelectedFilter]);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 10;
        let currentPage = filterResult?.pagination?.currentPage
        let isGettingResult = filterResult?.isGettingResult
        let hasMore = filterResult?.pagination?.hasMore ?? false
        let { result, filters } = checkFilterParams(yourFilter)
        if (bottom && result && hasMore && (!isGettingResult)) {
            dispatch(thunkLoadMoreFilterResult({
                conditionals: filters,
                page: currentPage
            }))
        }
    }

    const handleSaveFilter = () => {
        console.log({ editFilter });
        let { filterId, addConditionals, removeFilterLineIds, updateConditionals } = editFilter
        if (filterId && (addConditionals?.length + removeFilterLineIds?.length + updateConditionals?.length == 0)) {
            ToastHelper.showError(t("NoChangeFilterConditionals"))
        }
        if (filterId && (addConditionals?.length + removeFilterLineIds?.length + updateConditionals?.length > 0)) {
            dispatch(thunkEditConditional(
                {
                    filterId: filterId,
                    addConditionals: addConditionals,
                    removeFilterLineIds: removeFilterLineIds,
                    updateConditionals: updateConditionals
                }
            )).then(res => {
                let { result } = res.payload
                if (result == "success") {
                    ToastHelper.showSuccess(t("UpdateFilterSuccess"))
                }
            })
        }
        if (filterId == null) {
            let cleanFilter = checkFilterParams(yourFilter)
            let { result, filters } = cleanFilter
            if (result) {
                setNewConditionals(filters)
                setModalCreateNewFilter(true)
            }
        }
    }

    const buttons = [
        {
            iconClassName: "far fa-file-download",
            title: "Lưu Bộ Lọc",
            onClick: () => handleSaveFilter()
        },
        {
            iconClassName: "far fa-monitor-heart-rate",
            title: "Tạo Bộ Lọc Mới",
            onClick: () => setModalCreateNewFilter(true)


        },
        {
            iconClassName: "far fa-bell",
            title: "MoneyGain Cảnh Báo",
            onClick: () => ListAlertModal.show()
        },
        {
            iconClassName: "far fa-bell-plus",
            title: "Tạo Cảnh Báo",
            onClick: () => CreateAlertModal.show()
        },
        {
            iconClassName: "far fa-plus-circle",
            title: "Thêm Điều Kiện",
            onClick: () => FilterModal.show()
        },
    ]

    return (
        <div>
            <IdeaListHeaderView
                title={t('BuildYourScreen')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
            />

            {(Utils.isObjectNull(isLoggedIn) || Utils.isObjectEmpty(isLoggedIn))
                ? <div className='d-flex align-items-center justify-content-around px-5 flex-grow-1 bg-light min-h-600px'>
                    <EmptyView
                        iconEmpty={AppIcons.icCircleUser}
                        title={t('SignIn')}
                        description={t('ListFiltersNotLoggedInDescription')}
                        buttonText={t('SignIn')}
                        buttonIcon='far fa-sign-in'
                        onPressButton={() => {
                            history.push('/sign-in');
                        }}
                    />
                </div>
                : <div className='BuildYourScreen bg-light py-5'>
                    <div className='mx-5 border bg-white'>

                        {/* line 1 */}

                        <div className='border-bottom font-size-lg font-weight-bolder px-3 py-3'>
                            <Dropdown className='w-110px'>
                                <Dropdown.Toggle
                                    className='font-weight-bolder font-size-base w-25px pl-2 py-0 d-flex justify-content-start align-items-center'
                                    variant=''
                                >
                                    <span className='px-0 py-0'>
                                        {(listFilters.length === 0)
                                            ? t('filterName')
                                            : (Utils.isObjectNull(currentSelectedFilter) ? t('SelectFilter') : currentSelectedFilter.filterName)}
                                    </span>
                                    <i className="text-dark ml-3 fas fa-caret-down px-0 py-0 mr-5"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-250px py-0'>
                                    <div className='font-weight-bolder border-bottom pl-5 py-4'>{t('listFilter')}</div>
                                    {listFilters.length == 0
                                        ? <div className='d-flex align-items-center justify-content-center py-10 font-weight-light'>
                                            <EmptyView
                                                iconEmpty={AppIcons.icEmptyPack}
                                                description={t('NoDataToDisplay')}
                                            />
                                        </div>
                                        : <div>

                                            <div style={{ maxHeight: 199, overflowY: 'auto', overflowX: 'hidden' }}>
                                                {
                                                    listFilters.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    dispatch(setCurrentSelectedFilter(item));
                                                                }}
                                                                key={index}
                                                                className={`d-flex flex-row justify-content-between align-items-center border-bottom btn-light ${index == listFilters.length - 1 && 'border-bottom-0'}`}>
                                                                <div>
                                                                    <img src={AppIcons.icSquareList} alt="icon" className='mr-3' draggable={false} />
                                                                    <span>{item.filterName}</span>
                                                                </div>
                                                                {(!Utils.isObjectNull(currentSelectedFilter) && currentSelectedFilter.filterId === item.filterId) && <i className="text-success far fa-check"></i>}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <Dropdown.Item
                                        className='d-flex flex-row justify-content-start border-top  btn-light'
                                        onClick={() => { setModalCreateNewFilter(true) }}>
                                        <i className="text-primary fal fa-plus"></i>
                                        <span className='text-primary ml-3 font-weight-bolder'>{t('createNewFilter')}</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className='d-flex flex-row justify-content-start border-top  btn-light'
                                        onClick={() => { setModalManageFilters(true) }}>
                                        <i className="fal fa-cog text-primary"></i>
                                        <span className='text-primary ml-3 font-weight-bolder'>{t('ManageFilters')}</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        {/* line 2 */}
                        <div className='bg-light py-2 '>
                            {
                                buttons.map((item, index) => {
                                    const { iconClassName, title } = item
                                    return (
                                        <button key={index} className='bg-white rounded-sm border mx-3 p-3 my-2'
                                            onClick={item?.onClick}
                                        >
                                            <i className={iconClassName} style={{ verticalAlign: 'text-top' }} />
                                            <span className='ml-3 font-weight-bolder'
                                                style={{ color: "#5C6077" }}>{title}</span>
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <Accordion activeKey={activeKey}>
                            <Card className='border-0 rounded-0'>
                                <Accordion.Collapse eventKey={activeKey}>
                                    <Card.Body className='p-0 ' style={{ minHeight: 0 }}>
                                        {
                                            yourFilter.map((item, index) => {
                                                return (
                                                    <FilterLine
                                                        key={index}
                                                        conditional={item}
                                                        onAdd={() => {

                                                        }}
                                                        onUpdate={() => {

                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                                <Card.Header
                                    className='bg-white border-top d-flex py-3 justify-content-between align-items-center px-4'>
                                    <div>
                                        <p className='font-weight-bolder font-size-lg'>Số lượng thỏa mãn tất cả điều
                                            kiện</p>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <span className='font-weight-bolder mr-2' style={{
                                                fontSize: '2.46rem',
                                                color: '#7621B1'
                                            }}>{Utils.formatNumber(filterResult?.pagination?.total ?? 0) + " " + t("stockCodes")}</span>
                                            {/*<button className='bg-white border py-3 rounded px-4'>*/}
                                            {/*<i className="fas fa-star"></i>*/}
                                            {/*<span className='font-weight-bolder ml-2' style={{color:'#5C6077'}}>Thêm Vào Watchlist</span>*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                    <CustomFilterConditionToggle eventKey={activeKey} callback={(key) => {
                                        setActiveKey(!activeKey)
                                    }} />
                                </Card.Header>
                            </Card>
                        </Accordion>
                    </div>
                    <div className='m-5 border bg-white d-block overflow-auto max-h-600px' onScroll={handleScroll}>
                        <p className='border-bottom font-size-lg font-weight-bolder pl-3 py-3'>Kết Quả</p>
                        <MGDataTable
                            className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                            headItems={[
                                { title: 'Mã', subTitle: 'Chứng khoán', col: 'col-1' },
                                { title: 'Sàn', subTitle: '', col: 'col-1' },
                                { title: 'Giá', subTitle: 'VND', col: 'col-1' },
                                { title: 'YearROA', subTitle: '%', col: 'col-1' },
                                { title: 'YearROE', subTitle: '%', col: 'col-1' },
                                { title: 'YearROS', subTitle: '%', col: 'col-1' },
                                { title: 'BVPS', subTitle: '', col: 'col-1' },
                                { title: 'Sức Mạnh', subTitle: 'EPS', col: 'col-1' },

                            ]}
                            dataItems={showData}
                        />
                    </div>
                </div>}
            <ModalCreateNewFilter
                conditionals={newConditionals}
                show={modalCreateNewFilter}
                onHide={() => setModalCreateNewFilter(false)}
                onSetfilterIdChoosen={() => {
                    // setFilterIdChoosen(listFilters[0].filterId)
                    // dispatch(thunkGetFilterDetail({ "filterId": filterIdChoosen }));
                }}
            />
            {/* <FilterModalComponent /> */}
            {/* <CreateAlertModalComponent />
            <ListAlertModalComponent /> */}
            {/* <RemoveAlertModalComponent /> */}
            <ModalManageFilters
                show={modalManageFilters}
                onClose={() => {
                    setModalManageFilters(false);
                }}
                onShow={() => {
                    setModalManageFilters(true);
                }}
            />
        </div>
    );
}

export default BuildYourScreen;
