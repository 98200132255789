import axiosClient from "./axiosClient";

const faqApi = {
    // Get faq list
    getFaqList: (params) => {
        console.log(params);
        const url = '/faq/all';
        return axiosClient.get(url, { params });
    },
};

export default faqApi;