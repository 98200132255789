import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

AppBreadcrumb.propTypes = {
    items: PropTypes.array,
};

AppBreadcrumb.defaultProps = {
    items: [],
};

function AppBreadcrumb(props) {
    // MARK: --- Params ---
    const { items } = props;
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div>
            <ul className="breadcrumb bg-white pl-0">
                {
                    items.map((item, index) => (
                        item.url === ''
                            ? <li key={index} className="breadcrumb-item active" aria-current="page">{item.text}</li>
                            : <li key={index} className="breadcrumb-item text-uppercase font-size-lg font-weight-bolder cursor-pointer text-primary hover-opacity-70" onClick={() => history.push(item.url)}>{t(`${item.text}`)}</li>
                    ))
                }
            </ul>
        </div>
    );
}

export default AppBreadcrumb;
