import { AppImages } from "general/constants/AppResource";
import { useTranslation } from "react-i18next";
import Utils from "../../../../general/utils/Utils";
import "./style.scss";

ProductInfor.propTypes = {};

function ProductInfor(props) {
  const { t } = useTranslation();
  const { name, productId, price, currency, description } = props?.product
  return (
    <div className="w-100 bg-white rounded-top">
      <div className="w-100 position-relative">
        <div className="border-bottom w-100 px-6 py-4">
          <p className="font-weight-bolder font-size-lg">
            {t("Product")}
          </p>
        </div>
        <div className="w-100 border-bottom px-6 py-4">
          <p className="font-weight-bold font-size-base">
            {name}
          </p>
        </div>
        <div className="d-flex justify-content-between w-100 border-bottom px-6 py-4">
          {/* <p className="font-weight-normal font-size-base">
            {t("Description")}
          </p> */}
          <p className="font-weight-normal font-size-base">
            {description}
          </p>
        </div>
        <div className="d-flex justify-content-between px-6 py-4">
          <p className="mt-2 font-weight-normal font-size-base">
            {t("FinalPrice")}
          </p>
          <p className="mt-2 font-weight-boldest font-size-lg purple">
            {Utils.formatNumber(price)} {currency ? (" " + currency) : " VNĐ"}
          </p>
        </div>
        <img
          src={AppImages.imgZigZac}
          draggable={false}
          className="w-100 position-absolute start-0 end-0 bot-0"
        />
      </div>
    </div>
  );
}

export default ProductInfor;
