import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

SectionActionButton.propTypes = {
    title: PropTypes.string,
    detail: PropTypes.string,
    focused: PropTypes.bool,
    icon: PropTypes.string,
    borderBottomColor: PropTypes.string,
};

SectionActionButton.defaultProps = {
    title: '',
    detail: '',
    focused: false,
    icon: '',
    borderBottomColor: '',
};

function SectionActionButton(props) {
    // MARK: --- Params ---
    const { title, detail, focused, icon, borderBottomColor } = props;

    return (
        <div
            className='d-flex align-items-center justify-content-start py-6 my-6 position-relative SectionActionButton flex-grow-1'
            style={{
                borderBottomColor: borderBottomColor,
                cursor: 'default'
            }}
        >
            <i className={`${icon} fa-3x SectionActionButton_Icon ml-6`} />
            <div className='ml-4'>
                <p className='m-0 SectionActionButton_Title'>{title}</p>
                <p className='m-0 SectionActionButton_Detail'>{detail}</p>
            </div>
            <span
                className='h-5px position-absolute SectionActionButton_BottomDivider'
                style={{
                    backgroundColor: borderBottomColor
                }}
            ></span>
        </div>
    );
}

export default SectionActionButton;