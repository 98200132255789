import React from 'react';
import PropTypes from 'prop-types';

LoadingOverlay.propTypes = {
    message: PropTypes.string,
};

LoadingOverlay.defaultProps = {
    message: '',
};

function LoadingOverlay(props) {
    const { message } = props;

    return (
        <div className="overlay-layer bg-dark-o-90 rounded border-0">
            <div className="spinner spinner-right spinner-white pr-12 py-3 pl-4 rounded">
                <span className="text-secondary">{message}</span>
            </div>
        </div>
    );
}

export default LoadingOverlay;