import EmptyView from "general/components/Views/EmptyView";
import { AppIcons } from "general/constants/AppResource";
import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import stockApi from "../../../../../api/stockApi";
import BaseSearchBar from "../../../../../general/components/Forms/FormControls/BaseSearchBar";
import ToastHelper from "../../../../../general/helpers/ToastHelper";
import Utils from "../../../../../general/utils/Utils";
import { thunkCreateAlert, thunkUpdateAlert } from "../../../IdeaListSlice";
import "./style.scss";

const CreateAlertModalRef = createRef()

export default {
    show: (editMode, alert, cantSelectStockCode, stockCodeDefault) => {
        CreateAlertModalRef.current?.show(editMode, alert, cantSelectStockCode, stockCodeDefault)
    },
    hide: () => {
        CreateAlertModalRef.current?.hide()
    }
}

export function CreateAlertModalComponent() {
    const { t } = useTranslation();
    const editModeRef = useRef()
    const [alertId, setAlertId] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)
    const [inputSearchStockCode, setInputSearchStockCode] = useState(null)
    const [selectStockCode, setSelectStockCode] = useState(null)
    const [compareTitle, setCompareTitle] = useState(null)
    const [compare, setCompare] = useState(null)
    const [showSearchStockRecommend, setShowSearchStockRecommend] = useState(false)
    //type=1:price, 2: volume
    const [enableSelectStockCode, setEnableSelectStockCode] = useState(true)
    const [selectType, setSelectType] = useState(1)
    const [inputValue, setInputValue] = useState(null)
    const [pageStockSearch, setPageStockSearch] = useState(0)
    const [searchStockRes, setSearchStockRes] = useState([])
    const [totalSearchStock, setTotalSearchStock] = useState(0)
    const dispatch = useDispatch()
    const loggedInUser = useSelector(state => state.auth.current);
    const isUserLoggedIn = !Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser);
    const history = useHistory();

    useLayoutEffect(() => {
        CreateAlertModalRef.current = {
            'show': (editMode, alert, cantSelectStockCode, stockCodeDefault) => {
                editModeRef.current = editMode
                let compareTitle = (alert?.compare == null) ? null : Utils.getCompareTitle(alert?.compare)
                if (editMode) {
                    setSelectStockCode({
                        stockCode: alert?.stockCode
                    })
                    setAlertId(alert?.alertId ?? null)
                    setCompare(alert?.compare)
                    setCompareTitle(compareTitle)
                    setSelectType(alert?.type ?? 1)
                    setInputValue(alert?.value)
                }
                if (!editMode) {
                    setSelectStockCode(null)
                    setAlertId(null)
                    setCompare(null)
                    setCompareTitle(null)
                    setSelectType(1)
                    setInputValue(null)
                }
                if (cantSelectStockCode) {
                    setEnableSelectStockCode(false)
                    setSelectStockCode({
                        stockCode: stockCodeDefault
                    })
                }
                setModalVisible(true)
            },
            'hide': () => setModalVisible(false)
        }
    }, [])

    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowSearchStockRecommend(false);
            setInputSearchStockCode(null)
        }
    };

    const changeCompare = (type) => {
        setCompare(type)
        switch (type) {
            case 1:
                setCompareTitle("Greater")
                break;
            case 2:
                setCompareTitle("Smaller")
                break;
            case 3:
                setCompareTitle("GreaterOrEqual")
                break;
            case 4:
                setCompareTitle("SmallerOrEqual")
                break;
            default:
                setCompareTitle("Equal")
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (inputSearchStockCode?.length > 0) {
            setSearchStockRes([])
            setTotalSearchStock(0)
            setPageStockSearch(0)
            const timeout = setTimeout(async () => {
                await handleSearchStock(true)
            }, 250)
            return () => clearTimeout(timeout)
        }
    }, [inputSearchStockCode])

    const resetInputValue = () => {
        setInputSearchStockCode(null)
        setSelectStockCode(null)
        setCompareTitle(null)
        setCompare(null)
        setShowSearchStockRecommend(null)
        setSelectType(1)
        setInputValue(null)
    }

    const handleAddAlert = async () => {
        let params = {
            stockCode: selectStockCode?.stockCode,
            compare: compare,
            type: selectType,
            value: parseFloat(inputValue)
        }
        try {
            await dispatch(thunkCreateAlert(params)).then((res) => {
                console.log({ res });
                if (res?.payload?.result == "success") {
                    ToastHelper.showSuccess(t("CreateAlertSuccess"))
                    resetInputValue()
                    setModalVisible(false)
                } else { }
            })
        } catch (e) {
            ToastHelper.showError(e)
        }
    }

    const handleUpdateAlert = async () => {
        let params = {
            alertId: alertId,
            stockCode: selectStockCode?.stockCode,
            compare: compare,
            type: selectType,
            value: parseFloat(inputValue)
        }
        try {
            await dispatch(thunkUpdateAlert(params)).then((res) => {
                if (res?.payload?.result == "success") {
                    ToastHelper.showSuccess(t("UpdateAlertSuccess"))
                    resetInputValue()
                    setModalVisible(false)
                } else {
                    ToastHelper.showError(t("UpdateAlertFailed"))
                }
            })
        } catch (e) {
            ToastHelper.showError(e)
        }
    }

    const handleScroll = async (e) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom && totalSearchStock > searchStockRes?.length) {
            await handleSearchStock(false)
        }
    }

    const handleSearchStock = async (newResult) => {
        try {
            let searchStockResult = await stockApi.getListStockCode({
                "page": newResult ? 0 : pageStockSearch,
                "limit": 10,
                "orderMode": 'DESC',
                "searchKey": inputSearchStockCode
            })
            let { result, stocks, total, page } = searchStockResult
            if (result == "success" && stocks?.length > 0) {
                setShowSearchStockRecommend(true)
                setPageStockSearch(page + 1)
                let newValues = (page == 0) ? stocks : [
                    ...searchStockRes,
                    ...stocks
                ]
                setSearchStockRes(newValues)
            }
            if (page == 0) {
                setTotalSearchStock(total)
                setPageStockSearch(1)
            }
        } catch (e) {
            throw e
        }
    }


    return (
        <>
            <Modal
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                backdrop={true}
                keyboard={false}
                size={"md"}
                centered
            >
                <Modal.Header style={{ height: "50px" }}>
                    <Modal.Title>{editModeRef.current ? t("EditAlert") : t("CreateAlert")}</Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className={"custom-body-modal"}>
                    {
                        isUserLoggedIn && (
                            <div>
                                <div className={"custom-heading-text"}>
                                    <p className={"font-weight-bold"}>{t("StockCode")}</p>
                                    <div ref={ref}>
                                        {
                                            (selectStockCode?.stockCode) ?
                                                (enableSelectStockCode ?
                                                    <div className={"select-stock-area mt-2"}>
                                                        <span
                                                            className={"stock-name-custom"}>{editModeRef.current ? (selectStockCode?.stockCode + ((selectStockCode?.stockName) ? (" - " + selectStockCode?.stockName) : "")) : (selectStockCode?.stockCode + " - " + selectStockCode?.stockName)}</span>
                                                        <i className={"far fa-times-circle pop-industry-icon hover-opacity-50"}
                                                            onClick={() => setSelectStockCode(null)} />
                                                    </div> :
                                                    <div className={"current-stock-area mt-2"}>
                                                        <span>{selectStockCode?.stockCode}</span>
                                                    </div>)
                                                :
                                                <BaseSearchBar
                                                    onFocus={(searchStockRes?.length > 0) && (() => setShowSearchStockRecommend(true))}
                                                    className='custom-right-component mt-2'
                                                    name='searchbar'
                                                    onSubmit={(text) => {
                                                        setInputSearchStockCode(text);
                                                    }}
                                                    placeholder={`${t('TypeToSearch')}...`}
                                                />
                                        }
                                        {selectStockCode ? <></> : (searchStockRes?.length > 0) && showSearchStockRecommend &&
                                            <Dropdown onScroll={handleScroll} className={"overflow-auto custom-dropdown-stock"}
                                                show={searchStockRes?.length > 0}>
                                                {
                                                    searchStockRes?.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} eventKey={index}
                                                                onClick={() => setSelectStockCode({
                                                                    stockCode: item?.stockCode,
                                                                    stockName: item?.stockName
                                                                })}>
                                                                <span className={"flex-shrink-text"}>{
                                                                    item?.stockCode + " - " + item?.stockName
                                                                }</span>
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown>
                                        }
                                    </div>
                                </div>

                                <div className={"custom-heading-text"}>
                                    <span className={"font-weight-bold"}>{t("AlertConditional")}</span>
                                </div>
                                <Dropdown className=''>
                                    <Dropdown.Toggle
                                        className='font-weight-bold d-flex flex-row align-items-center custom-dropdown-select'
                                        variant=''>
                                        <p className={"custom-font-large"}>{selectType == 1 ? t("Price") : t("TradingVolume")}</p>
                                        <i style={{ color: '#7621B1' }} className="fas fa-caret-down ml-2"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => setSelectType(1)}>{t("Price")}</Dropdown.Item>
                                        <Dropdown.Item href="#"
                                            onClick={() => setSelectType(2)}>{t("TradingVolume")}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className={"d-flex flex-row mt-2"} style={{
                                    justifyContent: "space-between"
                                }}>
                                    <Dropdown className='custom-dropdown-compare'>
                                        <Dropdown.Toggle
                                            className='d-flex flex-row align-items-center justify-content-between' variant=''>
                                            {(compareTitle == null) ? t("NotSelected") : t(compareTitle)}
                                            <i style={{ color: '#7621B1' }} className="fas fa-caret-down ml-2"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={() => changeCompare(1)}>{t("Greater")}</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => changeCompare(2)}>{t("Smaller")}</Dropdown.Item>
                                            <Dropdown.Item href="#"
                                                onClick={() => changeCompare(3)}>{t("GreaterOrEqual")}</Dropdown.Item>
                                            <Dropdown.Item href="#"
                                                onClick={() => changeCompare(4)}>{t("SmallerOrEqual")}</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => changeCompare(0)}>{t("Equal")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className=''>
                                        <input
                                            type='text'
                                            className='form-control custom-input-value'
                                            placeholder={t("InputValue")}
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        !isUserLoggedIn && (
                            <EmptyView
                                iconEmpty={AppIcons.icCircleUser}
                                title={t('SignIn')}
                                description={t('PleaseSignInFirst')}
                                buttonText={t('SignIn')}
                                buttonIcon='far fa-sign-in'
                                onPressButton={() => {
                                    history.push('/sign-in');
                                }}
                            />
                        )
                    }
                </Modal.Body>
                <Modal.Footer className=''>
                    <div className='w-100 d-flex row'>
                        <Button className='font-weight-bold flex-grow-1 col mr-3' variant="secondary" onClick={() => {
                            setModalVisible(false);
                        }}>
                            {t('Cancel')}
                        </Button>
                        {
                            isUserLoggedIn && (
                                <Button className='font-weight-bold flex-grow-1 col ml-3' variant="primary" onClick={() => editModeRef.current ? handleUpdateAlert() : handleAddAlert()}>
                                    {t('Save')}
                                </Button>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

