import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import Utils from 'general/utils/Utils';
import { Markup } from 'interweave';

ModalArticleDetail.propTypes = {
    show: PropTypes.bool,
    article: PropTypes.object,
    onClose: PropTypes.func,
};

ModalArticleDetail.defaultProps = {
    show: false,
    article: {},
    onClose: null,
};

function ModalArticleDetail(props) {
    // MARK: --- Params ---
    const { show, article, onClose } = props;
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function handleClose(e) {
        if (e) {
            e.preventDefault();
        }
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>
                        {
                            article && (
                                <>
                                    {article.title}
                                    <p className='font-size-base text-dark-75 font-weight-normal mt-1'>{`${Utils.formatDateTime(article.date, 'DD/MM/YYYY HH:mm')} | ${t('By')}: `}<span className='text-primary font-weight-bolder'>{article.author}</span></p>
                                </>
                            )
                        }

                    </Modal.Title>
                    <a href="#" className="btn btn-sm btn-icon btn-secondary" onClick={handleClose}>
                        <i className="far fa-times"></i>
                    </a>
                </Modal.Header>
                <Modal.Body className='overflow-auto' style={{
                    maxHeight: '84vh'
                }}>
                    {article && (
                        <Markup
                            className='font-size-lg text-justify q-markup'
                            content={Utils.decodeHTML(article.content)}
                        />
                    )}
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button className='font-weight-bold' variant="secondary" onClick={() => {
                        handleClose();
                    }}>
                        {t('Close')}
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
}

export default ModalArticleDetail;