import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';
import { AppIcons } from 'general/constants/AppResource';
import { Markup } from 'interweave';

CellNotificationList.propTypes = {
    notificationItem: PropTypes.object,
    unread: PropTypes.bool,
    showDivider: PropTypes.bool,
    onPress: PropTypes.func,
};

CellNotificationList.defaultProps = {
    notificationItem: {},
    unread: false,
    showDivider: true,
    onPress: null,
};

function CellNotificationList(props) {
    // MARK: --- Params ---
    const { notificationItem, unread, showDivider, onPress } = props;

    // MARK: --- Functions ---
    function handlePress() {
        if (onPress) {
            onPress();
        }
    }

    return (
        <div className={`d-flex align-items-center flex-grow-1 ${showDivider && 'border-bottom'} px-5 py-2 cursor-pointer bg-hover-light`} onClick={handlePress}>
            <div className="symbol symbol-50  flex-shrink-0">
                <div className="symbol-label" style={{ backgroundColor: '#7621B1' }}>
                    {/* <i className="fad fa-bell fa-2x text-secondary"></i> */}
                    <img
                        src={AppIcons.icMGLogo}
                        draggable={false}
                        alt='icon'
                        className=''
                        style={{
                            height: '50%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
            <div className="d-flex flex-column ml-3 mt-2 mb-2 flex-grow-1">
                <a href="#" className="font-size-lg font-weight-bold text-dark text-hover-primary">
                    {notificationItem.title}
                </a>
                <span className="font-size-base font-weight-bold text-muted">
                    {notificationItem?.description}
                    {/* <Markup
                        className='font-size-lg text-justify q-markup'
                        content={Utils.decodeHTML(notificationItem.content)}
                    /> */}
                </span>
                <span className="font-size-sm font-weight-normal text-muted">
                    {Utils.formatDateTime(notificationItem.date, 'DD/MM/YYYY HH:mm')}
                </span>
            </div>

            {
                unread && (
                    <div className='rounded-circle' style={{ backgroundColor: '#7621B1', width: '8px', aspectRatio: '1' }}>

                    </div>
                )
            }
        </div>
    );
}

export default CellNotificationList;