
import accountApi from "../../api/accountApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
export const thunkGetCourseTransactions = createAsyncThunk('transaction/course', async (params, thunkAPI) => {
    const res = await accountApi.getTransactionHistory({
        type: "COURSE"
    });
    return res;
});

const transactionSlice = createSlice({
    name: 'course',
    initialState: {
        course: []
    },
    reducers: {

    },
    extraReducers: {
        [thunkGetCourseTransactions.pending]: (state, action) => {
        },
        [thunkGetCourseTransactions.rejected]: (state, action) => {
        },
        [thunkGetCourseTransactions.fulfilled]: (state, action) => {
            let { result, transactions } = action.payload
            if (result === 'success') {
                state.course = transactions
            }
        },
    },
});

const { reducer, actions } = transactionSlice;
export const { } = actions;
export default reducer;
