import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { AccordionContext } from 'react-bootstrap';
import './style.scss';

CustomFrequentlyQuestionToggle.propTypes = {

};

function CustomFrequentlyQuestionToggle({ children, eventKey }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => {

    });
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className='CustomFrequentlyQuestionToggle border-0 bg-transparent py-6 w-100 d-flex'
            onClick={decoratedOnClick}
        >
            <div className='w-100 d-flex justify-content-between'>
                {children}
                <i className={`fas ${isCurrentEventKey ? 'fa-caret-up' : 'fa-caret-down'} text-dark-75 ml-4 CustomFrequentlyQuestionToggle_Caret`}></i>
            </div>
        </button>
    );
}

export default CustomFrequentlyQuestionToggle;