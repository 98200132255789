import bookApi from 'api/bookApi';
import CellBookGrid from 'features/Book/components/CellBookGrid';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KCHeaderView from '../KCHeaderView';
import './style.scss';

KCBook.propTypes = {
    onMenuHandle: PropTypes.func,
};

KCBook.defaultProps = {
    onMenuHandle: null,
};

function KCBook(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const [loading, setLoading] = useState(false);
    const [books, setBooks] = useState([]);
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        q: '',
    });
    const pagination = useRef(null);

    // MARK: --- Functions ---
    async function getBookList() {
        setLoading(true);
        const res = await bookApi.getBookList(filter);
        const { result, total, page, count } = res;
        const resBooks = res.books;
        if (result === 'success' && resBooks) {
            if (page === 0) {
                setBooks(resBooks);
            } else {
                setBooks(books.concat(resBooks));
            }
            pagination.current = {
                total: total,
                count: count,
                currentPage: page
            };
        }
        setLoading(false);
    }

    function onWindowScroll() {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (pagination.current) {
                let nextPage = Utils.getNextPage(pagination.current);
                if (nextPage) {
                    setFilter({
                        ...filter,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getBookList();
    }, [filter]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <div className='KCBook'>
            <KCHeaderView
                title={t('AllBooks')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
                onSubmitSearch={(text) => {
                    setFilter({
                        ...filter,
                        q: text
                    })
                }}
            />
            <div className='KCBook_Banner row px-10 px-md-20 pt-5 pt-md-10 border-bottom'>
                <div className='col-5 d-flex align-items-center pb-5 pb-md-10'>
                    <p className='m-0 text-left font-weight-boldest text-uppercase' style={{ fontSize: '2.5rem', lineHeight: '120%' }}>
                        {t('Library')}
                        <br />
                        <span className='text-primary' style={{ fontSize: '3rem' }}>MoneyGain</span>
                    </p>
                </div>
                <div className='KCBook_Banner_Right col-7 py-30'></div>
            </div>

            {/* content */}
            <div className='row px-7'>
                {
                    books.length > 0 && books.map((item, index) => {
                        return (
                            <div key={index} className='col-12 col-sm-6 col-md-4 my-7'>
                                <CellBookGrid item={item} />
                            </div>
                        )
                    })
                }

                {
                    books.length === 0 && (
                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                            <EmptyView
                                description='Không có sách'
                                iconEmpty={AppIcons.icEmptyPack}
                                loading={loading}
                            />
                        </div>
                    )
                }
            </div>
        </div >
    );
}

export default KCBook;