import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import CellOfLandingPageSectionIntro2 from './CellOfLandingPageSectionIntro2';
import { AppIcons } from 'general/constants/AppResource';

LandingPageSectionIntro2.propTypes = {

};

function LandingPageSectionIntro2(props) {
    return (
        <div className='LandingPageSectionIntro2'>
            <div className='container-xxl py-md-20 py-10'>
                <div className='row mt-6'>
                    <div className='col-12 col-sm-6 col-lg-3 my-10'>
                        <CellOfLandingPageSectionIntro2
                            image={AppIcons.icTrienVong}
                            title="Triển Vọng Thị Trường"
                            detail="Phân tích ngày, tuần về xu hướng thị trường, giúp các nhà đầu tư hiểu rõ về tình trạng hiện tại của thị trường đang hỗ trợ hay tổn hại đến danh mục. More… "
                        />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-3 my-10'>
                        <CellOfLandingPageSectionIntro2
                            image={AppIcons.icCoPhieuChonLoc}
                            title="Cổ phiếu chọn lọc"
                            detail="Danh mục mẫu.Cách dễ dàng giúp bạn biết cổ phiếu nào đang tốt nhất hiện nay qua các danh sách cổ phiếu, xếp hạng theo tiêu chí chọn lọc khắt khe như Danh sách MoneyGain 50, Danh sách tăng tăng trưởng…More…"
                        />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-3 my-10'>
                        <CellOfLandingPageSectionIntro2
                            image={AppIcons.icPhanTich}
                            title="Phân tích cổ phiếu"
                            detail="Điểm xếp hạng sức mạnh cổ phiếu dựa trên sự kết hợp về tăng trưởng doanh thư, EPS, sức mạnh giá, sức mạnh nhóm ngành…giúp bạn dễ dàng đánh giá sức mạnh cổ phiếu và danh mục. More…"
                        />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-3 my-10'>
                        <CellOfLandingPageSectionIntro2
                            image={AppIcons.icHocVien}
                            title="Học viện MoneyGain"
                            detail="Các khóa học online dựa trên phương pháp đầu tư đã được kiểm chứng từ các huyền thoại William Oneil, Mark Minervini…giúp nhà đầu tư tự trang bị cho mình một hệ thống đầu tư hoàn chỉnh"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionIntro2;