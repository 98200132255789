import axiosClient from "./axiosClient";

const eventApi = {
    // get all 
    getAll: (params) => {
        const url = '/event/all';
        return axiosClient.get(url, { params });
    },
};

export default eventApi;