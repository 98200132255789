import { MyListStock } from '../general/types/index.d'
import axiosClient from "./axiosClient";

const myListApi = {
    // Lay ds my list cua tai khoan
    getMyList: (params) => {
        const url = '/myList/all';
        return axiosClient.get(url, { params });
    },

    // Tao my list
    createMyList: (name) => {
        const url = '/myList/create';
        return axiosClient.post(url, {
            name: name,
        });
    },

    // Xoa my list
    deleteMyList: (id) => {
        const url = '/myList/delete';
        return axiosClient.post(url, {
            myListId: id,
        })
    },

    // Doi ten my list
    renameMyList: (mylistId, newName) => {
        const url = `/myList/${mylistId}`;
        return axiosClient.put(url, {
            name: newName,
        })
    },

    // Them ma co phieu vao my list
    addStock: (params) => {
        const url = '/myList/add_stock';
        return axiosClient.post(url, params);
    },

    // Xoa 1 ma co phieu khoi my list
    removeStock: (mylistStockId) => {
        const url = '/myList/remove_stock';
        return axiosClient.post(url, {
            myListStockId: mylistStockId,
        })
    },

    // lay chi tiet 1 mylist
    getMyListDetail: (mylistId, params = {}) => {
        const url = `/myList/${mylistId}`;
        return axiosClient.get(url, { params });
    },

    // du lieu tab danh muc
    getMyListListData: (myListId) => {
        const url = `/myList/stockData/list/${myListId}`;
        return axiosClient.get(url);
    },

    // du lieu tab xep hang
    getMyListRankData: (myListId) => {
        const url = `/myList/stockData/rankInList/${myListId}`;
        return axiosClient.get(url);
    },

    // du lieu tab co ban
    getMyListBasicData: (myListId) => {
        const url = `/myList/stockData/basic/${myListId}`;
        return axiosClient.get(url);
    },

    // du lieu tab ky thuat
    getMyListTechnicalData: (myListId) => {
        const url = `/myList/stockData/technique/${myListId}`;
        return axiosClient.get(url);
    },

    // du lieu tab phan tich danh muc
    getMyListAnalyticList: (myListId) => {
        const url = `/myList/stockData/listAnalysis/${myListId}`;
        return axiosClient.get(url);
    },

    // du lieu tab phan tich lich su giao dich
    getMyListAnalyticTransactionHistory: (myListId) => {

    },

    // lay du lieu cua mot stock trong danh muc cua toi
    getStockDetail: (myListId, stockCode) => {
        const url = `/myList/stockCode/${myListId}`;
        const params = {
            stockCode: stockCode
        }
        return axiosClient.get(url, { params });
    },

    // sua du lieu cua mot stock trong danh muc cua toi
    editStockDetail: (params) => {
        const url = `/myList/updateMyListStock/${params.myListStockId}`
        return axiosClient.put(url, {
            datetime: params.datetime,
            price: params.price,
            quantity: params.quantity,
            note: params.note
        })
    },

    // lay danh sach toan bo lenh da giao dich
    getHistoryTransaction: (myListId, params = {}) => {
        const url = `/myList/${myListId}`;
        return axiosClient.get(url, { params })
    },
    // add multiple to my list
    addMultiple(myListId: number, data: MyListStock[]) {
        return axiosClient.post('/myList/addMultiple/'.concat(myListId), {
            data
        })
    },

    // get my list summary
    getMyListSummary: (myListId) => {
        const url = `myList/stockData/summary/${myListId}`;
        return axiosClient.get(url);
    },
};

export default myListApi;