import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

CellReviewGrid.propTypes = {
    item: PropTypes.object.isRequired,
};

CellReviewGrid.defaultProps = {

};

function CellReviewGrid(props) {
    // MARK: --- Params ---
    const { item } = props;
    const { name, avatar, job, reviewText, createdAt, updatedAt } = item;

    return (
        <div className='CellReviewGrid p-10 bg-white rounded-lg d-flex flex-column justify-content-between w-100 h-100'>
            <p className='CellReviewGrid_Text font-size-lg m-0'>{reviewText}</p>
            <div className='CellReviewGrid_ViewAuthor p-6 rounded mt-6 d-flex align-items-center'>
                <div className="d-flex align-items-center justify-content-center bg-primary w-60px h-60px rounded-circle">
                    <i className="fad fa-user fa-2x text-white"></i>
                </div>
                <div className='d-flex flex-column ml-4'>
                    <p className='font-size-h5 font-weight-boldest m-0 CellReviewGrid_AuthorName'>{name}</p>
                    {
                        job && (
                            <p className='font-size-lg m-0 CellReviewGrid_AuthorDescription'>{job}</p>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default CellReviewGrid;