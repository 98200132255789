import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import "./index.scss";


BarChart.propTypes = {
    data: PropTypes.array,
    color: PropTypes.array,
    col: PropTypes.array,
    legend: PropTypes.array,
    dataLabel: PropTypes.bool,
    stacked: PropTypes.bool,
    showScale: PropTypes.bool,
};

BarChart.defaultProps = {
    data: [],
    color: ['#68C57C', '#E84650'],
    col: ['col-4', 'col-8'],
    legend: null,
    dataLabel: true,
    stacked: false,
    showScale: true,
};

/**
 * @prop data: [ {label: string, value:[int, int,...]},... ]
 * @prop color: array string
 * @prop col: default ['col-4', 'col-8'] căn chỉnh ko gian của label và bar
 * @prop legend: array string,
 * @prop dataLabel: bool hiện % trên bar,
 * @prop stacked: bool,
 * @prop showScale: bool hiện scale tỉ lệ % phía dưới bảng,
 */
function BarChart(props) {
    const { t } = useTranslation();

    // --- MARK: Param --- 
    const id = uuidv4();
    const grey = '#5C6077';
    const lightGrey = '#F3F5F8';

    const data = props.data;
    const color = props.color;
    const col = props.col;
    const legend = props.legend;
    const dataLabel = props.dataLabel;
    const stacked = props.stacked;
    const showScale = props.showScale;

    const maxRatio = [Math.ceil(Math.max(...data.map(index => Math.abs(index.value[0]))) / 100)];
    const barWidth = [data.map(index => Math.abs(index.value[0] / maxRatio[0]))];
    if (stacked) {
        maxRatio.push(Math.ceil(Math.max(...data.map(index => Math.abs(index.value[1]))) / 100));
        barWidth.push(data.map(index => Math.abs(index.value[1] / maxRatio[1])));
    }

    const barHeight = '22px';

    // --- MARK: Function
    const markerMouseEnter = (barGroupIndex) => {
        var barChartContainer = document.getElementById(id);

        if (barChartContainer.querySelector('#marker' + barGroupIndex).classList.contains('Disable')) return;
        var allBars = barChartContainer.getElementsByClassName(barGroupIndex ^ 1);
        for (var i = 0, len = allBars.length; i < len; i++) {
            if (allBars[i].classList.contains('opacity-20')) return;
            allBars[i].classList.add('opacity-20');
        }
    }
    const markerMouseLeave = (barGroupIndex) => {
        var barChartContainer = document.getElementById(id);

        var allBars = barChartContainer.getElementsByClassName(barGroupIndex ^ 1);
        for (var i = 0, len = allBars.length; i < len; i++) {
            allBars[i].classList.remove('opacity-20');
        }
    }

    const markerClick = (barGroupIndex) => {
        var barChartContainer = document.getElementById(id);

        var allBars = barChartContainer.getElementsByClassName(barGroupIndex);
        for (var i = 0, len = allBars.length; i < len; i++) {
            if (allBars[i].classList.contains('d-none')) allBars[i].classList.remove('d-none');
            else allBars[i].classList.add('d-none');
        }

        var marker = barChartContainer.querySelector('#marker' + barGroupIndex);
        if (marker.classList.contains('Disable')) marker.classList.remove('Disable');
        else {
            marker.classList.add('Disable');
            markerMouseLeave(barGroupIndex);
        }
    }


    return (
        <div className='BarChart' id={id}>
            {
                data.map((item, index) => {
                    return (
                        <div key={index} className={`px-5 py-4 ${index > 0 && 'border-top'} ${stacked ? '' : `${item.value[0] > 0 ? 0 : 1}`}`} style={{ transition: 'all 400ms', }}>
                            <div className='row d-flex align-items-center'>
                                <div className={col[0]}><p className='Mini_content_13'>{item.label}</p></div>
                                <div className={col[1]}>
                                    <div className='border bg-light d-flex flex-row-reverse align-items-center' style={{ position: 'relative', height: '24px', }}>
                                        <div className={'flex-grow-1 ' + (item.value[0] > 0 ? 0 : 1)} align='left' style={{ transition: 'all 400ms', }}>
                                            <div style={{
                                                backgroundColor: item.value[0] > 0 ? color[0] : color[1],
                                                height: barHeight,
                                                width: `${barWidth[0][index]}%`,
                                            }}
                                            >
                                                {
                                                    dataLabel &&
                                                    <p className='Mini_content_13 px-2 text-dark' style={{ color: 'white', textAlign: 'right' }}>
                                                        {item.value[0]}%
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        {
                                            stacked &&
                                            <div className='flex-grow-1 1' align='right' style={{ transition: 'all 400ms', }}>
                                                <div style={{ backgroundColor: color[1], height: barHeight, width: `${barWidth[1][index]}%` }}>
                                                    {
                                                        dataLabel &&
                                                        <p className='Mini_content_13 px-2 text-dark' style={{ color: 'white', textAlign: 'left', direction: 'rtl' }}>
                                                            {item.value[1]}%
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            {
                showScale && (
                    <div className='px-5 py-3 border-top' style={{ backgroundColor: lightGrey }}>
                        <div className='row'>
                            <div className={col[0]}><p className='Mini_content_13'>{t('Rate')}</p></div>
                            <div className={col[1]}>
                                <div className='d-flex flex-row-reverse' style={{ position: 'relative' }}>
                                    <div className={`flex-grow-1 ${stacked ? 0 : ''}`}>
                                        <div className='d-flex flex-row flex-grow-1' align='right'>
                                            <p className='Mini_content_13 w-50' style={{ color: grey, textAlign: 'right' }}>{`${maxRatio[0] * 50}%`}</p>
                                            <p className='Mini_content_13 w-50' style={{ color: grey, textAlign: 'right' }}>{`${maxRatio[0] * 100}%`}</p>
                                        </div>
                                    </div>
                                    <p className='Mini_content_13' style={{ color: grey, textAlign: 'right' }}>0%</p>
                                    {
                                        stacked &&
                                        <div className='flex-grow-1 1'>
                                            <div className='d-flex flex-row' align='right'>
                                                <p className='Mini_content_13 w-50' style={{ color: grey, textAlign: 'left' }}>{`${maxRatio[1] * 100}%`}</p>
                                                <p className='Mini_content_13 w-50' style={{ color: grey, textAlign: 'left' }}>{`${maxRatio[1] * 50}%`}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                legend && legend.length > 0 &&
                <div className='Legend px-5 border-top' style={{ backgroundColor: lightGrey }}>
                    <div className='row'>
                        <div className={col[0]}></div>
                        <div className={col[1]}>
                            <div className='d-flex flex-row-reverse justify-content-around'>
                                {
                                    legend.map((text, index) => {
                                        return (
                                            <div key={index} id={`marker${index}`} className='d-flex flex-row py-3 align-items-center cursor-pointer'
                                                onMouseEnter={() => { markerMouseEnter(index) }}
                                                onMouseLeave={() => { markerMouseLeave(index) }}
                                                onClick={() => { markerClick(index) }}
                                            >
                                                <div className='Marker' style={{ backgroundColor: color[index] }} />
                                                <p className='Mini_content_13' style={{ color: grey }}>{text}</p>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default BarChart;
