import companyApi from 'api/companyApi';
import { useFormik } from 'formik';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import AppTabs from 'general/components/AppTabs';
import BaseDropdownSelect from 'general/components/Forms/FormControls/BaseDropdownSelect';
import DropdownStockSelect from 'general/components/Forms/FormControls/DropdownStockSelect';
import TextFieldNoFormik from 'general/components/Forms/FormControls/TextFieldNoFormik';
import MGDataTable from 'general/components/Tables/MGDataTable';
import AppData from 'general/constants/AppData';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

Compare.propTypes = {
    company: PropTypes.object,
};

Compare.defaultProps = {
    company: {},
};

function Compare(props) {
    // MARK: --- Params ---
    const { company } = props;
    const companyId = company ? company.companyId : 0;
    const { t } = useTranslation();
    const tabs = [
        t('SameIndustry'),
        t('QuickCompare')
    ];
    const formik = useFormik({
        initialValues: {
            exchange: '',
            filterOption: '',
            filterValueOption: '',
            filterValue: 0,
            quickStockSearchText: '',
        },
        onSubmit: (values) => {

        },
        enableReinitialize: true
    });
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const history = useHistory();
    const ignoreWindowScrollEvent = useRef(false);

    // Quick search stock
    const [quickCompareStocks, setQuickCompareStocks] = useState([]);
    const [quickStockSearchResult, setQuickStockSearchResult] = useState([]);
    const [filterQuickStockSearch, setFilterQuickStockSearch] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
        q: '',
    });
    const [paginationQuickStockSearch, setPaginationQuickStockSearch] = useState(null);

    const [filterSimilarCompany, setFilterSimilarCompany] = useState({
        industryId: company ? company.industryId : 0,
        companyId: company ? company.companyId : 0,
        page: 0,
        limit: Global.gDefaultPagination,
        exchangeCode: AppData.allExchangeCode[0],
        compareField: AppData.sampleStockFilterOptions[0].value,
        compareType: AppData.sampleStockFilterValueOptions[0].value,
        compareValue: 0,
    });
    const [similarCompanies, setSimilarCompanies] = useState([]);
    const paginationSimilarCompany = useRef(null);

    // MARK: --- Functions ---
    function handlePressStock() {

    }

    function handleSelectedTab(tab) {
        setSelectedTab(tab);
        if (tab === t('QuickCompare')) {
            ignoreWindowScrollEvent.current = true;
        } else {
            ignoreWindowScrollEvent.current = false;
        }
    }

    async function quickSearchStock() {
        const res = await companyApi.search(filterQuickStockSearch);
        const { result, total, count, page } = res;
        const searchedStocks = res.companies;
        if (result === 'success' && searchedStocks) {
            if (page > 0) {
                setQuickStockSearchResult(quickStockSearchResult.concat(searchedStocks));
            } else {
                setQuickStockSearchResult(searchedStocks);
            }
            setPaginationQuickStockSearch({
                total: total,
                count: count,
                currentPage: page
            });
        }
    }

    async function getSimilarCompany() {
        const res = await companyApi.getSimilarCompany(filterSimilarCompany);
        const { result, total, count, page } = res;
        paginationSimilarCompany.current = {
            total: total,
            count: count,
            currentPage: page
        };
        const searchedCompanies = res.companies;
        if (result === 'success' && searchedCompanies) {
            // console.log({ page });
            if (page > 0) {
                setSimilarCompanies(similarCompanies.concat(searchedCompanies));
            } else {
                setSimilarCompanies(searchedCompanies);
            }
        }
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            if (paginationSimilarCompany.current) {
                let nextPage = Utils.getNextPage(paginationSimilarCompany.current);
                if (nextPage) {
                    setFilterSimilarCompany({
                        ...filterSimilarCompany,
                        page: nextPage,
                    });
                }
            }
        }
    }

    function onWindowScroll() {
        if (ignoreWindowScrollEvent.current) {
            return;
        }
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (paginationSimilarCompany.current) {
                let nextPage = Utils.getNextPage(paginationSimilarCompany.current);
                console.log({ nextPage });
                if (nextPage) {
                    setFilterSimilarCompany({
                        ...filterSimilarCompany,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        quickSearchStock();
    }, [filterQuickStockSearch]);

    useEffect(() => {
        if (company) {
            getSimilarCompany();
        }

        const interval = setInterval(() => {
            getSimilarCompany();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [filterSimilarCompany, company]);

    useEffect(() => {
        // window.addEventListener('scroll', onWindowScroll);
        // return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <div className=''>
            <AppTabs
                tabs={tabs.reverse()}
                selectedTab={selectedTab}
                handleClick={handleSelectedTab}
            />
            <Tabs
                defaultActiveKey={tabs[0]}
                activeKey={selectedTab}
                className='border-0'
            >
                {/* DN cung nghanh */}
                <Tab eventKey={tabs[1]} className=''>
                    <AppCardDataInfo
                        additionsClassName='border-top-0'
                        title={t('SameIndustry')}
                        alignTitleItems='center'
                        rightComponent={(
                            <div className='d-flex flex-column align-items-start'>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column align-items-start my-2'>
                                        <BaseDropdownSelect
                                            name='selectExchange'
                                            label={t('ChooseExchange')}
                                            options={AppData.sampleExchangeOptions.filter(item => item.value !== 'UNKNOWN')}
                                            fieldHelpers={formik.getFieldHelpers('exchange')}
                                            fieldMeta={formik.getFieldMeta('exchange')}
                                            fieldProps={formik.getFieldProps('exchange')}
                                            onValueChanged={(value) => {
                                                setFilterSimilarCompany({
                                                    ...filterSimilarCompany,
                                                    exchangeCode: value,
                                                    page: 0,
                                                })
                                            }}
                                        />
                                        <p className='m-0 font-italic mt-2'>{`(${Utils.formatNumber(paginationSimilarCompany.current ? paginationSimilarCompany.current.total : 0)} ${t('SameIndustryCompany')})`}</p>
                                    </div>

                                    <div className='ml-4 my-2'>
                                        <BaseDropdownSelect
                                            name='selectFilterOption'
                                            label=''
                                            options={AppData.sampleStockFilterOptions}
                                            fieldHelpers={formik.getFieldHelpers('filterOption')}
                                            fieldMeta={formik.getFieldMeta('filterOption')}
                                            fieldProps={formik.getFieldProps('filterOption')}
                                            onValueChanged={(value) => {
                                                setFilterSimilarCompany({
                                                    ...filterSimilarCompany,
                                                    compareField: value,
                                                    page: 0,
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className='ml-4 my-2'>
                                        <BaseDropdownSelect
                                            name='selectFilterValueOption'
                                            label=''
                                            options={AppData.sampleStockFilterValueOptions}
                                            fieldHelpers={formik.getFieldHelpers('filterValueOption')}
                                            fieldMeta={formik.getFieldMeta('filterValueOption')}
                                            fieldProps={formik.getFieldProps('filterValueOption')}
                                            onValueChanged={(value) => {
                                                setFilterSimilarCompany({
                                                    ...filterSimilarCompany,
                                                    compareType: value,
                                                    page: 0,
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className='ml-4 my-2'>
                                        <TextFieldNoFormik
                                            name='filterValue'
                                            placeholder={`${t('Value')}...`}
                                            type='number'
                                            onSubmit={(value) => {
                                                setFilterSimilarCompany({
                                                    ...filterSimilarCompany,
                                                    compareValue: parseInt(value),
                                                    page: 0,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        content={(
                            <div className='max-h-600px overflow-auto' onScroll={handleScroll}>
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                                        { title: t('Vốn Hoá'), subTitle: '(tỷ đồng)', col: 'col-1', textAlign: 'right' },
                                        { title: t('Price'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' },
                                        { title: t('Thay Đổi (%)'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                        { title: t('Thay Đổi 1 Tháng (%)'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                        { title: t('KLGD'), subTitle: '(nghìn CP)', col: 'col-1', textAlign: 'right' },
                                        { title: t('KLGD'), subTitle: '(tỷ đồng)', col: 'col-1', textAlign: 'right' },
                                        { title: t('Sức Mạnh'), subTitle: 'Tổng Hợp', col: 'col-1', textAlign: 'right' },
                                        { title: t('Strength'), subTitle: 'EPS', col: 'col-1', textAlign: 'right' },
                                        { title: t('Strength'), subTitle: t('Price'), col: 'col-1', textAlign: 'right' },
                                        { title: t('Strength'), subTitle: t('Lực Cầu'), col: 'col-1', textAlign: 'right' },
                                        // { title: t('Ranking'), subTitle: t('Group'), col: 'col-1', textAlign: 'right' },
                                        { title: t('P/E'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                        { title: t('P/E TB 5 năm'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                        { title: t('P/B'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        similarCompanies.map((item, index) => {
                                            const price = item?.stock?.price ?? 0;
                                            const refPrice = item?.stock?.refPrice ?? 0;
                                            const lastMonthPrice = item?.stock?.last_month_price ?? 0;
                                            let changeInPercent = 0;
                                            let changeInPercent1Month = 0;

                                            if (refPrice > 0) {
                                                changeInPercent = ((price - refPrice) / refPrice) * 100;
                                            }
                                            if (lastMonthPrice > 0) {
                                                changeInPercent1Month = ((price - lastMonthPrice) / lastMonthPrice) * 100;
                                            }

                                            return {
                                                onPress: () => {

                                                },
                                                data: [
                                                    { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item.fullname, name: '', value: '', valueColor: '', col: 'col-3' },
                                                    { icon: '', title: '', name: Utils.formatNumber((item?.stock?.marketCap / 1000000000).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.price / 1000), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: `${Utils.formatNumber(changeInPercent.toFixed(2))}%`, nameColor: Utils.getValueColor(changeInPercent), value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: `${Utils.formatNumber(changeInPercent1Month.toFixed(2))}%`, nameColor: Utils.getValueColor(changeInPercent1Month), value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber((item?.stock?.totalVolume / 1000).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber((item?.stock?.totalPrice / 1000000000).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.summaryStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.priceStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: item?.stock?.demandStrength, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    // { icon: '', title: '', name: Utils.formatNumber(item?.stock?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.pb), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber((item?.stock?.pe_mean_5y ?? 0).toFixed(2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.stock?.pe), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                ]
                                            }
                                        })
                                    }
                                />
                            </div>
                        )}
                    />
                </Tab>

                {/* So sanh nhanh */}
                <Tab eventKey={tabs[0]} className=''>
                    <AppCardDataInfo
                        additionsClassName='border-top-0'
                        title={t('QuickCompare')}
                        alignTitleItems='center'
                        rightComponent={(
                            <div>
                                <DropdownStockSelect
                                    label={t('ChooseStockCode')}
                                    onSelectedStock={(stock) => {
                                        setQuickCompareStocks(quickCompareStocks.concat(stock.stock));
                                    }}
                                    name='quickStockSearchText'
                                    placeholder={`${t('TypeToSearch')}...`}
                                    options={quickStockSearchResult}
                                    fieldHelpers={formik.getFieldHelpers('quickStockSearchText')}
                                    fieldProps={formik.getFieldProps('quickStockSearchText')}
                                    fieldMeta={formik.getFieldMeta('quickStockSearchText')}
                                    onSubmit={(text) => {
                                        setFilterQuickStockSearch({
                                            ...filterQuickStockSearch,
                                            q: text
                                        })
                                    }}
                                />
                            </div>
                        )}
                        content={(
                            <>
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 min-h-300px'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-2' },
                                        { title: t('Price'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' },
                                        { title: t('Score'), subTitle: 'MoneyGain', col: 'col-1', textAlign: 'right' },
                                        { title: t('Score'), subTitle: t('Demand'), col: 'col-1', textAlign: 'right' },
                                        { title: t('Score'), subTitle: t('Group'), col: 'col-1', textAlign: 'right' },
                                        { title: t('Strength'), subTitle: 'EPS', col: 'col-1', textAlign: 'right' },
                                        { title: t('Strength'), subTitle: t('Price'), col: 'col-1', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        quickCompareStocks.map((item, index) => {
                                            return ({
                                                onPress: () => {
                                                    history.push(`/stock/${item.stockCode}`);
                                                },
                                                data: [
                                                    { icon: '', title: item.stockCode, subTitle: item.stockName, name: '', value: '', valueColor: '', col: 'col-2' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.price / 1000), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.mgScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.demandScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                    { icon: '', title: '', name: Utils.formatNumber(item?.priceStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                ]
                                            })
                                        })
                                    }
                                />
                            </>
                        )}
                    />
                </Tab>
            </Tabs>
        </div>
    )
}

export default Compare;
