import axiosClient from "./axiosClient";

const watchlistApi = {
    // Lay ds watch list cua tai khoan
    getWatchList: (params) => {
        const url = '/watchlist/all';
        return axiosClient.get(url, { params });
    },

    // Tao watch list
    createWatchList: (name) => {
        const url = '/watchlist/create';
        return axiosClient.post(url, {
            name: name,
        });
    },

    // Xoa watch list
    deleteWatchList: (id) => {
        const url = '/watchlist/delete';
        return axiosClient.post(url, {
            watchlistId: id,
        })
    },

    // Doi ten watch list
    renameWatchList: (watchlistId, newName) => {
        const url = `/watchlist/${watchlistId}`;
        return axiosClient.put(url, {
            name: newName,
        })
    },

    // Them ma co phieu vao watch list
    addStock: (watchlistId, stockCode) => {
        const url = '/watchlist/add_stock';
        return axiosClient.post(url, {
            watchlistId: watchlistId,
            stockCode: stockCode,
        })
    },

    // Xoa 1 ma co phieu khoi watch list
    removeStock: (watchlistStockId) => {
        const url = '/watchlist/remove_stock';
        return axiosClient.post(url, {
            watchlistStockId: watchlistStockId,
        })
    },

    // lay chi tiet 1 watchlist
    getWatchListDetail: (watchlistId) => {
        const url = `/watchlist/${watchlistId}`;
        return axiosClient.get(url);
    }
};

export default watchlistApi;