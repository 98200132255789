import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useAccordionToggle } from 'react-bootstrap/esm/AccordionToggle';
import { AccordionContext } from 'react-bootstrap';

CustomFilterConditionToggle.propTypes = {
    
};

function CustomFilterConditionToggle({ children, eventKey, callback } ) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
        type="button"
        onClick={decoratedOnClick}
        className='border-0 bg-transparent d-flex mr-sm-3'
        >
        <div className='w-100 d-flex justify-content-center align-items-center' style={{color:'#7621B1'}}>
            <span className='font-weight-bolder'>
                {isCurrentEventKey? 'Thu Gọn' : 'Mở Rộng'}
            </span>
            <i style={{color:'#7621B1'}} className={`fas ${isCurrentEventKey ? 'fa-caret-up' : 'fa-caret-down'}  ml-1`}></i>
        </div>
        </button>
    );
}

export default CustomFilterConditionToggle;