import watchlistApi from "api/watchlistApi";
import { AppLayout } from 'general/components/AppLayout';
import { convertNwSeToNeSw } from "google-map-react";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Lay ds watch list

export const thunkGetAllWithDetail = createAsyncThunk('watchlist/all_with_detail', async (params) => {
    const res = await watchlistApi.getWatchList(params);
    let watchlists = [];
    if(res.result === 'success') watchlists = res.watchlists;

    for(let i = 0; i < watchlists.length; i++){
        const res = await watchlistApi.getWatchListDetail(watchlists[i].watchlistId);
        if(res.result === 'success') watchlists[i] = {...watchlists[i], listStock: res.detail}
        else watchlists[i] = {...watchlists[i], listStock: []}
    }

    return watchlists;
});

export const thunkCreateWatchList = createAsyncThunk('watchlist/create', async (name) => {
    const res = await watchlistApi.createWatchList(name);
    return res;
});

export const thunkDeleteWatchList = createAsyncThunk('watchlist/delete', async (id) => {
    const res = await watchlistApi.deleteWatchList(id);
    return res;
});

export const thunkRenameWatchList = createAsyncThunk('watchlist/rename', async (params) => {
    const { watchlistId, newName } = params;
    const res = await watchlistApi.renameWatchList(watchlistId, newName);
    return res;
});

export const thunkAddStock = createAsyncThunk('watchlist/add_stock', async (params) => {
    const { watchlistId, stockCode } = params;
    const res = await watchlistApi.addStock(watchlistId, stockCode);
    return res;
});

export const thunkRemoveStock = createAsyncThunk('watchlist/remove_stock', async (params) => {
    const { watchlistStockId } = params;
    const res = await watchlistApi.removeStock(watchlistStockId);
    return res;
});

const watchlistSlice = createSlice({
    name: 'watchlist',
    initialState: {
        isLoadingWatchList: false,
        listWatchList: [],
        selectedWatchlist: null,
    },
    reducers: {
        setSelectedWatchlist: (state, action) => {
            state.selectedWatchlist = action.payload;
        },
    },
    extraReducers: {
        // Lay danh sach Watchlist cung voi danh sach CK cua Watchlist
        [thunkGetAllWithDetail.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkGetAllWithDetail.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkGetAllWithDetail.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            state.listWatchList = action.payload;
            if (state.selectedWatchlist === null && action.payload.length > 0) {
                state.selectedWatchlist = action.payload[0];
            }
        },

        // Tao watchlist
        [thunkCreateWatchList.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkCreateWatchList.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkCreateWatchList.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            const payload = action.payload;
            const { result, detail } = payload;
            if (result === 'success') {
                detail.listStock = [];
                state.listWatchList = [detail, ...state.listWatchList];
                state.selectedWatchlist = detail;
            }
        },

        // Xoa watchlist
        [thunkDeleteWatchList.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkDeleteWatchList.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkDeleteWatchList.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            const result = action.payload.result;
            const watchlistId = action.meta.arg;
            if (result === 'success') {                
                state.listWatchList = state.listWatchList.filter(item => !(watchlistId === item.watchlistId));
                if(watchlistId === state.selectedWatchlist.watchlistId) state.selectedWatchlist = state.listWatchList[0];
            }
        },

        // Rename watchlist
        [thunkRenameWatchList.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkRenameWatchList.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkRenameWatchList.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            const result = action.payload.result;
            const { watchlistId, newName } = action.meta.arg;
            if (result === 'success') {
                for (let i = 0; i < state.listWatchList.length; i++)
                    if (state.listWatchList[i].watchlistId === watchlistId) {
                        state.listWatchList[i].name = newName

                        if (state.selectedWatchlist.watchlistId === watchlistId)
                            state.selectedWatchlist.name = newName
                        break
                    }
            }
        },

        // Add Stock
        [thunkAddStock.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkAddStock.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkAddStock.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            const payload = action.payload;
            let { result, detail, stock } = payload;            

            if (result === 'success') {
                    detail = {...detail, stock: stock};
                for(let i = 0; i < state.listWatchList.length; i++){
                    if(detail.watchlistId === state.listWatchList[i].watchlistId){
                        state.listWatchList[i].listStock = [detail, ...state.listWatchList[i].listStock];

                        if(detail.watchlistId === state.selectedWatchlist.watchlistId)
                            state.selectedWatchlist.listStock = [detail, ...state.selectedWatchlist.listStock];
                        break;
                    }
                }
            }
        },

        // Remove Stock
        [thunkRemoveStock.pending]: (state, action) => {
            state.isLoadingWatchList = true;
        },
        [thunkRemoveStock.rejected]: (state, action) => {
            state.isLoadingWatchList = false;
        },
        [thunkRemoveStock.fulfilled]: (state, action) => {
            state.isLoadingWatchList = false;
            const { result } = action.payload;
            const { watchlistStockId } = action.meta.arg
            let newListStock = []
            let check = false
            if (result === 'success') {
                console.log('helo1');
                for(let i = 0; i < state.listWatchList.length; i++){
                    // newListStock = state.listWatchList[i].listStock.filter(item => !(item.watchlistStockId === watchlistStockId));
                    for(let j = 0; j < state.listWatchList[i].listStock.length; j++)
                        if(state.listWatchList[i].listStock[j].watchlistStockId === watchlistStockId) {
                            state.listWatchList[i].listStock.splice(j, 1);
                            check = true;
                            break;
                        }
                    if(check) {
                        if(state.listWatchList[i].watchlistId === state.selectedWatchlist.watchlistId)
                            state.selectedWatchlist = state.listWatchList[i];
                        break;
                    }

                    
                }
            }
        },
    },
});

const { reducer, actions } = watchlistSlice;
export const { setSelectedWatchlist } = actions;
export default reducer;