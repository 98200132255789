import PropTypes from 'prop-types';

EmptyView.propTypes = {
    iconEmpty: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonIcon: PropTypes.string,
    buttonText: PropTypes.string,
    onPressButton: PropTypes.func,
    loading: PropTypes.bool,
};

EmptyView.defaultProps = {
    iconEmpty: null,
    title: '',
    description: '',
    buttonIcon: '',
    buttonText: '',
    onPressButton: null,
    loading: false,
};

function EmptyView(props) {
    // MARK: --- Params ---
    const { iconEmpty, title, description, buttonIcon, buttonText, onPressButton, loading } = props;

    // MARK: --- Functions ---
    function handlePress() {
        if (onPressButton) {
            onPressButton();
        }
    }

    return (
        <div className='d-flex flex-column align-items-center'>
            {
                iconEmpty && (
                    <img
                        className=''
                        alt='icon'
                        draggable={false}
                        src={iconEmpty}
                    />
                )
            }
            <div className='d-flex flex-column align-items-center my-5'>
                {
                    loading ? (
                        <div className='d-flex align-items-center'>
                            <div className='mr-4'>Đang lấy dữ liệu...</div>
                            <span><div className='spinner'></div></span>
                        </div>
                    ) : (
                        <div>
                            <h2 className='font-weight-boldest text-center'>{title}</h2>
                            <p className='text-center font-size-lg'>{description}</p>
                        </div>
                    )
                }
            </div>
            {
                buttonText && buttonText.length > 0 && (
                    <button
                        type="button"
                        className="btn btn-outline-secondary font-weight-bold"
                        onClick={handlePress}
                    >
                        {
                            buttonIcon && buttonIcon.length > 0 && (
                                <i className={`${buttonIcon} mr-2`} />
                            )
                        }
                        {buttonText}
                    </button>
                )
            }
        </div>
    );
}

export default EmptyView;