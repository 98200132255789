import ideaListApi from 'api/ideaListApi';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import IdeaListHeaderView from '../IdeaListHeaderView';

MGListStock.propTypes = {
    onMenuHandle: PropTypes.func,
    title: PropTypes.string,
    listName: PropTypes.string,
};

MGListStock.defaultProps = {
    onMenuHandle: null,
    title: '',
    listName: '',
}

function MGListStock(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle, title, listName } = props;
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
    });
    const [pagination, setPagination] = useState(null);
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // MARK: --- Functions ---
    async function getIdeaListData() {
        setLoading(true);
        setStocks([]);
        const res = await ideaListApi.getList(listName, filter);
        const { result, total, count, page, ideaList } = res;
        if (result === 'success' && ideaList) {
            const ideaListStocks = ideaList.map(item => item.stock);
            if (page === 0) {
                setStocks(ideaListStocks);
            } else {
                setStocks(stocks.concat(ideaListStocks));
            }
            setPagination({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoading(false);
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(pagination);
            if (nextPage) {
                setFilter({
                    ...filter,
                    page: nextPage,
                });
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getIdeaListData();
    }, [filter, listName]);

    return (
        <div>
            <IdeaListHeaderView
                title={title}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
            />

            <div className='overflow-auto max-h-600px' onScroll={handleScroll}>
                <MGDataTable
                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                    loading={loading}
                    headItems={[
                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                        { title: t('Price'), subTitle: 'VNĐ', col: 'col-1' },
                        { title: t('Score'), subTitle: 'MoneyGain', col: 'col-1' },
                        { title: t('Score'), subTitle: t('Demand'), col: 'col-1' },
                        { title: t('Score'), subTitle: t('Group'), col: 'col-1' },
                        { title: t('Strength'), subTitle: 'EPS', col: 'col-1' },
                        { title: t('Strength'), subTitle: t('Price'), col: 'col-1' },
                    ]}
                    dataItems={
                        stocks.map((item, index) => {
                            return ({
                                onPress: () => {
                                    history.push(`/stock/${item.stockCode}`);
                                },
                                data: [
                                    { icon: '', title: item.stockCode, subTitle: item.stockName, name: '', value: '', valueColor: '', col: 'col-3' },
                                    { icon: '', title: '', name: Utils.formatNumber(item?.price), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                    { icon: '', title: '', name: Utils.formatNumber(item?.mgScore), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                    { icon: '', title: '', name: item?.demandScore, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                    { icon: '', title: '', name: Utils.formatNumber(item?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                    { icon: '', title: '', name: Utils.formatNumber(item?.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                    { icon: '', title: '', name: item?.priceStrength, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                ]
                            })
                        })
                    }
                />
            </div>
        </div>
    );
}

export default MGListStock;