import axiosClient from "./axiosClient";

const webinarApi = {
    // Get webinar list
    getWebinarList: (params) => {
        const url = '/webinar/all';
        return axiosClient.get(url, { params });
    },

    // Get webinar info by id
    getWebinarInfo: (webinarId) => {
        const url = `/webinar/${webinarId}`;
        return axiosClient.get(url);
    },

    // Get webinar session list
    getWebinarSessions: (webinarId, params = {}) => {
        const url = `/webinar_session/all/${webinarId}`;
        return axiosClient.get(url, { params })
    },

    joinInWebinar: (webinarId, question) => {
        const url = `/webinar/join_in`;
        return axiosClient.post(url, {
            webinarId: webinarId,
            question: question,
        })
    }
};

export default webinarApi;
