import React from 'react';
import PropTypes from 'prop-types';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import './style.scss'
import Utils from 'general/utils/Utils';

CellProduct.propTypes = {

};

function CellProduct(props) {


    // MARK: --- Functions ---
    function handlePress(e) {
        e.preventDefault();
        if (url) {
            Utils.openInNewTab(url);
        }
    }
    const { title, description, thumbnail, url } = props
    return (
        <div className='col-lg-4 col-md-6 col-12 py-5'>
            <a
                href='#'
                className={`CellMGProductGrid CellProduct d-flex flex-column align-items-center justify-content-center w-100 h-200px hover-opacity-40 rounded-lg`}
                onClick={handlePress}
            >
                <img
                    className='CellMGProductGrid_Icon'
                    draggable={false}
                    src={thumbnail ? Utils.getFullUrl(thumbnail) : AppIcons.icMGProduct}
                    alt='ic_mg_product'
                    style={{
                        objectFit: "cover",

                        height: "50%"
                    }}
                    onError={(e) => {
                        e.target.src = AppIcons.icMGProduct;
                    }}
                />
            </a>
            <p className='CellProduct_Title pt-3'>{title}</p>
            <p className='CellProduct_Description'>{description}</p>
        </div>
    );
}

export default CellProduct;