import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBannerCurrentIndex, thunkGetBanners } from "app/appSlice";
import { Banner, BannerType } from "general/types/index.d";
import { Carousel, CarouselItem } from "react-bootstrap";
import Utils from "general/utils/Utils";

AppBanner.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

AppBanner.defaultProps = {
  className: "h-180px",
  text: "Banner",
};

/**
 *
 * @param {{className:string,text:string,type:BannerType}} props
 * @returns
 */
function AppBanner(props) {
  // MARK: --- Params ---
  const { className, text, type } = props;
  const { t } = useTranslation();
  const { list, currentIndex }: { list: Banner[], currentIndex: Number } =
    useSelector(
      (state) =>
        state.app.banners[type ?? "BANNER_9_16"] ?? {
          list: [],
          currentIndex: 0,
        }
    );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(thunkGetBanners());
  }, []);

  let aspectRatio = 16 / 9;
  switch (type) {
    case "BANNER_3_4":
      aspectRatio = 3 / 4;
      break;
    case "DEFAULT":
      aspectRatio = 1;
      break;
  }

  return (
    <div
      style={{ overflow: "hidden", aspectRatio }}
      className={`AppBanner d-flex align-items-center justify-content-center ${className}`}
    >
      {list.length == 0 ? (
        <p className="m-0 font-size-h4 font-weight-bolder text-center AppBanner_Text">
          {t("Banner")}
          <br />
          {text}
        </p>
      ) : (
        <Carousel
          interval={3500}
          pause="hover"
          // indicators={false}
          tabIndex={currentIndex}
          onSelect={(selectIndex, e) => {
            dispatch(setBannerCurrentIndex({ type, index: selectIndex }));
          }}
          className="w-100 justify-content-center align-content-center"
          nextIcon={
            // <div className="w-40px h-40px">
            //   <i className="fas fa-arrow-right text-primary text-hover-white w-100 h-100 d-flex align-items-center justify-content-center"></i>
            // </div>
            null
          }
          prevIcon={
            // <div className=" w-40px h-40px">
            //   <i className="fas fa-arrow-left text-primary text-hover-white w-100 h-100 d-flex align-items-center justify-content-center"></i>
            // </div>
            null
          }
        >
          {list.map((banner, index) => {
            return (
              <CarouselItem key={index} className="w-100 justify-content-between align-content-center">
                <a href={banner.url} target={"_blank"}>
                  <img
                    className="w-100"
                    draggable={false}
                    //   style={{ aspectRatio }}
                    src={Utils.getFullUrl(banner.image)}
                  />
                </a>
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

export default AppBanner;
