import React from 'react';
import PropTypes from 'prop-types';
import { AppImages } from 'general/constants/AppResource';
import './style.scss'

CellOfLandingPageSectionIntro2.propTypes = {

};

function CellOfLandingPageSectionIntro2(props) {
    const { image, title, detail } = props

    return (
        <div className='CellOfLandingPageSectionIntro2'>
            <img
                src={image}
                alt="icon"
                className='w-80px h-80px'
                style={{
                    objectFit: 'contain'
                }}
                draggable={false}
            />
            <div className='mt-10'>
                <p className='CellOfLandingPageSectionIntro2_Title text-white mb-4'>{title}</p>
                <p className='CellOfLandingPageSectionIntro2_Detail text-white text-justify' style={{ lineHeight: '140%' }}>{detail}</p>
            </div>
        </div>
    );
}

export default CellOfLandingPageSectionIntro2;