import axiosClient from "./axiosClient";

const bankAccountApi = {
    getList: (params) => {
        const url = '/bankAccount/all';
        return axiosClient.get(url, { params });
    },
};

export default bankAccountApi;
