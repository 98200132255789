import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BaseSearchBar from '../Forms/FormControls/BaseSearchBar';
import { Tab, Tabs } from 'react-bootstrap';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import CellStockList from 'features/Stock/components/CellStockList';
import EmptyView from '../Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import stockApi from 'api/stockApi';
import { useDispatch } from 'react-redux';
import { setCurrentSelectedStockInfo } from 'features/Stock/stockSlice';

let KTOffcanvas = require("assets/plugins/ktoffcanvas");

AppStockSearch.propTypes = {
    onOffcanvasReady: PropTypes.func,
};

AppStockSearch.defaultProps = {
    onOffcanvasReady: null,
};

function AppStockSearch(props) {
    // MARK: --- Params ---
    const { onOffcanvasReady } = props;
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const { t } = useTranslation();
    const refOffcanvasSearch = useRef(null);
    const [activeTab, setActiveTab] = useState('tab_stock');
    const refScrollContent = useRef(null);
    const dispatch = useDispatch();

    const [filterStock, setFilterStock] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
        searchKey: '',
    });
    const [loadingStock, setLoadingStock] = useState(false);
    const [stocks, setStocks] = useState([]);
    const [paginationStock, setPaginationStock] = useState(null);
    const refSavedScrollTopStock = useRef(0);

    // MARK: --- Functions ---
    function handleOffcanvasReady() {
        if (onOffcanvasReady) {
            onOffcanvasReady(refOffcanvasSearch.current);
        }
    }

    // search stock
    async function searchStock() {
        setLoadingStock(true);
        const res = await stockApi.getListStockCode(filterStock);
        const { result, total, count, page } = res;
        const searchedStocks = res.stocks;
        if (result === 'success' && searchedStocks) {
            if (page > 0) {
                setStocks(stocks.concat(searchedStocks));
            } else {
                setStocks(searchedStocks);
            }
            setPaginationStock({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoadingStock(false);
    }

    // handle scroll content
    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 20;
        if (bottom) {
            if (activeTab === 'tab_stock' && !loadingStock) {
                let nextPage = Utils.getNextPage(paginationStock);
                // console.log({ nextPage });
                if (nextPage) {
                    setFilterStock({
                        ...filterStock,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (typeof KTOffcanvas !== "undefined" && refOffcanvasSearch.current === null) {
            let offCanvasObject = new KTOffcanvas("quick_app_search_aside", {
                baseClass: "offcanvas",
                overlay: true,
                toggleBy: {
                    target: 'search-menu-handle',
                }
            });
            refOffcanvasSearch.current = offCanvasObject;
            handleOffcanvasReady();
        }
    }, []);

    useEffect(() => {
        searchStock();
    }, [filterStock]);

    useEffect(() => {
        if (refScrollContent.current) {
            if (activeTab === 'tab_stock') {
                refScrollContent.current.scrollTop = refSavedScrollTopStock.current;
            }
        }
    }, [activeTab]);

    return (
        <div className='AppStockSearch offcanvas offcanvas-right bg-white pt-8 d-flex flex-column' id='quick_app_search_aside'>
            <div className='d-flex align-items-center justify-content-between mb-2 px-5'>
                <h3 className='font-weight-bold'>
                    {t('Search')}
                    <small className="text-muted font-size-base ml-2">{`${t('Stock')}, ${t('Company')}`}</small>
                </h3>
                <a
                    href="#"
                    className="btn btn-xs btn-icon btn-light btn-hover-secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        refOffcanvasSearch.current.hide();
                    }}
                >
                    <i className="far fa-times"></i>
                </a>
            </div>

            <div className='px-5'>
                <BaseSearchBar
                    name='searchBar'
                    className=''
                    placeholder={`${t('TypeToSearch')}...`}
                    onSubmit={(text) => {
                        // console.log({ text });
                        setFilterStock({
                            ...filterStock,
                            page: 0,
                            searchKey: text,
                        })
                    }}
                    noBorder
                />
            </div>

            <hr className='w-100 mb-0 mt-2' />

            <div className='flex-grow-1 overflow-auto pt-5 q-scrollbar-thin' ref={refScrollContent} onScroll={handleScroll}>
                <div className='px-0'>
                    <Tabs
                        defaultActiveKey='tab_stock'
                        activeKey={activeTab}
                        className='border-0'
                    >
                        <Tab eventKey='tab_stock' className=''>
                            <div>
                                {
                                    stocks.length > 0 && stocks.map((item, index) => {
                                        return (
                                            <CellStockList
                                                key={index}
                                                item={item}
                                                onPress={() => {
                                                    dispatch(setCurrentSelectedStockInfo(item))
                                                    if (refOffcanvasSearch.current) {
                                                        refOffcanvasSearch.current.hide();
                                                    }
                                                    history.push(`/stock/${item.stockCode}`);
                                                }}
                                                showDivider={index < stocks.length - 1}
                                                className='px-5'
                                            />
                                        )
                                    })
                                }

                                {
                                    stocks.length === 0 && (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default AppStockSearch;