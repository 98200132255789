import CustomToggleButton from "features/Payment/components/CustomToggleButton";
import _ from 'lodash';
import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Utils from "../../../../../../general/utils/Utils";

BankInfor.propTypes = {};

/**
 * 
 * @param {{onBankAccountSelected:({bankAccountId,bankName})=>void}} props 
 * @returns 
 */
function BankInfor(props) {
  const { t } = useTranslation();
  const [accordionCheck, setAccordionCheck] = React.useState(null);
  const [bankIndex, setBankIndex] = React.useState(0);
  const [bankBorderCheck, setBankBorderCheck] = React.useState(null);
  const bankList = useSelector(state => state?.payment?.bankAccount?.list)
  const { onBankAccountSelected, productInfo } = props

  return (
    <Accordion defaultActiveKey="0">
      <Card className="border-0">
        <Card.Header>
          <CustomToggleButton
            btnColor={" btn-light"}
            eventKey="0"
            onClick={() => console.log("dung")}
            borderShape={" rounded-0"}
          >
            <p className=" font-weight-bolder font-size-base text-left darkBlue">
              {t("BankTransfer")}
            </p>
            <p
              className="font-weight-normal font-size-base text-left"
              style={{ color: "#5C6077" }}
            >
              {t("BankTransferDescription")}
            </p>
          </CustomToggleButton>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="bg-light p-0">
            <div
              className="w-100 mb-2 "
              style={{
                height: "1px",
                backgroundColor: "rgba(10, 3, 60, 0.15) ",
              }}
            />
            <Accordion activeKey={accordionCheck}>
              <Card className="border-0">
                <Card.Header className="border-0">
                  <div className="container mt-2 ">
                    <div className="row">
                      {bankList.map((bank: { bankAccountId: Number, logo: String, bankName: String }, index) => {
                        return (
                          <div
                            key={index}
                            className="col-xl-3 col-md-6 col-sm-6 col-6 mb-5 d-flex justify-content-center"
                          >
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="0"
                              onClick={() => {
                                setAccordionCheck("0");
                                setBankIndex(index);
                                setBankBorderCheck(index);
                                _.isFunction(onBankAccountSelected) && onBankAccountSelected(bank)
                              }}
                              className="p-0 rounded-0 "
                              style={
                                bankBorderCheck === index
                                  ? {
                                    borderColor: "#7621b1",
                                  }
                                  : {
                                    borderStyle: "none",
                                  }
                              }
                            >
                              <img src={Utils.getFullUrl(bank?.logo)} className="w-100 h-100 border rounded"
                                draggable={false} style={{
                                  objectFit: 'cover',
                                  aspectRatio: '16/10'
                                }} />
                            </Accordion.Toggle>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="bg-light">
                    <p className="font-weight-bolder font-size-base line-height-lg darkBlue">
                      {t("PlsTransfer")} {Utils.formatNumber(productInfo?.price)} {t("ToAccount")}
                    </p>
                    <ul className="mt-1">
                      <li className="font-weight-normal font-size-base line-height-md darkBlue">
                        {t("BankName")} :{" "}
                        <span className="purple">
                          {bankList[bankIndex]?.bankName}
                        </span>
                      </li>
                      <li className="font-weight-normal font-size-base line-height-md darkBlue">
                        {t("AccountNumber")} :{" "}
                        <span className="purple">
                          {bankList[bankIndex]?.accountNumber}
                        </span>
                      </li>
                      <li className="font-weight-normal font-size-base line-height-md darkBlue">
                        {t("AccountName")} :{" "}
                        <span className="purple">{bankList[bankIndex]?.accountName}</span>
                      </li>
                    </ul>
                    <p className="font-weight-normal font-size-base line-height-md darkBlue">
                      {t("BankTransferInstruction")}
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default BankInfor;
