import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AppLogo from '../AppLogo';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import UserHelper from 'general/helpers/UserHelper';
import QuickAppSiteMenu from '../QuickAppSiteMenu';
import QuickUser from '../QuickUser';
import { useHistory } from 'react-router-dom';
import AppStockSearch from '../AppStockSearch';

AppMobileSiteMenu.propTypes = {
    onPressSearch: PropTypes.func,
    onPressWatchlist: PropTypes.func,
    onPressNotification: PropTypes.func,
};

AppMobileSiteMenu.defaultProps = {
    onPressSearch: null,
    onPressWatchlist: null,
    onPressNotification: null,
};

function AppMobileSiteMenu(props) {
    // MARK: --- Params ---
    const { onPressSearch, onPressWatchlist, onPressNotification } = props;
    const loggedInUser = useSelector(state => state.auth.current);
    const history = useHistory();
    const refOffcanvasQuickUser = useRef(null);
    const totalUnreadNotifications = useSelector(state => state.notification.totalUnread);

    return (
        <div className='AppMobileSiteMenu min-h-55px d-flex align-items-center px-4'>
            <div className='d-flex align-items-center justify-content-center'>
                {/* Menu handle */}
                <button className="btn btn-icon hover-opacity-40 rounded-0 mr-4" id='site-menu-handle'>
                    <img alt='menu-handle'
                        draggable={false} src={AppIcons.icMenuHandle} />
                </button>
                <AppLogo
                    showOnlyLogo={true}
                    logoClassName='max-h-30px'
                />
            </div>

            <div className='d-flex flex-grow-1 align-items-center justify-content-end'>
                <div className='d-flex align-items-center'>
                    {/* button notification */}
                    <div className='mr-1 pr-3 position-relative'>
                        <button
                            className="btn btn-icon hover-opacity-40 rounded-0"
                            id='notification-menu-handle'
                            onClick={() => {
                                if (onPressNotification) {
                                    onPressNotification();
                                }
                            }}
                        >
                            <i className="fal fa-bell text-white"></i>
                        </button>
                        {
                            totalUnreadNotifications > 0 && (
                                <span className="label label-danger position-absolute" style={{ top: '-2px', right: '12px', pointerEvents: 'none' }}>{`${totalUnreadNotifications > 9 ? '9+' : totalUnreadNotifications}`}</span>
                            )
                        }
                    </div>
                    {/* button watch list */}
                    <button
                        className="btn btn-icon hover-opacity-40 rounded-0 mr-1 pr-3"
                        id='watchlist-menu-handle'
                        onClick={() => {
                            if (onPressWatchlist) {
                                onPressWatchlist();
                            }
                        }}
                    >
                        <i className="fal fa-list-alt text-white"></i>
                    </button>
                    {/* Button search */}
                    <button
                        className="btn btn-icon hover-opacity-40 rounded-0 mr-5 pr-4"
                        id='search-menu-handle'
                        onClick={() => {
                            if (onPressSearch) {
                                onPressSearch();
                            }
                        }}
                        style={{
                            borderRightWidth: '1',
                            borderRightColor: 'rgba(255, 255, 255, 0.2)'
                        }}
                    >
                        <i className="far fa-search text-white"></i>
                    </button>
                </div>

                {/* Menu handle */}
                {/* <button className="btn btn-icon hover-opacity-40 rounded-0 mr-4" id='site-menu-handle'>
                    <img alt='menu-handle' src={AppIcons.icMenuHandle} />
                </button> */}

                {/* User avatar */}
                <div
                    className="btn btn-icon hover-opacity-40 w-auto d-flex align-items-center btn-lg"
                    id={!Utils.isObjectEmpty(loggedInUser) ? "mg_quick_user_toggle" : ''}
                    onClick={() => {
                        if (refOffcanvasQuickUser.current) {
                            refOffcanvasQuickUser.current.show();
                        }
                    }}
                >
                    <div className="symbol symbol-circle symbol-30 bg-white overflow-hidden border">
                        <div className="symbol-label">
                            <img
                                alt="Avatar" src={!Utils.isObjectEmpty(loggedInUser) ? Utils.getFullUrl(UserHelper.getAvatar(loggedInUser)) : AppImages.imgDefaultAvatar}
                                className="w-100"
                                draggable={false}
                                style={{ objectFit: 'cover' }}
                                onError={(e) => {
                                    e.target.src = UserHelper.getRandomAvatarUrl();
                                }}
                                onClick={() => {
                                    if (Utils.isObjectEmpty(loggedInUser)) {
                                        history.push('/sign-in');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <QuickAppSiteMenu />
            <QuickUser onOffcanvasReady={(offcanvas) => {
                refOffcanvasQuickUser.current = offcanvas;
            }} />
        </div>
    );
}

export default AppMobileSiteMenu;