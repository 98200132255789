import React from 'react';
import PropTypes from 'prop-types';
import { AppImages } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils'

CellWebinarSessionList.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
    showDivider: PropTypes.bool,
};

CellWebinarSessionList.defaultProps = {
    item: {},
    onPress: null,
    showDivider: false,
};

function CellWebinarSessionList(props) {
    // MARK: --- Params ---
    const { item, onPress, showDivider } = props;
    const { name, video, thumbnail, time, description } = item;

    function handleClick() {
        if (onPress) {
            onPress();
        }
    }

    return (
        <div
            className={`CellWebinarSessionList d-flex cursor-pointer hover-opacity-60 ${showDivider && 'border-bottom pb-5'}`}
            onClick={handleClick}
        >
            <img
                className='w-160px h-100px rounded min-w-160px min-h-100px border'
                alt='thumbnail'
                draggable={false}
                style={{ objectFit: 'cover' }}
                src={Utils.getFullUrl(thumbnail ?? AppImages.imgDefaultThumbnail)}
                onError={(e) => {
                    e.target.src = AppImages.imgDefaultThumbnail;
                }}
            />
            <div className='ml-4'>
                <p className='font-size-h5 font-weight-bolder m-0 q-max-line-2'>{name}</p>
                <p className='font-size-lg m-0 q-max-line-2'>{description}</p>
            </div>
        </div>
    );
}

export default CellWebinarSessionList;