import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Accordion, Card, Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MGDataTable from "../../../../general/components/Tables/MGDataTable";
import EmptyView from "../../../../general/components/Views/EmptyView";
import { AppIcons } from "../../../../general/constants/AppResource";
import Utils from "../../../../general/utils/Utils";
import {
    setCurrentSelectedFilterMG,
    thunkGetAllMGFilter,
    thunkGetFilterResultByFilterId, thunkGetMGFilterDetail,
    thunkLoadMoreFilterResultByFilterId
} from "../../IdeaListSlice";
import CustomFilterConditionToggle from "../BuildYourScreen/CustomFilterConditionToggle";
import MGFilterLine from "../BuildYourScreen/MGFilterLine";
import IdeaListHeaderView from '../IdeaListHeaderView';

FilterStockMG.propTypes = {
    onMenuHandle: PropTypes.func,
};

FilterStockMG.defaultProps = {
    onMenuHandle: null,
}

function FilterStockMG(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const history = useHistory();
    // const [filterNameChoosen, setFilterNameChoosen] = useState('')
    // const [currentFilter, setCurrentFilter] = useState(null)
    const currentFilter = useSelector(state => state.ideaList.currentSelectedFilterMG);

    const yourFilter = useSelector(state => state?.ideaList?.mgFilter?.currentFilter)
    const filterResult = useSelector(state => state?.ideaList?.mgFilter?.filterResult)
    const listFilters = useSelector(state => state?.ideaList?.mgFilter?.listFilters)
    const isLoggedIn = useSelector(state => state?.auth?.current)


    let showData = []
    filterResult?.result?.map((item) => {
        showData.push({
            onPress: () => {
                history.push(`/stock/${item.stockCode}`);
            },
            data: [
                {
                    title: item?.stockCode,
                    titleColor: '#7621B1',
                    titleWeight: '600',
                    subTitle: '',
                    name: '',
                    value: '',
                    valueColor: '#7621B1',
                    col: 'col-1'
                },
                { name: item?.stock?.exchangeCode, nameColor: '', value: '', valueColor: 'green', col: 'col-1' },
                { name: item?.stock?.price, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRoa, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRos, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.yearRoe, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.mgScore, nameColor: '', value: '', valueColor: '', col: 'col-1' },
                { name: item?.stock?.epsStrength, nameColor: '', value: '', valueColor: '', col: 'col-1' },
            ]
        })
    })
    const [activeKey, setActiveKey] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        if (currentFilter?.filterId) {
            setActiveKey(true)
            dispatch(thunkGetFilterResultByFilterId({
                filterId: currentFilter?.filterId,
            }))
            dispatch(thunkGetMGFilterDetail({ "filterId": currentFilter?.filterId }))
        }
    }, [currentFilter])

    useEffect(() => {
        if (listFilters.length === 0) {
            dispatch(thunkGetAllMGFilter())
        }
    }, [isLoggedIn])

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 10;
        let currentPage = filterResult?.pagination?.currentPage
        let isGettingResult = filterResult?.isGettingResult
        let hasMore = filterResult?.pagination?.hasMore ?? false
        if (bottom && hasMore && (!isGettingResult)) {
            dispatch(thunkLoadMoreFilterResultByFilterId({
                filterId: currentFilter?.filterId,
                page: currentPage
            }))
        }
    }


    return (
        <div>
            <IdeaListHeaderView
                title={t('StockFilterMG')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
            />

            {(Utils.isObjectNull(isLoggedIn) || Utils.isObjectEmpty(isLoggedIn))
                ?
                <div className='d-flex align-items-center justify-content-around px-5 flex-grow-1 bg-light min-h-600px'>
                    <EmptyView
                        iconEmpty={AppIcons.icCircleUser}
                        title={t('SignIn')}
                        description={t('ListFiltersNotLoggedInDescription')}
                        buttonText={t('SignIn')}
                        buttonIcon='far fa-sign-in'
                        onPressButton={() => {
                            history.push('/sign-in');
                        }}
                    />
                </div>
                : <div className='BuildYourScreen bg-light py-5'>
                    <div className='mx-5 border bg-white'>

                        {/* line 1 */}

                        <div className='font-size-lg font-weight-bolder px-3 py-3'>
                            <Dropdown className=''>
                                <Dropdown.Toggle
                                    className='font-weight-bolder font-size-base px-1 py-2 d-flex align-items-center'
                                    variant=''
                                >
                                    <span className='px-0 py-0'>{currentFilter ? currentFilter?.filterName : t('filterName')}</span>
                                    <i className="text-dark ml-3 fas fa-caret-down px-0 py-0"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='w-250px py-0'>
                                    {listFilters.length == 0
                                        ? <div
                                            className='d-flex align-items-center justify-content-center py-10 font-weight-light'>
                                            <EmptyView
                                                iconEmpty={AppIcons.icEmptyPack}
                                                description={t('NoDataToDisplay')}
                                            />
                                        </div>
                                        : <div>
                                            <div
                                                className='font-weight-bolder border-bottom pl-5 py-4'>{t('listFilter')}</div>
                                            <div style={{ maxHeight: 199, overflowY: 'auto', overflowX: 'hidden' }}>
                                                {
                                                    listFilters.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    dispatch(setCurrentSelectedFilterMG(item));
                                                                    // setCurrentFilter(item);
                                                                    // dispatch(thunkGetMGFilterDetail({ "filterId": item.filterId }))
                                                                }}
                                                                key={index}
                                                                className={`d-flex flex-row justify-content-between border-bottom  btn-light ${index == listFilters.length - 1 && 'border-bottom-0'}`}>
                                                                <div>
                                                                    <img src={AppIcons.icSquareList} alt="icon"
                                                                        draggable={false}
                                                                        className='mr-3' />
                                                                    <span>{item.filterName}</span>
                                                                </div>
                                                                {(!Utils.isObjectNull(currentFilter) && currentFilter.filterId === item.filterId) && <i className="text-success far fa-check"></i>}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Accordion activeKey={activeKey}>
                            <Card className='border-0 rounded-0'>
                                <Accordion.Collapse eventKey={activeKey}>
                                    <Card.Body className='p-0 ' style={{ minHeight: 0 }}>
                                        {
                                            yourFilter?.map((item) => {
                                                return (
                                                    <MGFilterLine conditional={item} />
                                                )
                                            })
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                                <Card.Header
                                    className='bg-white border-top d-flex py-3 justify-content-between align-items-center px-4'>
                                    <div>
                                        <p className='font-weight-bolder font-size-lg'>Số lượng thỏa mãn tất cả điều
                                            kiện</p>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <span className='font-weight-bolder mr-2' style={{
                                                fontSize: '2.46rem',
                                                color: '#7621B1'
                                            }}>{(filterResult?.pagination?.total ?? 0) + " " + t("stockCodes")}</span>
                                        </div>
                                    </div>
                                    <CustomFilterConditionToggle eventKey={activeKey} callback={(key) => {
                                        setActiveKey(!activeKey)
                                    }} />
                                </Card.Header>
                            </Card>
                        </Accordion>
                    </div>
                    <div className='m-5 border bg-white d-block overflow-auto max-h-600px' onScroll={handleScroll}>
                        <p className='border-bottom font-size-lg font-weight-bolder pl-3 py-3'>Kết Quả</p>
                        <MGDataTable
                            className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                            headItems={[
                                { title: 'Mã', subTitle: 'Chứng khoán', col: 'col-1' },
                                { title: 'Sàn', subTitle: '', col: 'col-1' },
                                { title: 'Giá', subTitle: 'VND', col: 'col-1' },
                                { title: 'YearROA', subTitle: '%', col: 'col-1' },
                                { title: 'YearROE', subTitle: '%', col: 'col-1' },
                                { title: 'YearROS', subTitle: '%', col: 'col-1' },
                                { title: 'Tổng Điểm', subTitle: 'MoneyGain', col: 'col-1' },
                                { title: 'Sức Mạnh', subTitle: 'EPS', col: 'col-1' },

                            ]}
                            dataItems={showData}
                        />
                    </div>
                </div>}

        </div>
    );
}

export default FilterStockMG;
