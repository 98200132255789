import axiosClient from "./axiosClient";

const bookApi = {
    // Get book list
    getBookList: (params) => {
        const url = '/knowledge/book/find';
        return axiosClient.post(url, params);
    },
};

export default bookApi;