import axiosClient from "./axiosClient";

const mgIdentifyApi = {
    // get identify list
    getIdentifyList: (params) => {
        const url = '/mgIdentify/find';
        return axiosClient.get(url, { params });
    },
};

export default mgIdentifyApi;