import axiosClient from "./axiosClient";

const courseApi = {
    getAll: (params) => {
        const url = `/course/get_courses`;
        return axiosClient.get(url, { params });
    },
    getDetail: (params) => {
        const url = `/course/detail/${params?.courseId}`;
        return axiosClient.get(url);
    },
    updateLessionProcess: (params) => {
        const url = `/course/lession_process/${params?.lessionId}`;
        return axiosClient.put(url,{
            duration: params.duration
        });
    },
};

export default courseApi;
