import { unwrapResult } from '@reduxjs/toolkit';
import { updateCurrentAccountInfo } from 'app/authSlice';
import { thunkUpdateAccountInfo } from 'features/Account/accountSlice';
import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import { useFormik } from 'formik';
import BaseCheckBox from 'general/components/Forms/FormControls/BaseCheckBox';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import DatePickerInputMask from 'general/components/Forms/FormWidgets/DatePickerInputMask';
import ImageInput from 'general/components/Forms/FormWidgets/ImageInput';
import AppRegex from 'general/constants/AppRegex';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

AccountInfoScreen.propTypes = {

};

const sTag = '[AccountInfoScreen]';

function AccountInfoScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const loggedInUser = useSelector(state => state.auth.current);
    const formik = useFormik({
        initialValues: {
            fullname: loggedInUser?.fullname,
            avatar: loggedInUser?.avatar,
            dob: loggedInUser?.dob ? moment(loggedInUser?.dob).format('DD/MM/YYYY') : null,
            phone: loggedInUser?.phone,
            earningNotification: loggedInUser?.earningNotification === 1,
            eventNotification: loggedInUser?.eventNotification === 1,
            newsNotification: loggedInUser?.newsNotification === 1,
            reportNotification: loggedInUser?.reportNotification === 1,
            stockAlertNotification: loggedInUser?.stockAlertNotification === 1,
            enableEmailNotification: loggedInUser?.enableEmailNotification === 1,
            enablePushNotification: loggedInUser?.enablePushNotification === 1,
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            requestUpdateAccountInfo(values);
        },
        validationSchema: Yup.object({
            phone: Yup.string().matches(AppRegex.phone, t('PhoneNotValid')).nullable(),
        }),
    });
    const { isUpdatingInfo } = useSelector(state => state.account);
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedImage = useRef(null);

    // MARK: --- Functions ---
    async function requestUpdateAccountInfo(values) {
        const params = { ...values };
        for (const [key, value] of Object.entries(params)) {
            if (value === true) {
                params[key] = 1;
            }
            if (value === false) {
                params[key] = 0;
            }
        }
        delete params['avatar'];
        if (selectedImage.current) {
            params['avatar'] = selectedImage.current;
        }
        if (!_.isEmpty(values.dob) && moment(values.dob, 'DD/MM/YYYY').isValid()) {
            params['dob'] = moment(values.dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
        } else {
            delete params['dob'];
        }

        Utils.formatEmptyKey(params);
        Utils.removeNullKey(params);
        // console.log({ params });
        // return;

        const accountId = loggedInUser.accountId;
        try {
            const res = unwrapResult(await dispatch(thunkUpdateAccountInfo({ userId: accountId, values: params })));
            const { result, account } = res;
            if (result === 'success') {
                if (account) {
                    dispatch(updateCurrentAccountInfo(account));
                }
                ToastHelper.showSuccess(t('UpdateAccountInfoSuccess'));
            }
        } catch (error) {
            console.log(`${sTag} update account info error: ${error.message}`);
        }
    }

    function handleImageSelected(image) {
        selectedImage.current = image;
    }

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className=''>
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{t('AccountInfoTitle').toUpperCase()}</p>
                    <p className='font-size-lg text-center m-0'>{t('AccountInfoDescription')}</p>
                </div>
                <div className='row mt-10'>
                    <div className='col-3'></div>
                    <div className='col'>
                        <form onSubmit={formik.handleSubmit}>
                            {/* Avatar */}
                            <div className='form-group d-flex align-items-center justify-content-center'>
                                <ImageInput
                                    name='avatar'
                                    // label={t('UpdateAvatar')}
                                    className=''
                                    circle={true}
                                    fieldHelper={formik.getFieldHelpers('avatar')}
                                    fieldProps={formik.getFieldProps('avatar')}
                                    fieldMeta={formik.getFieldMeta('avatar')}
                                    onImageSelected={handleImageSelected}
                                />
                            </div>

                            {/* Fullname */}
                            <div className='form-group'>
                                <BaseTextField
                                    name='fullname'
                                    placeholder={`${t('Fullname')}...`}
                                    label={t('Fullname')}
                                    fieldHelper={formik.getFieldHelpers('fullname')}
                                    fieldProps={formik.getFieldProps('fullname')}
                                    fieldMeta={formik.getFieldMeta('fullname')}
                                />
                            </div>
                            {/* Dob */}
                            <div className='form-group'>
                                <DatePickerInputMask
                                    name='dob'
                                    fieldHelpers={formik.getFieldHelpers('dob')}
                                    fieldMeta={formik.getFieldMeta('dob')}
                                    fieldProps={formik.getFieldProps('dob')}
                                    label={t('Dob')}
                                />
                            </div>
                            {/* Phone */}
                            <div className='form-group'>
                                <BaseTextField
                                    name='phone'
                                    placeholder={`${t('Phone')}...`}
                                    label={t('Phone')}
                                    text={t('PhoneInputHint')}
                                    fieldHelper={formik.getFieldHelpers('phone')}
                                    fieldProps={formik.getFieldProps('phone')}
                                    fieldMeta={formik.getFieldMeta('phone')}
                                />
                            </div>
                            {/* Receive Notification Via */}
                            <p className='font-size-lg font-weight-boldest mb-4'>{t('ReceiveNotificationVia')}</p>
                            <div className='form-group'>
                                <BaseCheckBox
                                    name='enablePushNotification'
                                    label={t('PushNotification')}
                                    fieldHelper={formik.getFieldHelpers('enablePushNotification')}
                                    fieldProps={formik.getFieldProps('enablePushNotification')}
                                    fieldMeta={formik.getFieldMeta('enablePushNotification')}
                                />
                                <BaseCheckBox
                                    name='enableEmailNotification'
                                    label={t('EmailNotification')}
                                    fieldHelper={formik.getFieldHelpers('enableEmailNotification')}
                                    fieldProps={formik.getFieldProps('enableEmailNotification')}
                                    fieldMeta={formik.getFieldMeta('enableEmailNotification')}
                                />
                            </div>

                            {/* Notification Type */}
                            <p className='font-size-lg font-weight-boldest mb-4'>{t('NotificationType')}</p>
                            <div className='form-group'>
                                <BaseCheckBox
                                    name='earningNotification'
                                    label={t('EarningNotification')}
                                    fieldHelper={formik.getFieldHelpers('earningNotification')}
                                    fieldProps={formik.getFieldProps('earningNotification')}
                                    fieldMeta={formik.getFieldMeta('earningNotification')}
                                />
                                <BaseCheckBox
                                    name='eventNotification'
                                    label={t('EventNotification')}
                                    fieldHelper={formik.getFieldHelpers('eventNotification')}
                                    fieldProps={formik.getFieldProps('eventNotification')}
                                    fieldMeta={formik.getFieldMeta('eventNotification')}
                                />
                                <BaseCheckBox
                                    name='newsNotification'
                                    label={t('NewsNotification')}
                                    fieldHelper={formik.getFieldHelpers('newsNotification')}
                                    fieldProps={formik.getFieldProps('newsNotification')}
                                    fieldMeta={formik.getFieldMeta('newsNotification')}
                                />
                                <BaseCheckBox
                                    name='reportNotification'
                                    label={t('ReportNotification')}
                                    fieldHelper={formik.getFieldHelpers('reportNotification')}
                                    fieldProps={formik.getFieldProps('reportNotification')}
                                    fieldMeta={formik.getFieldMeta('reportNotification')}
                                />
                                <BaseCheckBox
                                    name='stockAlertNotification'
                                    label={t('StockAlertNotification')}
                                    fieldHelper={formik.getFieldHelpers('stockAlertNotification')}
                                    fieldProps={formik.getFieldProps('stockAlertNotification')}
                                    fieldMeta={formik.getFieldMeta('stockAlertNotification')}
                                />
                            </div>

                            {/* Button save */}
                            <div className='d-flex justify-content-center'>
                                {/* <button
                                    className="btn btn-secondary btn-lg border-0 mr-4"
                                >
                                    {t('Back')}
                                </button> */}
                                <a
                                    href='#'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.goBack();
                                    }}
                                    className="btn btn-secondary btn-lg border-0 font-weight-bolder hover-opacity-80 mr-4"
                                >
                                    {t('Back')}
                                </a>
                                <button
                                    type='submit'
                                    className="btn btn-primary btn-lg border-0 font-weight-bolder hover-opacity-80"
                                >
                                    {t('Save')}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className='col-3'>

                    </div>
                </div>
            </div>
        </AccountBaseLayout>
    );
}

export default AccountInfoScreen;