import articleApi from 'api/articleApi';
import CellArticleList from 'features/Article/components/CellArticleList';
import AppBanner from 'general/components/AppBanner';
import AppBreadcrumb from 'general/components/AppBreadcrumb';
import AppLayout from 'general/components/AppLayout';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import AppConfigs from 'general/constants/AppConfigs';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import { Markup } from 'interweave';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FacebookShareButton } from 'react-share';
//import { FacebookShareButton, FacebookShareCount } from 'react-share';

ArticleDetailScreen.propTypes = {

};

const sTag = '[ArticleDetailScreen]';

function ArticleDetailScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { topArticles, topResearches } = useSelector(state => state.app);
    const routeParams = match.params;
    let articleId = routeParams.articleId;
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [loadingRelatedArticles, setLoadingRelatedArticles] = useState(false);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const path = match.url;
    let arrPaths = path.split('/');
    arrPaths = arrPaths.filter((item, index) => item.length > 0 && index < arrPaths.length - 1);
    const subBreadcrumbs = arrPaths.map((item, index) => {
        const url = arrPaths.slice(0, index + 1).join('/');
        return {
            text: item,
            url: `/${url}`
        }
    });

    const currentLoggedInUser = useSelector(state => state.auth.current);
    let isAccessLevelValid = true;
    if (article) {
        isAccessLevelValid = currentLoggedInUser?.accessLevel <= article?.accessLevel || article?.accessLevel === 3;
    }

    // MARK: --- Functions ---
    // get article detail
    async function getArticleDetail(id) {
        setLoading(true);
        const res = await articleApi.getArticleInfo(id);
        const { result, data } = res;
        if (result === 'success' && data && data.article) {
            setArticle(data.article);
            getRelatedArticles(data.article.articleId, data.article.category.categoryId, data.article.type);
        }
        setLoading(false);
    }

    // get related articles
    async function getRelatedArticles(articleId, categoryId, type) {
        setLoadingRelatedArticles(true);
        const res = await articleApi.getRelatedArticles({
            page: 0,
            limit: Global.gDefaultPagination,
            categoryId: categoryId,
            type: type,
            articleId: articleId
        });
        const { result, articles } = res;
        if (result === 'success' && articles) {
            setRelatedArticles(articles);
        }

        setLoadingRelatedArticles(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} view did load`);

        return () => {
            console.log(`${sTag} view will dismiss`);
        }
    }, []);

    useEffect(() => {
        if (articleId && !isNaN(articleId)) {
            getArticleDetail(articleId);
            Utils.scrollToTop()
        }
    }, [articleId]);

    // useScrollToTop()

    return (
        <AppLayout>
            <div className='ArticleDetailScreen bg-white min-h-600px'>
                <div className='container-xxl py-10'>
                    {
                        article && (
                            <AppBreadcrumb
                                items={[
                                    { text: 'Home', url: '/home' },
                                    ...subBreadcrumbs,
                                    { text: article.title, url: '' }
                                ]}
                            />
                        )
                    }

                    <div className='row'>
                        <div className='col-12 col-lg-8 overflow-hidden'>
                            {
                                article && (
                                    <div className=''>
                                        <span className='font-weight-boldest' style={{
                                            fontSize: '2.2rem',
                                        }}>{article.title}</span>
                                        <p className='font-weight-bold mb-4 text-muted font-size-lg'>{`${Utils.formatDateTime(article.date, 'DD/MM/YYYY HH:mm')} | ${t('Author')}: `}<span className='text-primary font-weight-boldest'>{article.author}</span></p>

                                        {/* content */}
                                        {
                                            isAccessLevelValid ? (
                                                <div>
                                                    <Markup
                                                        className='font-size-lg text-justify q-markup'
                                                        content={Utils.decodeHTML(article.content)}
                                                    />
                                                    {/* share */}
                                                    <div className='row mt-10'>
                                                        <div className="col-6">
                                                            <FacebookShareButton
                                                                url={Utils.getCurrentUrl()}
                                                                quote={""}
                                                                title={article.title}
                                                                hashtag={AppConfigs.hashtag}
                                                                description={article.description}
                                                                className='w-100 h-40px btn text-white font-weight-bold d-flex align-items-center justify-content-center hover-opacity-60'
                                                                style={{
                                                                    backgroundColor: '#3C5795'
                                                                }}
                                                            >
                                                                <i className="fab fa-facebook text-white mr-2"></i>{t('Share')}
                                                            </FacebookShareButton>
                                                        </div>
                                                        <div className='col-6'>

                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {
                                                        article?.accessLevel === 1 ? (
                                                            <div>
                                                                {
                                                                    !Utils.isObjectEmpty(currentLoggedInUser) ? (
                                                                        <div>
                                                                            <p>Bạn cần đăng ký gói thuê bao để có thể xem được bài viết này.</p>
                                                                            <button className='btn btn-primary mt-2 hover-opacity-80' onClick={() => {
                                                                                history.push('/payment/plan');
                                                                            }}>Mua Gói</button>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <p>Bạn cần đăng ký gói thuê bao để có thể xem được bài viết này.</p>
                                                                            <button className='btn btn-primary mt-2 hover-opacity-80' onClick={() => {
                                                                                history.push('/sign-in');
                                                                            }}>Đăng Nhập</button>
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                        ) : article?.accessLevel === 2 ? (
                                                            <div>
                                                                <p>Bạn cần đăng nhập để có thể xem được bài viết này.</p>
                                                                <button className='btn btn-primary mt-2 hover-opacity-80' onClick={() => {
                                                                    history.push('/sign-in');
                                                                }}>Đăng Nhập</button>
                                                            </div>
                                                        ) : (
                                                            <div>

                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }

                            {/* related articles */}
                            <div className='mt-10'>
                                <SectionHeaderView
                                    title={t('RelatedArticles')}
                                />
                                <div className='row mt-5'>
                                    {
                                        relatedArticles.length > 0 && relatedArticles.map((item, index) => {
                                            return (
                                                <div key={index} className={`col-12 mb-4 pb-4`}>
                                                    <CellArticleList
                                                        item={item}
                                                        onPress={() => {
                                                            history.push(`/article/${item.articleId}`);
                                                        }}
                                                        showDivider={index < relatedArticles.length - 1}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        relatedArticles.length === 0 && (

                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-4'>
                            <AppBanner type='DEFAULT' className='mb-10' />
                            <div className=''>
                                <SectionHeaderView
                                    title={(article && article.type === 'RESEARCH') ? t('TopResearches') : t('TopArticles')}
                                />
                                <div className='row mt-5'>
                                    {
                                        ((article && article.type === 'RESEARCH' ? topResearches : topArticles)).map((item, index) => {
                                            return (
                                                <div key={index} className={`col-12 mb-0 pb-5`}>
                                                    <CellArticleList
                                                        item={item}
                                                        onPress={() => {
                                                            history.push(`/article/${item.articleId}`);
                                                        }}
                                                        showDivider={index < topArticles.length - 1}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default ArticleDetailScreen;