import axiosClient from "./axiosClient";

const companyApi = {
    // search
    search: (params) => {
        const url = '/company/';
        return axiosClient.get(url, { params });
    },

    // get company info by stock code
    getInfoByStockCode: (stockCode) => {
        const url = `/company/detail/${stockCode}`;
        return axiosClient.get(url);
    },

    // get company other info
    getOtherInfo: (companyId) => {
        const url = `/company/otherInfo/${companyId}`;
        return axiosClient.get(url);
    },

    // get articles stock
    getStockArticles: (stockCode, params) => {
        const url = `/company/articleStock/${stockCode}`;
        return axiosClient.get(url, { params });
    },

    // get events stock
    getStockEvents: (stockCode, params) => {
        const url = `/company/stockEvent/${stockCode}`;
        return axiosClient.get(url, { params });
    },

    // get similar company
    getSimilarCompany: (params) => {
        const url = '/company/similar';
        return axiosClient.get(url, { params });
    },

    // get report norm
    getReportNorm: () => {
        const url = '/company/reportSample';
        return axiosClient.get(url);
    },

    // lay ket qua kinh doanh theo thoi gian
    getFinanceReportByTime: (stockCode) => {
        const url = `/company/reportData/term/${stockCode}`;
        return axiosClient.get(url);
    }
};

export default companyApi;
