import { unwrapResult } from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import { thunkSignIn, thunkSignInWithFB, thunkSignInWithGoogle } from 'app/authSlice';
import { startFirebaseSignInFB, startFirebaseSignInGoogle } from 'app/FirebaseListener';
import AuthContent from 'features/Auth/components/AuthContent';
import AuthHeader from 'features/Auth/components/AuthHeader';
import ModalActiveAccount from 'features/Auth/components/ModalActiveAccount';
import { useFormik } from 'formik';
import BaseCheckBox from 'general/components/Forms/FormControls/BaseCheckBox';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import ToastHelper from 'general/helpers/ToastHelper';
import UserHelper from 'general/helpers/UserHelper';
import Utils from 'general/utils/Utils';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import './style.scss';

const sTag = '[SignInScreen]';

function SignInScreen(props) {
    // MARK: --- Params ---
    const [modalActiveAccount, setModalActiveAccount] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isSigningIn } = useSelector(state => state.auth);
    const formik = useFormik({
        initialValues: {
            email: localStorage.getItem(PreferenceKeys.savedEmail) ?? '',
            password: localStorage.getItem(PreferenceKeys.savedPassword) ?? '',
            remember: true
        },
        onSubmit: async (values) => {
            const params = { ...values };
            delete params['remember'];
            let inputPassword = params.password
            params["password"] = Utils.sha256(inputPassword)
            try {
                const res = unwrapResult(await dispatch(thunkSignIn(params)));
                if (res?.status === 409) {
                    localStorage.setItem(PreferenceKeys.activeEmail, params.email);
                    setModalActiveAccount(true);
                } else {
                    const displayName = UserHelper.getDisplayName(res);
                    ToastHelper.showSuccess(`${t('Hello')}, ${displayName}`);
                    history.replace('/');
                }
            } catch (error) {
                // console.log({ error });
                // console.log(`${sTag} login error: ${error.message}`);
            }
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t('EmailIsRequired')).email(t('EmailNotValid')),
            password: Yup.string().required(t('PasswordIsRequired')),
        }),
    });

    // MARK: --- Functions ---
    function handleSignIn(e) {
        e.preventDefault();
        formik.handleSubmit();
    }

    function handleSignInWithFB(e) {
        e.preventDefault();
        startFirebaseSignInFB((user, credential, accessToken) => {
            console.log({ user, credential, accessToken });
            // Dang nhap bang FB
            requestSignInWithFB(accessToken);
        }, (error) => {
            console.log({ error });
        })
    }

    function handleSignInWithGoogle(e) {
        e.preventDefault();
        startFirebaseSignInGoogle((user, credential, accessToken) => {
            console.log({ user, credential, accessToken });
            // Dang nhap bang Google
            requestSignInWithGoogle(accessToken);
        }, (error) => {
            console.log({ error });
        })
    }

    async function requestSignInWithGoogle(accessToken) {
        try {
            const res = unwrapResult(await dispatch(thunkSignInWithGoogle(accessToken)));
            if (res) {
                const displayName = UserHelper.getDisplayName(res);
                ToastHelper.showSuccess(`${t('Hello')}, ${displayName}`);
                history.replace('/');
            }
        } catch (error) {
            console.log(`${sTag} login error: ${error.message}`);
        }
    }

    async function requestSignInWithFB(accessToken) {
        try {
            const res = unwrapResult(await dispatch(thunkSignInWithFB(accessToken)));
            if (res) {
                const displayName = UserHelper.getDisplayName(res);
                ToastHelper.showSuccess(`${t('Hello')}, ${displayName}`);
                history.replace('/');
            }
        } catch (error) {
            console.log(`${sTag} login error: ${error.message}`);
        }
    }

    function handleSignUp() {
        history.push('/sign-up');
    }

    function handleForgotPassword(e) {
        e.preventDefault();
        history.push('/forgot-password');
    };


    async function activeAccount() {
        const activeEmail = localStorage.getItem(PreferenceKeys.activeEmail);
        const res = await authApi.activeAccount({
            "email": activeEmail,
        });
        if (res.result === 'success') {
            ToastHelper.showSuccess('Email kích hoạt đã được gửi thành công, vui lòng kiểm tra hộp thư mail để kích hoạt tài khoản. Lưu ý: Nếu không thấy mail kích hoạt tài khoản, bạn có thể kiểm tra hộp thư Spam')
            localStorage.removeItem(PreferenceKeys.activeEmail);
            setModalActiveAccount(false);
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {

    }, []);

    return (
        <div className='SignInScreen min-vh-100 d-flex flex-column'>
            <AuthHeader />
            <AuthContent
                rightElement={(
                    <div>
                        <Card className={`rounded-lg overflow-hidden`}>
                            <Card.Header className='border-0'>
                                <p className='font-size-h3 font-weight-boldest m-0 text-center'>{t('SignIn')}</p>
                            </Card.Header>
                            <Card.Body className='py-0'>
                                <div className=''>
                                    <form onSubmit={formik.handleSubmit}>
                                        <BaseTextField
                                            name='email'
                                            placeholder={`${t('Email')}...`}
                                            label={t('Email')}
                                            fieldHelper={formik.getFieldHelpers('email')}
                                            fieldProps={formik.getFieldProps('email')}
                                            fieldMeta={formik.getFieldMeta('email')}
                                        />
                                        <BaseTextField
                                            name='password'
                                            placeholder={`${t('Password')}...`}
                                            label={t('Password')}
                                            type='password'
                                            fieldHelper={formik.getFieldHelpers('password')}
                                            fieldProps={formik.getFieldProps('password')}
                                            fieldMeta={formik.getFieldMeta('password')}
                                        />

                                        <div>
                                            <button
                                                className={`btn btn-primary btn-lg w-100 border-0 font-weight-bold hover-opacity-80 ${isSigningIn ? 'spinner spinner-white spinner-right' : ''}`}
                                                onClick={handleSignIn}
                                            >
                                                {t('SignIn')}
                                            </button>
                                            <p className='text-center mt-4'>{t('Or')}</p>
                                            <div className='d-flex'>
                                                {/* <button
                                                    className="btn btn-primary btn-lg w-100 border-0 font-weight-bold mt-4 hover-opacity-80 mr-2"
                                                    style={{
                                                        backgroundColor: '#1877F2'
                                                    }}
                                                    onClick={handleSignInWithFB}
                                                >
                                                    <i className='fab fa-facebook' />
                                                    {t('SignInWithFB')}
                                                </button> */}
                                                <button
                                                    className="btn btn-primary btn-lg w-100 border-0 font-weight-bold mt-4 hover-opacity-80 ml-2"
                                                    style={{
                                                        backgroundColor: '#EA4335'
                                                    }}
                                                    onClick={handleSignInWithGoogle}
                                                >
                                                    <i className='fab fa-google' />
                                                    {t('SignInWithGoogle')}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='mt-8 d-flex align-items-start justify-content-between'>
                                            <a
                                                className="btn btn-link-primary font-weight-bold"
                                                href='#'
                                                onClick={handleForgotPassword}
                                            >
                                                {`${t('ForgotPassword')}?`}
                                            </a>

                                            <BaseCheckBox
                                                name='checkboxRemember'
                                                label={t('Remember')}
                                                fieldHelper={formik.getFieldHelpers('remember')}
                                                fieldProps={formik.getFieldProps('remember')}
                                                fieldMeta={formik.getFieldMeta('remember')}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </Card.Body>
                            <Card.Footer className='d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: '#F3F5F8', borderTopColor: '#DBE3EF' }}>
                                <p className='m-0 text-center font-weight-boldest font-size-h6'>{t('SignUpNewAccount')}</p>
                                <button onClick={handleSignUp} style={{ backgroundColor: '#4886FF' }} className="btn btn-linkedin btn-lg mt-4 w-100 border-0 hover-opacity-80 font-weight-bold">{t('SignUp')}</button>
                            </Card.Footer>
                        </Card>
                    </div>
                )}
            />
            <ModalActiveAccount
                show={modalActiveAccount}
                onClose={() => setModalActiveAccount(false)}
                active={activeAccount}
            />
        </div>
    );
}

export default SignInScreen;
