import axiosClient from "./axiosClient";

const alertApi = {
    // Lấy danh sách cảnh báo
    getAll: (params) => {
        const url = `/alert/all`;
        return axiosClient.get(url,{params});
    },
    // tạo cảnh báo
    createAlert: (params) => {
        const url = `/alert/add`;
        return axiosClient.post(url,params);
    },
    // sửa cảnh báo
    editAlert: (params) => {
        const url = `/alert/update/${params?.alertId}`;
        return axiosClient.put(url, params);
    },
    // xoá cảnh báo
    removeAlert: (params) => {
        const url = `/alert/remove`;
        return axiosClient.post(url,params);
    },
};

export default alertApi;
