import { async } from "@firebase/util";
import myListApi from "api/myListApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
export const thunkGetListMyList = createAsyncThunk('mylist/all', async (params, thunkAPI) => {
    const res = await myListApi.getMyList(params);
    return res;
});

export const thunkRenameMyList = createAsyncThunk('mylist/rename', async (params) => {
    const { myListId, newName } = params
    const res = await myListApi.renameMyList(myListId, newName);
    return res;
});

export const thunkDeleteMyList = createAsyncThunk('mylist/delete', async (id) => {
    const res = await myListApi.deleteMyList(id);
    return res;
});

export const thunkGetMyListDetail = createAsyncThunk('mylist/get_detail', async (params) => {
    const res = await myListApi.getMyListDetail(params.myListId, params.param);
    return res;
});

export const thunkGetStockDetail = createAsyncThunk('mylist/stock_detail', async (params) => {
    const res = await myListApi.getStockDetail(params.myListId, params.stockCode);
    return res;
})

export const thunkRemoveStock = createAsyncThunk('mylist/delete_detail', async (params) => {
    const res = await myListApi.removeStock(params);
    return res;
})

export const thunkEditStockDetail = createAsyncThunk('mylist/edit_detail', async (params) => {
    const res = await myListApi.editStockDetail(params);
    return res;
})

export const thunkGetHistoryTransaction = createAsyncThunk('mylist/history_transaction', async (myListId, thunkAPI) => {
    const res = await myListApi.getHistoryTransaction(myListId, thunkAPI.getState().myList.transactionHistoryFilter);
    return res;
});

export const thunkGetInvestmentPerformance = createAsyncThunk('myList/getInvestmentPerformance', async (params, thunkAPI) => {
    const myListId = params.myListId;
    const res = await myListApi.getMyListDetail(myListId, {
        from: params.fromDate,
        to: params.toDate,
    });
    return res;
});

const myListSlice = createSlice({
    name: "myList",
    initialState: {
        isGetting: false,
        allMyLists: [],
        selectedMyList: null,
        selectedMyListDetail: [],
        stockDetails: [],

        selectedStockCode: null,
        transactionHistorys: [],
        selectedMyListId: null,

        transactionHistoryFilter: {
            limit: Global.gDefaultPagination,
            page: 0,
        },
        transactionHistoryPagination: null,

        isGettingInvestmentPerformance: false,
        myListInvestmentPerformance: [],
    },
    reducers: {
        setCurrentSelectedMyList: (state, action) => {
            state.selectedMyList = action.payload;
        },
        addNewList: (state, action) => {
            return {
                ...state,
                selectedMyList: action.payload,
                allMyLists: [
                    action.payload,
                    ...state.allMyLists
                ]
            }
        },
        setSelectedStockCode: (state, action) => {
            return {
                ...state,
                selectedStockCode: action.payload,
            }
        },

        setTransactionHistoryFilter: (state, action) => {
            return {
                ...state,
                transactionHistoryFilter: {
                    ...state.transactionHistoryFilter,
                    ...action.payload
                }
            }
        },

        resetTransactionHistory: (state, action) => {
            return {
                ...state,
                transactionHistoryFilter: {
                    limit: Global.gDefaultPagination,
                    page: 0,
                },
                transactionHistoryPagination: null,
                transactionHistorys: [],
            }
        }
    },
    extraReducers: {
        // Lay ds my list
        [thunkGetListMyList.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkGetListMyList.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkGetListMyList.fulfilled]: (state, action) => {
            state.isGetting = false;
            const payload = action.payload;
            const { result, myLists } = payload;
            if (result === 'success' && myLists) {
                state.allMyLists = myLists;
                if (state.selectedMyList === null && myLists.length > 0) {
                    state.selectedMyList = myLists[0];
                }
            }
        },

        // Rename Mylist
        [thunkRenameMyList.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkRenameMyList.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkRenameMyList.fulfilled]: (state, action) => {
            state.isGetting = false;
            const result = action.payload.result;
            const { myListId, newName } = action.meta.arg;
            if (result === 'success') {
                for (let i = 0; i < state.allMyLists.length; i++)
                    if (state.allMyLists[i].myListId == myListId) {
                        state.allMyLists[i].name = newName
                        if (state.selectedMyList.myListId === state.allMyLists[i].myListId) state.selectedMyList = state.allMyLists[i]
                        break
                    }
            }
        },

        // Delete Mylist
        [thunkDeleteMyList.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkDeleteMyList.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkDeleteMyList.fulfilled]: (state, action) => {
            state.isGetting = false;
            const result = action.payload.result;
            const myListId = action.meta.arg;
            if (result === 'success') {
                state.allMyLists = state.allMyLists.filter(item => !(myListId === item.myListId));
                if (state.selectedMyList.myListId === myListId) state.selectedMyList = state.allMyLists[0] ?? null;
            }
        },

        //get Stock Detail
        [thunkGetStockDetail.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkGetStockDetail.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkGetStockDetail.fulfilled]: (state, action) => {
            state.isGetting = false;
            const { result, data } = action.payload;
            if (result == 'success') {
                state.stockDetails = data;
            }
        },

        //delete Stock Detail
        [thunkRemoveStock.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkRemoveStock.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkRemoveStock.fulfilled]: (state, action) => {
            state.isGetting = false;
            const result = action.payload.result;
            const myListStockIdDeleted = action.meta.arg;
            if (result == 'success') {
                state.stockDetails = state.stockDetails.filter(item => !(myListStockIdDeleted === item.myListStockId));
                state.transactionHistorys = state.transactionHistorys.filter(item => !(myListStockIdDeleted === item.myListStockId));
                state.selectedStockCode = null;
            }
        },

        //edit Stock Detail
        [thunkEditStockDetail.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkEditStockDetail.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkEditStockDetail.fulfilled]: (state, action) => {
            const { result } = action.payload;
            const { myListStockId, datetime, price, quantity, note } = action.meta.arg;
            if (result == 'success') {
                for (let i = 0; i < state.stockDetails.length; i++) {
                    if (myListStockId == state.stockDetails[i].myListStockId) {
                        state.stockDetails[i].datetime = datetime;
                        state.stockDetails[i].price = price;
                        state.stockDetails[i].quantity = quantity;
                        state.stockDetails[i].note = note;
                    }
                }
            }

        },

        //get history transaction
        [thunkGetHistoryTransaction.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkGetHistoryTransaction.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkGetHistoryTransaction.fulfilled]: (state, action) => {
            const { result, detail, page, total, count } = action.payload;
            if (result == 'success' && detail) {
                if (page === 0) {
                    state.transactionHistorys = detail;
                } else {
                    state.transactionHistorys = state.transactionHistorys.concat(detail);
                }
                state.transactionHistoryPagination = {
                    total: total,
                    count: count,
                    currentPage: page
                }
            }
        },

        // get my list detail
        [thunkGetMyListDetail.fulfilled]: (state, action) => {
            const { result, detail } = action.payload;
            if (result === 'success' && detail) {
                state.selectedMyListDetail = detail;
            }
        },

        // get investment performance
        [thunkGetInvestmentPerformance.pending]: (state, action) => {
            state.isGettingInvestmentPerformance = true;
        },
        [thunkGetInvestmentPerformance.rejected]: (state, action) => {
            state.isGettingInvestmentPerformance = false;
        },
        [thunkGetInvestmentPerformance.fulfilled]: (state, action) => {
            state.isGettingInvestmentPerformance = false;
            const payload = action.payload;
            const { result, detail } = payload;
            if (result === 'success' && detail) {
                state.myListInvestmentPerformance = detail;
            }
        },
    },
});

const { reducer, actions } = myListSlice;
export const { setCurrentSelectedMyList, addNewList, setSelectedStockCode, setTransactionHistoryFilter, resetTransactionHistory } = actions;
export default reducer;