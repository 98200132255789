import AppLayout from 'general/components/AppLayout';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import companyApi from 'api/companyApi';
import stockApi from 'api/stockApi';
import store from 'app/store';
import ModalAddTransaction from 'features/MyList/components/ModalAddTransaction';
import { thunkGetListMyList } from 'features/MyList/myListSlice';
import CompanyOverView from 'features/Stock/components/companyOverView';
import Compare from 'features/Stock/components/compare';
import FinancialAnalysis from 'features/Stock/components/financyAnalysis';
import MajorHolder from 'features/Stock/components/majorHolder';
import NewsEvent from 'features/Stock/components/newsEvent';
import Warrant from 'features/Stock/components/warrants';
import { setLoadingStockChartData, setStockCandleData, setStockVolumeData } from 'features/Stock/stockSlice';
import AppData from 'general/constants/AppData';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CreateAlertModal, {
    CreateAlertModalComponent
} from "../../../IdeaList/components/BuildYourScreen/CreateAlertModal";
import ListAlertModal from "../../../IdeaList/components/BuildYourScreen/ListAlertModal";
import { RemoveAlertModalComponent } from "../../../IdeaList/components/BuildYourScreen/RemoveAlertModal";
import Summary from "../../components/summary";
import ModalAddtoWatchlist from './../../components/ModalAddToWatchlist';

const currentYear = moment().year();
const arrReportYears = [`${currentYear - 3}`, `${currentYear - 2}`, `${currentYear - 1}`, `${currentYear}`];
const sTag = '[StockInfoScreen]';
const stockMenuItems = [
    {
        id: 'stock_summary',
        text: 'Summary',
        path: 'summary'
    },
    {
        id: 'stock_company_overview',
        text: 'Thông Tin Doanh Nghiệp',
        path: 'company-overview'
    },
    {
        id: 'stock_major_holder',
        text: 'MajorHolder',
        path: 'major-holder'
    },
    {
        id: 'stock_financial_analytics',
        text: 'FinancialAnalytics',
        path: 'financial-analytics'
    },
    {
        id: 'stock_news_event',
        text: 'NewsAndEvent',
        path: 'news-events'
    },
    {
        id: 'stock_compare',
        text: 'Doanh Nghiệp Cùng Ngành',
        path: 'compare'
    },
    {
        id: 'stock_license',
        text: 'License',
        path: 'license'
    },
];
const rsItems = [
    { name: 'RS (1 Tháng)', value: '1M' },
    { name: 'RS (3 Tháng)', value: '3M' },
    { name: 'RS (6 Tháng)', value: '6M' },
    { name: 'RS (1 Năm)', value: '1Y' },
];

function StockInfoScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const routeParams = match.params;
    let stockCode = routeParams.stockCode;
    const isSpecialStockCode = AppData.specialStockCodes.includes(stockCode);
    const filteredStockMenuItems = stockMenuItems.filter(item => locationPathname.includes(item.path));
    const selectedStockMenuItem = filteredStockMenuItems && filteredStockMenuItems.length > 0 ? filteredStockMenuItems[0] : null;
    const [activeTab, setActiveTab] = useState(selectedStockMenuItem ? selectedStockMenuItem.id : stockMenuItems[0].id);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [companyMajorHolders, setCompanyMajorHolders] = useState([]);
    const [companyAssociates, setCompanyAssociates] = useState([]);
    const [companyBoardOfManagements, setCompanyBoardOfManagements] = useState([]);
    const [companyRevenues, setCompanyRevenues] = useState([]);
    const [lastYearRevenue, setLastYearRevenue] = useState({});
    const [companyLaborStructures, setCompanyLaborStructures] = useState([]);
    const [companyProcessOfIncreasingCharteredCapitals, setCompanyProcessOfIncreasingCharteredCapitals] = useState([]);
    const [companyReportData, setCompanyReportData] = useState({});
    const [companyOwnerships, setCompanyOwnerships] = useState([]);
    const [filterStockArticle, setFilterStockArticle] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
    });
    const [filterStockEvent, setFilterStockEvent] = useState({
        limit: Global.gDefaultPagination,
        page: 0,
    });
    const [paginationStockArticle, setPaginationStockArticle] = useState(null);
    const [paginationStockEvent, setPaginationStockEvent] = useState(null);
    const [stockArticles, setStockArticles] = useState([]);
    const [modalAddToWatchlistShowing, setModalAddToWatchlistShowing] = useState(false)
    const [stockEvents, setStockEvents] = useState([]);
    const isLoadingStockArticles = useRef(false);
    const isLoadingStockEvents = useRef(false);
    const [follow, setFollow] = useState(false);
    const [modalAddTransactionShowing, setModalAddTransactionShowing] = useState(false);
    const { isGetting, allMyLists, selectedMyList } = useSelector(state => state.myList);
    const dispatch = useDispatch();
    const [stockWarrants, setStockWarrants] = useState([]);
    const listAlert = useSelector(state => state?.ideaList?.mgAlert?.listAlert?.filter(item => item?.stockCode == stockCode));
    const [stockRealtimeChange, setStockRealtimeChange] = useState({});
    const [stickyBarTop, setStickyBarTop] = useState(undefined);
    const stickyBarWidth = useRef(undefined);
    const stickyBarHeight = useRef(0);
    const appHeaderHeight = useRef(0);
    const [companyReports, setCompanyReports] = useState({});
    const [companyReportNorms, setCompanyReportNorms] = useState({});
    const [companyFinanceIndexes, setCompanyFinanceIndexes] = useState({});
    const [companyFinanceIndexNorms, setCompanyFinanceIndexNorms] = useState({});
    const [reportYears, setReportYears] = useState(arrReportYears);
    const [reportTerms, setReportTerms] = useState(['Q1', 'Q2', 'Q3', 'Q4', 'N']);
    const [companyFinanceReportByTime, setCompanyFinanceReportByTime] = useState({});

    const [stockChartIntervalType, setStockChartIntervalType] = useState(4);
    const [stockChartStartDate, setStockChartStartDate] = useState(moment().subtract(10, 'years').format("DD/MM/YYYY"));
    const [stockChartEndDate, setStockChartEndDate] = useState(moment().format("DD/MM/YYYY"));
    const [selectedRS, setSelectedRS] = useState('1Y');
    const [stockRSObj, setStockRSObj] = useState(null);

    let price = stockRealtimeChange?.currentPrice;
    let refPrice = stockRealtimeChange?.lastPrice;

    if (price === undefined) {
        price = companyInfo?.stock?.price;
    }
    if (refPrice === undefined) {
        refPrice = companyInfo?.stock?.refPrice;
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} view did load`);

        return () => {
            console.log(`${sTag} view will dismiss`);
        }
    }, []);

    useEffect(() => {
        Utils.scrollToTop();
        setCompanyMajorHolders([]);
        setCompanyAssociates([]);
        setCompanyLaborStructures([]);
        setCompanyRevenues([]);
        setCompanyProcessOfIncreasingCharteredCapitals([]);
        setCompanyReportData({});
        setCompanyOwnerships([]);
        setStockArticles([]);
        setStockEvents([]);
        setCompanyReports({});
        setCompanyFinanceIndexes({});
        setCompanyFinanceReportByTime({});

        getCompanyInfo();

        setReportYears(arrReportYears);
        setFilterStockArticle({
            limit: Global.gDefaultPagination,
            page: 0
        });
        setFilterStockEvent({
            limit: Global.gDefaultPagination,
            page: 0
        });
        Global.gSavedScrollTopArticle = 0;
        Global.gSavedScrollTopEvent = 0;

        if (stockChartIntervalType < 4) {
            getStockRealtime(stockCode, stockChartStartDate, stockChartEndDate, stockChartIntervalType);
        } else {
            getStockHistory(stockCode, stockChartStartDate, stockChartEndDate);
        }
    }, [stockCode]);

    useEffect(() => {
        getStockArticles(stockCode, filterStockArticle);
    }, [stockCode, filterStockArticle]);

    useEffect(() => {
        getStockEvents(stockCode, filterStockEvent);
    }, [stockCode, filterStockEvent]);

    useEffect(() => {
        if (allMyLists.length === 0) {
            dispatch(thunkGetListMyList({}));
        }
    }, []);

    useEffect(() => {
        getReportData(stockCode, reportYears, reportTerms);
    }, [reportTerms, reportYears]);

    useEffect(() => {
        const interval = setInterval(() => {
            getStockRealtimeChange(stockCode);
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, [stockCode]);

    // MARK: --- Functions ---
    async function getCompanyInfo() {
        if (stockCode) {
            getStockWarrants(stockCode, {});
            getStockRealtimeChange(stockCode);
            getReportData(stockCode);
            getCompanyFinanceReportByTime(stockCode);
            const res = await companyApi.getInfoByStockCode(stockCode);
            const { result, company, boardOfManagements, alerts } = res;
            const rs = company?.stock?.rs;
            if (result === 'success') {
                if (company) {
                    setCompanyInfo(company);
                    const companyId = company.companyId;
                    getCompanyOtherInfo(companyId);
                } else {
                    setCompanyInfo(null);
                }
                if (boardOfManagements) {
                    const companyBoardOfManagements = [];
                    const groupedBoardOfManagements = Utils.groupBy(boardOfManagements, 'name');
                    const boardOfManagementsKeys = Object.keys(groupedBoardOfManagements);
                    boardOfManagementsKeys.forEach(key => {
                        const value = groupedBoardOfManagements[key];
                        const sortedValues = value.sort((a, b) => {
                            return a.lastUpdate > b.lastUpdate;
                        });
                        if (sortedValues.length > 0) {
                            companyBoardOfManagements.push(sortedValues[0]);
                        }
                    });
                    setCompanyBoardOfManagements(companyBoardOfManagements);
                }
                // if (alerts?.length > 0) {
                //     dispatch(setMGAlert(alerts))
                // }
                if (rs) {
                    setStockRSObj(rs);
                }
            } else {
                setCompanyInfo(null);
            }
        }
    }

    async function getStockArticles(stockCode, params) {
        if (isLoadingStockArticles.current) {
            return;
        }
        isLoadingStockArticles.current = true;
        const res = await companyApi.getStockArticles(stockCode, params);
        // console.log({ res });
        const { result, count, page, total, articles } = res;
        if (result === 'success' && articles) {
            setPaginationStockArticle({
                total: total,
                count: count,
                currentPage: page
            });
            if (page !== null && page === 0) {
                setStockArticles(articles);
            } else {
                setStockArticles(stockArticles.concat(articles));
            }
        }
        isLoadingStockArticles.current = false;
    }

    async function getStockEvents(stockCode, params) {
        if (isLoadingStockEvents.current) {
            return;
        }
        isLoadingStockEvents.current = true;
        const res = await companyApi.getStockEvents(stockCode, params);
        const { result, count, page, total, events } = res;
        if (result === 'success' && events) {
            setPaginationStockEvent({
                total: total,
                count: count,
                currentPage: page
            });
            if (page !== null && page === 0) {
                setStockEvents(events);
            } else {
                setStockEvents(stockEvents.concat(events));
            }
        }
        isLoadingStockEvents.current = false;
    }

    async function getCompanyOtherInfo(companyId) {
        const res = await companyApi.getOtherInfo(companyId);
        const {
            result,
            majorHolders,
            associates,
            laborStructures,
            revenues,
            processOfIncreasingCharteredCapitals,
            reportData,
            ownerships,
            lastYearRevenue
        } = res;
        if (result === 'success') {
            if (majorHolders) {
                const companyMajorHolders = [];
                const groupedMajorHolders = Utils.groupBy(majorHolders, 'name');
                const majorHolderKeys = Object.keys(groupedMajorHolders);
                majorHolderKeys.forEach(key => {
                    const value = groupedMajorHolders[key];
                    const sortedValues = value.sort((a, b) => {
                        return a.lastUpdate > b.lastUpdate;
                    });
                    if (sortedValues.length > 0) {
                        companyMajorHolders.push(sortedValues[0]);
                    }
                });
                setCompanyMajorHolders(companyMajorHolders);
            }

            if (associates) {
                const companyAssociates = [];
                const groupedAssociates = Utils.groupBy(associates, 'name');
                const associateKeys = Object.keys(groupedAssociates);
                associateKeys.forEach(key => {
                    const value = groupedAssociates[key];
                    const sortedValues = value.sort((a, b) => {
                        return a.lastUpdate > b.lastUpdate;
                    });
                    if (sortedValues.length > 0) {
                        companyAssociates.push(sortedValues[0]);
                    }
                });
                setCompanyAssociates(companyAssociates);
            }

            if (laborStructures) {
                const companyLaborStructures = [];
                const groupedLaborStructures = Utils.groupBy(laborStructures, 'educationLevel');
                const laborStructureKeys = Object.keys(groupedLaborStructures);
                laborStructureKeys.forEach(key => {
                    const value = groupedLaborStructures[key];
                    const sortedValues = value.sort((a, b) => {
                        return a.lastUpdate > b.lastUpdate;
                    });
                    if (sortedValues.length > 0) {
                        companyLaborStructures.push(sortedValues[0]);
                    }
                });
                setCompanyLaborStructures(companyLaborStructures);
            }

            if (revenues) {
                const companyRevenues = [];
                const groupedRevenues = Utils.groupBy(revenues, 'name');
                const revenueKeys = Object.keys(groupedRevenues);
                revenueKeys.forEach(key => {
                    const value = groupedRevenues[key];
                    const sortedValues = value.sort((a, b) => {
                        return a.lastUpdate > b.lastUpdate;
                    });
                    if (sortedValues.length > 0) {
                        companyRevenues.push(sortedValues[0]);
                    }
                });
                setCompanyRevenues(companyRevenues);
            }

            if (processOfIncreasingCharteredCapitals) {
                setCompanyProcessOfIncreasingCharteredCapitals(processOfIncreasingCharteredCapitals);
            }

            if (reportData) {
                setCompanyReportData(reportData);
            }

            if (ownerships) {
                setCompanyOwnerships(ownerships);
            }

            if (lastYearRevenue) {
                setLastYearRevenue(lastYearRevenue)
            }
        } else {
            setCompanyMajorHolders([]);
            setCompanyAssociates([]);
            setCompanyLaborStructures([]);
            setCompanyRevenues([]);
            setCompanyProcessOfIncreasingCharteredCapitals([]);
            setCompanyReportData({});
            setCompanyOwnerships([]);
        }
    }

    async function getStockWarrants(stockCode, params) {
        const res = await stockApi.getWarrantData(stockCode, params);
        const { result, warrants } = res;
        if (result === 'success' && warrants) {
            setStockWarrants(warrants);
        }
    }

    async function getReportData(stockCode, arrYears = arrReportYears, arrTerms = ['Q1', 'Q2', 'Q3', 'Q4', 'N']) {
        // console.log({ stockCode, arrYears, arrTerms });
        const res = await stockApi.getReportData(stockCode, arrYears, arrTerms);
        const { result, reports, alterReports, financeIndex } = res;
        if (result === 'success') {
            if (reports) {
                setCompanyReports(reports);
                setCompanyReportNorms(store.getState().app.reportNormData.data);
            } else if (alterReports) {
                setCompanyReports(alterReports);
                setCompanyReportNorms(store.getState().app.reportNormData.alternative);
            } else {
                setCompanyReports({});
                setCompanyReportNorms({});
            }
            if (financeIndex) {
                setCompanyFinanceIndexes(financeIndex);
                setCompanyFinanceIndexNorms(store.getState().app.reportNormData.reportFinanceIndexData);
            } else {
                setCompanyFinanceIndexes({});
                setCompanyFinanceIndexNorms({});
            }
        } else {
            setCompanyReports([]);
        }
    }

    const getStockHistory = async (stockCode, startDate, endDate, interval) => {
        dispatch(setLoadingStockChartData(true));
        const sInterval = interval === 4 ? '1D' : interval === 5 ? '1W' : interval === 6 ? '1M' : '1D';
        if (moment(endDate, 'DD/MM/YYYY').isAfter(moment(startDate, 'DD/MM/YYYY'))) {
            try {
                let stockRes = await stockApi.stockHistory({
                    stockCode: stockCode,
                    from: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    to: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    interval: sInterval
                })
                let { result, data } = stockRes
                if (result == "success") {
                    let candle = []
                    let volume = []
                    data?.map((item) => {
                        let date = item?.date ?? item?.month ?? ''
                        candle.push({
                            "time": date,
                            "open": item?.openingPrice,
                            "high": item?.highestPrice,
                            "low": item?.lowestPrice,
                            "close": item?.closingPrice,
                        });
                        volume.push({
                            "time": date,
                            "value": _.toNumber(item?.totalTradingVolume),
                            "color":
                                (item?.closingPrice < item?.openingPrice)
                                    ? "rgba(255, 104, 56, 0.35)"
                                    : "rgba(88, 189, 125, 0.3)",
                        });
                    });
                    dispatch(setStockCandleData(candle));
                    dispatch(setStockVolumeData(volume));
                }
            } catch (error) {
                console.log({ error });
            }
        }
        dispatch(setLoadingStockChartData(false));
    }

    const getStockRealtime = async (stockCode, startDate, endDate, interval) => {
        dispatch(setLoadingStockChartData(true));
        if (moment(endDate, 'DD/MM/YYYY').isAfter(moment(startDate, 'DD/MM/YYYY'))) {
            try {
                let stockRes = await stockApi.stockRealtime({
                    stockCode: stockCode,
                    from: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    to: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    interval: `${interval + 1}H`
                })
                let { result, data } = stockRes
                if (result == "success") {
                    let candle = []
                    let volume = []
                    data?.map((item) => {
                        let date = moment(item?.time).add(7, 'hours').local().unix()
                        candle.unshift({
                            "time": date,
                            "open": item?.openingPrice,
                            "high": item?.highestPrice,
                            "low": item?.lowestPrice,
                            "close": item?.closingPrice,
                        });
                        volume.unshift({
                            "time": date,
                            "value": _.toNumber(item?.totalVolume),
                            "color":
                                (item?.closingPrice < item?.openingPrice)
                                    ? "rgba(255, 104, 56, 0.35)"
                                    : "rgba(88, 189, 125, 0.3)",
                        });
                    });
                    dispatch(setStockCandleData(candle));
                    dispatch(setStockVolumeData(volume));
                }
            } catch (error) {
                console.log({ error });
            }
        }
        dispatch(setLoadingStockChartData(false));
    }

    // get realtime change
    async function getStockRealtimeChange(stockCode) {
        const res = await stockApi.getRealtimeChange(stockCode);
        const { result, stock } = res;
        if (result === 'success' && stock) {
            setStockRealtimeChange(stock);
        }
    }

    // lay bao cao tai chinh theo thoi gian
    async function getCompanyFinanceReportByTime(stockCode) {
        const res = await companyApi.getFinanceReportByTime(stockCode);
        const { result, profits, revenues } = res;
        if (result === 'success') {
            setCompanyFinanceReportByTime({
                profits: profits ?? [],
                revenues: revenues ?? [],
            });
        }
    }

    // Add to list
    function addWatchList() {
        console.log({ stockCode });
        if (stockCode) {
            setModalAddTransactionShowing(true);
        }
    }

    // Add follow
    function addFollow() {
        if (stockCode) {
            setModalAddToWatchlistShowing(true);
        }
    }

    // Add warning price
    function warningPrice() {
        if (listAlert?.length > 0) {
            ListAlertModal.show(true, stockCode)
        } else {
            CreateAlertModal.show(false, null, true, stockCode)
        }
    }

    return (
        <AppLayout>
            <div className='StockInfoScreen d-flex flex-column' style={{ backgroundColor: '#F3F5F8' }}>
                <div className='container-xxl flex-grow-1 py-10'>
                    <div className='card card-custom border border-light overflow-hidden'>
                        {/* card header */}
                        <div className='card-header border-0 pt-5 d-flex flex-column px-0 pb-0 bg-white' id='stock-info-card-header'>
                            <div className='d-flex flex-grow-1 justify-content-between px-8'>
                                <div className='card-title'>
                                    <div className='d-flex align-items-center'>
                                        {/* logo */}
                                        {
                                            companyInfo?.logo && (
                                                <div className="btn btn-icon btn-bg-secondary btn-circle align-items-center overflow-hidden w-85px h-85px mr-4">
                                                    <img
                                                        alt="logo"
                                                        draggable={false}
                                                        src={Utils.getFullUrl(companyInfo?.logo)}
                                                        className="h-100 w-100" style={{ objectFit: 'cover' }}
                                                        onError={(e) => {
                                                            // e.target.src = AppIcons.icMGLogo;
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            isSpecialStockCode ? (
                                                <div>
                                                    <p className='m-0 text-dark-75' style={{
                                                        fontSize: '1.8rem',
                                                        fontWeight: '700',
                                                        color: '#23262F'
                                                    }}>{stockCode}</p>
                                                </div>
                                            ) : (
                                                <div>
                                                    <p className='m-0 text-dark-75' style={{
                                                        fontSize: '1.8rem',
                                                        fontWeight: '700',
                                                        color: '#23262F'
                                                    }}>{companyInfo?.fullname}<span className='ml-2 text-primary'>{`(${companyInfo?.stockCode ?? stockCode})`}</span></p>
                                                    {/* <p className='m-0 text-dark-50' style={{
                                                        fontSize: '1.2rem',
                                                        fontWeight: '500',
                                                    }}>{`${companyInfo?.fullnameEn ?? ''}`}</p> */}
                                                    <p className='m-0 text-dark-75' style={{
                                                        fontSize: '1.2rem',
                                                        fontWeight: '700',
                                                    }}>{`${companyInfo?.stock?.exchangeCode ?? ''}`}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='card-toolbar'>
                                    <div className='d-flex flex-column align-items-end'>
                                        <p className='m-0' style={{
                                            fontSize: '1.8rem',
                                            fontWeight: '700',
                                            color: Utils.getStockColor(Utils.getStockPriceChangeInPercent(price, refPrice))
                                        }}>{Utils.formatNumber(price)}</p>
                                        <p className='m-0' style={{
                                            fontSize: '1.2rem',
                                            fontWeight: '700',
                                            color: Utils.getStockColor(Utils.getStockPriceChangeInPercent(price, refPrice))
                                        }}>
                                            {`${Utils.getStockPriceChange(price, refPrice) > 0 ? '+' : ''}${(parseFloat(Utils.getStockPriceChange(price, refPrice)).toFixed(2))} / ${Utils.getStockPriceChangeInPercent(price, refPrice) > 0 ? '+' : ''}${parseFloat(Utils.getStockPriceChangeInPercent(price, refPrice)).toFixed(2)}%`}
                                        </p>
                                        {/* RS */}
                                        <div className='d-flex align-items-center'>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className='font-size-normal font-weight-bold text-hover-primary pr-0'
                                                    variant=""
                                                >
                                                    <div className='' style={{ overflowX: 'hidden' }}>
                                                        {`RS (${t(selectedRS)})`}
                                                        <i className="fa fa-solid fa-caret-down pl-2 text-dark" />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu alignRight>
                                                    {
                                                        rsItems.map((item, index) => {
                                                            return (
                                                                <Dropdown.Item key={index} href="#" onClick={() => {
                                                                    setSelectedRS(item.value);
                                                                }}>{item.name}</Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <p className='m-0 text-dark-75 font-size-h5 font-weight-bolder ml-2'>{stockRSObj ? stockRSObj[selectedRS]?.toFixed(2) : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* buttons */}
                            {
                                !isSpecialStockCode && (
                                    <div className='px-8 mt-4'>
                                        <button
                                            type="button"
                                            className={`btn ${follow ? 'btn-primary' : 'btn-outline-secondary'} mr-4 font-weight-bold`}
                                            onClick={addFollow}
                                        >
                                            {
                                                follow === true
                                                    ? <span><i className="fal fa-check mr-1" />{t('Following')}</span>
                                                    : <span><i className="fal fa-plus mr-1" />{t('Follow')}</span>
                                            }
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary mr-4 font-weight-bold"
                                            onClick={addWatchList}
                                        >
                                            <i className="fal fa-plus mr-1" />{t('AddToList')}
                                        </button>
                                        <button
                                            type="button"
                                            className={listAlert?.length > 0 ? "btn btn-primary border-0 font-weight-bold" : "btn btn-outline-secondary font-weight-bold"}
                                            onClick={warningPrice}
                                        >
                                            <i className={listAlert?.length > 0 ? "fal fa-bell mr-1" : "fal fa-bell-plus mr-1"} />{listAlert?.length > 0 ? t("WasSetAlert") : t('SetWarning')}
                                        </button>
                                    </div>
                                )
                            }

                            {/* nav menus */}
                            {
                                !isSpecialStockCode ? (
                                    <ul className="nav nav-tabs nav-tabs-line mt-5 px-8">
                                        {
                                            stockMenuItems.map((item, index) => {
                                                return (
                                                    <li key={index} className="nav-item">
                                                        <a className={`font-weight-bolder nav-link ${activeTab === item.id && 'active'}`} href='#' onClick={(e) => {
                                                            e.preventDefault();
                                                            setActiveTab(item.id);
                                                        }}>{t(item.text)}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <div className='border-bottom py-2'></div>
                                )
                            }
                        </div>

                        {/* card body */}
                        <div className='' style={{ backgroundColor: '#F3F5F8' }} id='card-body-content'>
                            {/* <Summary
                                stockCode={stockCode}
                                company={companyInfo}
                                revenues={companyRevenues}
                                lastYearRevenue={lastYearRevenue}
                                isSpecialStockCode={isSpecialStockCode}
                                boardOfManagements={companyBoardOfManagements}
                                majorHolders={companyMajorHolders}
                                ownerships={companyOwnerships}
                                reportData={companyReportData}
                                processOfIncreasingCharteredCapitals={companyProcessOfIncreasingCharteredCapitals}
                                financeReportByTime={companyFinanceReportByTime}
                                intervalType={stockChartIntervalType}
                                onChangeIntervalType={(intervalType) => {
                                    setStockChartIntervalType(intervalType);
                                    if (intervalType < 4) {
                                        getStockRealtime(stockCode, stockChartStartDate, stockChartEndDate, intervalType);
                                    } else {
                                        getStockHistory(stockCode, stockChartStartDate, stockChartEndDate);
                                    }
                                }}
                                startDate={stockChartStartDate}
                                endDate={stockChartEndDate}
                                onChangeDate={(startDate, endDate) => {
                                    setStockChartStartDate(startDate);
                                    setStockChartEndDate(endDate);
                                    if (stockChartIntervalType < 4) {
                                        getStockRealtime(stockCode, startDate, endDate, stockChartIntervalType);
                                    } else {
                                        getStockHistory(stockCode, startDate, endDate);
                                    }
                                }}
                                articles={stockArticles}
                                paginationArticle={paginationStockArticle}
                                onLoadMoreArticle={(page) => {
                                    setFilterStockArticle({
                                        ...filterStockArticle,
                                        page: page
                                    })
                                }}
                                events={stockEvents}
                                paginationEvent={paginationStockEvent}
                                onLoadMoreEvent={(page) => {
                                    setFilterStockEvent({
                                        ...filterStockEvent,
                                        page: page
                                    })
                                }}
                                warrants={stockWarrants}

                                reports={companyReports}
                                reportNorms={companyReportNorms}
                                financeIndexes={companyFinanceIndexes}
                                financeIndexNorms={companyFinanceIndexNorms}
                                reportYears={reportYears}
                                reportTermCodes={reportTerms}
                                onFilter={(arrYears, arrTerms) => {
                                    setReportYears(arrYears);
                                    setReportTerms(arrTerms);
                                }}
                                associates={companyAssociates}
                                laborStructures={companyLaborStructures}
                            /> */}
                            {
                                activeTab === 'stock_summary' ? (
                                    <Summary
                                        currentStockPrice={isNaN(price) ? 0 : parseFloat(price)}
                                        stockCode={stockCode}
                                        tradingViewSymbol={`${companyInfo?.stock?.exchangeCode ?? ''}:${stockCode}`}
                                        company={companyInfo}
                                        revenues={companyRevenues}
                                        lastYearRevenue={lastYearRevenue}
                                        isSpecialStockCode={isSpecialStockCode}
                                        boardOfManagements={companyBoardOfManagements}
                                        majorHolders={companyMajorHolders}
                                        ownerships={companyOwnerships}
                                        reportData={companyReportData}
                                        processOfIncreasingCharteredCapitals={companyProcessOfIncreasingCharteredCapitals}
                                        financeReportByTime={companyFinanceReportByTime}
                                        intervalType={stockChartIntervalType}
                                        onChangeIntervalType={(intervalType) => {
                                            setStockChartIntervalType(intervalType);
                                            if (intervalType < 4) {
                                                getStockRealtime(stockCode, stockChartStartDate, stockChartEndDate, intervalType);
                                            } else {
                                                getStockHistory(stockCode, stockChartStartDate, stockChartEndDate, intervalType);
                                            }
                                        }}
                                        startDate={stockChartStartDate}
                                        endDate={stockChartEndDate}
                                        onChangeDate={(startDate, endDate) => {
                                            setStockChartStartDate(startDate);
                                            setStockChartEndDate(endDate);
                                            if (stockChartIntervalType < 4) {
                                                getStockRealtime(stockCode, startDate, endDate, stockChartIntervalType);
                                            } else {
                                                getStockHistory(stockCode, startDate, endDate, stockChartIntervalType);
                                            }
                                        }}
                                        articles={stockArticles}
                                        paginationArticle={paginationStockArticle}
                                        onLoadMoreArticle={(page) => {
                                            setFilterStockArticle({
                                                ...filterStockArticle,
                                                page: page
                                            })
                                        }}
                                        events={stockEvents}
                                        paginationEvent={paginationStockEvent}
                                        onLoadMoreEvent={(page) => {
                                            setFilterStockEvent({
                                                ...filterStockEvent,
                                                page: page
                                            })
                                        }}
                                        warrants={stockWarrants}
                                        onClickMoreWarrant={() => {
                                            setActiveTab('stock_license');
                                        }}
                                        onClickMoreMajorHolder={() => {
                                            setActiveTab('stock_major_holder');
                                        }}

                                        // reports={companyReports}
                                        // reportNorms={companyReportNorms}
                                        // financeIndexes={companyFinanceIndexes}
                                        // financeIndexNorms={companyFinanceIndexNorms}
                                        // reportYears={reportYears}
                                        // reportTermCodes={reportTerms}
                                        // onFilter={(arrYears, arrTerms) => {
                                        //     setReportYears(arrYears);
                                        //     setReportTerms(arrTerms);
                                        // }}
                                        associates={companyAssociates}
                                    />
                                ) : (
                                    activeTab === 'stock_company_overview' ? (
                                        <CompanyOverView
                                            company={companyInfo}
                                            associates={companyAssociates}
                                            boardOfManagements={companyBoardOfManagements}
                                            revenues={companyRevenues}
                                            laborStructures={companyLaborStructures}
                                            processOfIncreasingCharteredCapitals={companyProcessOfIncreasingCharteredCapitals}
                                            reportData={companyReportData}
                                            financeReportByTime={companyFinanceReportByTime}
                                        />
                                    ) : (
                                        activeTab === 'stock_major_holder' ? (
                                            <MajorHolder
                                                majorHolders={companyMajorHolders}
                                                ownerships={companyOwnerships}
                                                isSpecialStockCode={isSpecialStockCode}
                                            />
                                        ) : (
                                            activeTab === 'stock_financial_analytics' ? (
                                                <FinancialAnalysis
                                                    reports={companyReports}
                                                    reportNorms={companyReportNorms}
                                                    financeIndexes={companyFinanceIndexes}
                                                    financeIndexNorms={companyFinanceIndexNorms}
                                                    reportYears={reportYears}
                                                    reportTermCodes={reportTerms}
                                                    onFilter={(arrYears, arrTerms) => {
                                                        setReportYears(arrYears);
                                                        setReportTerms(arrTerms);
                                                    }}
                                                />
                                            ) : (
                                                activeTab === 'stock_news_event' ? (
                                                    <NewsEvent
                                                        articles={stockArticles}
                                                        paginationArticle={paginationStockArticle}
                                                        onLoadMoreArticle={(page) => {
                                                            setFilterStockArticle({
                                                                ...filterStockArticle,
                                                                page: page
                                                            })
                                                        }}
                                                        events={stockEvents}
                                                        paginationEvent={paginationStockEvent}
                                                        onLoadMoreEvent={(page) => {
                                                            setFilterStockEvent({
                                                                ...filterStockEvent,
                                                                page: page
                                                            })
                                                        }}
                                                    />
                                                ) : (
                                                    activeTab === 'stock_compare' ? (
                                                        <div className='p-4'>
                                                            <Compare company={companyInfo} />
                                                        </div>
                                                    ) : (
                                                        activeTab === 'stock_license' ? (
                                                            <div className='p-4'>
                                                                <Warrant warrants={stockWarrants} />
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddtoWatchlist
                show={modalAddToWatchlistShowing}
                onClose={() => {
                    setModalAddToWatchlistShowing(false);
                }}
                onOpen={() => {
                    setModalAddToWatchlistShowing(true);
                }}
                stockCode={stockCode}
                onFollow={() => { setFollow(true) }}
                onUnFollow={() => { setFollow(false) }}
            />

            {/* Modal add transaction */}
            <ModalAddTransaction
                show={modalAddTransactionShowing}
                onClose={() => {
                    setModalAddTransactionShowing(false);
                }}
                initialStockCode={stockCode}
            />
            <CreateAlertModalComponent />
            {/* <ListAlertModalComponent /> */}
            <RemoveAlertModalComponent />
        </AppLayout >
    );
}

export default StockInfoScreen;
