import eventApi from 'api/eventApi';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalEventInfo from '../ModalEventInfo';

ModalAllEvents.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalAllEvents.defaultProps = {
    show: false,
    onClose: null,
};

function ModalAllEvents(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const { recentEvents } = useSelector(state => state.app);
    const [modalEventShowing, setModalEventShowing] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allEvents, setAllEvents] = useState([]);
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        searchKey: '',
        onlyIncoming: true,
    });
    const [pagination, setPagination] = useState(null);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function getAllEvent() {
        if (loading) {
            return;
        }
        setLoading(true);
        const res = await eventApi.getAll(filter);
        const { result, total, count, page, events } = res;
        if (result === 'success' && events) {
            if (page === 0) {
                setAllEvents(events);
            } else {
                setAllEvents(allEvents.concat(events));
            }
            setPagination({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoading(false);
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(pagination);
            console.log({ nextPage });
            if (nextPage) {
                setFilter({
                    ...filter,
                    page: nextPage,
                });
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getAllEvent();
    }, [filter]);

    return (
        <div>
            <Modal
                size='lg'
                scrollable={true}
                // backdrop='static'
                className=''
                show={show}
                // centered={true}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('AllFinancialEvents')}
                    </Modal.Title>
                    <BaseSearchBar
                        name='searchEvent'
                        placeholder={`${t('TypeToSearch')}...`}
                        onSubmit={(text) => {
                            setFilter({
                                ...filter,
                                page: 0,
                                searchKey: text,
                            })
                        }}
                    />
                </Modal.Header>
                <Modal.Body className='max-h-500px' onScroll={handleScroll}>
                    {/* content */}
                    <MGDataTable
                        className='bg-white mt-2'
                        headItems={[
                            { title: t('Event'), subTitle: '', col: 'col-5' },
                            { title: t('Location'), subTitle: '', col: 'col-4' },
                            { title: t('Date'), subTitle: '', col: 'col-3' },
                        ]}
                        dataItems={
                            allEvents.map((item, index) => {
                                return {
                                    onPress: () => {
                                        setModalEventShowing(true);
                                        setSelectedEvent(item);
                                    },
                                    data: [
                                        {
                                            icon: '',
                                            title: item.name,
                                            subTitle: '',
                                            name: '',
                                            value: '',
                                            valueColor: '',
                                            col: 'col-5'
                                        },
                                        {
                                            icon: '',
                                            title: '',
                                            subTitle: '',
                                            name: item.location,
                                            value: '',
                                            valueColor: '',
                                            col: 'col-4'
                                        },
                                        {
                                            icon: '',
                                            title: '',
                                            subTitle: '',
                                            name: Utils.formatDateTime(item.time, 'DD/MM/YYYY'),
                                            value: '',
                                            valueColor: '',
                                            col: 'col-3'
                                        },
                                    ]
                                }
                            })
                        }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className='font-weight-bold' variant="secondary" onClick={() => {
                        handleClose();
                    }}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal event */}
            <ModalEventInfo
                show={modalEventShowing}
                event={selectedEvent}
                onClose={() => {
                    setModalEventShowing(false);
                }}
            />
        </div>
    );
}

export default ModalAllEvents;