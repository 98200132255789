import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import NotFound from "general/components/NotFound";
import GuestRoute from "general/components/Routes/GuestRoute";
import PrivateRoute from "general/components/Routes/PrivateRoute";

import SignInScreen from "features/Auth/screens/SignInScreen";
import SignUpScreen from "features/Auth/screens/SignUpScreen";
import AboutUsScreen from "features/Others/screens/AboutUsScreen";
import ContactUsScreen from "features/Others/screens/ContactUsScreen";
import FAQScreen from "features/Others/screens/FAQScreen";
import PolicyPrivacyScreen from "features/Others/screens/PolicyPrivacyScreen";
import TermsScreen from "features/Others/screens/TermsScreen";

import DataCommonListener from "app/DataCommonListener";
import FirebaseListener from "app/FirebaseListener";
import AccountListener from "features/Account/AccountListener";
import ForgotPasswordScreen from "features/Auth/screens/ForgotPasswordScreen";
import ResetPasswordScreen from "features/Auth/screens/ResetPasswordScreen";
import PushNotification from "features/Notification/components/PushNotification";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";

import Account from "features/Account";
import Article from "features/Article";
import Course from "features/Course";
import Home from "features/Home";
import IdeaList from "features/IdeaList";
import { CreateAlertModalComponent } from "features/IdeaList/components/BuildYourScreen/CreateAlertModal";
import { FilterModalComponent } from "features/IdeaList/components/BuildYourScreen/FilterModal";
import { ListAlertModalComponent } from "features/IdeaList/components/BuildYourScreen/ListAlertModal";
import { RemoveAlertModalComponent } from "features/IdeaList/components/BuildYourScreen/RemoveAlertModal";
import KnowledgeCenter from "features/KnowledgeCenter";
import MGProduct from "features/MGProduct";
import Notification from "features/Notification";
import Payment from "features/Payment";
import Research from "features/Research";
import Stock from "features/Stock";
import Video from "features/Video";
import Webinar from "features/Webinar";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

window.moment = require('moment')

// Load plugins
require("assets/plugins/ktutil");
require("assets/plugins/ktmenu");
require("assets/plugins/ktoffcanvas");
require("assets/plugins/ktcookie");

// Lazy load - Code splitting
// const Home t= React.lazy(() => import("./features/Home"));
// const KnowledgeCenter = React.lazy(() => import("./features/KnowledgeCenter"));
// const Account = React.lazy(() => import('./features/Account'));
// const Research = React.lazy(() => import('./features/Research'));
// const Course = React.lazy(() => import('./features/Course'));
// const Stock = React.lazy(() => import('./features/Stock'));
// const IdeaList = React.lazy(() => import('./features/IdeaList'));
// const MGProduct = React.lazy(() => import('./features/MGProduct'));
// const Article = React.lazy(() => import('./features/Article'));
// const Webinar = React.lazy(() => import('./features/Webinar'));
// const Video = React.lazy(() => import('./features/Video'));
// const Payment = React.lazy(() => import('./features/Payment'));
// const Notificaion = React.lazy(() => import('./features/Notification'));

const sTag = '[App]';
const timeout = { enter: 300, exit: 200 }

function App() {
  // MARK: --- Params ---
  const location = useLocation();

  // MARK: --- Hooks ---
  useEffect(() => {
    console.log(`${sTag} viewDidLoad`);

    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());

    if (process.env.REACT_APP_DEV_TOOLS == 0) {
      document.onkeydown = function (e) {
        // disable F12 key
        if (e.keyCode == 123) {
          return false;
        }
      }
    }

    return () => { }
  }, []);

  return (
    <>
      <Route render={({ location }) => (
        <>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={timeout}
              classNames='fade'
              unmountOnExit>
              {/* <Suspense fallback={<AppLoader />}> */}
              <Switch location={location}>
                <Redirect exact from="/" to="/home" />

                {/* Guest route */}
                <GuestRoute path="/sign-in" component={SignInScreen} />
                <GuestRoute path="/sign-up" component={SignUpScreen} />
                <GuestRoute path="/forgot-password" component={ForgotPasswordScreen} />
                <GuestRoute path="/reset-password" component={ResetPasswordScreen} />

                {/* Private route */}
                <PrivateRoute path="/account" component={Account} />

                {/* Route */}
                {/* quybka */}
                {/* Home */}
                <Route path="/home" component={Home} />
                {/* Knowledge Center */}
                <Route path="/knowledge-center" component={KnowledgeCenter} />
                {/* Research */}
                <Route path='/research' component={Research} />
                {/* Course */}
                <Route path='/course' component={Course} />
                {/* Stock */}
                <Route path='/stock' component={Stock} />
                {/* IdeaList */}
                <Route path='/idea-list' component={IdeaList} />
                {/* MGProduct */}
                <Route path='/product' component={MGProduct} />
                {/* Article */}
                <Route path='/article' component={Article} />
                {/* Webinar */}
                <Route path='/webinar' component={Webinar} />
                {/* Video */}
                <Route path='/video' component={Video} />
                {/* Payment */}
                <Route path='/payment' component={Payment} />
                {/* Notification */}
                <Route path='/notification' component={Notification} />

                {/* Others */}
                <Route path="/about-us" component={AboutUsScreen} />
                <Route path="/contact-us" component={ContactUsScreen} />
                <Route path="/terms" component={TermsScreen} />
                <Route path="/policy-privacy" component={PolicyPrivacyScreen} />
                <Route path="/faq" component={FAQScreen} />
                {/* --- */}

                {/* Not found */}
                <Route component={NotFound} />

              </Switch>
              {/* </Suspense> */}

            </CSSTransition>
          </TransitionGroup>
        </>
      )} />
      {/* Toast */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      {/* Data Common Listener */}
      <DataCommonListener />
      {/* Account Listener */}
      <AccountListener />
      {/* Push Notification */}
      <PushNotification />
      {/* Firebase Listener */}
      <FirebaseListener />

      <ListAlertModalComponent />
      <CreateAlertModalComponent />
      <RemoveAlertModalComponent />
      <FilterModalComponent />
    </>
  );
}

export default App;
