import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

ModalConfirmDelete.propTypes = {
    onShow: PropTypes.func,
    onClose: PropTypes.func,
    deleteItem: PropTypes.func

};

ModalConfirmDelete.defaultProps = {
    onShow: null,
    onClose: null,
    deleteItem: null
}

function ModalConfirmDelete(props) {


    // const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const { t } = useTranslation();
    const { onShow, onClose, deleteItem, deleteText } = props

    function handleShow(){ 
        if(onShow){
            onShow()
        }
    }

    function handleClose(){
        if(onClose){
            onClose()
        }
    }

    function handleDeleteItem(){
        if(deleteItem){
            deleteItem()
        }
    }

    return (
        <div>
            <Modal 
                    {...props}
                    onHide={handleClose}
                    centered
                    size='md'
                    className=''
                    >
                        <Modal.Header className='py-3 '>
                            <span className='font-weight-bolder font-size-base text-dark'>{t('Delete')}</span>
                            <div 
                                onClick={() => {
                                    handleClose();
                                }} 
                                className='btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer'>
                                    <i className="fal fa-times"></i>
                                </div>
                        </Modal.Header>
                        <Modal.Body className='bg-light d-flex align-items-center'>
                            <i className="fas fa-trash-alt fa-2x"></i>
                            <span className='font-weight-bolder font-size-base text-dark ml-6'>{deleteText}</span>
                        </Modal.Body>
                        <Modal.Footer className='d-flex flex-row justify-content-between'>
                            <button 
                            className='border btn btn-secondary font-weight-bolder font-size-base'
                            style={{width: 220}}
                            onClick={()=>{
                                handleClose();
                                }}
                            >
                            {t('Cancel')}
                            </button>

                            <button
                            className='border-0 btn btn-primary font-weight-bolder font-size-base'
                            style={{width: 220}}
                            onClick={()=>{
                                handleDeleteItem();
                                handleClose()
                            }}>
                            {t('Delete')}
                            </button>
                        </Modal.Footer>
                    </Modal>
        </div>
    );
}

export default ModalConfirmDelete;