import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactPlayer from "react-player";
import Utils from "../../../../general/utils/Utils";
import { AppImages } from 'general/constants/AppResource';
import { useSelector } from "react-redux";
import './style.scss'
import _ from 'lodash';

CellCourseGrid.propTypes = {};

function CellCourseGrid(props) {
    // MARK: --- Params ---
    const { name, level, description, video, price, thumbnail, courseLessions, author, courseId } = props.info;
    const courseTransaction = useSelector(state => state?.transaction?.course)
    let isPurchased = (courseTransaction.find((course) => course?.courseId === courseId))?.status == 'SUCCESS'
    const { t } = useTranslation();
    const history = useHistory();

    // MARK: --- Functions ---
    function handleClick() {
        history.push(`/course/${courseId}`);
    }

    return (
        <div className='CellCourseGrid cursor-pointer hover-opacity-60' onClick={handleClick}>
            <div className="custom-div-img">

                <div className="custom-div-img">
                    {isPurchased && <img src={AppImages.imgPurchasedCourseTag} className={"custom-top-tag w-30"} />}

                    <img
                        className='CellCourseGrid_Image w-100 custom_image_course rounded'
                        alt='thumbnail'
                        draggable={false}
                        src={Utils.getFullUrl(thumbnail)}
                        style={{
                            aspectRatio: '16/9',
                            backgroundColor: '#f3f5f8',
                            objectFit: 'cover'
                        }}
                        onError={(e) => {
                            e.target.src = AppImages.imgDefaultThumbnail;
                        }}
                    />
                </div>
                <img
                    draggable={false}
                    src={isPurchased ? AppImages.imgPurchasedCourseBottomBanner : AppImages.imgCourseBottomBanner}
                    className={"custom-banner-bottom w-100"}
                />
                {isPurchased && <div className="custom-tag-name">{t('purchased_tag')}</div>}
                <div className="custom-level-area">
                    <span className="custom-level-title">{t('Level') + ": "}</span>
                    <span className="custom-level-text">{Utils.getCourseLevelDesc(level)}</span>
                </div>
                <div className="custom-price-area">
                    <span className="custom-text-price">{
                        (price > 0) ? Utils.formatNumber(price) + " VND" : "Miễn Phí"
                    }</span>
                </div>


            </div>
            <div className='mt-4'>
                <p
                    className='font-size-h5 font-weight-bolder m-0'
                    style={{
                        color: '#18214d'
                    }}
                >
                    {name}
                </p>
                <p
                    className='font-weight-bold my-2 text-muted'
                >
                    {`${t('Lesson')}: `}<span className='text-dark-75'>{courseLessions?.length + " Bài"}</span>&nbsp;&nbsp;
                    {
                        !_.isEmpty(author) && (
                            <span>|&nbsp;&nbsp;{`${t('CourseAuthor')}: `}<span className='text-dark-75'>{author}</span></span>
                        )
                    }
                </p>
                <p
                    className='font-size-lg m-0 q-max-line-3'
                    style={{
                        color: '#5c6077'
                    }}
                >
                    {description}
                </p>
            </div>
        </div>
    );
}

export default CellCourseGrid;
