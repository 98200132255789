const AppConfigs = {
    appVersion: '1.0',
    appBuild: 1,
    emailSupport: 'moneygainvn@gmail.com',
    copyright: '@MoneyGainDauTuThongThai',
    hotline: '02873072088',
    companyName: 'MoneyGain VN CO., LTD',
    companyAddress: "Việt Nam",
    facebookPage: 'https://www.facebook.com/MoneyGainDauTuThongThai/',
    messengerPage: 'https://www.facebook.com/messages/t/103328555394438',
    youtubePage: 'https://www.youtube.com/c/MoneyGainDauTuThongThai',
    hashtag: '#MoneyGain',
    fileSampleListImport: 'resources/importing-myList-sample.xlsx',
};

export default AppConfigs;