import React from 'react';
import PropTypes from 'prop-types';

CellQuickStockSearch.propTypes = {
    item: PropTypes.object,
    showDivider: PropTypes.bool,
    onPress: PropTypes.func,
    onContextMenu: PropTypes.func,
};

CellQuickStockSearch.defaultProps = {
    item: {},
    showDivider: true,
    onPress: null,
    onContextMenu: null,
};

function CellQuickStockSearch(props) {
    // MARK: --- Params ---
    const { item, showDivider, onPress, onContextMenu } = props;

    // MARK: --- Functions ---
    function handlePress(e) {
        e.preventDefault();
        if (onPress) {
            onPress();
        }
    }

    function handleRightClick(e) {
        e.preventDefault();
        if (onContextMenu) {
            onContextMenu();
        }
    }

    return (
        <div className={`CellQuickStockSearch hover-opacity-60 cursor-pointer`} onClick={handlePress} onContextMenu={handleRightClick}>
            <div className='d-flex flex-column px-5'>
                <a href='#' onClick={e => e.preventDefault()} className="font-weight-bold font-size-lg text-dark flex-grow-1">{item?.stockCode}</a>
                <span className="font-size-sm font-weight-bold text-muted flex-grow-1" style={{ whiteSpace: 'normal' }}>{item?.stock?.stockName}</span>
            </div>

            {
                showDivider && (
                    <hr className='my-4' />
                )
            }
        </div >
    );
}

export default CellQuickStockSearch;