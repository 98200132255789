import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { resetTransactionHistory, setTransactionHistoryFilter, thunkGetHistoryTransaction, thunkRemoveStock } from 'features/MyList/myListSlice';
import { AppIcons } from 'general/constants/AppResource';
import ModalConfirmDelete from 'general/components/ModalConfirmDelete';
import EmptyView from 'general/components/Views/EmptyView';
import Utils from 'general/utils/Utils';

ModalTransactionHistory.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onShow: PropTypes.func,
    onEditSuccess: PropTypes.func,
};

ModalTransactionHistory.defaultProps = {
    show: false,
    onClose: null,
    onShow: null,
    onEditSuccess: null,
};

function ModalTransactionHistory(props) {
    // MARK: --- Params ---
    const { show, onClose, onShow, onEditSuccess } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { transactionHistorys, selectedMyListId, transactionHistoryPagination, transactionHistoryFilter, selectedMyList } = useSelector(state => state.myList);
    const [modalConfirmDeleteFilter, setModalConfirmDeleteFilter] = useState(false);
    const [myListStockIdDeleted, setMyListStockIdDeleted] = useState();

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }
    function handleShow() {
        if (onShow) {
            onShow();
        }
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(transactionHistoryPagination);
            console.log({ nextPage });
            if (nextPage) {
                dispatch(setTransactionHistoryFilter({
                    page: nextPage,
                }));
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (selectedMyList) {
            dispatch(thunkGetHistoryTransaction(selectedMyList.myListId));
        }
    }, [transactionHistoryFilter]);

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
                size='xl'
                onExited={() => {
                    dispatch(resetTransactionHistory());
                    if (onEditSuccess) {
                        onEditSuccess();
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('TransactionHistory')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className='d-block overflow-auto border' style={{ maxHeight: '44vh' }} onScroll={handleScroll}>
                        {
                            transactionHistorys.length > 0 ? (
                                <table className="table bg-white m-0">
                                    <thead>
                                        <tr>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('StockCode')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Command')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('TransactionTime')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Price')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Quantity')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Notes')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionHistorys.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='font-weight-bolder' style={{ verticalAlign: 'middle' }}>{item?.stockCode}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{t(item?.command)}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <span>{Utils.formatDateTime(item?.datetime, 'DD/MM/YYYY HH:mm')}</span>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <span>{Utils.formatNumber(item?.price)}</span>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <span>{Utils.formatNumber(item?.quantity)}</span>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <span>{item.note}</span>
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <div className='py-3'>
                                                            <img
                                                                src={AppIcons.icSquareXMark}
                                                                draggable={false}
                                                                className='cursor-pointer hover-opacity-70'
                                                                onClick={() => {
                                                                    setModalConfirmDeleteFilter(true);
                                                                    setMyListStockIdDeleted(item.myListStockId)
                                                                    handleClose();
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }

                    </div>
                </Modal.Body>
            </Modal>
            <ModalConfirmDelete
                show={modalConfirmDeleteFilter}
                onClose={() => {
                    setModalConfirmDeleteFilter(false);
                    handleShow();
                }}
                deleteItem={() => {
                    dispatch(thunkRemoveStock(myListStockIdDeleted));
                    setMyListStockIdDeleted();
                    handleShow();
                }}
                deleteText={t('ConfirmDeleteTransaction') + '?'}
                onShow={() => { setModalConfirmDeleteFilter(true) }}
            />
        </div>
    );
}

export default ModalTransactionHistory;