import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { useTranslation } from 'react-i18next';
import CellArticleGrid from 'features/Article/components/CellArticleGrid';
import CellArticleList from 'features/Article/components/CellArticleList';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';

HomeSectionResearch.propTypes = {

};

function HomeSectionResearch(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { topResearches } = useSelector(state => state.app);
    const history = useHistory();

    return (
        <div className='HomeSectionResearch'>
            <div className='bg-white py-10'>
                <div className='container-xxl'>
                    <SectionHeaderView
                        title={t('AnalysisCenter')}
                        buttonTitle={t('ViewAll')}
                        onPressButton={() => {
                            history.push('/research')
                        }}
                    />

                    <div className='row mt-6'>
                        {
                            topResearches && topResearches.length > 0 && (
                                <>
                                    <div className='col-12 col-md-6'>
                                        <CellArticleGrid
                                            item={topResearches[0]}
                                            onPress={() => {
                                                history.push(`/research/${topResearches[0].articleId}`);
                                            }}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 mt-4 mt-md-0'>
                                        <div className='row'>
                                            {
                                                topResearches.map((item, index) => {
                                                    if (index > 0) {
                                                        return (
                                                            <div key={index} className='col-12 pb-4'>
                                                                <CellArticleList
                                                                    item={item}
                                                                    onPress={() => {
                                                                        history.push(`/research/${item.articleId}`);
                                                                    }}
                                                                    showDivider={index < topResearches.length - 1}
                                                                />
                                                            </div>
                                                        )
                                                    } else {
                                                        return <div key={index}></div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            topResearches.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSectionResearch;