import React from "react";
import PropTypes from "prop-types";
import { AppIcons } from "general/constants/AppResource";
import { AppImages } from "general/constants/AppResource";
import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import { useSelector } from 'react-redux'
ModalPaymentSuccess.propTypes = {};

/**
 * 
 * @param {ModalProps} props 
 * @returns 
 */
function ModalPaymentSuccess(props) {
  const { t } = useTranslation();
  const { current }: { current: { username: String, fullname: String } } = useSelector(
    (state) => state.auth ?? {}
  );

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <p className="font-weight-bolder font-size-base ">
            {t("PaymentSuccess")}
          </p>
        </Modal.Title>
        <div
          className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
          onClick={() => {
            if (props.onHide) {
              props.onHide();
            }
          }}
        >
          <i className="far fa-times"></i>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <img src={AppImages.imgPaymentSuccess}
          draggable={false} className="w-100 my-2" />

        <p className="line-height-lg mt-2">
          {t("Thanks")} <span className="purple font-weight-bolder">{current?.fullname ?? current?.username}</span>
        </p>
        <p className="font-weight-normal mt-2">
          {t("CongratSuccessfulPayment")}
        </p>
        <p className="font-weight-normal mt-2">
          {t("GreetingsAfterPayment")}
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPaymentSuccess;
