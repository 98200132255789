import {Modal} from "react-bootstrap";
import React, {createRef, useLayoutEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "../style.scss"
import ConditionalCheckbox from "../../ConditionalCheckbox";
import {useSelector} from "react-redux";

const FilterModalRef = createRef()

export default {
    show: () => {
        FilterModalRef.current?.show()
    },
    hide: () => {
        FilterModalRef.current?.hide()
    }
}

export function FilterModalComponent(){
    const [modalVisible, setModalVisible] = useState(false)
    const { t } = useTranslation();

    const allConditional = useSelector(state=>state?.ideaList?.filter?.allConditional)

    let basicList = allConditional.filter((item)=>item.filterConditionalId<=2)
    let statisticList = allConditional.filter((item)=>item.filterConditionalId>2)

    useLayoutEffect(() => {
        FilterModalRef.current = {
            'show': () => {
                setModalVisible(true)
            },
            'hide': () => setModalVisible(false)
        }
    }, [])

    return (
        <>
            <Modal
                show={modalVisible}
                onHide={()=>setModalVisible(false)}
                backdrop={true}
                keyboard={false}
                size={"xl"}
            >
                <Modal.Header style={{height: "50px"}}>
                    <Modal.Title>{t("AddConditionalToFilter")}</Modal.Title>
                    <div className={"custom-right-component-modal"}>
                        <div className={"custom-div-chart close-button hover-opacity-50"} onClick={()=>setModalVisible(false)}>
                            <div className={"custom-close-button"}>
                                <i className="far fa-times icon-xl font-weight-light"/>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className={"body-modal-custom"}>
                    <div className={"custom-heading-text"}>
                        <span className={"font-weight-bold"}>{"Thông tin cơ bản"}</span>
                    </div>
                    <div className={"row custom-check-area"}>
                        {
                            basicList.map((item, index)=> {
                                return <ConditionalCheckbox filterConditionalId={item?.filterConditionalId} key={item?.filterConditionalId}/>
                            })
                        }
                    </div>
                    <div className={"custom-heading-text"}>
                        <span className={"font-weight-bold"}>{"Thống kê giá"}</span>
                    </div>
                   <div className={"row custom-check-area"}>
                       {
                           statisticList.map((item, index)=> {
                               return <ConditionalCheckbox filterConditionalId={item?.filterConditionalId}  key={item?.filterConditionalId}/>
                           })
                       }
                   </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

