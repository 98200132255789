import mgIdentifyApi from 'api/mgIdentifyApi';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

ModalMgIdentifies.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalMgIdentifies.defaultProps = {
    show: false,
    onClose: null,
};

function ModalMgIdentifies(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        q: '',
        orderField: 'datetime',
        orderMode: 'DESC'
    });
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(false);
    const [allIdentifies, setAllIdentifies] = useState([]);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function getAllIdentifies() {
        if (loading) {
            return;
        }
        setLoading(true);
        const res = await mgIdentifyApi.getIdentifyList(filter);
        const { result, total, count, page, mgIdentifies } = res;
        if (result === 'success' && mgIdentifies) {
            if (page === 0) {
                setAllIdentifies(mgIdentifies);
            } else {
                setAllIdentifies(allIdentifies.concat(mgIdentifies));
            }
            setPagination({
                total: total,
                count: count,
                currentPage: page
            });
        }
        setLoading(false);
    }

    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(pagination);
            console.log({ nextPage });
            if (nextPage) {
                setFilter({
                    ...filter,
                    page: nextPage,
                });
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getAllIdentifies();
    }, [filter]);

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('IdentifyOfMG')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='overflow-auto p-0' style={{
                    maxHeight: '84vh'
                }} onScroll={handleScroll}>
                    {
                        allIdentifies.map((item, index) => {
                            return (
                                <div key={index} className=''>
                                    <div className='py-4 px-6'>
                                        <p className='font-weight-bold'>{item.title}</p>
                                        <p>{item.description}</p>
                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                            <p className=''>{`${t('By')}: `}<span className='text-primary font-weight-bold'>{item.author}</span></p>
                                            <p className='font-italic'>{Utils.formatDateTime(item.datetime, 'DD/MM/YYYY HH:mm')}</p>
                                        </div>
                                    </div>
                                    {
                                        index < allIdentifies.length - 1 && (
                                            <hr className='m-0' />
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default ModalMgIdentifies;