import moment from 'moment';
import { sha256 } from 'js-sha256';
import Global from 'general/Global';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import PreferenceKeys from 'general/constants/PreferenceKeys';
import store from 'app/store';
import { setReload } from 'features/Notification/notificationSlice';
import _ from 'lodash'
import { element } from 'prop-types';

const Utils = {
    // sha256
    sha256: (text) => {
        return sha256(text);
    },

    // Get full url
    getFullUrl: (url) => {
        if (url && !url.startsWith('http') && !url.startsWith('blob')) {
            return `${process.env.REACT_APP_BASE_URL}${url}`;
        }
        return url;
    },

    // Check object empty
    isObjectEmpty: (obj) => {
        return Utils.isObjectNull(obj) || (Object.keys(obj).length === 0 && obj.constructor === Object)
    },

    // Check object null|undefine
    isObjectNull: (obj) => {
        return obj === null || obj === undefined || obj === 'NULL' || obj === 'null'
    },

    // convert first character of string to uppercase
    convertFirstCharacterToUppercase: (stringToConvert) => {
        var firstCharacter = stringToConvert.substring(0, 1)
        var restString = stringToConvert.substring(1)
        return firstCharacter.toUpperCase() + restString
    },

    // format number
    formatNumber: (iNumber) => {
        // if (iNumber < 0) {
        //     return '---';
        // }
        if (isNaN(iNumber)) {
            return '---';
        }
        const iRet = new Intl.NumberFormat('de-DE').format(iNumber);
        if (_.isNaN(iRet)) {
            return '0';
        }
        return iRet;
    },

    qFormatNumber: (number, join = '.') => {
        const sNumber = `${number}`.split('').reverse().join('');
        const chunks = Utils.chunkItems(sNumber, 3);
        return chunks.join(join).split('').reverse().join('');
    },

    // format date time
    formatDateTime: (sDateTime, sFormat = 'DD/MM/YYYY HH:mm', utc = false) => {
        if (utc) {
            return moment(sDateTime).utc().format(sFormat);
        }
        return moment(sDateTime).local().format(sFormat);
    },

    // add date time
    formatAddDateTime: (sDateTime, amount, unit, sFormat, utc = false) => {
        if (utc) {
            return moment(sDateTime).utc().add(amount, unit).format(sFormat);
        }
        return moment(sDateTime).local().add(amount, unit).format(sFormat);
    },

    // get time ago
    timeAgo: (sDateTime) => {
        const momentTime = moment.utc(sDateTime);
        return momentTime.fromNow();
    },

    // random item
    randomItem: (items) => {
        return items[Math.floor(Math.random() * items.length)];
    },

    // open link in new tab
    openInNewTab: (url) => {
        window.open(url, '_blank').focus();
    },

    // get next pagination
    getNextPage: (pagination) => {
        const { total, count, currentPage } = pagination;

        const hasMorePage = ((currentPage + 1) * Global.gDefaultPagination) < total;
        if (hasMorePage) {
            return currentPage + 1;
        }

        return null;
    },

    // format thời gian sang dạng HH:mm
    displayDuration(seconds) {
        const h = Math.floor(seconds / 3600)
        const m = Math.floor((seconds % 3600) / 60)
        const s = Math.round(seconds % 60)
        return [
            h,
            m > 9 ? m : (h ? '0' + m : m || '0'),
            s > 9 ? s : '0' + s
        ].filter(Boolean).join(':')
    },

    // Change empty to null
    formatEmptyKey: (items) => {
        for (const [key, value] of Object.entries(items)) {
            if (value === '') {
                items[key] = null;
            }
        }
    },

    // remove null key
    removeNullKey: (items) => {
        for (const [key, value] of Object.entries(items)) {
            if (value === null) {
                delete items[key];
            }
        }
    },

    // remove empty key
    removeEmptyKey: (items) => {
        for (const [key, value] of Object.entries(items)) {
            if (value === '') {
                delete items[key];
            }
        }
    },

    // Delete null
    formatNullKey: (items) => {
        for (const [key, value] of Object.entries(items)) {
            if (value === null) {
                delete items[key];
            }
        }
    },

    // scroll to top
    scrollToTop: () => {
        window.scroll({ left: 0, top: 0, behavior: 'smooth' });
    },



    // get current url
    getCurrentUrl: () => {
        return window.location.href;
    },

    // get last array item
    getLastItem: (items) => {
        if (items && Array.isArray(items) && items.length > 0) {
            return items[items.length - 1];
        }
        return null;
    },

    // scroll div to bottom
    scrollToBottom: (id) => {
        var div = document.getElementById(id);
        if (div) {
            div.scrollTop = div.scrollHeight - div.clientHeight;
        }
    },

    // split array
    chunkItems: (items, chunkSize) => {
        let arrRet = [];
        for (let i = 0; i < items.length; i += chunkSize) {
            const chunk = items.slice(i, i + chunkSize);
            arrRet.push(chunk);
        }
        return arrRet;
    },

    // group by
    groupBy: (data, key) => { // `data` is an array of objects, `key` is the key (or property accessor) to group by
        // reduce runs this anonymous function on each element of `data` (the `item` parameter,
        // returning the `storage` parameter at the end
        return data.reduce(function (storage, item) {
            // get the first instance of the key by which we're grouping
            var group = item[key];

            // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
            storage[group] = storage[group] || [];

            // add this item to its group within `storage`
            storage[group].push(item);

            // return the updated storage to the reduce function, which will then loop through the next
            return storage;
        }, {}); // {} is the initial value of the storage
    },

    getCourseLevelDesc: (level) => {
        const { t } = useTranslation();
        let desc = ''
        switch (level) {
            case 1:
                desc = t("Basic")
                break;
            case 2:
                desc = t("Medium")
                break;
            case 3:
                desc = t("Advanced")
                break;
            default:
                break;
        }
        return desc
    },

    // Get position name from array
    getPositionNames: (positions) => {
        if (positions === null || !Array.isArray(positions) || positions.length === 0) {
            return '';
        }

        const arrMappedItems = positions.map(item => item?.dataValues?.positionName);
        const positionNames = arrMappedItems.join(', ');
        return positionNames;
    },

    // Decode html
    decodeHTML: (html) => {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    },

    formatMonthYear: (text) => {
        const isValid = moment(text, "DD/MM/YYYY", true).isValid();
        if (!isValid) {
            return text;
        }

        return moment(text, "DD/MM/YYYY").format("MM/YYYY");
    },
    getFilterConditionalTitle: (filterConditionalId) => {
        let title = ''
        switch (filterConditionalId) {
            case 1:
                title = "SelectExchangeCode"
                break;
            case 2:
                title = "SelectIndustry"
                break;
            case 3:
                title = "EPSConditional"
                break;
            case 4:
                title = "BVPSConditional"
                break;
            case 5:
                title = "YearROEConditional"
                break;
            case 6:
                title = "YearROAConditional"
                break;
            case 7:
                title = "YearROSConditional"
                break;
            case 8:
                title = "CapitalConditional"
                break;
            default:
                break;
        }
        return title
    },

    // Remove duplicate from array
    removeDuplicateArray: (array) => {
        let ret = [];
        array.forEach(element => {
            if (element && !ret.includes(element)) {
                ret.push(element);
            }
        });
        return ret;
    },

    // Get stock color by value
    getStockColor: (value) => {
        if (isNaN(value)) {
            return '';
        }
        if (value < 0) {
            if (value <= -6.5) {
                // xanh lo
                return '#00C9FF';
            }
            // do
            return '#FF0017';
        }
        if (value > 0) {
            // tim
            if (value >= 6.5) {
                return '#F23AFF';
            }
            // xanh la cay
            return '#0BDF39';
        }
        if (value === 0) {
            return 'orange';
        }
    },
    // get value color
    getValueColor: (value) => {
        if (value < 0) {
            return '#FF0000';
        } else if (value > 0) {
            return '#0BDF39';
        } else {
            return '#23262F';
        }
    },

    getCompareTitle(type) {
        let res = null
        switch (type) {
            case 0:
                res = "Equal"
                break;
            case 1:
                res = "Greater"
                break;
            case 2:
                res = "Smaller"
                break;
            case 3:
                res = "GreaterOrEqual"
                break;
            case 4:
                res = "SmallerOrEqual"
                break;
            default:
                break;
        }
        return res
    },
    getCompareSign(type) {
        let res = "="
        switch (type) {
            case 1:
                res = ">"
                break;
            case 2:
                res = "<"
                break;
            case 3:
                res = "≥"
                break;
            case 4:
                res = "≤"
                break;
            default:
                break;
        }
        return res
    },
    getAlertType(type) {
        let res = "Price"
        switch (type) {
            case 2:
                res = "TradingVolume"
                break;
            default:
                break;
        }
        return res
    },
    getUnitByFilterConditionalId(filterConditionalId) {
        let res = null
        switch (filterConditionalId) {
            case 3:
                res = "VND"
                break;
            case 4:
                res = "VND"
                break;
            case 5:
                res = "%"
                break;
            case 6:
                res = "%"
                break;
            case 7:
                res = "%"
                break;
            default:
                break;
        }
        return res
    },

    abbreviateNumber(number) {
        let SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];
        let tier = Math.log10(Math.abs(number)) / 3 | 0;
        if (tier == 0) return number;
        let suffix = SI_SYMBOL[tier];
        let scale = Math.pow(10, tier * 3);
        let scaled = number / scale;
        return scaled.toFixed(2) + suffix;
    },

    downloadURI: (uri, name) => {
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', name);
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        link.remove();
    },

    addReadNotificationId: (id) => {
        if (Utils.checkNotificationRead(id)) {
            return;
        }
        const currentReadNotificationIds = localStorage.getItem(PreferenceKeys.savedReadNotificationIds) ?? '';
        const readNotificationIds = currentReadNotificationIds.concat(`|${id}`);
        localStorage.setItem(PreferenceKeys.savedReadNotificationIds, readNotificationIds);
        store.dispatch(setReload(true));
    },

    checkNotificationRead: (id) => {
        const currentReadNotificationIds = localStorage.getItem(PreferenceKeys.savedReadNotificationIds) ?? '';
        if (currentReadNotificationIds.length > 0) {
            return currentReadNotificationIds.includes(`|${id}`);
        }
        return false;
    },

    getStockPriceChange: (price, refPrice) => {
        if (price === 0 || refPrice === 0) {
            return 0;
        }
        return (price - refPrice);
    },

    getTextStockPriceChangeInPercent: (price, refPrice) => {
        if (price === 0 || refPrice === 0) {
            return '0%';
        }
        return ((price - refPrice) * 100 / refPrice).toFixed(2) + '%';
    },

    getStockPriceChangeInPercent: (price, refPrice) => {
        if (price === 0 || refPrice === 0) {
            return 0;
        }
        return ((price - refPrice) * 100 / refPrice);
    },

    // check localhost
    isLocalhost: () => {
        return (
            window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.0/8 are considered localhost for IPv4.
            window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
        )
    },

    // get transaction status color
    getTransactionStatusColor: (status) => {
        if (status === 'SUCCESS') {
            return '#0BDF39';
        } else if (status === 'FAILED') {
            return '#FF0017';
        } else if (status === 'PENDING') {
            return 'orange';
        }

        return '#23262F';
    }

};

export default Utils

/**
 * scroll to top on mounted and history changed  
 *   
 * **MUST** place inside a router component where `useHistory` and `useEffect` is usable
 * ```js
 * export function(){
 *  ...
 *  useScrollToTop()
 *  ...
 * 
 *  return <div></div>
 * }
 * ```
 * **remember to follow hook rules**
 */
export function useScrollToTop() {
    const history = useHistory();

    function scrollToTop() {
        return setTimeout(
            () => window?.scrollTo({ top: 0, left: 0, behavior: "smooth" }),
            0
        );
    }
    useEffect(() => {
        let timeout = scrollToTop();

        return history.listen(() => {
            let timeout = scrollToTop();
        });
    }, []);

    return null;
}