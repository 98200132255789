import React from 'react';
import PropTypes from 'prop-types';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { useTranslation } from 'react-i18next';
import MGDataTable from 'general/components/Tables/MGDataTable';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';

HomeSectionMyList.propTypes = {

};

function HomeSectionMyList(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const data = useSelector(state => state.app.homeMyListData);

    return (
        <div className='bg-white'>
            <div className='container-xxl py-20'>
                <div className=''>
                    <SectionHeaderView
                        title={t('MyLists')}
                        buttonTitle={t('More')}
                        dividerVisible={false}
                        onPressButton={() => {
                            history.push('/account/my-list')
                        }}
                    />
                    <MGDataTable
                        className='bg-white mt-2'
                        headItems={[
                            { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                            { title: t('Command'), col: 'col-1', },
                            { title: t('TotalVolume'), subTitle: t('Stock'), col: 'col-1' },
                            { title: t('AveragePurchasePrice'), subTitle: 'VNĐ', col: 'col-1' },
                            { title: t('MarketPrice'), subTitle: 'VNĐ', col: 'col-1' },
                            { title: t('PurchaseValue'), subTitle: 'VNĐ', col: 'col-1' },
                            { title: t('MarketValue'), subTitle: 'VNĐ', col: 'col-1' },
                            { title: t('Profit_Loss'), subTitle: '', col: 'col-1' },
                            { title: t('Profit_LossInDay'), subTitle: '', col: 'col-1' },
                        ]}
                        dataItems={
                            data.map((item, index) => {
                                return {
                                    onPress: () => {
                                        history.push(`/stock/${item?.stockCode}`);
                                    },
                                    data: [
                                        { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item.companyName, name: '', value: '', valueColor: '', col: 'col-3' },
                                        { icon: '', title: t(item.command), titleWeight: 600, subTitle: '', name: '', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.sumQuantity), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(_.round(item.priceAvg, 2)), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.marketPrice), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.value), nameColor: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.marketValue), nameColor: '', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.diff), nameColor: item.diff > 0 ? '#0BDF39' : 'red', value: '', valueColor: '', col: 'col-1' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.dayDiff), nameColor: item.dayDiff > 0 ? '#0BDF39' : 'red', value: '', valueColor: '', col: 'col-1' },
                                    ]
                                }
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default HomeSectionMyList;