import React from 'react';
import PropTypes from 'prop-types';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import { useTranslation } from 'react-i18next';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Chart from 'react-apexcharts';
import Utils from 'general/utils/Utils';
import AppData from 'general/constants/AppData';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import _ from 'lodash';

MajorHolder.propTypes = {
    majorHolders: PropTypes.array,
    ownerships: PropTypes.array,
    isSpecialStockCode: PropTypes.bool,
};

MajorHolder.defaultProps = {
    majorHolders: [],
    ownerships: [],
    isSpecialStockCode: false,
};

function MajorHolder(props) {
    // MARK: --- Params ---
    const { majorHolders, ownerships, isSpecialStockCode } = props;
    const { t } = useTranslation();
    const options = {
        chart: {
            id: 'apexchart-pie-major-holder'
        },
        labels: ownerships.map(item => item.name),
        colors: AppData.apexChartColors,
    };
    const series = ownerships.map(item => item.shares);
    const pieChartFeature = ownerships.map((item, index) => {
        return {
            label: item.name,
            color: AppData.apexChartColors[index]
        }
    })

    return (
        <div className='p-4'>
            <div className='row'>
                <div className='col-12 col-lg-4 p-0 pl-4 pr-4 pr-lg-0'>
                    {/* Co cau co dong */}
                    <div className=''>
                        <AppCardDataInfo
                            title={t('ShareholderStructure')}
                            content={(
                                <div>
                                    {
                                        ownerships.length > 0 ? (
                                            <div>
                                                <Chart
                                                    className='pieChart-hide-label'
                                                    options={options}
                                                    series={series}
                                                    type="pie"
                                                    width='100%'
                                                    height={320}
                                                />
                                                <div>
                                                    {
                                                        pieChartFeature.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="py-3 px-4 d-flex align-items-center">
                                                                        <div className="w-20px h-20px min-w-20px min-h-20px rounded" style={{ background: item.color }}></div>
                                                                        <p className="m-0 ml-3" align='center-left'>
                                                                            {item.label}
                                                                        </p>
                                                                    </div>
                                                                    {
                                                                        index < pieChartFeature.length - 1 && (
                                                                            <hr className='m-0' />
                                                                        )
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className='col-12 col-lg-8 mt-4 mt-lg-0 p-0 px-4'>
                    <MGDataTable
                        className='bg-white'
                        headItems={[
                            { title: t('HolderName'), subTitle: '', col: 'col-3' },
                            { title: t('Quantity'), subTitle: '', col: 'col-3', textAlign: 'right' },
                            { title: t('Ratio'), subTitle: '', col: 'col-3', textAlign: 'right' },
                            { title: t('Thời Gian Cập Nhật'), subTitle: '', col: 'col-3', textAlign: 'right' },
                        ]}
                        dataItems={
                            _.orderBy(majorHolders, ['ratio'], 'desc').map((item, index) => {
                                console.log({ item });
                                return {
                                    onPress: () => { },
                                    data: [
                                        { icon: '', title: item.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.quantity), nameColor: '', value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                        { icon: '', title: '', name: `${Utils.formatNumber(item.ratio)}%`, nameColor: '', value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item?.lastUpdate, 'DD/MM/YYYY HH:mm'), nameColor: '', value: '', valueColor: '', col: 'col-3', textAlign: 'right' },
                                    ]
                                }
                            })
                        }
                    />
                </div>
            </div>
        </div >
    );
}

export default MajorHolder;