import React from 'react';
import PropTypes from 'prop-types';

BaseCheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelper: PropTypes.object,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    additionClassName: PropTypes.string,
};

BaseCheckBox.defaultProps = {
    fieldProps: {},
    fieldMeta: {},
    fieldHelper: {},

    label: '',
    disabled: false,
    text: '',
    additionClassName: '',
};

function BaseCheckBox(props) {
    // MARK: --- Params ---
    const { name, fieldProps, fieldMeta, fieldHelper, label, disabled, text, additionClassName } = props;
    const { error, touched } = fieldMeta;
    const isError = error && touched;

    return (
        <div className={`form-group ${additionClassName}`}>
            <label className='checkbox checkbox-outline checkbox-primary'>
                <input
                    type='checkbox'
                    name={name}
                    {...fieldProps}
                    checked={fieldProps.value ?? false}

                />
                <span className='mr-3'></span>
                {label}
            </label>
            {
                text.length > 0 && (
                    <span className='form-text text-muted'>{text}</span>
                )
            }
            {
                isError && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{error}</div>
                    </div>
                )
            }
        </div>
    );
}

export default BaseCheckBox;