import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import AppData from 'general/constants/AppData';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Button } from 'react-bootstrap';
import CustomFrequentlyQuestionToggle from './CustomFrequentlyQuestionToggle';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

LandingPageFrequentlyQuestion.propTypes = {

};

function LandingPageFrequentlyQuestion(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const { topFaqs } = useSelector(state => state.app);

    // MARK: --- Functions ---
    function handlePressMore(e) {
        e.preventDefault();
        history.push('/faq');
    }

    return (
        <div>
            {
                topFaqs.length > 0 ? (
                    <div className='LandingPageFrequentlyQuestion py-20 bg-white'>
                        <div className='container-xxl'>
                            <p className='LandingPageFrequentlyQuestion_Title m-0'>{t('FrequentlyQuestion')}</p>
                            <div className=''>
                                <Accordion>
                                    {
                                        topFaqs.map((item, index) => {
                                            return (
                                                <Card className='rounded-0 border-right-0 border-left-0' key={index}>
                                                    <Card.Header className='bg-transparent'>
                                                        <CustomFrequentlyQuestionToggle eventKey={`${item.faqId}`}>
                                                            <p className='m-0 font-size-lg font-weight-bold text-left'>{`${t('Question')} ${index + 1}: ${item.question}`}</p>
                                                        </CustomFrequentlyQuestionToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={`${item.faqId}`}>
                                                        <Card.Body className='pl-5 pt-0'>
                                                            <p className='m-0 font-size-lg text-dark-75 pr-10'>{item.answer}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })
                                    }
                                </Accordion>

                                <a
                                    href='#'
                                    className='font-size-lg font-weight-bold d-flex align-items-center mt-6'
                                    onClick={handlePressMore}
                                >
                                    {t('ViewMore')}<i className="far fa-angle-right text-primary ml-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </div>
    );
}

export default LandingPageFrequentlyQuestion;