const { default: axiosClient } = require("./axiosClient");

const ideaListApi = {
    // Get list stock by list name
    getList: (listName, params) => {
        const url = `/ideaList/all/${listName}`;
        return axiosClient.get(url, { params });
    }
};

export default ideaListApi;