import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppIcons } from 'general/constants/AppResource';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';

IdeaListHeaderView.propTypes = {
    title: PropTypes.string,
    onMenuHandle: PropTypes.func,
    rightElement: PropTypes.element,
};

IdeaListHeaderView.defaultProps = {
    title: '',
    onMenuHandle: null,
    rightElement: null,
};

function IdeaListHeaderView(props) {
    // MARK: --- Params ---
    const { title, onMenuHandle, rightElement } = props;
    const { t } = useTranslation();

    // MARK: --- Functions ---

    return (
        <div className='IdeaListHeaderView border-bottom py-3 p-3 d-flex align-items-center justify-content-between min-h-55px'>
            <div className='d-flex align-items-center'>
                <button
                    className="btn btn-icon hover-opacity-40 d-block d-lg-none"
                    id='idea_list_aside_toggle'
                    onClick={() => {
                        if (onMenuHandle) {
                            onMenuHandle();
                        }
                    }}
                >
                    <i className="fas fa-bars text-primary" />
                </button>
                <p className='m-0 font-size-lg font-weight-bolder ml-2'>{title}</p>
            </div>

            {
                rightElement && rightElement
            }
        </div>
    );
}

export default IdeaListHeaderView;