import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import CellReviewGrid from './CellReviewGrid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import Utils from 'general/utils/Utils';

LandingPageFeaturedReview.propTypes = {

};

function LandingPageFeaturedReview(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { topFeaturedReviews } = useSelector(state => state.app);
    const breakpoint = useRef('');
    // const [chunkReviews, setChunkReviews] = useState([]);
    let chunkReviews = Utils.chunkItems(topFeaturedReviews, 3);
    // console.log({ chunkReviews });

    // MARK: --- Functions ---
    function handleWindowResize() {
        const width = window.innerWidth;
        // console.log(width, breakpoint.current, topFeaturedReviews);
        // if (width <= 576 && breakpoint.current !== 'sm' && topFeaturedReviews.length > 0) {
        //     // sm
        //     breakpoint.current = 'sm';
        //     chunkReviews = (Utils.chunkItems(topFeaturedReviews, 1));
        // }
        // if (width > 576 && width <= 768 && breakpoint.current !== 'md' && topFeaturedReviews.length > 0) {
        //     // md
        //     breakpoint.current = 'md';
        //     chunkReviews = (Utils.chunkItems(topFeaturedReviews, 2));
        // }
        // if (width >= 992 && breakpoint.current !== 'lg' && topFeaturedReviews.length > 0) {
        //     // lg
        //     breakpoint.current = 'lg';
        //     chunkReviews = (Utils.chunkItems(topFeaturedReviews, 3));
        // }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        handleWindowResize();
    }, [topFeaturedReviews]);

    return (
        <div>
            {
                topFeaturedReviews.length > 0 ? (
                    <div className='LandingPageFeaturedReview py-20'>
                        <div className='container-xxl d-flex flex-column'>
                            <p className='LandingPageFeaturedReview_Title' style={{ marginBottom: '-10px' }}>{t('FeaturedReview')}</p>
                            <div className='row'>
                                {/* {
                        topFeaturedReviews.map((item, index) => {
                            return (
                                <div key={index} className='col-12 col-sm-6 col-md-4 d-flex'>
                                    <CellReviewGrid item={item} />
                                </div>
                            )
                        })
                    } */}
                                <div className='col-12'>
                                    <Carousel
                                        className='w-100'
                                        indicators={true}
                                        controls={false}
                                    >
                                        {
                                            chunkReviews.map((item, index) => {
                                                return (
                                                    <Carousel.Item className='w-100' key={index}>
                                                        <div className='d-flex justify-content-start row'>
                                                            {
                                                                item && Array.isArray(item) && item.map((subItem, subIndex) => {
                                                                    return (
                                                                        <div className='col-12 col-sm-6 col-md-6 col-lg-4 d-flex mt-8' key={subIndex}>
                                                                            <CellReviewGrid item={subItem} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Carousel.Item>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            }
        </div>
    );
}

export default LandingPageFeaturedReview;