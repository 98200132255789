const PreferenceKeys = {
    accessToken: 'moneygain_frontend_accessToken',
    accessTokenExpired: 'moneygain_frontend_accessToken_expired',
    savedEmail: 'saved_email',
    savedPassword: 'saved_password',
    savedReadNotificationIds: 'saved_read_notification_ids',

    savedResetPasswordExpirationDate: 'saved_reset_password_expiration_date',

    fbAccessToken: 'moneygain_fb_accessToken',
    googleAccessToken: 'moneygain_google_accessToken',
    activeEmail: 'moneygain_active_email',
};

export default PreferenceKeys;