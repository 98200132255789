import axiosClient from "./axiosClient";

const authApi = {
    // sign in
    signIn: (params) => {
        const url = '/account/login';
        return axiosClient.post(url, params);
    },

    // sign in fb
    signInWithFb: (fbAccessToken) => {
        const url = '/account/loginWithFacebook';
        return axiosClient.post(url, {
            accessToken: fbAccessToken
        });
    },

    // sign in google
    signInWithGoogle: (googleAccessToken) => {
        const url = '/account/loginWithGoogle';
        return axiosClient.post(url, {
            accessToken: googleAccessToken
        });
    },

    // get current account info
    getCurrentUserInfo: () => {
        const url = '/account/profile';
        return axiosClient.get(url);
    },

    // sign up
    signUp: (params) => {
        const url = '/account/register';
        return axiosClient.post(url, params);
    },

    // sign out
    signOut: (params) => {
        const url = '/account/logout';
        return axiosClient.post(url, params);
    },
    changePassword: (params) => {
        const url = '/account/change_password';
        return axiosClient.post(url, params);
    },
    //active account
    activeAccount: (params) => {
        const url = '/account/request-activation-email';
        return axiosClient.post(url, params);
    }
}

export default authApi;
