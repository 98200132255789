import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'general/components/AppLayout';
import AppBanner from 'general/components/AppBanner';
import './style.scss';
import { Switch, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from 'general/components/NotFound';
import { useTranslation } from 'react-i18next';
import AppData from 'general/constants/AppData';
import KCVideo from 'features/KnowledgeCenter/components/KCVideo';
import KCWebinar from 'features/KnowledgeCenter/components/KCWebinar';
import KCArticle from 'features/KnowledgeCenter/components/KCArticle';
import KCBook from 'features/KnowledgeCenter/components/KCBook';
import KCInvestingUniversity from 'features/KnowledgeCenter/components/KCInvestingUniversity';

let KTOffcanvas = require("assets/plugins/ktoffcanvas");

KnowledgeCenterHomeScreen.propTypes = {

};

function KnowledgeCenterHomeScreen(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();
    const { t } = useTranslation();
    const history = useHistory();
    const refOffcanvasKCAside = useRef(null);

    // MARK: --- Functions ---
    function handleMenu() {
        if (refOffcanvasKCAside.current) {
            refOffcanvasKCAside.current.show();
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (typeof KTOffcanvas !== "undefined") {
            const offCanvasObject = new KTOffcanvas("knowledge_center_aside", {
                baseClass: "offcanvas-mobile",
                overlay: true,
                //closeBy: 'kt_quick_user_close',
                toggleBy: {
                    target: 'knowledge_center_aside_toggle',
                }
            });
            refOffcanvasKCAside.current = offCanvasObject;
        }
    }, []);

    return (
        <AppLayout className=''>
            {/* Content */}
            <div className='bg-white'>
                <div className='container-xxl'>
                    {/* Banner */}
                    <div className='py-6'>
                        <AppBanner className='' />
                    </div>

                    <div className='d-flex flex-row pb-7'>
                        {/* Left */}
                        <div className='offcanvas-mobile w-300px w-xl-300px KnowledgeCenterAside' id='knowledge_center_aside'>
                            {/* Navbar */}
                            <ul className="nav flex-column border rounded overflow-hidden">
                                {
                                    AppData.knowledgeCenterMenuItems.map((item, index) => {
                                        const active = history.location.pathname === item.url;
                                        const isLastItem = index === AppData.knowledgeCenterMenuItems.length - 1;
                                        return (
                                            <li key={index} className={`nav-item py-2 bg-hover-light ${!isLastItem && 'border-bottom'} ${active && 'bg-light'}`}>
                                                <a
                                                    className={`nav-link ${active && 'active text-primary'}`}
                                                    href="#"
                                                    style={{
                                                        fontWeight: '600',
                                                        fontSize: '1.1rem',
                                                        color: '#23262F'
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(item.url);
                                                        refOffcanvasKCAside.current.hide();
                                                    }}
                                                >
                                                    {t(item.text)}
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        {/* Right */}
                        <div className='flex-row-fluid ml-lg-5 border rounded overflow-hidden'>
                            <Switch>
                                <Redirect
                                    exact
                                    from={match.url}
                                    to={`${match.url}/video`}
                                />
                                <Route path={`${match.url}/video`} component={() => (<KCVideo onMenuHandle={handleMenu} />)} />
                                <Route path={`${match.url}/webinar`} component={() => (<KCWebinar onMenuHandle={handleMenu} />)} />
                                <Route path={`${match.url}/article`} component={() => (<KCArticle onMenuHandle={handleMenu} />)} />
                                <Route path={`${match.url}/book`} component={() => (<KCBook onMenuHandle={handleMenu} />)} />
                                <Route path={`${match.url}/investing-university`} component={() => (<KCInvestingUniversity onMenuHandle={handleMenu} />)} />

                                <Route
                                    render={(props) => (
                                        <NotFound />
                                    )}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default KnowledgeCenterHomeScreen;