import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import { MyListStock } from "../types/index.d";

export default {
  readFromXls(file: File) {
    return new Promise(
      (resolve: (data: any[]) => void, reject: (err: Error) => void) => {
        try {
          // check if is xls, xlsx, csv file
          const regex = /^.*\.(xlsx|csv|xls)$/;
          if (regex.test(file.name.toLowerCase())) {
            if (typeof FileReader !== "undefined") {
              const reader = new FileReader();
              if (reader.readAsBinaryString) {
                reader.onload = (e) => {
                  resolve(processExcel(reader.result));
                };
                reader.readAsBinaryString(file);
              }
            } else {
              throw new Error("This browser does not support HTML5.");
            }
          } else throw new Error("WRONG_FILE_TYPE");
        } catch (error) {
          reject(error);
        }
      }
    );
  },
  /**
   * exact object to myList
   * @returns {MyListStock}
   */
  exactMyList(myList: Object) {
    if (!_.isObject(myList)) return {};
    let values = _.values(myList);

    /**
     * @type {string}
     */
    let wrongFormatDatetime = values[4];
    let wrongFormatDatetimArr = wrongFormatDatetime?.split(" ");
    let date = wrongFormatDatetimArr[0]?.split("/").reverse().join("-");
    let time = wrongFormatDatetimArr[1];

    // console.log({ date, time });

    return {
      stockCode: _.toUpper(values[0]),
      command: _.toUpper(values[1]),
      quantity: values[2],
      price: values[3],
      datetime: moment(date?.concat(!!time ? ` ${time}` : "")).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      note: values[5],
    };
  },
};

function processExcel(data) {
  try {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);

    return excelRows;
  } catch (error) {
    throw error;
  }
}
