import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppIcons } from 'general/constants/AppResource';
import AppData from 'general/constants/AppData';
import './style.scss';
import AppConfigs from 'general/constants/AppConfigs';
import { useHistory } from 'react-router-dom';
import AppLogo from '../AppLogo';

AppFooter.propTypes = {

};

function AppFooter(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="footer pt-10 d-flex flex-column AppFooter">
            <div className='container-xxl'>
                <div className='d-flex flex-column justify-content-between mb-6'>
                    <div className='row'>
                        {/* Logo and text */}
                        <div className='col-lg-3'>
                            <AppLogo />

                            <hr className='AppFooter_Divider' />

                            <p className='mt-2 text-white AppFooter_Slogan'>
                                {t('MGSlogan')}
                            </p>
                        </div>
                        {/* <div className='col'></div> */}

                        <div className='col-lg-8 d-flex'>
                            <div className='row flex-grow-1'>
                                {/* Menu */}
                                <div className='row col-md-8 col-sm-12'>
                                    {/* Menu customer support */}
                                    <div className='col-sm-12 col-md-4'>
                                        <p className='font-weight-bold mb-2 font-size-lg AppFooter_NavTitle'>{t('CustomerSupport')}</p>
                                        <ul className='navi'>
                                            {
                                                AppData.footerCustomerSupportMenuItems.map((item, index) => {
                                                    return (
                                                        <li key={index} className='navi-item'>
                                                            <a href='#' className='navi-link py-2 pl-0' onClick={(e) => {
                                                                e.preventDefault();
                                                                history.push(item.url);
                                                            }}>
                                                                <span className='navi-text text-white text-hover-primary'>{t(item.text)}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                    {/* menu features */}
                                    <div className='col-sm-12 col-md-4'>
                                        <p className='font-weight-bold mb-2 font-size-lg AppFooter_NavTitle'>{t('Features')}</p>
                                        <ul className='navi'>
                                            {
                                                AppData.footerFeatureMenuItems.map((item, index) => {
                                                    return (
                                                        <li key={index} className='navi-item'>
                                                            <a href='#' className='navi-link py-2 pl-0' onClick={(e) => {
                                                                e.preventDefault();
                                                                history.push(item.url);
                                                            }}>
                                                                <span className='navi-text text-white text-hover-primary'>{t(item.text)}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                    {/* Menu cooperate */}
                                    <div className='col-4'>
                                        <p className='font-weight-bold mb-2 font-size-lg AppFooter_NavTitle'>{t('Cooperate')}</p>
                                        <ul className='navi'>
                                            {
                                                AppData.footerCooperateMenuItems.map((item, index) => {
                                                    return (
                                                        <li key={index} className='navi-item'>
                                                            <a
                                                                href='#'
                                                                className='navi-link py-2 pl-0'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    history.push(item.url);
                                                                }}
                                                            >
                                                                <span className='navi-text text-white text-hover-primary'>{t(item.text)}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                                {/* Connect */}
                                <div className='col'>
                                    <p className='font-weight-bold mb-2 font-size-lg AppFooter_NavTitle'>{t('Contact')}</p>
                                    <ul className='navi'>
                                        <li className='navi-item'>
                                            <span className='navi-link py-2 pl-0'>
                                                <span className='navi-text text-white'>{`${t('Hotline')}:`}<a className='ml-1 font-weight-bold AppFooter_Link' href={`tel: ${AppConfigs.hotline}`}>{AppConfigs.hotline}</a></span>
                                            </span>
                                        </li>
                                        <li className='navi-item'>
                                            <span className='navi-link py-2 pl-0'>
                                                <span className='navi-text text-white'>{`${t('Email')}:`}<a className='ml-1 font-weight-bold AppFooter_Link' href={`mailto: ${AppConfigs.emailSupport}`}>{AppConfigs.emailSupport}</a></span>
                                            </span>
                                        </li>
                                        <li className='navi-item'>
                                            <span className='navi-link py-2 pl-0'>
                                                <div>
                                                    <a
                                                        href={AppConfigs.facebookPage}
                                                        target='_blank'
                                                        className='border btn btn-icon btn-icon-primary btn-circle mr-6 AppFooter_SocialButton'
                                                    >
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                    <a href={AppConfigs.youtubePage} target='_blank' className='border btn btn-icon btn-icon-primary btn-circle mr-6 AppFooter_SocialButton'>
                                                        <i className="fab fa-youtube text-white"></i>
                                                    </a>
                                                    <a href={AppConfigs.messengerPage} target='_blank' className='border btn btn-icon btn-icon-primary btn-circle mr-6 AppFooter_SocialButton'>
                                                        <i className="fab fa-facebook-messenger text-white"></i>
                                                    </a>
                                                </div>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='AppFooter_Copyright'>
                <div className='container-xxl'>
                    {/* Copyright */}
                    <div className="text-white order-2 order-md-1 my-5 d-flex justify-content-between">
                        <div>
                            <span className="text-white font-weight-bold mr-2">2022©</span>
                            <a href="#" className="text-white text-hover-primary">{t('Copyright')}</a>
                        </div>
                        <div>{`${t('Version')} ${AppConfigs.appVersion} (${AppConfigs.appBuild})`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppFooter;