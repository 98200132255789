import stockApi from "api/stockApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get all stock
export const thunkGetAllStock = createAsyncThunk('stock/getAllStock', async (params, thunkAPI) => {
    const res = await stockApi.getAllStocks();
    return res;
});

const stockSlice = createSlice({
    name: 'stock',
    initialState: {
        isLoading: false,
        allStocks: [],

        currentSelectedStockInfo: null,

        stockCandleData: [],
        stockVolumeData: [],
        isLoadingStockChartData: false,
    },
    reducers: {
        setCurrentSelectedStockInfo: (state, action) => {
            state.currentSelectedStockInfo = action.payload;
        },

        setStockCandleData: (state, action) => {
            state.stockCandleData = action.payload;
        },

        setStockVolumeData: (state, action) => {
            state.stockVolumeData = action.payload;
        },

        setLoadingStockChartData: (state, action) => {
            state.isLoadingStockChartData = action.payload;
        }
    },
    extraReducers: {
        // Get all stocks
        [thunkGetAllStock.pending]: (state, action) => {
            state.isLoading = true;
        },
        [thunkGetAllStock.rejected]: (state, action) => {
            state.isLoading = false;
        },
        [thunkGetAllStock.fulfilled]: (state, action) => {
            state.isLoading = false;
            const payload = action.payload;
            const { result, stocks } = payload;
            if (result === 'success' && stocks) {
                state.allStocks = stocks;
            }
        },
    },
});

const { actions, reducer } = stockSlice;
export const { setCurrentSelectedStockInfo, setStockCandleData, setStockVolumeData, setLoadingStockChartData } = actions;
export default reducer;