import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import CellStockList from 'features/Stock/components/CellStockList';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';
import CellQuickStockSearch from 'features/Stock/components/CellQuickStockSearch';

DropdownStockSelect.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object.isRequired,
    fieldMeta: PropTypes.object.isRequired,
    fieldHelpers: PropTypes.object.isRequired,

    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    options: PropTypes.array,
    onSubmit: PropTypes.func,
    onSelectedStock: PropTypes.func,
};

DropdownStockSelect.defaultProps = {
    type: "text",
    label: "",
    placeholder: "",
    disabled: false,
    text: "",
    options: [],
    onSubmit: null,
    onSelectedStock: null,
};

function DropdownStockSelect(props) {
    // MARK: --- Params ---
    const {
        fieldProps,
        fieldMeta,
        fieldHelpers,
        type,
        name,
        label,
        placeholder,
        disabled,
        text,
        options,
        onSubmit,
        onSelectedStock } = props;
    const value = fieldProps.value;
    const { error, touched } = fieldMeta;
    const showError = error && touched;
    const [focus, setFocus] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const typingTimeoutRef = useRef(null);
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function toggleDropdownVisible() {
        setDropdownVisible(!dropdownVisible);
    }

    function handleTextChanged(e) {
        const value = e.target.value;
        fieldHelpers.setValue(value);

        if (!onSubmit) {
            return;
        }

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            onSubmit(value);
        }, 500);
    }

    return (
        <div className='d-flex align-items-center'>
            <label className="text-left text-lg-right m-0" htmlFor={name}>{label && label}</label>
            <div className="ml-4">
                <Dropdown
                    isOpen={!disabled && (dropdownVisible || focus)}
                    toggle={() => {
                        setDropdownVisible(!dropdownVisible);
                    }}
                    className=''
                >
                    <DropdownToggle caret={false} className="border-0 p-0 bg-transparent flex-grow-1">
                        <input
                            className={`form-control form-control ${showError ? 'is-invalid' : ''}`}
                            id={name}
                            type={type}
                            placeholder={placeholder}
                            autoComplete='off'
                            disabled={disabled}
                            value={value}
                            onFocus={() => {
                                setFocus(true);
                                fieldHelpers.setTouched(true);
                            }}
                            onBlur={() => {
                                setFocus(false);
                            }}
                            onChange={handleTextChanged}
                        />
                    </DropdownToggle>
                    <DropdownMenu
                        className='py-4 w-100 q-hide-scrollbar w-375px'
                        style={{ overflowY: 'scroll', maxHeight: '300px' }}

                    >
                        {
                            options.length > 0 && options.map((item, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        className='py-2 px-0 d-block'
                                        style={{

                                        }}
                                        toggle={false}
                                        onClick={() => {
                                            setDropdownVisible(false);
                                            if (onSelectedStock) {
                                                onSelectedStock(item);
                                            }
                                        }}
                                        children={(
                                            <CellQuickStockSearch
                                                item={item}
                                                showDivider={false}
                                            />
                                        )}
                                    />
                                )
                            })
                        }

                        {
                            options.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </DropdownMenu>
                </Dropdown>

                {
                    showError && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    )
                }

                <span className="form-text text-muted">{text}</span>
            </div>
        </div>
    );
}

export default DropdownStockSelect;