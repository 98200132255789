import store from 'app/store';
import UserHelper from 'general/helpers/UserHelper';
import Utils from 'general/utils/Utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

function GuestRoute(props) {
    // const loggedInUser = store.getState().auth.current;
    const isAuth = UserHelper.checkAccessTokenValid()/* && !Utils.isObjectEmpty(loggedInUser)*/;

    return isAuth
        ? <Redirect to='/' />
        : <Route {...props} />
}

export default GuestRoute;