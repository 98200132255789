import axiosClient from "./axiosClient";

const academyApi = {
    // Get academy data
    getAcademyData: () => {
        const url = '/knowledge/academy/show';
        return axiosClient.get(url);
    }
};

export default academyApi;