import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

AnalyzeTransactionHistoryTableHeadItem.propTypes = {

};

function AnalyzeTransactionHistoryTableHeadItem(props) {

    const { headTitle, bodyTitles } = props
    const { t } = useTranslation()

    return (
        <div>
            <div className='border-bottom w-100 d-flex align-items-center justify-content-center pb-3 pt-4'>
                <span className=''>{headTitle}</span>
            </div>
            <div className='row w-100 m-0'>
                {bodyTitles.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className='col-4 py-3 d-flex flex-column justify-content-between'>
                            <span>{item.subTitle}</span>
                            <span className='font-weight-normal mt-2'>{item.unit}</span>
                        </div>)
                })}
            </div>
        </div>
    );
}

export default AnalyzeTransactionHistoryTableHeadItem;