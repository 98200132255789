import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

SectionHeaderView.propTypes = {
    title: PropTypes.string,
    buttonTitle: PropTypes.string,
    onPressButton: PropTypes.func,
    dividerVisible: PropTypes.bool,
    titleClassName: PropTypes.string,
    tintColor: PropTypes.string,
};

SectionHeaderView.defaultProps = {
    title: '',
    buttonTitle: '',
    onPressButton: null,
    dividerVisible: true,
    titleClassName: 'font-size-h4 font-weight-bolder',
    tintColor: null
};

function SectionHeaderView(props) {
    // MARK: --- Params ---
    const { title, buttonTitle, onPressButton, dividerVisible, titleClassName, tintColor } = props;

    // MARK: --- Functions ---
    function handlePressButton(e) {
        e.preventDefault();
        if (onPressButton) {
            onPressButton();
        }
    }

    return (
        <div className='SectionHeaderView'>
            <div className='d-flex justify-content-between align-items-center'>
                <p className={`SectionHeaderView_Title m-0 ${titleClassName}`} style={{
                    color: tintColor ?? '',
                }}>{title}</p>
                {
                    buttonTitle.length > 0 && (
                        <a
                            href='#'
                            className='font-size-lg font-weight-bold d-flex align-items-center'
                            onClick={(e) => {
                                handlePressButton(e);
                            }}
                            style={{
                                color: tintColor ?? '',
                            }}
                        >
                            {buttonTitle}<i className="far fa-angle-right ml-2" style={{
                                color: tintColor ?? '#a635ff',
                            }}></i>
                        </a>
                    )
                }
            </div>

            {
                dividerVisible && (
                    <div className='SectionHeaderView_Divider h-2px mt-2'></div>
                )
            }
        </div>
    );
}

export default SectionHeaderView;