import accountApi from "api/accountApi";
import { updateCurrentAccountInfo } from "app/authSlice";
import store from "app/store";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Update account info
export const thunkUpdateAccountInfo = createAsyncThunk('account/update', async (params, thunkAPI) => {
    const res = await accountApi.updateInfo(params.userId, params.values);
    return res;
});

// Get all transactions
export const thunkGetAllTransactions = createAsyncThunk('account/allTransactions', async (params, thunkAPI) => {
    const res = await accountApi.getTransactionHistory(params);
    return res;
});

// Get all my webinars
export const thunkGetAllWebinars = createAsyncThunk('account/allWebinars', async (params, thunkAPI) => {
    const res = await accountApi.getMyWebinars();
    return res;
});

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        isUpdatingInfo: false,
        allTransactions: [],
        allWebinars: [],
    },
    reducers: {

    },
    extraReducers: {
        // Update account info
        [thunkUpdateAccountInfo.pending]: (state, action) => {
            state.isUpdatingInfo = true;
        },
        [thunkUpdateAccountInfo.rejected]: (state, action) => {
            state.isUpdatingInfo = false;
        },
        [thunkUpdateAccountInfo.fulfilled]: (state, action) => {
            state.isUpdatingInfo = false;
        },

        // Get all transactions
        [thunkGetAllTransactions.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, transactions } = payload;
            if (result === 'success' && transactions) {
                state.allTransactions = transactions;
            }
        },

        // Get all webinars
        [thunkGetAllWebinars.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, webinars } = payload;
            // console.log({ result, webinars });
            if (result === 'success' && webinars) {
                // console.log(webinars);
                state.allWebinars = webinars;
            }
        },
    },
});

const { reducer, actions } = accountSlice;
export const { } = actions;
export default reducer;
