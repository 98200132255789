import { useFormik } from 'formik';
import AppCardDataInfo from 'general/components/AppCardDataInfo';
import AppPagination from 'general/components/AppPagination';
import BarChart from 'general/components/Charts/BarChart';
import DatePickerInputMask from 'general/components/Forms/FormWidgets/DatePickerInputMask';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import AnalyzeTransactionHistoryTableBodyItem from './AnalyzeTransactionHistoryTableBodyItem';
import AnalyzeTransactionHistoryTableHeadItem from './AnalyzeTransactionHistoryTableHeadItem';

AnalyzeTransactionHistory.propTypes = {
    transactionHistoryData: PropTypes.array,
    transactionHistoryPagination: PropTypes.object,
    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
    transactionsGroupedByStockCode: PropTypes.object,
    allTransactionsHistory: PropTypes.array,
    onChangeInvestmentPerformance: PropTypes.func,
    investmentPerformanceDurationValue: PropTypes.string,
    investmentPerformanceFrom: PropTypes.string,
    investmentPerformanceTo: PropTypes.string,
    investmentPerformanceData: PropTypes.array,
    loading: PropTypes.bool,
};

AnalyzeTransactionHistory.defaultProps = {
    transactionHistoryData: [],
    transactionHistoryPagination: {},
    onChangePage: null,
    onChangePerPage: null,
    transactionsGroupedByStockCode: {},
    allTransactionsHistory: [],
    investmentPerformanceDurationValue: '1M',
    onChangeInvestmentPerformance: null,
    investmentPerformanceFrom: '',
    investmentPerformanceTo: '',
    investmentPerformanceData: [],
    loading: false,
};

function AnalyzeTransactionHistory(props) {
    // MARK: --- Params ---
    const {
        transactionHistoryData,
        transactionHistoryPagination,
        onChangePage,
        onChangePerPage,
        transactionsGroupedByStockCode,
        allTransactionsHistory,
        onChangeInvestmentPerformance,
        investmentPerformanceDurationValue,
        investmentPerformanceFrom,
        investmentPerformanceTo,
        investmentPerformanceData,
        loading
    } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const durationOptions = [
        { name: '1 Tháng', value: '1M', iValue: 1 },
        { name: '3 Tháng', value: '3M', iValue: 3 },
        { name: '6 Tháng', value: '6M', iValue: 6 },
        { name: 'YTD', value: 'YTD' },
        { name: '1 Năm', value: '12M', iValue: 12 },
        { name: 'Tất Cả', value: 'All' },
    ];
    const formik = useFormik({
        initialValues: {
            fromDate: investmentPerformanceFrom,
            toDate: investmentPerformanceTo,
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        }
    });
    const tmpInvestmentPerformanceData = [];
    _.forEach(investmentPerformanceData, (item, index) => {
        tmpInvestmentPerformanceData.push({
            ...item,
            dayMonthYear: moment(item.datetime).format('YYYY-MM-DD'),
        });
    });
    const investmentPerformanceGroupedByDayMonthYear = _.chain(tmpInvestmentPerformanceData).groupBy('dayMonthYear').value();
    // console.log(investmentPerformanceGroupedByDayMonthYear);
    let arrDayInMonthInvestmentPerformanceData = [];
    Object.keys(investmentPerformanceGroupedByDayMonthYear).forEach(key => {
        let objItem = {};
        objItem.date = key;

        const retObjValue = investmentPerformanceGroupedByDayMonthYear[key];
        let totalInterest = 0;
        let totalGross = 0;
        let totalBuy = 0;
        retObjValue.forEach((item, index) => {
            const command = item?.command ?? '';
            const refPrice = item?.stock?.price ?? 0;
            const commandPrice = item?.price ?? 0;
            const quantity = item?.quantity ?? 0;
            let status = '';
            let statusColor = '';
            let diffCommandPrice = '';
            let diffCommandPricePercent = '';
            let fDiffCommandPrice = '';
            let fTotalBuyValue = 0;
            if (command === 'BUY') {
                if (commandPrice < refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalInterest += Math.abs((refPrice - commandPrice) * quantity);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs(refPrice - commandPrice) * quantity)}`;
                    fDiffCommandPrice = `-${Math.abs(refPrice - commandPrice) * quantity}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalGross += Math.abs(refPrice - commandPrice) * quantity * -1;
                }
                fTotalBuyValue += (quantity * commandPrice);
            } else if (command === 'SELL') {
                if (commandPrice > refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalInterest += Math.abs((refPrice - commandPrice) * quantity);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `-${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalGross += Math.abs(refPrice - commandPrice) * quantity * -1;
                }
            }
            totalBuy += fTotalBuyValue;
        });

        objItem.totalBuy = totalBuy;
        objItem.totalInterest = totalInterest;
        objItem.totalGross = totalGross;
        objItem.win = Math.abs(totalInterest) > Math.abs(totalGross);
        objItem.fInterestRate = totalInterest * 100 / totalBuy;
        objItem.fGrossRate = Math.abs(totalGross) * 100 / totalBuy;

        arrDayInMonthInvestmentPerformanceData.push(objItem);
    });
    arrDayInMonthInvestmentPerformanceData = _.orderBy(arrDayInMonthInvestmentPerformanceData, ['date'], ['asc']);
    console.log({ arrDayInMonthInvestmentPerformanceData });

    const options = {
        colors: ['#0BDF39', '#FF0000'],
        chart: {
            type: 'area',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: false,
            },
            offsetY: 0
        },
        dataLabels: {
            enabled: true,
            offsetY: 0,
        },
        xaxis: {
            categories: _.map(arrDayInMonthInvestmentPerformanceData, 'date'),
            labels: {
                show: true,
                rotate: 0,
                formatter: function (value, timestamp) {
                    return Utils.formatDateTime(value, 'DD/MM/YYYY');
                },
            },
            axisBorder: {
                show: true,
            },
            crosshairs: {
                show: true,
            },
            axisTicks: {
                show: true,
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        yaxis: {
            show: true,
            axisTicks: {
                show: true
            },
            title: {
                text: 'tr VNĐ'
            },
            crosshairs: {
                show: true
            },
            axisBorder: {
                show: true,
            },
        },
        grid: {
            show: false,
        },
        markers: {
            color: '#f00',
            width: 12,
            height: 12,
            strokeWidth: 0,
        }
    };
    const series = [
        {
            name: t('Interest'),
            data: _.map(arrDayInMonthInvestmentPerformanceData, item => item.totalInterest / 1000000)//[31, 40, 28, 51, 42, 109, 100, 23, 123, 98, 33, 102]
        },
        {
            name: t('Gross'),
            data: _.map(arrDayInMonthInvestmentPerformanceData, item => Math.abs(item.totalGross) / 1000000)
        }
    ];
    // console.log({ transactionHistoryPagination });
    let retObj = {};
    const tmpTransactionsGroupedByStockCode = { ...transactionsGroupedByStockCode };
    Object.keys(tmpTransactionsGroupedByStockCode).forEach(element => {
        retObj[element] = [];
        const tmpTransactionsGroupedByStockCodeValue = tmpTransactionsGroupedByStockCode[element];
        tmpTransactionsGroupedByStockCodeValue.forEach((item, index) => {
            const objItem = {};

            objItem.transaction = item;
            objItem.status = {};

            const command = item?.command ?? '';
            const refPrice = item?.stock?.price ?? 0;
            const commandPrice = item?.price ?? 0;
            const quantity = item?.quantity ?? 0;
            let status = '';
            let statusColor = '';
            let diffCommandPrice = '';
            let diffCommandPricePercent = '';
            let fDiffCommandPrice = '';
            let fTotalBuyValue = 0;
            if (command === 'BUY') {
                if (commandPrice < refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs(refPrice - commandPrice) * quantity)}`;
                    fDiffCommandPrice = `-${Math.abs(refPrice - commandPrice) * quantity}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                }
                fTotalBuyValue += (quantity * commandPrice);
                // console.log({ fTotalBuyValue });
            } else if (command === 'SELL') {
                if (commandPrice > refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `-${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                }
            }

            objItem.status.status = status;
            objItem.status.statusColor = statusColor;
            objItem.status.diffCommandPrice = diffCommandPrice;
            objItem.status.diffCommandPricePercent = diffCommandPricePercent;
            objItem.status.fDiffCommandPrice = fDiffCommandPrice;
            objItem.status.fTotalBuyValue = fTotalBuyValue;

            retObj[element].push(objItem);
        });
    });

    // console.log({ retObj });

    // overview
    const tmpAllTransactionsHistory = [];
    _.forEach(allTransactionsHistory, (item, index) => {
        tmpAllTransactionsHistory.push({
            ...item,
            monthYear: moment(item.datetime).format('MM/YYYY'),
            dayMonthYear: moment(item.datetime).format('DD/MM/YYYY'),
        });
    });

    const allTransactionsGroupedByDayMonthYear = _.chain(tmpAllTransactionsHistory).groupBy('dayMonthYear').value();
    const arrDayInMonthStatistic = [];
    Object.keys(allTransactionsGroupedByDayMonthYear).forEach(key => {
        let dayInMonthStatisticObj = {};
        dayInMonthStatisticObj.date = key;

        const retObjValue = allTransactionsGroupedByDayMonthYear[key];
        let totalInterest = 0;
        let totalGross = 0;
        let totalBuy = 0;
        retObjValue.forEach((item, index) => {
            const command = item?.command ?? '';
            const refPrice = item?.stock?.price ?? 0;
            const commandPrice = item?.price ?? 0;
            const quantity = item?.quantity ?? 0;
            let status = '';
            let statusColor = '';
            let diffCommandPrice = '';
            let diffCommandPricePercent = '';
            let fDiffCommandPrice = '';
            let fTotalBuyValue = 0;
            if (command === 'BUY') {
                if (commandPrice < refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalInterest += Math.abs((refPrice - commandPrice) * quantity);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs(refPrice - commandPrice) * quantity)}`;
                    fDiffCommandPrice = `-${Math.abs(refPrice - commandPrice) * quantity}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalGross += Math.abs(refPrice - commandPrice) * quantity * -1;
                }
                fTotalBuyValue += (quantity * commandPrice);
            } else if (command === 'SELL') {
                if (commandPrice > refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalInterest += Math.abs((refPrice - commandPrice) * quantity);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `-${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalGross += Math.abs(refPrice - commandPrice) * quantity * -1;
                }
            }
            totalBuy += fTotalBuyValue;
        });

        dayInMonthStatisticObj.totalBuy = totalBuy;
        dayInMonthStatisticObj.totalInterest = totalInterest;
        dayInMonthStatisticObj.totalGross = totalGross;
        dayInMonthStatisticObj.win = Math.abs(totalInterest) > Math.abs(totalGross);
        dayInMonthStatisticObj.fInterestRate = totalInterest * 100 / totalBuy;
        dayInMonthStatisticObj.fGrossRate = Math.abs(totalGross) * 100 / totalBuy;

        arrDayInMonthStatistic.push(dayInMonthStatisticObj);
    });
    // console.log({ arrDayInMonthStatistic });

    const allTransactionsGroupedByMonthYear = _.chain(tmpAllTransactionsHistory).groupBy('monthYear').value();
    const arrStatistic = [];
    Object.keys(allTransactionsGroupedByMonthYear).forEach(key => {
        let statisticObj = {};

        statisticObj.monthYear = key;
        const retObjValue = allTransactionsGroupedByMonthYear[key];

        let totalTransaction = retObjValue.length;
        let totalTransactionWin = 0;
        let totalTransactionLoss = 0;
        let transactionWinRate = '';
        let transactionLossRate = '';
        let totalBuyValue = 0;
        let totalInterestValue = 0;
        let totalGrossValue = 0;

        retObjValue.forEach((item, index) => {
            const command = item?.command ?? '';
            const refPrice = item?.stock?.price ?? 0;
            const commandPrice = item?.price ?? 0;
            const quantity = item?.quantity ?? 0;
            let status = '';
            let statusColor = '';
            let diffCommandPrice = '';
            let diffCommandPricePercent = '';
            let fDiffCommandPrice = '';
            let fTotalBuyValue = 0;

            if (command === 'BUY') {
                if (commandPrice < refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalTransactionWin += 1;
                    totalInterestValue += parseFloat(fDiffCommandPrice);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs(refPrice - commandPrice) * quantity)}`;
                    fDiffCommandPrice = `-${Math.abs(refPrice - commandPrice) * quantity}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalTransactionLoss += 1;
                    totalGrossValue += parseFloat(fDiffCommandPrice);
                }
                fTotalBuyValue += (quantity * commandPrice);
            } else if (command === 'SELL') {
                if (commandPrice > refPrice) {
                    status = t('Interest');
                    statusColor = '#0BDF39';
                    diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `+${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                    totalTransactionWin += 1;
                    totalInterestValue += parseFloat(fDiffCommandPrice);
                } else {
                    status = t('Gross');
                    statusColor = '#FF0017';
                    diffCommandPrice = `-${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                    fDiffCommandPrice = `-${Math.abs((refPrice - commandPrice) * quantity)}`;
                    diffCommandPricePercent = `-${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                    totalTransactionLoss += 1;
                    totalGrossValue += parseFloat(fDiffCommandPrice);
                }
            }

            totalBuyValue += fTotalBuyValue;
        });

        transactionWinRate = `${(totalTransactionWin * 100 / totalTransaction).toFixed(2)}%`;
        transactionLossRate = `${(totalTransactionLoss * 100 / totalTransaction).toFixed(2)}%`;

        statisticObj.totalTransaction = totalTransaction;
        statisticObj.totalTransactionWin = totalTransactionWin;
        statisticObj.totalTransactionLoss = totalTransactionLoss;
        statisticObj.transactionWinRate = transactionWinRate;
        statisticObj.transactionLossRate = transactionLossRate;

        const arrInMonthStatistic = _.filter(arrDayInMonthStatistic, (item => item.date.includes(key)));
        statisticObj.totalTransactionDay = arrInMonthStatistic.length;
        statisticObj.totalTransactionDayWin = _.filter(arrInMonthStatistic, ['win', true]).length;
        statisticObj.totalTransactionDayLoss = _.filter(arrInMonthStatistic, ['win', false]).length;
        statisticObj.totalBuyValue = totalBuyValue;
        statisticObj.totalInterestValue = totalInterestValue;
        statisticObj.totalGrossValue = totalGrossValue;
        statisticObj.averageInterestRate = `${(_.sumBy(arrDayInMonthStatistic, 'fInterestRate') / arrInMonthStatistic.length).toFixed(2)}%`;
        statisticObj.averageGrossRate = `${(_.sumBy(arrDayInMonthStatistic, 'fGrossRate') / arrInMonthStatistic.length).toFixed(2)}%`;

        if (totalBuyValue > 0) {
            statisticObj.winRate = `${((totalInterestValue * 100) / totalBuyValue).toFixed(2)}%`;
            statisticObj.lossRate = `${((Math.abs(totalGrossValue) * 100) / totalBuyValue).toFixed(2)}%`;
            statisticObj.winRateAdjusted = `${((totalInterestValue) / totalBuyValue).toFixed(2)}`;
            statisticObj.lossRateAdjusted = `${((Math.abs(totalGrossValue)) / totalBuyValue).toFixed(2)}`;
        }

        arrStatistic.push(statisticObj);
    });
    // console.log({ arrStatistic });

    const arrSummary = [];
    Object.keys(retObj).forEach(key => {
        let summaryObj = {};
        const retObjValue = retObj[key];
        let countInterest = 0;
        let countGross = 0;
        let totalInterest = 0;
        let totalGross = 0;
        let totalBuyValue = 0;
        retObjValue.forEach(element => {
            const status = element.status;

            if (parseFloat(status?.fDiffCommandPrice) > 0) {
                countInterest += 1;
                totalInterest += parseFloat(status?.fDiffCommandPrice);
            } else if (parseFloat(status?.fDiffCommandPrice) < 0) {
                countGross += 1;
                totalGross += parseFloat(status?.fDiffCommandPrice);
            }

            totalBuyValue += status?.fTotalBuyValue;
        });

        summaryObj.stockCode = retObjValue[0]?.transaction.stockCode;
        summaryObj.stockName = retObjValue[0]?.transaction?.stock?.stockName;
        summaryObj.industryName = retObjValue[0]?.transaction?.stock?.industry?.name;
        summaryObj.countInterest = countInterest;
        summaryObj.countGross = countGross;
        summaryObj.totalInterest = totalInterest;
        summaryObj.totalGross = totalGross;
        summaryObj.totalBuyValue = totalBuyValue <= 0 ? 1 : totalBuyValue;
        summaryObj.win = totalInterest > Math.abs(totalGross);

        arrSummary.push(summaryObj);
    });

    // console.log({ arrSummary });
    // console.log(_.orderBy(arrSummary, 'countInterest', 'desc').slice(0, 2));
    const winLostStatistic = _.countBy(arrSummary, 'win');
    const win = winLostStatistic['true'];
    const lost = winLostStatistic['false'];
    // console.log({ win, lost });

    const allStockTotalBuy = _.sumBy(arrSummary, 'totalBuyValue');
    const allStockTotalInterest = _.sumBy(arrSummary, 'totalInterest');
    const allStockTotalGross = _.sumBy(arrSummary, 'totalGross');
    // console.log({ allStockTotalBuy, allStockTotalInterest, allStockTotalGross });

    const overviewData = [
        { label: 'Tỷ lệ chiến thắng', value: `${((win / (win + lost)) * 100).toFixed(2)}%` },
        { label: 'Trung bình lãi', value: `${((allStockTotalInterest / allStockTotalBuy) * 100).toFixed(2)}%` },
        { label: 'Trung bình lỗ', value: `${((Math.abs(allStockTotalGross) / allStockTotalBuy) * 100).toFixed(2)}%` },
        { label: 'Tỷ lệ lãi/lỗ', value: `${((allStockTotalInterest / Math.abs(allStockTotalGross)) * 100).toFixed(2)}%` },
        { label: 'Tỷ lệ lãi/lỗ điều chỉnh', value: `${((allStockTotalInterest / Math.abs(allStockTotalGross))).toFixed(2)}` },
    ];

    const arrSummaryGroupedByIndustry = _.groupBy(arrSummary, 'industryName');
    const arrSummaryIndustry = [];
    Object.keys(arrSummaryGroupedByIndustry).forEach(element => {
        const obj = {};
        obj.industryName = element;

        let totalInterest = 0;
        let totalGross = 0;
        let totalBuyValue = 0;
        arrSummaryGroupedByIndustry[element].forEach(item => {
            totalInterest += item.totalInterest;
            totalGross += item.totalGross;
            totalBuyValue += item.totalBuyValue;
        });
        obj.totalInterest = totalInterest;
        obj.totalGross = totalGross;
        obj.totalBuyValue = totalBuyValue;
        obj.win = totalInterest > Math.abs(totalGross);
        if (totalBuyValue > 0) {
            obj.winRate = (totalInterest * 100 / totalBuyValue).toFixed(2);
            obj.lossRate = (Math.abs(totalGross) * 100 / totalBuyValue).toFixed(2);
        }

        arrSummaryIndustry.push(obj);
    })
    // console.log({ arrSummaryIndustry });

    // MARK: --- Functions ---

    // MARK: --- Hooks ---

    return (
        <div className='bg-white p-4 border-left border-bottom border-right'>
            {/* Lich su chot loi */}
            <div className='border'>
                <AppCardDataInfo
                    border={false}
                    title='Thống Kê Lịch Sử Chốt Lời'
                    content={(
                        <div>
                            <MGDataTable
                                className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 pt-2'
                                loading={loading}
                                headItems={[
                                    { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                                    { title: t('TransactionDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                    { title: t('Command'), subTitle: `${t('Buy')}/${t('Sell')}`, col: 'col-1', textAlign: 'right' },
                                    { title: t('Price'), subTitle: 'VNĐ', col: 'col-1', textAlign: 'right' },
                                    { title: t('Volume'), subTitle: '', col: 'col-1', textAlign: 'right' },
                                    { title: t('Status'), subTitle: t('ProfitToLossRatio'), col: 'col-1', textAlign: 'right' },
                                    { title: `${t('Interest')}/${t('Gross')}`, subTitle: 'VNĐ', col: 'col-1', textAlign: 'right' },
                                    { title: `${t('Interest')}/${t('Gross')}`, subTitle: '%', col: 'col-1', textAlign: 'right' },
                                ]}
                                dataItems={
                                    transactionHistoryData.map((item, index) => {
                                        const command = item?.command ?? '';
                                        const refPrice = item?.stock?.price ?? 0;
                                        const commandPrice = item?.price ?? 0;
                                        const quantity = item?.quantity ?? 0;
                                        let status = '';
                                        let statusColor = '';
                                        let diffCommandPrice = '';
                                        let diffCommandPricePercent = '';
                                        if (command === 'BUY') {
                                            if (commandPrice < refPrice) {
                                                status = t('Interest');
                                                statusColor = '#0BDF39';
                                                diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                                                diffCommandPricePercent = `+${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                                            } else {
                                                status = t('Gross');
                                                statusColor = '#FF0017';
                                                diffCommandPrice = `-${Utils.formatNumber(Math.abs(refPrice - commandPrice) * quantity)}`;
                                                diffCommandPricePercent = `-${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                                            }
                                        } else if (command === 'SELL') {
                                            if (commandPrice > refPrice) {
                                                if (item?.stockCode === 'BTP') {
                                                    console.log(refPrice, commandPrice, quantity);
                                                }
                                                status = t('Interest');
                                                statusColor = '#0BDF39';
                                                diffCommandPrice = `+${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                                                diffCommandPricePercent = `+${Utils.formatNumber((((commandPrice - refPrice) / refPrice) * 100).toFixed(2))}%`;
                                            } else {
                                                status = t('Gross');
                                                statusColor = '#FF0017';
                                                diffCommandPrice = `-${Utils.formatNumber(Math.abs((refPrice - commandPrice) * quantity))}`;
                                                diffCommandPricePercent = `-${Utils.formatNumber((((refPrice - commandPrice) / commandPrice) * 100).toFixed(2))}%`;
                                            }
                                        }

                                        return {
                                            onPress: () => {
                                                history.push(`/stock/${item?.stockCode}`);
                                            },
                                            data: [
                                                { icon: '', title: item?.stockCode, titleWeight: 600, subTitle: item?.stock?.stockName, name: '', value: '', valueColor: '', col: 'col-3' },
                                                { icon: '', title: '', name: Utils.formatDateTime(item?.datetime, 'DD/MM/YYYY HH:mm'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: t(command), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: Utils.formatNumber(item?.price), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: Utils.formatNumber(item?.quantity), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: status, nameColor: statusColor, value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: diffCommandPrice, nameColor: statusColor, value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                                { icon: '', title: '', name: diffCommandPricePercent, nameColor: statusColor, value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                            ]
                                        }
                                    })
                                }
                            />
                        </div>
                    )}
                />
                {/* Pagination */}
                <div className='px-4'>
                    <AppPagination
                        rowsPerPage={transactionHistoryPagination?.perPage}
                        rowCount={transactionHistoryPagination?.total}
                        currentPage={transactionHistoryPagination?.currentPage + 1}
                        onChangePage={(newPage) => {
                            let iNewPage = parseInt(newPage);
                            iNewPage -= 1;
                            if (iNewPage < 0) {
                                iNewPage = 0;
                            }
                            if (onChangePage) {
                                onChangePage(iNewPage);
                            }
                        }}
                        onChangeRowsPerPage={(newPerPage) => {
                            const iNewPerPage = parseInt(newPerPage);
                            if (onChangePerPage) {
                                onChangePerPage(newPerPage);
                            }
                        }}
                    />
                </div>
            </div>

            {/* Tom tat */}
            <div className='mt-4'>
                <div className='row'>
                    <div className='col-12 col-md-6 pl-4 pr-4 pr-md-2 align-self-start'>
                        <AppCardDataInfo
                            title='Top 3 Mã Tỷ Lệ Lãi Cao Nhất'
                            content={(
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 pt-2'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-6' },
                                        { title: `${t('Interest')}`, subTitle: '%', col: 'col-3', textAlign: 'right' },
                                        { title: `${t('Interest')}`, subTitle: 'VNĐ', col: 'col-3', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.orderBy(arrSummary, 'countInterest', 'desc').filter(item => item.countInterest > 0).slice(0, 3).map(item => {
                                            return {
                                                onPress: () => {
                                                    history.push(`/stock/${item?.stockCode}`);
                                                },
                                                data: [
                                                    {
                                                        icon: '',
                                                        title: item?.stockCode,
                                                        titleWeight: '600',
                                                        subTitle: item?.stockName,
                                                        name: '',
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-6'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${((item?.totalInterest / item?.totalBuyValue) * 100).toFixed(2)}%`,
                                                        nameColor: Utils.getValueColor(item?.totalInterest),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${Utils.formatNumber(item?.totalInterest)}`,
                                                        nameColor: Utils.getValueColor(item?.totalInterest),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                ]
                                            }
                                        })
                                    }
                                />
                            )}
                        />
                    </div>

                    <div className='col-12 col-md-6 pr-4 pl-4 pl-md-2 align-self-start'>
                        <AppCardDataInfo
                            title='Top 3 Mã Tỷ Lệ Lỗ Cao Nhất'
                            content={(
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 pt-2'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-6' },
                                        { title: `${t('Gross')}`, subTitle: '%', col: 'col-3', textAlign: 'right' },
                                        { title: `${t('Gross')}`, subTitle: 'VNĐ', col: 'col-3', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.orderBy(arrSummary, 'countGross', 'desc').filter(item => item.countGross > 0).slice(0, 3).map(item => {
                                            return {
                                                onPress: () => {
                                                    history.push(`/stock/${item?.stockCode}`);
                                                },
                                                data: [
                                                    {
                                                        icon: '',
                                                        title: item?.stockCode,
                                                        titleWeight: '600',
                                                        subTitle: item?.stockName,
                                                        name: '',
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-6'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `-${((Math.abs(item?.totalGross) / item?.totalBuyValue) * 100).toFixed(2)}%`,
                                                        nameColor: Utils.getValueColor(item?.totalGross),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${Utils.formatNumber(item?.totalGross)}`,
                                                        nameColor: Utils.getValueColor(item?.totalGross),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                ]
                                            }
                                        })
                                    }
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-12 col-md-6 pl-4 pr-4 pr-md-2 align-self-start'>
                        <AppCardDataInfo
                            title='Top 3 Mã Giá Trị Lãi Cao Nhất'
                            content={(
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 pt-2'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-6' },
                                        { title: `${t('Interest')}`, subTitle: '%', col: 'col-3', textAlign: 'right' },
                                        { title: `${t('Interest')}`, subTitle: 'VNĐ', col: 'col-3', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.orderBy(arrSummary, 'totalInterest', 'desc').filter(item => item.totalInterest > 0).slice(0, 3).map(item => {
                                            return {
                                                onPress: () => {
                                                    history.push(`/stock/${item?.stockCode}`);
                                                },
                                                data: [
                                                    {
                                                        icon: '',
                                                        title: item?.stockCode,
                                                        titleWeight: '600',
                                                        subTitle: item?.stockName,
                                                        name: '',
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-6'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${((item?.totalInterest / item?.totalBuyValue) * 100).toFixed(2)}%`,
                                                        nameColor: Utils.getValueColor(item?.totalInterest),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${Utils.formatNumber(item?.totalInterest)}`,
                                                        nameColor: Utils.getValueColor(item?.totalInterest),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                ]
                                            }
                                        })
                                    }
                                />
                            )}
                        />
                    </div>

                    <div className='col-12 col-md-6 pr-4 pl-4 pl-md-2 align-self-start'>
                        <AppCardDataInfo
                            title='Top 3 Mã Giá Trị Lỗ Cao Nhất'
                            content={(
                                <MGDataTable
                                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0 pt-2'
                                    headItems={[
                                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-6' },
                                        { title: `${t('Gross')}`, subTitle: '%', col: 'col-3', textAlign: 'right' },
                                        { title: `${t('Gross')}`, subTitle: 'VNĐ', col: 'col-3', textAlign: 'right' },
                                    ]}
                                    dataItems={
                                        _.orderBy(arrSummary, 'totalGross', 'asc').filter(item => item.totalGross < 0).slice(0, 3).map(item => {
                                            return {
                                                onPress: () => {
                                                    history.push(`/stock/${item?.stockCode}`);
                                                },
                                                data: [
                                                    {
                                                        icon: '',
                                                        title: item?.stockCode,
                                                        titleWeight: '600',
                                                        subTitle: item?.stockName,
                                                        name: '',
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-6'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `-${((Math.abs(item?.totalGross) / item?.totalBuyValue) * 100).toFixed(2)}%`,
                                                        nameColor: Utils.getValueColor(item?.totalGross),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                    {
                                                        icon: '',
                                                        title: '',
                                                        subTitle: '',
                                                        name: `${Utils.formatNumber(item?.totalGross)}`,
                                                        nameColor: Utils.getValueColor(item?.totalGross),
                                                        value: '',
                                                        valueColor: '',
                                                        col: 'col-3', textAlign: 'right'
                                                    },
                                                ]
                                            }
                                        })
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
            </div>

            {/* Tong quan + top lai theo nganh */}
            <div className='row mt-4'>
                <div className='col-12 col-md-6 pl-4 pr-4 pr-md-2 align-self-start'>
                    <AppCardDataInfo
                        title={t('Overview')}
                        content={(
                            <div>
                                {
                                    overviewData.map((item, index) => (
                                        <div key={index}>
                                            <div className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                <p className="m-0">{item.label}</p>
                                                <p className="m-0 font-weight-bolder" align='right'>{item.value}</p>
                                            </div>
                                            {
                                                index < overviewData.length - 1 && (
                                                    <hr className='m-0' />
                                                )
                                            }
                                        </div>

                                    ))
                                }
                            </div>
                        )}
                    />
                </div>

                <div className='col-12 col-md-6 pr-4 pl-4 pl-md-2 align-self-start'>
                    <AppCardDataInfo
                        title='Top Lãi Theo Ngành'
                        content={(
                            <div>
                                <BarChart
                                    data={
                                        _.orderBy(arrSummaryIndustry, 'totalInterest', 'desc').map(item => {
                                            return { label: item.industryName, value: [item.winRate, item.lossRate] }
                                        })
                                    }
                                    color={['#0BDF39', '#FF0000']}
                                    legend={[t('WinRate'), t('LossRate')]}
                                    stacked={true}
                                    dataLabel={true}
                                />
                            </div>
                        )}
                    />
                </div>
            </div>

            {/* Hieu suat dau tu */}
            <div className='mt-4'>
                <AppCardDataInfo
                    title='Hiệu Suất Đầu Tư'
                    showTitleDivider={false}
                    content={(
                        <div className='px-4 pb-4 pt-2 position-relative'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="btn-group border rounded">
                                    {
                                        durationOptions.map((item, index) => {
                                            const isActive = item.value === investmentPerformanceDurationValue;
                                            return (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className={`btn btn-white border-0 ${isActive && 'active bg-primary text-white'}`}
                                                    onClick={() => {
                                                        let to = moment().format('DD/MM/YYYY');
                                                        let from = '';
                                                        if (_.includes(['1M', '3M', '6M', '12M'], item.value)) {
                                                            const iValue = _.find(durationOptions, { 'value': item.value })?.iValue;
                                                            if (iValue) {
                                                                from = moment().subtract(iValue, 'months').format('DD/MM/YYYY');
                                                            }
                                                        } else if (item.value === 'YTD') {
                                                            from = `01/01/${moment().format('YYYY')}`;
                                                        }
                                                        if (item.value === 'All') {
                                                            from = '';
                                                            to = '';
                                                            formik.getFieldHelpers('fromDate').setValue(from);
                                                            formik.getFieldHelpers('toDate').setValue(to);
                                                        }
                                                        if (!_.isEmpty(from)) {
                                                            formik.getFieldHelpers('fromDate').setValue(from);
                                                        }
                                                        if (!_.isEmpty(to)) {
                                                            formik.getFieldHelpers('toDate').setValue(to);
                                                        }
                                                        if (onChangeInvestmentPerformance) {
                                                            onChangeInvestmentPerformance(item.value, from, to);
                                                        }
                                                    }}
                                                >
                                                    {item.name}
                                                </button>
                                            )
                                        })
                                    }
                                </div>

                                <div className='d-flex align-items-center justify-content-center'>
                                    <DatePickerInputMask
                                        name='fromDate'
                                        fieldHelpers={formik.getFieldHelpers('fromDate')}
                                        fieldMeta={formik.getFieldMeta('fromDate')}
                                        fieldProps={formik.getFieldProps('fromDate')}
                                        // placeholder={`${t('FromDate')}...`}
                                        text={t('FromDate')}
                                        additionClassName='mb-0'
                                        disabled
                                    />
                                    <div>
                                        <span className='mx-4'>-</span>
                                        <p>&nbsp;</p>
                                    </div>
                                    <DatePickerInputMask
                                        name='toDate'
                                        fieldHelpers={formik.getFieldHelpers('toDate')}
                                        fieldMeta={formik.getFieldMeta('toDate')}
                                        fieldProps={formik.getFieldProps('toDate')}
                                        // placeholder={`${t('ToDate')}...`}
                                        text={t('ToDate')}
                                        additionClassName='mb-0'
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className='h-325px mt-4'>
                                <Chart
                                    options={options}
                                    series={series}
                                    type="area"
                                    className='flex-grow-1'
                                    height='100%'
                                />
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className='mt-4'>
                <MGDataTable
                    className='bg-white'
                    headItems={[
                        { title: t('Month'), subTitle: '', col: 'col-1' },
                        {
                            title: '', subTitle: '', component: (
                                <AnalyzeTransactionHistoryTableHeadItem
                                    headTitle='THỐNG KÊ GIAO DỊCH'
                                    bodyTitles={[
                                        {
                                            subTitle: 'Tổng Số Giao Dịch',
                                            unit: 'Lãi/Lỗ (Hòa)'
                                        },
                                        {
                                            subTitle: 'Số Ngày Giao Dịch',
                                            unit: 'Lãi/Lỗ (Hòa)'
                                        },
                                        {
                                            subTitle: 'Tỉ Lệ Giao Dịch Lãi/Lỗ',
                                            unit: '%'
                                        }
                                    ]}
                                />
                            ), col: 'col-3'
                        },
                        {
                            title: '', subTitle: '', component: (
                                <AnalyzeTransactionHistoryTableHeadItem
                                    headTitle='HIỆU SUẤT LÃI/LỖ'
                                    bodyTitles={[
                                        {
                                            subTitle: 'Trung Bình Lãi/Lỗ',
                                            unit: '%'
                                        },
                                        {
                                            subTitle: 'Tỷ Lệ Lãi/Lỗ',
                                            unit: '%'
                                        },
                                        {
                                            subTitle: 'Tỷ Lệ Lãi/Lỗ Điều Chỉnh',
                                            unit: ''
                                        }
                                    ]}
                                />
                            ), col: 'col-3'
                        },
                        {
                            title: '', subTitle: '', component: (
                                <AnalyzeTransactionHistoryTableHeadItem
                                    headTitle='SỐ LÃI/LỖ'
                                    bodyTitles={[
                                        {
                                            subTitle: 'Giá Trị Lãi/Lỗ',
                                            unit: '%'
                                        },
                                        {
                                            subTitle: 'Giá Trị Lãi/Lỗ',
                                            unit: 'VNĐ'
                                        },
                                        {
                                            subTitle: 'Ngày Nắm Giữ TB Lãi/Lỗ',
                                            unit: 'Ngày'
                                        }
                                    ]}
                                />
                            ), col: 'col-3'
                        },
                    ]}
                    dataItems={
                        arrStatistic.map((item, index) => {
                            return {
                                onPress: () => { },
                                data: [
                                    { title: item.monthYear, titleWeight: 600 },
                                    {
                                        component: (
                                            <AnalyzeTransactionHistoryTableBodyItem
                                                data={[
                                                    {
                                                        title: item.totalTransaction,
                                                        gain: item.totalTransactionWin,
                                                        loss: item.totalTransactionLoss
                                                    },
                                                    {
                                                        title: item.totalTransactionDay,
                                                        gain: item.totalTransactionDayWin,
                                                        loss: item.totalTransactionDayLoss
                                                    },
                                                    {
                                                        title: item.transactionWinRate,
                                                        titleColor: '#0BDF39',
                                                        subTitle: item.transactionLossRate,
                                                        subTitleColor: '#FF0000'
                                                    }
                                                ]}
                                            />)
                                    },
                                    {
                                        component: (
                                            <AnalyzeTransactionHistoryTableBodyItem
                                                data={[
                                                    // trung binh lai lo
                                                    { title: item.winRate, titleColor: '#0BDF39', subTitle: item.lossRate, subTitleColor: '#FF0000' },
                                                    // ti le lai/lo
                                                    { title: item.winRate, titleColor: '#0BDF39', subTitle: item.lossRate, subTitleColor: '#FF0000' },
                                                    // ti le lai/lo dieu chinh
                                                    { title: item.winRateAdjusted, titleColor: '#0BDF39', subTitle: item.lossRateAdjusted, subTitleColor: '#FF0000' }
                                                ]}
                                            />)
                                    },
                                    {
                                        component: (
                                            <AnalyzeTransactionHistoryTableBodyItem
                                                data={[
                                                    // gia tri lai/lo %
                                                    { title: item.winRate, titleColor: '#0BDF39', subTitle: item.lossRate, subTitleColor: '#FF0000' },
                                                    // gia tri lai/lo VND
                                                    { title: Utils.formatNumber(item.totalInterestValue), titleColor: '#0BDF39', subTitle: Utils.formatNumber(item.totalGrossValue), subTitleColor: '#FF0000' },
                                                    // ngay nam giu TB lai/lo
                                                    { title: item.totalTransactionDayWin, titleColor: '#0BDF39', subTitle: item.totalTransactionDayLoss, subTitleColor: '#FF0000' }
                                                ]}
                                            />)
                                    },
                                ]
                            }
                        })
                    }
                />
            </div>
        </div>
    );
}

export default AnalyzeTransactionHistory;
