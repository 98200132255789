import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import BaseFileInput from "general/components/Forms/FormWidgets/BaseFileInput";
import MyListHelper from "../../../../general/helpers/MyListHelper";
import myListApi from "../../../../api/myListApi";
import { useDispatch, useSelector } from "react-redux";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import { setCurrentSelectedMyList } from "../../myListSlice";

ModalImportTransaction.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalImportTransaction.defaultProps = {
  show: false,
  onClose: null,
};

/**
 * @prop show (bool): default false
 * @function onClose(): callback when close. Default null
 */
function ModalImportTransaction(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { show, onClose } = props;
  const { selectedMyList } = useSelector((state) => state.myList);
  const dispatch = useDispatch();

  // MARK: --- Formik ---
  const fileMaxLength = 10; // MB

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .required(t("NO_FILE_CHOSEN"))
        .test("fileSize", t("FILE_TOO_LARGE"), (file) => {
          return file?.size / 1048576 <= fileMaxLength;
        })
        .test("fileExtention", t("WRONG_FILE_TYPE"), (file) => {
          if (
            ![
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
              "application/excel",
              "application/x-excel",
              "application/x-msexcel",
            ].includes(file?.type)
          )
            return false;
          return true;
        }),
    }),

    onSubmit: async (values) => {
      // Call API ...
      handleClose();
      ToastHelper.showSuccess(t('Importing'))
      
      formik.handleReset();
      console.log(values.file);

      if (values.file) {
        try {
          let rs = await MyListHelper.readFromXls(values.file);

          console.log(rs);
          rs = rs.map((item) => MyListHelper.exactMyList(item));

          let { result } = await myListApi.addMultiple(
            selectedMyList?.myListId,
            rs
          );

          if (result == "success") {
            ToastHelper.showSuccess(t("Importing success"));
            dispatch(
              setCurrentSelectedMyList({
                ...selectedMyList,
                refreshingRequired: true,
              })
            );
          } else ToastHelper.showError(t("Importing failed"));
        } catch (error) {
          console.log(error);
          if (!!error.message) ToastHelper.showError(t(error.message));
        }
      }
    },
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        onHide={() => {
          handleClose();
        }}
        centered
      >
        <Modal.Header className="px-5 py-3">
          <Modal.Title>{t("ImportTransaction")}</Modal.Title>
          <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div>
        </Modal.Header>

        <Modal.Body className="bg-light p-6">
          <BaseFileInput
            name="file"
            fileExtension={".xlsx, .csv, .xls"}
            text={t("HintImportTransaction")}
            fieldProps={formik.getFieldProps("file")}
            fieldHelpers={formik.getFieldHelpers("file")}
            fieldMeta={formik.getFieldMeta("file")}
          />
        </Modal.Body>

        <Modal.Footer className="">
          <div className="w-100 d-flex row">
            <Button
              className="font-weight-bold flex-grow-1 col mr-3"
              variant="secondary"
              onClick={() => {
                handleClose();
                formik.handleReset();
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="font-weight-bold flex-grow-1 col ml-3"
              variant="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {t("Upload")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalImportTransaction;
