import AppCardDataInfo from 'general/components/AppCardDataInfo';
import BarChart from 'general/components/Charts/BarChart';
import EmptyView from 'general/components/Views/EmptyView';
import AppData from 'general/constants/AppData';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

CategoryAnalysis.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
};

CategoryAnalysis.defaultProps = {
    data: {},
    loading: false,
};

function CategoryAnalysis(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { data, loading } = props;
    const { capitalRef, industriesRef, top10Shares } = data;

    let isCapitalRefEmpty = true;
    (Object.keys(capitalRef ?? {})).forEach(item => {
        if (!Utils.isObjectNull(capitalRef[item])) {
            isCapitalRefEmpty = false;
        }
    });
    let isIndustriesRefEmpty = true;
    (Object.keys(industriesRef ?? {})).forEach(item => {
        if (!Utils.isObjectNull(industriesRef[item])) {
            isIndustriesRefEmpty = false;
        }
    });
    let isTop10SharesEmpty = top10Shares?.length === 0;

    const options = {
        chart: {
            id: 'apexchart-pie-marketCap'
        },
        labels: Object.keys(capitalRef ?? {}).map(item => t(item.toLowerCase())),
        // labels: [
        //     'Vốn hóa nhỏ',
        //     'Vốn hóa trung',
        //     'Vốn hóa lớn',
        // ], //revenues.map(item => item.name),
        colors: AppData.apexChartColors,
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            inverseOrder: true,
            height: 44,
            fontSize: '13px',
            fontFamily: 'IBM Plex Sans',
            fontWeight: 400,
            markers: {
                width: 18,
                height: 18,
                radius: 4,
            },
            itemMargin: {
                horizontal: 20,
                vertical: 0
            },
        },
    };
    const series = Object.keys(capitalRef ?? {}).map(item => capitalRef[item])
    // const series = [30000, 30000, 140000];//revenues.map(item => item.revenue);
    // const pieChartFeature = revenues.map((item, index) => {
    //     return {
    //         label: item.name,
    //         color: AppData.apexChartColors[index]
    //     }
    // });

    return (
        <div className='bg-white p-4 border-left border-bottom border-right'>
            <div className='row'>
                <div className='pl-4 col-12 col-lg-6 pr-4 pr-lg-2 align-self-start'>
                    <AppCardDataInfo
                        title={t('StructureOfThePortfolioByIndustry')}
                        content={(
                            <div>
                                {
                                    (!Utils.isObjectEmpty(data) && !isIndustriesRefEmpty) ? (
                                        <BarChart
                                            data={
                                                (Object.keys(industriesRef ?? {})).map(item => {
                                                    return (
                                                        { label: item, value: [parseFloat(industriesRef[item]).toFixed(1)] }
                                                    )
                                                })
                                                // [
                                                //     { label: 'Ngân hàng', value: [90, 25] },
                                                //     { label: 'Bất động sản', value: [55, 35] },
                                                //     { label: 'Công nghệ thông tin', value: [50, 40] },
                                                //     { label: 'Tài chính', value: [40, 35] },
                                                //     { label: 'Bán lẻ', value: [50, 30] },
                                                //     { label: 'Thực phẩm đồ uống', value: [96, 45] },
                                                //     { label: 'Xăng dầu', value: [95, 35] },
                                                //     { label: 'Hóa chất', value: [90, 25] },
                                                //     { label: 'Xăng dầu', value: [90, 25] },
                                                //     { label: 'Hóa chất', value: [30, 85] },
                                                // ]
                                            }
                                            color={['#68C57C'/*, '#4886FF'*/]}
                                            // legend={[t('Rate')/*, 'Nighty 50'*/]}
                                            // stacked={true}
                                            dataLabel={true}
                                        />
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                                loading={loading}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    />
                </div>
                <div className='col-12 col-lg-6 pl-4 pl-lg-2 mt-4 mt-lg-0 align-self-start'>
                    <AppCardDataInfo
                        title={t('Top10HoldingStocks')}
                        content={(
                            <div>
                                {
                                    (!Utils.isObjectEmpty(data) && !isTop10SharesEmpty) ? (
                                        <BarChart
                                            data={
                                                (top10Shares ?? []).map(item => {
                                                    return (
                                                        { label: `${item?.companyName} (${parseFloat(item?.percent).toFixed(6)}%)`, value: [parseFloat(item?.percent).toFixed(6)] }
                                                    )
                                                })
                                                // [
                                                //     { label: 'Ngân hàng', value: [250] },
                                                //     { label: 'Bất động sản', value: [140] },
                                                //     { label: 'Công nghệ thông tin', value: [125] },
                                                //     { label: 'Tài chính', value: [115] },
                                                //     { label: 'Bán lẻ', value: [100] },
                                                //     { label: 'Thực phẩm đồ uống', value: [90] },
                                                //     { label: 'Xăng dầu', value: [80] },
                                                //     { label: 'Hóa chất', value: [70] },
                                                //     { label: 'Xăng dầu', value: [-100] },
                                                //     { label: 'Hóa chất', value: [-115] },
                                                // ]
                                            }
                                            color={['#68C57C'/*, '#E84650'*/]}
                                            // legend={[t('Rate')]}
                                            dataLabel={true}
                                        />
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                                loading={loading}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    />

                    <AppCardDataInfo
                        additionsClassName='mt-4'
                        title={t('MarketCap')}
                        content={(
                            <div className='py-4'>
                                {
                                    (!Utils.isObjectEmpty(data) && !isCapitalRefEmpty) ? (
                                        <Chart
                                            // className='pieChart-hide-label'
                                            options={options}
                                            series={series}
                                            type="pie"
                                            width='100%'
                                            height={320}
                                        />
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                                loading={loading}
                                            />
                                        </div>
                                    )
                                }

                            </div>
                        )}
                    />
                </div>
                {/* <div className='pl-4 col-12 col-lg-6 pr-4 pr-lg-2 mt-4'>
                    <AppCardDataInfo
                        title={t('MarketCap')}
                        content={(
                            <div className='py-4'>
                                <Chart
                                    // className='pieChart-hide-label'
                                    options={options}
                                    series={series}
                                    type="pie"
                                    width='100%'
                                    height={320}
                                />
                            </div>
                        )}
                    />
                </div> */}
            </div>
        </div>
    );
}

export default CategoryAnalysis;
