import ModalSectorChart from 'features/Stock/components/ModalSectorChart';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

ModalAllSector.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

ModalAllSector.defaultProps = {
    show: false,
    onClose: null,
};

function ModalAllSector(props) {
    // MARK: --- Params ---
    const { show, onClose } = props;
    const { t } = useTranslation();
    const { sectorIndexData } = useSelector(state => state.app);
    const [modalSectorChartShowing, setModalSectorChartShowing] = useState(false);
    const [selectedSectorItem, setSelectedSectorItem] = useState(null);
    const [showing, setShowing] = useState(true);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show && showing}
                onHide={() => {
                    handleClose();
                }}
                centered
                size='lg'
                onExited={() => {

                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('Sectors')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='overflow-auto' style={{
                    maxHeight: '84vh'
                }}>
                    <div>
                        <MGDataTable
                            className='bg-white mt-2'
                            headItems={[
                                { title: t('Sector'), subTitle: '', col: 'col-4' },
                                { title: t('Volume'), subTitle: '', col: 'col-2', textAlign: 'right' },
                                { title: t('Change') + ' KL (%)', subTitle: '', col: 'col-2', textAlign: 'right' },
                                { title: `${t('Price')}`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                { title: `${t('Change') + ' Giá (%)'}`, subTitle: '', col: 'col-2', textAlign: 'right' },
                            ]}
                            dataItems={
                                sectorIndexData.map((item, index) => {
                                    const { totalVolume, totalPrice } = item;
                                    const yesterdayTotalVolume = item?.yesterday?.totalVolume;
                                    const yesterdayTotalPrice = item?.yesterday?.totalPrice;
                                    let volumePerChange = '0';
                                    let pricePerChange = '0';
                                    if (totalVolume && yesterdayTotalVolume) {
                                        volumePerChange = ((totalVolume - yesterdayTotalVolume) * 100 / yesterdayTotalVolume).toFixed(2);
                                    }
                                    if (totalPrice && yesterdayTotalPrice) {
                                        pricePerChange = ((totalPrice - yesterdayTotalPrice) * 100 / yesterdayTotalPrice).toFixed(2);
                                    }

                                    return {
                                        onPress: () => {
                                            setModalSectorChartShowing(true);
                                            setShowing(false);
                                            setSelectedSectorItem(item);
                                        },
                                        data: [
                                            { icon: '', title: item.name, titleWeight: '600', name: '', value: '', valueColor: '', col: 'col-4', enableHoverTitle: true },
                                            { icon: '', title: '', name: Utils.formatNumber(item.totalVolume), value: '', valueColor: Utils.getValueColor(volumePerChange), col: 'col-2', textAlign: 'right' },
                                            { icon: '', title: '', value: '', name: `${volumePerChange > 0 ? '+' : ''}${Utils.formatNumber(volumePerChange)}%`, nameColor: Utils.getValueColor(volumePerChange), col: 'col-2', textAlign: 'right' },
                                            { icon: '', title: '', name: Utils.formatNumber((item.totalPrice / 1).toFixed(2)), value: ``, valueColor: Utils.getValueColor(pricePerChange), col: 'col-2', textAlign: 'right' },
                                            { icon: '', title: '', value: '', name: `${pricePerChange > 0 ? '+' : ''}${Utils.formatNumber(pricePerChange)}%`, nameColor: Utils.getValueColor(pricePerChange), col: 'col-2', textAlign: 'right' },
                                        ]
                                    }
                                })
                            }
                        />
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal sector chart */}
            <ModalSectorChart
                show={modalSectorChartShowing}
                sectorItem={selectedSectorItem}
                onClose={() => {
                    setModalSectorChartShowing(false);
                    setShowing(true);
                    setSelectedSectorItem(null);
                }}
            />
        </div>
    );
}

export default ModalAllSector;