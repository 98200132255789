import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppImages } from "general/constants/AppResource";
import Utils from "general/utils/Utils";

ModalJoinWebinarSuccess.propTypes = {
  webinarItem: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalJoinWebinarSuccess.defaultProps = {
  show: false,
  onClose: null,
};

function ModalJoinWebinarSuccess(props) {
  // MARK: --- Params ---
  const { webinarItem, show, onClose } = props;
  const { t } = useTranslation();

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className=""
      size="sm"
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header>
        <Modal.Title>
          {t("RegisterSuccessful")}
        </Modal.Title>
        <div
          className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
          onClick={() => {
            handleClose();
          }}
        >
          <i className="far fa-times"></i>
        </div>
      </Modal.Header>

      <Modal.Body className="bg-light">
        <img
          src={AppImages.imgRegisterWebinarSuccess}
          draggable={false}
          className="w-100 mt-2 "
        />

        <p className="font-weight-normal font-size-base line-height-lg mt-2">
          {t('RegisterSeminarSuccessGreetings')}
          <span className="font-weight-boldest">"{webinarItem.name}"</span>
        </p>
        <p className="font-weight-normal font-size-base line-height-lg">
          {t('SebinarTakePlace')} <span className="purple font-weight-bold">{Utils.formatDateTime(webinarItem.startTime, 'DD/MM/YYYY hh:mm A')}</span>
        </p>
        <p className="font-weight-normal font-size-base line-height-lg">
          {t("GreetingsAfterPayment")}
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalJoinWebinarSuccess;
