import casual from "casual-browserify";
import { AppIcons } from "./AppResource";

const AppData = {
  footerCustomerSupportMenuItems: [
    {
      url: "/about-us",
      text: "AboutUs",
    },
  ],

  footerCooperateMenuItems: [
    {
      url: "/faq",
      text: "FAQ",
    },
    {
      url: "/policy-privacy",
      text: "PrivacyPolicy",
    },
    {
      url: "/terms",
      text: "TermsAndConditions",
    },
    {
      url: "/contact-us",
      text: "ContactUs",
    },
  ],

  footerFeatureMenuItems: [
    {
      url: '/knowledge-center',
      text: 'KnowledgeCenter',
    },
    {
      url: "/course",
      text: "Course",
    },
    {
      url: "/webinar",
      text: "Webinar",
    },
    {
      url: "/product",
      text: "Product",
    },
  ],

  stockIndexSampleData: [
    {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    }, {
      stockCode: "VNINDEX",
      price: "1,497.73",
      changeInPercent: "-0.04",
      change: "-0.53",
    },
    {
      stockCode: "VNXALL",
      price: "1,497.73",
      changeInPercent: "0.02",
      change: "0.67",
    },
    {
      stockCode: "VN30",
      price: "1,497.73",
      changeInPercent: "-0.01",
      change: "-0.23",
    },
    {
      stockCode: "HNX30",
      price: "1,497.73",
      changeInPercent: "0.06",
      change: "0.13",
    },
  ],

  // App site menu
  appSiteMenus: [
    {
      url: "/home/main",
      text: "Home",
    },
    {
      url: "/idea-list",
      text: "IdeaList",
      subMenus: [
        {
          url: "/idea-list/mg-lists",
          text: "MoneyGainLists",
        },
        {
          url: "/idea-list/top-50-growth-portfolio-mg",
          text: "Top50GrowthPortfolioMG",
        },
        {
          url: "/idea-list/stock-filter-mg",
          text: "StockFilterMG",
        },
        // {
        //   url: "/idea-list/guru-screen",
        //   text: "GuruScreen",
        // },
        {
          url: "/idea-list/build-your-screen",
          text: "BuildYourScreen",
        },
        {
          url: "/account/my-list",
          text: "MyLists",
        },
      ],
    },
    {
      url: "/research",
      text: "Research",
    },
    {
      url: "/knowledge-center",
      text: "Learn",
      subMenus: [
        {
          url: "/knowledge-center/video",
          text: "Video",
          // icon: 'fas fa-video'
        },
        {
          url: "/knowledge-center/webinar",
          text: "Webinar",
          // icon: 'fas fa-handshake'
        },
        {
          url: "/knowledge-center/article",
          text: "Article",
          // icon: 'fas fa-newspaper'
        },
        {
          url: "/knowledge-center/book",
          text: "Book",
          // icon: 'fas fa-book'
        },
        {
          url: "/knowledge-center/investing-university",
          text: "InvestingUniversity",
          // icon: 'fas fa-school'
        },
      ],
    },
    {
      url: "/course",
      text: "Course",
    },
  ],

  // Sample images url
  sampleImageUrls: [
    "https://www.w3schools.com/css/img_forest.jpg",
    "https://www.w3schools.com/css/img_5terre.jpg",
    "https://www.w3schools.com/css/img_lights.jpg",
    "https://www.w3schools.com/css/img_mountains.jpg",
  ],

  // MoneyGainLists
  landingPageMGLists: [
    {
      text: "MoneyGain50 - Danh sách 50 cổ phiếu xếp hạng cao của MG.",
      url: "/idea-list/top-50-growth-portfolio-mg/top-50-growth",
    },
    {
      text: "Danh sách những cổ phiếu dẫn dắt với dòng tiền lớn, nơi đây bạn sẽ tìm thấy các cổ phiếu tốt nhất thỏa các tiêu chí đầu tư về sức mạnh về tài chính và sức mạnh giá. Đây là những ứng cử viên chính để tăng cao hơn.",
      url: "",
    },
    {
      text: "Danh sách đỉnh cao mới, phá vỡ nền giá, DS kéo ngược MA50.",
      url: "",
    },
    {
      text: "Danh sách cổ phiếu gần điểm mua, nơi đây bạn sẽ nhận được những cổ phiếu trong danh sách cổ phiếu đang ở gần vùng mua lý tưởng.",
      url: "",
    },
    {
      text: "Phân tích đồ thị trong ngày với các tín hiệu mua bán, nơi đây bạn sẽ nhận được danh sách cập nhật các cổ phiếu xuất hiện các tín hiệu tích cực cần phải để mắt tới.",
      url: "",
    },
    {
      text: "Danh sách những cổ phiếu trong xu hướng tăng trung dài hạn theo Mark Minervini, nơi đây cho phép bạn lựa chọn những cổ phiếu nằm trong xu hướng tăng trung dài hạn. Đây là tiêu chí đầu tiên trước khi mua.",
      url: "",
    },
    {
      text: "Danh sách những cổ phiếu trong xu hướng giảm trung dài hạn, đây là danh sách những cổ phiếu không nên nắm giữ hoặc mua mới.",
      url: "",
    },
  ],

  // apex chart colors
  apexChartColors: [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#03A9F4',
    '#4CAF50',
    '#F9CE1D',
    '#FF9800',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
    '#13D8AA',
    '#A5978B',
    '#4ECDC4',
    '#C7F464',
    '#81D4FA',
    '#546E7A',
    '#FD6A6A',
    '#2B908F',
    '#F9A3A4',
    '#90EE7E',
    '#FA4443',
    '#69D2E7',
    '#449DD1',
    '#F86624',
    '#EA3546',
    '#662E9B',
    '#C5D86D',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
    '#662E9B',
    '#F86624',
    '#F9C80E',
    '#EA3546',
    '#43BCCD',
    '#5C4742',
    '#A5978B',
    '#8D5B4C',
    '#5A2A27',
    '#C4BBAF',
    '#A300D6',
    '#7D02EB',
    '#5653FE',
    '#2983FF',
    '#00B1F2',
  ],

  // Sample frequently questions
  sampleFrequentlyQuestions: Array(6)
    .fill({})
    .map((item, index) => {
      return {
        id: index,
        question: casual.text,
        answer: casual.text,
      };
    }),

  accountMenuItems: [
    {
      icon: AppIcons.icAccountInfo,
      title: "AccountInfoTitle",
      description: "AccountInfoDescription",
      detail: "AccountInfoDetail",
      url: "/account/info",
    },
    {
      icon: AppIcons.icAccountReferral,
      title: "AccountReferralTitle",
      description: "AccountReferralDescription",
      detail: "AccountReferralDetail",
      url: "/account/referral",
    },
    {
      icon: AppIcons.icAccountCourse,
      title: "AccountCourseTitle",
      description: "AccountCourseDescription",
      detail: "AccountCourseDetail",
      url: "/account/course",
    },
    {
      icon: AppIcons.icAccountTransaction,
      title: "AccountTransactionTitle",
      description: "AccountTransactionDescription",
      detail: "AccountTransactionDetail",
      url: "/account/transaction",
    },
    {
      icon: AppIcons.icAccountMyList,
      title: "AccountMyListTitle",
      description: "AccountMyListDescription",
      detail: "AccountMyListDetail",
      url: "/account/my-list",
    },
    {
      icon: AppIcons.icAccountSecurity,
      title: "AccountSecurityTitle",
      description: "AccountSecurityDescription",
      detail: "AccountSecurityDetail",
      url: "/account/security",
    },
  ],
  // Pagination per page items
  perPageItems: [{ value: 5 }, { value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }, { value: 80 }, { value: 100 }],

  knowledgeCenterMenuItems: [
    {
      text: 'Video',
      url: '/knowledge-center/video',
    },
    {
      text: 'Webinar',
      url: '/knowledge-center/webinar',
    },
    {
      text: 'Article',
      url: '/knowledge-center/article',
    },
    {
      text: 'Book',
      url: '/knowledge-center/book',
    },
    {
      text: 'InvestingUniversity',
      url: '/knowledge-center/investing-university',
    },
  ],

  sampleExchangeOptions: [
    {
      text: 'HOSE',
      value: 'HOSE'
    },
    {
      text: 'HNX',
      value: 'HNX'
    },
    {
      text: 'UPCOM',
      value: 'UPCOM'
    },
  ],

  sampleStockFilterOptions: [
    // { text: 'ChooseFilterOption', value: '' },
    { text: 'EPS', value: 'epsStrength' },
    { text: 'BVPS', value: 'bvps' },
    { text: 'ROE', value: 'yearRoe' },
    { text: 'ROA', value: 'yearRoa' },
    { text: 'ROS', value: 'yearRos' },
  ],

  sampleStockFilterValueOptions: [
    { text: 'Greater', value: 1 },
    { text: 'GreaterOrEqual', value: 3 },
    { text: 'Equal', value: 0 },
    { text: 'Smaller', value: 2 },
    { text: 'SmallerOrEqual', value: 4 },
  ],
  allExchangeCode: ["HOSE", "HNX", "UPCOM"],

  sampleStockOptions: [
    { text: 'Tăng Giá (%)', value: 'CHANGE_ASC' },
    { text: 'Giảm Giá (%)', value: 'CHANGE_DESC' },
    { text: 'Khối Lượng', value: 'VOLUME' },
  ],

  reportTermItems: [
    { text: 'Quý 1', value: 'Q1' },
    { text: 'Quý 2', value: 'Q2' },
    { text: 'Quý 3', value: 'Q3' },
    { text: 'Quý 4', value: 'Q4' },
    { text: 'Năm', value: 'N' },
  ],

  samplePushNotificaltion: {
    "title": "Title",
    "text": "Body",
    "image": "https://scontent.fhan3-3.fna.fbcdn.net/v/t39.30808-6/244680012_125121496548477_5000572505486212464_n.jpg?stp=dst-jpg_s960x960&_nc_cat=101&ccb=1-7&_nc_sid=e3f864&_nc_ohc=5QPXahBDMosAX-AvabA&_nc_ht=scontent.fhan3-3.fna&oh=00_AT-rcE8X5txvBf1i0i23zcx9DURe2XXkv6Wduvx-I0gDfg&oe=62CCA885",
    "tag": "#mg",
    "url": "https://dev.moneygain.com.vn"
  },

  specialStockCodes: ['VNINDEX', 'VN30', 'HNXINDEX', 'HNX30', 'UPCOMINDEX', 'VNXALL'],
};

export default AppData;
