import { toast } from 'react-toastify';

const ToastHelper = {
    showSuccess: (message) => {
        toast.success(message);
    },

    showError: (error) => {
        toast.error(error);
    },
    dismiss: ()=>{
        toast.dismiss()
    }
};

export default ToastHelper;
