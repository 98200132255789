import CellArticleList from "features/Article/components/CellArticleList";
import ModalArticleDetail from "features/Article/components/ModalArticleDetail";
import { useFormik } from "formik";
import AppCardDataInfo from "general/components/AppCardDataInfo";
import AppTabs from "general/components/AppTabs";
import DatePickerInputMask from "general/components/Forms/FormWidgets/DatePickerInputMask";
import MGDataTable from "general/components/Tables/MGDataTable";
import EmptyView from "general/components/Views/EmptyView";
import AppData from "general/constants/AppData";
import { AppIcons } from "general/constants/AppResource";
import Global from "general/Global";
import _ from "lodash";
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from "react";
import Chart from 'react-apexcharts';
import { Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import LoadMore from "../../../../general/components/LoadMore";
import Utils from "../../../../general/utils/Utils";
import ModalAssociates from "../ModalAssociates";
import ModalTradingView from "../ModalTradingView";
import Warrant from "../warrants";
import CandleChart from "./components/CandleChart";
import './styles.scss';

Summary.propTypes = {
    currentStockPrice: PropTypes.number,
    tradingViewSymbol: PropTypes.string,
    boardOfManagements: PropTypes.array,
    majorHolders: PropTypes.array,
    ownerships: PropTypes.array,
    reportData: PropTypes.object,
    laborStructures: PropTypes.array,
    associates: PropTypes.array,
    processOfIncreasingCharteredCapitals: PropTypes.array,
    financeReportByTime: PropTypes.object,
    intervalType: PropTypes.number,
    onChangeIntervalType: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onChangeDate: PropTypes.func,
    warrants: PropTypes.array,
    onClickMoreWarrant: PropTypes.func,
    onClickMoreMajorHolder: PropTypes.func,

    // bao cao tai chinh
    // reports: PropTypes.object,
    // reportNorms: PropTypes.object,
    // financeIndexes: PropTypes.object,
    // financeIndexNorms: PropTypes.object,
    // onFilter: PropTypes.func,
    // reportYears: PropTypes.array.isRequired,
    // reportTermCodes: PropTypes.array.isRequired,
};

Summary.defaultProps = {
    currentStockPrice: 0,
    tradingViewSymbol: '',
    boardOfManagements: [],
    majorHolders: [],
    ownerships: [],
    reportData: {},
    laborStructures: [],
    associates: [],
    processOfIncreasingCharteredCapitals: [],
    financeReportByTime: {},
    intervalType: 4,
    onChangeIntervalType: PropTypes.func,
    startDate: '',
    endDate: '',
    onChangeDate: null,
    warrants: [],
    onClickMoreWarrant: null,
    onClickMoreMajorHolder: null,
    // bao cao tai chinh
    // reports: {},
    // reportNorms: {},
    // financeIndexes: {},
    // financeIndexNorms: {},
    // onFilter: null,
};

function Summary(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const {
        stockCode,
        currentStockPrice,
        tradingViewSymbol,
        company,
        revenues,
        lastYearRevenue,
        boardOfManagements,
        majorHolders,
        ownerships,
        reportData,
        laborStructures,
        associates,
        processOfIncreasingCharteredCapitals,
        financeReportByTime,
        intervalType,
        onChangeIntervalType,
        startDate,
        endDate,
        onChangeDate,

        articles,
        events,
        paginationArticle,
        paginationEvent,
        onLoadMoreArticle,
        onLoadMoreEvent,

        warrants,
        onClickMoreWarrant,
        onClickMoreMajorHolder,

        // bao cao tai chinh
        // reports,
        // reportNorms,
        // financeIndexNorms,
        // financeIndexes,
        // onFilter,
        // reportYears,
        // reportTermCodes
    } = props;
    const isSpecialStockCode = props.isSpecialStockCode ?? false;
    let sumRevenue = lastYearRevenue?.value
    const [currentRange, setCurrentRange] = useState('1D');
    const [modalViewMoreNews, setModalViewMoreNews] = useState(false);
    const [modalViewMoreEvent, setModalViewMoreEvent] = useState(false);

    const intervalList = [
        {
            Id: 0,
            value: "1H",
            title: "1H"
        },
        {
            Id: 1,
            value: "2H",
            title: "2H"
        },
        {
            Id: 2,
            value: "3H",
            title: "3H"
        },
        {
            Id: 3,
            value: "4H",
            title: "4H"
        },
        {
            Id: 4,
            value: null,
            title: "1D"
        },
        {
            Id: 5,
            value: null,
            title: "1W"
        },
        {
            Id: 6,
            value: null,
            title: "1M"
        }
    ];

    const options = useMemo(() => {
        return {
            chart: {
                id: 'apexchart-pie-major-holder'
            },
            labels: ownerships.map(item => item.name),
            colors: AppData.apexChartColors,
        }
    }, [ownerships]);
    const series = useMemo(() => {
        return ownerships.map(item => item.shares);
    }, [ownerships]);
    const pieChartFeature = useMemo(() => {
        return ownerships.map((item, index) => {
            return {
                label: item.name,
                color: AppData.apexChartColors[index]
            }
        })
    }, [ownerships]);

    // const options = {
    //     chart: {
    //         id: 'apexchart-pie-major-holder'
    //     },
    //     labels: ownerships.map(item => item.name),
    //     colors: AppData.apexChartColors,
    // };
    // const series = ownerships.map(item => item.shares);
    // const pieChartFeature = ownerships.map((item, index) => {
    //     return {
    //         label: item.name,
    //         color: AppData.apexChartColors[index]
    //     }
    // })

    // doanh thu
    const optionsRevenue = {
        chart: {
            id: 'apexchart-pie-revenue'
        },
        labels: revenues.map(item => item.name),
        colors: AppData.apexChartColors,
    };
    const seriesRevenue = revenues.map(item => item.revenue);
    const pieChartFeatureRevenue = revenues.map((item, index) => {
        return {
            label: item.name,
            color: AppData.apexChartColors[index]
        }
    });

    // thong tin niem yet
    const listedInfo = [
        { label: t('ListingDate'), value: Utils.formatDateTime(company?.postUpDate, 'DD/MM/YYYY') },
        { label: t('FirstTradingSessionPrice'), value: `${Utils.formatNumber(company?.firstTradingSessionPrice)} VNĐ` },
        // { label: t('InitialListingVolume'), value: '' },
        { label: t('ListingVolume'), value: Utils.formatNumber(reportData.klcpny) },
        { label: t('ListingVolumeCirculate'), value: Utils.formatNumber(reportData.klcplh) },
        { label: t('ListingPlace'), value: company?.stock.exchangeCode },
    ];

    // thong tin cong ty
    const employees = laborStructures.map(item => item.numOfEmployees);
    const totalEmployees = employees.reduce((sum, number) => {
        return sum + number;
    }, 0);
    const info = [
        { label: t('Address'), value: company?.address, },
        { label: t('PhoneNumber'), value: company?.phone, },
        { label: t('TaxCode'), value: company?.taxCode, },
        { label: t("Fax"), value: company?.fax, },
        { label: t("Email"), value: company?.email, },
        { label: t("Website"), value: company?.website, },
        { label: t("FoundPermit"), value: company?.foundPermit, },
        { label: t("BusinessPermit"), value: company?.businessPermit, },
        { label: t('Industry'), value: company?.industry?.name },
        { label: t('Employees'), value: Utils.formatNumber(totalEmployees) },
    ];
    const text = company?.introduction;

    // lich su tang von
    const seriesCharteredCapitals = [{
        name: t('AuthorizedCapital'),
        data: processOfIncreasingCharteredCapitals.map(item => Math.round(item.charteredCapital / 1000000000))
    }];
    const optionsCharteredCapitals = {
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        xaxis: {
            categories: processOfIncreasingCharteredCapitals.map(item => Utils.formatMonthYear(item.dateChange)),
        }
    };
    // bao cao tai chinh
    const tabsFinanceReport = [
        t('FinanceReportByQuarter'),
        t('FinanceReportByYear')
    ];
    const [selectedTabFinanceReport, setSelectedTabFinanceReport] = useState(tabsFinanceReport[0]);
    let arrQuarter = [];
    let arrYears = [];
    const arrProfits = [];
    _.forEach((financeReportByTime.profits ?? []), (item, index) => {
        const quarterYear = `${item?.termCode}/${item?.yearPeriod}`;
        arrProfits.push({
            quarterYear: quarterYear,
            quarter: item.termCode,
            value: item.value,
            year: item.yearPeriod,
        });
        if (!_.includes(arrQuarter, quarterYear) && item.termCode !== 'N') {
            arrQuarter.push(quarterYear);
        }
        if (!_.includes(arrYears, item.yearPeriod) && item.termCode === 'N') {
            arrYears.push(item.yearPeriod);
        }
    });

    const arrRevenues = [];
    _.forEach((financeReportByTime.revenues ?? []), (item, index) => {
        const quarterYear = `${item?.termCode}/${item?.yearPeriod}`;
        arrRevenues.push({
            quarterYear: `${item?.termCode}/${item?.yearPeriod}`,
            quarter: item.termCode,
            value: item.value,
            year: item.yearPeriod,
        });

        if (!_.includes(arrQuarter, quarterYear) && item.termCode !== 'N') {
            arrQuarter.push(quarterYear);
        }
        if (!_.includes(arrYears, item.yearPeriod) && item.termCode === 'N') {
            arrYears.push(item.yearPeriod);
        }
    });
    arrQuarter = _.orderBy(arrQuarter, 'desc');
    arrYears = _.orderBy(arrYears, 'desc');


    const handleChangeDateRange = (code) => {
        if (code != intervalType) {
            if (onChangeIntervalType) {
                onChangeIntervalType(code);
                setCurrentRange(intervalList[code].title)
            }
        }
    }

    const stockInfo = [
        { label: t('Industry'), value: company?.industry?.name },
        {
            label: t('MarketCap'),
            value: (Utils.formatNumber((company?.marketCap / 1000000000).toFixed(2)) ?? "") + " " + t("billion")
        },
        {
            label: `${t('Revenue')} (${lastYearRevenue?.year ?? ""})`,
            value: Utils.formatNumber((sumRevenue / 1000000000).toFixed(2)) + " " + t("billion")
        },
        // { label: "FEPS", value: Utils.formatNumber(company?.stock?.feps) ?? "" },
        { label: "EPS (x1000)", value: Utils.formatNumber(company?.stock?.eps) ?? "" },
        { label: "EPS (DC)", value: Utils.formatNumber(company?.stock?.eps_dc) ?? "" },
        { label: "P/E", value: Utils.formatNumber(company?.stock?.pe) ?? "" },
        { label: "P/B", value: Utils.formatNumber(company?.stock?.pb) ?? "" },
        { label: "ROE", value: Utils.formatNumber(company?.stock?.roe ?? "") + '%' },
        { label: "ROA", value: Utils.formatNumber(company?.stock?.roa ?? "") + '%' },
        { label: "EV/EBITDA", value: Utils.formatNumber(company?.stock?.roa ?? "") },
        { label: "BVPS (x1000)", value: Utils.formatNumber(company?.stock?.bvps) },
        { label: "Beta", value: Utils.formatNumber(company?.stock?.beta ?? "") },
        { label: "KLCPLH", value: Utils.formatNumber(company?.stock?.klcplh) },
        { label: "KLCPLHDC", value: Utils.formatNumber((company?.stock?.klcplh_dc ?? 0).toFixed(2)) },
        { label: t('Cổ tức tiền (x1000)'), value: `${Utils.formatNumber(company?.stock?.dividend) ?? ""} (${currentStockPrice > 0 ? Utils.formatNumber((parseFloat(company?.stock?.dividend) * 100 / currentStockPrice).toFixed(2)) : ''}%)` },
    ];

    const { stockCandleData, stockVolumeData, isLoadingStockChartData } = useSelector(state => state.stock);

    const [showStockChartModal, setShowStockChartModal] = useState(false)

    const handleClose = () => setShowStockChartModal(false);
    const handleShow = () => setShowStockChartModal(true);

    const formik = useFormik({
        initialValues: {
            endDate: endDate,
            startDate: startDate
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            if (onChangeDate) {
                onChangeDate(values.startDate, values.endDate);
            }
        }
    });

    const refScrollArticle = useRef(null);
    const refScrollEvent = useRef(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [modalArticleDetailVisible, setModalArticleDetailVisible] = useState(false);

    function handleScrollArticle(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(paginationArticle);
            if (onLoadMoreArticle && nextPage) {
                if (refScrollArticle.current) {
                    Global.gSavedScrollTopArticle = refScrollArticle.current.scrollTop;
                }
                onLoadMoreArticle(nextPage);
            }
        }
    }

    function handleScrollEvent(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
        if (bottom) {
            let nextPage = Utils.getNextPage(paginationEvent);
            if (onLoadMoreEvent && nextPage) {
                Global.gSavedScrollTopEvent = refScrollEvent.current.scrollTop;

                onLoadMoreEvent(nextPage);
            }
        }
    }

    function handleSelectedTabFinanceReport(tab) {
        setSelectedTabFinanceReport(tab);
    }

    const [modalTradingViewShowing, setModalTradingViewShowing] = useState(false);
    const [modalAssociateShowing, setModalAssociateShowing] = useState(false);

    return (
        <div className="p-4">
            <div className="">
                <div className="row" id="stock_summary">
                    {/* bieu do */}
                    <div className={'col-12 align-self-start'}>
                        <AppCardDataInfo
                            title={(
                                <div>
                                    {t('Chart')}
                                    <button className="btn btn-light-dark font-weight-bold ml-4" onClick={() => {
                                        setModalTradingViewShowing(true);
                                    }}>Trading View</button>
                                </div>
                            )}
                            rightComponent={(
                                <form onSubmit={formik.handleSubmit}>
                                    <div style={{
                                        alignContent: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "39px"
                                    }}>
                                        <div className={"col-xs-12"} style={{
                                            paddingBottom: 0,
                                            marginBottom: 0
                                        }}>

                                            <DatePickerInputMask
                                                name="startDate"
                                                fieldHelper={formik.getFieldHelpers('startDate')}
                                                fieldProps={formik.getFieldProps('startDate')}
                                                fieldMeta={formik.getFieldMeta('startDate')}
                                            />
                                        </div>
                                        <span style={{
                                            paddingLeft: "5px",
                                            paddingRight: "5px",
                                            lineHeight: "39px",
                                            verticalAlign: "middle"
                                        }}>{"–"}</span>
                                        <div className={"col-xs-12"}>
                                            <DatePickerInputMask
                                                name="endDate"
                                                fieldHelper={formik.getFieldHelpers('endDate')}
                                                fieldProps={formik.getFieldProps('endDate')}
                                                fieldMeta={formik.getFieldMeta('endDate')}
                                            />
                                        </div>
                                        <div style={{ height: "36px" }}>
                                            <button
                                                type='submit'
                                                className="custom-filter-button btn-primary btn-sm border-0 font-weight-bolder font-size-base hover-opacity-80 px-6"
                                            >
                                                {t('Filter')}
                                            </button>
                                        </div>
                                        <div className={"custom-div-chart extend-button hover-opacity-80 px-6 d-flex align-items-center justify-content-center"}
                                            onClick={handleShow}>
                                            <div className={"custom-loading"}>
                                                <i className="far fa-expand" style={{
                                                    color: "white"
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                            content={(
                                isLoadingStockChartData ?
                                    <div className="h-600px custom-div-chart">
                                        <div className={"custom-loading"}>
                                            <LoadMore />
                                        </div>
                                    </div> :
                                    (stockCandleData?.length == 0 && stockVolumeData?.length == 0) ?
                                        (
                                            <div
                                                className='h-600px w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                        :
                                        <div className="h-650px">
                                            <CandleChart candleData={stockCandleData} volumeData={stockVolumeData} />
                                        </div>

                            )}
                            bottomComponent={(
                                <div>
                                    <Dropdown alignRight>
                                        <Dropdown.Toggle variant="" className="font-weight-bolder border">
                                            {currentRange}
                                            <i className="fas fa-caret-down text-dark ml-2"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {intervalList.map((item, index) => {
                                                return (
                                                    <Dropdown.Item className="w-100 font-weight-bolder d-flex flex-row justify-content-between" key={index} onClick={() => handleChangeDateRange(item?.Id)}>
                                                        {item?.title}
                                                        {currentRange === item.title && <i className="fas fa-check text-success"></i>}
                                                    </Dropdown.Item>
                                                )
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* {
                                        intervalList.map((item, index) => {
                                            return (
                                                <div className="w-100" key={index}>
                                                    <button
                                                        className={getClassNameButton(item?.Id)}
                                                        onClick={() => handleChangeDateRange(item?.Id)}
                                                    >
                                                        {item?.title}

                                                    </button>
                                                </div>
                                            )
                                        })
                                    } */}
                                </div>
                            )}
                        />
                    </div>
                </div>

                {
                    !isSpecialStockCode && (
                        <div>
                            {/* row 1 */}
                            <div className="row mt-4">
                                <div className="col-12 col-lg-4 align-self-start p-0 pl-4 pr-4 pr-lg-0">
                                    {/* thong tin chung khoan */}
                                    <div>
                                        <AppCardDataInfo
                                            title={t('StockInfo')}
                                            content={(
                                                company ? (
                                                    <div>
                                                        {
                                                            stockInfo.map((item, index) => (
                                                                <div key={index}>
                                                                    <div
                                                                        className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                                        <p className="m-0">{item.label}</p>
                                                                        <p className="m-0" align='right'>{item.value}</p>
                                                                    </div>
                                                                    {
                                                                        index < stockInfo.length - 1 && (
                                                                            <hr className='m-0' />
                                                                        )
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                        <EmptyView
                                                            description={t('NoDataToDisplay')}
                                                            iconEmpty={AppIcons.icEmptyPack}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        />
                                    </div>

                                    {/* thong tin niem yet */}
                                    {/* <div className='mt-4'>
                                        <AppCardDataInfo
                                            title={t('ListingInformation')}
                                            content={
                                                <div>
                                                    {
                                                        listedInfo.map((item, index) => (
                                                            <div key={index}>
                                                                <div className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                                    <p className="m-0">{item.label}</p>
                                                                    <p className="m-0" align='right'>{item.value}</p>
                                                                </div>
                                                                {
                                                                    index < listedInfo.length - 1 && (
                                                                        <hr className='m-0' />
                                                                    )
                                                                }
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                            }
                                        />
                                    </div> */}

                                    {/* co cau doanh thu */}
                                    {/* <div className='mt-4'>
                                        <AppCardDataInfo
                                            title={`${t('Revenues')} ${revenues && revenues.length > 0 && revenues[0].year}`}
                                            content={(
                                                <div className=''>
                                                    <Chart
                                                        className='pieChart-hide-label'
                                                        options={optionsRevenue}
                                                        series={seriesRevenue}
                                                        type="pie"
                                                        width='100%'
                                                        height={320}
                                                    />
                                                    <div className='max-h-300px overflow-auto'>
                                                        {
                                                            pieChartFeatureRevenue.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="py-3 px-4 d-flex align-items-center">
                                                                            <div className="w-20px h-20px rounded" style={{ background: item.color }}></div>
                                                                            <p className="m-0 ml-3" align='center-left'>
                                                                                {item.label}
                                                                            </p>
                                                                        </div>
                                                                        {
                                                                            index < pieChartFeatureRevenue.length - 1 && (
                                                                                <hr className='m-0' />
                                                                            )
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div> */}

                                    {/* ban lanh dao */}
                                    {/* <div className='mt-4'>
                                        <AppCardDataInfo
                                            title={t('ExecutiveBoard')}
                                            content={
                                                <div className='overflow-auto max-h-500px'>
                                                    <MGDataTable
                                                        className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                                        headItems={[
                                                            { title: t('Name'), subTitle: t('Position'), col: 'col-8' },
                                                            { title: t('Quantity'), subTitle: '', col: 'col-4' },
                                                        ]}
                                                        dataItems={
                                                            boardOfManagements.map((item, index) => {
                                                                return {
                                                                    onPress: () => { },
                                                                    data: [
                                                                        { icon: '', title: '', subTitle: '', name: `${item.title} ${item.name}`, nameColor: '', value: Utils.getPositionNames(item.positions), valueColor: '#5C6077', col: 'col-8' },
                                                                        { icon: '', title: '', name: Utils.formatNumber(item.totalShares), nameColor: '', value: '', valueColor: '', col: 'col-4' },
                                                                    ]
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </div> */}

                                    {/* co cau co dong */}
                                    <div className="mt-4">
                                        <AppCardDataInfo
                                            title={t('ShareholderStructure')}
                                            rightComponent={(
                                                <div>
                                                    <a
                                                        href='#'
                                                        className='font-size-lg font-weight-bold d-flex align-items-center'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (onClickMoreMajorHolder) {
                                                                onClickMoreMajorHolder();
                                                            }
                                                        }}
                                                    >
                                                        {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                                    </a>
                                                </div>
                                            )}
                                            content={(
                                                <div>
                                                    {
                                                        ownerships.length > 0 ? (
                                                            <div>
                                                                <Chart
                                                                    className='pieChart-hide-label'
                                                                    options={options}
                                                                    series={series}
                                                                    type="pie"
                                                                    width='100%'
                                                                    height={320}
                                                                />
                                                                <div className="max-h-240px overflow-auto">
                                                                    {
                                                                        pieChartFeature.map((item, index) => {
                                                                            return (
                                                                                <div key={index} className='h-40px'>
                                                                                    <div className="py-3 px-4 d-flex align-items-center">
                                                                                        <div className="w-20px h-20px min-w-20px min-h-20px rounded" style={{ background: item.color }}></div>
                                                                                        <p className="m-0 ml-3 q-max-line-1" align='center-left'>
                                                                                            {item.label}
                                                                                        </p>
                                                                                    </div>
                                                                                    {
                                                                                        index < pieChartFeature.length - 1 && (
                                                                                            <hr className='m-0' />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                                <EmptyView
                                                                    description={t('NoDataToDisplay')}
                                                                    iconEmpty={AppIcons.icEmptyPack}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-8 align-self-start p-0 pl-4 pr-4 pt-4 pt-lg-0" id="stock_company_overview">
                                    {/* thong tin cong ty */}
                                    {/* <div className='' id="stock_company_overview">
                                        <AppCardDataInfo
                                            title={t('CompanyInfo')}
                                            content={(
                                                <div>
                                                    {
                                                        info.map((item, index) => (
                                                            <div key={index}>
                                                                <div className="py-4 px-5 d-flex align-items-center justify-content-between">
                                                                    <p className="m-0">{item.label}</p>
                                                                    <p className="m-0" align='right'>{item.value}</p>
                                                                </div>
                                                                {
                                                                    index < info.length - 1 && (
                                                                        <hr className='m-0' />
                                                                    )
                                                                }
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                            )}
                                        />
                                    </div> */}

                                    {/* thong tin khac */}
                                    <div className=''>
                                        <AppCardDataInfo
                                            title={t('Introduction')}
                                            content={(
                                                <div className='p-4'>
                                                    <ShowMoreText
                                                        lines={6}
                                                        more={t('ShowMore')}
                                                        less={t('ShowLess')}
                                                        expanded={false}
                                                        truncatedEndingComponent={"... "}
                                                        keepNewLines={true}
                                                        children={Utils.decodeHTML(text)}
                                                    >
                                                        {/* <p className="m-0">{text}</p> */}
                                                    </ShowMoreText>
                                                </div>
                                            )}
                                        />
                                    </div>

                                    {/* cong ty con, lien doanh, lien ket */}
                                    <div className='mt-4'>
                                        <AppCardDataInfo
                                            title={`${t('Subsidiaries')}, ${t('JointVenture')}, ${t('Associated')}`}
                                            rightComponent={(
                                                <div>
                                                    <a
                                                        href='#'
                                                        className='font-size-lg font-weight-bold d-flex align-items-center'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setModalAssociateShowing(true);
                                                        }}
                                                    >
                                                        {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                                    </a>
                                                </div>
                                            )}
                                            content={(
                                                <div className='overflow-auto max-h-500px'>
                                                    <MGDataTable
                                                        className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                                        headItems={[
                                                            { title: t('CompanyName'), subTitle: '', col: 'col-4' },
                                                            { title: `${t('AuthorizedCapital')}`, subTitle: '', col: 'col-4', textAlign: 'right' },
                                                            { title: `${t('Percentage')} (%)`, subTitle: '', col: 'col-4', textAlign: 'right' },
                                                        ]}
                                                        dataItems={
                                                            _.chain(associates).orderBy(['ratio'], ['desc']).slice(0, 7).value().map((item, index) => {
                                                                return {
                                                                    onPress: () => { },
                                                                    data: [
                                                                        { icon: '', title: item.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-4' },
                                                                        { icon: '', title: '', name: `${item.currencyUnit.trim() === 'USD' ? Utils.formatNumber(item.charteredCapital / 1000000) : Utils.formatNumber(item.charteredCapital / 1000000000)} ${item.currencyUnit.trim() === 'USD' ? 'tr USD' : 'tỷ VND'}`, nameColor: '', value: '', valueColor: '', col: 'col-4', textAlign: 'right' },
                                                                        { icon: '', title: '', name: `${Utils.formatNumber(item.ratio)}%`, nameColor: '#0BDF39', value: '', valueColor: '', col: 'col-4', textAlign: 'right' },
                                                                    ]
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="row mt-4" id="stock_news_event">
                                        {/* tin tuc */}
                                        <div className="col-12 col-lg-6 align-self-start p-0 pl-4 pr-4 pr-lg-0 pt-4 pt-lg-0">
                                            <AppCardDataInfo
                                                title={t('News')}
                                                rightComponent={(
                                                    <div>
                                                        <a
                                                            href='#'
                                                            className='font-size-lg font-weight-bold d-flex align-items-center'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setModalViewMoreNews(true);
                                                            }}
                                                        >
                                                            {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                                        </a>
                                                    </div>
                                                )}
                                                content={(
                                                    <div ref={refScrollArticle} className='pb-4' onScroll={handleScrollArticle}>
                                                        {
                                                            articles.length > 0 && _.slice(articles, 0, 3).map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-12 px-0 mt-5'>
                                                                        <CellArticleList
                                                                            item={item.article}
                                                                            showDivider={index < 2}
                                                                            imgMl={true}
                                                                            onPress={() => {
                                                                                setSelectedArticle(item.article);
                                                                                setModalArticleDetailVisible(true);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            articles.length === 0 && (
                                                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                                    <EmptyView
                                                                        description={t('NoDataToDisplay')}
                                                                        iconEmpty={AppIcons.icEmptyPack}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        {/* su kien */}
                                        <div className="col-12 col-lg-6 p-0 pr-4 pl-4 align-self-start pt-4 pt-lg-0">
                                            <AppCardDataInfo
                                                title={(
                                                    <div>
                                                        {t('Events')}
                                                        <p className='m-0 font-italic text-dark-75 font-size-sm font-weight-normal'>(*): Ngày được hiển thị là ngày GDKHQ</p>
                                                    </div>
                                                )}
                                                rightComponent={(
                                                    <div>
                                                        <a
                                                            href='#'
                                                            className='font-size-lg font-weight-bold d-flex align-items-center'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setModalViewMoreEvent(true)
                                                            }}
                                                        >
                                                            {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                                                        </a>
                                                    </div>
                                                )}
                                                content={(
                                                    <div ref={refScrollEvent} className='' onScroll={handleScrollEvent}>
                                                        {
                                                            _.slice(events, 0, 8).map((item, index) => {
                                                                return (
                                                                    <div key={index} className={`col-12 px-0 ${index % 2 !== 0 && 'bg-light'}`}>
                                                                        <div className='d-flex align-items-center px-5 py-4'>
                                                                            {
                                                                                (item?.eventTypeId === 2 || item?.eventTypeId === 5) ? (
                                                                                    <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.gdkhqDate ? Utils.formatDateTime(item?.gdkhqDate, 'DD/MM/YYYY') : ''}</p>
                                                                                ) : (
                                                                                    <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.ndkthDate ? Utils.formatDateTime(item?.ndkthDate, 'DD/MM/YYYY') : ''}</p>
                                                                                )
                                                                            }
                                                                            <p className='m-0 font-size-lg font-weight-base flex-grow-1'>{item?.titleEvent}</p>
                                                                        </div>
                                                                        {
                                                                            index < 7 && (
                                                                                <hr className='m-0' />
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            events.length === 0 && (
                                                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                                    <EmptyView
                                                                        description={t('NoDataToDisplay')}
                                                                        iconEmpty={AppIcons.icEmptyPack}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <Warrant
                                                scrollXButton={false}
                                                warrants={warrants}
                                                showMore
                                                onClickMore={() => {
                                                    if (onClickMoreWarrant) {
                                                        onClickMoreWarrant();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* lich su tang von */}
                                    {/* <div className="bg-white border mt-4 p-4">
                                        <div className='d-flex align-items-center justify-content-between px-5'>
                                            <p className="m-0" style={{
                                                fontSize: '1.08rem',
                                                fontWeight: '600',
                                                lineHeight: '1.85rem'
                                            }}>{t('IncreasingCharteredCapitals')}</p>

                                            <div className="py-3 px-4 d-flex align-items-center">
                                                <div className="w-20px h-20px rounded" style={{ background: '#008FFB' }}></div>
                                                <p className="m-0 ml-3" align='center-left'>
                                                    {`${t('AuthorizedCapital')} (Tỷ VNĐ)`}
                                                </p>
                                            </div>
                                        </div>

                                        <Chart
                                            className=''
                                            options={optionsCharteredCapitals}
                                            series={seriesCharteredCapitals}
                                            type="line"
                                            width='100%'
                                            height={350}
                                        />
                                    </div> */}

                                    {/* ket qua kinh doanh */}
                                    <div className='mt-4'>
                                        <AppTabs
                                            tabs={tabsFinanceReport.reverse()}
                                            selectedTab={selectedTabFinanceReport}
                                            handleClick={handleSelectedTabFinanceReport}
                                        />
                                        {
                                            selectedTabFinanceReport === t('FinanceReportByQuarter') ? (
                                                <div className='max-h-400px overflow-auto border-bottom border-right border-left'>
                                                    <MGDataTable
                                                        className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                                        headItems={[
                                                            { title: t('Quarter'), subTitle: '', col: 'col-1' },
                                                            { title: `${t('Revenue')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Profit')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                        ]}
                                                        dataItems={
                                                            _.chain(arrQuarter).orderBy(arrQuarter, 'desc').splice(0, 5).value().map((item, index) => {
                                                                const currentRevenue = _.find(arrRevenues, { 'quarterYear': item })?.value ?? 0;
                                                                let prevRevenue = 0;
                                                                const prevRevenueIndex = index + 1;
                                                                if (prevRevenueIndex < arrQuarter.length) {
                                                                    prevRevenue = _.find(arrRevenues, { 'quarterYear': arrQuarter[prevRevenueIndex] })?.value ?? 0;
                                                                }
                                                                let growthRevenue = 0;
                                                                if (prevRevenue > 0) {
                                                                    growthRevenue = (currentRevenue - prevRevenue) * 100 / prevRevenue;
                                                                }

                                                                const currentProfit = _.find(arrProfits, { 'quarterYear': item })?.value ?? 0;
                                                                const currentProfitIndex = _.findIndex(arrProfits, { 'quarterYear': item });
                                                                let prevProfit = 0;
                                                                const prevProfitIndex = index + 1;
                                                                if (prevProfitIndex < arrQuarter.length) {
                                                                    prevProfit = _.find(arrProfits, { 'quarterYear': arrQuarter[prevProfitIndex] })?.value ?? 0;
                                                                }
                                                                let growthProfit = 0;
                                                                if (prevProfit > 0) {
                                                                    growthProfit = (currentProfit - prevProfit) * 100 / prevProfit;
                                                                }

                                                                return {
                                                                    onPress: () => { },
                                                                    data: [
                                                                        { icon: '', title: item, subTitle: '', name: '', value: '', valueColor: '', col: 'col-1' },
                                                                        { icon: '', title: '', subTitle: '', name: Utils.formatNumber((currentRevenue / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${growthRevenue.toFixed(2)}%`, nameColor: Utils.getValueColor(growthRevenue), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${Utils.formatNumber((currentProfit / 1000000000).toFixed(2))}`, nameColor: Utils.getValueColor(currentProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${growthProfit.toFixed(2)}%`, nameColor: Utils.getValueColor(growthProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                    ]
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div className='max-h-400px overflow-auto border-bottom border-right border-left'>
                                                    <MGDataTable
                                                        className='bg-white border-top-0 border-bottom-0 border-left-0 border-right-0'
                                                        headItems={[
                                                            { title: t('Year'), subTitle: '', col: 'col-1' },
                                                            { title: `${t('Revenue')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Profit')} (tỷ)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                            { title: `${t('Growth')} (%)`, subTitle: '', col: 'col-2', textAlign: 'right' },
                                                        ]}
                                                        dataItems={
                                                            _.chain(arrYears).orderBy(arrYears, 'desc').splice(0, 5).value().map((item, index) => {
                                                                const currentRevenue = _.find(arrRevenues, { 'quarterYear': `N/${item}` })?.value ?? 0;
                                                                let prevRevenue = 0;
                                                                const prevRevenueIndex = index + 1;
                                                                if (prevRevenueIndex < arrYears.length) {
                                                                    prevRevenue = _.find(arrRevenues, { 'quarterYear': `N/${arrYears[prevRevenueIndex]}` })?.value ?? 0;
                                                                }
                                                                let growthRevenue = 0;
                                                                if (prevRevenue > 0) {
                                                                    growthRevenue = (currentRevenue - prevRevenue) * 100 / prevRevenue;
                                                                }

                                                                const currentProfit = _.find(arrProfits, { 'quarterYear': `N/${item}` })?.value ?? 0;
                                                                let prevProfit = 0;
                                                                const prevProfitIndex = index + 1;
                                                                if (prevProfitIndex < arrYears.length) {
                                                                    prevProfit = _.find(arrProfits, { 'quarterYear': `N/${arrYears[prevProfitIndex]}` })?.value ?? 0;
                                                                }
                                                                let growthProfit = 0;
                                                                if (prevProfit > 0) {
                                                                    growthProfit = (currentProfit - prevProfit) * 100 / prevProfit;
                                                                }

                                                                return {
                                                                    onPress: () => { },
                                                                    data: [
                                                                        { icon: '', title: `${item}`, subTitle: '', name: '', value: '', valueColor: '', col: 'col-1' },
                                                                        { icon: '', title: '', subTitle: '', name: Utils.formatNumber((currentRevenue / 1000000000).toFixed(2)), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${growthRevenue.toFixed(2)}%`, nameColor: Utils.getValueColor(growthRevenue), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${Utils.formatNumber((currentProfit / 1000000000).toFixed(2))}`, nameColor: Utils.getValueColor(currentProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                        { icon: '', title: '', subTitle: '', name: `${growthProfit.toFixed(2)}%`, nameColor: Utils.getValueColor(growthProfit), value: '', valueColor: '', col: 'col-2', textAlign: 'right' },
                                                                    ]
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>

                                    {/* danh sach co dong */}
                                    {/* <div className="mt-4" id="stock_major_holder">
                                        <MGDataTable
                                            className='bg-white'
                                            headItems={[
                                                { title: t('Holder'), subTitle: t('Name'), col: 'col-6' },
                                                { title: t('Quantity'), subTitle: '', col: 'col-3' },
                                                { title: t('Ratio'), subTitle: '%', col: 'col-3' },
                                            ]}
                                            dataItems={
                                                majorHolders.map((item, index) => {
                                                    return {
                                                        onPress: () => { },
                                                        data: [
                                                            { icon: '', title: item.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-6' },
                                                            { icon: '', title: '', name: Utils.formatNumber(item.quantity), nameColor: '', value: '', valueColor: '', col: 'col-3' },
                                                            { icon: '', title: '', name: `${item.ratio}%`, nameColor: '', value: '', valueColor: '', col: 'col-3' },
                                                        ]
                                                    }
                                                })
                                            }
                                        />
                                    </div> */}
                                </div>
                            </div>

                            {/* row 2 */}

                            {/* row 3 */}
                            {/* <div className="row mt-4" id='stock_compare'> */}
                            {/* so sanh */}
                            {/* <div className="col-12">
                                    <Compare company={company} />
                                </div> */}
                            {/* </div> */}

                            {/* row 4 */}
                            {/* <div className="row mt-4" id="stock_license"> */}
                            {/* chung quyen */}
                            {/* <div className="col-12">
                                <Warrant warrants={warrants} />
                            </div> */}
                            {/* </div> */}

                            {/* row 5 */}
                            {/* <div className="row mt-4 px-4" id='stock_financial_analytics'>
                                {/* phan tich tai chinh */}
                            {/* <FinancialAnalysis
                                reports={reports}
                                reportNorms={reportNorms}
                                financeIndexes={financeIndexes}
                                financeIndexNorms={financeIndexNorms}
                                reportYears={reportYears}
                                reportTermCodes={reportTermCodes}
                                onFilter={(arrYears, arrTerms) => {
                                    if (onFilter) {
                                        onFilter(arrYears, arrTerms);
                                    }
                                }}
                            /> */}
                            {/* </div> */}
                        </div>
                    )
                }
            </div>

            {/* modal chart */}
            <Modal show={showStockChartModal} onHide={handleClose} size="xl" centered dialogClassName="modal-90w">
                <Modal.Header className={"custom-header-modal d-flex flex-row justify-content-between"} closeButton>

                    <Modal.Title>{t('Chart')}</Modal.Title>

                    {/* dropdown */}
                    <Dropdown alignRight style={{ marginLeft: 'auto' }}>
                        <Dropdown.Toggle variant="" className="border font-weight-bolder">
                            {currentRange}
                            <i className="fas fa-caret-down ml-2 text-dark"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {intervalList.map((item, index) => {
                                return (
                                    <Dropdown.Item className="w-100 font-weight-bolder d-flex flex-row justify-content-between" key={index} onClick={() => handleChangeDateRange(item?.Id)}>
                                        {item?.title}
                                        {currentRange === item.title && <i className="fas fa-check text-success"></i>}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className={"custom-right-component-modal d-flex flex-row"}>
                        <div
                            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                            onClick={handleClose}
                        >
                            <i className="far fa-times"></i>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {(
                        isLoadingStockChartData ?
                            <div className="custom-div-chart custom-height">
                                <div className={"custom-loading"}>
                                    <LoadMore />
                                </div>
                            </div> :
                            (stockCandleData?.length == 0 && stockVolumeData?.length == 0) ?
                                (
                                    <div
                                        className='h-600px w-100 d-flex align-items-center justify-content-center py-10'>
                                        <EmptyView
                                            description={t('NoDataToDisplay')}
                                            iconEmpty={AppIcons.icEmptyPack}
                                        />
                                    </div>
                                ) :
                                <div className="custom-height">
                                    <CandleChart heightStyle={"h-600px"} candleData={stockCandleData}
                                        volumeData={stockVolumeData} />
                                </div>

                    )}
                </Modal.Body>
                {/* <Modal.Footer className={"custom-footer-modal"}>
                    <div className={"bottom-select-range d-flex flex-row custom-modal-footer"}>
                        {
                            intervalList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <button
                                            className={getClassNameButton(item?.Id)}
                                            onClick={() => handleChangeDateRange(item?.Id)}
                                        >
                                            {item?.title}
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Footer> */}
            </Modal>

            {/* Modal article detail */}
            <ModalArticleDetail
                show={modalArticleDetailVisible}
                article={selectedArticle}
                onClose={() => {
                    setModalArticleDetailVisible(false);
                }}
            />

            <ModalTradingView
                show={modalTradingViewShowing}
                symbol={tradingViewSymbol}
                onClose={() => {
                    setModalTradingViewShowing(false);
                }}
            />

            <ModalAssociates
                show={modalAssociateShowing}
                onClose={() => {
                    setModalAssociateShowing(false);
                }}
                items={associates}
            />

            {/* modal view more news */}
            <Modal size="lg" show={modalViewMoreNews} onHide={() => setModalViewMoreNews(false)} centered>
                <Modal.Header>
                    <Modal.Title>
                        {t('News')}
                    </Modal.Title>
                    <div className={"custom-right-component-modal d-flex flex-row"}>
                        <div
                            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                            onClick={() => setModalViewMoreNews(false)}
                        >
                            <i className="far fa-times"></i>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div ref={refScrollArticle} className='pb-4 overflow-auto max-h-500px' onScroll={handleScrollArticle}>
                        {
                            articles.length > 0 && articles.map((item, index) => {
                                return (
                                    <div key={index} className='col-12 px-0 mt-5'>
                                        <CellArticleList
                                            item={item.article}
                                            showDivider={index < 2}
                                            imgMl={true}
                                            onPress={() => {
                                                setSelectedArticle(item.article);
                                                setModalArticleDetailVisible(true);
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }

                        {
                            articles.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>

            {/* modal view more event */}
            <Modal size="lg" show={modalViewMoreEvent} onHide={() => setModalViewMoreEvent(false)} centered>
                <Modal.Header>
                    <Modal.Title>
                        {t('Events')}
                    </Modal.Title>
                    <div className={"custom-right-component-modal d-flex flex-row"}>
                        <div
                            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                            onClick={() => setModalViewMoreEvent(false)}
                        >
                            <i className="far fa-times"></i>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div ref={refScrollEvent} className='overflow-auto max-h-500px' onScroll={handleScrollEvent}>
                        {
                            events.map((item, index) => {
                                return (
                                    <div key={index} className={`col-12 px-0 ${index % 2 !== 0 && 'bg-light'}`}>
                                        <div key={index} className={`col-12 px-0 ${index % 2 !== 0 && 'bg-light'}`}>
                                            <div className='d-flex align-items-center px-5 py-4'>
                                                {
                                                    (item?.eventTypeId === 2 || item?.eventTypeId === 5) ? (
                                                        <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.gdkhqDate ? Utils.formatDateTime(item?.gdkhqDate, 'DD/MM/YYYY') : ''}</p>
                                                    ) : (
                                                        <p className='m-0 font-size-normal font-weight-base font-weight-boldest mr-2 text-primary'>{item?.ndkthDate ? Utils.formatDateTime(item?.ndkthDate, 'DD/MM/YYYY') : ''}</p>
                                                    )
                                                }
                                                <p className='m-0 font-size-lg font-weight-base flex-grow-1'>{item?.titleEvent}</p>
                                            </div>
                                            {
                                                index < 7 && (
                                                    <hr className='m-0' />
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            events.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Summary;
