import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'general/components/AppLayout';
import './style.scss'
import GoogleMapReact from 'google-map-react';
import { AppIcons } from 'general/constants/AppResource';
import AppConfigs from 'general/constants/AppConfigs';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Map() {

    const center = {
        lat: 10.7886241155125,
        lng: 106.71859537082541
    }
    const zoom = 15

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDTxOPrt41pX4wNps53QIaQm0iYKgwntsQ" }}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <AnyReactComponent
                    lat={10.7886241155125}
                    lng={106.71859537082541}
                    text="My Marker"
                />
            </GoogleMapReact>
        </div>
    )
}


ContactUsScreen.propTypes = {

};

const sTag = '[ContactUsScreen]';



function ContactUsScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        }
    }, []);

    // MARK: --- Functions ---

    return (
        <AppLayout>
            <div className='ContactUsScreen min-h-600px bg-white'>
                <div className='ContactUsScreen_Header py-lg-30 py-md-12'>{t('ContactUs').toUpperCase()}</div>
                <div className='container-xxl'>
                    <div className='d-flex justify-content-center pt-sm-20 pt-lg-0'>
                        <p className='ContactUsScreen_Description py-lg-15 py-10 py-sm-0 col-md-7 m-0'>{t('ContactUsDescriptionText')}</p>
                    </div>
                    <div className='container-xxl pb-10 px-md-0'>
                        <div className='row'>
                            <div className='py-md-10 h-sm-50vh col-lg-6 col-12 order--1 order-lg-2 pr-0 py-sm-10 pt-lg-0 pb-sm-10 py-md-0 pb-10' style={{ width: "50%" }}>
                                <div style={{
                                    fontWeight: '600',
                                    fontSize: '2.77rem',
                                    color: '#7621B1',
                                }}>{AppConfigs.companyName}</div>
                                <div className='container-xxl px-0'>
                                    <div className='row py-3'>
                                        <img className='pl-4' src={AppIcons.icLocation}
                                            draggable={false} alt="" />
                                        <p className='ContactUsScreen_Infor col-11 pr-0'>{AppConfigs.companyAddress}</p>
                                    </div>
                                </div>
                                <div className='container-xxl px-0'>
                                    <div className='row py-3'>
                                        <img className='pl-4' src={AppIcons.icMail}
                                            draggable={false} alt="" />
                                        <a href={`mailto: ${AppConfigs.emailSupport}`} className='ContactUsScreen_Infor col-11 pr-0'>{AppConfigs.emailSupport}</a>
                                    </div>
                                </div>
                                <div className='container-xxl px-0'>
                                    <div className='row py-3'>
                                        <img className='pl-4' src={AppIcons.icPhone}
                                            draggable={false} alt="" />
                                        <a href={`tel: ${AppConfigs.hotline}`} className='ContactUsScreen_Infor col-11 pr-0'>{AppConfigs.hotline}</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-12 order-sm-2 order-lg-1' style={{ width: "100%", height: "40vh" }} /*href="https://www.google.com/maps/place/Sunwah+Pearl+Golden+House/@10.7884028,106.718531,17z/data=!3m1!4b1!4m5!3m4!1s0x31752988e4594bd9:0x6054cb17b1402622!8m2!3d10.7884028!4d106.718531?hl=vi-VN" target="_blank"*/>
                                <Map />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default ContactUsScreen;