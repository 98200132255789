import axiosClient from "./axiosClient";

const notificationApi = {
    // update subscription token
    updateSubscriptionToken: (subscriptionToken) => {
        const url = '/notification/registerToken';
        return axiosClient.post(url, {
            subscriptionToken: subscriptionToken,
        });
    }
};

export default notificationApi;