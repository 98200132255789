import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Section_1 from './components/Section_1';
import Section_4 from './components/Section_4';
import './index.scss';

CompanyOverView.propTypes = {
    company: PropTypes.object,
    associates: PropTypes.array,
    boardOfManagements: PropTypes.array,
    revenues: PropTypes.array,
    laborStructures: PropTypes.array,
    processOfIncreasingCharteredCapitals: PropTypes.array,
    reportData: PropTypes.object,
    financeReportByTime: PropTypes.object,
};

CompanyOverView.defaultProps = {
    company: {},
    associates: [],
    boardOfManagements: [],
    revenues: [],
    laborStructures: [],
    processOfIncreasingCharteredCapitals: [],
    reportData: {},
    financeReportByTime: {},
};

function CompanyOverView(props) {
    // MARK: --- Params ---
    const { company, associates, boardOfManagements, revenues, laborStructures, processOfIncreasingCharteredCapitals, reportData, financeReportByTime } = props;
    const { t } = useTranslation();

    return (
        <div className="CompanyOverView p-4">
            {
                company ? (
                    <>
                        <Section_1
                            company={company}
                            associates={associates}
                            boardOfManagements={boardOfManagements}
                            revenues={revenues}
                            laborStructures={laborStructures}
                            reportData={reportData}
                            financeReportByTime={financeReportByTime}
                        />
                        <Section_4 processOfIncreasingCharteredCapitals={processOfIncreasingCharteredCapitals} />
                    </>
                ) : (
                    <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white border'>
                        <EmptyView
                            description={t('NoDataToDisplay')}
                            iconEmpty={AppIcons.icEmptyPack}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default CompanyOverView;
