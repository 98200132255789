import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import PropTypes from 'prop-types';
import AppData from "../../../../../general/constants/AppData";
import Utils from "../../../../../general/utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { editConditional, popConditional } from "../../../IdeaListSlice";
import { Dropdown, FormControl } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import filterApi from "../../../../../api/filterApi";
import industryApi from "../../../../../api/industryApi";
import BaseSearchBar from "../../../../../general/components/Forms/FormControls/BaseSearchBar";
import "bootstrap/dist/css/bootstrap.min.css";
import ToastHelper from "../../../../../general/helpers/ToastHelper";

FilterLine.propTypes = {
    onDelete: PropTypes.func,
};

FilterLine.defaultProps = {
    onDelete: null,
}

function FilterLine(props) {
    let { filterConditionalId, values, listTitle, max, min, filterLineId, filterId } = props?.conditional
    const { t } = useTranslation();
    const [inputValues, setInputValues] = useState(values)
    const [selectIndustry, setSelectIndustry] = useState(listTitle ?? [])
    const [countStockSatisfied, setCountStockSatisfied] = useState(null)
    const [inputSearchIndustry, setInputSearchIndustry] = useState("")
    const [industrySearchResult, setIndustrySearchResult] = useState([])
    const [showRecommendIndustry, setShowRecommendIndustry] = useState(false)
    const [industryCurrentPage, setIndustryCurrentPage] = useState(0)
    const [totalIndustryResult, setTotalIndustryResult] = useState(0)
    const [minValue, setMinValue] = useState(min)
    const [maxValue, setMaxValue] = useState(max)
    const dispatch = useDispatch()
    const [minValueError, setMinValueError] = useState(false);
    const [maxValueError, setMaxValueError] = useState(false);

    // console.log({ values });

    const ref = useRef()
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setInputSearchIndustry(null)
            setShowRecommendIndustry(false);
        } else {
            setShowRecommendIndustry(true);
        }
    };

    useEffect(() => {
        // console.log(props.conditional);
        // console.log('11111');
        setMinValue(props.conditional.min);
        setMaxValue(props.conditional.max);
        setInputValues(props.conditional.values);

    }, [props?.conditional]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        // console.log('222222');
        if ([1, 2].includes(filterConditionalId) && inputValues || ((!isNaN(maxValue) && (!isNaN(minValue) && (maxValue >= minValue))))) {
            countStock().then(total => {
                setCountStockSatisfied(total)
            })
        }
    }, [inputValues, maxValue, minValue])

    useEffect(() => {
        if (filterConditionalId == 2 && inputSearchIndustry?.length > 0) {
            setIndustrySearchResult([])
            setTotalIndustryResult(0)
            setIndustryCurrentPage(0)
            const timeout = setTimeout(async () => {
                await handleSearchIndustry(true)
            }, 500)
            return () => clearTimeout(timeout);
        }
    }, [inputSearchIndustry])

    const handleScroll = async (e) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 10;
        if (bottom && totalIndustryResult > industrySearchResult?.length) {
            await handleSearchIndustry(false)
        }
    }

    const countStock = async () => {
        try {
            let countResult = await filterApi.countNumberSatisfied({
                "filterConditionalId": filterConditionalId,
                "values": inputValues,
                "max": maxValue,
                "min": minValue
            })
            let { result, total } = countResult
            if (result == "success") {
                return total
            }
        } catch (e) {
            throw e
        }
    }

    const handlePopConditional = () => {
        dispatch(popConditional({
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId
        }))
    }

    const handleAddIndustry = (industry) => {
        let newValues = selectIndustry
        let newIds = inputValues
        let index = newValues.findIndex(item => item?.industryId == industry?.industryId)
        if (index > -1) {
            ToastHelper.showError('Đã chọn ngành "' + industry?.name + '" trước đó')
        } else {
            if (newValues == null || newValues?.length == 0) {
                newValues = [industry]
                newIds = [industry?.industryId]
            } else {
                newValues = [...newValues, industry]
                newIds = [...inputValues, industry?.industryId]
            }
        }
        setSelectIndustry(newValues)
        setInputValues(newIds)
        let params = {
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId,
            values: newIds,
            filterId: filterId,
            max: maxValue,
            min: minValue,
            listTitle: newValues
        }
        dispatch(editConditional(params))
    }

    const handlePopIndustry = (industry) => {
        let newValues = selectIndustry
        let newIds = inputValues
        newValues = newValues.filter(item => item?.industryId != industry?.industryId)
        newIds = newIds.filter(item => item != industry?.industryId)
        setSelectIndustry(newValues)
        setInputValues(newIds)
        let params = {
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId,
            values: newIds,
            filterId: filterId,
            max: maxValue,
            min: minValue,
            listTitle: newValues
        }
        dispatch(editConditional(params))
    }

    const handleSelectExchangeCode = (exchangeCode) => {
        let newValues = inputValues ?? []
        if (newValues.length == 0) {
            newValues = [exchangeCode]
        } else {
            let index = newValues.indexOf(exchangeCode)
            if (index >= 0) {
                newValues = newValues.filter(item => item != exchangeCode)
            } else {
                newValues = [
                    ...newValues, exchangeCode
                ]
            }
        }
        let params = {
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId,
            values: newValues,
            filterId: filterId,
            max: maxValue,
            min: minValue,
            listTitle: selectIndustry
        }
        dispatch(editConditional(params))
        setInputValues(newValues)
    }

    const handleSearchIndustry = async (newResult) => {
        try {
            let searchIndustryRes = await industryApi.searchIndustryForFilter({
                "page": newResult ? 0 : industryCurrentPage,
                "limit": 10,
                "searchKey": inputSearchIndustry
            })
            let { result, industries, total, page } = searchIndustryRes
            if (result == "success" && industries?.length > 0) {
                setShowRecommendIndustry(true)
                setIndustryCurrentPage(page + 1)
                let newValues = (page == 0) ? industries : [
                    ...industrySearchResult,
                    ...industries
                ]
                setIndustrySearchResult(newValues)
            }
            if (page == 0) {
                setTotalIndustryResult(total)
                setIndustryCurrentPage(1)
            }
        } catch (e) {
            throw e
        }
    }

    const handleChangeMinValue = (e) => {
        e.preventDefault()
        let newMinValue = parseFloat(e.target.value)
        setMinValue(newMinValue)

        if (newMinValue > maxValue) {
            setMinValueError(true);
            return;
        } else {
            setMinValueError(false);
            setMaxValueError(false);
        }

        let params = {
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId,
            values: inputValues,
            filterId: filterId,
            max: maxValue,
            min: newMinValue,
            listTitle: selectIndustry
        }
        dispatch(editConditional(params))
    }

    const handleChangeMaxValue = (e) => {
        e.preventDefault()
        let newMaxValue = parseFloat(e.target.value)
        setMaxValue(newMaxValue)

        if (newMaxValue < minValue) {
            setMaxValueError(true);
            return;
        } else {
            setMinValueError(false);
            setMaxValueError(false);
        }

        let params = {
            filterConditionalId: filterConditionalId,
            filterLineId: filterLineId,
            values: inputValues,
            filterId: filterId,
            max: newMaxValue,
            min: minValue,
            listTitle: selectIndustry
        }
        dispatch(editConditional(params))
    }

    const renderFilterLine = (filterConditionalId) => {

        // select exchange code
        if (filterConditionalId == 1) {
            return (
                <div className='d-flex  py-4 align-items-center border-top'>
                    <div className='col-xs-12 col-3 d-flex flex-row'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{t(Utils.getFilterConditionalTitle(filterConditionalId))}</span>
                    </div>
                    <div className='col-xs-10 col-7 d-flex flex-row'>
                        <div className='custom-right-component'>
                            {
                                AppData.allExchangeCode.map((exchangeCode, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className={`border-0 py-2 px-3 mr-2 rounded-sm font-weight-bolder ${(inputValues?.includes(exchangeCode)) ? "BuildYourScreen_Button_Clicked" : "BuildYourScreen_Button_UnClicked"}`}
                                            onClick={() => {
                                                handleSelectExchangeCode(exchangeCode)
                                            }}>{exchangeCode}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='col-2 d-flex flex-row justify-content-end'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{countStockSatisfied > 0 ? (Utils.formatNumber(countStockSatisfied) + " " + t("stocks")) : null}</span>
                        <button onClick={() => {
                            handlePopConditional();
                        }}
                            className='mx-sm-5 border-0 rounded-sm d-flex align-items-center justify-content-center btn-secondary'>
                            <i className="fal fa-times text-dark-65"></i>
                        </button>
                    </div>
                </div>
            )
            // select industry
        } else if (filterConditionalId == 2) {
            return (
                <div>
                    <div className='d-flex border-top py-4 align-items-center'>
                        <div className='col-xs-12 col-3 d-flex flex-row'>
                            <span
                                className='BuildYourScreen_FilterCondition_Title'>{t(Utils.getFilterConditionalTitle(filterConditionalId))}</span>
                        </div>
                        <div className='col-xs-12 col-7' ref={ref}>
                            {(industrySearchResult?.length > 0) && showRecommendIndustry &&
                                <Dropdown onScroll={handleScroll} className={"overflow-auto custom-industry-dropdown"}
                                    show={industrySearchResult?.length > 0}>

                                    {
                                        industrySearchResult?.map((item, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={index}
                                                    onClick={() => handleAddIndustry(item)}>
                                                    <span className={"flex-shrink-text"}>{item?.name}</span>
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown>}
                            <BaseSearchBar
                                className='limit-width custom-right-component'
                                name='searchbar'
                                onSubmit={(text) => {
                                    setInputSearchIndustry(text);
                                }}
                                placeholder={`${t('TypeToSearch')}...`}
                            />

                        </div>
                        <div className='col-xs-2 col-2 d-flex flex-row justify-content-end'>
                            <span
                                className='BuildYourScreen_FilterCondition_Title'>{countStockSatisfied > 0 ? (countStockSatisfied + " " + t("stocks")) : null}</span>
                            <button onClick={() => {
                                handlePopConditional();
                            }}
                                className='mx-sm-5 border-0 rounded-sm d-flex align-items-center justify-content-center btn-secondary'>
                                <i className="fal fa-times text-dark-65"></i>
                            </button>
                        </div>
                    </div>
                    {
                        (listTitle?.length > 0) &&
                        <div className={"d-flex pt-2 custom-select-industry-options align-items-center"}>
                            <div className={"col-xs-0 col-3 d-flex flex-row"}></div>
                            <div className={'col-xs-12 col-7'} style={{
                                marginTop: "-10px",
                                marginBottom: "10px",
                            }}>
                                {
                                    listTitle?.map((item, index) => {
                                        return (
                                            <p className={"industry-area"}>
                                                <span className={"industry-name-custom"}>{item?.name}</span>
                                                <i className={"far fa-times-circle pop-industry-icon hover-opacity-50"}
                                                    onClick={() => handlePopIndustry(item)} />
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div className='d-flex border-top py-3 align-items-center'>
                    <div className='col-xs-12 col-3 d-flex flex-row align-items-center'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{t(Utils.getFilterConditionalTitle(filterConditionalId)) + " (" + Utils.getUnitByFilterConditionalId(filterConditionalId) + ")"}</span>
                    </div>
                    <div className='col-xs-8 col-7 d-flex' style={{
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}>
                        <FormControl
                            className={`custom-text-input ${minValueError && 'is-invalid'}`}
                            type='number'
                            name='inputValue'
                            placeholder={`${t("inputMin")} ...`}
                            value={minValue}
                            onChange={(e) => handleChangeMinValue(e)}
                        />
                        <p className={"pl-1 pr-1"}>{"−"}</p>
                        <FormControl
                            className={`custom-text-input  ${maxValueError && 'is-invalid'}`}
                            type='number'
                            name='inputValue'
                            placeholder={`${t("inputMax")} ...`}
                            value={maxValue}
                            onChange={(e) => handleChangeMaxValue(e)}
                        />
                    </div>
                    <div className='col-xs-2 col-2 d-flex flex-row justify-content-end'>
                        <span
                            className='BuildYourScreen_FilterCondition_Title'>{countStockSatisfied > 0 ? (countStockSatisfied + " " + t("stocks")) : null}</span>
                        <button
                            onClick={() => {
                                handlePopConditional();
                            }}
                            className='mx-sm-5 border-0 rounded-sm d-flex align-items-center justify-content-center btn-secondary'>
                            <i className="fal fa-times text-dark-65"></i>
                        </button>
                    </div>
                </div>

            )
        }
    }
    return (
        renderFilterLine(filterConditionalId)
    )

}
FilterLine.propTypes = {
    onDelete: PropTypes.func,
};

FilterLine.defaultProps = {
    onDelete: null,
}

export default FilterLine
