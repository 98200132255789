import { setSelectedStockCode } from 'features/MyList/myListSlice';
import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

Category.propTypes = {
    data: PropTypes.array,
    onEditMyListStockCode: PropTypes.func,
    onPressAddTransaction: PropTypes.func,
};

Category.defaultProps = {
    data: [],
    onEditMyListStockCode: null,
    onPressAddTransaction: null,
};

function Category(props) {
    // MARK: --- Params ---
    const dispatch = useDispatch();
    const { isGetting, allMyLists, selectedMyList } = useSelector(state => state.myList);
    const { t } = useTranslation();
    const { data, onEditMyListStockCode, onPressAddTransaction } = props;
    const history = useHistory();

    return (
        <>
            <div className='border-left border-bottom border-right pt-3 bg-white'>
                <MGDataTable
                    className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                    loading={isGetting}
                    headItems={[
                        { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                        { title: t('Command'), col: 'col-1', textAlign: 'right' },
                        { title: t('TotalVolume'), subTitle: t('Stock'), col: 'col-1', textAlign: 'right' },
                        { title: t('AveragePurchasePrice'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('MarketPrice'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('PurchaseValue'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('MarketValue'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('Profit_Loss'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('Profit_LossInDay'), subTitle: 'VND', col: 'col-1', textAlign: 'right' },
                        { title: t('Action'), subTitle: '', col: 'col-1', textAlign: 'right' },
                    ]}
                    dataItems={
                        data.map((item, index) => {
                            return {
                                onPress: () => {
                                    history.push(`/stock/${item?.stockCode}`);
                                },
                                data: [
                                    { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item.companyName, name: '', value: '', valueColor: '', col: 'col-3' },
                                    { icon: '', title: '', subTitle: '', name: t(item.command), value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.sumQuantity), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(_.round(item.priceAvg, 2)), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.marketPrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.value), nameColor: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.marketValue), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.diff), nameColor: item.diff > 0 ? '#0BDF39' : 'red', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    { icon: '', title: '', name: Utils.formatNumber(item.dayDiff), nameColor: item.dayDiff > 0 ? '#0BDF39' : 'red', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    {
                                        icon: '', title: '', name: '', nameColor: '', value: '', valueColor: '', col: 'col-1', componentParentAdditionClass: 'border-left-0', component: (
                                            <div className='d-flex align-items-center w-100'>
                                                <a className="btn btn-icon btn-sm btn-hover-dark mr-2" style={{ backgroundColor: '#4886FF' }} onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (onPressAddTransaction) {
                                                        onPressAddTransaction(item.stockCode);
                                                    }
                                                }}>
                                                    <i className="fad fa-plus p-0 icon-1x text-white" />
                                                </a>
                                                <a className="btn btn-icon btn-sm btn-hover-dark" style={{ backgroundColor: '#4886FF' }} onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dispatch(setSelectedStockCode(item.stockCode));
                                                    if (onEditMyListStockCode) {
                                                        onEditMyListStockCode(item.stockCode);
                                                    }
                                                }}>
                                                    <i className="fad fa-edit p-0 icon-1x text-white" />
                                                </a>
                                            </div>
                                        )
                                    },
                                ]
                            }
                        })
                    }
                />
            </div>
        </>
    );
}

export default Category;
