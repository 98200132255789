import notificationApi from 'api/notificationApi';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as serviceWorker from '../serviceWorkerRegistration';

// kiem tra trinh duyet ho tro Push Notification
const pushNotificationSupported = serviceWorker.isPushNotificationSupported();

export default function usePushNotifications() {
    //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
    //We initialize the userConsent with that value
    const [userConsent, setSuserConsent] = useState(pushNotificationSupported ? Notification.permission : null);
    //to manage the use push notification subscription
    const [userSubscription, setUserSubscription] = useState(null);
    //to manage the push server subscription
    const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState();
    //to manage errors
    const [error, setError] = useState(null);
    //to manage async actions
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        // neu Push Notification duoc ho tro => dang ky service worker
        if (pushNotificationSupported) {
            setLoading(true);
            setError(false);
            serviceWorker.register();
        }
    }, []);

    //Retrieve if there is any push notification subscription for the registered service worker
    useEffect(() => {
        setLoading(true);
        setError(false);
        const getExixtingSubscription = async () => {
            const existingSubscription = await serviceWorker.getUserSubscription();
            setUserSubscription(existingSubscription);
            setLoading(false);
            // console.log(JSON.stringify(existingSubscription));
        };
        getExixtingSubscription();
    }, []);

    // yeu cau quyen truy cap Push Notification
    const onClickAskUserPermission = () => {
        setLoading(true);
        setError(false);
        serviceWorker.askUserPermission().then((consent) => {
            setSuserConsent(consent);
            if (consent !== 'granted') {
                setError({
                    name: t('ConsentDenied'),
                    message: t('ConsentDeniedMessage'),
                    code: 0
                });
            }
            setLoading(false);
        });
    };

    // tao Push Notification Subscription
    const onClickSusbribeToPushNotification = () => {
        setLoading(true);
        setError(false);
        serviceWorker
            .createNotificationSubscription()
            .then(function (subscription) {
                setUserSubscription(subscription);
                setLoading(false);
                // console.log(JSON.stringify(subscription));
            })
            .catch((err) => {
                console.error(
                    "Couldn't create the notification subscription",
                    err,
                    'name:',
                    err.name,
                    'message:',
                    err.message,
                    'code:',
                    err.code
                );
                setError(err);
                setLoading(false);
            });
    };

    // gui User Subscription len server de luu tru
    const onClickSendSubscriptionToPushServer = () => {
        setLoading(true);
        setError(false);
        notificationApi.updateSubscriptionToken(userSubscription);
    };

    /**
     * define a click handler that requests the push server to send a notification, passing the id of the saved subscription
     */
    const onClickSendNotification = async () => {
        setLoading(true);
        setError(false);
        // axios.get(`http://localhost:4000/subscription/${pushServerSubscriptionId}`).catch((error) => {
        //     setLoading(false);
        //     setError(error);
        // });
        // setLoading(false);
    };

    return {
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        userConsent,
        pushNotificationSupported,
        userSubscription,
        error,
        loading
    };
}