import ModalConfirmDelete from 'general/components/ModalConfirmDelete';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { thunkDeleteMyList, thunkRenameMyList } from './../../myListSlice';

ModalManageMyList.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};

ModalManageMyList.defaultProps = {
    show: false,
    onClose: null,
    onOpen: PropTypes.func,
};

function ModalManageMyList(props) {
    // MARK: --- Params ---
    const { show, onClose, onOpen } = props;
    const { t } = useTranslation();
    const [editableId, setEditableId] = useState()
    const [textInput, setTextInput] = useState('')
    const dispatch = useDispatch()
    const { isGetting, allMyLists } = useSelector(state => state.myList);
    const [modalConfirmDeleteShowing, setModalConfirmDeleteShowing] = useState(false);
    const [deleteId, setDeleteId] = useState()

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose()
            setEditableId()
        }
    }

    function handleOpen() {
        if (onOpen) {
            onOpen()
        }
    }

    function handleChangeMyListName() {
        for (let i = 0; i < allMyLists.length; i++)
            if (allMyLists[i].name === textInput) {
                if (allMyLists[i].myListId === editableId) {
                    // ToastHelper.showError(`${t('MyListNameUnchanged')}`)
                    setEditableId()
                    return
                } else {
                    ToastHelper.showError(`${t('MyListNameExisted')}`)
                    return
                }
            }
        dispatch(thunkRenameMyList({ myListId: editableId, newName: textInput }))
        setEditableId()
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (textInput === '') {
                ToastHelper.showError(`${t('MylistNameCannotBeEmpty')}`)
                return
            }
            handleChangeMyListName()
        }
    }

    function handleInputChange(e) {
        setTextInput(e.target.value)
    }

    return (
        <div className=''>
            <Modal
                className=''
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex align-items-center font-weight-bold'>
                    <Modal.Title>
                        {t('ManageMyList')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => handleClose()}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='max-h-350px p-0 q-hide-scrollbar rounded-bottom' style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                    <div className='font-size-h6'>
                        {
                            allMyLists.map((item, index) => (
                                <div
                                    key={item.myListId}
                                    className={`px-4 py-2 bg-white d-flex justify-content-between align-items-center ${index < allMyLists.length - 1 && 'border-bottom'}`}
                                >
                                    {
                                        editableId == item.myListId
                                            ? <input
                                                className='form-control w-75 py-0 font-size-lg'
                                                defaultValue={item.name}
                                                onChange={handleInputChange}
                                                onKeyPress={handleKeyPress}
                                                style={{ flex: '1 1 auto' }}
                                                spellCheck={false}
                                            />
                                            : <div key={item.myListId} className='px-2' style={{ overflowX: 'hidden', flex: '1 1 auto' }}>{item.name}</div>
                                    }

                                    <div
                                        className='p-3'
                                        style={{ flex: '0 0 auto' }}
                                    >
                                        {
                                            editableId === item.myListId
                                                ? <img
                                                    src={AppIcons.icCheck}
                                                    draggable={false}
                                                    className='mr-5 cursor-pointer hover-opacity-70'
                                                    onClick={() => {
                                                        handleChangeMyListName()
                                                    }}
                                                />
                                                : <img
                                                    src={AppIcons.icSquarePen}
                                                    draggable={false}
                                                    className='mr-5 cursor-pointer hover-opacity-70'
                                                    onClick={() => {
                                                        (!editableId || editableId !== item.myListId) ? setEditableId(item.myListId) : setEditableId()
                                                        setTextInput(item.name)
                                                    }}
                                                />
                                        }
                                        <img
                                            src={AppIcons.icSquareXMark}
                                            draggable={false}
                                            className='cursor-pointer hover-opacity-70'
                                            onClick={() => {
                                                handleClose()
                                                setModalConfirmDeleteShowing(true)
                                                setDeleteId(item.myListId)
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                        {
                            allMyLists.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                        loading={isGetting}
                                    />
                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <ModalConfirmDelete
                deleteText={t('ConfirmDeleteMyList')}
                show={modalConfirmDeleteShowing}
                onClose={() => {
                    setModalConfirmDeleteShowing(false)
                    handleOpen()
                }}
                deleteItem={() => {
                    dispatch(thunkDeleteMyList(deleteId))
                    handleOpen()
                }}
            />
        </div>
    );
}

export default ModalManageMyList;