import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ToastHelper from './../../../../general/helpers/ToastHelper';
import { thunkAddStock, thunkRemoveStock } from 'features/WatchList/watchlistSlice';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import { useHistory } from 'react-router-dom';
import Utils from 'general/utils/Utils';
import ModalCreateWatchlist from './../../../WatchList/components/ModalCreateWatchlist/index';

ModalAddtoWatchlist.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    stockCode: PropTypes.string,
    onFollow: PropTypes.func,
    onUnFollow: PropTypes.func,
};

ModalAddtoWatchlist.defaultProps = {
    show: false,
    onClose: null,
    onOpen: null,
    stockCode: null,
    onFollow: null,
    onUnFollow: null,
};

function ModalAddtoWatchlist(props) {
    // MARK: --- Params ---
    const { show, onClose, onOpen, stockCode, onFollow, onUnFollow } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { isLoadingWatchList, listWatchList } = useSelector(state => state.watchlist);
    const [watchlistInclude, setWatchlistInclude] = useState([])
    const history = useHistory();
    const loggedInUser = useSelector(state => state.auth.current);
    const [modalCreateWatchlistShowing, setModalCreateWatchlistShowing] = useState(false);

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose()
        }
    }

    function handleOpen() {
        if (onOpen) {
            onOpen()
        }
    }

    function handleUnFollow() {
        if (onUnFollow) {
            onUnFollow()
        }
    }

    function handleFollow() {
        if (onFollow) {
            onFollow()
        }
    }

    function handleClick(watchlist) {

        const obj = watchlistInclude.find(item => item === watchlist.watchlistId)

        if (obj === undefined) {
            dispatch(thunkAddStock({ watchlistId: watchlist.watchlistId, stockCode: stockCode }))
            ToastHelper.showSuccess(`${t('AddedToWatchlist')}: ${watchlist.name}`)
        } else {
            const obj1 = watchlist.listStock.find(item => item.stockCode === stockCode)
            dispatch(thunkRemoveStock({ watchlistStockId: obj1.watchlistStockId }))
            ToastHelper.showError(`${t('RemovedFromWatchlist')}: ${watchlist.name}`)
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        let listInclude = []

        listWatchList.forEach((watchlist) => {
            const obj = watchlist.listStock.find(item => item.stockCode === stockCode)
            if (obj) listInclude.push(watchlist.watchlistId)
            // console.log(obj)
        });
        if (listInclude.length === 0) handleUnFollow(); else handleFollow();
        setWatchlistInclude(listInclude)
    }, [stockCode, listWatchList])

    // console.log(watchlistInclude)

    return (
        <div className=''>
            <Modal
                className=''
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex align-items-center font-weight-bold'>
                    <Modal.Title>
                        {t('AddToWatchlist')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => handleClose()}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='max-h-350px p-0 q-hide-scrollbar rounded-bottom' style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                    <div className='mb-1 font-size-h6'>
                        {
                            listWatchList.length > 0 && listWatchList.map((watchlist, index) => {
                                return (
                                    <div
                                        key={watchlist.watchlistId}
                                        className={`px-4 py-4 bg-white d-flex justify-content-between align-items-center ${index < listWatchList.length - 1 && 'border-bottom'}`}
                                    >
                                        <div key={watchlist.watchlistId} className='px-2' style={{ overflowX: 'hidden', flex: '1 1 auto' }}>{watchlist.name}</div>
                                        <label className='checkbox checkbox-outline checkbox-primary pl-4' style={{ flex: '0 0 auto' }}>
                                            <input
                                                type='checkbox'
                                                name={watchlist.name}
                                                checked={watchlistInclude.find(item => item === watchlist.watchlistId) !== undefined}
                                                onChange={() => handleClick(watchlist)}
                                            />
                                            <span className='mr-4'></span>
                                        </label>
                                    </div>
                                )
                            })
                        }

                        {
                            listWatchList.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10 px-10'>
                                    {
                                        (Utils.isObjectNull(loggedInUser) || Utils.isObjectEmpty(loggedInUser)) ? (
                                            <EmptyView
                                                iconEmpty={AppIcons.icCircleUser}
                                                title={t('SignIn')}
                                                description={t('WatchlistNotLoggedInDescription')}
                                                buttonText={t('SignIn')}
                                                buttonIcon='far fa-sign-in'
                                                onPressButton={() => {
                                                    history.push('/sign-in');
                                                }}
                                            />
                                        ) : (
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        )
                                    }


                                </div>
                            )
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col'>
                            <a
                                className='w-100 btn btn-light font-size-lg font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        {
                            (!Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser)) && (
                                <div className='col'>
                                    <a
                                        className='w-100 btn btn-primary font-size-lg font-weight-bold'
                                        onClick={() => {
                                            setModalCreateWatchlistShowing(true)
                                            handleClose()
                                        }}
                                    >
                                        {t('CreateWatchlist')}
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </Modal.Footer>
            </Modal>
            {/* Modal create watchlist */}
            <ModalCreateWatchlist
                show={modalCreateWatchlistShowing}
                onClose={() => {
                    setModalCreateWatchlistShowing(false);
                    handleOpen()
                }}
            />
        </div>
    );
}

export default ModalAddtoWatchlist;