import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import UserHelper from 'general/helpers/UserHelper';
import Utils from 'general/utils/Utils';
import { thunkGetCurrentUserInfo } from 'app/authSlice';
import { thunkGetCourseTransactions } from './TransactionReducer';
import { thunkGetAllTransactions, thunkGetAllWebinars } from './accountSlice';
import { thunkGetListMyList } from 'features/MyList/myListSlice';
import { clearHomeMyListData, thunkGetHomeMyListData } from 'app/appSlice';
import { thunkGetBankAccountList, thunkGetPlanList } from 'features/Payment/paymentSlice';

AccountListener.propTypes = {

};

const sTag = '[AccountListener]';

function AccountListener(props) {
    // MARK: --- Params ---
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.auth.current);
    const { selectedMyList } = useSelector(state => state.myList);

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} did load`);

        return () => {
            console.log(`${sTag} will dismiss`);
        }
    }, []);

    useEffect(() => {
        console.log(`${sTag} loggedInUser changed`);
        if (Utils.isObjectEmpty(loggedInUser) && UserHelper.checkAccessTokenValid()) {
            dispatch(thunkGetCurrentUserInfo());
        }

        dispatch(thunkGetCourseTransactions());
        // lay tat ca lich su giao dich
        dispatch(thunkGetAllTransactions());
        // Lay tat ca hoi thao cua toi
        dispatch(thunkGetAllWebinars());
        // Lay ds danh muc cua toi
        dispatch(thunkGetListMyList({}));
        // Lay ds goi thue bao plan
        dispatch(thunkGetPlanList());
        // Lay ds tai khoan thanh toan ngan hang
        dispatch(thunkGetBankAccountList());
    }, [loggedInUser]);

    useEffect(() => {
        console.log(`${sTag} selectedMyList changed`);
        if (selectedMyList) {
            dispatch(thunkGetHomeMyListData({ myListId: selectedMyList.myListId }));
        } else {
            dispatch(clearHomeMyListData());
        }
    }, [selectedMyList]);

    return (<></>);
}

export default AccountListener;