import moment from "moment";
import { useEffect, useState } from "react";

/**
 * 
 * @param {number} expirationDateInSeconds 
 * @returns
 */
function useCountDown(expirationDateInSeconds) {
    const [timeString, setTimeString] = useState('');

    useEffect(() => {
        if (!isNaN(expirationDateInSeconds)) {
            const countDownInterval = setInterval(() => {
                const nowInSeconds = moment().local().unix();
                const diffInSeconds = expirationDateInSeconds - nowInSeconds;

                if (!isNaN(diffInSeconds) && diffInSeconds > 0) {
                    const minutes = parseInt(diffInSeconds / 60);
                    const seconds = diffInSeconds % 60;

                    const sTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                    setTimeString(sTime);
                } else {
                    setTimeString('00:00');
                }
            }, 1000);

            return () => {
                // cleanup
                clearInterval(countDownInterval);
            }
        }
    }, [expirationDateInSeconds]);

    return timeString;
};

export default useCountDown;