import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import AppLogo from 'general/components/AppLogo';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import UserHelper from 'general/helpers/UserHelper';
import { useHistory } from 'react-router-dom';

AccountHeader.propTypes = {

};

function AccountHeader(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();

    // MARK: --- Functions ---
    function handleSignOut(e) {
        e.preventDefault();

        Swal.fire({
            title: t('SignOut'),
            text: t('DoYouReallyWantToSignOut'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('Yes'),
            cancelButtonText: t('Cancel'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger font-weight-bolder',
                cancelButton: 'btn btn-light font-weight-bolder',
            }
        }).then(function (result) {
            console.log(result);
            if (result.value) {
                UserHelper.signOut();
                window.location.href = '/sign-in';

            }
        });
    }

    return (
        <div className='AccountHeader'>
            <div className='container-xxl py-3'>
                <div className='row'>
                    {/* Logo */}
                    <div className='col-lg-3 col-md-4 col-sm-5'>
                        <AppLogo />
                    </div>

                    <div className='col'></div>

                    {/* Right Links */}
                    <div className='col-lg-4 col-md-5 col-sm-6 d-flex justify-content-end align-items-center'>
                        <ul className="nav d-flex align-items-center">
                            <li className="nav-item">
                                <a className="nav-link text-white text-hover-primary font-size-lg font-weight-bold" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/terms');
                                }}>{t('Terms')}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white text-hover-primary font-size-lg font-weight-bold" href="#" onClick={handleSignOut}>{t('SignOut')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountHeader;