import axiosClient from "./axiosClient";

const planApi = {
    getPlanList: (params) => {
        const url = '/plan/all';
        return axiosClient.get(url, { params });
    },
};

export default planApi;
