import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'general/components/AppLayout';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import webinarApi from 'api/webinarApi';
import CellWebinarList from 'features/Webinar/components/CellWebinarList';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { useTranslation } from 'react-i18next';
import CellWebinarGrid from 'features/Webinar/components/CellWebinarGrid';
import Utils, { useScrollToTop } from 'general/utils/Utils';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import Global from 'general/Global';
import CellWebinarSessionList from 'features/Webinar/components/CellWebinarSessionList';
import { Markup } from 'interweave';
import AppBreadcrumb from 'general/components/AppBreadcrumb';
import { FacebookShareButton } from 'react-share';
import AppConfigs from 'general/constants/AppConfigs';
import ToastHelper from 'general/helpers/ToastHelper';
import EmptyView from 'general/components/Views/EmptyView';
import ModalRegisterJoinWebinar from 'features/Webinar/components/ModalRegisterJoinWebinar';
import ModalJoinWebinarSuccess from 'features/Webinar/components/ModalJoinWebinarSuccess';
import UserHelper from 'general/helpers/UserHelper';
import ModalVideoPlayer from 'features/Video/components/ModalVideoPlayer';

WebinarDetailScreen.propTypes = {

};

const sTag = '[WebinarDetailScreen]';

function WebinarDetailScreen(props) {
    // MARK: --- Params ---
    const { } = props;
    const { t } = useTranslation();
    const { recentWebinars } = useSelector(state => state.app);
    const match = useRouteMatch();
    const routeParams = match.params;
    const webinarId = routeParams.webinarId;
    const [webinar, setWebinar] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [loadingOtherWebinars, setLoadingOtherWebinars] = useState(false);
    const [otherWebinars, setOtherWebinars] = useState([]);
    const [loadingSessions, setLoadingSessions] = useState(false);
    const [sessions, setSessions] = useState([]);
    const loggedInUser = useSelector(state => state.auth.current);
    const isUserLoggedIn = !Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser);
    const [modalRegisterJoinWebinarShowing, setModalRegisterJoinWebinarShowing] = useState(false);
    const [modalJoinWebinarSuccessShowing, setModalJoinWebinarSuccessShowing] = useState(false);
    const [isPurchased, setIsPurchased] = useState()
    const [transaction, setTransaction] = useState()
    const [joined, setJoined] = useState(false)

    const [webinarStatus, setWebinarStatus] = useState(null);
    const allAccountTransactions = useSelector(state => state.account.allTransactions);

    const [videoModalTitle, setVideoModalTitle] = useState('');
    const [videoModalUrl, setVideoModalUrl] = useState('');
    const [videoModalOpen, setVideoModelOpen] = useState(false);

    // MARK: --- Functions ---
    // get webinar detail
    async function getWebinarDetail(id) {
        setLoading(true);
        const res = await webinarApi.getWebinarInfo(id);
        const { result, detail, isPurchased, transaction, joined } = res;
        if (result === 'success' && detail) {
            setWebinar(detail);
        }
        setIsPurchased(!!isPurchased)
        setTransaction(transaction)
        setJoined(!!joined)
        setLoading(false);
        getWebinarSession(id);
    }

    async function getOtherWebinars() {
        setLoadingOtherWebinars(true);
        const res = await webinarApi.getWebinarList({
            page: 0,
            limit: 10,
            searchKey: '',
        });
        const { result, webinars } = res;
        if (result === 'success' && webinars) {
            setOtherWebinars(webinars);
        }
        setLoadingOtherWebinars(false);
    }

    // get sessions
    async function getWebinarSession(id) {
        setLoadingSessions(true);
        const res = await webinarApi.getWebinarSessions(id, {
            page: 0,
            limit: Global.gDefaultPagination,
            searchKey: ''
        });
        const { result, webinarSessions } = res;
        if (result === 'success' && webinarSessions) {
            setSessions(webinarSessions);
        }
        setLoadingSessions(false);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} view did load`);

        return () => {
            console.log(`${sTag} view will dismiss`);
        }
    });

    useEffect(() => {
        if (webinarId && !isNaN(webinarId)) {
            getWebinarDetail(webinarId);
            getOtherWebinars();
            Utils.scrollToTop()
        }
    }, [webinarId]);

    useEffect(() => {
        if (webinarId && !isNaN(webinarId)) {
            const webinarStatus = UserHelper.checkWebinarStatus(webinarId);
            setWebinarStatus(webinarStatus);
        }
    }, [allAccountTransactions, webinarId]);

    // useScrollToTop()

    return (
        <AppLayout>
            <div className='WebinarDetailScreen bg-white min-h-600px'>
                <div className='container-xxl py-10'>
                    <div className='row'>
                        <div className='col-12 col-lg-8 overflow-hidden'>

                            {
                                webinar && (
                                    <AppBreadcrumb
                                        items={[
                                            { text: 'Home', url: '/home' },
                                            { text: 'Webinar', url: '/webinar' },
                                            { text: webinar.name, url: '' }
                                        ]}
                                    />
                                )
                            }

                            {
                                webinar && (
                                    <div className=''>
                                        <span className='font-weight-boldest' style={{
                                            fontSize: '2.2rem',
                                        }}>{webinar.name}</span>
                                        <span className='ml-2 text-size-lg font-weight-bold' style={{
                                            color: webinar.status === 'FINISHED' ? '#FF0000' : '#68C57C'
                                        }}>({webinar.status === 'OPEN' ? t('WebinarOpen') : webinar.status === 'CLOSED' ? t('WebinarClosed') : t('WebinarFinished')})</span>
                                        <p className='font-size-lg mt-2 text-justify'>{webinar.description}</p>

                                        <div className='mt-3'>
                                            {/* <img
                                                className='w-100 mb-5'
                                                alt='thumbnail'
                                                src={webinar.thumbnail ?? AppImages.imgDefaultThumbnail}
                                                style={{
                                                    // aspectRatio: '16/9',
                                                    backgroundColor: '#f3f5f8',
                                                    objectFit: 'cover'
                                                }}
                                            /> */}
                                            <p
                                                className='font-weight-bold m-0 text-primary'
                                                style={{
                                                    color: '#a4a9c5'
                                                }}
                                            >
                                                <i className="far fa-calendar-alt mr-2 text-dark-75"></i>{Utils.formatDateTime(webinar.startTime, 'DD/MM/YYYY HH:mm')} {webinar.status === 'FINISHED' && (<span className='' style={{ color: 'red' }}>{`(${t('Finished')})`}</span>)}
                                            </p>
                                            <p
                                                className='font-weight-bold m-0 my-2'
                                            >
                                                {`${t('Type')}: `}
                                                <span className='text-primary'>{webinar.online === 1 ? t('Online') : t('Offline')}</span>
                                            </p>
                                            {
                                                webinar.location && webinar.location.length > 0 && (
                                                    <p
                                                        className='font-weight-bold m-0 text-primary'
                                                        style={{
                                                            color: '#a4a9c5'
                                                        }}
                                                    >
                                                        <i className="fas fa-map-marker mr-2 text-dark-75"></i>{webinar.location}
                                                    </p>
                                                )
                                            }

                                            {/* webinar sessions */}
                                            <div className='py-2 mt-5'>
                                                <div className='row'>
                                                    {
                                                        sessions.map((item, index) => {
                                                            return (
                                                                <div key={index} className={`col-12 mb-5`}>
                                                                    <CellWebinarSessionList
                                                                        item={item}
                                                                        showDivider={index < sessions.length - 1}
                                                                        onPress={() => {
                                                                            // alert(`Video link: ${item.video}`);
                                                                            setVideoModelOpen(true);
                                                                            setVideoModalTitle(item?.name);
                                                                            setVideoModalUrl(item?.video);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* content */}
                                        <Markup
                                            className='font-size-lg text-justify q-markup'
                                            content={webinar.content}
                                        />

                                        {/* share */}
                                        <div className='row mt-10'>
                                            <div className="col-6">
                                                <FacebookShareButton
                                                    url={Utils.getCurrentUrl()}
                                                    quote={""}
                                                    title={webinar.name}
                                                    hashtag={AppConfigs.hashtag}
                                                    description={webinar.description}
                                                    className='w-100 h-40px btn text-white font-weight-bold d-flex align-items-center justify-content-center hover-opacity-60'
                                                    style={{
                                                        backgroundColor: '#3C5795'
                                                    }}
                                                >
                                                    <i className="fab fa-facebook text-white mr-2"></i>{t('Share')}
                                                </FacebookShareButton>
                                            </div>
                                            <div className='col-6'>
                                                {
                                                    webinar.status !== 'FINISHED' && (
                                                        <a
                                                            href='#'
                                                            className={`${(['SUCCESS', 'PENDING'].includes(webinarStatus) || webinar.status === 'FINISHED') && 'disabled'} bg-primary btn text-white w-100 font-weight-bold d-flex align-items-center justify-content-center hover-opacity-60`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!isUserLoggedIn) {
                                                                    ToastHelper.showError(t('YouNeedToLoginFirst'));
                                                                    return;
                                                                }
                                                                if (webinar?.price == 0 && !['SUCCESS', 'PENDING'].includes(webinarStatus))
                                                                    setModalRegisterJoinWebinarShowing(true);
                                                                else if (webinar?.price > 0) {
                                                                    history.push({
                                                                        pathname:
                                                                            "/payment/purchase",
                                                                        state: {
                                                                            productId:
                                                                                webinar?.webinarId,
                                                                            name: webinar?.name,
                                                                            price:
                                                                                webinar?.price,
                                                                            currency:
                                                                                null,
                                                                            type: "WEBINAR",
                                                                            description: webinar?.description,
                                                                        },
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <i className="far fa-sign-in text-white mr-2"></i>
                                                            {webinarStatus === 'SUCCESS' ? t('Joined') : webinarStatus == 'PENDING' ? t('Wait for confirmation') : t('JoinWebinar')}
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            {/* other webinars */}
                            <div className='mt-10'>
                                <SectionHeaderView
                                    title={t('OtherWebinars')}
                                />
                                <div className='row mt-5'>
                                    {
                                        otherWebinars.length > 0 && otherWebinars.map((item, index) => {
                                            if (item?.webinarId != webinarId) {
                                                return (
                                                    <div key={index} className={`col-12 mb-4 pb-4`}>
                                                        <CellWebinarList
                                                            item={item}
                                                            showDivider={index < otherWebinars.length - 1}
                                                        />
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={index}></div>
                                                )
                                            }
                                        })
                                    }

                                    {
                                        otherWebinars.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        {/* recently webinars */}
                        <div className='col-12 col-lg-4'>
                            <div className=''>
                                <SectionHeaderView
                                    title={t('RecentlyWebinars')}
                                />
                                <div className='row mt-5'>
                                    {
                                        recentWebinars.length > 0 && recentWebinars.map((item, index) => {
                                            return (
                                                <div key={index} className={`col-12 mb-4 pb-4 ${index < recentWebinars.length - 1 && 'border-bottom'}`}>
                                                    <CellWebinarGrid
                                                        item={item}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        recentWebinars.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal register join webinar */}
            <ModalRegisterJoinWebinar
                show={modalRegisterJoinWebinarShowing}
                onClose={() => {
                    setModalRegisterJoinWebinarShowing(false);
                }}
                webinarItem={webinar ?? {}}
            />

            {/* Modal register success */}
            <ModalJoinWebinarSuccess
                show={modalJoinWebinarSuccessShowing}
                onClose={() => {
                    setModalJoinWebinarSuccessShowing(false);
                }}
                webinarItem={webinar ?? {}}
            />

            <ModalVideoPlayer
                title={videoModalTitle}
                url={videoModalUrl}
                isOpen={videoModalOpen}
                onClose={() => {
                    setVideoModelOpen(false);
                }}
            />
        </AppLayout>

    );
}

export default WebinarDetailScreen;
