import bannerApi from 'api/bannerApi';
import miscApi from "api/miscApi";
import myListApi from 'api/myListApi';
import sectorApi from 'api/sectorApi';
import stockApi from "api/stockApi";
import { Banner, BannerType } from 'general/types/index.d';
import _ from 'lodash';

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get common data
export const thunkGetCommonData = createAsyncThunk('app/getCommonData', async (params, thunkAPI) => {
    const res = await miscApi.getCommonData(params);
    return res;
});

// Get realtime indexes
export const thunkGetRealtimeIndexes = createAsyncThunk('app/getRealtimeIndexes', async (params, thunkAPI) => {
    const res = await stockApi.realtimeIndex(params);
    return res;
})

// get banners
export const thunkGetBanners = createAsyncThunk('app/getBanners', async () => {
    return bannerApi.getBanners()
})

// get report norm data
export const thunkGetReportNormData = createAsyncThunk('app/getReportNormData', async (params, thunkAPI) => {
    const res = await miscApi.getReportNormData();
    return res;
});

// get top profit company
export const thunkGetTopProfitCompany = createAsyncThunk('app/getTopProfitCompany', async (params, thunkAPI) => {
    const res = await miscApi.getTopProfitCompany(params);
    return res;
});

// get home my list data
export const thunkGetHomeMyListData = createAsyncThunk('app/getHomeMyListData', async (params, thunkAPI) => {
    const res = await myListApi.getMyListListData(params.myListId);
    return res;
});

// get home sector index data
export const thunkGetHomeSectorIndexData = createAsyncThunk('app/getHomeSectorIndexData', async (params, thunkAPI) => {
    const res = await sectorApi.getSectorIndex();
    return res;
});

const appSlice = createSlice({
    name: 'app',
    initialState: {
        topFaqs: [],
        topFeaturedReviews: [],
        topArticles: [],
        topResearches: [],
        topVideos: [],
        topProducts: [],
        recentWebinars: [],
        incomingWebinars: [],
        topCourses: [],
        recentEvents: [],
        ideaLists: [],
        topMgIdentifies: [],
        /**
         * @type {{[x in BannerType]:{list:Banner[],currentIndex:number}}}
         */
        banners: {
            BANNER_3_4: { list: [], currentIndex: 0 },
            BANNER_9_16: { list: [], currentIndex: 0 },
            DEFAULT: { list: [], currentIndex: 0 }
        },
        realtimeIndexes: [],
        reportNormData: {},
        topProfitCompanies: [],

        homeMyListData: [],

        sectorIndexData: [],
    },
    reducers: {
        setBannerCurrentIndex(state, { payload }: { payload: { type: BannerType, index: Number } }) {
            state.banners[payload?.type ?? 'DEFAULT'].currentIndex = payload.index
        },

        clearHomeMyListData: (state, action) => {
            state.homeMyListData = [];
        },
    },
    extraReducers: {
        // Get common data
        [thunkGetCommonData.fulfilled]: (state, action) => {
            const payload = action.payload;
            const {
                result,
                faqs,
                featuredReviews,
                topArticles,
                topResearchs,
                topVideos,
                topProducts,
                recentWebinars,
                incomingWebinars,
                topCourses,
                recentEvent,
                ideaLists,
                topMgIdentifies
            } = payload;
            if (result === 'success') {
                if (faqs) {
                    state.topFaqs = faqs;
                }
                if (featuredReviews) {
                    state.topFeaturedReviews = featuredReviews;
                }
                if (topArticles) {
                    state.topArticles = topArticles;
                }
                if (topResearchs) {
                    state.topResearches = topResearchs;
                }
                if (topVideos) {
                    state.topVideos = topVideos;
                }
                if (topProducts) {
                    state.topProducts = topProducts;
                }
                if (recentWebinars) {
                    state.recentWebinars = recentWebinars;
                }
                if (incomingWebinars) {
                    state.incomingWebinars = incomingWebinars;
                }
                if (topCourses) {
                    state.topCourses = topCourses;
                }
                if (recentEvent) {
                    state.recentEvents = recentEvent;
                }
                if (ideaLists) {
                    state.ideaLists = ideaLists;
                }
                if (topMgIdentifies) {
                    state.topMgIdentifies = topMgIdentifies;
                }
            }
        },

        // Get realtime indexes
        [thunkGetRealtimeIndexes.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, indexes } = payload;
            if (result === 'success' && indexes) {
                state.realtimeIndexes = indexes;
            }
        },


        //get banners
        [thunkGetBanners.fulfilled]:
            /**
             * 
             * @param {{banners:{[x in BannerType]:{list:Banner[],currentIndex:number}}}} state 
             * @param {{payload}} param1 
             */
            (state, { payload }) => {
                const { result, banners }: { result: 'success', banners: Banner[] } = payload

                if (result == 'success') {
                    if (_.isArray(banners)) {
                        state.banners.BANNER_3_4.list = banners.filter(banner => banner.type == 'BANNER_3_4')
                        state.banners.BANNER_9_16.list = banners.filter(banners => banners.type == 'BANNER_9_16')
                        state.banners.DEFAULT.list = banners.filter(banners => banners.type == 'DEFAULT')
                    }
                    _.forOwn(state.banners, dict => {
                        dict.currentIndex = Math.max(0, _.min([dict.currentIndex, dict.list.length - 1]))
                    })
                }
            },

        // Get report norm data
        [thunkGetReportNormData.fulfilled]: (state, action) => {
            const payload = action.payload;
            // console.log({ payload });
            const { result, data, alternative, financeIndex } = payload;
            if (result === 'success') {
                if (data) {
                    state.reportNormData.data = data;
                }
                if (alternative) {
                    state.reportNormData.alternative = alternative;
                }
                if (financeIndex) {
                    state.reportNormData.reportFinanceIndexData = financeIndex;
                }
            }
        },

        // Get top profit company
        [thunkGetTopProfitCompany.fulfilled]: (state, action) => {
            const payload = action.payload;
            // console.log({ payload });
            const { result, data } = payload;
            if (result === 'success') {
                if (data) {
                    state.topProfitCompanies = data;
                }
            }
        },

        // Get home my list data
        [thunkGetHomeMyListData.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, data } = payload;
            if (result === 'success') {
                if (data) {
                    state.homeMyListData = data;
                }
            }
        },

        // Get home sector index data
        [thunkGetHomeSectorIndexData.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, data } = payload;
            if (result === 'success' && data) {
                state.sectorIndexData = data;
            }
        },
    },
});

const { reducer, actions } = appSlice;
export const { setBannerCurrentIndex, clearHomeMyListData } = actions;
export default reducer;