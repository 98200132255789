import webinarApi from 'api/webinarApi';
import CellWebinarGrid from 'features/Webinar/components/CellWebinarGrid';
import CellWebinarList from 'features/Webinar/components/CellWebinarList';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KCHeaderView from '../KCHeaderView';

KCWebinar.propTypes = {
    onMenuHandle: PropTypes.func,
};

KCWebinar.defaultProps = {
    onMenuHandle: null,
};

function KCWebinar(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { onMenuHandle } = props;
    const { incomingWebinars } = useSelector(state => state.app);
    const [loading, setLoading] = useState(false);
    const [webinars, setWebinars] = useState([]);
    const history = useHistory();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        searchKey: '',
    });
    const pagination = useRef(null);

    // MARK: --- Functions ---
    async function getWebinarList() {
        setLoading(true);
        const res = await webinarApi.getWebinarList(filter);
        const { result, total, page, count } = res;
        const resWebinars = res.webinars;
        if (result === 'success' && resWebinars) {
            if (page === 0) {
                setWebinars(resWebinars);
            } else {
                setWebinars(webinars.concat(resWebinars));
            }
            pagination.current = {
                total: total,
                count: count,
                currentPage: page
            };
        }
        setLoading(false);
    }

    function onWindowScroll() {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (pagination.current) {
                let nextPage = Utils.getNextPage(pagination.current);
                console.log({ nextPage });
                if (nextPage) {
                    setFilter({
                        ...filter,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getWebinarList();
    }, [filter]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <div className='KCWebinar'>
            <KCHeaderView
                title={t('AllWebinars')}
                onMenuHandle={() => {
                    if (onMenuHandle) {
                        onMenuHandle();
                    }
                }}
                onSubmitSearch={(text) => {
                    setFilter({
                        ...filter,
                        page: 0,
                        searchKey: text,
                    });
                }}
            />

            <div>
                {/* incoming webinars */}
                <div className='px-5 pt-5 pb-2'>
                    <div>
                        <SectionHeaderView
                            title={t('IncomingWebinar')}
                            dividerVisible={false}
                            titleClassName='font-size-lg font-weight-bolder'
                        />
                        <div className='row'>
                            {
                                incomingWebinars.length > 0 && incomingWebinars.map((item, index) => {
                                    return (
                                        <div key={index} className='col-12 col-sm-6 col-md-6 my-4'>
                                            <CellWebinarGrid item={item} />
                                        </div>
                                    )
                                })
                            }

                            {
                                incomingWebinars.length === 0 && (
                                    <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                        <EmptyView
                                            description='Không có hội thảo sắp diễn ra'
                                            iconEmpty={AppIcons.icEmptyPack}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <hr className='bg-light m-0' />

                <div className='pt-5'>
                    <SectionHeaderView
                        title={t('WebinarList')}
                        dividerVisible={false}
                        titleClassName='font-size-lg font-weight-bolder ml-5'
                    />
                    <div className='row m-0'>
                        {
                            webinars.length > 0 && webinars.map((item, index) => {
                                return (
                                    <div key={index} className={`col-12 py-4 border-light ${index < webinars.length - 1 && 'border-bottom'}`}>
                                        <CellWebinarList item={item} />
                                    </div>
                                )
                            })
                        }

                        {
                            webinars.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description='Không có hội thảo'
                                        iconEmpty={AppIcons.icEmptyPack}
                                        loading={loading}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KCWebinar;