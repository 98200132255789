import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import VideoDetailScreen from './screens/VideoDetailScreen';
import NotFound from 'general/components/NotFound';

Video.propTypes = {

};

function Video(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            {/* <Route
                exact
                path={`${match.url}`}
                component={ArticleHomeScreen}
            /> */}
            <Route
                path={`${match.url}/:videoId`}
                component={VideoDetailScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Video;