import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthHeader from 'features/Auth/components/AuthHeader';
import AuthContent from 'features/Auth/components/AuthContent';
import { Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import BaseTextField from 'general/components/Forms/FormControls/BaseTextField';
import * as Yup from 'yup';
import './style.scss';
import Utils from 'general/utils/Utils';
import accountApi from 'api/accountApi';
import ToastHelper from 'general/helpers/ToastHelper';
import LoadingOverlay from 'general/components/LoadingOverlay';
import moment from 'moment';
import useCountDown from 'hooks/useCountDown';
import _ from 'lodash';

ResetPasswordScreen.propTypes = {

};

const sTag = '[ResetPasswordScreen]';

function ResetPasswordScreen(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: localStorage.getItem(PreferenceKeys.savedEmail) ?? '',
            code: '',
            password: '',
            rePassword: '',
        },
        onSubmit: async (values) => {
            let hashPassword = Utils.sha256(values.password);
            const hashCode = Utils.sha256(values.code);
            const params = {
                email: values.email,
                code: hashCode,
                newPassword: hashPassword,
            };
            console.log(`${sTag} on submit: ${JSON.stringify(params)}`);
            try {
                const res = await accountApi.resetPassword(params);
                const { result } = res;
                setLoading(false);
                if (result === 'success') {
                    ToastHelper.showSuccess(t('ResetPasswordSuccess'));
                    history.push('/sign-in');
                }
            } catch (error) {
                setLoading(false);
                console.log(`${sTag} Error: ${error}`);
            }

        },
        validationSchema: Yup.object({
            email: Yup.string().required(t('EmailIsRequired')).email(t('EmailNotValid')),
            code: Yup.string().required(t('CodeIsRequired')),
            password: Yup.string().required(t('PasswordIsRequired')),
            rePassword: Yup.string().required(t('PasswordIsRequired')).oneOf([Yup.ref('password')], t('PasswordNotMatch')),
        }),
    });
    const savedResetPasswordExpirationDate = localStorage.getItem(PreferenceKeys.savedResetPasswordExpirationDate);
    const [resetPasswordExpirationDate, setResetPasswordExpirationDate] = useState(() => {
        if (savedResetPasswordExpirationDate) {
            return moment(savedResetPasswordExpirationDate).local().unix();//.format('DD/MM/YYYY HH:mm:ss');
        } else {
            return null;
        }
    });
    const textCountDown = useCountDown(resetPasswordExpirationDate);
    const [resendingCode, setResendingCode] = useState(false);

    // MARK: --- Functions ---
    /**
     * 
     * @param {*} email 
     */
    async function resendResetPasswordCode(email) {
        if (!_.isEmpty(email)) {
            try {
                setResendingCode(true);
                const res = await accountApi.requestResetPassword(email);
                const { result, expirationDate } = res;
                setResendingCode(false);
                if (result === 'success') {
                    if (expirationDate) {
                        localStorage.setItem(PreferenceKeys.savedResetPasswordExpirationDate, expirationDate);
                        setResetPasswordExpirationDate(moment(expirationDate).local().unix());
                    }
                    ToastHelper.showSuccess('Mã đặt lại mật khẩu đã được gửi vào hòm thư của bạn');
                }
            } catch (error) {
                setResendingCode(false);
                console.log(`${sTag} Error: ${error}`);
            }
        } else {
            ToastHelper.showError('Bạn chưa nhập email');
        }
    }

    // MARK: --- Hooks ---

    return (
        <div className='ResetPasswordScreen min-vh-100 d-flex flex-column'>
            <AuthHeader />
            <AuthContent
                leftTitle='Bạn Quên Mật Khẩu?'
                leftDescription='Đừng lo! Hãy nhập Email của bạn và làm theo hướng dẫn để cập nhật mật khẩu mới'
                rightElement={(
                    <div>
                        <Card className={`rounded-lg overflow-hidden  ${loading ? 'overlay overlay-block' : ''}`}>
                            <Card.Header className='border-0'>
                                <p className='font-size-h3 font-weight-boldest m-0 text-center'>{t('ResetPassword')}</p>
                            </Card.Header>
                            <Card.Body className='pt-0'>
                                <div className=''>
                                    <form onSubmit={formik.handleSubmit}>
                                        <BaseTextField
                                            name='email'
                                            placeholder={`${t('Email')}...`}
                                            label={t('Email')}
                                            fieldHelper={formik.getFieldHelpers('email')}
                                            fieldProps={formik.getFieldProps('email')}
                                            fieldMeta={formik.getFieldMeta('email')}
                                        />
                                        <BaseTextField
                                            name='code'
                                            placeholder={`${t('Code')}...`}
                                            label={t('Code')}
                                            fieldHelper={formik.getFieldHelpers('code')}
                                            fieldProps={formik.getFieldProps('code')}
                                            fieldMeta={formik.getFieldMeta('code')}
                                        />
                                        <BaseTextField
                                            name='password'
                                            placeholder={`${t('NewPassword')}...`}
                                            label={t('NewPassword')}
                                            type='password'
                                            fieldHelper={formik.getFieldHelpers('password')}
                                            fieldProps={formik.getFieldProps('password')}
                                            fieldMeta={formik.getFieldMeta('password')}
                                        />
                                        <BaseTextField
                                            name='rePassword'
                                            placeholder={`${t('ReNewPassword')}...`}
                                            label={t('ReNewPassword')}
                                            type='password'
                                            fieldHelper={formik.getFieldHelpers('rePassword')}
                                            fieldProps={formik.getFieldProps('rePassword')}
                                            fieldMeta={formik.getFieldMeta('rePassword')}
                                        />

                                        <div className=''>
                                            {
                                                textCountDown === '00:00' ? (
                                                    <p>{t('CodeExpired')}</p>
                                                ) : (
                                                    <p>{t('CodeWillExpiredIn')}&nbsp;<span className='font-size-lg font-weight-bold' style={{
                                                        color: '#A635FF'
                                                    }}>{textCountDown}</span></p>
                                                )
                                            }
                                            {
                                                textCountDown === '00:00' && (
                                                    <a href='#' className='' style={{
                                                        textDecoration: 'underline'
                                                    }} onClick={(e) => {
                                                        e.preventDefault();
                                                        resendResetPasswordCode(formik.values.email);
                                                    }}>{t('Resend')}</a>
                                                )
                                            }
                                        </div>

                                        <button
                                            className="btn btn-primary btn-lg w-100 border-0 font-weight-bold mt-4"
                                            onClick={() => {

                                            }}
                                        >
                                            {t('Send')}
                                        </button>
                                    </form>
                                </div>
                            </Card.Body>
                            <Card.Footer className='d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: '#F3F5F8', borderTopColor: '#DBE3EF' }}>
                                <p className='m-0 text-center font-weight-boldest font-size-h6'>Quay lại màn hình</p>
                                <button onClick={() => {
                                    history.goBack();
                                }} style={{ backgroundColor: '#4886FF' }} className="btn btn-linkedin btn-lg mt-4 w-100 border-0 hover-opacity-80 font-weight-bold">{t('ForgotPassword')}</button>
                            </Card.Footer>

                            {
                                loading && (
                                    <LoadingOverlay />
                                )
                            }
                        </Card>
                    </div>
                )}
            >

            </AuthContent>
        </div>
    );
}

export default ResetPasswordScreen;