import webinarApi from 'api/webinarApi';
import CellWebinarGrid from 'features/Webinar/components/CellWebinarGrid';
import CellWebinarList from 'features/Webinar/components/CellWebinarList';
import AppBanner from 'general/components/AppBanner';
import AppLayout from 'general/components/AppLayout';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const sTag = '[WebinarHomeScreen]';

function WebinarHomeScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { incomingWebinars, recentWebinars } = useSelector(state => state.app);
    const [webinars, setWebinars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
        searchKey: '',
    });
    const pagination = useRef(null);
    const history = useHistory();

    // MARK: --- Functions ---
    async function getWebinarList() {
        setLoading(true);
        const res = await webinarApi.getWebinarList(filter);
        const { result, total, page, count } = res;
        const resWebinars = res.webinars;
        if (result === 'success' && resWebinars) {
            if (page === 0) {
                setWebinars(resWebinars);
            } else {
                setWebinars(webinars.concat(resWebinars));
            }
            pagination.current = {
                total: total,
                count: count,
                currentPage: page
            };
        }
        setLoading(false);
    }

    function onWindowScroll() {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight) {
            if (pagination.current) {
                let nextPage = Utils.getNextPage(pagination.current);
                console.log({ nextPage });
                if (nextPage) {
                    setFilter({
                        ...filter,
                        page: nextPage,
                    });
                }
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        getWebinarList();
    }, [filter]);

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll);
    }, []);

    return (
        <AppLayout>
            <div className='WebinarHomeScreen bg-white min-h-600px'>
                {/* content */}
                <div className='container-xxl py-10'>
                    {/* incoming webinars */}
                    <SectionHeaderView
                        title={t('IncomingWebinars')}
                    />
                    <div className='row pb-4'>
                        {
                            incomingWebinars.length > 0 && incomingWebinars.map((item, index) => {
                                return (
                                    <div key={index} className='col-12 col-sm-6 col-md-6 my-4'>
                                        <CellWebinarGrid
                                            item={item}
                                            onPress={() => {
                                                history.push(`/webinar/${item.webinarId}`);
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }

                        {
                            incomingWebinars.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={'Không có hội thảo sắp diễn ra'}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>

                    {/* other webinars */}
                    <div className='mt-10'>
                        <SectionHeaderView
                            title={t('RecentWebinars')}
                        />
                        <div className='row pb-10'>
                            <div className='col-12 col-md-8'>
                                <div className='row'>
                                    {
                                        webinars.length > 0 && webinars.map((item, index) => {
                                            return (
                                                <div key={index} className='col-12 pt-5'>
                                                    <CellWebinarList
                                                        item={item}
                                                        showDivider={index < webinars.length - 1}
                                                        onPress={() => {
                                                            history.push(`/webinar/${item.webinarId}`);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        webinars.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={'Không có hội thảo gần đây'}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                    loading={loading}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* banner */}
                            <div className='col-4 pl-4 pt-4 d-none d-md-block'>
                                <AppBanner className='h-600px' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}

export default WebinarHomeScreen;