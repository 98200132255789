import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';
import { useTranslation } from 'react-i18next';
import { AppIcons, AppImages } from 'general/constants/AppResource';

ImageInput.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelper: PropTypes.object,

    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    onImageSelected: PropTypes.func,
    circle: PropTypes.bool,
};

ImageInput.defaultProps = {
    disabled: false,
    text: "",

    label: '',
    className: '',
    fieldProps: {},
    fieldHelper: {},
    fieldMeta: {},
    onImageSelected: null,
    circle: false,
};

function ImageInput(props) {
    // MARK: --- Params ---
    const { label, name, fieldProps, fieldHelper, fieldMeta, disabled, text, className, circle, onImageSelected } = props;
    const { error, touched } = fieldMeta;
    const isError = error && touched;
    const value = fieldProps.value;
    const [selectedImage, setSelectedImage] = useState(null);
    const { t } = useTranslation();
    let image = encodeURI(value);
    if (selectedImage !== null) {
        image = URL.createObjectURL(selectedImage);
    }
    if (Utils.isObjectNull(image)) {
        image = AppIcons.icAccountInfo;
    }

    // MARK: --- Functions ---
    function handleImageChange(e) {
        const images = e.target.files;
        if (images && images.length > 0) {
            const selectedImage = images[0];
            setSelectedImage(selectedImage);

            if (onImageSelected) {
                onImageSelected(selectedImage);
            }
        }
    }

    function handleRemoveImage(e) {
        e.preventDefault();
        setSelectedImage(null);
        if (onImageSelected) {
            onImageSelected(null);
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        return () => {
            if (!Utils.isObjectNull(image)) {
                URL.revokeObjectURL(image);
            }
        }
    }, [image]);

    return (
        <div className='ImageInput'>
            <div className='form-group d-flex flex-column align-items-center'>
                <div className={`image-input image-input-empty image-input-outline ${circle && 'image-input-circle'}`} style={{ backgroundImage: `url(${Utils.getFullUrl(image)})` }}>
                    <div className={`image-input-wrapper ${className} ${isError && 'border-1 border-danger'}`}>
                        {
                            !Utils.isObjectNull(selectedImage) && (
                                <a
                                    href='#'
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute"
                                    style={{ right: '5px', bottom: '5px' }}
                                    onClick={handleRemoveImage}
                                >
                                    <i className="fas fa-times text-muted"></i>
                                </a>
                            )
                        }
                    </div>
                    <label className={`btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${isError && 'border-1 border-danger'}`} data-action='change'>
                        <i className='fa fa-pen icon-sm text-muted' />
                        <input
                            type="file"
                            name={name}
                            id={name}
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChange}
                        />
                    </label>
                </div>
                <p className="mt-6 mb-0 font-size-lg font-weight-bold text-primary">{label}</p>
            </div>
        </div>
    );
}

export default ImageInput;