import React from 'react';
import PropTypes from 'prop-types';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';
import { useTranslation } from 'react-i18next';
import { AppIcons } from 'general/constants/AppResource';

KCHeaderView.propTypes = {
    title: PropTypes.string,
    onMenuHandle: PropTypes.func,
    onSubmitSearch: PropTypes.func,
    showSearchBar: PropTypes.bool,
};

KCHeaderView.defaultProps = {
    title: '',
    onMenuHandle: null,
    onSubmitSearch: null,
    showSearchBar: true,
};

function KCHeaderView(props) {
    // MARK: --- Params ---
    const { title, onMenuHandle, onSubmitSearch, showSearchBar } = props;
    const { t } = useTranslation();

    // MARK: --- Functions ---

    return (
        <div className='KCHeaderView border-bottom py-3 p-3 d-flex align-items-center justify-content-between min-h-55px'>
            <div className='d-flex align-items-center'>
                <button
                    className="btn btn-icon hover-opacity-40 d-block d-lg-none"
                    id='knowledge_center_aside_toggle'
                    onClick={() => {
                        if (onMenuHandle) {
                            onMenuHandle();
                        }
                    }}
                >
                    <i className="fas fa-bars text-primary" />
                </button>
                <p className='m-0 font-size-lg font-weight-bolder ml-2'>{title}</p>
            </div>

            <div>
                {
                    showSearchBar && (
                        <BaseSearchBar
                            name='searchBar'
                            placeholder={`${t('TypeToSearch')}...`}
                            onSubmit={(text) => {
                                if (onSubmitSearch) {
                                    onSubmitSearch(text);
                                }
                            }}
                        />
                    )
                }

            </div>
        </div>
    );
}

export default KCHeaderView;