import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppIcons } from 'general/constants/AppResource';
import ModalConfirmDelete from 'general/components/ModalConfirmDelete';
import { thunkEditStockDetail, thunkGetStockDetail, thunkRemoveStock } from 'features/MyList/myListSlice';
import { useFormik } from 'formik';
import Utils from 'general/utils/Utils';
import EmptyView from 'general/components/Views/EmptyView';

ModalEditTransaction.propTypes = {
    onClose: PropTypes.func,
    onShow: PropTypes.func,
    onEditSuccess: PropTypes.func,
};

ModalEditTransaction.defaultProps = {
    onClose: null,
    onShow: null,
    onEditSuccess: null,
}

function ModalEditTransaction(props) {
    const { show, onClose, onShow, onEditSuccess } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { stockDetails, selectedMyList, selectedStockCode } = useSelector(state => state.myList);
    const [isEditedId, setIsEditedId] = useState();
    const [modalConfirmDeleteFilter, setModalConfirmDeleteFilter] = useState(false);
    const [myListStockIdDeleted, setMyListStockIdDeleted] = useState();

    function handleClose() {
        if (props.onClose) {
            props.onClose();
            setIsEditedId();
        }
    }
    function handleShow() {
        if (props.onShow) {
            props.onShow();
        }
    }

    useEffect(() => {
        if (selectedStockCode) {
            dispatch(thunkGetStockDetail({ myListId: selectedMyList.myListId, stockCode: selectedStockCode }));
        }
    }, [selectedMyList, selectedStockCode]);

    const formik = useFormik({
        initialValues: {
            datetime: '',
            price: '',
            quantity: '',
            note: ''
        },
        onSubmit: values => {
            console.log({
                myListStockId: isEditedId,
                datetime: values.datetime,
                price: values.price,
                quantity: values.quantity,
                note: values.note
            });
            for (let i = 0; i < stockDetails.length; i++) {
                if (stockDetails[i].myListStockId === isEditedId &&
                    (
                        stockDetails[i].datetime !== values.datetime ||
                        stockDetails[i].price !== values.price ||
                        stockDetails[i].quantity !== values.quantity ||
                        stockDetails[i].note !== values.note)
                ) {
                    dispatch(thunkEditStockDetail({
                        myListStockId: isEditedId,
                        datetime: values.datetime,
                        price: values.price,
                        quantity: values.quantity,
                        note: values.note
                    }));
                }
            }
            setIsEditedId();
        }
    })
    return (
        <div>
            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                onExited={() => {
                    if (onEditSuccess) {
                        onEditSuccess();
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('UpdateTransaction')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                {/* Modal body */}
                <div className='bg-light p-5 rounded-bottom'>
                    <div className='d-block overflow-auto border' style={{ maxHeight: '84vh' }}>
                        {
                            stockDetails.length > 0 ? (
                                <table className="table bg-white m-0">
                                    <thead>
                                        <tr>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('StockCode')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Command')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('TransactionTime')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Price')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Quantity')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Notes')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('Action')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stockDetails.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ verticalAlign: 'middle' }} className='font-weight-bolder'>{item?.stockCode}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{t(item?.command)}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {isEditedId == item.myListStockId
                                                            ? <input
                                                                type='datetime-local'
                                                                id='datetime'
                                                                name='datetime'
                                                                className='form-control py-0'
                                                                spellCheck='false'
                                                                value={formik.values.datetime}
                                                                onChange={formik.handleChange}
                                                            />
                                                            : <span>{Utils.formatDateTime(item?.datetime, 'DD/MM/YYYY hh:mm A')}</span>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {isEditedId == item.myListStockId
                                                            ? <input
                                                                type='number'
                                                                id='price'
                                                                name='price'
                                                                className='form-control rounded py-0 w-100px'
                                                                spellCheck='false'
                                                                value={formik.values.price}
                                                                onChange={formik.handleChange}
                                                            />
                                                            : <span>{Utils.formatNumber(item?.price)}</span>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {isEditedId == item.myListStockId
                                                            ? <input
                                                                type='number'
                                                                id='quantity'
                                                                name='quantity'
                                                                className='form-control py-0 w-100px'
                                                                defaultValue={item.quantity}
                                                                spellCheck='false'
                                                                value={formik.values.quantity}
                                                                onChange={formik.handleChange}
                                                            />
                                                            : <span>{Utils.formatNumber(item?.quantity)}</span>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        {isEditedId == item.myListStockId
                                                            ? <input
                                                                id='note'
                                                                name='note'
                                                                className='form-control rounded py-0 w-100px'
                                                                defaultValue={item.note}
                                                                spellCheck='false'
                                                                value={formik.values.note}
                                                                onChange={formik.handleChange}
                                                            />
                                                            : <span>{item?.note}</span>
                                                        }
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <div className='py-3'>
                                                            {(isEditedId !== item.myListStockId)
                                                                ? <img
                                                                    src={AppIcons.icSquarePen}
                                                                    draggable={false}
                                                                    className='mr-5 cursor-pointer hover-opacity-70'
                                                                    onClick={() => {
                                                                        (!isEditedId || isEditedId !== item.myListStockId) ? setIsEditedId(item.myListStockId) : setIsEditedId();
                                                                        formik.getFieldHelpers('price').setValue(item.price);
                                                                        formik.getFieldHelpers('quantity').setValue(item.quantity);
                                                                        formik.getFieldHelpers('note').setValue(item.note);
                                                                        formik.getFieldHelpers('datetime').setValue(Utils.formatDateTime(item?.datetime, "YYYY-MM-DDTHH:mm"));
                                                                        console.log({ item });
                                                                    }}
                                                                />
                                                                : <img
                                                                    src={AppIcons.icCheck}
                                                                    draggable={false}
                                                                    className='mr-5 cursor-pointer hover-opacity-70'
                                                                    onClick={() => {
                                                                        formik.handleSubmit()
                                                                        // setIsEditedId();
                                                                    }} />
                                                            }
                                                            <img
                                                                src={AppIcons.icSquareXMark}
                                                                draggable={false}
                                                                className='cursor-pointer hover-opacity-70'
                                                                onClick={() => {
                                                                    setModalConfirmDeleteFilter(true);
                                                                    setMyListStockIdDeleted(item.myListStockId)
                                                                    handleClose();
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
            <ModalConfirmDelete
                show={modalConfirmDeleteFilter}
                onClose={() => {
                    setModalConfirmDeleteFilter(false);
                    handleShow();
                }}
                deleteItem={() => {
                    dispatch(thunkRemoveStock(myListStockIdDeleted));
                    setMyListStockIdDeleted();
                    handleShow();
                }}
                deleteText={t('ConfirmDeleteTransaction')}
                onShow={() => { setModalConfirmDeleteFilter(true) }}
            />
        </div>
    );
}

export default ModalEditTransaction;