import planApi from "../../api/planApi";
import bankAccountApi from "../../api/bankAccountApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
export const thunkGetPlanList = createAsyncThunk('plan/all', async (params, thunkAPI) => {
    const res = await planApi.getPlanList(params)
    return res;
});

export const thunkGetBankAccountList = createAsyncThunk('bankAccount/all', async (params, thunkAPI) => {
    const res = await bankAccountApi.getList(params)
    return res;
});


const courseSlice = createSlice({
    name: 'payment',
    initialState: {
        plan: {
            isGettingPlanList: false,
            pagination: { perPage: 10, hasMore: true },
            list: []
        },
        bankAccount: {
            isGettingBankAccountList: false,
            pagination: { perPage: 10, hasMore: true },
            list: []
        }
    },
    reducers: {},
    extraReducers: {
        [thunkGetPlanList.fulfilled]: (state, action) => {
            state.plan.isGettingPlanList = false
            const { result, plans, total, count, page } = action.payload;
            if (result === 'success') {
                state.plan.list = plans
                state.plan.pagination = {
                    ...state.pagination,
                    total: total,
                    hasMore: (total <= count + page * 8) ? false : true,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },
        [thunkGetBankAccountList.fulfilled]: (state, action) => {
            state.bankAccount.isGettingBankAccountList = false
            const { result, bankAccounts, total, count, page } = action.payload;
            if (result === 'success') {
                state.bankAccount.list = bankAccounts
                state.bankAccount.pagination = {
                    ...state.pagination,
                    total: total,
                    hasMore: (total <= count + page * 8) ? false : true,
                    count: count,
                    currentPage: page + 1,
                };
            }
        }
    },
});

const { reducer, actions } = courseSlice;
export const { } = actions;
export default reducer;
