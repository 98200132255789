import AppTabs from 'general/components/AppTabs';
import CheckBoxNoFormik from 'general/components/Forms/FormControls/CheckBoxNoFormik';
import TextFieldNoFormik from 'general/components/Forms/FormControls/TextFieldNoFormik';
import MGDataTable from 'general/components/Tables/MGDataTable';
import EmptyView from 'general/components/Views/EmptyView';
import AppData from 'general/constants/AppData';
import { AppIcons } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import { useWindowSize } from 'hooks/useWindowSize';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import './index.scss';

const currentYear = moment().year();

FinancialAnalysis.propTypes = {
    reports: PropTypes.object,
    reportNorms: PropTypes.object,
    financeIndexes: PropTypes.object,
    financeIndexNorms: PropTypes.object,
    onFilter: PropTypes.func,
    reportYears: PropTypes.array.isRequired,
    reportTermCodes: PropTypes.array.isRequired,
};

FinancialAnalysis.defaultProps = {
    reports: {},
    reportNorms: {},
    financeIndexes: {},
    financeIndexNorms: {},
    onFilter: null,
};


function FinancialAnalysis(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { reports, reportNorms, financeIndexNorms, financeIndexes, onFilter, reportYears, reportTermCodes } = props;
    // console.log({ financeIndexes, financeIndexNorms });
    // console.log({ reportTermCodes });
    // const reportKeys = Object.keys(reports);
    const reportKeys = Object.keys(reportNorms);
    const financeIndexKeys = Object.keys(financeIndexes);
    // console.log({ reportKeys, financeIndexKeys });
    let objReportTerms = {};
    AppData.reportTermItems.forEach(item => {
        objReportTerms[item.value] = true;
    });
    const [reportTerms, setReportTerms] = useState(() => {
        let terms = {
            'Q1': false,
            'Q2': false,
            'Q3': false,
            'Q4': false,
            'N': false
        }
        reportTermCodes.forEach(element => {
            terms[element] = true;
        });

        return terms;
    });
    const [yearInvalid, setYearInvalid] = useState(false);
    const arrYearPeriods = useRef(reportYears);

    const [stickyBarTop, setStickyBarTop] = useState(undefined);
    const stickyBarWidth = useRef(undefined);
    const stickyBarHeight = useRef(0);
    const tableContentWidth = useRef(0);
    const appHeaderHeight = useRef(0);
    const cardHeaderHeight = useRef(0);

    const windowSize = useWindowSize();
    const tabs = [
        // t('FinancialReport'),
        t('BusinessResults'),
        t('BalanceSheet'),
        t('CashFlow'),
        t('FinancialIndex')
    ];
    const [selectedTab, setSelectedTab] = useState(t('BusinessResults'));

    // MARK: --- Functions ---
    function handlePressFilter() {
        if (!yearInvalid) {
            const arrTermKeys = Object.keys(reportTerms);
            const arrTerms = [];
            arrTermKeys.forEach(element => {
                if (reportTerms[element]) {
                    arrTerms.push(element);
                }
            });

            // console.log({ arrYearPeriods, arrTerms });
            if (onFilter) {
                onFilter(arrYearPeriods.current, arrTerms);
            }
        }
    }

    function handleSelectedTab(tab) {
        setSelectedTab(tab);
    }

    function onSticky(e) {
        const stickyEl = document.getElementById('sticky-bar-filter');
        if (stickyEl) {
            const cardBodyContentEl = document.getElementById('card-body-content');
            const scrollTop = window.scrollY;
            // console.log(scrollTop);
            // console.log(cardHeaderHeight.current, appHeaderHeight.current);
            if (scrollTop >= 380) {
                stickyEl.classList.add('q-is-sticky');
                stickyEl.style.width = `${tableContentWidth.current}px`;
                stickyEl.style.top = `${appHeaderHeight.current}px`;
                // console.log(stickyBarHeight.current);
                if (cardBodyContentEl) {
                    cardBodyContentEl.style.paddingTop = `${stickyBarHeight.current}px`
                }
            } else {
                stickyEl.classList.remove('q-is-sticky');
                if (cardBodyContentEl) {
                    cardBodyContentEl.style.paddingTop = '0px'
                }
            }
        }
    }

    // MARK: --- Hooks ---
    // useEffect(() => {
    //     const stickyEl = document.getElementById('sticky-bar-filter');
    //     if (stickyEl) {
    //         setStickyBarTop(stickyEl.getBoundingClientRect().top);
    //         stickyBarWidth.current = tableContentWidth.current;
    //         stickyBarHeight.current = stickyEl.offsetHeight;
    //         // console.log(stickyBarHeight.current);
    //     }
    //     const stockInfoCardHeaderEl = document.getElementById('stock-info-card-header');
    //     if (stockInfoCardHeaderEl) {
    //         cardHeaderHeight.current = stockInfoCardHeaderEl.offsetHeight;
    //     }
    //     const appHeaderEl = document.getElementById('app-header');
    //     if (appHeaderEl) {
    //         appHeaderHeight.current = appHeaderEl.offsetHeight;
    //     }

    //     const tableContentEl = document.getElementById('table-content');
    //     if (tableContentEl) {
    //         tableContentWidth.current = tableContentEl.offsetWidth;
    //         stickyEl.style.width = `${tableContentWidth.current}px`;
    //     }
    // }, [windowSize]);

    // useEffect(() => {
    //     if (!stickyBarTop) return;

    //     window.addEventListener('scroll', onSticky);

    //     return () => {
    //         window.removeEventListener('scroll', onSticky);
    //     };
    // }, [stickyBarTop]);

    function handleScrollLeft() {
        document.getElementById('container').scrollLeft -= 40;
    };

    function handleScrollRight() {
        document.getElementById('container').scrollLeft += 40;
    };

    return (
        <div className='w-100 p-4'>
            {/* filter */}
            <div className='p-4 border bg-white mb-4 d-flex align-items-center justify-content-between'>
                <span className='font-weight-bolder font-size-lg mr-4'>{t('Filter')}</span>
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-wrap'>
                        <div className='mr-6'>
                            <TextFieldNoFormik
                                label={t('Year')}
                                subText={t('SeparateYearsWithCommas')}
                                name='year'
                                placeholder='xxxx,xxxx...'
                                value={arrYearPeriods.current.join(', ')}
                                className='m-0'
                                isError={yearInvalid}
                                onSubmit={(text) => {
                                    let isValid = true;
                                    let arrTextYears = text.split(',');
                                    if (arrTextYears && arrTextYears.length > 0) {
                                        arrTextYears = arrTextYears.filter(item => item.length > 0);
                                        arrTextYears.forEach(element => {
                                            if (isNaN(element) || parseInt(element) < 1000 || parseInt(element) > 9999) {
                                                isValid = false;
                                            }
                                        });
                                        setYearInvalid(!isValid);
                                        if (isValid) {
                                            arrTextYears = arrTextYears.map(item => item.trim());
                                            arrYearPeriods.current = arrTextYears;
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <p className='m-0 mb-2'>{t('Term')}</p>
                            <div className='d-flex align-items-center flex-wrap' style={{
                                height: '38px'
                            }}>
                                {
                                    AppData.reportTermItems.map((item, index) => {
                                        return (
                                            <div key={index} className='mr-6'>
                                                <CheckBoxNoFormik
                                                    label={item.text}
                                                    additionClassName='m-0'
                                                    name={item.value}
                                                    value={reportTerms[item.value]}
                                                    onChange={(name) => {
                                                        const temp = { ...reportTerms };
                                                        temp[name] = !reportTerms[name];
                                                        setReportTerms(temp);
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type='button' className='btn btn-primary mb-0 font-weight-bold' onClick={handlePressFilter}>{t('Filter')}</button>
                    </div>
                </div>
            </div>

            <div>
                <div className='d-flex flex-row Valuation_Scroll_Button'>
                    <button
                        className='AppCardDataInfor_Button rounded border-0 d-flex align-items-center justify-content-center mr-2'
                        onClick={handleScrollLeft}
                    >
                        <i className="fas fa-caret-left m-2"></i>
                    </button>
                    <button
                        className='AppCardDataInfor_Button rounded border-0 d-flex align-items-center justify-content-center'
                        onClick={handleScrollRight}
                    >
                        <i className="fas fa-caret-right m-2"></i>
                    </button>
                </div>
                <AppTabs
                    tabs={tabs.reverse()}
                    activeTab={selectedTab}
                    handleClick={handleSelectedTab}
                />
                <div className='max-h-800px bg-white border-left border-right' id='container' style={{ overflowX: 'scroll', overflowY: 'auto' }}>
                    {
                        selectedTab === t('FinancialReport') ? (
                            // Bao cao tai chinh
                            <div>
                                {
                                    !Utils.isObjectEmpty(reports) ? (
                                        <div className=''>
                                            {
                                                reportKeys.map((item, index) => {
                                                    let reportValues = reports[item] ?? [];
                                                    // console.log({ reportValues });
                                                    const dReportValueTermCodes = reportValues.map(item => `${item.yearPeriod}/${item.description}`);
                                                    let reportValueTermCodes = Utils.removeDuplicateArray(dReportValueTermCodes);
                                                    reportValueTermCodes.sort();
                                                    // console.log({ reportValueTermCodes });

                                                    let arrValues = [];
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod } = element;
                                                        let bExisted = false;
                                                        arrValues.forEach(el => {
                                                            if (el.reportTermCode === reportTermCode && el.yearPeriod === yearPeriod && el.name === name) {
                                                                bExisted = true;
                                                            }
                                                        })
                                                        if (!bExisted) {
                                                            arrValues.push(element);
                                                        }
                                                    });
                                                    reportValues = arrValues;
                                                    // console.log({ reportValues });

                                                    let groupedReportValues = {};
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod, description } = element;
                                                        if (Utils.isObjectNull(groupedReportValues[name])) {
                                                            groupedReportValues[name] = [];
                                                        }
                                                        groupedReportValues[name].push({ value: value, name: `${description}/${yearPeriod}` });
                                                    });
                                                    const keysGroupedReportValues = reportNorms[item];
                                                    // const keysGroupedReportValues = Object.keys(groupedReportValues);
                                                    // console.log({ keysGroupedReportValues, groupedReportValues });

                                                    return (
                                                        <div key={index} className='' id='table-content'>
                                                            <MGDataTable
                                                                toggleHead={true}
                                                                key={index}
                                                                className={`bg-white ${index < reportKeys.length - 1 && 'border-bottom-0 border-top-0'}`}
                                                                headItems={[
                                                                    { title: item, subTitle: '', col: 'col-3' },
                                                                    ...reportValueTermCodes.map(item => {
                                                                        return ({ title: item.split('/').reverse().join('/'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' })
                                                                    })
                                                                ]}
                                                                dataItems={
                                                                    keysGroupedReportValues.map((subItem, subIndex) => {
                                                                        const valuesGroupedReportValues = groupedReportValues[subItem?.name] ?? [];
                                                                        // console.log({ valuesGroupedReportValues, subItem });
                                                                        return ({
                                                                            onPress: () => { },
                                                                            data: [
                                                                                { icon: '', title: subItem?.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                                                                ...reportValueTermCodes.map((item, index) => {
                                                                                    const filtered = valuesGroupedReportValues.filter(i => i.name === item.split('/').reverse().join('/'));
                                                                                    const value = ((filtered && filtered.length > 0) ? filtered[0] : { value: 0 }).value;
                                                                                    // console.log({ valuesGroupedReportValues, item, filtered, value });
                                                                                    return ({ icon: '', title: '', subTitle: '', name: /*Utils.qFormatNumber(value)*/Utils.formatNumber(value / 1000), value: '', valueColor: '', col: 'col-1', textAlign: 'right' })
                                                                                })
                                                                            ]
                                                                        })
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white border-left border-right border-bottom'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ) : selectedTab === t('FinancialIndex') ? (
                            // Chi so tai chinh
                            <div>
                                {
                                    !Utils.isObjectEmpty(financeIndexes) ? (
                                        <div className=''>
                                            {
                                                financeIndexKeys.map((item, index) => {
                                                    let financeIndexValues = financeIndexes[item] ?? [];
                                                    // console.log({ financeIndexValues });
                                                    const dFinanceIndexValueTermCodes = financeIndexValues.map(item => `${(_.find(AppData.reportTermItems, { 'value': item.termCode }))?.text}/${item.yearPeriod}`);
                                                    const financeIndexValueTermCodes = Utils.removeDuplicateArray(dFinanceIndexValueTermCodes);
                                                    // console.log({ financeIndexValueTermCodes });

                                                    let arrValues = [];
                                                    financeIndexValues.forEach(element => {
                                                        const { financeIndexId, financeIndexName, indexValue, termCode, yearPeriod, unit, unitEn } = element;
                                                        let bExisted = false;
                                                        arrValues.forEach(el => {
                                                            if (el.termCode === termCode && el.yearPeriod === yearPeriod && el.financeIndexName === financeIndexName) {
                                                                bExisted = true;
                                                            }
                                                        })
                                                        if (!bExisted) {
                                                            arrValues.push(element);
                                                        }
                                                    });
                                                    financeIndexValues = arrValues;
                                                    // console.log({ financeIndexValues });

                                                    let groupedFinanceIndexValues = {};
                                                    financeIndexValues.forEach(element => {
                                                        const { financeIndexId, financeIndexName, indexValue, unit, unitEn, termCode, yearPeriod } = element;
                                                        if (Utils.isObjectNull(groupedFinanceIndexValues[financeIndexName])) {
                                                            groupedFinanceIndexValues[financeIndexName] = [];
                                                        }
                                                        groupedFinanceIndexValues[financeIndexName].push({ value: indexValue, unit: unit, name: `${(_.find(AppData.reportTermItems, { 'value': termCode }))?.text}/${yearPeriod}` });
                                                    });
                                                    const keysGroupedFinanceIndexValues = financeIndexNorms[item] ?? [];
                                                    // console.log({ keysGroupedFinanceIndexValues });

                                                    return (
                                                        <div key={index} className='' id='table-content'>
                                                            <MGDataTable
                                                                nonBorder={true}
                                                                tableId='FinancialIndex'
                                                                overflowX={true}
                                                                toggleHead={true}
                                                                key={index}
                                                                className={` ${index < financeIndexKeys.length - 1 && 'border-bottom-0 border-top-0'}`}
                                                                headItems={[
                                                                    { title: item, subTitle: '', col: 'col-3' },
                                                                    ...financeIndexValueTermCodes.map(item => {
                                                                        return ({ title: item, subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' })
                                                                    })
                                                                ]}
                                                                dataItems={
                                                                    keysGroupedFinanceIndexValues.map((subItem, subIndex) => {
                                                                        const valuesGroupedFinanceIndexValues = groupedFinanceIndexValues[subItem?.financeIndexName] ?? [];
                                                                        // console.log({ valuesGroupedFinanceIndexValues, subItem });
                                                                        return ({
                                                                            onPress: () => { },
                                                                            data: [
                                                                                { icon: '', title: subItem?.financeIndexName, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                                                                ...financeIndexValueTermCodes.map((item, index) => {
                                                                                    const filtered = valuesGroupedFinanceIndexValues.filter(i => i.name === item);
                                                                                    const value = ((filtered && filtered.length > 0) ? filtered[0] : { value: 0 }).value;
                                                                                    const unit = ((filtered && filtered.length > 0) ? filtered[0] : { unit: '' }).unit;
                                                                                    // console.log({ valuesGroupedReportValues, item, filtered, value });
                                                                                    return ({ icon: '', title: '', subTitle: '', name: /*Utils.qFormatNumber(value)*/Utils.formatNumber((value / 1000).toFixed(0)) + ' ' + unit, value: '', valueColor: '', col: 'col-1', textAlign: 'right' })
                                                                                })
                                                                            ]
                                                                        })
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ) : selectedTab === t('BusinessResults') ? (
                            // Ket qua kinh doanh
                            <div>
                                {
                                    !Utils.isObjectEmpty(reports) && reportKeys.includes(t('BusinessResults')) ? (
                                        <div className=''>
                                            {
                                                reportKeys.map((item, index) => {
                                                    if (item !== t('BusinessResults')) {
                                                        return <></>
                                                    }
                                                    let reportValues = reports[item] ?? [];
                                                    // console.log({ reportValues });
                                                    const dReportValueTermCodes = reportValues.map(item => `${item.yearPeriod}/${item.description}`);
                                                    let reportValueTermCodes = Utils.removeDuplicateArray(dReportValueTermCodes);
                                                    reportValueTermCodes.sort();
                                                    // console.log({ reportValueTermCodes });

                                                    let arrValues = [];
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod } = element;
                                                        let bExisted = false;
                                                        arrValues.forEach(el => {
                                                            if (el.reportTermCode === reportTermCode && el.yearPeriod === yearPeriod && el.name === name) {
                                                                bExisted = true;
                                                            }
                                                        })
                                                        if (!bExisted) {
                                                            arrValues.push(element);
                                                        }
                                                    });
                                                    reportValues = arrValues;
                                                    // console.log({ reportValues });

                                                    let groupedReportValues = {};
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod, description } = element;
                                                        if (Utils.isObjectNull(groupedReportValues[name])) {
                                                            groupedReportValues[name] = [];
                                                        }
                                                        groupedReportValues[name].push({ value: value, name: `${description}/${yearPeriod}` });
                                                    });
                                                    const keysGroupedReportValues = reportNorms[item];
                                                    // const keysGroupedReportValues = Object.keys(groupedReportValues);
                                                    // console.log({ keysGroupedReportValues, groupedReportValues });

                                                    return (
                                                        <div key={index} className='' id='table-content' >
                                                            <MGDataTable
                                                                nonBorder={true}
                                                                tableId='BusinessResults'
                                                                overflowX={true}
                                                                toggleHead={true}
                                                                key={index}
                                                                className={` ${index < reportKeys.length - 1 && 'border-bottom-0 border-top-0'}`}
                                                                headItems={[
                                                                    { title: item, subTitle: '', col: 'col-3' },
                                                                    ...reportValueTermCodes.map(item => {
                                                                        return ({ title: item.split('/').reverse().join('/'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' })
                                                                    })
                                                                ]}
                                                                dataItems={
                                                                    keysGroupedReportValues.map((subItem, subIndex) => {
                                                                        const valuesGroupedReportValues = groupedReportValues[subItem?.name] ?? [];
                                                                        // console.log({ valuesGroupedReportValues, subItem });
                                                                        return ({
                                                                            onPress: () => { },
                                                                            data: [
                                                                                { icon: '', title: subItem?.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                                                                ...reportValueTermCodes.map((item, index) => {
                                                                                    const filtered = valuesGroupedReportValues.filter(i => i.name === item.split('/').reverse().join('/'));
                                                                                    const value = ((filtered && filtered.length > 0) ? filtered[0] : { value: 0 }).value;
                                                                                    // console.log({ valuesGroupedReportValues, item, filtered, value });
                                                                                    return ({ icon: '', title: '', subTitle: '', name: /*Utils.qFormatNumber(value)*/Utils.formatNumber((value / 1000).toFixed(0)), value: '', valueColor: '', col: 'col-1', textAlign: 'right' })
                                                                                })
                                                                            ]
                                                                        })
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ) : selectedTab === t('BalanceSheet') ? (
                            // Can doi ke toan
                            <div>
                                {
                                    !Utils.isObjectEmpty(reports) && reportKeys.includes(t('BalanceSheet')) ? (
                                        <div className=''>
                                            {
                                                reportKeys.map((item, index) => {
                                                    if (item !== t('BalanceSheet')) {
                                                        return <></>
                                                    }
                                                    let reportValues = reports[item] ?? [];
                                                    // console.log({ reportValues });
                                                    const dReportValueTermCodes = reportValues.map(item => `${item.yearPeriod}/${item.description}`);
                                                    let reportValueTermCodes = Utils.removeDuplicateArray(dReportValueTermCodes);
                                                    reportValueTermCodes.sort();
                                                    // console.log({ reportValueTermCodes });

                                                    let arrValues = [];
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod } = element;
                                                        let bExisted = false;
                                                        arrValues.forEach(el => {
                                                            if (el.reportTermCode === reportTermCode && el.yearPeriod === yearPeriod && el.name === name) {
                                                                bExisted = true;
                                                            }
                                                        })
                                                        if (!bExisted) {
                                                            arrValues.push(element);
                                                        }
                                                    });
                                                    reportValues = arrValues;
                                                    // console.log({ reportValues });

                                                    let groupedReportValues = {};
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod, description } = element;
                                                        if (Utils.isObjectNull(groupedReportValues[name])) {
                                                            groupedReportValues[name] = [];
                                                        }
                                                        groupedReportValues[name].push({ value: value, name: `${description}/${yearPeriod}` });
                                                    });
                                                    const keysGroupedReportValues = reportNorms[item];
                                                    // const keysGroupedReportValues = Object.keys(groupedReportValues);
                                                    // console.log({ keysGroupedReportValues, groupedReportValues });

                                                    return (
                                                        <div key={index} className='' id='table-content'>
                                                            <MGDataTable
                                                                nonBorder={true}
                                                                tableId='BalanceSheet'
                                                                overflowX={true}
                                                                toggleHead={true}
                                                                key={index}
                                                                className={` ${index < reportKeys.length - 1 && 'border-bottom-0 border-top-0'}`}
                                                                headItems={[
                                                                    { title: item, subTitle: '', col: 'col-3' },
                                                                    ...reportValueTermCodes.map(item => {
                                                                        return ({ title: item.split('/').reverse().join('/'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' })
                                                                    })
                                                                ]}
                                                                dataItems={
                                                                    keysGroupedReportValues.map((subItem, subIndex) => {
                                                                        const valuesGroupedReportValues = groupedReportValues[subItem?.name] ?? [];
                                                                        // console.log({ valuesGroupedReportValues, subItem });
                                                                        return ({
                                                                            onPress: () => { },
                                                                            data: [
                                                                                { icon: '', title: subItem?.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                                                                ...reportValueTermCodes.map((item, index) => {
                                                                                    const filtered = valuesGroupedReportValues.filter(i => i.name === item.split('/').reverse().join('/'));
                                                                                    const value = ((filtered && filtered.length > 0) ? filtered[0] : { value: 0 }).value;
                                                                                    // console.log({ valuesGroupedReportValues, item, filtered, value });
                                                                                    return ({ icon: '', title: '', subTitle: '', name: /*Utils.qFormatNumber(value)*/Utils.formatNumber((value / 1000).toFixed(0)), value: '', valueColor: '', col: 'col-1', textAlign: 'right' })
                                                                                })
                                                                            ]
                                                                        })
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ) : selectedTab === t('CashFlow') ? (
                            // Luu chuyen dong tien
                            <div>
                                {
                                    !Utils.isObjectEmpty(reports) && reportKeys.includes(t('CashFlow')) ? (
                                        <div className=''>
                                            {
                                                reportKeys.map((item, index) => {
                                                    if (item !== t('CashFlow')) {
                                                        return <></>
                                                    }
                                                    let reportValues = reports[item] ?? [];
                                                    // console.log({ reportValues });
                                                    const dReportValueTermCodes = reportValues.map(item => `${item.yearPeriod}/${item.description}`);
                                                    let reportValueTermCodes = Utils.removeDuplicateArray(dReportValueTermCodes);
                                                    reportValueTermCodes.sort();
                                                    // console.log({ reportValueTermCodes });

                                                    let arrValues = [];
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod } = element;
                                                        let bExisted = false;
                                                        arrValues.forEach(el => {
                                                            if (el.reportTermCode === reportTermCode && el.yearPeriod === yearPeriod && el.name === name) {
                                                                bExisted = true;
                                                            }
                                                        })
                                                        if (!bExisted) {
                                                            arrValues.push(element);
                                                        }
                                                    });
                                                    reportValues = arrValues;
                                                    // console.log({ reportValues });

                                                    let groupedReportValues = {};
                                                    reportValues.forEach(element => {
                                                        const { name, value, reportTermCode, yearPeriod, description } = element;
                                                        if (Utils.isObjectNull(groupedReportValues[name])) {
                                                            groupedReportValues[name] = [];
                                                        }
                                                        groupedReportValues[name].push({ value: value, name: `${description}/${yearPeriod}` });
                                                    });
                                                    const keysGroupedReportValues = reportNorms[item];
                                                    // const keysGroupedReportValues = Object.keys(groupedReportValues);
                                                    // console.log({ keysGroupedReportValues, groupedReportValues });

                                                    return (
                                                        <div key={index} className='' id='table-content'>
                                                            <MGDataTable
                                                                nonBorder={true}
                                                                tableId='CashFlow'
                                                                overflowX={true}
                                                                toggleHead={true}
                                                                key={index}
                                                                className={` ${index < reportKeys.length - 1 && 'border-bottom-0 border-top-0'}`}
                                                                headItems={[
                                                                    { title: item, subTitle: '', col: 'col-3' },
                                                                    ...reportValueTermCodes.map(item => {
                                                                        return ({ title: item.split('/').reverse().join('/'), subTitle: 'x1000 VND', col: 'col-1', textAlign: 'right' })
                                                                    })
                                                                ]}
                                                                dataItems={
                                                                    keysGroupedReportValues.map((subItem, subIndex) => {
                                                                        const valuesGroupedReportValues = groupedReportValues[subItem?.name] ?? [];
                                                                        // console.log({ valuesGroupedReportValues, subItem });
                                                                        return ({
                                                                            onPress: () => { },
                                                                            data: [
                                                                                { icon: '', title: subItem?.name, subTitle: '', name: '', value: '', valueColor: '', col: 'col-3' },
                                                                                ...reportValueTermCodes.map((item, index) => {
                                                                                    const filtered = valuesGroupedReportValues.filter(i => i.name === item.split('/').reverse().join('/'));
                                                                                    const value = ((filtered && filtered.length > 0) ? filtered[0] : { value: 0 }).value;
                                                                                    // console.log({ valuesGroupedReportValues, item, filtered, value });
                                                                                    return ({ icon: '', title: '', subTitle: '', name: /*Utils.qFormatNumber(value)*/Utils.formatNumber((value / 1000).toFixed(0)), value: '', valueColor: '', col: 'col-1', textAlign: 'right' })
                                                                                })
                                                                            ]
                                                                        })
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <div className='w-100 d-flex align-items-center justify-content-center py-10 bg-white'>
                                            <EmptyView
                                                description={t('NoDataToDisplay')}
                                                iconEmpty={AppIcons.icEmptyPack}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        ) : (
                            <></>
                        )
                    }

                </div>
            </div>
        </div>
    );
}

export default FinancialAnalysis;