import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppIcons } from 'general/constants/AppResource';
import { useHistory } from 'react-router-dom';
import Utils from 'general/utils/Utils';

CellMGProductGrid.propTypes = {
    className: PropTypes.string,
    item: PropTypes.object,
};

CellMGProductGrid.defaultProps = {
    className: '',
    item: {},
};

function CellMGProductGrid(props) {
    // MARK: --- Params ---
    const { className, item } = props;
    const { name, thumbnail, url, description } = item;
    const history = useHistory();

    // MARK: --- Functions ---
    function handlePress(e) {
        e.preventDefault();
        if (url) {
            Utils.openInNewTab(url);
        }
    }

    return (
        <a
            href='#'
            className={`CellMGProductGrid d-flex flex-column align-items-center justify-content-center w-100 h-160px hover-opacity-40 ${className}`}
            onClick={handlePress}
        >
            <img
                className='CellMGProductGrid_Icon w-70px'
                draggable={false}
                src={thumbnail ? Utils.getFullUrl(thumbnail) : AppIcons.icMGProduct}
                alt='ic_mg_product'
                style={{
                    aspectRatio: '1/1'
                }}
                onError={(e) => {
                    e.target.src = AppIcons.icMGProduct;
                }}
            />
            <p className='text-white font-size-h6 font-weight-bolder m-0 CellMGProductGrid_Label mt-4 text-center q-max-line-1'>{name}</p>
        </a>
    );
}

export default CellMGProductGrid;