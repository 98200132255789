import Utils from 'general/utils/Utils';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SectionActionButton from '../LandingPageSectionAction/Button';
import './style.scss';

function LandingPageSectionIntro(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const playerRef = useRef(null);
    const loggedInUser = useSelector(state => state.auth.current);
    const history = useHistory();

    return (
        <div className='LandingPageSectionIntro py-20'>
            <div className='container-xxl'>
                <div className='row d-flex align-items-center'>
                    {/* Left */}
                    <div className='col-12 col-md-6'>
                        <p className='m-0 LandingPageSectionIntro_TextTitle'>Đầu tư thông thái để tiền sinh sôi nảy nở và kiến tạo cuộc sống thịnh vượng</p>
                        <p className='m-0 mt-4 LandingPageSectionIntro_TextContent'>Kênh hỗ trợ nhà đầu tư kiến thức và danh mục đầu tư với tỉ suất lợi nhuận vượt trội</p>
                        <div className='mt-4'>
                            {
                                (Utils.isObjectNull(loggedInUser) || Utils.isObjectEmpty(loggedInUser)) && (
                                    <button
                                        href="#"
                                        className="LandingPageSectionIntro_Button hover-opacity-60"
                                        style={{
                                            border: '1px solid transparent',
                                            backgroundColor: '#A635FF'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/sign-up');
                                        }}
                                    >
                                        {t('OpenAccount')}
                                    </button>
                                )
                            }

                            {/* <a
                                href="#"
                                className="btn btn-primary btn-lg text-hover-primary btn-outline-white ml-4 font-weight-bold"
                            >
                                {t('MoreInfo')}
                            </a> */}
                        </div>
                    </div>

                    {/* Right */}
                    <div className='col-12 col-md-6 mt-6 mt-md-0'>
                        <div className='rounded-lg overflow-hidden' style={{ aspectRatio: '16/10' }}>
                            <ReactPlayer
                                url={'https://www.youtube.com/watch?v=QtkRyPV2BXc'}
                                ref={playerRef}
                                width='100%'
                                height='100%'
                                controls={true}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* Action buttons */}
                <div className='px-6 mt-20 LandingPageSectionIntro_Action'>
                    <div className='row'>
                        <div className='col-12 col-md-4 d-flex align-items-end'>
                            <SectionActionButton
                                title={t('AccessPlatform')}
                                detail={t('ProfessionalTradingPlatform')}
                                icon='fad fa-terminal'
                                borderBottomColor='#F4B638'
                            />
                        </div>
                        <div className='col-12 col-md-4 d-flex align-items-end'>
                            <SectionActionButton
                                title={t('Reviews_Valuations')}
                                detail={t('StockAnalysis')}
                                icon='fad fa-analytics'
                                borderBottomColor='#2FE1A2'
                            />
                        </div>
                        <div className='col-12 col-md-4 d-flex align-items-end'>
                            <SectionActionButton
                                title={t('CashFlowTrend')}
                                detail={t('MarketAnalysis')}
                                icon='fad fa-coins'
                                borderBottomColor='#7621B1'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionIntro;