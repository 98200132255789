import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'general/components/AppLayout';
import './style.scss'
import { Markup } from 'interweave';
import terms from 'general/constants/Terms';
import Utils from 'general/utils/Utils';

TermsScreen.propTypes = {

};

const sTag = '[TermsScreen]';

function TermsScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);
        Utils.scrollToTop();

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        }
    }, []);

    // MARK: --- Functions ---

    return (
        <AppLayout>
            <div className='TermsScreen min-h-600px bg-white'>
                <div className='TermScreen_Header py-lg-30 py-12'>{t('Terms').toUpperCase()}</div>
                <div className='container-xxl py-20 font-size-lg text-justify'>
                    <Markup content={terms} />
                </div>
            </div>
        </AppLayout>
    );
}

export default TermsScreen;