import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppImages } from 'general/constants/AppResource';

LandingPageSectionInfo3Item.propTypes = {
    title: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]),
    icon: PropTypes.any,
    reverse: PropTypes.bool,
};

LandingPageSectionInfo3Item.defaultProps = {
    title: '',
    content: '',
    icon: AppImages.imgLandingPageChart01,
    reverse: false
};

function LandingPageSectionInfo3Item(props) {
    // MARK: --- Params ---
    const { title, content, icon, reverse } = props;

    return (
        <div className='LandingPageSectionInfo3Item'>
            <div className={`row d-flex ${reverse ? 'flex-row-reverse' : 'flex-row'}`}>
                {/* Left */}
                <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                    <p className='mt-sm-4 LandingPageSectionInfo3Item_Title text-left'>{title}</p>
                    <p className='m-0 text-justify font-size-h6'>{content}</p>
                </div>

                {/* Right */}
                <div className='col-12 col-md-5 d-flex align-items-center'>
                    <img alt='' src={icon} className='w-100' draggable={false} />
                </div>
            </div>
        </div>
    );
}

export default LandingPageSectionInfo3Item;