import PropTypes from 'prop-types';
import './style.scss';

AppCardDataInfo.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
    ]),
    content: PropTypes.element,
    rightComponent: PropTypes.element,
    bottomComponent: PropTypes.element,
    alignTitleItems: PropTypes.string,
    additionsClassName: PropTypes.string,
    showTitleDivider: PropTypes.bool,
    scrollXButton: PropTypes.bool,
    scrollRight: PropTypes.func,
    scrollLeft: PropTypes.func,
    border: PropTypes.bool,
};

AppCardDataInfo.defaultProps = {
    title: '',
    content: (<></>),
    rightComponent: (<></>),
    bottomComponent: null,
    alignTitleItems: 'center',
    additionsClassName: '',
    showTitleDivider: true,
    scrollXButton: false,
    border: true,

};

function AppCardDataInfo(props) {
    // MARK: --- Params ---
    const { title, content, rightComponent, bottomComponent, alignTitleItems, additionsClassName, showTitleDivider, scrollXButton, border } = props;

    function handleScrollLeft() {
        document.getElementById('content').scrollLeft -= 40;
    };

    function handleScrollRight() {
        document.getElementById('content').scrollLeft += 40;
    };

    return (
        <div className={`AppCardDataInfo bg-white ${border ? 'border' : ''} border-bottom-0 d-flex flex-column h-100 ${additionsClassName}`}>
            <div className={`${showTitleDivider && 'border-bottom'} py-3 px-5 row`} style={{
                justifyContent: "space-between",
                alignItems: alignTitleItems,
                marginLeft: "0px",
                marginRight: "0px"
            }}>
                <div className="m-0" style={{
                    fontSize: '1.08rem',
                    fontWeight: '600',
                    lineHeight: '1.85rem'
                }}>{title}</div>
                <div className='d-flex flex-row'>
                    {bottomComponent && <div className='flex-grow-1 mr-4'>
                        {bottomComponent}
                    </div>}
                    {rightComponent}
                </div>
                {scrollXButton
                    && <div className='d-flex flex-row'>
                        <button
                            className='AppCardDataInfor_Button rounded border-0 d-flex align-items-center justify-content-center mr-2'
                            onClick={handleScrollLeft}
                        >
                            <i className="fas fa-caret-left m-2"></i>
                        </button>
                        <button
                            className='AppCardDataInfor_Button rounded border-0 d-flex align-items-center justify-content-center'
                            onClick={handleScrollRight}
                        >
                            <i className="fas fa-caret-right m-2"></i>
                        </button>
                    </div>}
            </div>
            <div id='content' className='border-bottom flex-grow-1' style={{ overflowX: `${scrollXButton ? 'scroll' : 'hidden'}`, scrollBehavior: 'smooth' }}>
                {content}
            </div>
        </div>
    );
}

export default AppCardDataInfo;
