import axiosClient from "./axiosClient";

const accountApi = {
    // Update info
    updateInfo: (userId, params) => {
        const url = `/account/${userId}`;
        const formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
            formData.append(key, value);
        }
        return axiosClient.put(url, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // set referral code
    updateReferralCode: (code) => {
        const url = '/account/input_referral_code';
        return axiosClient.post(url, {
            referralCode: code
        });
    },

    // get transaction history
    getTransactionHistory: (params) => {
        const url = '/transaction/history';
        return axiosClient.get(url, { params });
    },

    // get my notifications
    getMyNotifications: (params) => {
        const url = '/myNotification';
        return axiosClient.get(url, { params });
    },

    // lay danh sach hoi thao cua toi
    getMyWebinars: (params = {}) => {
        const url = '/webinar/mine';
        return axiosClient.get(url, { params })
    },

    // gui yc reset mat khau
    requestResetPassword: (email) => {
        const url = '/account/requestResetPassword';
        return axiosClient.post(url, {
            email: email,
        });
    },

    // reset password
    resetPassword: (params) => {
        const url = '/account/resetPassword';
        return axiosClient.post(url, params);
    }


};

export default accountApi;
