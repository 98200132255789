import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

ModalTradingView.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    symbol: PropTypes.string,
};

ModalTradingView.defaultProps = {
    show: false,
    onClose: null,
    symbol: '',
};

function ModalTradingView(props) {
    // MARK: --- Params ---
    const { show, onClose, symbol } = props;

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                centered
                onHide={() => {
                    handleClose();
                }}
                size='xl'
            >
                <Modal.Header>
                    <Modal.Title>
                        Trading View
                    </Modal.Title>

                    <div
                        className="btn btn-sm btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={handleClose}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='overflow-auto' style={{
                    maxHeight: '84vh'
                }}>
                    <AdvancedRealTimeChart theme="light" locale='vi_VN' symbol={symbol}></AdvancedRealTimeChart>
                    {/* <FundamentalData colorTheme="light" width="100%" symbol='UPCOM:A32' locale='vi_VN'></FundamentalData> */}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalTradingView;