import React from "react";
import PropTypes from "prop-types";
import { AppIcons } from "general/constants/AppResource";
import { AppImages } from "general/constants/AppResource";

import { useTranslation } from "react-i18next";
import "./style.scss";
import Utils from "../../../../general/utils/Utils";
import { useHistory } from "react-router-dom";
CellPlan.propTypes = {};

function CellPlan(props) {
  const { t } = useTranslation();
  const { cellPlanItem } = props;
  const { name, price, duration, currency, planId } = cellPlanItem;
  const description = cellPlanItem?.description ?? [];
  let product = {
    productId: planId,
    name: name,
    price: price,
    currency: currency,
    type: 'MEMBER',
    description: duration + " " + t("month"),
  }
  const history = useHistory();
  return (
    <div className="bg-white rounded-lg cellPlan w-100 border py-6 px-5 h-100">
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <img
            className="w-40px mb-3"
            draggable={false}
            src={AppIcons.icPriceTag}
            style={{
              aspectRatio: "1/1",
            }}
          />
          <div className={"d-flex flex-row"} style={{
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <p
              className="font-weight-bolder font-size-h4"
              style={{ color: "#0A033C" }}
            >
              {name}
            </p>
            <p className="font-weight-light font-size-md"
              style={{ color: "#0A033C", whiteSpace: 'nowrap' }}>
              {duration + " " + t("month")}
            </p>
          </div>
        </div>

        <div className="my-2">
          {description.map((item, index) => {
            return (
              <div key={index} className="h-10 d-flex mt-5">
                <img
                  className="h-100"
                  draggable={false}
                  style={{ aspectRatio: "1/1" }}
                  src={AppIcons.icConfirmTick}
                ></img>
                <p className="font-weight-bold font-size-base ml-2">
                  {item}
                </p>
              </div>
            );
          })}
          {/* {featureOff?.map((item, index) => {
          return (
            <div key={index} className="h-10 d-flex mt-5">
              <img
                className="h-100"
                draggable={false}
                style={{ aspectRatio: "1/1" }}
                src={AppIcons.icCloseTick}
              ></img>
              <p className="font-weight-bold font-size-base ml-2">
                {item}
              </p>
            </div>
          );
        })} */}
        </div>

        <div>
          <div className="mt-3 d-flex align-items-center ">
            <p
              className=" font-weight-boldest font-size-h2 text-uppercase"
              style={{ color: "#23262F" }}
            >
              {Utils.formatNumber(price) + " VNĐ"}
            </p>
          </div>
          <div
            className="h-40px bg-white rounded mt-2 buttonBuyNow text-center d-flex align-items-center justify-content-center"
            style={{
              border: "1px solid #A635FF",
            }}

            onClick={() => history.push({
              pathname: '/payment/purchase',
              state: product
            })}
          >
            <p
              className="font-weight-boldest font-size-lg line-height-md buyNowText"
              style={{ color: "#A635FF" }}
            >
              {t("BuyNow")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CellPlan;
