import React from 'react'
import './styles.css'
const LoadMore = ()=> {
    return (
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoadMore
