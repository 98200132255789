import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Utils from 'general/utils/Utils';
import UserHelper from 'general/helpers/UserHelper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppData from 'general/constants/AppData';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

let KTOffcanvas = require("assets/plugins/ktoffcanvas");

QuickUser.propTypes = {
    onOffcanvasReady: PropTypes.func,
};

QuickUser.defaultProps = {
    onOffcanvasReady: null,
};

function QuickUser(props) {
    // MARK: --- Params ---
    const { onOffcanvasReady } = props;
    const isSignedIn = UserHelper.checkAccessTokenValid();
    const loggedInUser = useSelector(state => state.auth.current);
    const { t } = useTranslation();
    const history = useHistory();
    const refOffcanvasUser = useRef(null);

    // MARK: --- Functions ---
    function handleNavigate(url) {
        refOffcanvasUser.current.hide();
        history.push(url);
    }

    function handleOffcanvasReady() {
        if (onOffcanvasReady) {
            onOffcanvasReady(refOffcanvasUser.current);
        }
    }

    function handleSignOut(e) {
        e.preventDefault();
        Swal.fire({
            title: t('SignOut'),
            text: t('DoYouReallyWantToSignOut'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('Yes'),
            cancelButtonText: t('Cancel'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'btn btn-danger font-weight-bolder',
                cancelButton: 'btn btn-success font-weight-bolder',
            },

        }).then(function (result) {
            if (result.value) {
                UserHelper.signOut();
                window.location.href = '/sign-in';
            }
        });
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (typeof KTOffcanvas !== "undefined") {
            let offCanvasObject = new KTOffcanvas("quick_user_aside", {
                baseClass: "offcanvas-mobile",
                overlay: true,
                //closeBy: 'kt_quick_user_close',
                toggleBy: {
                    target: 'mg_quick_user_toggle',
                }
            });
            refOffcanvasUser.current = offCanvasObject;
            handleOffcanvasReady();
        }
    }, []);

    return (
        <div className='QuickUser offcanvas-mobile offcanvas-mobile-right w-300px w-xl-325px bg-white px-6 pt-4 pb-10' id='quick_user_aside'>
            <div className='d-flex flex-column align-items-center py-2 pt-4'>
                <div className="btn btn-icon btn-bg-secondary btn-circle align-items-center overflow-hidden w-85px h-85px">
                    <img
                        alt="Avatar"
                        draggable={false}
                        src={Utils.getFullUrl(UserHelper.getAvatar(loggedInUser))}
                        className="h-100 w-100 cursor-pointer" style={{ objectFit: 'cover' }}
                        onError={(e) => {
                            e.target.src = UserHelper.getRandomAvatarUrl();
                        }}
                        onClick={() => {
                            handleNavigate('/account');
                        }}
                    />
                </div>
                <p className='mb-0 mt-4 font-size-lg font-weight-bolder text-center'>{UserHelper.getDisplayName(loggedInUser)}</p>
                <p className='mb-0 mt-2 text-primary font-weight-bold text-center'>{t('AccessLevel' + loggedInUser.accessLevel)}</p>
            </div>

            <div className="separator separator-dashed my-5"></div>

            <div className='navi navi-spacer-x-0 p-0'>
                {
                    AppData.accountMenuItems.map((item, index) => {
                        return (
                            <a
                                key={index}
                                href='#'
                                className='navi-item'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavigate(item.url);
                                }}
                            >
                                <div className='navi-link'>
                                    <div className='symbol symbol-40 bg-transparent mr-3'>
                                        <div className='symbol-label bg-transparent'>
                                            {/* icon */}
                                            <img
                                                className='w-100 h-100'
                                                draggable={false}
                                                src={item.icon}
                                                alt={item.title}
                                            />
                                        </div>
                                    </div>
                                    <div className='navi-text'>
                                        <div className='font-weight-bold'>{t(item.title)}</div>
                                        <div className='text-muted'>{t(item.description)}</div>
                                    </div>
                                </div>
                            </a>
                        )
                    })
                }

                <span className="navi-item mt-2 d-flex align-items-center justify-content-center">
                    <span className="navi-link">
                        <a
                            href="#"
                            className="btn btn-sm btn-secondary font-weight-bolder py-3 px-6"
                            onClick={handleSignOut}
                        >
                            {t('SignOut')}
                        </a>
                    </span>
                </span>
            </div>
        </div>
    );
}

export default QuickUser;