import accountApi from "api/accountApi";
import Global from "general/Global";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
export const thunkGetAllNotifications = createAsyncThunk('notification/getAll', async (params, thunkAPI) => {
    const res = await accountApi.getMyNotifications(params);
    return res;
});

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        isGetting: false,
        allNotifications: [],
        pagination: {},
        totalUnread: 0,
        reload: false,
    },
    reducers: {
        updatePagination: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                }
            }
        },

        setReload: (state, action) => {
            return {
                ...state,
                reload: action.payload,
            }
        }
    },
    extraReducers: {
        // Get all notifications
        [thunkGetAllNotifications.pending]: (state, action) => {
            state.isGetting = true;
        },
        [thunkGetAllNotifications.rejected]: (state, action) => {
            state.isGetting = false;
        },
        [thunkGetAllNotifications.fulfilled]: (state, action) => {
            state.isGetting = false;
            const { result, notifications, total, count, page } = action.payload;
            if (result === 'success' && notifications) {
                state.allNotifications = notifications;
                // console.log(action.payload);
                state.pagination = {
                    total: total,
                    count: count,
                    currentPage: page,
                }
            }
        },
    }
});

const { actions, reducer } = notificationSlice;
export const { updatePagination, setReload } = actions;
export default reducer;