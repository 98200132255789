import ListAlertModal from 'features/IdeaList/components/BuildYourScreen/ListAlertModal';
import { thunkGetListAlert } from 'features/IdeaList/IdeaListSlice';
import { thunkGetAllNotifications } from 'features/Notification/notificationSlice';
import EmptyView from 'general/components/Views/EmptyView';
import { AppIcons } from 'general/constants/AppResource';
import Global from 'general/Global';
import Utils from 'general/utils/Utils';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CellNotificationList from '../CellNotificationList';

let KTOffcanvas = require("assets/plugins/ktoffcanvas");

QuickNotification.propTypes = {
    onOffcanvasReady: PropTypes.func,
};

QuickNotification.defaultProps = {
    onOffcanvasReady: null,
};

function QuickNotification(props) {
    // MARK: --- Params ---
    const { onOffcanvasReady } = props;
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const { t } = useTranslation();
    const refOffcanvasNotification = useRef(null);
    const loggedInUser = useSelector(state => state.auth.current);
    const { isGetting, allNotifications, reload, pagination } = useSelector(state => state.notification);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        page: 0,
        limit: Global.gDefaultPagination,
    });
    const listAlert = useSelector(state => state?.ideaList?.mgAlert?.listAlert)
    const isLoggedIn = useSelector(state => state?.auth?.current)
    const [notificationFooterHeight, setNotificationFooterHeight] = useState(0);
    const windowSize = useWindowSize();

    // MARK: --- Functions ---
    function handleOffcanvasReady() {
        if (onOffcanvasReady) {
            onOffcanvasReady(refOffcanvasNotification.current);
        }
    }

    // handle scroll content
    function handleScroll(e) {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 20;
        if (bottom) {
            let nextPage = Utils.getNextPage(pagination);
            console.log({ nextPage });
            if (nextPage) {
                setFilter({
                    ...filter,
                    page: nextPage,
                });
            }
        }
    }

    function calculateHeight() {
        const notificationFooterEl = document.getElementById('notification-footer-id');
        const notificationHeaderEl = document.getElementById('notification-header-id');
        if (notificationFooterEl && notificationHeaderEl) {
            const notificationContentEl = document.getElementById('notification-content-id');
            if (notificationContentEl) {
                notificationContentEl.style.height = `calc(100vh - ${notificationFooterEl.offsetHeight + notificationHeaderEl.offsetHeight}px)`;
            }
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        if (typeof KTOffcanvas !== "undefined" && refOffcanvasNotification.current === null) {
            let offCanvasObject = new KTOffcanvas("quick_notification_aside", {
                baseClass: "offcanvas",
                overlay: true,
                toggleBy: {
                    target: 'notification-menu-handle',
                }
            });
            refOffcanvasNotification.current = offCanvasObject;
            handleOffcanvasReady();
        }

        const timeout = setTimeout(() => {
            calculateHeight();
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    useEffect(() => {
        calculateHeight();
    }, [windowSize]);

    useEffect(() => {
        if (allNotifications.length === 0) {
            dispatch(thunkGetAllNotifications(filter))
        }
    }, [filter]);

    useEffect(() => {
        if (listAlert.length === 0) {
            dispatch(thunkGetListAlert())
        }
    }, [isLoggedIn])

    return (
        <div className='offcanvas offcanvas-right bg-white pt-0 d-flex flex-column' id='quick_notification_aside'>
            {/* title */}
            <div className='d-flex align-items-center justify-content-between mb-0 p-5 border-bottom' id='notification-header-id'>
                <h4 className='font-weight-bold m-0'>
                    {t('Notification')}
                </h4>
                <a
                    href="#"
                    className="btn btn-xs btn-icon btn-light btn-hover-secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        refOffcanvasNotification.current.hide();
                    }}
                >
                    <i className="far fa-times" />
                </a>
            </div>

            <div className='d-flex flex-grow-1' id='notification-body-id'>
                {
                    (Utils.isObjectNull(loggedInUser) || Utils.isObjectEmpty(loggedInUser)) ? (
                        <div className='py-10 w-100 d-flex align-items-center justify-content-center'>
                            <EmptyView
                                iconEmpty={AppIcons.icCircleUser}
                                title={t('SignIn')}
                                description={t('NotificationNotLoggedInDescription')}
                                buttonText={t('SignIn')}
                                buttonIcon='far fa-sign-in'
                                onPressButton={() => {
                                    history.push('/sign-in');
                                }}
                            />
                        </div>
                    ) : (
                        <div className='d-flex flex-column flex-grow-1'>
                            <div className='d-flex flex-column h-100'>
                                <div id='notification-content-id' className='flex-grow-1 overflow-auto p-0' onScroll={handleScroll} style={{ overflowY: 'scroll' }}>
                                    {
                                        allNotifications.length > 0 && allNotifications.map((item, index) => {
                                            return (
                                                <CellNotificationList
                                                    key={index}
                                                    notificationItem={item}
                                                    unread={!Utils.checkNotificationRead(item.notificationId)}
                                                    showDivider={index < allNotifications.length - 1}
                                                    onPress={() => {
                                                        Utils.addReadNotificationId(item.notificationId);
                                                    }}
                                                />
                                            )
                                        })
                                    }

                                    {
                                        allNotifications.length === 0 && (
                                            <div className='w-100 d-flex h-100 align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                />
                                            </div>
                                        )
                                    }
                                </div>

                                {/* footer */}
                                <div className='bg-light border-top px-5 py-4 d-flex align-items-center' id='notification-footer-id'>
                                    <span className='text-center w-100'>
                                        Xin  hãy đảm bảo rằng bạn đã có sẵn các cảnh báo hiện hoạt động trong
                                        <a style={{ color: '#7621B1' }} href='#' className='font-weight-bolder ml-1' onClick={(e) => {
                                            e.preventDefault();
                                            ListAlertModal.show();
                                        }}>{t('NotificationCenter')}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div >
    );
}

export default QuickNotification;