import authApi from 'api/authApi';
import { updateAxiosAccessToken } from 'api/axiosClient';
import PreferenceKeys from 'general/constants/PreferenceKeys';

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Sign in
export const thunkSignIn = createAsyncThunk('auth/signIn', async (params, thunkAPI) => {
    const res = await authApi.signIn(params);
    return res;
});

// Sign in with facebook
export const thunkSignInWithFB = createAsyncThunk('auth/signInWithFB', async (fbAccessToken, thunkAPI) => {
    const res = await authApi.signInWithFb(fbAccessToken);
    return res;
});

// Sign in with google
export const thunkSignInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (googleAccessToken, thunkAPI) => {
    const res = await authApi.signInWithGoogle(googleAccessToken);
    return res;
});

// Get current user info
export const thunkGetCurrentUserInfo = createAsyncThunk('auth/getCurrentUserInfo', async (params, thunkAPI) => {
    const res = await authApi.getCurrentUserInfo(params);
    return res;
});

// Sign out
export const thunkSignOut = createAsyncThunk('auth/signOut', async function (params, thunkAPI) {
    const res = await authApi.signOut(params);
    return res;
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isSigningIn: false,
        current: {},

        resetPasswordExpirationDate: '',
    },
    reducers: {
        updateCurrentAccountInfo: (state, action) => {
            return {
                ...state,
                current: {
                    ...state.current,
                    ...action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Sign in
        [thunkSignIn.pending]: (state, action) => {
            state.isSigningIn = true;
        },
        [thunkSignIn.rejected]: (state, action) => {
            state.isSigningIn = false;
        },
        [thunkSignIn.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            const account = action.payload;
            if (account) {
                state.current = account;

                const { token, expirationDate } = account;
                if (token && expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessToken, token);
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, expirationDate);
                    updateAxiosAccessToken(token);
                }
            }
        },

        // Sign in with FB
        [thunkSignInWithFB.pending]: (state, action) => {
            state.isSigningIn = true;
        },
        [thunkSignInWithFB.rejected]: (state, action) => {
            state.isSigningIn = false;
        },
        [thunkSignInWithFB.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            const account = action.payload;
            if (account) {
                state.current = account;

                const { token, expirationDate } = account;
                if (token && expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessToken, token);
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, expirationDate);
                    updateAxiosAccessToken(token);
                }
            }
        },

        // Sign in with Google
        [thunkSignInWithGoogle.pending]: (state, action) => {
            state.isSigningIn = true;
        },
        [thunkSignInWithGoogle.rejected]: (state, action) => {
            state.isSigningIn = false;
        },
        [thunkSignInWithGoogle.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            const account = action.payload;
            if (account) {
                state.current = account;

                const { token, expirationDate } = account;
                if (token && expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessToken, token);
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, expirationDate);
                    updateAxiosAccessToken(token);
                }
            }
        },

        // Get current user info
        [thunkGetCurrentUserInfo.fulfilled]: (state, action) => {
            const account = action.payload;
            if (account) {
                state.current = account;

                const { token, expirationDate } = account;
                if (token && expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessToken, token);
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, expirationDate);
                    updateAxiosAccessToken(token);
                }
            }
        },

        // Sign out
        [thunkSignOut.fulfilled]: (state, action) => {
            localStorage.removeItem(PreferenceKeys.accessToken);
            localStorage.removeItem(PreferenceKeys.accessTokenExpired);
            const data = action.payload;
            if (data) {
                state.current = {};
            }
        },
    }
});

const { reducer, actions } = authSlice;
export const { updateCurrentAccountInfo } = actions;
export default reducer;