import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';
import AppData from 'general/constants/AppData';
import { AppImages } from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

CellBookGrid.propTypes = {
    item: PropTypes.object,
};

CellBookGrid.defaultProps = {
    item: {},
};

function CellBookGrid(props) {
    // MARK: --- Params ---
    const { item } = props;
    const { author, cover, createdAt, updatedAt, description, link, name, price, publisher, year } = item;
    const { t } = useTranslation();

    return (
        <div className='CellBookGrid cursor-pointer hover-opacity-60' onClick={() => {
            Utils.openInNewTab(link)
        }}>
            <img
                className='CellBookGrid_Image w-100 min-w-100 p-3 border rounded'
                alt='thumbnail'
                draggable={false}
                src={Utils.getFullUrl(cover)}
                style={{
                    aspectRatio: '3/4',
                    backgroundColor: '#f3f5f8',
                    objectFit: 'cover'
                }}
                onError={(e) => {
                    e.target.src = AppImages.imgEmptyBookCover;
                }}
            />
            <div className='mt-4'>
                <p className='CellBookGrid_Title font-size-lg font-weight-bold my-1 q-max-line-2' style={{ color: '#18214d' }}>{name}</p>
                {/* <p className='CellBookGrid_Author font-weight-bolder my-2 text-dark-50'>{publisher}</p> */}
                <p className='CellBookGrid_Price font-size-lg font-weight-normal m-0 q-max-line-2' style={{ color: '#68C57C' }}>{`${t('Price')}: ${Utils.formatNumber(price)} VNĐ`}</p>
            </div>
        </div>
    );
}

export default CellBookGrid;