import React from "react";
import PropTypes from "prop-types";
import AppLayout from "general/components/AppLayout";
import LandingPageSectionIntro from "features/Home/components/LandingPageSectionIntro";
import LandingPageSectionAction from "features/Home/components/LandingPageSectionAction";
import LandingPageSectionInfo1 from "features/Home/components/LandingPageSectionInfo1";
import LandingPageSectionInfo2 from "features/Home/components/LandingPageSectionInfo2";
import LandingPageSectionInfo3 from "features/Home/components/LandingPageSectionInfo3";
import LandingPageFeaturedReview from "features/Home/components/LandingPageFeaturedReview";
import LandingPageSectionInfo4 from "features/Home/components/LandingPageSectionInfo4";
import LandingPageFrequentlyQuestion from "features/Home/components/LandingPageFrequentlyQuestion";
import LandingPageSectionTrending from "features/Home/components/LandingPageSectionTrending";
import LandingPageSectionIntro2 from "features/Home/components/LandingPageSectionIntro2";
import HomeSectionUpDownTrend from "features/Home/components/HomeSectionUpDownTrend";

HomeLandingPageScreen.propTypes = {};

function HomeLandingPageScreen(props) {
  return (
    <AppLayout>
      <LandingPageSectionIntro />
      {/* <LandingPageSectionAction /> */}
      {/* <LandingPageSectionTrending /> */}
      <HomeSectionUpDownTrend />
      <LandingPageSectionIntro2 />
      <LandingPageSectionInfo1 />
      <LandingPageSectionInfo2 />
      <LandingPageSectionInfo3 />
      <LandingPageSectionInfo4 />
      <LandingPageFeaturedReview />
      <LandingPageFrequentlyQuestion />
    </AppLayout>
  );
}

export default HomeLandingPageScreen;
