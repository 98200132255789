import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { AppImages } from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import AppData from 'general/constants/AppData';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

CellArticleGrid.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
};

CellArticleGrid.defaultProps = {
    item: {},
    onPress: null,
};

function CellArticleGrid(props) {
    // MARK: --- Params ---
    const { item, onPress } = props;
    const history = useHistory();
    const { title, description, author, category, content, date, thumbnail, articleId } = item;
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function handleClick() {
        if (onPress) {
            onPress();
        } else {
            history.push(`/research/${articleId}`);
        }
    }

    return (
        <div
            className='CellArticleGrid cursor-pointer hover-opacity-60'
            onClick={handleClick}
        >
            <img
                className='CellArticleGrid_Image w-100 rounded border'
                alt='thumbnail'
                src={thumbnail ?? AppImages.imgDefaultThumbnail}
                style={{ objectFit: 'cover' }}
                onError={(e) => {
                    e.target.src = AppImages.imgDefaultThumbnail;
                }}
                draggable={false}
            />
            <div className='mt-4'>
                <p className='CellArticleGrid_Title font-size-lg font-weight-bolder m-0'>{title}</p>
                <p className='CellArticleGrid_Content font-size-lg m-0 q-max-line-3'>{description}</p>
                {
                    category && (
                        <p className='CellArticleGrid_Time font-weight-bold my-1'>{`${t('Category')}: `}<span className='font-size-lg font-weight-bolder text-dark-75'>{category.name}</span></p>
                    )
                }
                <p className='CellArticleGrid_Time font-weight-normal m-0'>
                    {`${Utils.formatDateTime(date, 'DD/MM/YYYY HH:mm')}`}
                    {
                        !_.isEmpty(author) && (
                            <span className='font-weight-bold text-dark-75'>{` | ${t('By')}: ${author}`}</span>
                        )
                    }
                </p>
            </div>
        </div>
    );
}

export default CellArticleGrid;