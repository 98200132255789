import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Accordion, Modal } from 'react-bootstrap';
import ToastHelper from 'general/helpers/ToastHelper';
import filterApi from 'api/filterApi';
import { useDispatch, useSelector } from 'react-redux';
import { thunkCreateNewFilter, thunkGetFilterDetail } from 'features/IdeaList/IdeaListSlice';



ModalCreateNewFilter.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSetfilterIdChoosen: PropTypes.func,
};

ModalCreateNewFilter.defaultProps = {
    show: false,
    onClose: null,
    onSetfilterIdChoosen: null,
};

export function ModalCreateNewFilter(props) {


    const { t } = useTranslation();
    const [filterName, setFilterName] = useState('');
    const [filterDescription, setFilterDescription] = useState('');
    const listFilters = useSelector(state => state?.ideaList?.listFilters)
    let conditionals = props?.conditionals
    const dispatch = useDispatch()

    function handleClose(){
        props.onHide();
    }

    function handleFilterNameChange(e){
        setFilterName(e.target.value)
    }

    function handleFilterDescriptionChange(e){
        setFilterDescription(e.target.value)
    }



    function handleCreateNewFilter(){
        dispatch(thunkCreateNewFilter({
            "filterName": filterName,
            "description": filterDescription,
            "conditionals": conditionals??null
        })).then(res=>{
            let {result, detail}= res.payload
            if(result=="success"){
                ToastHelper.showSuccess(t("CreateFilterSuccess"))
            }
        })

        setFilterName('');
        setFilterDescription('')
    }


    return (
        <div>
            <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <div>
                    {/* Modal Header */}
                    <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                        <span className='font-weight-bolder font-size-base ml-5'>{t('CreateNewFilter')}</span>
                        <div onClick={props.onHide} className='btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer mr-6'>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>

                    {/* Modal Body */}

                    <div className=' py-6 bg-light'>
                        <div className='mx-5'>
                            <label className='font-weight-bolder font-size-base form-label'>{t('NameOfFilter')}</label>
                            <input
                            type='text'
                            className='form-control rounded-0'
                            placeholder='Nhập tên bộ lọc muốn tạo'
                            value={filterName}
                            onChange={handleFilterNameChange}
                            />
                        </div>
                        <div className='mx-5 pt-6'>
                            <label className='font-weight-bolder font-size-base form-label'>{t('DescriptionOfFilter')}</label>
                            <textarea
                            type='text'
                            className='form-control rounded-0'
                            placeholder='Mô tả vào bộ lọc nếu muốn'
                            rows='6' value={filterDescription}
                            onChange={handleFilterDescriptionChange}
                            />
                        </div>
                    </div>

                    {/* modal footer */}

                    <div className='py-5 border-top d-flex flex-row justify-content-center'>
                        <button className='border-0 col-5 mx-5 btn-secondary rounded-sm py-3 font-weight-bolder font-size-base' onClick={() => {handleClose()}}>{t('Cancel')}</button>
                        <button
                        className='border-0 col-5 mx-5 btn-primary rounded-sm py-3 font-weight-bolder font-size-base'
                        onClick={() => {
                            if(filterName === ''){
                                ToastHelper.showError('Please enter your new Filter name');
                                return
                            }

                            for(let i = 0; i < listFilters.length ; i++){
                                if(listFilters[i].filterName === filterName){
                                    ToastHelper.showError(`${t('FilterNameIsExisted')}`)
                                    return
                                }
                            }
                            handleCreateNewFilter();
                            handleClose();

                        }}
                        >{t('Save')}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalCreateNewFilter;
