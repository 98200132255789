import NotFound from 'general/components/NotFound';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { thunkGetAllConditional, thunkGetAllMGFilter } from "./IdeaListSlice";
import IdeaListHomeScreen from './screens/IdeaListHomeScreen';

function IdeaList(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(thunkGetAllConditional())
        // dispatch(thunkGetListAlert())
        dispatch(thunkGetAllMGFilter())
    }, [])

    return (
        <Switch>
            <Route
                path={`${match.url}`}
                component={IdeaListHomeScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default IdeaList;
