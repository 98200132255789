import React, { useState } from 'react';
import PropTypes from 'prop-types';

CheckBoxNoFormik.propTypes = {
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    additionClassName: PropTypes.string,
    value: PropTypes.bool,
    onChanged: PropTypes.func,
};

CheckBoxNoFormik.defaultProps = {
    label: '',
    disabled: false,
    text: '',
    additionClassName: '',
    value: false,
    onChange: null,
};

function CheckBoxNoFormik(props) {
    // MARK: --- Params ---
    const { name, label, disabled, text, additionClassName, value, onChange } = props;
    const [checked, setChecked] = useState(false);

    // MARK: --- Functions ---
    function handleChange(e) {
        if (onChange) {
            onChange(name);
        }
    }

    return (
        <div className={`form-group ${additionClassName}`}>
            <label className='checkbox checkbox-outline checkbox-primary' htmlFor={name}>
                <input
                    type='checkbox'
                    id={name}
                    name={name}
                    value={name}
                    checked={value}
                    onChange={handleChange}
                />
                <span className='mr-3'></span>
                {label}
            </label>
            {
                text.length > 0 && (
                    <span className='form-text text-muted'>{text}</span>
                )
            }
        </div>
    );
}

export default CheckBoxNoFormik;