import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import Utils from 'general/utils/Utils';
import { useTranslation } from 'react-i18next';

ModalEventInfo.propTypes = {
    show: PropTypes.bool,
    event: PropTypes.object,
    onClose: PropTypes.func,
};

ModalEventInfo.defaultProps = {
    show: false,
    event: {},
    onClose: null,
};

function ModalEventInfo(props) {
    // MARK: --- Params ---
    const { show, event, onClose } = props;
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
            >
                <Modal.Header>
                    <Modal.Title>
                        {event && event.name}
                        <p className='font-size-lg text-dark-75 font-weight-bold'>{event && event.location}</p>
                        <p className='font-size-base text-dark-50 font-weight-normal'>{event && Utils.formatDateTime(event.time, 'DD/MM/YYYY HH:mm')}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{event && event.description}</Modal.Body>
                <Modal.Footer>
                    <Button className='font-weight-bold' variant="secondary" onClick={() => {
                        handleClose();
                    }}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalEventInfo;