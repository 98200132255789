import React, { useRef } from "react";
import PropTypes from "prop-types";
import StockIndexHeaderView from "../StockIndexHeaderView";
import AppSiteMenu from "../AppSiteMenu";
import AppMobileSiteMenu from "../AppMobileSiteMenu";
import StockIndexTopView from "../StockIndexTopView";
import AppStockSearch from "../AppStockSearch";
import QuickWatchlist from "features/WatchList/components/QuickWatchlist";
import QuickNotification from "features/Notification/components/QuickNotification";
import { useSelector } from "react-redux";

AppHeader.propTypes = {};

function AppHeader(props) {
  // MARK: --- Params ---
  const refCanvasSearch = useRef(null);
  const refCanvasWatchlist = useRef(null);
  const refCanvasNotification = useRef(null);
  const { realtimeIndexes } = useSelector(state => state.app);

  return (
    <div className="sticky-top" id="app-header">
      {
        realtimeIndexes && realtimeIndexes.length > 0 && (
          <StockIndexTopView />
        )
      }
      <div className="d-none d-lg-block">
        <AppSiteMenu
          onPressSearch={() => {
            if (refCanvasSearch.current) {
              refCanvasSearch.current.show();
            }
          }}
          onPressWatchlist={() => {
            if (refCanvasWatchlist.current) {
              refCanvasWatchlist.current.show();
            }
          }}
          onPressNotification={() => {
            if (refCanvasNotification.current) {
              refCanvasNotification.current.show();
            }
          }}
        />
      </div>
      <div className="d-block d-lg-none">
        <AppMobileSiteMenu
          onPressSearch={() => {
            if (refCanvasSearch.current) {
              refCanvasSearch.current.show();
            }
          }}
          onPressWatchlist={() => {
            if (refCanvasWatchlist.current) {
              refCanvasWatchlist.current.show();
            }
          }}
          onPressNotification={() => {
            if (refCanvasNotification.current) {
              refCanvasNotification.current.show();
            }
          }}
        />
      </div>

      <AppStockSearch
        onOffcanvasReady={(canvasSearch) => {
          if (refCanvasSearch.current === null) {
            refCanvasSearch.current = canvasSearch;
          }
        }}
      />

      <QuickWatchlist
        onOffcanvasReady={(canvasWatchlist) => {
          if (refCanvasWatchlist.current === null) {
            refCanvasWatchlist.current = canvasWatchlist;
          }
        }}
      />

      <QuickNotification
        onOffcanvasReady={(canvasNotification) => {
          if (refCanvasNotification.current === null) {
            refCanvasNotification.current = canvasNotification;
          }
        }}
      />
    </div>
  );
}

export default AppHeader;
