import axiosClient from "./axiosClient";

const sectorApi = {
    // get all
    getAll: (params) => {
        const url = 'sector/all';
        return axiosClient.get(url, { params });
    },

    // get sector index
    getSectorIndex: () => {
        const url = '/industry/index';
        return axiosClient.get(url);
    },
};

export default sectorApi;