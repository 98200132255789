import React, { useRef } from 'react';
import PropTypes from 'prop-types';


StockSearchBar.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    typingTimeout: PropTypes.number,
    onSubmit: PropTypes.func,
    noBorder: PropTypes.bool,

    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelpers: PropTypes.object,

    validMeta: PropTypes.object,
    validHelpers: PropTypes.object,

    disabled: PropTypes.bool,
};

StockSearchBar.defaultProps = {
    type: "text",
    placeholder: "",
    className: '',
    typingTimeout: 300,
    onSubmit: null,
    noBorder: false,

    fieldProps: {},
    fieldHelpers: {},
    fieldMeta: {},

    validMeta: {},
    validHelpers: {},
    disabled: false,
};


function StockSearchBar(props) {
    // MARK: --- Params ---
    const {
        name,
        fieldProps, fieldHelpers, fieldMeta,
        validHelpers, validMeta,
        type,
        placeholder,
        className,
        typingTimeout,
        onSubmit,
        noBorder,
        disabled
    } = props;
    const { error, touched } = fieldMeta;
    const fieldIsError = error && touched;

    const typingTimeoutRef = useRef(null);


    // MARK: --- Functions ---
    function handleTextChanged(e) {
        const value = e.target.value;
        validHelpers.setValue('false')

        if (onSubmit === null) {
            return;
        }

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            onSubmit(value);
        }, typingTimeout);
    }


    return (
        <div className={`input-icon ${className}`}>
            <input
                {...fieldProps}
                id={name}
                type={type}
                className={`form-control ${noBorder && 'border-0'} ${fieldIsError && 'is-invalid'}`}
                placeholder={placeholder}
                onChange={(e) => {
                    fieldProps.onChange(e);
                    handleTextChanged(e);
                }}
                disabled={disabled}
            />
            {/* {
                fieldIsError && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{error}</div>
                    </div>
                )
            } */}
            {/* {
                (!error && validMeta.error) && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{validMeta.error}</div>
                    </div>
                )
            } */}
        </div>
    );
}

export default StockSearchBar;
