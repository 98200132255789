import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Utils from 'general/utils/Utils';
import moment from 'moment';

DatePickerInputMask.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object.isRequired,
    fieldMeta: PropTypes.object.isRequired,
    fieldHelpers: PropTypes.object,

    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    additionClassName: PropTypes.string,
};

DatePickerInputMask.defaultProps = {
    label: '',
    placeholder: '',
    disabled: false,
    text: '',
    additionClassName: '',
};

function DatePickerInputMask(props) {
    // MARK: --- Params ---
    const { fieldProps, fieldMeta, fieldHelpers, name, label, placeholder, disabled, text, additionClassName } = props;
    const { error, touched } = fieldMeta;
    const showError = error && touched;
    let dateTimeValue = fieldProps.value;
    // if (dateTimeValue && moment(dateTimeValue, 'YYYY-MM-DD').isValid()) {
    //     dateTimeValue = moment(dateTimeValue, 'YYYY-MM-DD').local().format('DD/MM/YYYY');
    // }
    // console.log(fieldProps.value);
    // console.log({ dateTimeValue });

    return (
        <div className={`form-group ${additionClassName}`}>
            {
                label && (
                    <label htmlFor={name}>{label}</label>
                )
            }
            <div className="">
                <div>
                    <InputMask
                        mask="99/99/9999"
                        // defaultValue="17-06-2022"
                        alwaysShowMask={placeholder.length === 0}
                        id={name}
                        className={`form-control ${showError ? 'is-invalid' : ''}`}
                        {...fieldProps}
                        value={dateTimeValue}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </div>

                {
                    error && error.length > 0 && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    )
                }

                <span className="form-text text-muted">{text}</span>
            </div>
        </div>
    );
}

export default DatePickerInputMask;