import store from "app/store";
import courseApi from "../../api/courseApi";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
export const thunkGetCourseList = createAsyncThunk('course/all', async (params, thunkAPI) => {
    const res = await courseApi.getAll(params);
    return res;
});

export const thunkGetCourseDetail = createAsyncThunk('course/detail', async (params, thunkAPI) => {
    const res = await courseApi.getDetail(params);
    return res;
});

export const thunkUpdateLessionProcess = createAsyncThunk('course/update_process', async (params, thunkAPI) => {
    const res = await courseApi.updateLessionProcess(params);
    return res;
});


const courseSlice = createSlice({
    name: 'course',
    initialState: {
        allCourse: {
            isGettingCourseList: false,
            isGettingMoreCourse: false,
            pagination: { perPage: 8, hasMore: true },
            list: []
        },
        currentCourse: {},
    },
    reducers: {},
    extraReducers: {
        [thunkGetCourseList.pending]: (state, action) => {
            let page = action.meta.arg.page
            state.allCourse.list = [];
            state.allCourse.isGettingCourseList = true
            state.allCourse.isGettingMoreCourse = (page > 0) ? true : false
            state.allCourse.pagination = {
                ...state.pagination,
                perPage: action.meta.arg.limit
            }
        },
        [thunkGetCourseList.rejected]: (state, action) => {
            state.allCourse.isGettingCourseList = false;
            state.allCourse.isGettingMoreCourse = false
        },
        [thunkGetCourseList.fulfilled]: (state, action) => {
            state.allCourse.isGettingCourseList = false
            state.allCourse.isGettingMoreCourse = false
            const { result, courses, total, count, page } = action.payload;
            if (result === 'success') {
                if (page == 0) {
                    state.allCourse.list = courses
                }
                if (page > 0) {
                    state.allCourse.list = [
                        ...state.allCourse.list,
                        ...courses
                    ];
                }
                state.allCourse.pagination = {
                    ...state.pagination,
                    total: total,
                    hasMore: (total <= count + page * 8) ? false : true,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },
        [thunkGetCourseDetail.pending]: (state, action) => {
        },
        [thunkGetCourseDetail.rejected]: (state, action) => {
        },
        [thunkGetCourseDetail.fulfilled]: (state, action) => {
            const { result, detail } = action.payload;
            if (result === 'success') {
                state.currentCourse = detail
            }
        },
        [thunkUpdateLessionProcess.pending]: (state, action) => {
        },
        [thunkUpdateLessionProcess.rejected]: (state, action) => {
        },
        [thunkUpdateLessionProcess.fulfilled]: (state, action) => {
            const { result, lession } = action.payload;
            if (result == "success") {
                const lessionId = lession?.lessionId
                const newDuration = lession?.duration
                let currentProcessList = state.currentCourse?.viewProcess
                let newList = []

                currentProcessList.forEach((process) => {
                    if (process.lessionId == lessionId) {
                        process.duration = newDuration
                        newList.push(process)
                    } else {
                        newList.push(process)
                    }
                })
                state.currentCourse = {
                    ...state.currentCourse,
                    viewProcess: newList,
                }
            }

        }
    },
});

const { reducer, actions } = courseSlice;
export const { } = actions;
export default reducer;
