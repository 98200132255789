import MGDataTable from 'general/components/Tables/MGDataTable';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

Ranking.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

Ranking.defaultProps = {
    data: [],
    loading: false,
};

function Ranking(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { data, groupScore, loading } = props;
    const history = useHistory();

    return (
        <div className='border-left border-bottom border-right pt-3 bg-white'>
            <MGDataTable
                className='bg-white border-top-0 border-left-0 border-right-0'
                headItems={[
                    { title: t('CatalogValue'), subTitle: 'VNĐ', col: 'col-2' },
                    { title: t('TotalScoreMG'), subTitle: t('EntireCategory'), col: 'col-2' },
                    { title: t('StrengthEPS'), subTitle: t('EntireCategory'), col: 'col-2' },
                    { title: t('StrengthPrice'), subTitle: t('EntireCategory'), col: 'col-2' },
                    { title: t('RankDemand'), subTitle: t('List'), col: 'col-2' },
                    { title: t('RankGroup'), subTitle: t('List'), col: 'col-2' },
                ]}
                dataItems={
                    [
                        {
                            onPress: () => { },
                            data: [
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber(groupScore?.totalPrice), value: '', valueColor: '', col: '' },
                                { icon: '', title: '', subTitle: '', name: groupScore?.mgScore, value: '', valueColor: '', col: '' },
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber(parseFloat(groupScore?.epsStrength ?? 0).toFixed(2)), value: '', valueColor: '', col: '' },
                                { icon: '', title: '', subTitle: '', name: (groupScore?.priceStrength ?? 0), value: '', valueColor: '', col: '' },
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber((groupScore?.demandScore ?? 0).toFixed(2)), value: '', valueColor: '', col: '' },
                                { icon: '', title: '', subTitle: '', name: Utils.formatNumber(parseFloat(groupScore?.groupScoreRank ?? 0).toFixed(2)), value: '', valueColor: '', col: '' },
                            ]
                        }
                    ]
                }
            />

            <div className='bg-light p-4'>
                <div className='border bg-white pt-2'>
                    <MGDataTable
                        className='bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                        loading={loading}
                        headItems={[
                            { title: t('StockCode'), subTitle: t('CompanyName'), col: 'col-3' },
                            { title: t('MarketPrice'), subTitle: 'VNĐ', col: 'col-1', textAlign: 'right' },
                            { title: t('TotalPoint'), subTitle: 'MoneyGain', col: 'col-1', textAlign: 'right' },
                            { title: t('Strength'), subTitle: t('EPS'), col: 'col-1', textAlign: 'right' },
                            { title: t('Strength'), subTitle: t('Price'), col: 'col-1', textAlign: 'right' },
                            { title: t('Ranking'), subTitle: t('Demand'), col: 'col-1', textAlign: 'right' },
                            { title: t('Ranking'), subTitle: t('Group'), col: 'col-1', textAlign: 'right' },
                        ]}
                        dataItems={
                            data.map((item, index) => {
                                return {
                                    onPress: () => {
                                        history.push(`/stock/${item?.stockCode}`);
                                    },
                                    data: [
                                        { icon: '', title: item.stockCode, titleWeight: 600, subTitle: item.companyName, name: '', value: '', valueColor: '', col: 'col-3' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.marketPrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.mgScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.epsStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.priceStrength), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: item?.demandScore, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatNumber(item?.groupScore), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    ]
                                }
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default Ranking;
