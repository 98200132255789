import { createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "general/utils/Utils";
import alertApi from "../../api/alertApi";
import filterApi from "../../api/filterApi";

const { createSlice } = require("@reduxjs/toolkit");


export const thunkGetAllConditional = createAsyncThunk('conditional/all', async (params, thunkAPI) => {
    const res = await filterApi.getAllConditionals();
    return res;
});

export const thunkGetAllFilter = createAsyncThunk('filter/all', async (params, thunkAPI) => {
    const res = await filterApi.getAll(params);
    return res;
})

export const thunkCreateNewFilter = createAsyncThunk('filter/create', async (filterName, filterDescription) => {
    const res = await filterApi.createFilter(filterName, filterDescription);
    return res;
})

export const thunkGetFilterResult = createAsyncThunk('filter/result', async (params, thunkAPI) => {
    const res = await filterApi.getResultByConditionals(params);
    return res;
});

export const thunkLoadMoreFilterResult = createAsyncThunk('filter/result_more', async (params, thunkAPI) => {
    const res = await filterApi.getResultByConditionals(params);
    return res;
});

export const thunkDeleteFilter = createAsyncThunk('filter/delete', async (params, thunkAPI) => {
    const res = await filterApi.deleteFilter(params);
    return res;
});

export const thunkEditFilter = createAsyncThunk('filer/edit', async (params, thunkAPI) => {
    const res = await filterApi.renameFilter(params);
    return res;
})

export const thunkGetFilterDetail = createAsyncThunk('filter/detail', async (params, thunkAPI) => {
    const res = await filterApi.getDetail(params);
    return res;
})
export const thunkGetSelectedFilterResult = createAsyncThunk('filter/current/result', async (params, thunkAPI) => {
    const res = await filterApi.getResult(params);
    return res;
});

export const thunkLoadMoreSelectedFilterResult = createAsyncThunk('filter/current/result_more', async (params, thunkAPI) => {
    const res = await filterApi.getResult(params);
    return res;
});

export const thunkCreateAlert = createAsyncThunk('alert/add', async (params, thunkAPI) => {
    const res = await alertApi.createAlert(params);
    return res;
});

export const thunkGetListAlert = createAsyncThunk('alert/list', async (params, thunkAPI) => {
    const res = await alertApi.getAll();
    return res;
});

export const thunkUpdateAlert = createAsyncThunk('alert/edit', async (params, thunkAPI) => {
    const res = await alertApi.editAlert(params);
    return res;
});

export const thunkLoadMoreAlert = createAsyncThunk('alert/load_more', async (params, thunkAPI) => {
    const res = await alertApi.getAll(params);
    return res;
});

export const thunkRemoveAlert = createAsyncThunk('alert/remove', async (params, thunkAPI) => {
    const res = await alertApi.removeAlert(params);
    return res;
});

export const thunkEditConditional = createAsyncThunk('filter/edit_conditional', async (params, thunkAPI) => {
    const res = await filterApi.editConditional(params);
    return res;
})

export const thunkGetAllMGFilter = createAsyncThunk('filter/all/mg', async (params, thunkAPI) => {
    const res = await filterApi.getMGFilter(params);
    return res;
})

export const thunkGetFilterResultByFilterId = createAsyncThunk('filter/result/byId', async (params, thunkAPI) => {
    const res = await filterApi.getResult(params);
    return res;
})

export const thunkLoadMoreFilterResultByFilterId = createAsyncThunk('filter/result/byId/more', async (params, thunkAPI) => {
    const res = await filterApi.getResult(params);
    return res;
});

export const thunkGetMGFilterDetail = createAsyncThunk('filter/mg/detail', async (params, thunkAPI) => {
    const res = await filterApi.getDetail(params);
    return res;
})


const ideaListSlice = createSlice({
    name: 'ideaList',
    initialState: {
        listFilters: [],
        currentSelectedFilter: null,
        currentSelectedFilterMG: null,
        isLoadingListFilters: false,
        filter: {
            currentFilter: [],
            allConditional: [],
            filterResult: {
                result: [],
                isGettingResult: false,
                pagination: { perPage: 20, hasMore: true }
            },
        },
        editFilter: {
            filterId: null,
            addConditionals: [],
            removeFilterLineIds: [],
            updateConditionals: []
        },
        mgAlert: {
            listAlert: [],
            pagination: { perPage: 10, hasMore: true }
        },
        mgFilter: {
            listFilters: [],
            pagination: { perPage: 10, hasMore: true },
            isLoadingListFilters: false,
            filterResult: {
                result: [],
                isGettingResult: false,
                pagination: { perPage: 20, hasMore: true }
            },
        }

    },
    reducers: {
        addOrRemoveConditional: (state, action) => {
            let currentFilter = state.filter.currentFilter
            let conditional = action.payload
            let findIndex = currentFilter.map(object => object.filterConditionalId).indexOf(conditional.filterConditionalId)
            let currentFilterId = state.editFilter.filterId
            if (findIndex >= 0) {
                let currentPop = currentFilter[findIndex]
                let { filterLineId } = currentPop
                if (filterLineId) {
                }
                state.editFilter.removeFilterLineIds = [
                    ...state.editFilter.removeFilterLineIds,
                    filterLineId
                ]
                currentFilter.splice(findIndex, 1)
            }
            if (findIndex == -1) {
                currentFilter.push({
                    filterId: currentFilterId,
                    filterConditionalId: conditional.filterConditionalId,
                })
            }
        },
        popConditional: (state, action) => {
            let currentFilter = state.filter.currentFilter
            let { filterConditionalId, filterLineId } = action.payload
            if (filterLineId) {
                state.editFilter.removeFilterLineIds = [
                    ...state.editFilter.removeFilterLineIds,
                    filterLineId
                ]
            }
            if (filterLineId == null) {
                let currentAddConditionals = state.editFilter.addConditionals
                state.editFilter.addConditionals = currentAddConditionals?.filter(item => item?.filterConditionalId != filterConditionalId)
            }
            let findIndex = currentFilter.map(object => object.filterConditionalId).indexOf(filterConditionalId)
            if (findIndex >= 0) {
                currentFilter.splice(findIndex, 1)
            }
        },
        editConditional: (state, action) => {
            let currentFilter = state.filter.currentFilter
            let newConditional = action.payload
            let { filterId, filterLineId, filterConditionalId, max, min, values } = newConditional
            if (filterLineId && filterId) {
                let currentUpdateConditionals = state.editFilter.updateConditionals
                let findIndexOfConditionals = currentUpdateConditionals.findIndex(item => item?.filterLineId == filterLineId)
                if (findIndexOfConditionals >= 0) {
                    currentUpdateConditionals[findIndexOfConditionals] = {
                        filterLineId: filterLineId,
                        filterConditionalId: filterConditionalId,
                        max: max ?? 0,
                        min: min ?? 0,
                        values: values ?? null
                    }
                    state.editFilter.updateConditionals = currentUpdateConditionals
                } else {
                    state.editFilter.updateConditionals = [
                        ...currentUpdateConditionals,
                        {
                            filterLineId: filterLineId,
                            filterConditionalId: filterConditionalId,
                            max: max ?? 0,
                            min: min ?? 0,
                            values: values ?? null
                        }
                    ]
                }
            }
            if (filterId && (filterLineId == null)) {
                let currentAddConditionals = state.editFilter.addConditionals
                let findIndexOfConditionals = currentAddConditionals.findIndex(item => item?.filterConditionalId == filterConditionalId)
                if (findIndexOfConditionals >= 0) {
                    currentAddConditionals[findIndexOfConditionals] = {
                        filterConditionalId: filterConditionalId,
                        max: max ?? 0,
                        min: min ?? 0,
                        values: values ?? null
                    }
                    state.editFilter.addConditionals = currentAddConditionals
                } else {
                    state.editFilter.addConditionals = [
                        ...currentAddConditionals,
                        {
                            filterLineId: filterLineId,
                            filterConditionalId: filterConditionalId,
                            max: max ?? 0,
                            min: min ?? 0,
                            values: values ?? null
                        }
                    ]
                }
            }
            let selectFilterConditionalId = newConditional.filterConditionalId
            let findIndex = currentFilter.findIndex(item => item?.filterConditionalId == selectFilterConditionalId)
            currentFilter[findIndex] = newConditional
        },
        cleanResult: (state, action) => {
            state.filter.filterResult = {
                result: [],
                isGettingResult: false,
                pagination: { perPage: 20, hasMore: true }
            }
        },
        setMGAlert: (state, action) => {
            let length = action.payload.length
            state.mgAlert = {
                listAlert: action.payload,
                pagination: {
                    perPage: length,
                    hasMore: false,
                    total: length,
                    count: length,
                    currentPage: 0
                }
            }
        },
        setCurrentSelectedFilter: (state, action) => {
            state.currentSelectedFilter = action.payload;
        },
        setCurrentSelectedFilterMG: (state, action) => {
            state.currentSelectedFilterMG = action.payload;
        },
    },

    extraReducers: {
        [thunkGetAllConditional.fulfilled]: (state, action) => {
            const { result, conditionals } = action.payload;
            if (result === 'success') {
                if (conditionals) {
                    state.filter.allConditional = conditionals;
                }
            }
        },
        [thunkGetFilterResult.pending]: (state, action) => {
            state.filter.filterResult.isGettingResult = true
        },
        [thunkGetFilterResult.rejected]: (state, action) => {
            state.filter.filterResult.isGettingResult = false
        },
        [thunkGetFilterResult.fulfilled]: (state, action) => {
            state.filter.filterResult.isGettingResult = false
            const { result, companies, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;

            if (result === 'success') {
                if (companies) {
                    state.filter.filterResult.result = companies;
                }
                state.filter.filterResult.pagination = {
                    ...state.filter.filterResult.pagination,
                    total: total,
                    count: count,
                    hasMore: total > (20 * currentPage + count),
                    currentPage: page + 1
                };
            }

        },
        [thunkLoadMoreFilterResult.pending]: (state, action) => {
            state.filter.filterResult.isGettingResult = true
        },
        [thunkLoadMoreFilterResult.rejected]: (state, action) => {
            state.filter.filterResult.isGettingResult = false
        },
        [thunkLoadMoreFilterResult.fulfilled]: (state, action) => {
            state.filter.filterResult.isGettingResult = false
            const { result, companies, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;
            if (result === 'success') {
                if (companies?.length > 0) {
                    let currentResult = state.filter.filterResult.result
                    state.filter.filterResult.result = [
                        ...currentResult,
                        ...companies
                    ]
                    state.filter.filterResult.pagination = {
                        ...state.filter.filterResult.pagination,
                        total: total,
                        count: count,
                        hasMore: total > (20 * currentPage + count),
                        currentPage: page + 1
                    };
                }
            }
        },
        // get list filters
        [thunkGetAllFilter.pending]: (state, action) => {
            state.isLoadingListFilters = true;
        },

        [thunkGetAllFilter.rejected]: (state, action) => {
            state.isLoadingListFilters = false;
        },

        [thunkGetAllFilter.fulfilled]: (state, action) => {
            state.isLoadingListFilters = false;
            const payload = action.payload;
            const { result, filters } = payload;
            if (result === 'success' && filters) {
                state.listFilters = filters;
                if (Utils.isObjectNull(state.currentSelectedFilter) && filters.length > 0) {
                    state.currentSelectedFilter = filters[0];
                }
            }
        },

        //create new filter

        [thunkCreateNewFilter.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, detail } = payload;

            if (result === 'success' && detail) {
                state.listFilters = [detail, ...state.listFilters]
                state.currentSelectedFilter = detail;
            }
        },


        // delete filter
        [thunkDeleteFilter.pending]: (state, action) => {
            state.isLoadingListFilters = true;
        },

        [thunkDeleteFilter.rejected]: (state, action) => {
            state.isLoadingListFilters = true;
        },

        [thunkDeleteFilter.fulfilled]: (state, action) => {
            const result = action.payload.result;
            const filterId = action.meta.arg.filterId;
            if (result == 'success') {
                state.listFilters = state.listFilters.filter(item => filterId !== item.filterId)

                if (!Utils.isObjectNull(state.currentSelectedFilter) && state.listFilters.length > 0 && filterId === state.currentSelectedFilter.filterId) {
                    state.currentSelectedFilter = state.listFilters[0];
                }
            }
        },

        // edit filer
        [thunkEditFilter.fulfilled]: (state, action) => {
            const result = action.payload.result;
            const filterId = action.meta.arg.filterId;
            const filterNewName = action.meta.arg.name;
            if (result === 'success') {
                for (let i = 0; i < state.listFilters.length; i++) {
                    if (filterId == state.listFilters[i].filterId) {
                        state.listFilters[i].filterName = filterNewName;
                    }
                    if (filterId == state.currentSelectedFilter.filterId) {
                        state.currentSelectedFilter.filterName = filterNewName;
                    }
                }
            }
        },

        // get filter detail
        [thunkGetFilterDetail.pending]: (state, action) => {
            state.editFilter = {
                ...state.editFilter,
                // filterId: null,
                addConditionals: [],
                removeFilterLineIds: [],
                updateConditionals: []
            }
        },
        [thunkGetFilterDetail.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, detail, listIndustry } = payload;
            if (result === 'success') {
                let findIndex = detail.findIndex(item => item?.filterConditionalId == 2)
                if (findIndex >= 0) {
                    detail[findIndex].listTitle = listIndustry
                }
                state.filter.currentFilter = detail;
                // state.editFilter.filterId = detail[0]?.filterId ?? null
                state.editFilter.filterId = state.currentSelectedFilter.filterId;
            }

            if (state.filter.currentFilter.length === 0) {
                state.filter.filterResult.result = [];
                state.filter.filterResult.pagination = { perPage: 20, hasMore: true }
            }
        },

        [thunkCreateAlert.fulfilled]: (state, action) => {
            const payload = action.payload;
            const { result, detail } = payload;
            if (result === 'success' && detail) {
                state.mgAlert.listAlert = [detail, ...state.mgAlert.listAlert]
            }
        },
        [thunkGetListAlert.pending]: (state, action) => {
        },
        [thunkGetListAlert.rejected]: (state, action) => {
        },
        [thunkGetListAlert.fulfilled]: (state, action) => {
            const { result, total, count, page, alerts } = action?.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;
            if (result == 'success') {
                if (alerts) {
                    state.mgAlert.listAlert = alerts;
                }
                state.mgAlert.pagination = {
                    ...state.mgAlert.pagination,
                    total: total,
                    count: count,
                    hasMore: total > (10 * currentPage + count),
                    currentPage: page + 1
                };
            }
        },
        [thunkUpdateAlert.fulfilled]: (state, action) => {
            const payload = action.payload;
            let list = state.mgAlert.listAlert
            const { result } = payload;
            const params = action?.meta?.arg
            if (result === 'success') {
                let index = list.findIndex((item) => item?.alertId == params?.alertId)
                state.mgAlert.listAlert[index] = params
            }
        },
        [thunkRemoveAlert.fulfilled]: (state, action) => {
            const payload = action.payload;
            let list = state.mgAlert.listAlert
            const { result } = payload;
            const alertId = action?.meta?.arg?.alertId
            if (result === 'success') {
                state.mgAlert.listAlert = list.filter(item => item?.alertId != alertId)
            }
        },
        [thunkLoadMoreAlert.fulfilled]: (state, action) => {
            const { result, alerts, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;
            if (result === 'success') {
                if (alerts?.length > 0) {
                    let currentResult = state.mgAlert.listAlert
                    state.mgAlert.listAlert = [
                        ...currentResult,
                        ...alerts
                    ]
                    state.mgAlert.pagination = {
                        ...state.mgAlert.pagination,
                        total: total,
                        count: count,
                        hasMore: total > (10 * currentPage + count),
                        currentPage: page + 1
                    };
                }
            }
        },

        //edit condition
        [thunkEditConditional.fulfilled]: (state, action) => {
            const payload = action.payload
        },
        [thunkGetAllMGFilter.fulfilled]: (state, action) => {
            const { result, filters, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;
            if (result === 'success') {
                if (filters?.length > 0) {
                    let currentList = state.mgFilter.listFilters
                    state.mgFilter.listFilters = (page > 1) ? [
                        ...currentList,
                        ...filters
                    ] : filters
                    state.mgFilter.pagination = {
                        ...state.mgFilter.pagination,
                        total: total,
                        count: count,
                        hasMore: total > (10 * currentPage + count),
                        currentPage: page + 1
                    };
                    if (Utils.isObjectNull(state.currentSelectedFilterMG) && filters.length > 0) {
                        state.currentSelectedFilterMG = filters[0];
                    }

                }
            }
        },
        [thunkGetFilterResultByFilterId.fulfilled]: (state, action) => {
            state.mgFilter.filterResult.isGettingResult = false
            const { result, companies, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;

            if (result === 'success') {
                if (companies) {
                    state.mgFilter.filterResult.result = companies;
                }
                state.mgFilter.filterResult.pagination = {
                    ...state.mgFilter.filterResult.pagination,
                    total: total,
                    count: count,
                    hasMore: total > (20 * currentPage + count),
                    currentPage: page + 1
                };
            }
        },
        [thunkLoadMoreFilterResultByFilterId.fulfilled]: (state, action) => {
            state.mgFilter.filterResult.isGettingResult = false
            const { result, companies, total, count, page } = action.payload;
            const currentPage = action?.meta?.arg?.page ?? 0;
            if (result === 'success') {
                if (companies?.length > 0) {
                    let currentResult = state.mgFilter.filterResult.result
                    state.mgFilter.filterResult.result = [
                        ...currentResult,
                        ...companies
                    ]
                    state.mgFilter.filterResult.pagination = {
                        ...state.mgFilter.filterResult.pagination,
                        total: total,
                        count: count,
                        hasMore: total > (20 * currentPage + count),
                        currentPage: page + 1
                    };
                }
            }
        },
        [thunkGetMGFilterDetail.fulfilled]: (state, action) => {
            let { result, detail, listIndustry } = action.payload;
            if (result == "success") {
                let index = detail.findIndex(item => item.filterConditionalId == 2)
                if (index != -1) {
                    detail[index].listTitle = listIndustry
                }
                state.mgFilter.currentFilter = detail
            }
        },


    },
},
);

const { actions, reducer } = ideaListSlice;
export const { addOrRemoveConditional, popConditional, editConditional, cleanResult, setMGAlert, setCurrentSelectedFilter, setCurrentSelectedFilterMG } = actions;
export default reducer;
