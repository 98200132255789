import BuildYourScreen from 'features/IdeaList/components/BuildYourScreen';
import FilterStockMG from 'features/IdeaList/components/FilterStockMG';
import MGListStock from 'features/IdeaList/components/MGListStock';
import AppLayout from 'general/components/AppLayout';
import Utils from 'general/utils/Utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './style.scss';

let KTLayoutAsideMenu = require("assets/plugins/aside/aside-menu");
let KTOffcanvas = require("assets/plugins/ktoffcanvas");
const ideaListMenuItems = [
    {
        url: "mg-lists",
        text: "MoneyGainLists",
        subMenus: [
            {
                url: "mg-lists/current",
                text: "CurrentList",
                name: 'CURRENT'
            },
            {
                url: "mg-lists/buy-point",
                text: "BuyPointList",
                name: 'BUYPOINT'
            },
            {
                url: "mg-lists/added",
                text: "AddedList",
                name: 'ADDED'
            },
            {
                url: "mg-lists/removed",
                text: "RemovedList",
                name: 'REMOVED'
            },
            {
                url: "mg-lists/buy-queue",
                text: "BuyQueueList",
                name: 'BUY_QUEUE'
            },
            {
                url: "mg-lists/sell-queue",
                text: "SellQueueList",
                name: 'SELL_QUEUE'
            },
            {
                url: "mg-lists/sell-watch",
                text: "SellWatchList",
                name: 'SELL_WATCH'
            },
            {
                url: "mg-lists/buy-watch",
                text: "BuyWatchList",
                name: 'BUY_WATCH'
            },
        ],
    },
    {
        url: "top-50-growth-portfolio-mg",
        text: "Top50GrowthPortfolioMG",
        // subMenus: [
        //     {
        //         url: "top-50-growth-portfolio-mg/top-50-growth",
        //         text: "Top50Growth",
        //     },
        //     {
        //         url: "top-50-growth-portfolio-mg/buy-point",
        //         text: "BuyPointList",
        //     },
        //     {
        //         url: "top-50-growth-portfolio-mg/added",
        //         text: "AddedList",
        //     },
        //     {
        //         url: "top-50-growth-portfolio-mg/removed",
        //         text: "RemovedList",
        //     },
        // ],
    },
    {
        url: "stock-filter-mg",
        text: "StockFilterMG",
    },
    // {
    //     url: "guru-screen",
    //     text: "GuruScreen",
    // },
    {
        url: "build-your-screen",
        text: "BuildYourScreen",
    },
    // {
    //     url: "watch-list",
    //     text: "WatchList",
    // },
];

function IdeaListHomeScreen(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();
    // console.log({ match });
    const { t } = useTranslation();
    const history = useHistory();
    const locationPathname = history.location.pathname;
    const refOffcanvasIdeaListAside = useRef(null);
    const [stickyBarTop, setStickyBarTop] = useState(undefined);
    const [selectedMenu, setSelectedMenu] = useState('mg-lists/current');
    const [selectedIdeaListName, setSelectedIdeaListName] = useState('CURRENT');
    const [selectedIdeaListTitle, setSelectedIdeaListTitle] = useState('CurrentList');

    // MARK: --- Functions ---
    function handleMenu() {
        if (refOffcanvasIdeaListAside.current) {
            refOffcanvasIdeaListAside.current.show();
        }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        // Init IdeaList Aside Menu
        if (typeof KTLayoutAsideMenu !== "undefined") {
            KTLayoutAsideMenu.init("idea_list_aside_menu");
        }

        if (typeof KTOffcanvas !== "undefined") {
            const offCanvasObject = new KTOffcanvas("idea_list_aside", {
                baseClass: "offcanvas-mobile",
                overlay: true,
                toggleBy: {
                    target: 'idea_list_aside_toggle',
                }
            });
            refOffcanvasIdeaListAside.current = offCanvasObject;
        }
    }, []);

    useEffect(() => {
        const stickyEl = document.querySelector('.sticky-bar');
        if (stickyEl) {
            setStickyBarTop(stickyEl.getBoundingClientRect().top);
        }
    }, []);

    useEffect(() => {
        if (!stickyBarTop) return;

        window.addEventListener('scroll', onSticky);

        return () => {
            window.removeEventListener('scroll', onSticky);
        };
    }, [stickyBarTop]);

    // MARK: --- Functions ---
    function onSticky(e) {
        const stickyEl = document.querySelector('.sticky-bar');
        const scrollTop = window.scrollY;
        if (scrollTop >= stickyBarTop - 10) {
            stickyEl.classList.add('q-is-sticky');
        } else {
            stickyEl.classList.remove('q-is-sticky');
        }
    }

    return (
        <AppLayout>
            {/* Content */}
            <div className='bg-light' style={{
                minHeight: '70vh'
            }}>
                <div className='container-xxl'>
                    <div className='d-flex flex-row py-7'>
                        {/* Aside menu */}
                        <div
                            className='offcanvas-mobile w-300px w-xl-300px h-100'
                            id='idea_list_aside'
                            style={{
                                // zIndex: '9999'
                            }}
                        >
                            <div
                                id="idea_list_aside_menu"
                                className="aside-menu h-100 overflow-auto"
                                data-menu-scroll="1"
                            >
                                <ul className="menu-nav bg-danger py-0 bg-white border rounded IdeaListAside overflow-hidden">
                                    {
                                        ideaListMenuItems.map((item, index) => {
                                            const { url, text, subMenus } = item;
                                            const isActive = selectedMenu.includes(url);
                                            const hasSubMenu = !Utils.isObjectNull(subMenus);

                                            return (
                                                <li
                                                    key={index}
                                                    className={`menu-item ${isActive ? 'menu-item-active' : ''} ${hasSubMenu && `menu-item-submenu ${isActive && 'menu-item-open'}`}`}
                                                >
                                                    <a
                                                        href='#'
                                                        className={`py-5 align-items-center menu-link ${hasSubMenu && 'menu-toggle'} ${index < ideaListMenuItems.length - 1 && 'border-bottom border-light'}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!hasSubMenu) {
                                                                refOffcanvasIdeaListAside.current.hide();
                                                                setSelectedMenu(item.url);
                                                            }
                                                        }}
                                                    >
                                                        <span className="menu-text">{t(text)}</span>
                                                        {
                                                            hasSubMenu && (
                                                                <i className="menu-arrow q-arrow-2" />
                                                            )
                                                        }
                                                    </a>
                                                    {
                                                        hasSubMenu && (
                                                            <div className="menu-submenu">
                                                                <ul className="menu-subnav">
                                                                    {
                                                                        subMenus.map((subItem, subIndex) => {
                                                                            const isSubMenuActive = selectedMenu === (subItem.url);
                                                                            return (
                                                                                <li key={subIndex} className={`menu-item ${isSubMenuActive && 'menu-item-active'}`}>
                                                                                    <a
                                                                                        className='menu-link border-bottom py-4'
                                                                                        style={{ borderRadius: 0 }}
                                                                                        href='#'
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            refOffcanvasIdeaListAside.current.hide();
                                                                                            setSelectedMenu(subItem.url);
                                                                                            setSelectedIdeaListName(subItem.name);
                                                                                            setSelectedIdeaListTitle(subItem.text);
                                                                                        }}
                                                                                    >
                                                                                        <i className="menu-bullet menu-bullet-dot">
                                                                                            <span></span>
                                                                                        </i>
                                                                                        <span className="menu-text">{t(subItem.text)}</span>
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        {/* Right content */}
                        <div className='flex-row-fluid ml-lg-5 border rounded overflow-hidden bg-white align-self-start'>
                            {
                                ['mg-lists/current', 'mg-lists/buy-point', 'mg-lists/added', 'mg-lists/removed', 'mg-lists/buy-queue', 'mg-lists/sell-queue', 'mg-lists/buy-watch', 'mg-lists/sell-watch'].includes(selectedMenu) && (
                                    <MGListStock onMenuHandle={handleMenu} title={t(selectedIdeaListTitle)} listName={selectedIdeaListName} />
                                )
                            }
                            {
                                selectedMenu === 'top-50-growth-portfolio-mg' && (
                                    <MGListStock onMenuHandle={handleMenu} title={t('Top50Growth')} listName='TOP50' />
                                )
                            }
                            {
                                selectedMenu === 'stock-filter-mg' && (
                                    <FilterStockMG onMenuHandle={handleMenu} />
                                )
                            }
                            {
                                selectedMenu === 'build-your-screen' && (
                                    <BuildYourScreen onMenuHandle={handleMenu} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout >
    );
}

export default IdeaListHomeScreen;