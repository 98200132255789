import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ResearchHomeScreen from './screens/ResearchHomeScreen';
import NotFound from 'general/components/NotFound';
import ArticleDetailScreen from 'features/Article/screens/ArticleDetailScreen';

Research.propTypes = {

};

function Research(props) {
    // MARK: --- Params ---
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                component={ResearchHomeScreen}
            />
            <Route
                path={`${match.url}/:articleId`}
                component={ArticleDetailScreen}
            />

            {/* Not found */}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Research;