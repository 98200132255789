import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AccountBaseLayout from 'features/Account/components/AccountBaseLayout';
import { AppIcons } from 'general/constants/AppResource';
import './style.scss'
import { useFormik, validateYupSchema } from 'formik';
import * as Yup from "yup";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToastHelper from 'general/helpers/ToastHelper';
import accountApi from 'api/accountApi';
import Utils from 'general/utils/Utils';
import UserHelper from 'general/helpers/UserHelper';

AccountReferralScreen.propTypes = {

};

function AccountReferralScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const loggedInUser = useSelector(state => state.auth.current);
    const referralByUser = loggedInUser.referralBy instanceof Object ? loggedInUser.referralBy : null;
    const myReferralCode = loggedInUser.referralCode ?? '';
    const [loading, setLoading] = useState(false);
    const [referralByName, setReferralByName] = useState('');

    const formik = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema: Yup.object({
            code: Yup.string().nullable().min(6, t('CodeLengthInvalid')),
        }),
        onSubmit: (values) => {
            const code = values.code;
            if (code.length === 0) {
                ToastHelper.showError(t('PleaseInputCode'));
            } else {
                updateReferralCode(code);
            }
        }
    });

    // MARK: --- Functions ---
    function copyToClipboard() {
        /* Get the text field */
        var copyText = document.getElementById("AccountReferralScreenMyCode").innerHTML;
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText);
        ToastHelper.showSuccess(t('Copied'));
    }

    async function updateReferralCode(code) {
        setLoading(true);
        const res = await accountApi.updateReferralCode(code);
        const { result, referralBy, description } = res;
        if (result === 'success') {
            if (referralBy) {
                const name = referralBy.email;
                setReferralByName(name);
            }
        } else if (description && description.length > 0) {
            ToastHelper.showError(description);
        }
        setLoading(false);

    }

    return (
        <AccountBaseLayout>
            <div className='px-0 px-md-10'>
                <div className='d-flex flex-column align-items-center'>
                    <img
                        className='mb-6'
                        src={AppIcons.icAccountReferral}
                        alt='icon'
                        draggable={false}
                    />
                    <p className='text-dark-75 font-size-h4 font-weight-boldest text-center m-0'>{t('AccountReferralTitle').toUpperCase()}</p>
                    <p className='font-size-lg text-center'>{t('AccountReferralDescription')}</p>
                </div>

                {/* dung */}
                <div className='d-flex flex-column align-items-center my-3'>
                    <div className='pb-20 w-75 w-md-50'>
                        <div className=''>
                            <div className='border-bottom pb-7'>
                                <p className='font-size-lg font-weight-boldest mb-2'>{t('YourReferrerCode')}</p>
                                <form className="form-row" onSubmit={formik.handleSubmit}>
                                    <div className="col-7 form-group mr-3 mb-2">
                                        <label className="sr-only">{t('EnterCode')}</label>
                                        <input
                                            type="text"
                                            className={`form-control ${formik.errors.code && 'border-danger'}`}
                                            value={formik.values.code}
                                            onChange={formik.handleChange}
                                            id="code"
                                            placeholder={t('EnterCode')}
                                            disabled={referralByName.length > 0}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="col-4 btn mb-2 AccountReferralScreen_Button btn-primary hover-opacity-60"
                                        disabled={referralByName.length > 0}
                                    >
                                        {t('Verify')}
                                    </button>
                                </form>
                                <p className='text-danger'>{formik.errors.code}</p>
                                {
                                    referralByName.length === 0 && Utils.isObjectNull(referralByUser) && (
                                        <span className="form-text text-muted">{t('CodeLength6Characters')}</span>
                                    )
                                }
                                {
                                    referralByName.length > 0 || referralByUser && (
                                        <p className='AccountReferralScreen_Alert'>{`${t('UpdateReferralCodeSuccess', { name: `[${referralByUser ? referralByUser.email : referralByName}]` })}`}</p>
                                    )
                                }
                            </div>
                        </div>

                        <div className='pt-7'>
                            <p className='font-size-lg font-weight-boldest mb-2'>{t('YourReferralCode')}</p>
                            <div className='form-row'>
                                <div className="col-7 form-group m-0 mr-3">
                                    <p className='form-control m-0' id="AccountReferralScreenMyCode">{myReferralCode}</p>
                                </div>
                                <button className='col-4 btn btn-secondary font-weight-boldest hover-opacity-60' onClick={() => { copyToClipboard() }}>{t('Copy')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AccountBaseLayout>
    );
}

export default AccountReferralScreen;