import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';

ModalEmptyList.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onAcceptCreateNewList: PropTypes.func,
};

ModalEmptyList.defaultProps = {
    show: false,
    onClose: null,
    onAcceptCreateNewList: null,
};

function ModalEmptyList(props) {
    // MARK: --- Params ---
    const { show, onClose, onAcceptCreateNewList } = props;
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <div>
            <Modal
                className=''
                show={show}
                onHide={() => {
                    handleClose();
                }}
                centered
                onExited={() => {

                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        {t('ChooseList')}
                    </Modal.Title>
                    <div
                        className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <i className="far fa-times"></i>
                    </div>
                </Modal.Header>

                <Modal.Body className='overflow-auto' style={{
                    maxHeight: '84vh'
                }}>
                    <div>
                        {t('MessageEmptyList')}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex flex-row justify-content-between'>
                    <button
                        className='border btn btn-secondary font-weight-bolder font-size-base'
                        style={{ width: 220 }}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('No')}
                    </button>

                    <button
                        className='border-0 btn btn-primary font-weight-bolder font-size-base'
                        style={{ width: 220 }}
                        onClick={() => {
                            if (onAcceptCreateNewList) {
                                onAcceptCreateNewList();
                            }
                            handleClose()
                        }}>
                        {t('Yes')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalEmptyList;