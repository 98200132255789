import transactionApi from "api/transactionApi";
import { thunkGetCurrentUserInfo } from "app/authSlice";
import { thunkGetAllTransactions } from "features/Account/accountSlice";
import ModalPaymentSuccess from "features/Payment/components/BillInfor/components/ModalPaymentSuccess";
import CustomToggleButton from "features/Payment/components/CustomToggleButton";
import { useFormik } from "formik";
import BaseTextField from "general/components/Forms/FormControls/BaseTextField";
import ToastHelper from "general/helpers/ToastHelper";
import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BankInfor from "./components/BankInfor";
import "./style.scss";

BillInfor.propTypes = {};

/**
 *
 * @param {{product:{type:'WEBINAR'|'COURSE'|'MEMBER',productId:number,price:number}}} props
 * @returns
 */
function BillInfor(props) {
  const { product } = props;
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const [stepOneActive, setStepOneActive] = React.useState(false);
  const [stepTwoActive, setStepTwoActive] = React.useState(false);
  const dispatch = useDispatch();

  // console.log({ product });

  const { current }: { current: { email: String } } = useSelector(
    (state) => state.auth ?? {}
  );

  const validationSchema = Yup.object().shape({
    paymentTransactionId: Yup.string().required(t("Required")),
  });

  const formik = useFormik({
    initialValues: {
      paymentTransactionId: undefined,
      bankAccountId: undefined,
      bankName: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log({ values });
      if (!values.bankAccountId)
        return ToastHelper.showError(t("Please choose one bank account above"));

      const { result, reason } = await transactionApi.createTransaction({
        ...values,
        ...product,
      });

      if (result == "success") {
        setModalShow(true);
        dispatch(thunkGetAllTransactions());

        if (product?.type === 'MEMBER') {
          dispatch(thunkGetCurrentUserInfo());
        }
      }
      else {
        ToastHelper.showError(
          t("Fail to send your transaction. Try again later.")
        );
      }
    },
  });

  return (
    <div className="w-100 bg-white rounded">
      <div className="border-bottom w-100 px-6 py-4">
        <p className="font-weight-bolder font-size-lg">
          {t("BillingInformation")}
        </p>
      </div>

      <div className="py-4">
        <div className="px-6">
          <Accordion defaultActiveKey="0">
            <Card className="border rounded">
              <Card.Header className="rounded">
                <CustomToggleButton
                  btnColor={" btn-white"}
                  eventKey="0"
                  borderShape={
                    stepOneActive ? "rounded-bottom-0" : "rounded-bottom"
                  }
                  onClick={() => {
                    setStepOneActive(!stepOneActive);
                  }}
                >
                  <p className={stepOneActive ? "purple" : null}>
                    {t("StepOne")} {t("ChoosePaymentMethod")}
                  </p>
                </CustomToggleButton>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="p-0 bg-light ">
                  <div className="border-top ">
                    <BankInfor
                      productInfo={product}
                      onBankAccountSelected={({ bankAccountId, bankName }) => {
                        formik
                          .getFieldHelpers("bankAccountId")
                          .setValue(bankAccountId);

                        formik.getFieldHelpers("bankName").setValue(bankName);
                      }}
                    ></BankInfor>
                  </div>
                  {/* <div className="rounded-bottom">
                    <MomoInfor></MomoInfor>
                  </div> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <div className="px-6 py-4">
          <Accordion>
            <Card className="border rounded">
              <Card.Header className="rounded">
                <CustomToggleButton
                  btnColor={" btn-white "}
                  eventKey="0"
                  borderShape={
                    stepOneActive ? "rounded-bottom-0" : "rounded-bottom"
                  }
                  onClick={() => {
                    setStepTwoActive(!stepTwoActive);
                  }}
                >
                  <p className={stepTwoActive ? "purple" : null}>
                    {t("StepTwo")} {t("PaymentConfirmation")}
                  </p>
                </CustomToggleButton>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="bg-light border-top rounded-bottom">
                  <form>
                    <div className="w-md-50" style={{ whiteSpace: "normal" }}>
                      <BaseTextField
                        name="paymentTransactionId"
                        placeholder={`${t("EnterTradingCode")}...`}
                        label={t("TradingCode")}
                        text={t("TradingCodeNote")}
                        fieldHelper={formik.getFieldHelpers(
                          "paymentTransactionId"
                        )}
                        fieldMeta={formik.getFieldMeta("paymentTransactionId")}
                        fieldProps={formik.getFieldProps(
                          "paymentTransactionId"
                        )}
                      />
                    </div>

                    <div className="w-md-50" style={{ whiteSpace: "normal" }}>
                      <BaseTextField
                        disabled={!!current?.email}
                        name="email"
                        placeholder={current?.email ?? `${t("Email")}...`}
                        label={t("EnterEmail")}
                        text={!current?.email ? t("EmailNote") : undefined}
                      />
                    </div>
                  </form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        <div className="px-6 row">
          <div className="col-6">
            <div
              className="h-40px w-100 bg-light rounded buttonConfirm text-center d-flex align-items-center justify-content-center"
              style={{
                borderColor: "#A635FF",
                width: "33%",
              }}
              onClick={() => {
                if (!stepTwoActive) {
                  ToastHelper.showError(t("Fill your transaction id"));
                } else formik.handleSubmit();
              }}
            >
              <p
                className="font-weight-bolder font-size-lg"
                style={{ color: "white" }}
              >
                {t("Confirm")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalPaymentSuccess
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          history.back();
        }}
      />
    </div>
  );
}

export default BillInfor;
