import CellCourseGrid from 'features/Course/components/CellCourseGrid';
import AppLayout from 'general/components/AppLayout';
import BaseSearchBar from 'general/components/Forms/FormControls/BaseSearchBar';
import EmptyView from 'general/components/Views/EmptyView';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { AppIcons } from 'general/constants/AppResource';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import LoadMore from "../../../../general/components/LoadMore/index";
import { thunkGetCourseList } from "../../CourseReducer";
import './style.scss';

const sTag = '[CourseHomeScreen]';

function CourseHomeScreen(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('course_all');
    const dispatch = useDispatch()
    const limit = useSelector(state => state?.course?.allCourse?.pagination?.perPage ?? 8)
    const list = useSelector(state => state?.course?.allCourse?.list)
    const currentPage = useSelector(state => state?.course?.allCourse?.pagination?.currentPage ?? 0)
    const hasMore = useSelector(state => state?.course?.allCourse?.pagination?.hasMore)
    const loading = useSelector(state => state?.course?.allCourse?.isGettingCourseList);
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [selectLevel, setSelectLevel] = useState(null)

    // MARK: --- Hooks ---
    useEffect(() => {
        dispatch(thunkGetCourseList({
            q: searchText,
            courseLevel: selectLevel,
            page: 0,
            limit: limit
        }))
        return () => {
        }
    }, []);

    useEffect(() => {
        dispatch(thunkGetCourseList({
            q: searchText,
            courseLevel: selectLevel,
            page: 0,
            limit: limit
        }))
    }, [selectLevel]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            dispatch(thunkGetCourseList({
                q: searchText,
                courseLevel: selectLevel,
                page: 0,
                limit: limit
            }))
        }, [500])
        return () => {
            clearTimeout(timeOut);
        };
    }, [searchText])

    useEffect(() => {
        window.addEventListener('scroll', getMore);
        return () => window.removeEventListener('scroll', getMore);
    }, [currentPage])

    const getMore = () => {
        if ((window.innerHeight + document.documentElement.scrollTop) >= document.scrollingElement.scrollHeight && hasMore) {
            setIsLoadMore(true)
            setTimeout(() => {
                dispatch(thunkGetCourseList({
                    q: searchText,
                    courseLevel: selectLevel,
                    page: currentPage,
                    limit: limit
                }))
                setIsLoadMore(false)
            }, 1000)
        }
    }

    return (
        <AppLayout>
            <div className='CourseHomeScreen bg-white min-h-600px'>
                <div className='container-xxl py-10'>
                    {/* Banner */}
                    <div className='row'>
                        <div className='col-12'>
                            <div className='CourseHomeScreen_Banner px-10 px-md-20 pt-5 pt-md-10 rounded-lg max-h-260px d-flex'>
                                <div className='col-5 d-flex align-items-center pb-5 pb-md-10'>
                                    <p className='m-0 text-left font-weight-boldest text-uppercase' style={{ fontSize: '2.5rem', lineHeight: '120%' }}>
                                        {t('Course')}
                                        <br />
                                        <span className='text-primary' style={{ fontSize: '3rem' }}>MoneyGain</span>
                                    </p>
                                </div>
                                <div className='CourseHomeScreen_Banner_Right col-7 py-30'></div>
                            </div>
                        </div>
                    </div>

                    {/* courses list */}
                    <div className='mt-10'>
                        <SectionHeaderView
                            title={`${t('Course')} MoneyGain`}
                            dividerVisible={false}
                        />

                        <div className='d-flex justify-content-between align-items-end'>
                            {/* Nav menus */}
                            <ul className="nav nav-tabs nav-tabs-line border-0">
                                <li className="nav-item">
                                    <a className={`font-weight-bolder nav-link ${activeTab === 'course_all' && 'active'}`} href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('course_all');
                                        setSelectLevel(null)
                                    }}>{t('All')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`font-weight-bolder nav-link ${activeTab === 'course_basic' && 'active'}`} href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('course_basic');
                                        setSelectLevel(1)
                                    }}>{t('Basic')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`font-weight-bolder nav-link ${activeTab === 'course_medium' && 'active'}`} href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('course_medium');
                                        setSelectLevel(2)
                                    }}>{t('Medium')}</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`font-weight-bolder nav-link ${activeTab === 'course_advance' && 'active'}`} href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTab('course_advance');
                                        setSelectLevel(3)
                                    }}>{t('Advanced')}</a>
                                </li>
                            </ul>

                            {/* Search bar */}
                            <div className='mb-4'>
                                <BaseSearchBar
                                    className=''
                                    name='searchbar'
                                    onSubmit={(text) => {
                                        setSearchText(text);
                                    }}
                                    placeholder={`${t('TypeToSearch')}...`}

                                />
                            </div>
                        </div>

                        <Tabs
                            defaultActiveKey='course_all'
                            activeKey={activeTab}
                            className=''
                        >
                            <Tab eventKey='course_all'>
                                <div className='row'>
                                    {list.length > 0 && list.map((item, index) => {
                                        return (
                                            <div className='col-12 col-sm-6 col-md-6 my-4' key={index}>
                                                <CellCourseGrid info={item} />
                                            </div>
                                        )
                                    })}
                                    {
                                        list.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                    loading={loading}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    isLoadMore ? <div className={"course-custom-load-more"}>
                                        <LoadMore />
                                    </div> : null

                                }
                            </Tab>
                            <Tab eventKey='course_basic'>
                                <div className='row'>
                                    {list.length > 0 && list.map((item, index) => {
                                        return (
                                            <div className='col-12 col-sm-6 col-md-6 my-4' key={index}>
                                                <CellCourseGrid info={item} />
                                            </div>
                                        )
                                    })}
                                    {
                                        list.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                    loading={loading}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    isLoadMore ? <div className={"course-custom-load-more"}>
                                        <LoadMore />
                                    </div> : null

                                }
                            </Tab>
                            <Tab eventKey='course_medium'>
                                <div className='row'>
                                    {list.length > 0 && list.map((item, index) => {
                                        return (
                                            <div className='col-12 col-sm-6 col-md-6 my-4' key={index}>
                                                <CellCourseGrid info={item} />
                                            </div>
                                        )
                                    })}
                                    {
                                        list.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                    loading={loading}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    isLoadMore ? <div className={"course-custom-load-more"}>
                                        <LoadMore />
                                    </div> : null

                                }
                            </Tab>
                            <Tab eventKey='course_advance'>
                                <div className='row'>
                                    {list.length > 0 && list.map((item, index) => {
                                        return (
                                            <div className='col-12 col-sm-6 col-md-6 my-4' key={index}>
                                                <CellCourseGrid info={item} />
                                            </div>
                                        )
                                    })}
                                    {
                                        list.length === 0 && (
                                            <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                                <EmptyView
                                                    description={t('NoDataToDisplay')}
                                                    iconEmpty={AppIcons.icEmptyPack}
                                                    loading={loading}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    isLoadMore ? <div className={"course-custom-load-more"}>
                                        <LoadMore />
                                    </div> : null
                                }
                            </Tab>
                        </Tabs>
                    </div>

                </div>
            </div>
        </AppLayout>
    );
}

export default CourseHomeScreen;
