import AppCardDataInfo from "general/components/AppCardDataInfo";
import MGDataTable from "general/components/Tables/MGDataTable";
import Utils from "general/utils/Utils";
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

Warrant.propTypes = {
    warrants: PropTypes.array,
    showMore: PropTypes.bool,
    onClickMore: PropTypes.func,
    scrollXButton: PropTypes.bool,
};

Warrant.defaultProps = {
    warrants: [],
    showMore: false,
    onClickMore: null,
    scrollXButton: true,
};

function Warrant(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { warrants, showMore, onClickMore, scrollXButton } = props;

    return (
        <div>
            <AppCardDataInfo
                // scrollLeft={handleScrollLeft}
                // scrollRight={handleScrollRight}
                tableId={`${scrollXButton ? 'warrant' : ''}`}
                scrollXButton={scrollXButton ? true : false}
                title={t('Warrant')}
                rightComponent={
                    showMore ? (
                        <div>
                            <a
                                href='#'
                                className='font-size-lg font-weight-bold d-flex align-items-center'
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (onClickMore) {
                                        onClickMore();
                                    }
                                }}
                            >
                                {t('ViewMore')}<i className="far fa-angle-right ml-2 text-primary" />
                            </a>
                        </div>
                    ) : (<></>)
                }
                content={(
                    // <>
                    <MGDataTable
                        tableId={`${scrollXButton ? 'warrant' : ''}`}
                        overflowX={scrollXButton ? true : false}
                        // tableId="warrantTable"
                        // maxHeight="446px"
                        // minWidth="1225px"
                        className='contentTable bg-white border-top-0 border-left-0 border-right-0 border-bottom-0'
                        headItems={[
                            // { title: t('Document'), subTitle: t(''), col: 'col-3' },
                            { title: t('CWCode'), subTitle: t('CWName'), col: 'col-1' },
                            { title: t('ConversionRate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('ExercisePrice'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('BaseStock'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('Issuers'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('ReleaseDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('PostUpDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('FirstTradingDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('LastTradingDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                            { title: t('DueDate'), subTitle: '', col: 'col-1', textAlign: 'right' },
                        ]}
                        dataItems={
                            (showMore ? _.slice(warrants, 0, 6) : warrants).map((item, index) => {
                                return {
                                    onPress: () => {
                                        // const url = item.url;
                                        // if (url) {
                                        //     Utils.downloadURI(url, '');
                                        // }
                                    },
                                    data: [
                                        // {
                                        //     icon: '', title: '', subTitle: '', name: '', value: '', valueColor: '', col: 'col-1', component: <a href={item.url} className="px-3" download style={{
                                        //         textDecorationLine: 'underline',
                                        //         color: 'blue',
                                        //     }}>{t('Download')}</a>
                                        // },
                                        { icon: '', title: item.warrantCode, titleWeight: 600, subTitle: item.stockName, name: '', value: '', valueColor: '', col: 'col-3', titleMinWidth: '260px' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.conversionRate), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatNumber(item.excercisePrice), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: item.stock.stockCode, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: item.stock.stockName, nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item.releaseDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item.postUpDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item.firstTradingDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item.lastTradingDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                        { icon: '', title: '', name: Utils.formatDateTime(item.dueDate, 'DD/MM/YYYY'), nameColor: '', value: '', valueColor: '', col: 'col-1', textAlign: 'right' },
                                    ]
                                }
                            })
                        }
                    />
                    // </>
                )}
            />
        </div>
    )
}

export default Warrant;
