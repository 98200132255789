import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useTranslation } from 'react-i18next';
import CellMGProductGrid from 'features/MGProduct/components/CellMGProductGrid';
import { Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';

HomeSectionMGProduct.propTypes = {

};

function HomeSectionMGProduct(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const history = useHistory();
    const { topProducts } = useSelector(state => state.app);
    const chunkProducts = Utils.chunkItems(topProducts, 3);

    // MARK: --- Functions ---

    return (
        <div className='HomeSectionMGProduct py-16'>
            <div className='container-xxl'>
                <div className='row'>
                    {/* Left */}
                    <div className='col-12 col-md-5 d-flex flex-column justify-content-center'>
                        <p className='mt-sm-4 HomeSectionMGProduct_TitleSmall text-white'>{t('ProductsOf')} <span className='HomeSectionMGProduct_TitleLarge'>MoneyGain</span></p>
                        <a
                            href='#'
                            className='font-size-h6 font-weight-bolder border rounded text-hover-primary mt-4 align-self-start px-4 py-2 HomeSectionMGProduct_ButtonMore'
                            style={{ color: '#F4B638' }}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/product');
                            }}
                        >
                            {t('ViewAll')}
                        </a>
                    </div>

                    {/* Right */}
                    <div className='HomeSectionMGProduct_Right col-12 col-md-7 d-flex px-10'>
                        <Carousel
                            className='w-100'
                            indicators={false}
                            nextIcon={(
                                <div
                                    className='HomeSectionMGProduct_Right_Arrow w-40px h-40px'
                                >
                                    <i className="fas fa-arrow-right text-primary text-hover-white w-100 h-100 d-flex align-items-center justify-content-center"></i>
                                </div>
                            )}
                            prevIcon={(
                                <div
                                    className='HomeSectionMGProduct_Right_Arrow w-40px h-40px'
                                >
                                    <i className="fas fa-arrow-left text-primary text-hover-white w-100 h-100 d-flex align-items-center justify-content-center"></i>
                                </div>
                            )}
                        >
                            {
                                chunkProducts.map((item, index) => {
                                    return (
                                        <Carousel.Item className='w-100' key={index}>
                                            <div className='d-flex justify-content-start row'>
                                                {
                                                    item && Array.isArray(item) && item.map((subItem, subIndex) => {
                                                        return (
                                                            <div className='col-4' key={subIndex}>
                                                                <CellMGProductGrid item={subItem} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSectionMGProduct;