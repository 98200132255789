import axiosClient from "./axiosClient";

const industryApi = {
    searchIndustryForFilter: (params) => {
        const url = `/industry/search`;
        return axiosClient.post(url, params);
    },

    getIndustrySpecial: (industryId) => {
        const url = `/industry/special/${industryId}`;
        return axiosClient.get(url);
    },

    getIndustryChartData: (industryId, interval, fromDate, toDate) => {
        const params = {
            interval,
            from: fromDate,
            to: toDate,
        };
        const url = `/industry/realtime/${industryId}`;
        return axiosClient.get(url, { params });
    }
};

export default industryApi;
