import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { AppIcons, AppImages } from 'general/constants/AppResource';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import CellWebinarGrid from 'features/Webinar/components/CellWebinarGrid';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import { useHistory } from 'react-router-dom';
import webinarApi from "../../../../api/webinarApi";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import ModalRegisterJoinWebinar from 'features/Webinar/components/ModalRegisterJoinWebinar';
import ModalJoinWebinarSuccess from 'features/Webinar/components/ModalJoinWebinarSuccess';
import EmptyView from 'general/components/Views/EmptyView';
import UserHelper from 'general/helpers/UserHelper';

HomeSectionWebinar.propTypes = {};

function HomeSectionWebinar(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { incomingWebinars, recentWebinars } = useSelector(state => state.app);
    const incomingWebinar = incomingWebinars.length > 0 ? incomingWebinars[0] : null;
    const history = useHistory();
    const [modalRegisterJoinWebinarShowing, setModalRegisterJoinWebinarShowing] = useState(false);
    const [modalJoinWebinarSuccessShowing, setModalJoinWebinarSuccessShowing] = useState(false);
    const loggedInUser = useSelector(state => state.auth.current);
    const isUserLoggedIn = !Utils.isObjectNull(loggedInUser) && !Utils.isObjectEmpty(loggedInUser);

    const allAccountTransactions = useSelector(state => state.account.allTransactions);
    const allAccountWebinars = useSelector(state => state.account.allWebinars);
    // console.log({ allAccountTransactions, allAccountWebinars });
    const incomingWebinarId = incomingWebinar?.webinarId;
    const [incomingWebinarStatus, setIncomingWebinarStatus] = useState(null);

    // MARK: --- Functions ---
    async function handleJoinWebinar(webinarId) {
        try {
            let joinInWebinarResult = await webinarApi.joinInWebinar(webinarId)
            let { result, detail } = joinInWebinarResult
            if (result == "success") {
                ToastHelper.showSuccess(t("join_in_webinar_success"));
                history.push(`/webinar/${webinarId}`)
            }
            else {
                ToastHelper.showError(t(detail));
            }
        } catch (e) {
            ToastHelper.showError(t("buy_webinar_notice"));
        }
    }

    useEffect(() => {
        if (incomingWebinarId) {
            const incomingWebinarStatus = UserHelper.checkWebinarStatus(incomingWebinarId);
            setIncomingWebinarStatus(incomingWebinarStatus);
        }
    }, [allAccountTransactions, allAccountWebinars, incomingWebinarId]);

    return (
        <div className='HomeSectionWebinar'>
            {/* Incoming Webinars */}
            {
                incomingWebinar && (
                    <div className='HomeSectionWebinar_Incoming position-relative'>
                        <div className='HomeSectionWebinar_Incoming_BG position-absolute w-100 h-100'></div>
                        <div className='container-xxl py-20 d-flex flex-column align-items-center'>
                            <p className='HomeSectionWebinar_Incoming_Title font-size-h2 font-weight-bolder'>{t('IncomingWebinar')}</p>
                            <p className='HomeSectionWebinar_Incoming_Name font-size-h2 font-weight-bolder text-white text-center'>{incomingWebinar.name}</p>
                            <p className='HomeSectionWebinar_Incoming_Time font-size-h4 font-weight-bolder text-white py-2'>{Utils.formatDateTime(incomingWebinar.startTime, 'DD/MM/YYYY hh:mm A')}</p>
                            <button
                                onClick={() => {
                                    if (!isUserLoggedIn) {
                                        ToastHelper.showError(t('YouNeedToLoginFirst'))
                                        return;
                                    }
                                    if (!['SUCCESS', 'PENDING'].includes(incomingWebinarStatus)) {
                                        setModalRegisterJoinWebinarShowing(true);
                                    } else {
                                        history.push(`/webinar/${incomingWebinarId}`);
                                    }
                                }}
                                type="button"
                                // disabled={['SUCCESS', 'PENDING'].includes(incomingWebinarStatus)}
                                className="btn btn-primary font-weight-bold mt-4"
                            >
                                {incomingWebinarStatus === 'SUCCESS' ? t('Joined') : (incomingWebinarStatus === 'PENDING' ? t('PENDING') : t('JoinWebinar'))}
                            </button>
                        </div>
                    </div>
                )
            }

            {/* Recently Webinars */}
            <div className='HomeSectionWebinar_Recently bg-white py-10'>
                <div className='container-xxl'>
                    <SectionHeaderView
                        title={t('RecentlyWebinars')}
                        buttonTitle={t('ViewAll')}
                        onPressButton={() => {
                            history.push('/webinar')
                        }}
                    />
                    <div className='row mt-6'>
                        {
                            recentWebinars.length > 0 && recentWebinars.map((item, index) => {
                                return (
                                    <div key={index} className='col-12 col-sm-6 col-md-4'>
                                        <CellWebinarGrid item={item} />
                                    </div>
                                )
                            })
                        }

                        {
                            recentWebinars.length === 0 && (
                                <div className='w-100 d-flex align-items-center justify-content-center py-10'>
                                    <EmptyView
                                        description={t('NoDataToDisplay')}
                                        iconEmpty={AppIcons.icEmptyPack}
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                incomingWebinar && (
                    <>
                        {/* Modal register join webinar */}
                        <ModalRegisterJoinWebinar
                            show={modalRegisterJoinWebinarShowing}
                            onClose={() => {
                                setModalRegisterJoinWebinarShowing(false);
                            }}
                            webinarItem={incomingWebinar}
                        />
                        {/* Modal register success */}
                        <ModalJoinWebinarSuccess
                            show={modalJoinWebinarSuccessShowing}
                            onClose={() => {
                                setModalJoinWebinarSuccessShowing(false);
                            }}
                            webinarItem={incomingWebinar}
                        />
                    </>
                )
            }
        </div>
    );
}

export default HomeSectionWebinar;
