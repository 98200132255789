import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SectionHeaderView from 'general/components/Views/SectionHeaderView';
import { useSelector } from 'react-redux';
import Utils from 'general/utils/Utils';
import { Carousel } from 'react-bootstrap';
import CellReviewGrid from '../LandingPageFeaturedReview/CellReviewGrid';
import './style.scss';
import CellNewVideoGrid from 'features/Video/components/CellNewVideoGrid';
import { useHistory } from 'react-router-dom';

HomeSectionNewVideos.propTypes = {

};

HomeSectionNewVideos.defaultProps = {

};

function HomeSectionNewVideos(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { } = props;
    const { topVideos } = useSelector(state => state.app);
    const breakpoint = useRef('');
    let chunkVideos = Utils.chunkItems(topVideos, 3);
    const history = useHistory();

    // MARK: --- Functions ---
    function handleWindowResize() {
        const width = window.innerWidth;
        // console.log(width, breakpoint.current, topVideos);
        // if (width <= 576 && breakpoint.current !== 'sm' && topVideos.length > 0) {
        //     // sm
        //     breakpoint.current = 'sm';
        //     chunkVideos = (Utils.chunkItems(topVideos, 1));
        // }
        // if (width > 576 && width <= 768 && breakpoint.current !== 'md' && topVideos.length > 0) {
        //     // md
        //     breakpoint.current = 'md';
        //     chunkVideos = (Utils.chunkItems(topVideos, 2));
        // }
        // if (width >= 992 && breakpoint.current !== 'lg' && topVideos.length > 0) {
        //     // lg
        //     breakpoint.current = 'lg';
        //     chunkVideos = (Utils.chunkItems(topVideos, 3));
        // }
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        handleWindowResize();
    }, [topVideos]);

    return (
        <div className='HomeSectionNewVideos pb-20 pt-16' style={{
            background: 'linear-gradient(280.94deg, #45108A 0.04%, #3D065F 38.2%, #6B2676 100.1%)',
        }}>
            <div className='container-xxl'>
                <div style={{
                    marginBottom: '-12px'
                }}>
                    <SectionHeaderView
                        title={t('NewVideos')}
                        // buttonTitle={t('ViewAll')}
                        dividerVisible={false}
                        tintColor='#F4B638'
                    // onPressButton={() => {
                    //     history.push('/video')
                    // }}
                    />
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <Carousel
                            className='w-100'
                            indicators={true}
                            controls={false}
                        >
                            {
                                chunkVideos.map((item, index) => {
                                    return (
                                        <Carousel.Item className='w-100' key={index}>
                                            <div className='d-flex justify-content-start row'>
                                                {
                                                    item && Array.isArray(item) && item.map((subItem, subIndex) => {
                                                        return (
                                                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 d-flex mt-8' key={subIndex}>
                                                                <CellNewVideoGrid
                                                                    item={subItem}
                                                                    onPress={() => {
                                                                        history.push(`/knowledge-center/video/${subItem.videoId}`);
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSectionNewVideos;